import {
  chain,
  compose,
  equals,
  filter,
  findIndex,
  head,
  identity,
  last,
  length,
  lensPath,
  lensProp,
  map,
  mergeRight,
  over,
  pick,
  prop,
  propEq,
  propOr,
  slice,
  sortBy,
  uniq,
  values,
  zip
} from 'ramda';
import { lineString, multiLineString, point } from '@turf/helpers';
import transformTranslate from '@turf/transform-translate';
import nearestPointOnLine from '@turf/nearest-point-on-line';
import bearing from '@turf/bearing';
import pointToLineDistance from '@turf/point-to-line-distance';
import { memoizedWith, strPathOr } from '@rescapes/ramda';
import { MAP_TRANSFORM_TRACK_PERPENDICULAR_DISTANCE_INTERVAL } from 'appConfigs/trainConfigs/trainConfig.js';
import { useMemo } from 'react';

export const trainDataFriendlyDateFormatString = 'ccc LLL d, yyyy';
export const trainDataFriendlyDatetimeFormatString = `${trainDataFriendlyDateFormatString} HH:mm`;
export const trainTimeFriendlyTimeFormatString = 'HH:mm:ss';


/**
 * Transforms the point perpendicular to the track data, either 90 or -90 depending on the given geojsonIndex
 * @param {Object} trackMultiLineString Multiple feature sets of oldTrackConfig, each representing a different railroad or similar
 * @param {[Object]} lineSegments Train data line segment
 * @param {Number} geojsonIndex Index of the the UserTrainRunInterval that the point is from. This lets
 * use put UserTrainRunInterval data on either side of the track on the map. TODO we could support
 * a distance from the track concept with this index so showed more than 2 UserTrainRunInterval lines perpendicular
 * to the track
 * @param {Object} point The turf point to transform
 * @returns {Object} The translated turf point
 */
export const transformPoint = ({ trackData: { trackMultiLineString, lineSegments }, geojsonIndex, point }) => {
  // Find the nearest lineSegment from trackMultiLineString and lineSegments
  const lineSegment = nearestLineToPoint({
      trackData: { trackMultiLineString, lineSegments }, point
    }
  );
  // Get the distance and direction to transfrom the point based on the bearing of the line segment
  const { distance, direction } = calculateTransformDistanceAndDirection({
    geojsonIndex,
    bearing: bearing(...map(f => f(lineSegment.geometry.coordinates), [head, last]))
  });
  return transformTranslate(point, distance, direction);
};

/**
 * Transforms lineSegment +/-90 degrees by transformDistance
 * @param {Number} geojsonIndex The index of the underlying data that indicates what direction and how far to transform
 * the line segment
 * @param {Object} lineSegment 2 Point LineSegment feature
 * @returns {*}
 */
export const transformLineSegment = ({ geojsonIndex, lineSegment }) => {

  const pointCoords = lineSegment.geometry.coordinates;
  const pointPairs = zip(slice(0, -1, pointCoords), slice(1, Infinity, pointCoords));
  // Update the features to the transformed version
  return compose(
    coords => {
      return lineString(coords);
    },
    lines => {
      return uniq(chain(line => line.geometry.coordinates, lines));
    },
    chain(
      // Transform each line segment perpendicular to itself
      pointPair => {
        const { distance, direction } = calculateTransformDistanceAndDirection(
          { geojsonIndex, bearing: bearing(...pointPair) }
        );

        return transformTranslate(lineString(pointPair), distance, direction);
      }
    )
  )(pointPairs);
};

/**
 *  transform perpendicular to closest line of track transformDistance km
 * transformDistance is multiplied by the geojsonIndex divided by two with a quotient starting a 1
 * since every other track is tranformed 90 or -90, we want to tranfsorm 90 degrees transformDistance, then,
 * -90 degrees transformDistance, then 90 degrees transformDistance * 2, then -90 degrees transformDistance * 2,
 // etc
 * @param {Number} [transformDistance] Default MAP_TRANSFORM_TRACK_PERPENDICULAR_DISTANCE_INTERVAL
 * Usually a constant distance interval to transform perpendicular from the track
 * @param {Number} geojsonIndex The index of the TrainRun being shown, used to decide where to place the trainRun's
 * track perpendicular to the main track
 * @param bearing
 * @returns {{distance: number, direction: *}}
 */
const calculateTransformDistanceAndDirection = (
  {
    transformDistance = MAP_TRANSFORM_TRACK_PERPENDICULAR_DISTANCE_INTERVAL,
    geojsonIndex,
    bearing
  }) => {
  const maybeMinus = equals(0, geojsonIndex % 2) ? -1 : 1;
  const direction = bearing + maybeMinus * 90;
  const distance = transformDistance * ((geojsonIndex + 3) / 2);
  return { direction, distance };
};

/**
 * Like transformGeojson but just moves each point in the geojson to the nearest point on the lineString
 * Memoized with a reference check of userTrainRunInterval so that whenever the userTrainRunInterval or distanceRange
 * of userTrainRunInterval changes, this will rerun
 * @param userTrainRunInterval
 * @param lineString
 * @param geojson
 * @returns {*}
 */
export const memoizedTransformGeojsonToNearest = memoizedWith((
  { userTrainRunInterval }
) => {
  // Only recalculate if the geojsonIndex or geojson has changed for this userTrainRunInterval
  return [userTrainRunInterval.sourceKey, userTrainRunInterval.geojsonIndex, userTrainRunInterval.geojson];
}, ({ lineString, geojson }) => {
  return over(
    lensProp('features'),
    features => {
      return map(
        point => {
          const nearest = nearestPointOnLine(lineString, point, { units: 'meters' });
          // Take the geometry of the nearest, maintaining point's properties
          return mergeRight(point, pick(['geometry'], nearest));
        },
        features);
    },
    geojson
  );
});

/**
 * Find the nearest line on lineSegments to point
 * @param {Object} trackData
 * @param {Object} trackData.trackMultiLineString Multiple feature sets of oldTrackConfig, each representing a different railroad or similar
 * @param {[Object]} trackData.lineSegments Train data line segment
 * @param {Object} point The turf point to transform
 * @returns {Object} The nearest line from lineSegment
 */
export const nearestLineToPoint = ({ trackData: { trackMultiLineString, lineSegments }, point }) => {
  const nearestPoint = nearestPointOnLine(trackMultiLineString, point, { units: 'meters' });
  // I don't know why this doesn't work, use the line below it instead
  //const line = find(lineString => booleanPointOnLine(nearestPoint, lineString), lineStrings)
  return sortBy(line => pointToLineDistance(nearestPoint, line), lineSegments)[0];
};




/**
 * Returns the imuPoints of the TrainRun, TODO currently this favors the device with the most point
 * @param trainRun
 * @returns {*}
 */
export const trainRunImupointsWithMostData = trainRun => {
  // TODO take the imuPoints with most data for now.
  return compose(
    last,
    sortBy(length),
    // Discard the formation sourceKeys
    values
  )(trainRun.imuPoints);
};

export const lineStringFirstOrLastPoint = (firstLast, lineStringFeature) => {
  return point(firstLast(lineStringFeature.geometry.coordinates));
};


export const nameOfTimetabledPassingTimeStopNamePath = 'stopPointInJourneyPattern.scheduledStopPoint.name';
/**
 * The underling name of the stpp of the timetabledPassingTime
 * @param timetabledPassingTime
 * @returns {*}
 */
export const nameOfTimetabledPassingTimeStop = timetabledPassingTime => {
  return strPathOr(null, nameOfTimetabledPassingTimeStopNamePath, timetabledPassingTime);
};
export const nameOfRoutePointStopNamePath = 'projections.0.projectedPoint.name';
/**
 * The underling name of the stop of the routePoint
 * @param routePoint
 * @returns {*}
 */
export const nameOfRoutePointStop = routePoint => {
  return strPathOr(null, nameOfRoutePointStopNamePath, routePoint);
};

/**
 * Creates a multiline string from individual point features
 * @param trackPointFeatures
 */
export const trackPointFeaturesToMultilineString = trackPointFeatures => {
  return multiLineString(map(
    feature => {
      return feature.geometry.coordinates;
    },
    trackPointFeatures || []
  ));
};

/**
 * Creates two point line segments from a trackMultiLineString
 * @param tracklineString A multi or single line string
 * @returns {*}
 */
export const trackLineSegments = tracklineString => {
  const func = tracklineString.geometry.type === 'MultiLineString' ? chain : map;
  const pointCoords = func(identity, tracklineString.geometry.coordinates);
  return map(
    points => lineString(points),
    zip(slice(0, -1, pointCoords), slice(1, Infinity, pointCoords))
  );
};

/**
 * Given a resolvedDataPathConfiguration for showing data on a chart or map and a category into that configuration
 * and itemPropPath where `properties.${itemPropPath}`matches one or more of the category's config.datPath,
 * return all matches
 * @param {Object} resolvedDataPathConfiguration See appConfigs.trainConfigs.dataConfigs for an example
 * @param {String} category
 * @param {String} itemPropPath
 * @returns {Object}
 */
export const useMemoResolveDataPathConfigsForCategoryAndItem = (resolvedDataPathConfiguration, category, itemPropPath) => {
  return useMemo(() => {
    const index = findIndex(propEq('category', category), resolvedDataPathConfiguration);
    const filtered = over(
      lensPath([index, 'configs']),
      configs => {
        // Find the acceleration config for the chosen acceleration
        return map(
          // Merge customControls into each config
          mergeRight({ customControls: propOr(null, 'customControls', resolvedDataPathConfiguration[index]) }),
          filter(config => {
            return equals(config.dataPath, `properties.${itemPropPath}`);
          }, configs)
        );
      },
      resolvedDataPathConfiguration
    );
    // Flatten all the configs
    return chain(
      prop('configs'),
      filtered
    );
  }, [category, itemPropPath]);
};