/**
 * Creates the props by calling propsFunc with no arguments unless loading is true
 * @param {Boolean} loading Returns {} if true
 * @param {Function} propsFunc Called with no arguments if loading is false
 * @returns {Object} The result of propsFunc or {}
 */
export const unlessLoadingProps = (loading, propsFunc) => {
  return loading ? {} : propsFunc()
}
/**
 * Creates the value by calling valueFunc with no arguments unless loading is true
 * @param {Boolean} loading Returns null if true
 * @param {Function} valueFunc Called with no arguments if loading is false
 * @returns {Object} The result of propsFunc or null
 */
export const unlessLoadingValue = (loading, valueFunc) => {
  return loading ? null : valueFunc()
}