import {
    railwayLineMapboxSourcesAndLayersSets,
    trackSwitchesMapboxSourcesAndLayersSets
} from 'appUtils/trainAppUtils/railwayLineUtils.js';
import {setMapboxSourceAndLayersSets} from 'utils/map/mapboxSourceUtils.js';
import {useNotLoadingSetterEffect} from 'utils/hooks/useMemoHooks.js';
import {setMapboxOnHover} from 'async/trainAppAsync/hooks/mapHooks/trainMapHooks.js';
import {singlePointFeatureFromPayload} from 'components/charts/stackedChart/chartMapInteractionUtils.js';


/**
 * Call railwayLineMapboxSourcesAndLayersSets and update/creates the resulting sources on the trainMap
 * @param {Boolean} loading Do nothing if true
 * @param {Object} trainProps
 * @param {Object} trainProps.railwayLineProps
 * @param {[Object]} trainProps.railwayLineProps.railwayLines The railwayLines whose tracks we create sources for
 * @param {Object} mapProps
 * @param {Object} mapProps.trainMap The Mapbox map
 * @param {Function} t The translation function
 */
export const useRailwayLineMapboxSourcesAndLayers = (
    {
        loading,
        appProps,
        organizationProps,
        trainProps,
        mapProps,
        t
    }) => {
    useNotLoadingSetterEffect(
        {loading},
        ({trainProps}) => {
            if (appProps.currentAppPage == 'ride_comfort') {
                // Hack to not show this with ride_comfort
                return []
            }
            return railwayLineMapboxSourcesAndLayersSets({trainProps})
        },
        {trainProps},
        sourceAndLayersSets => {
            // railwayLineMapboxSourcesAndLayersSets produces a source and one or more layers
            // for each RailwayLine Track. Set Mapbox to them here
            setMapboxSourceAndLayersSets({mapboxMap: mapProps.trainMap, sourceAndLayersSets, zoomToSources: true});
        },
        [trainProps.railwayLineProps.railwayLines, appProps.currentAppPage ]
    );

    /*
    TODO this icon doesn't work. Copy the code of trainMap.addImage('train-highlighted', imgHighlighted) instead,
    which did work at some point
    useNotLoadingEffect(loading, () => {
      const img = new Image(20, 20);
      img.onload = () => {
        mapProps.trainMap.addImage('track-switch', img);
        setTrainSwitchLoaded(true);
      };
      img.src = TrackSwitchIconSvg;
    });
    */
    const trainSwitchLoaded = true

    // Train switches layer
    useNotLoadingSetterEffect(
        {loading: loading || !trainSwitchLoaded},
        trackSwitchesMapboxSourcesAndLayersSets,
        {trainProps},
        sourceAndLayersSets => {
            // railwayLineMapboxSourcesAndLayersSets produces a source and one or more layers
            // for each RailwayLine Track. Set Mapbox to them here
            setMapboxSourceAndLayersSets({mapboxMap: mapProps.trainMap, sourceAndLayersSets, zoomToSources: false});
            setMapboxOnHover({
                appProps,
                organizationProps,
                trainProps,
                mapboxMap: mapProps.trainMap,
                sourceAndLayersSets,
                onHover: trackSwitchesMapboxSourcesAndLayersSetsOnClick,
                // Only disable the switch popup on click
                onClickOnly: true,
                t
            });
        },
        [trainProps.railwayLineProps.railwayLines]
    );
};

/**
 * Popup to describe a railway switch feature point
 * @param trainMap
 * @param popup
 * @param e
 * @param data
 */
const trackSwitchesMapboxSourcesAndLayersSetsOnClick = ({trainMap, popup, e}, payload) => {
    popup.remove();

    const feature = singlePointFeatureFromPayload(payload)
    const description = `Direction: ${feature.properties.direction}<br/>Type: ${feature.properties.type}<br/>Km: ${feature.properties.km}`;
    // Populate the popup and set its coordinates
    // based on the feature found.
    popup.setLngLat(e.lngLat).setHTML(description).addTo(trainMap);
};