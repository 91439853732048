import {useTrainMap} from 'async/trainAppAsync/hooks/mapHooks/trainMapHooks.js';
import {
    moveCursorAlongChartLine,
    moveCursorAlongTrainMapLine,
    removeCursorFromChart,
    removeCursorFromTrainMap
} from 'components/charts/stackedChart/chartMapInteractionUtils.js';
import TrainRunIntervalDependency from 'async/trainAppAsync/dependencies/TrainRunIntervalDepedency.js';

/**
 * Loads/Updates Mapbox data into mapProps
 * Depends directly on TrainRoutes at trainProps.trainRoutes
 * @param appProps
 * @param organizationProps
 * @param trainProps
 * @param children
 * @return {*}
 * @constructor
 */
const TrainMapDependency = ({appProps, organizationProps, trainProps, mapProps}) => {

    const loading = trainProps.trainRouteProps.loading;

    const {
        trainMap,
        setTrainMap,
        trainMapLoading,
        setTrainMapLoading,
        isTrainMapMounted,
        setIsTrainMapMounted
    } = mapProps;

    // Once our async data is loaded we set up the map
    const bbox = loading ? null : trainProps.trainRouteProps.trainRoute.trackData.boundingBox;
    useTrainMap({
        loading,
        isTrainMapMounted,
        bbox,
        setTrainMap,
        setTrainMapLoading,
        setIsTrainMapMounted
    });
    const updateTrainRunLinePosition = payload => {
        moveCursorAlongTrainMapLine({appProps, trainProps})(trainProps.trainRouteProps.trainRouteInterval, payload);
    };
    const loadingExplanation = {
        'trainMapLoading': trainMapLoading,
        'isTrainMapMounted': isTrainMapMounted,
        'trainProps.trainRouteProps.loading': trainProps.trainRouteProps.loading
    };

    return <TrainRunIntervalDependency {...{
        appProps: {
            ...appProps,
            // Initialize functions for cross component trainMap interaction, like hovering the mouse on the
            // chart and show the corresponding point on the map, and visa-versa
            moveCursorAlongChartLine: moveCursorAlongChartLine({
                    updateTrainRunLinePosition,
                    trainMap
                }
            ),
            removeCursorFromChart: removeCursorFromChart(trainMap),
            moveCursorAlongTrainMapLine: moveCursorAlongTrainMapLine({appProps, trainProps}),
            removeCursorFromTrainMap: removeCursorFromTrainMap({appProps, trainProps})
        },
        organizationProps,
        mapProps: {
            loadingExplanation,
            // Depends directly on trainProps.trainRouteProps
            loading: loading || trainMapLoading || !isTrainMapMounted,
            ...mapProps
        },
        trainProps
    }} />;
};

TrainMapDependency.displayName = 'TrainMapDependency';
export default TrainMapDependency;