import { getLambdaUrl } from 'lib/fetch/cemitApi/config.js';
import { setUserFromAuthenticationResponse } from 'monitoring/sentry.js';
import { useEffect } from 'react';

/**
 * Authenticates the given token and sests thei
 * @param userState
 * @param setUserState
 * @param navigate
 * @param token
 */
export const useAuthenticate = ({ userState, setUserState, navigate, token}) => {
  useEffect(() => {
    if (token) {
      authenticate({ userState, setUserState, navigate, token });
    }
  }, []);
}

/**
 * TODO Clean up this mess
 * @param userState
 * @param setUserState
 * @param navigate
 * @param token
 * @return {Promise<void>}
 */
export async function authenticate({ userState, setUserState, navigate, token }) {
  let json = '';
  let response;
  try {
    response = await fetch(`${getLambdaUrl()}/checkjwt`, {
      method: 'POST',
      body: JSON.stringify({ token })
    });
    if (!response.ok) {
      if (response.status === 403) {
        // User could not be authenticated using JWT token
        await setUserState({
          id: null,
          email: null,
          error: 'Invalid user',
          sourceKey: userState.sourceKey,
        });
      } else {
        // Generic HTTP response status
        if (console.error) {
          console.error('Failed checking JWT, HTTP response: ' + response.statusText);
        }
        await setUserState({
          id: null,
          email: null,
          error: 'Authentication error',
          sourceKey: userState.sourceKey,
        });
      }
      return;
    }
    json = await response.json();
  } catch (error) {
    // Generic error, for instance network problems
    if (console.error) {
      console.error('Failed checking JWT: ' + error.message);
    }
    await setUserState({
      id: null,
      email: null,
      error: 'Authentication service could not be reached',
      sourceKey: userState.sourceKey,
    });
    return;
  }

  // Log in Sentry
  setUserFromAuthenticationResponse(json);

  await setUserState({
    id: json.message.id,
    email: json.message.email,
    // TODO Replaces these with something more sophisticated
    trackAccess: json.message.trackAccess,
    tabAccess: json.message.tabAccess,
    group: json.message.group,
    // TODO What is this?
    iat: json.message.iat,
    admin: json.message.admin,
    clientSourceKey: userState.clientSourceKey
  });
  if (
    json.message.environmentAccess ||
    (process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'local')
  ) {
    if (
      !(
        json.message.environmentAccess?.includes(process.env.NODE_ENV) ||
        json.message.group === 'sysadmin'
      )
    ) {
      localStorage.removeItem('token');
      navigate('/');
      return;
    }
  }

  if (response.status !== 200) {
    localStorage.removeItem('token');
    navigate('/');
    return;
  }
  localStorage.setItem(
    'trackAccess',
    JSON.stringify({
      trackAccess: json.message.trackAccess
    })
  );
  localStorage.setItem(
    'tabAccess',
    JSON.stringify({
      tabAccess: json.message.tabAccess
    })
  );
  localStorage.setItem(
    'group',
    JSON.stringify({
      group: json.message.group
    })
  );

  // TODO, enable when Flytoget has the correct access
  // let tryingToAccessTrain = location.pathname.includes(`${APPS_PATH}/train`);
  // let accessToTrain =
  //   json.message.group?.includes('sysadmin') ||
  //   json.message.tabAccess?.includes('train');
  // if (tryingToAccessTrain) {
  //   if (!accessToTrain) {
  //     navigate(
  //       // profile is the only place we can safely send a user with no authorization
  //       routeMapping[json.message.tabAccess?.[0] || 'profile']
  //     );
  //   }
  // }
}