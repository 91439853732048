import {Typography} from '@mui/material'
import { useTranslation } from 'react-i18next';

/**
 * Show or hide base on the truthyness of value. If truthy display `${t(hide)} ${t(label)}`
 * If false display `${t('show')} ${t(label)}`
 * @param {*} value Truthy or Falsy value
 * @param {String} label The label to translate
 * @param {Object} [sx] Default {}. The sx property for Typography
 * @param {Object} [variant] Default 'body2', The variant property for Typography
 * @returns {JSX.Element}
 * @constructor
 */
export const ToggleLabelShowHide = ({ sx = {}, variant='body2', value, label }) => {
  const { t } = useTranslation();
  const fullLabel = `${t(value ? 'hide' : 'show')} ${label}`;
  return <Typography {...{ sx, variant }}>{fullLabel}</Typography>;
};