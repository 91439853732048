import { reqStrPathThrowing } from '@rescapes/ramda';
import { extremes } from 'utils/functional/functionalUtils.js';
import { compose, map } from 'ramda';

/**
 * Creates a TrainRouteInterval, the template for TrainRunIntervals, which is
 * only has the given TrainRoute set as an interval
 * @param {String} [sourceKeyPrefix] Default '' Used to distinguish the aggregate TrainRouteInterval from the normal one
 * @param {Object} trainRoute The TrainRoute instance
 * @param distance
 * @returns trainRouteInterval
 */
export const createTrainRouteInterval = ({ sourceKeyPrefix, trainRoute }) => {
  const distance = trainRoute.routeDistance;
  return compose(
    trainRoute => {
      // Use the calculated routeDistance for the end stations
      const [start, end] = map(
        reqStrPathThrowing('routePoint.routeDistance'),
        extremes(trainRoute.orderedRoutePoints)
      );
      return {
        // TrainRouteInterval is not currently persisted
        // We expect only one TrainRouteInterval per TrainRoute
        sourceKey: `${sourceKeyPrefix || 'Default'}TrainRouteInterval${trainRoute.sourceKey}`,
        // Mark it as a TrainRouteInterval since the others are TrainRunIntervals
        __type: 'TrainRouteInterval',
        trainRoute,
        distance,
        // Default to the full interval that is the middle 50% of the range in the center
        distanceRange: {
          start,
          end
        }
      };
    }
  )(trainRoute);
};
