import React from 'react';
import { SvgProps } from './SvgProps.ts';

export const TrainIcon = ({ fill, stroke }: SvgProps) => (
  <svg
    width='22'
    height='28'
    viewBox='0 0 22 28'
    fill={fill}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M20.8208 26.2582L18.9169 23.9672H18.962C20.1686 23.9672 21.15 22.9858 21.15 21.7792V4.50538C21.15 3.29878 20.1685 2.31732 18.962 2.31732H16.751V1.98802C16.751 1.36045 16.2407 0.85 15.6132 0.85H6.38695C5.75938 0.85 5.2491 1.36045 5.2491 1.98802V2.31732H3.03806C1.83146 2.31732 0.85 3.29878 0.85 4.50538V21.7792C0.85 22.9858 1.83146 23.9672 3.03806 23.9672H3.08313L1.17923 26.2582L1.17922 26.2582C0.920619 26.5695 0.822128 26.9384 0.973124 27.2602C1.12418 27.5821 1.47079 27.742 1.87539 27.742H4.95252C5.28415 27.742 5.63943 27.6326 5.95127 27.4595C6.26311 27.2864 6.5439 27.0428 6.71933 26.7613L8.4607 23.9671H13.5393L15.2807 26.7613L15.2807 26.7613C15.4562 27.0427 15.737 27.2864 16.0488 27.4595C16.3606 27.6325 16.7159 27.742 17.0475 27.742H20.1246C20.5293 27.742 20.8758 27.5821 21.0269 27.2601C21.1781 26.9381 21.0794 26.5693 20.8208 26.2582C20.8208 26.2582 20.8208 26.2582 20.8208 26.2582ZM4.35955 23.9673H7.304L5.88618 26.2421C5.80593 26.3709 5.65563 26.5024 5.47545 26.6024C5.29528 26.7024 5.10414 26.7604 4.95246 26.7604H2.03843L4.35955 23.9673ZM17.6405 23.9671L19.9616 26.7603H17.0475C16.8959 26.7603 16.7047 26.7023 16.5245 26.6023C16.3443 26.5023 16.194 26.3708 16.1138 26.2421L16.1138 26.242L14.696 23.9671H17.6405ZM6.23072 1.98796C6.23072 1.90182 6.301 1.83162 6.38689 1.83162H15.6132C15.699 1.83162 15.7692 1.90175 15.7692 1.98796V2.31727H6.23072V1.98796ZM1.83168 21.7792V4.50538C1.83168 3.84031 2.37298 3.299 3.03806 3.299H18.9621C19.6271 3.299 20.1684 3.8403 20.1684 4.50538V21.7792C20.1684 22.4443 19.6271 22.9856 18.9621 22.9856H3.038C2.37299 22.9856 1.83168 22.4443 1.83168 21.7792Z'
      fill={fill}
      stroke={stroke}
      strokeWidth='0.3'
    />
    <path
      d='M17.5843 4.42654H4.4157C3.57865 4.42654 2.89785 5.10727 2.89785 5.94433V13.9208C2.89785 14.7578 3.57865 15.4386 4.4157 15.4386H17.5843C18.4214 15.4386 19.1021 14.7578 19.1021 13.9208V5.94439C19.1021 5.10734 18.4214 4.42654 17.5843 4.42654ZM18.1204 13.9208C18.1204 14.2163 17.8797 14.457 17.5843 14.457H4.4157C4.12018 14.457 3.87953 14.2163 3.87953 13.9208V5.94439C3.87953 5.64888 4.12016 5.40827 4.4157 5.40827H17.5843C17.8798 5.40827 18.1204 5.64892 18.1204 5.94439V13.9208Z'
      fill={fill}
      stroke={stroke}
      strokeWidth='0.3'
    />
    <path
      d='M7.57422 19.306V19.306C7.57428 19.996 7.01263 20.5576 6.32258 20.5576C5.63254 20.5576 5.07093 19.996 5.07093 19.306C5.07093 18.6159 5.63255 18.0543 6.32258 18.0543C7.01261 18.0543 7.57422 18.6159 7.57422 19.306ZM6.32258 17.0727C5.09101 17.0727 4.08926 18.0744 4.08926 19.306C4.08926 20.5377 5.09101 21.5394 6.32258 21.5394C7.5542 21.5394 8.5559 20.5377 8.5559 19.306C8.55596 18.0744 7.55426 17.0727 6.32258 17.0727Z'
      fill={fill}
      stroke={stroke}
      strokeWidth='0.3'
    />
    <path
      d='M15.6771 17.0727C14.4456 17.0727 13.4437 18.0744 13.4437 19.306C13.4437 20.5377 14.4456 21.5394 15.6771 21.5394C16.9087 21.5394 17.9104 20.5377 17.9104 19.306C17.9104 18.0744 16.9087 17.0727 15.6771 17.0727ZM15.6771 20.5576C14.9871 20.5576 14.4255 19.996 14.4255 19.306C14.4255 18.6159 14.9871 18.0543 15.6771 18.0543C16.3672 18.0543 16.9288 18.6159 16.9288 19.306C16.9288 19.996 16.3672 20.5576 15.6771 20.5576Z'
      fill={fill}
      stroke={stroke}
      strokeWidth='0.3'
    />
  </svg>
);