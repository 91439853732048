import center from '@turf/center';
import circle from '@turf/circle';
import {memoizedWith, strPathOr} from '@rescapes/ramda';
import {chain, concat, head, join, map as mapR} from 'ramda';
import {EXTRUSION_HEIGHT_FACTOR} from 'appConfigs/trainConfigs/trainMapConfig.js';
import {
    TRAIN_RUN_INTERVAL_EXTRUDED_COLUMN_LAYER_PREFIX,
    TRAIN_RUN_INTERVAL_EXTRUDED_COLUMN_SOURCE_PREFIX
} from 'appConfigs/trainConfigs/trainConfig.js';
// TODO see file history for rails version of this
export const train3dColumnsOnClick = () => {
};

/**
 * Creates Mapbox extrusion color levels based on the dataThresholds
 * @param {[Object]} dataThresholds Data thresholds. It's assumed
 * that the dataThresdholds are ordered numerically from lowest to highest (e.g. meaning from acceptable to unacceptable)
 * @returns {(number|*|string|number)[]}
 */
const dataThresholdsToMapExtrusionColorLevels = dataThresholds => {

  // Creates [
  // 0,
  // 0 level color,
  // dataThreshold1 value
  // dataThreshold1 color
  // dataThreshold2 value
  // dataThreshold2 color
  // ...
  // ]
  return concat(
    // Use the first threshold for the 0 level
    [
      0,
      head(dataThresholds).style.color
    ],
    // Iterate through dataThresholds and map to [value, color], which
    // we use chain to flatten for Mapbox's ridiculous syntax
    chain(
      dataThreshold => {
        return [dataThreshold.value, dataThreshold.style.color];
      },
      dataThresholds
    )
  );
};

/**
 * Extrude columns on the map for the given geojson
 * TODO Rewrite and move colors, zooms, etc to appProps and organizationProps
 * @param  {Object} Returned with source and layers to so we know what UserTrainRunInterval they belong to
 * @param {Object} trainMap The Mapbox map
 * @param {String} featurePropPath The path in each feature.properties to use for the extrusion.
 * e.g. 'acceleration.sumMaxMean' or 'sumMaxMean'
 * @param {Object} geojson FeatureCollection where features are points or anything else whose center
 * is used for the columns
 * @param {[Object]} dataThresholds Objects with a {style: {color}, value}} where color colors column and value
 * determines the threshold for changing color
 * @param {String} [mapAsseSuffix] Default 'singleton' Unique suffix to add to the Mabbox source and layer
 * in case multiple datasets need to be extruded, in which case each has a separate Mapbox source and layer
 * source: `3d-source-${mapAsseSuffix}`
 * layer: `3d-layer-${mapAsseSuffix}`
 * @returns {Object} {source: The Mapbox source configuration, layers: array of currently one Mapbox layer, userTrainRunInterval}
 */
export const memoizedUserTrainRunInterval3dColumnSourceAndLayers = memoizedWith(
  ({
     userTrainRunInterval,
     featurePropPath,
     dataThresholds,
     extrude
   }) => {
    return [userTrainRunInterval, featurePropPath, dataThresholds, extrude];
  },
  ({
     userTrainRunInterval,
     featurePropPath,
     geojson,
     dataThresholds,
     extrude
   }) => {
    // Makes the source and layer unique to the UserTrainRunInterval
    const mapAssetSuffix = userTrainRunInterval.sourceKey;

    const mapboxSourceName = join('-', [TRAIN_RUN_INTERVAL_EXTRUDED_COLUMN_SOURCE_PREFIX, mapAssetSuffix]);
    const mapboxLayerId = join('-', [TRAIN_RUN_INTERVAL_EXTRUDED_COLUMN_LAYER_PREFIX, mapAssetSuffix]);

    const source = {
      name: mapboxSourceName,
      type: 'geojson',
      data: createExtrudableCoordinates({ featurePropPath }, geojson)
    };

    const layer = extrude ? {
      id: mapboxLayerId,
      type: 'fill-extrusion',
      source: mapboxSourceName,
      // Paint green, orange, or read based on the relationship between
      // the data value and the 3 dataThresholds
      // Convert silly mapbox expressions to javascript methods in  'utils/map/mapboxExpressionUtils.js';
      paint: {
        'fill-extrusion-color': [
          'interpolate',
          ['linear'],
          ['get', 'extrudeHeight'],
          ...dataThresholdsToMapExtrusionColorLevels(dataThresholds)
        ],
        'fill-extrusion-height': ['get', 'extrudeHeight'],
        'fill-extrusion-base': 0,
        'fill-extrusion-vertical-gradient': true,
        'fill-extrusion-opacity': 1
      },
      layout: {
        'visibility': userTrainRunInterval.activity.isVisible ? 'visible' : 'none'
      }
    } : {
      id: mapboxLayerId,
      type: 'fill',
      source: mapboxSourceName,
      // Paint green, orange, or read based on the relationship between
      // the data value and the 3 dataThresholds
      // Convert silly mapbox expressions to javascript methods in  'utils/map/mapboxExpressionUtils.js';
      paint: {
        'fill-color': [
          'interpolate',
          ['linear'],
          ['get', 'extrudeHeight'],
          ...dataThresholdsToMapExtrusionColorLevels(dataThresholds)
        ]
      },
      layout: {
        'visibility': userTrainRunInterval.activity.isVisible ? 'visible' : 'none'
      }
    };
    return {
      source,
      layers: [layer],
      userTrainRunInterval
    };
  }
);

export const remove3dColumns = () => {
  // TODO see file history
};
export const rails3dColumnsOnClick = () => {
  // TODO see file history
};
// TODO out of date
export const remove3dThresholdLayersAndSources = (map) => {
  if (map.getSource('3d-data-thresholds-source')) {
    map.removeLayer('3d-layer-green');
    map.removeLayer('3d-layer-orange');
    map.removeLayer('3d-layer-red');
    map.removeSource('3d-data-thresholds-source');
  }
};


/**
 * Creates a FeatureCollection with features points that each have
 * an extrudeHeight property based on the given feature prop path
 * e.g. 'acceleration.sumMaxMean'
 * @param featurePropPath
 * @param data
 * @param data.features
 * @param data.features[*].properties
 * @param bigRadius
 * @returns {{features: *[], type: string}}
 */
const createExtrudableCoordinates = ({ featurePropPath, bigRadius = false }, data) => {

  return {
    type: 'FeatureCollection',
    features: mapR(feature => {
      const origin = center(feature?.geometry);
      const radius = bigRadius ? 0.01 : 0.004;
      const options = {
        steps: 4,
        units: 'kilometers',
        properties: {
          ...feature.properties,
          // TODO use Math.abs for now
          extrudeHeight: Math.abs(strPathOr(0, featurePropPath, feature.properties) * EXTRUSION_HEIGHT_FACTOR)
        }
      };
      return circle(origin, radius, options);
    }, data?.features || [])
  };
};
