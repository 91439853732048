import { all, always, chain, identity, ifElse, lensPath, map, over, propOr, set } from 'ramda';
import { compact, strPathOr } from '@rescapes/ramda';
import { areIntervalsOverlapping } from 'date-fns';
import { dateRangePairToObj } from 'utils/date/dateUtils.js';

/**
 * Unsyncs the trainRunInterval from its trainRouteInterval's distanceRange. This occurs
 * when the user updates the TrainRunInterval's distanceRange, after which we don't want it
 * to be synced to the trainRouteInterval
 * @param {Object} crudTrainRunIntervals
 * @param {Function} crudTrainRunIntervals.updateOrCreate Called to update the trainRunInterval
 * @param {Object} trainRunInterval The trainRunInterval to update
 * @returns {Object} The updated trainRunInterval
 */
export const unsyncTrainRunIntervalFromTemplate = (crudTrainRunIntervals, trainRunInterval) => {
  // Don't sync anymore
  const updated = {
    ...trainRunInterval,
    // Don't sync anymore
    isSyncedToTrainRouteInterval: false
  };
  return crudTrainRunIntervals.updateOrCreate(updated);
};

/**
 * Return's the given TrainRun's collection devices along with the vehicle they are on.
 *
 * @param trainRun
 * @returns {[Object]} Objects keyed by collectionDevice and vehicle
 */
export const trainRunCollectionDevices = trainRun => {
  // Send the dateTimeRange range to allow only collection devices that were in use during the dateTimeRange
  return formationCollectionDevices(trainRun.trainFormation, [trainRun.departureDatetime, trainRun.arrivalDatetime]);
};

/**
 * Returns the CollectionDevices of the given Formation
 * @param formation
 * @returns {[Object]} Objects keyed by collectionDevice and vehicle
 */
export const formationCollectionDevices = (formation, dateTimeRange = null) => {
  return chain(
    orderedVehicle => compact(map(
      vehicleCollectionDevice => {
        const serviceDateTimeRange = propOr(null, 'serviceDatetimeRange', vehicleCollectionDevice);
        // If serviceDateTimeRange and dateTimeRange are defined, make sure they overlap
        const overlappingDateRange = ifElse(
          all(identity),
          ranges => areIntervalsOverlapping(
            ...map(range => {
              return dateRangePairToObj(range);
            }, ranges)
          ),
          always(true)
        )([serviceDateTimeRange, dateTimeRange]);
        return overlappingDateRange ? {
          vehicle: orderedVehicle.vehicle,
          collectionDevice: vehicleCollectionDevice.collectionDevice
        } : null;
      },
      strPathOr([], 'vehicle.vehicleCollectionDevices', orderedVehicle)
    )),
    formation.orderedVehicles
  );
};
export const trainRunIntervalCollectionDevices = trainRunInterval => {
  return trainRunCollectionDevices(trainRunInterval.trainRun);
};
/**
 * Clears imuPoints from the userTrainRunInterval
 * @param userTrainRunInterval
 * @returns {Object} The userTrainRunInterval with the imuPoints null
 */
export const clearUserTrainRunIntervalCollectionDevices = userTrainRunInterval => {
  return over(
    lensPath(['trainRunInterval', 'trainRun', 'formation', 'orderedVehicles']),
    orderedVehicles => {
      return map(
        orderedVehicle => {
          return over(
            lensPath(['vehicle', 'vehicleCollectionDevices']),
            vehicleCollectionDevices => {
              return map(
                vehicleCollectionDevice => {
                  return set(
                    lensPath(['collectionDevice', 'imuPoints']),
                    null,
                    vehicleCollectionDevice
                  );
                }, vehicleCollectionDevices
              );
            },
            orderedVehicle
          );
        },
        orderedVehicles
      );
    },
    userTrainRunInterval
  );
};

/**
 * Resolves the tab of the Train App based on a Location from useLocation
 * @param location
 * @return {string}
 */
export const resolveTabFromLocation = location => {
  const str = location.pathname;
  return (str.match(/\//g) || []).length === 3 ?
    str.split('/').pop() :
    str.substring(str.lastIndexOf('/train/') + 7, str.lastIndexOf('/'));
};