import React, { Suspense, useEffect } from 'react';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';
import ReactDom from 'react-dom/client';
import Loading from 'components/loading/index.js';
import CemitApp from 'components/apps/cemitAppComponents/CemitApp.js';
import TrainSWRContainer from 'pages/trainApp/api/TrainSWRContainer.js';
import { i18nTask } from '@rescapes/translation';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns/index.js';

import { HTML5Backend } from 'react-dnd-html5-backend';
import { I18nextProvider } from 'react-i18next';
import locale from 'date-fns/locale/en-GB/index.js';
import theme from 'theme/theme.ts';

import ErrorBoundary from './ErrorBoundary.js';
import Authentication from './pages/auth/Authentication.js';
import ProfilePage from './pages/profile/profilePage.js';
import resources from './resources.js';
import { ThemeProvider } from '@mui/material';
import { sentryInit } from 'monitoring/sentry.js';
import { cemitLoggingAppInit } from 'monitoring/cemitLogging.js';
import { APPS_PATH } from 'appConfigs/appConfig.js';
import AppDependency from 'async/cemitAppAsync/dependencies/AppDependency.js';
import ProtectedRouteDependency from 'pages/protectedRoute/ProtectedRouteDependency.js';
import { DndWrapper } from 'utils/dragAndDrop/DnDWrapper.js';
import TrainContainer from 'pages/trainApp/TrainContainer.js';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider.js';

if (FEATURE_SENTRY) {
  sentryInit();
}
Error.stackTraceLimit = 100;
if (DEBUG) {
  cemitLoggingAppInit();
}


const AppIndex = ({ i18n }) => {
  const abortController = new AbortController();
  useEffect(() => {
    // TODO I don't know what the point of this is https://developer.mozilla.org/en-US/docs/Web/API/AbortController
    return () => abortController.abort();
  }, []);
  return <TrainSWRContainer abortController={abortController}>
    <ThemeProvider theme={theme}>
      <I18nextProvider i18n={i18n}>
        {/*
     Start the dependency chain, which performs I/O and renders the
     TrainComponent with all configured, I/O, and derived props
    */}
        <AppDependency clientChildren={clientDependentComponents} />
      </I18nextProvider>
    </ThemeProvider>
  </TrainSWRContainer>;
};


const clientDependentComponents = ({ appProps, organizationProps }) => {
  return <ErrorBoundary>
    {/* Provide the MUI theme. This is by all componets for styling */}

    {/* TODO We're not using Suspense as intended. Fix or remove */}
    <Suspense fallback={<Loading />}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale} >
        {/* I believe that the DndWrapper needs this div */}
        <div id='default'>
          {/* Adds Drag and Drop support */}
          <DndWrapper id='default' backend={HTML5Backend}>
            <Routes>
              {/* Top level Route */}
              <Route path='/*' element={
                <ProtectedRouteDependency {...{ appProps, organizationProps }} >
                  <ProtectedRouteDependentComponents {...{ appProps, organizationProps }} />
                </ProtectedRouteDependency>
              }
              />
              {/* If we don't match /*, use Authentication, which checks app access and navigates
                to a default page if authenticated or presents a login screen if not */}
              <Route path='/' element={
                <Authentication />
              } />
            </Routes>
          </DndWrapper>
        </div>
      </LocalizationProvider>
    </Suspense>
  </ErrorBoundary>;
};

// Components that depend on ProtectedRoute authentication
const ProtectedRouteDependentComponents = ({ appProps, organizationProps }) => {
  return <CemitApp {...{ appProps, organizationProps }} >
    <Routes>
      {/* App level Route */}
      <Route path={`${APPS_PATH}/*`}>
        <Route path='index/*' element={<TrainContainer {...{ appProps, organizationProps }} />} />
        <Route path='train/*' element={<TrainContainer {...{ appProps, organizationProps }} />} />
        <Route path='profile' element={<ProfilePage {...{ appProps, organizationProps }} />} />
      </Route>
    </Routes>
    <Outlet />
  </CemitApp>;
};

function renderApp(i18n) {
  const root = ReactDom.createRoot(document.getElementById('app'));

  root.render(
    <BrowserRouter>
      <AppIndex i18n={i18n} />
    </BrowserRouter>
  );
}

// When the i18n is initialized, pass it to render
i18nTask(resources).run().promise().then(i18n => {
    renderApp(i18n);
  }
);

if ('serviceWorker' in navigator) {
  if (FEATURE_SERVICEWORKER) {
    if (DEBUG) {
      console.log('ServiceWorker installation started');
    }
    window.addEventListener('load', () => {
      navigator.serviceWorker.register(new URL('./sw.ts', import.meta.url)).then(registration => {
        if (DEBUG && console && console.log) {
          console.log('ServiceWorker registered: ', registration);
        }
      }).catch(registrationError => {
        if (DEBUG && console && console.log) {
          console.log('ServiceWorker registration failed: ', registrationError);
        }
      });
    });
  } else {
    let wasRegistered = false;
    navigator.serviceWorker.getRegistrations().then(function(registrations) {
      for (let registration of registrations) {
        registration.unregister();
        wasRegistered = true;
      }
    });
    if (DEBUG && console && console.log && wasRegistered) {
      console.log('ServiceWorker removed');
    }
  }
}
