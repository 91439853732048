import { TrainRunIntervalBarCustomDragLayer } from 'components/apps/trainAppComponents/trainRunChooserComponents/TrainRunIntervalBarCustomDragLayer.js';
import { TrainRunIntervalBar } from 'components/apps/trainAppComponents/trainRunChooserComponents/TrainRunIntervalBar.js';
import { STATION_DOT_HEIGHT } from 'theme/train/trainThemeConstants.js';
import { toArrayIfNot } from '@rescapes/ramda';
import { TRAIN_RUN_INTERVAL_BAR_HEIGHT } from 'appConfigs/trainConfigs/trainRunConfig.js';
import { ItemTypes } from 'appConfigs/trainConfigs/trainDragAndDropConfig.js';
import { computedIntervalBarPosition } from 'appUtils/trainAppUtils/trainRunIntervalUtils.js';

/**
 * This is the component that moves when we are dragging. It draws the same TrainRunIntervalBar
 as is drawn below in DraggableTrainRunIntervalBar but it's position and width changes according
 to the user's moverDrag and expansion/contraction of the edges of the bar
 * @param {Object} isTrainRouteLine
 * @param {Object} trainRun The TrainRun
 * @param {Boolean} isUserScope if true, then the objects being dragged our UserTrainRunIntervals, if false
 * they are TrainRunIntervals
 * @param {Boolean} spaceGeospatially
 * @param {Object} limitedDistanceRange
 * @param {Number} parentWidth Parent width in pixels
 * @param {Function} resolveOffsetLeft Function that expects a start distance or end distance and resolves the offset of the
 * moverDrag layer's left and width properties
 * @returns {JSX.Element}
 * @constructor
 */
const TrainRunIntervalBarMoverDragLayer = (
  {
    isTrainRouteLine,
    trainRun,
    isUserScope,
    spaceGeospatially,
    limitedDistanceRange,
    resolveOffsetLeft,
    parentWidth
  }) => {
  return <TrainRunIntervalBarCustomDragLayer
    handleItemType={ItemTypes.TRAIN_RUN_INTERVAL_BAR_MOVER}
    isTrainRouteLine={isTrainRouteLine}
    trainRun={trainRun}
    isUserScope={isUserScope}
    constrainY={true}
    render={
      /**
       * The render function. dragOffset is continuously recalculated as the difference of {x, y} from the
       * initial position of the element. We constrain y, so only x changes
       * @param sx
       * @param trainRunInterval
       * @param dragOffset
       * @returns {JSX.Element}
       */
        ({ sx, trainRunInterval, dragOffset }) => {
        const id = `dragComponent-${trainRunInterval.id}-${isTrainRouteLine ? 'isTrainRoute' : trainRun?.id || 'undefined'}`;
        const percentOrPixel = prop => `${prop}${spaceGeospatially ? '%' : 'px'}`;
        const _computedIntervalBarLeftPosition = (value, xOffset) => computedIntervalBarPosition({
          trainRunInterval,
          spaceGeospatially,
          limitedDistanceRange,
          resolveOffsetLeft,
          parentWidth
        }, value, xOffset);
        const left = _computedIntervalBarLeftPosition(trainRunInterval.distanceRange.start, (dragOffset?.x || 0));
        const width = _computedIntervalBarLeftPosition(trainRunInterval.distanceRange.end, (dragOffset?.x || 0)) - left;
        return <TrainRunIntervalBar key={id} {...{
          trainRunInterval,
          id,
          isDragLayer: true,
          sx: [
            {
              left: percentOrPixel(left),
              width: percentOrPixel(width),
              height: TRAIN_RUN_INTERVAL_BAR_HEIGHT,
              top: `calc((${STATION_DOT_HEIGHT} / 2) - (${TRAIN_RUN_INTERVAL_BAR_HEIGHT} / 2))`
            }, ...toArrayIfNot(sx)
          ]
        }}
        />;
      }
    } />;
};
export default TrainRunIntervalBarMoverDragLayer;