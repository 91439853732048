import { IconButton, Tooltip } from '@mui/material';
import { ErrorOutline, WarningAmber } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { equals, head, lensPath, map, set } from 'ramda';
import { ERROR_NO_IMU_POINTS } from 'lib/fetch/cemitApi/config.js';

/**
 * Shows that an error occurred downloading data for hthe UserTrainRunInterval from the crudUserTrainRunIntervals
 * Hitting the button attempts to reload the data
 * @param isSelectedTrainRuns
 * @param userTrainRunIntervals
 * @param crudUserTrainRunIntervals
 * @param [disabled] Default false
 * @returns {JSX.Element}
 * @constructor
 */
const UserTrainRunErrorButton = ({ userTrainRunIntervals, crudUserTrainRunIntervals, disabled=false }) => {
  const { t } = useTranslation();
  const error = head(userTrainRunIntervals).trainRunInterval.trainRun.error;
  const noImuPointsError = equals(error, ERROR_NO_IMU_POINTS);
  const Icon = noImuPointsError ? WarningAmber : ErrorOutline;
  const props = {
    disabled,
    color: 'error',
    'aria-label': 'error',
    sx: {
      float: 'right', alignItems: 'flex-start', p: 0
    }
  };
  return <Tooltip arrow {...{
    title: t(error)
  }}>
    {
      noImuPointsError ?
        <Icon key='errorIcon' {...props} /> :
        <IconButton
          key='errorButton'
          {...{
            ...props,
            onClick: () => {
              // Set trainRun.retry
              crudUserTrainRunIntervals.updateOrCreateAll(
                map(
                  userTrainRunInterval => {
                    return set(
                      lensPath(['trainRunInterval', 'trainRun', 'retry']),
                      true,
                      userTrainRunInterval
                    );
                  },
                  userTrainRunIntervals)
              );
            }
          }}
        >
          <Icon />
        </IconButton>
    }
  </Tooltip>;
};
export default UserTrainRunErrorButton;