import { useEffect, useRef, useState } from 'react';
import { SketchPicker } from 'react-color';
import { Box, IconButton, Tooltip } from '@mui/material';
import { PaletteOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const ColorPicker = ({ color: _color, handleChangeComplete, disabled = false }) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const timer = useRef()
  const [color, setColor] = useState(_color);
  useEffect(() => {
    if (_color !== color) {
      setColor(_color)
    }
  }, [_color])
  const { t } = useTranslation();
  const handleClick = e => {
    clearTimeout(timer.current);
    if (e.detail === 1) {
      timer.current = setTimeout(() => {
        setDisplayColorPicker(!displayColorPicker)
      }, 200)
    } else if (e.detail === 2) {
      handleChangeComplete(null)
    }
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
    handleChangeComplete(color);
  };

  const handleChange = color => {
    setColor(color.hex);
  };

  return <Box>
    <Tooltip arrow title={t('chooseColor')}>
      <IconButton {...{
        key: 'colorButton',
        sx: {
         p:0,
        },
        disabled,
        'aria-label': 'colorButton',
        onClick: handleClick
      }}
      >
        <PaletteOutlined {...{
          variant: 'tiny',
          sx: { color}
        }}>

        </PaletteOutlined>
      </IconButton>
    </Tooltip>

    {displayColorPicker ? <Box {...{
      sx: {
        position: 'absolute',
        zIndex: 10000
      }
    }}>
      <Box {...{
        sx: {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px'
        }, onClick: handleClose
      }} />
      <SketchPicker {...{
        color,
        onChange: handleChange
      }} />
    </Box> : null}
  </Box>;
};

export default ColorPicker;