import React, {useState} from 'react';

import RideComfortPage from "../../../pages/trainApp/rideComfort/RideComfortPage.tsx";
import {DependencyPropsWithDerived} from "visualizer-railbed/railbedTypes/propTypes/dependencyProps";
import {
  useUpdateAlertLayerForRideComfortTrainMap,
  UseUpdate3dForRideComfortTrainMapHookProps
} from "../../rideComfortAsync/rideComfortLayersHooks.ts";
import {unlessLoadingProps} from "../../../visualizer-railbed/railbedUtils/componentLogic/loadingUtils.ts";
import TrainRunDependency from "../dependencies/TrainRunDepedency.js";


/***
 * Loads/Updates MapData layers dependent on sensor data
 * Depends directly on TrainRunGroupSensor props in trainProps.trainRunGroupProps.geojsonProps and TrainMap props
 * @param appProps
 * @param organizationProps
 * @param trainProps
 * @param mapProps
 * @param children
 * @return {*}
 * @constructor
 */
const RideComfortMapSensorLayerDependency = ({ appProps, organizationProps, trainProps, mapProps }: DependencyPropsWithDerived) => {

  const loading = mapProps.mapLayerProps.loading
  const [are3dLayersUpdating, set3dLayersUpdating] = useState(false);

  // The 3d layers are dependent on sensor data
  const dataThresholds = trainProps.trainFormationProps.dataThresholds;

  const props: any | DependencyPropsWithDerived & {
    hookProps: UseUpdate3dForRideComfortTrainMapHookProps
  } = unlessLoadingProps(
    loading,
    () => ({
      appProps,
      organizationProps,
      trainProps,
      mapProps,
      hookProps: {
        trainMap: mapProps.trainMap,
        featurePropPath: 'value',
        dataColumns3DLayerAreVisible: true,
        extrude: false,
        dataThresholds,
        set3dLayersUpdating
      } as UseUpdate3dForRideComfortTrainMapHookProps
    }));

  useUpdateAlertLayerForRideComfortTrainMap(
    {
      loading: loading,
      ...props
    }
  );

  return <TrainRunDependency {...{
      appProps,
      organizationProps,
      mapProps: {
          trainMapSensorLayerProps: {
              // Depends directly on TrainRunGroupSensor props in trainProps.trainRunGroupProps.sensorProps
              // and TrainMap in mapProps
              loading: mapProps.loading,
              are3dLayersUpdating, set3dLayersUpdating,
          },
          ...mapProps
      },
      trainProps,
      mapProps
  }}/>

};
RideComfortMapSensorLayerDependency.displayName = 'RideComfortMapSensorLayerDependency';
export default RideComfortMapSensorLayerDependency;
