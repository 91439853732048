import React from 'react';
import { Stack } from '@mui/material';
import TrainRunLineContainer from 'components/apps/trainAppComponents/trainLineComponents/TrainRunLineContainer.js';
import TrainDataPathStackedChartContainer
  from 'components/apps/trainAppComponents/trainChartComponents/TrainDataPathStackedChartContainer.js';
import ChartAndMapDataContainer from 'components/charts/stackedChart/ChartAndMapDataContainer.js';
import { pick } from 'ramda';

/**
 *  Left side of page presenting a list of countries and the railroads available for each country
 * @param appProps
 * @param organizationProps
 * @param trainProps
 * @param dataPathsConfigs
 * @param {Object} componentProps
 * @param {[Object]} componentProps.dataPathsConfigs List of dataPathsConfigs. We show a chart for each
 * @param {[Object]} componentProps.customControls Optional list of custom controls to show above the
 * @param {[Object]} componentProps.trainDataPathStackedChartRefWidth
 * @param {[Object]} componentProps.trainDataPathStackedChartRef
 * @returns {JSX.Element}
 * @constructor
 */
const TrainChartDisplay = (
  {
    appProps,
    organizationProps,
    trainProps,
    componentProps: {
      loading,
      referenceStopLookup,
      dataPathsConfigs,
      trainDataPathStackedChartRefWidth,
      trainDataPathStackedChartRef,
      panelSize
    }
  }) => {

  // The chart y-axis width. Used to make the y-axis label not overlap
  // the ticks and to get proper alignment with the TrainRunLine
  const yAxisWidth = 75;
  // This approximation of the chart position based on yAxisWidth and a bit of buffer
  // to make sure the stops of TrainRunLine match the chart points
  const trainRunLayoutProps = {
    left: `${yAxisWidth - 5}px`,
    width: `${(trainDataPathStackedChartRefWidth || 0) - (yAxisWidth + 10)}px`,
    height: '38px'
  };

  return (
    <Stack spacing={2} sx={{
      marginTop: '10px',
      width: '100%',
      height: '100%'
    }}>{
      [
        // This is the adjustable bar that shows the full route
        trainDataPathStackedChartRefWidth &&
        <TrainRunLineContainer {...{
          key: 'trainRunLineAdjustable',
          appProps,
          organizationProps,
          trainProps,
          componentProps: {
            showTrainRunIntervalBars: true,
            spaceGeospatially: true,
            onlyStopsNearInterval: false,
            isTrainRouteLine: true,
            isAggregate: true,
            isUserTrainRunLine: false,
            panelSize
          },
          sx: { ...trainRunLayoutProps, minHeight: '80px', maxHeight: '80px' }
        }}
        />,
        <ChartAndMapDataContainer key='chartAndMapDataContainer' {...{
          trainProps,
          componentProps: {
            userTrainRunIntervals: trainProps.userTrainRunIntervalProps.activeUserTrainRunIntervalsWithoutErrors,
            dataPathsConfigs,
            referenceStopLookup,
            ...pick(['mostRecentTooltipPayload', 'setMostRecentTooltipPayload'],
              trainProps.userTrainRunIntervalProps.sensorProps
            )
          }
        }} />,
        <TrainDataPathStackedChartContainer key='trainDataPathStackedChart' {...{
          ref: trainDataPathStackedChartRef,
          appProps,
          organizationProps,
          trainProps,
          componentProps: {
            yAxisWidth,
            dataPathsConfigs,
            trainDataPathStackedChartRefWidth
          }
        }}
        />,
        // This is the read-only bar that shows the current range of the charts
        trainDataPathStackedChartRefWidth &&
        <TrainRunLineContainer {...{
          key: 'trainRunLineReadonly',
          appProps,
          organizationProps,
          trainProps,
          componentProps: {
            // Limit the distanceRange to what the user has chosen
            limitedDistanceRange: loading ? null : trainProps.trainRouteProps.trainRouteAggregateInterval.distanceRange,
            showTrainRunIntervalBars: false,
            spaceGeospatially: true,
            onlyStopsNearInterval: false,
            isTrainRouteLine: true,
            isAggregate: true,
            isUserTrainRunLine: false
          },
          sx: { ...trainRunLayoutProps, minHeight: '70px' }
        }}
        />
      ]
    }
    </Stack>
  );
};

TrainChartDisplay.propTypes = {};

export default TrainChartDisplay;
