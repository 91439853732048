import Button from '@mui/material/Button/index.js';
import Menu from '@mui/material/Menu/index.js';
import MenuItem from '@mui/material/MenuItem/index.js';
import { useState } from 'react';
import { equals, is, map } from 'ramda';
import TrainRouteOrGroupLabel
  from 'components/apps/trainAppComponents/trainRouteChooserComponents/TrainRouteOrGroupLabel.js';

/**
 * Displays the label for trainRouteOrGroup as a button, representing the chosen TrainRoute or TrainRouteGroup.
 * Clicking the button opens a menu to allow selecting of the other TrainRoute or TrainRouteGroups
 * @param trainRoutes All availalbe TrainRoutes and TrainRouteGroups
 * @param chooseTrainRouteOrGroup Change the current TrainRoute or TrainRouteGroup
 * @param trainRouteOrGroup The currently chosen TrainRoute or TrainRouteGroup
 * @returns {JSX.Element}
 * @constructor
 */
export const TrainRouteMenu = ({ trainRoutes, chooseTrainRouteOrGroup, trainRouteOrGroup }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e, trainRoute) => {
    setAnchorEl(null);
    // if trainRoute comes in as the string 'backDrop',
    // it means the user clicked outside the menu
    if (!is(String, trainRoute)) {
      chooseTrainRouteOrGroup(trainRoute);
    }
  };
  return <div>
    <Button
      key='button'
      id='train-route-button'
      color='secondary'
      aria-controls={open ? 'train-route-positioned-menu' : undefined}
      aria-haspopup='true'
      aria-expanded={open ? 'true' : undefined}
      onClick={handleClick}
    >
      <TrainRouteOrGroupLabel {...{ trainRouteOrGroup }} />
    </Button>
    <Menu
      key='menu'
      id='train-route-positioned-menu'
      aria-labelledby='train-route-button'
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
    >
      {
        // Display each TrainRoute or TrainRouteGroup's label
        map(aTrainRouteOrGroup => {
            return <MenuItem
              key={aTrainRouteOrGroup.id}
              {...{
                disabled: equals(trainRouteOrGroup, aTrainRouteOrGroup),
                onClick: e => handleClose(e, aTrainRouteOrGroup)
              }}>
              <TrainRouteOrGroupLabel {...{ trainRouteOrGroup: aTrainRouteOrGroup }} />
            </MenuItem>;
          },
          trainRoutes
        )
      }
    </Menu>
  </div>;
};