import {Stack, Typography} from "@mui/material";
import React from "react";
import {tempColors} from "../../../../theme/tempColors.ts";
import RideComfortTrainComponent from "./RideComfortTrainComponent.tsx";
import {addIndex, map} from "ramda";
import {AppProps} from "../../../../visualizer-railbed/types/propTypes/appProps";
import {GetTotalCount, QueryRideComfortApi} from "../../../../types/rideComfort/rideComfortFunctions";
import {RideComfortHeatMapDatum} from "../../../../types/rideComfort/rideComfortHeatMapDatum";
import {Setter} from "../../../../visualizer-railbed/types/hookHelpers/setter";
import {RideComfortGauge} from "../../../../types/rideComfort/rideComfortGauge";
import {RideComfortTrainInfo} from "../../../../types/rideComfort/rideComfortTrainInfo";
import {RideComfortAlertLevelDatum} from "../../../../types/rideComfort/rideComfortMapData";
import {RideComfortAlertType} from "../../../../types/rideComfort/rideComfortAlertType";
import {RideComfortIntervalDescription} from "../../../../types/rideComfort/rideComfortintervalDescription";
import RideComfortExportExcelButtonContainer
  from "./rideComfortExportComponents/RideComfortExportExcelButtonContainer.tsx";
import {TrainProps} from "../../../../visualizer-railbed/railbedTypes/propTypes/trainProps";

type RideComfortTrainStatusComponentProps = {
  appProps: AppProps,
  trainProps: TrainProps,
  trainInfos: RideComfortTrainInfo[],
  trainInfo: RideComfortTrainInfo,
  setTrainInfo: Setter<RideComfortTrainInfo>,
  queryRideComfortApiAndMutate: QueryRideComfortApi,
  date: Date,
  getTotalCount: GetTotalCount,
  setRideComfortGaugeByTimePeriod: Setter<RideComfortGauge>,
  setHeatMapData: Setter<RideComfortHeatMapDatum>,
  totalMapDataValues: Setter<RideComfortAlertLevelDatum>,
  intervalDescription: RideComfortIntervalDescription,
  rideComfortAlertType: RideComfortAlertType
}
const RideComfortTrainsComponent = (
  {
    appProps,
    trainProps,
    trainInfos,
    trainInfo,
    setTrainInfo,
    queryRideComfortApiAndMutate,
    date,
    getTotalCount,
    setRideComfortGaugeByTimePeriod,
    setHeatMapData,
    totalMapDataValues,
    intervalDescription,
    rideComfortAlertType
  }: RideComfortTrainStatusComponentProps) => {
  return <Stack {...{
    key: 'trainsComponent',
    spacing: 1,
    sx: {
      minWidth: 0, minHeight: 0, flex: 1, overflow: 'auto', position: 'relative',
      height: '100%', maxHeight: '100%', overflowY: 'auto',
      padding: 1,
    }
  }} >
    <Typography {...{
      key: 'title',
      variant: 'heading',
      sx: {
        color: tempColors.white,
        fontFamily: 'Roboto, Helvetica',
        fontWeight: 500,
        fontSize: '24px',
      }
    }}>Trains</Typography>

    <RideComfortExportExcelButtonContainer {...{
      key: 'rideComfortExportExcelButtonContainer',
      trainProps
    }} />

    <Stack {...{
      key: 'content',
      spacing: 2,
      sx: {
        minWidth: 0, minHeight: 0, flex: 1, overflow: 'auto', position: 'relative',
        justifyContent: 'flex-start'
      }
    }}>
      {
        addIndex(map)((_trainInfo: RideComfortTrainInfo, index: number) => {
          return <RideComfortTrainComponent {...{
            key: index.toString(),
            appProps,
            trainInfo: _trainInfo,
            selectedTrainInfo: trainInfo,
            setTrainInfo,
            queryRideComfortApiAndMutate,
            date,
            getTotalCount,
            setRideComfortGaugeByTimePeriod,
            setHeatMapData,
            totalMapDataValues,
            intervalDescription,
            rideComfortAlertType
          }} />
        }, trainInfos || [])
      }
    </Stack>
  </Stack>
}
export default RideComfortTrainsComponent
