import React, { memo } from 'react';
import { Stack, Typography } from '@mui/material';
import { always, cond, length, lensPath, map as mapR, set, T } from 'ramda';
import { useTranslation } from 'react-i18next';
import TrainRunContainer from 'components/apps/trainAppComponents/trainRunChooserComponents/TrainRunContainer.js';
import LoaderWithText from 'components/loading/LoaderWithText.js';
import { sortTrainRuns } from 'appUtils/trainAppUtils/trainRunUtils.js';

/**
 * The TrainOverview card for the TrainApp application
 * @param {Object} props
 * @param {Object} props.appProps
 *  @param {[Object]} props.trainRuns Runs of trains, meaning a run of the train from an origin to a destination
 * along with the cdcData that has been collected for that run, if any
 * @param {[Object]} props.formations
 * @param {Object} props.selectedTrain
 * @param {Object} props.hoverTrainRun
 * @param {Function} props.setHoverTrain
 * @param {Function} props.setSelectedTrain
 * @param {Object} props.dateInterval Current date range of service to show on the schedule
 * @param {Function} props.setDateInterval Setter hook for dateRange
 * A service currently consists of a {train: train id, distanceRange: the part of the route of interest}
 * @returns {JSX.Element} A react-table based table
 */
const TrainOverviewSchedule = memo((
  {
    appProps,
    organizationProps,
    trainProps,
    componentProps
  }) => {

  const { t } = useTranslation();
  const trainRuns = sortTrainRuns(trainProps.trainRunProps.orderedTrainRunsWithinFilter || []);

  return <Stack
    spacing={1}
    sx={{
      height: '100%',
      maxHeight: '100%',
      overflowY: 'auto',
      bgColor: 'background.default',
      justify: 'center'
    }}>
    {
      cond([
        [always(trainProps.trainRunIntervalProps.loading), () => {
          return <Stack sx={{
            width: '100%',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
            <LoaderWithText {...{
              text: 'loadingTrainOverviewSchedule',
              loadingExplanation: trainProps.trainRunIntervalProps.loadingExplanation
            }} />
          </Stack>;
        }],
        [length, mapR(
          trainRun => {
            const trainPropsUpdated = set(lensPath(['trainRunProps', 'trainRun']), trainRun, trainProps);
            return <TrainRunContainer key={trainRun.id} {...{
              appProps,
              organizationProps,
              // Add the TrainRun to the trainProps
              trainProps: trainPropsUpdated,
              componentProps
            }} />;
          }
        )],
        [T, () => {
          return <Stack sx={{
            width: '100%',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
            <Typography>{t('noTrainRuns')}</Typography>
          </Stack>;
        }]
      ])(trainRuns)
    }
  </Stack>;
});
TrainOverviewSchedule.displayName = 'TrainOverviewSchedule';
export default TrainOverviewSchedule;
