import React, {useEffect, useMemo, useState} from "react";
import {durationText} from "../../../appUtils/rideComfortUtils/timeUtils.ts";
import {head} from "ramda";
import {queryRideComfortApiAndMutate} from "../../rideComfortAsync/rideComfortQueries.ts";
import {totalMapDataValues} from "../../../appUtils/rideComfortUtils/setMapDataUtils.ts";
import {getTotalCount} from "../../../appUtils/rideComfortUtils/graphqlResultUtils.ts";
import {unlessLoadingProps} from "visualizer-railbed/railbedUtils/componentLogic/loadingUtils.ts";
import {RideComfortIntervalDescription} from "../../../types/rideComfort/rideComfortintervalDescription";
import {RideComfortAlertType} from "../../../types/rideComfort/rideComfortAlertType";
import {RideComfortAlertTypeKey} from "../../../types/rideComfort/rideComfortAlertTypeKey.ts";
import {RideComfortTrainInfo} from "../../../types/rideComfort/rideComfortTrainInfo";
import {Perhaps} from "../../../visualizer-railbed/railbedTypes/typeHelpers/perhaps";
import {headOrThrow} from "../../../visualizer-railbed/railbedUtils/functional/functionalUtils.ts";
import RideComfortMapDependency from "./RideComfortMapDependency.tsx";
import {RideComfortHeatMapDatum} from "../../../types/rideComfort/rideComfortHeatMapDatum";
import {RideComfortOrganization} from "../../../types/rideComfort/rideComfortOrganization";
import {TrainDependencyPropsWithRideComfortProps} from "../../../types/rideComfort/trainPropsWithRideComfortProps";
import {RideComfortProps} from "../../../types/rideComfort/rideComfortProps.ts";
import {useNotLoadingEffect} from "../../../visualizer-railbed/railbedUtils/hooks/useMemoHooks.ts";
import {RideComfortGaugeByTimePeriod} from "../../../types/rideComfort/rideComfortGauge";
import {PeriodEnum} from "../../../types/rideComfort/rideComfortAlertLevel.ts";
import {zoomMapToCriticalAlerts} from "../../rideComfortAsync/rideComfortMapInteraction.ts";

const RideComfortDependency = ({appProps, organizationProps, trainProps}: TrainDependencyPropsWithRideComfortProps) => {
    const loading = trainProps?.dateProps?.loading
    const [chosenDateTime, setChosenDateTime] = useState(new Date())
    const [ rideComfortGraphqlDataLoading, setRideComfortGraphqlDataLoading] = useState<boolean>(false)
    const {date, trains}: { date: Perhaps<Date>, trains: Perhaps<RideComfortTrainInfo[]> } = unlessLoadingProps(loading, () => {
        const organizationConfig: RideComfortOrganization = organizationProps.organization as RideComfortOrganization
        const trains: RideComfortTrainInfo[] = organizationConfig.trains
        const date: Date = chosenDateTime
        return {date, trains}
    }) || {date: undefined, trains: undefined}
    const [heatMapData, setHeatMapData] = useState<RideComfortHeatMapDatum[]>([]);
    const [rideComfortGaugeByTimePeriod, setRideComfortGaugeByTimePeriod] = useState<RideComfortGaugeByTimePeriod>({
        today: {
            normal: {value: 0, count: 0},
            warning: {value: 0, count: 0},
            critical: {value: 0, count: 0}
        },
        week: {
            normal: {value: 0},
            warning: {value: 0},
            critical: {value: 0}
        },
        month: {
            normal: {value: 0},
            warning: {value: 0},
            critical: {value: 0}
        }
    });

    const intervalDescriptions: RideComfortIntervalDescription[] = useMemo<RideComfortIntervalDescription[]>(
        () => [
            {label: 'Long', durationText: durationText(24 * 60), duration: 24 * 60}, // Full day, week, month
            {label: 'Normal', durationText: durationText(60), duration: 60}, // Normal hour week, month
            {label: 'Short', durationText: durationText(15), duration: 15}, // 15 minutes leading up to selected time
        ],
        []
    )
    const [intervalDescription, setIntervalDescription] = useState<RideComfortIntervalDescription>(head(intervalDescriptions))

    const rideComfortAlertTypes = useMemo<RideComfortAlertType[]>(
        () => [
            {label: 'Ride Comfort', alertPointKey: RideComfortAlertTypeKey.alertPointId},
            {label: 'Train Alerts', alertPointKey: RideComfortAlertTypeKey.trainPointId}
        ],
        []
    )
    const [rideComfortAlertType, setRideComfortAlertType] = useState<RideComfortAlertType>(headOrThrow(rideComfortAlertTypes))
    const [trainInfo, setTrainInfo] = useState<Perhaps<RideComfortTrainInfo>>(undefined)
    const [rideComfortTimePeriodForMap, setRideComfortTimePeriodForMap] = useState<keyof RideComfortGaugeByTimePeriod>(PeriodEnum.today)

    // Initialize trainInfo the first when trains are loaded
    useEffect(() => {
        if (trains && !trainInfo) {
            setTrainInfo(head(trains))
        }
    }, [trains, trainInfo])

    useNotLoadingEffect(loading, () => {
        async function fetchData(): Promise<void> {
            if (date && trainInfo && intervalDescription) {
                setRideComfortGraphqlDataLoading(true)
                await queryRideComfortApiAndMutate(
                    trainInfo,
                    date,
                    getTotalCount,
                    setRideComfortGaugeByTimePeriod,
                    setHeatMapData,
                    totalMapDataValues,
                    intervalDescription,
                    rideComfortAlertType,
                    rideComfortTimePeriodForMap,
                );
                setRideComfortGraphqlDataLoading(false)
            }
        }
        fetchData()
    }, [
        trainInfo,
        date,
        intervalDescription,
        rideComfortAlertType,
        rideComfortTimePeriodForMap
    ]);

    const localPropsNotReady = !(!loading && trains && chosenDateTime && intervalDescription && date && rideComfortGaugeByTimePeriod)
    return <RideComfortMapDependency {...{
        appProps,
        organizationProps,
        trainProps: {
            ...trainProps,
            rideComfortProps: {
                loading: loading || localPropsNotReady,
                trainInfos: trains,
                chosenDateTime,
                setChosenDateTime,
                intervalDescription,
                setIntervalDescription,
                intervalDescriptions,
                rideComfortAlertType,
                setRideComfortAlertType,
                rideComfortAlertTypes,
                heatMapData,
                setHeatMapData,
                trainInfo,
                setTrainInfo,
                date,
                rideComfortGaugeByTimePeriod,
                setRideComfortGaugeByTimePeriod,
                getTotalCount,
                totalMapDataValues,
                queryRideComfortApiAndMutate,
                rideComfortTimePeriodForMap,
                setRideComfortTimePeriodForMap,
                zoomMapToCriticalAlerts,
                rideComfortGraphqlDataLoading,
                setRideComfortGraphqlDataLoading
            } as RideComfortProps
        }
    }} />
}
export default RideComfortDependency
