import { all, compose, descend, find, head, includes, length, map, prop, sortWith } from 'ramda';

import { useNotLoadingEffect } from 'utils/hooks/useMemoHooks.js';
import { scheduledStopPointsOfTrainRoute } from 'appUtils/trainAppUtils/trainRouteUtils.js';
import { idsEqual } from 'utils/functional/functionalUtils.js';
import { useMemo } from 'react';
import { trainRouteDerivatives } from 'appUtils/trainAppUtils/trainRouteGeojsonUtils.js';

/**
 * If null, sets trainRoute to the first of TrainRouteGroup or failing that first trainRoute
 * @param {Boolean} loading Do nothing if true
 * @param [{Object}] trainRoutes The Routes
 * @param {Object} trainRoute The current Route if any
 * @param {Function} setTrainRoute The setter
 */
export const useSetTrainRoute = ({ loading, trainRouteGroups, trainRoutes, trainRoute, setTrainRoute }) => {
  useNotLoadingEffect(loading,
    () => {
      // Set TrainRoute to that with the most stop of the TrainRoutes if it's not set or not one of the trainRoutes
      if (!trainRoute || !includes(trainRoute.id, map(prop('id'), [...trainRouteGroups, ...trainRoutes]))) {
        setTrainRoute(
          head(sortWith([descend(compose(length, scheduledStopPointsOfTrainRoute))], [...trainRouteGroups, ...trainRoutes]))
        );
      }
    },
    [trainRouteGroups, trainRoutes, trainRoute]
  );
};


/**
 * Calculates the distances along the Route to RoutePoints
 * Also generates derived track data by calling derivedTrackOfTrainRoute
 * All results are set on the TrainRoute
 * @param {Boolean} loading Do nothing if true
 * @param {Object} organization The client
 * @param trainRoutes The trainRoutes to create derivatives for
 * @param setTrainRoutes The TrainRoutes setter
 * @param setTrainRoute The TrainRoute setter
 * @returns {Object} An updated version of the TrainRoute with
 *  routeInterval,
 *  measureDistancesFrom: Name of Reference Station (e.g. 'Oslo S'),
 *  distance: total distance of the Route in kilometers
 * and route.pointsOnRoute[*].routePoint.routeDistance: distance to the route point in km from the start point of the route
 */
export const useCreateTrainRoutesDerivatives = (
  {
    loading,
    organization,
    trainRouteGroups,
    setTrainRouteGroups,
    trainRoutes,
    setTrainRoutes,
    trainRoute,
    setTrainRoute
  }) => {

  useNotLoadingEffect(loading,
    () => {
      if (all(trainRoute => trainRoute.trackData, trainRoutes))
        return;

      const updatedTrainRouteGroups = map(trainRouteGroup => {
          if (trainRouteGroup.trackData) {
            // Already created. This case probably won't happen unless
            // we have paginated loading of TrainRouteGroups
            return trainRouteGroup;
          }
          return trainRouteDerivatives({
            organization,
            trainRoute: trainRouteGroup
          });
        },
        trainRouteGroups
      );
      // Update the TrainRouteGroups
      setTrainRouteGroups(updatedTrainRouteGroups);

      // Update the TrainRoutes
      const updatedTrainRoutes = map(trainRoute => {
          if (trainRoute.trackData) {
            // Already created. This case probably won't happen unless
            // we have paginated loading of trainRoutes
            return trainRoute;
          }
          return trainRouteDerivatives({
            organization,
            trainRoute
          });
        },
        trainRoutes
      );
      // Update the TrainRoutes
      setTrainRoutes(updatedTrainRoutes);

      // Sync the current TrainRoute if any
      const currenTrainRoute = find(
        updatedTrainRoute => idsEqual(trainRoute, updatedTrainRoute),
        [...updatedTrainRouteGroups, ...updatedTrainRoutes]
      );
      setTrainRoute(currenTrainRoute);
    }, [trainRoutes, trainRouteGroups, trainRoute]);
};

/**
 * With useMemo gets the scheduledStopPoints of the TrainRoute and adds the routeDistance from the routePoint to each
 * @param {Object} trainRoute The TrainRoute instance
 * @returns {[Object]} list ScheduledStopPoints
 */
export const useMemoScheduledStopPointsOfTrainRoute = trainRoute => {
  return useMemo(() => {
      return scheduledStopPointsOfTrainRoute(trainRoute);
    },
    [trainRoute]
  );
};

/***
 * Deserializes a TrainRoute or TrainRoute group with just an id to the matching instance
 * @param loading
 * @param trainRoute
 * @param trainRoutes
 * @param trainRouteGroups
 * @param setTrainRoute
 */
export const useDeserializeTrainRouteInStorage = (
  {
    loading,
    trainRoute,
    trainRoutes,
    trainRouteGroups,
    setTrainRoute
  }) => {
  // The UserTrainRunIntervals of the current TrainRoute
  useNotLoadingEffect(loading || !trainRoute || !trainRoutes || !trainRouteGroups, () => {
    // If the trainRoute changes, set the setUserTrainRunIntervals to those in storage
    const matchingTrainRoute = find(
      trainRouteOrGroup => {
        return idsEqual(trainRoute, trainRouteOrGroup);
      },
      [...trainRoutes, ...trainRouteGroups]
    );
    if (!matchingTrainRoute) {
      console.warn(`No matching TrainRoute or TrainRouteGroup for value id in cache ${trainRoute.id}`)
      return
    }
    if (!idsEqual(trainRoute, matchingTrainRoute)) {
      setTrainRoute(matchingTrainRoute);
    }
  }, [trainRoutes, trainRouteGroups, trainRoute]);
};