import Menu from '@mui/material/Menu/index.js';
import MenuItem from '@mui/material/MenuItem/index.js';
import { useState } from 'react';
import { is, map, mergeRight, omit, range } from 'ramda';
import { IconButton } from '@mui/material';
import { CalendarToday } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { matchesDateRecurrence } from 'appUtils/trainAppUtils/trainFilterDateRecurrenceUtils.js';

/**
 * Component to choose from a reasonable choice of date recurrences
 * days are the seven days of the week, weekdays, and weekend
 * dates are the 31 calendar days
 * times are list of all departure times for the availalble  TrainRuns
 * @param handleAddDateRecurrenceToFilter
 * @returns {JSX.Element}
 * @constructor
 */
export const DateRecurrenceDaysMenu = ({ chosenDateRecurrences, handleAddDateRecurrenceToFilter }) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e, day) => {
    setAnchorEl(null);
    // if dateRecurrence comes in as the string 'backDrop',
    // it means the user clicked outside the menu
    if (!is(String, day)) {
      handleAddDateRecurrenceToFilter(omit(['label'], day));
    }
  };
  const days = map(mergeRight({type:'getDay'}), [
    { values: range(1, 6), label: t('dayWeekdays') },
    { values: [0, 6], label: t('dayWeekends') },
    ...map(day => ({ values: [day], label: t(`day${day}s`) }), range(0, 7))
  ]);
  return <div>
    <IconButton
      key='buttonDateRecurrenceDepartureTimesMenu'
      id='dateRecurrence-day-button'
      sx={{padding:0}}
      color='secondary'
      aria-controls={open ? 'dateRecurrence-day-positioned-menu' : undefined}
      aria-haspopup='true'
      aria-expanded={open ? 'true' : undefined}
      onClick={handleClick}
    >
      <CalendarToday />
    </IconButton>
    <Menu
      key='dateRecurrenceDayMenu'
      id='dateRecurrence-day-positioned-menu'
      aria-labelledby='dateRecurrence-day-button'
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
    >
      {
        // Create a menu item for weekdays, weekends, and each day of the week
        map(day => {
            return <MenuItem
              key={day.label}
              disabled={matchesDateRecurrence(day, chosenDateRecurrences)}
              onClick={e => handleClose(e, day)}>{
              day.label
            }</MenuItem>;
          },
          days
        )
      }
    </Menu>
  </div>;
};