import {RideComfortLevels} from './rideComfortAlertLevels';
import {RideComfortPeriods} from './rideComfortPeriods';

export enum PeriodEnum {
  today = 'today',
  week = 'week',
  month = 'month',
}

export enum RideComfortAttributeAlertLevel {
  none = 'none',
  normal = 'normal',
  warning = 'warning',
  critical = 'critical',
}

/**
 * The API marks the alert level with these numbers
 * From graphql: element?.[1]?.node?.metadata?.['s-value'];
 */
export enum RideComfortAttributeAlertSValue {
  // Values from <0.5 are normal/good/ok
  none = 0,
  // Values from >=0.5 to <1.5
  warning = 0.5,
  // Values from >=1.5
  critical = 1.5,
}

export const periodLabelToAttribute: RideComfortPeriods = {
  Today: PeriodEnum.today,
  Week: PeriodEnum.week,
  Month: PeriodEnum.month,
} as RideComfortPeriods;

export const levelToAttribute: RideComfortLevels = {
  L0: RideComfortAttributeAlertLevel.normal,
  L1: RideComfortAttributeAlertLevel.warning,
  L2: RideComfortAttributeAlertLevel.critical,
  L3: RideComfortAttributeAlertLevel.critical,
} as RideComfortLevels;

export const rideComfortAlertLevelToLabel: Record<keyof RideComfortLevels, string> = {
  L0: 'Green',
  L1: 'Yellow',
  L2: 'Red',
  L3: 'Urgent',
};
