import React, {useState} from 'react';
import {Map} from 'mapbox-gl';

import RideComfortMapLayerDependency from "./RideComfortMapLayerDependency.tsx";
import {useTrainMap} from "visualizer-railbed/railbedAsync/cemitAppAsync/cemitAppHooks/mapHooks/trainMapHooks.ts";
import {TrainDependencyPropsWithRideComfortProps} from "../../../types/rideComfort/trainPropsWithRideComfortProps";

/**
 * Loads/Updates Mapbox data into mapProps
 * @param appProps
 * @param organizationProps
 * @param trainProps
 * @param children
 * @return {*}
 * @constructor
 */
const RideComfortMapDependency = ({appProps, organizationProps, trainProps}: TrainDependencyPropsWithRideComfortProps) => {

    const loading = trainProps.rideComfortProps.loading
    const isTrainMapUsed = !loading
    const inactive = loading || !isTrainMapUsed;

    // The Mapbox map. Avoid use of the variable name map since it is reserved for th emap function
    const [trainMap, setTrainMap] = useState<Map | undefined>(undefined);
    // Tracks loading of mapbox
    const [trainMapLoading, setTrainMapLoading] = useState<boolean>(true);
    // Tracks the mounting of the mapbox map after loading
    const [isTrainMapMounted, setIsTrainMapMounted] = useState<boolean>(false);

    const mapProps = {
        trainMap,
        setTrainMap,
        trainMapLoading,
        setTrainMapLoading,
        isTrainMapMounted,
        setIsTrainMapMounted,
    };

    // Once our railbedAsync data is loaded we set up the map
    const bounds = inactive ? undefined : undefined

    useTrainMap({
        loading: inactive,
        isTrainMapMounted,
        bounds,
        setTrainMap,
        setTrainMapLoading,
        setIsTrainMapMounted
    });

    const loadingExplanation = {
        'trainMapLoading': trainMapLoading,
        'isTrainMapMounted': isTrainMapMounted,
    };

    return <RideComfortMapLayerDependency {...{
        appProps: {
            ...appProps,
            appInteractionProps: {}
        },
        organizationProps,
        mapProps: {
            loadingExplanation,
            // Depends directly on trainProps.trainRouteOrGroupProps
            loading: loading || (isTrainMapUsed && (trainMapLoading || !isTrainMapMounted)),
            ...mapProps
        },
        trainProps
    }} />;
};

RideComfortMapDependency.displayName = 'RideComfortMapDependency';
export default RideComfortMapDependency;
