import { Chip, Stack } from '@mui/material';
import { length, map } from 'ramda';
import { useTranslation } from 'react-i18next';
import { dateRecurrenceLabel } from 'appUtils/trainAppUtils/trainFilterDateRecurrenceUtils.js';

/**
 * Creates Chips for the Chosen DateRecurrenceFilters with the ability to remove them from the filter
 * @param {[Object]} chosenDateRecurrences The DateRecurrence objects from the filter
 * @param {Function} handleRemoveDateRecurrenceFromFilters Handler to remove a DateRecurrenceObject from the filter
 * @returns {JSX.Element}
 * @constructor
 */
export const ChosenDateRecurrences = ({ chosenDateRecurrences, handleRemoveDateRecurrenceFromFilters }) => {

  const { t } = useTranslation();

  const dateRecurrenceChips = map(
    dateRecurrence => {
      const label = dateRecurrenceLabel({...dateRecurrence, t});
      return <Chip {...{
        key: label,
        color: 'primary',
        label, variant: 'outlined', onDelete: () => {
          handleRemoveDateRecurrenceFromFilters(dateRecurrence);
        }
      }} />;
    },
    chosenDateRecurrences
  );
  return length(dateRecurrenceChips) ? <Stack direction='column' spacing={1}>
    {dateRecurrenceChips}
  </Stack> : null;
};
export default ChosenDateRecurrences;