import { Stack } from '@mui/material';
import { extractDateRanges } from 'appUtils/trainAppUtils/trainFilterDateRangeUtils.js';
import { pick } from 'ramda';
import { onlyOneValueOrThrow } from 'utils/functional/functionalUtils.js';
import DateRangeChooserContainer
  from 'components/apps/trainAppComponents/dateRangeChooserComponents/DateRangeChooserContainer.js';
import { updateTrainRunFilterDateRange } from 'async/trainAppAsync/hooks/trainRunFilterHooks/trainFilterDateRangeHooks.js';

/**
 * Creates A TrainRunFilterDateRanges component that shows the current DateRanges in the filter
 * and has an interface for adding and remove them
 * @param trainProps
 * @returns {JSX.Element}
 * @constructor
 */
export const TrainRunFilterDateRangesComponent = (
  {
    trainProps,
  }) => {
  const currentDateRange = onlyOneValueOrThrow(extractDateRanges(trainProps.dateProps.trainRunFilterWithDateRanges, {}))

  /**
   * Update the DateRangeFilter with the date range chosen by the user
   * @param dateRange
   */
  const handleAddDateRangeToFilter = dateRange => {
    updateTrainRunFilterDateRange(
      pick(['trainRunFilterWithDateRanges', 'setTrainRunFilterWithDateRanges'], trainProps.dateProps),
      dateRange
    )
  };

  /*
   * Remove the given dateRange from the trainRunFilterWithDateRanges
   * @param dateRange
  const handleRemoveDateRangeFromFilters = dateRange => {
    const updatedTrainRunFilterWithDateRanges = removeDateRangeFilters(trainRunFilterWithDateRanges, dateRange, filterProps);
    trainProps.dateProps.setTrainRunFilterWithDateRanges(updatedTrainRunFilterWithDateRanges);
  };
   */

  return <Stack {...{ spacing: 2, direction: 'row', sx: { alignItems: 'center' } }} >
    <DateRangeChooserContainer {...{ trainProps,
      currentDateRange,
      handleAddDateRangeToFilter }} />
    {
      /*
      TODO don't show chips or label since we only allow one date range for now
      // Show Chips for DateRanges in the filter or show nothing
      !readonly && length(dateRangeLabelsFromFilter) ?
        <ChosenDateRanges {...{ trainProps, filterProps, handleRemoveDateRangeFromFilters }} /> :
        <Typography>
          {dateRangesLabel}
        </Typography>
       */
    }
  </Stack>;
};