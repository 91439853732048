import {compact, memoizedWith} from '@rescapes/ramda';
import {trainRunImupointsWithMostData} from 'appUtils/trainAppUtils/trainDataUtils.js';
import {featureCollection as turfFeatureCollection, lineString, point} from '@turf/helpers';
import {
    append,
    compose,
    equals,
    head, indexBy,
    last,
    length,
    lensPath,
    lensProp,
    map,
    mergeRight,
    omit,
    over,
    prop,
    reduce,
    set
} from 'ramda';
import nearestPointOnLine from '@turf/nearest-point-on-line';
import lineSlice from '@turf/line-slice';
import turfLength from '@turf/length';
import {extremes} from 'utils/functional/functionalUtils.js';
import {scheduledStopPointsOfTrainRun, trainRunHasImuPoints} from 'appUtils/trainAppUtils/trainRunUtils.js';
import {calculateMeanXYZAcceleration, calculateRmse} from 'appUtils/trainAppUtils/derivedAttributeUtils.js';

/**
 * Creates derived properties for the given imu_point, namely the meters property which
 * is a calculation of where it is along the TrainRoute and therefore where it shows on charts
 * @param trainRun
 * @param lineString
 * @param accumulatedDistance
 * @param pointFeature
 * @returns {{slicedLine, properties: {accXZYMean, meters: *}}}
 */
const derivativePropertiesForImuPoint = (trainRun, {lineString, accumulatedDistance}, pointFeature) => {
    // Slice the given lineString from its start to the point
    // This line is returned to the next point along with accumulatedDistance
    // If the point is at the start of the line, we get 1 feature and thus are at 0 meters
    // turf's lineSplit is broken, so using this compose instead
    const featureCollection = compose(
        lines => turfFeatureCollection(lines),
        compact,
        nearestPointOnLine => {
            // Create one or two new linestrings. Ignore single point strings
            return map(
                points => {
                    return equals(...map(point => point.geometry.coordinates, points)) ? null : lineSlice(...points, lineString);
                },
                // Take line slices
                [[point(head(lineString.geometry.coordinates)), nearestPointOnLine], [nearestPointOnLine, point(last(lineString.geometry.coordinates))]]
            );
        },
        pointFeature => {
            // Get the index of the nearest point on the line
            return nearestPointOnLine(lineString, pointFeature);
        }
    )(pointFeature);
    const slicedLine = last(featureCollection.features);
    // Use the accumulatedDistance and length line from the start of lineString until pointFeature
    // If pointFeature is at the start, then add 0
    const meters = accumulatedDistance + (length(featureCollection.features) > 1 ? turfLength(head(featureCollection.features), {units: 'meters'}) : 0);
    // If available, returns  rmse2hz and rsme4hz
    const rmseProperties  = calculateRmse(trainRun, pointFeature)
    return {
        properties: {
            accXZYMean: calculateMeanXYZAcceleration(pointFeature.properties),
            ...rmseProperties,
            meters
        },
        // Return for the next point
        accumulatedDistance: meters,
        slicedLine
    };
};


/**
 * Returns limited imuPoint based point features
 * Memoized to run for each unique trainRun by its id and the distanceIntervalsAndRanges of ImuPoints
 * that have been loaded
 * @param {Object} trainRoute
 * @param {Object} trainRoute.trackData Used to compute the geojson
 * @param {Object} trainRun The TrainRun whose imuPoints we want to create geojson for
 * @returns { nearestEndPoints, imuPointsSlicedLine, limitedPointFeatures, trackSlicedLine };
 */
export const memoizedTrainRunGeojson = memoizedWith(
    ({trainRun, distanceIntervalsAndRanges}) => [trainRun.id, distanceIntervalsAndRanges],
    ({trainRoute, trainRun}) => {
        const trackData = trainRoute.trackData;

        // TODO currently take the formation with the most sensor points
        const imuPoints = trainRunImupointsWithMostData(trainRun);

        // Inject the imuPoint properties into it's geojson point's properties
        const imuPointFeatures = map(
            imuPoint => {
                return set(
                    lensProp('properties'),
                    omit(['geojson'], imuPoint),
                    imuPoint.geojson);
            },
            imuPoints
        );

        // This line only has points that are imuPoints
        const lineFeatureOfAvailableImuPoints = lineString(
            map(point => point.geometry.coordinates, imuPointFeatures)
        );

        // Get the end points of the trainRun based on its stops
        const endScheduledStoPointsOfTrainRun = map(prop('geojson'), extremes(scheduledStopPointsOfTrainRun(trainRun)));
        // Find the nearest points on the rail line
        const nearestEndPoints = map(
            point => {
                return nearestPointOnLine(trackData.trackSingleLineString, point);
            },
            endScheduledStoPointsOfTrainRun
        );

        // TODO we can't currently query for imuPoints in a way that handles late trains because we can't
        // measure what direction along the track the Train is moving. I can't remember why that is
        // Ideally we should get the imuPoints that correspond to the TrainRun, whether on-time or late, from
        // the server and not limit the imuPoint here. But we have to limit them here from now or risk getting
        // points from the previous TrainRun that was late and going in the opposite direction on the same track

        // Limit the imuPoints to the TrainRun data-thresholds in case the TrainRun was late
        // and we captured imuPoints that were part of a previous TrainRun
        // Hash so we can inject the points with the properties back into the line later
        const hashToImuPoint = indexBy(point => point.geometry.coordinates.toString(), imuPointFeatures);

        // Slice the lineFeatureOfAvailableImuPoints to the end points of the TrainRun. This is shorter
        // than trackSingleLineString if we are missing imuPoints at the ends because the TrainRun was late
        const imuPointsSlicedLine = compose(
            slice => {
                // Move the last points of the slice to the closest imupoint if not already matching
                // This step is mostly important when we have to generate fake imupoints at stations because the
                // TrainRun has no available imupoints
                const lastIndex = length(slice.geometry.coordinates) - 1;
                return reduce(
                    (slice, index) => {
                        return over(
                            lensPath(['geometry', 'coordinates', index]),
                            coord => {
                                return hashToImuPoint[coord.toString()] ?
                                    coord :
                                    nearestPointOnLine(lineFeatureOfAvailableImuPoints, point(coord)).geometry.coordinates;
                            },
                            slice
                        );
                    },
                    slice,
                    [0, lastIndex]
                );
            },
            lineFeatureOfAvailableImuPoints => {
                return lineSlice(...nearestEndPoints, lineFeatureOfAvailableImuPoints);
            }
        )(lineFeatureOfAvailableImuPoints);

        // Limited
        const limitedImuPointFeatures = compact(map(
            coord => {
                return hashToImuPoint[coord.toString()];
            },
            imuPointsSlicedLine.geometry.coordinates
        ));

        // Add derived props. Do this efficiently by calculating the position
        // in meters of each sequential point using trackData.trackSingleLineString,
        // which we slice to the current point each iteration. This makes the calculation of the meters
        // along the line order n, where n is the number of points.
        const updatedPoints = prop('points', reduce(
            ({lineString, accumulatedDistance, points}, pointFeature) => {
                // Get the derived properties of the point and lineString sliced to start at this point
                const {
                    slicedLine,
                    accumulatedDistance: nextDistance,
                    properties: derivedProperties
                } = derivativePropertiesForImuPoint(
                    trainRun,
                    {
                        lineString, accumulatedDistance
                    }, pointFeature
                );
                return {
                    // Use the slicedLine and accumulatedDistance for the next iteration
                    lineString: slicedLine,
                    accumulatedDistance: nextDistance,
                    points: append(
                        over(
                            lensProp('properties'),
                            properties => {
                                return mergeRight(properties, derivedProperties);
                            },
                            pointFeature
                        ), points)
                };
            },
            {
                lineString: trackData.trackSingleLineString,
                accumulatedDistance: 0,
                points: []
            },
            limitedImuPointFeatures
        ));
        return {
            // TODO I don't know if this is used
            nearestEndPoints,
            // This should already be limited to the TrainRoute extent
            trackSlicedLine: trackData.trackSingleLineString,
            // The imuPoints sliced to match the TrainRun in case the train was late
            imuPointsSlicedLine,
            // The limited points with derived properties added
            limitedPointFeatures: updatedPoints
        };
    }
);

/**
 * Creates memoized geojson of sensor points for the TrainRun
 * Memoized to run for each unique trainRun by its id and the distanceIntervalsAndRanges of ImuPoints
 * that have been loaded
 * @param {Object} trainRoute
 * @param {Object} trainRoute.trackData Used to compute the geojson
 * @param {Object} trainRun The TrainRun whose imuPoints we want to create geojson for
 * @returns limitedPointFeatures
 */
export const memoizedTrainRunFeatureCollectionSensorPoints = memoizedWith(
    ({trainRun, distanceIntervalsAndRanges}) => [trainRun.id, distanceIntervalsAndRanges],
    ({trainRoute, trainRun, distanceIntervalsAndRanges}) => {
        if (!trainRunHasImuPoints(trainRun)) {
            throw Error(`trainRun with id ${trainRun.id} expected to have imuPoints loaded but did not`);
        }
        const {limitedPointFeatures} = memoizedTrainRunGeojson({trainRoute, trainRun, distanceIntervalsAndRanges});
        return limitedPointFeatures;
    });
