import { useTranslation } from 'react-i18next';
import { any, includes, mapObjIndexed, mergeRight } from 'ramda';
import {
  BudgetIcon,
  CatenaryIcon,
  DepotIcon,
  EventIcon,
  GRPSIcon,
  RailIcon,
  StationIcon,
  SwtichIcon,
  TrainIcon
} from 'components/apps/cemitAppComponents/sidebar/sidebarSvg.js';
import { APPS_PATH, tabAccess } from 'appConfigs/appConfig.js';
import { CemitAppNavigationItems } from 'components/apps/cemitAppComponents/CemitAppNavigationItems.js';
export const group = JSON.parse(localStorage.getItem('group'))?.group || [];

/**
 * @param currentPath
 * @param toggleSidebar
 * @return {JSX.Element}
 * @constructor
 */
const CemitAppNavigationItemsContainer = ({ currentPath, toggleSidebar }) => {
  const { t } = useTranslation();
  const setAccess = key => {
    return includes('sysadmin', [group]) || any(x => x.includes(key), tabAccess);
  };
  const apps = mapObjIndexed(
    (v, k) => mergeRight(
      v,
      { access: k === 'train' ? setAccess(k) : false }
    ), {
      train: {
        name: t('train'),
        to: `/${APPS_PATH}/train/runs`,
        Icon: TrainIcon
      },
      rails: {
        name: t('rails'),
        to: `/${APPS_PATH}/rails/overview`,
        Icon: RailIcon
      },
      catenary: {
        name: t('catenary'),
        to: `/${APPS_PATH}/catenary/overview`,
        Icon: CatenaryIcon
      },
      stations: {
        name: t('stations'),
        to: `/${APPS_PATH}/station/overview`,
        Icon: StationIcon
      },
      depots: {
        name: t('depots'),
        to: `/${APPS_PATH}/depots/overview`,
        Icon: DepotIcon
      },
      grps: {
        name: t('grps'),
        to: `/${APPS_PATH}/grps/overview`,
        Icon: GRPSIcon
      },
      events: {
        name: t('events'),
        to: `/${APPS_PATH}/events`,
        Icon: EventIcon
      },
      switch: {
        name: t('switch'),
        to: `/${APPS_PATH}/switch`,
        Icon: SwtichIcon
      },
      budgets: {
        name: t('budgets'),
        to: `/${APPS_PATH}/budgets/overview`,
        Icon: BudgetIcon
      }
    });

  return <CemitAppNavigationItems {...{
    currentPath, toggleSidebar, apps
  }} />;
};
export default CemitAppNavigationItemsContainer;