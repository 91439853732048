import { scaleLinear } from 'd3-scale';
import { extremes } from 'utils/functional/functionalUtils.js';
import {
  addIndex,
  always,
  any,
  compose,
  concat,
  equals,
  filter,
  findIndex,
  fromPairs,
  has,
  head,
  ifElse,
  indexBy,
  last,
  length,
  lensPath,
  lt,
  lte,
  map,
  not,
  prop,
  propOr,
  set,
  slice,
  subtract,
  times,
  uniq,
  unless,
  when,
  zipWith
} from 'ramda';
import { compact } from '@rescapes/ramda';
import { pseudoScheduledStopPointAtDistanceAlongRoute } from 'appUtils/trainAppUtils/timetabledPassingTimeUtils.js';
import { useNotLoadingMemo } from 'utils/hooks/useMemoHooks.js';
import { unlessLoadingProps } from 'utils/componentLogic/loadingUtils.js';
import { useMemoScheduledStopPointsOfTrainRoute } from 'async/trainAppAsync/hooks/typeHooks/trainRouteHooks.js';
import {
  extractTrainRunIntervalTrainRoute,
  scheduledStopPointOfTimeTabledPassingDatetime
} from 'appUtils/trainAppUtils/trainRunUtils.js';


/**
 * Memoized call that zips stops with their offsetLefts
 * @param {Boolean} loading return undefined if true
 * @param timetabledPassingDatetimes
 * @param offsetLefts
 * @returns {[Object]} Objects where each has {visiblescheduledStopPointAndMaybeDateTime, routeDistance, offsetLeft} routeDistance is scheduledStopPoint.routeDistance
 */
export const useMemoZipVisibleScheduledStopPointsAndMaybeTimesWithOffsetLefts = (loading, visibleScheduledStopPointsAndMaybeTimes, offsetLefts) => {
  return useNotLoadingMemo(loading,
    () => {
      return zipWith((scheduledStopPointAndMaybeDateTime, offsetLeft) => {
          return {
            scheduledStopPointAndMaybeDateTime,
            routeDistance: scheduledStopPointAndMaybeDateTime.routeDistance,
            offsetLeft: offsetLeft || 0
          };
        },
        visibleScheduledStopPointsAndMaybeTimes,
        offsetLefts
      );
    }, [visibleScheduledStopPointsAndMaybeTimes, offsetLefts]
  );
};

/**
 * Resolve the offsetLeft of the component based on its distance along the station line
 * @param config
 * @param config.routeDistancesWithOffsetLefts
 * @param distance
 * @returns {*}
 */
export const resolveOffsetLeft = ({ routeDistancesWithOffsetLefts }) => distance => {
  // Don't allow negative distances, although the user can moverDrag the bar beyond the start and end of the station line.
  // The distance is also forced to be no greater than that of the last station. The latter applies when we
  // are measuring the distance of the right side of the TrainRunInterval bar
  const validDistance = compose(
    distance => Math.min(last(routeDistancesWithOffsetLefts).routeDistance, distance),
    distance => Math.max(0, distance)
  )(distance);
  const maxIndex = length(routeDistancesWithOffsetLefts) - 1;
  // The distance is between this index and the one before it
  const lastIndex = compose(
    index => when(
      equals(0),
      () => 1
    )(index),
    index => when(
      equals(-1),
      () => maxIndex
    )(index)
  )(
    addIndex(findIndex)(
      ({ routeDistance }, index) => {
        return lt(validDistance, routeDistance) || (index === maxIndex && equals(validDistance, routeDistance));
      },
      routeDistancesWithOffsetLefts
    )
  );
  const { routeDistance: routeDistance1, offsetLeft: offsetLeft1 } = routeDistancesWithOffsetLefts[lastIndex - 1];
  const { routeDistance: routeDistance2, offsetLeft: offsetLeft2 } = routeDistancesWithOffsetLefts[lastIndex];
  const ratio = (validDistance - routeDistance1) / (routeDistance2 - routeDistance1);
  return offsetLeft1 + (ratio * (offsetLeft2 - offsetLeft1));
};

/**
 * @param config
 * @param config.useMemoZipVisibleScheduledStopPointsAndMaybeTimesWithOffsetLefts
 * Reverse of resolveOffsetLeft. Based on an offsetLeft that results from dragging, calculate the distance
 * along the station line
 * @param {Number} offset The distance in pixels dragged on the x axis by the user relative to the starting point
 */
export const resolveDistance = (
  {
    routeDistancesWithOffsetLefts,
    trainRunInterval,
    spaceGeospatially
  }) => offset => {

  if (spaceGeospatially) {
    // We have a percent offset, so we need to convert it to the trainRunInterval.distanceRange
    // or underlyingRoute distanceRange
    const distanceRange = { start: 0, end: extractTrainRunIntervalTrainRoute(trainRunInterval).routeDistance };
    return (offset / 100) * (distanceRange.end - distanceRange.start);
  }

  // If not spaceGeospatially, we must reolve based on the station offsets: routeDistancesWithOffsetLefts
  // The offset is between this index and the one before it. If it's beyond the last offset because the user
  // dragged off the line, then take the last offset
  const { index: lastIndex, offset: offsetUpdated } = compose(
    // Set to 1 if less than the first index and update the offset to match
    ({ index, offset }) => when(
      ({ index }) => equals(0, index),
      () => ({
        index: 1,
        offset: routeDistancesWithOffsetLefts[0].offsetLeft
      })
    )({ index, offset }),
    // Set to length - 1 if too far right and update the offset to match
    ({ index, offset }) => when(
      ({ index }) => equals(-1, index),
      () => {
        const indexAdjusted = length(routeDistancesWithOffsetLefts) - 1;
        return {
          index: indexAdjusted,
          offset: routeDistancesWithOffsetLefts[indexAdjusted].offsetLeft
        };
      }
    )({ index, offset }),
    index => ({ index, offset }),
    routeDistancesWithOffsetLefts => findIndex(
      ({ offsetLeft }) => {
        return lt(offset, offsetLeft);
      },
      routeDistancesWithOffsetLefts
    )
  )(routeDistancesWithOffsetLefts);
  const { routeDistance: routeDistance1, offsetLeft: offsetLeft1 } = routeDistancesWithOffsetLefts[lastIndex - 1];
  const { routeDistance: routeDistance2, offsetLeft: offsetLeft2 } = routeDistancesWithOffsetLefts[lastIndex];
  const ratio = (offsetUpdated - offsetLeft1) / (offsetLeft2 - offsetLeft1);
  return routeDistance1 + (ratio * (routeDistance2 - routeDistance1));
};

/**
 * Given stops of a trainRun or trainRoute, and a trainRunInterval, returns
 * the stops within that interval plus an optional buffer of stops
 * @param {Object} config
 * @param {Number} [config.buffer] Default 0 The number of stops before
 * and after those matching the trainRunInterval to add.
 * @param {Boolean} [config.includeIntervalEndsAsStops] Default false. If true, include the ends of the
 * trainRunInterval as pseudo-stops if not matching a real timetabledPassingTime
 * @param {Object} [config.trainRoute] Only needed for includeIntervalEndsAsStops=true to calculate the end points of the interval along
 * the route line
 * @param {Object} [config.trainRoute.trackData.line] A geojson linestring representing the route.
 * @param stops
 * @param trainRunInterval
 * @returns {*}
 */
export const stopsOfTrainRunInterval = (
  {
    buffer = 0,
    includeIntervalEndsAsStops = false,
    trainRoute = null,
    railwayLines
  }, stops, trainRunInterval) => {


  // Find the first timetabledPassingTime that is equal the start/within the distanceRange
  const firstStopWithinIntervalIndex = findIndex(
    stop => {
      return lte(Math.round(trainRunInterval.distanceRange.start), Math.round(stop.routeDistance));
    },
    stops
  );
  // Find the first timetabledPassingTime that is outside the distanceRange. If no match make it Infinity
  const firstStopWithoutIntervalIndex = when(equals(-1), always(Infinity))(findIndex(
    stop => {
      return lt(Math.round(trainRunInterval.distanceRange.end), Math.round(stop.routeDistance));
    },
    stops
  ));
  // Get the max of 0 and the index of the first match plus the buffer
  const max = Math.max(0, firstStopWithinIntervalIndex - buffer);
  const scheduledStopPointBeforeFirst = max > 0 ? stops[max - 1] : null;
  // Get the min of stop length and the first place we didn't find a match (or Infinity) plus the buffer
  const min = Math.min(stops.length, firstStopWithoutIntervalIndex + buffer);
  const limitedScheduledStopPoints = slice(max, min, stops);
  const scheduledStopPointAfterLast = min < length(stops) - 1 ? stops[min] :
    // TODO This should maybe be null, but then we have to handle null below
    last(stops);

  // Add pseudo end stops if includeIntervalEndsAsStops is true and a real end scheduledStopTime isn't visible
  const routeDistanceLookup = indexBy(prop('routeDistance'), limitedScheduledStopPoints);
  const stopAtDistanceAlongRouteIfNeeded = (distance, nextScheduledStopPoint) => {
    return has(distance, routeDistanceLookup) ? null : pseudoScheduledStopPointAtDistanceAlongRoute(
      { trainRoute, nextScheduledStopPoint, railwayLines },
      distance
    );
  };

  return when(
    always(includeIntervalEndsAsStops),
    limitedStops => {
      return compact([
        stopAtDistanceAlongRouteIfNeeded(trainRunInterval.distanceRange.start, scheduledStopPointBeforeFirst),
        ...limitedStops,
        stopAtDistanceAlongRouteIfNeeded(trainRunInterval.distanceRange.end, scheduledStopPointAfterLast)
      ]);
    }
  )(limitedScheduledStopPoints);
};

/**
 * For TrainRunLines with data-thresholds stops visible, this calculates where the gaps are so we can show
 * dotted lines between those stations
 * @param loading Return undefined if true
 * @param areOffsetLeftsReady
 * @param offsetLefts
 * @param routeStops
 * @param visibleStops
 * @returns {[Object]} A list of objects, each with a pair of stops and a property gap that is true
 * if there are hidden stops between them. Also returns the offset left for the first of stops
 */
export const useMemoCreateStopGaps = ({ loading, offsetLefts, routeStops, visibleStops }) => {
  return useNotLoadingMemo(loading, () => {
    const stopIdToIndex = fromPairs(addIndex(map)(
      (routeStop, index) => [prop(['id'], routeStop), index],
      routeStops
    ));
    return addIndex(zipWith)(
      (stops1, stops2, index) => {
        const stops = [stops1, stops2];
        const gap = compose(
          not,
          // See if they are adjacent
          equals(1),
          Math.abs,
          // Take the difference of the index of the stops
          indices => {
            return subtract(...indices);
          },
          map(stop => {
            return stopIdToIndex[stop.id];
          })
        )(stops);
        return {
          stops,
          // The x offset of the timetabledPassingTime along the TrainRunLine
          offsetLefts: slice(index, index + 2, offsetLefts),
          // If the stops have adjacent indices in routesStops, there is no gap
          // the stops are adjacent, there is not gap
          gap
        };
      },
      slice(0, -1, visibleStops),
      slice(1, Infinity, visibleStops)
    );
  }, [offsetLefts, routeStops, visibleStops]);
};

/**
 * Calculate the geospatial position of a timetabledPassingTime. This is used when we want to space stops by distance,
 * not evenly along the TrainRunLine
 * @param distanceRange
 * @param scheduledStopPoint
 * @returns {number}
 */
export const calculateGeospatialLeftOffsetOfStop = (distanceRange, scheduledStopPoint) => {
  return calculatePercentageOfDistanceRange(distanceRange, scheduledStopPoint.routeDistance);
};

/**
 * Given an unnormalized distance like a ScheduledStopPoint routeDistance or an offsetLeft,
 * calculates the percent value of the point relative to the given distance range.
 * If the point is before the start of the distanceRange, 0 is returned. If beyond the end of
 * the distance range, 100 is returned. Otherwise a value between 0 and 100 is returned
 * @param {{start:<Number>, end:<nNunmber>}}distanceRange a distance range in meters or similar
 * @param {Number} unnormalizedValue The value to normalize
 * @returns {Number} The percent number
 */
export const calculatePercentageOfDistanceRange = (distanceRange, unnormalizedValue) => {
  if (unnormalizedValue < distanceRange.start) {
    return 0;
  } else if (unnormalizedValue > distanceRange.end) {
    return 100;
  } else {
    const result = 100 * (unnormalizedValue - distanceRange.start) / (distanceRange.end - distanceRange.start);
    return result;
  }
};


/**
 * Given a trainRunInterval and all routeStops that the interval might cover, calculate
 * the stops to show on the TrainRunLine
 * @param {Object} config
 * @param {Number} [config.buffer] Default 0 The number of stops before
 * and after those matching the trainRunInterval to add.
 * @param {Boolean} [config.removeIntermediate] Default true. Remove the intermediate stops of the interval
 * that aren't adjacent to the ends of the interval. This means that two stops are shown at each end of the interval
 * plus the fist and last timetabledPassingTime of the entire route. If false, then all stops of the interval are displayed plus
 * the first and last timetabledPassingTime of the entire route
 * @param {Boolean} [config.includeEndStops] Default true. If false don't show the end stops of the route
 * unless part of the TrainRunInterval. Instead return pseudo stops where the interval ends are.
 * @param {Object} [config.trainRoute] Only needed for spaceGeospatially to calculate the end points of the interval along
 * the route line
 * @param {Object} [config.trainRoute.trackData.line] A geojson linestring representing the route.
 * @param {[Object]} routeScheduledStopPoints All stops of the route
 * @param {Object} trainRunInterval The TrainRunInterval
 * @param {Number} trainRunInterval.distanceRange The TrainRunInterval.distanceRance. Used to calculate the elible
 * stops by matching with each timetabledPassingTime's timetabledPassingTime.routeDistance
 * @returns {Object} The visible stops to show on the TrainRunLine
 */
export const visibleScheduledStopPointsOfTrainRouteOrRunInterval = (
  {
    buffer = 0, removeIntermediate = true, includeEndStops = true,
    trainRoute = null,
    railwayLines
  },
  routeScheduledStopPoints,
  trainRunInterval
) => {
  return compose(
    // Include the first and last timetabledPassingTime of the route if includeEndStops is true
    intervalStops => {
      return when(
        always(includeEndStops),
        intervalStops => {
          return uniq([
            head(routeScheduledStopPoints),
            ...intervalStops,
            last(routeScheduledStopPoints)
          ]);
        }
      )(intervalStops);
    },
    // When removeIntermediate, just take the two stops at each end of the interval
    stops => {
      return when(
        always(removeIntermediate),
        stops => uniq(concat(slice(0, 2, stops), slice(-2, Infinity, stops)))
      )(stops);
    },
    trainRunInterval => {
      return stopsOfTrainRunInterval({
        buffer,
        includeIntervalEndsAsStops: !includeEndStops,
        trainRoute,
        railwayLines
      }, routeScheduledStopPoints, trainRunInterval);
    }
  )(trainRunInterval);
};

/**
 * Calculates the geospatial offset for each scheduledStopPoint if spaceGeospatially is true,
 * else return null for all
 * @param {Boolean} spaceGeospatially
 * @param {Object} limitedDistanceRange Default null. with spaceGeospatially limits the distance range of the line
 * and normalizes the visible stops to fit the full line
 * @param {Object} distanceRange
 * @param {Number} distanceRange.start
 * @param {Number} distanceRange.end
 * @param {[Object]} scheduledStopPoints ScheduledStopPoint instances
 * @returns {[Number|null]} An array of offset amounts without a unit or array of nulls
 */
export const initStopOffsetLefts = (
  {
    spaceGeospatially,
    limitedDistanceRange,
    distanceRange,
    scheduledStopPoints
  }) => {
  return ifElse(
    () => spaceGeospatially,
    scheduledStopPoints => {
      // If we are spacing geospatially, we can already calculate the absolute positions of the timetabledPassingTime
      const stopOffsetLefts = map(
        scheduledStopPoint => {
          return calculateGeospatialLeftOffsetOfStop(distanceRange, scheduledStopPoint);
        },
        scheduledStopPoints
      );
      const updatedStopOffsetLefts = when(
        () => spaceGeospatially && limitedDistanceRange,
        stopOffsetLefts => {
          // If we aren't showing the line from the start to end of the TrainRoute, we have to scale the stopOffsetLefts such that
          // they span from 0 to 100
          const scale = scaleLinear().domain(extremes(stopOffsetLefts)).range([0, 100]);
          return map(offsetLeft => {
            return scale(offsetLeft);
          }, stopOffsetLefts);
        }
      )(stopOffsetLefts);
      return updatedStopOffsetLefts;
    },
    scheduledStopPoints => {
      // Otherwise create an empty array and let the flex-based stations tell us their offsets
      return times(() => null, scheduledStopPoints.length);
    })(scheduledStopPoints);
};


/**
 * Simplistic function to indicate if scheduledStopPoint is too close to others and needs to be clustered (i.e. minimized)
 * because it is lower prioirty
 *
 * @param componentWidth
 * @param spaceGeospatially If true be much more aggressive about clustering, since points are probably close
 * together for close stops. Otherwise don't cluster unless the size of the station is too small to make an
 * the schedule time partially invisible
 * @param timetabledPassingDatetimesWithOffsetLefts
 * @param timetabledPassingTIme
 * @param offsetLeft
 * @returns {*}
 */
export const shouldClusterIfOverlapsGreaterOrEqualPriorityStops = (
  {
    componentWidth,
    scheduledStopPointsAndMaybeTimesWithOffsetLefts,
    spaceGeospatially
  },
  { scheduledStopPointAndMaybeDateTime, offsetLeft }
) => {
  // If geospatial, look for close components and prioritize by station priority
  if (spaceGeospatially) {
    const closeScheduledStopPointsAndMaybeTimesWithOffsets = filter(
      ({
         scheduledStopPointAndMaybeDateTime: otherscheduledStopPointAndMaybeDateTime,
         offsetLeft: otherOffsetLeft
       }) => {
        return scheduledStopPointAndMaybeDateTime !== otherscheduledStopPointAndMaybeDateTime &&
          Math.abs(otherOffsetLeft - offsetLeft) < componentWidth
      },
      scheduledStopPointsAndMaybeTimesWithOffsetLefts
    );
    // Return true if anything is close that has at least scheduledStopPoint's priority
    return any(
      ({ scheduledStopPointAndMaybeDateTime: othersScheduledStopPointAndMaybeTime }) => {
        return (othersScheduledStopPointAndMaybeTime.priority || 0) >= (scheduledStopPointAndMaybeDateTime.priority || 0);
      },
      closeScheduledStopPointsAndMaybeTimesWithOffsets
    );
  }
  else {
    // Otherwise just make sure the component is wide enough to show the time and a reasonable abbreviation
    // Don't ever hide the end or reference stops, which currently are the only stops above 0 priority
    return scheduledStopPointAndMaybeDateTime.priority === 0 && componentWidth < 40
  }
};

/**
 * Activate a UserTrainRunInterval
 * @param userTrainRunInterval
 */
// export const activateUserTrainRunInterval = ({ crudUserTrainRunIntervals }, userTrainRunInterval) => {
//   const baseline = find(
//     userTrainRunInterval => userTrainRunInterval.activity?.isBaseline,
//     crudUserTrainRunIntervals.list
//   );
//   // TODO Activate by moving to the front of the list for now since we only allow two active
//   const index = findIndex(
//     item => eqProps('sourceKey', item, userTrainRunInterval),
//     crudUserTrainRunIntervals.list
//   );
//   const updated = [
//     // Assume the baseline is always index 0
//     baseline,
//     {
//       ...crudUserTrainRunIntervals.list[index],
//       activity: { isActive: true }
//     },
//     // Remove index here
//     ...slice(1, index, crudUserTrainRunIntervals.list),
//     ...slice(index + 1, Infinity, crudUserTrainRunIntervals.list)
//   ];
//   crudUserTrainRunIntervals.set(updated);
// };

/**
 * Convenience method to limit the given trainProps filteredCrudTrainRunIntervals and filteredCrudUserTrainRunIntervals
 * @param filteredCrudTrainRunIntervals
 * @param filteredCrudUserTrainRunIntervals
 * @param trainProps
 * @returns {*}
 */
export const setFilteredCrudOnTrainProps = (
  {
    filteredCrudTrainRunIntervals,
    filteredCrudUserTrainRunIntervals,
    trainProps
  }) => {
  return compose(
    trainProps => {
      return set(
        lensPath(['userTrainRunIntervalProps', 'crudUserTrainRunIntervals']),
        filteredCrudUserTrainRunIntervals,
        trainProps
      );
    },
    trainProps => {
      return set(
        lensPath(['trainRunIntervalProps', 'crudTrainRunIntervals']),
        filteredCrudTrainRunIntervals,
        trainProps
      );
    }
  )(trainProps);
};
/**
 * Gets the ScheduledStopPoints of the trainProps.trainRouteProps.trainRoute if isTrainRouteLine is true
 * or the ScheduledStopPoints with TimetabledPassingTimes from the trainProps.trainRouteProps.trainRun
 * @param loading
 * @param trainProps
 * @param isTrainRouteLine
 * @returns {{}|*} Array of Objects that are scheduledStopPointsOfTrainRoute for isTrainRoute==true and
 * scheduledStopPoints with their corresponding timetabledPassingTime merged in for TrainRuns
 */
export const trainRunLineScheduledStopPointsAndMaybeDatetimes = ({ loading, trainProps, isTrainRouteLine }) => {
  return unlessLoadingProps(loading, () => {
    const trainRouteOrGroup = trainProps.trainRouteProps.trainRoute;
    const scheduledStopPointsOfTrainRoute = useMemoScheduledStopPointsOfTrainRoute(trainRouteOrGroup);
    // Null if isTrainRouteLine
    const trainRun = isTrainRouteLine ? null : trainProps.trainRunProps.trainRun;

    // If this isn't a TrainRouteLine, return the ScheduledStopPoints and the corresponding TimetabledPassingTimes
    // of the TrainRun. If the TrainRun has a TrainRoute that is shorter than the longest TrainRoute of the
    // trainRouteOrGroup, certain timetabledPassingTimes will be null
    const scheduledStopPointsWithMaybeTimetabledPassingTimes = unless(
      always(isTrainRouteLine),
      scheduledStopPointsOfTrainRoute => {
        // Create a collection of {...scheduledStopPoint, timetabledPassingDatetime}
        // We don't have a TrainRun if we are displaying the TrainRoute, so just scheduledStopPoints without times
        const scheduledStopPointIdToTimetabledPassingDatetime = indexBy(
          compose(prop('id'), scheduledStopPointOfTimeTabledPassingDatetime),
          trainRun.timetabledPassingDatetimes
        );
        return map(
          scheduledStopPoint => {
            const timetabledPassingDatetime = propOr(null, scheduledStopPoint.id, scheduledStopPointIdToTimetabledPassingDatetime);
            return {
              ...scheduledStopPoint,
              timetabledPassingDatetime
            };
          },
          scheduledStopPointsOfTrainRoute);
      }
    )(scheduledStopPointsOfTrainRoute);
    return scheduledStopPointsWithMaybeTimetabledPassingTimes;
  });
};