import {
    addIndex,
    any,
    chain,
    equals,
    filter,
    forEach,
    forEachObjIndexed,
    groupBy,
    has,
    includes,
    indexBy,
    length,
    map,
    prop,
    propOr,
    reduce,
    startsWith,
    zipWith
} from 'ramda';
import {mapObjToValues, toArrayIfNot} from '@rescapes/ramda';
import bbox from '@turf/bbox';
import {MapboxIconConfig, MapboxLayer, MapSourceInfo, MapSourceVisual} from '../../railbedTypes/mapbox/mapSourceVisual';
import {GeoJSONSource, GeoJSONSourceRaw, Map} from 'mapbox-gl';
import {FeatureCollectionU, FeatureU} from '../../railbedTypes/geometry/geojsonUnions';
import {ChangeStatus} from '../../railbedTypes/mapbox/changeStatus';
import {BBox2d} from '@turf/helpers/dist/js/lib/geojson';


/**
 * Create a Mapbox geojson source configuration.
 * @param featureCollection Geojson FeatureCollection
 * @param sourceName The name of the source
 * @param [options] Options for addSource.
 * @return {Object} The source configuration
 */
export const mapboxGeojsonSource = (
    {sourceName, featureCollection, options = {}}:
        {
            sourceName: string,
            featureCollection: FeatureCollectionU,
            options: Record<string, any>
        }
) => {
    return {
        name: sourceName,
        type: 'geojson',
        data: featureCollection,
        ...options
    };
};

/**
 * Update or create the given Mapbox source
 * @param mapboxMap The mapbox map
 * @param name The source name
 * @param [type] Defaults to 'geojson'
 * @param data The data
 * @param options Options for addSource, such as
 *      cluster: true,
 *      clusterProperties: {
 *       train_ids: ['concat', ['concat', ['get', 'train_id'], ',']]
 *     }
 * @returns {Object} The name passed in and a change field that is 'update', 'create', or null, where
 *     null occurs if no change to the data was detected with a reference check
 */

export const updateOrCreateMapboxSourceIfNeeded = (
    {mapboxMap, sourceInfo: {name, type = 'geojson', data, options = {}}}:
        {
            mapboxMap: Map,
            sourceInfo: MapSourceInfo
        }
): ChangeStatus => {
    const dataSource = mapboxMap.getSource(name) as GeoJSONSource;
    if (dataSource) {
        // Mapbox doesn't publicly offer the source data, and _data is the same object with a different reference
        // So compare feature length and then do a deep equals if needed
        // @ts-expect-error Private member
        const privateData = dataSource._data as FeatureCollectionU;
        if (data.features.length !== privateData.features.length || !equals(data, privateData)) {
            dataSource.setData(data);
            return {name, change: 'update'};
        }
        return {name, change: undefined};
    } else {
        mapboxMap.addSource(name, {type, data, ...options} as GeoJSONSourceRaw);
        return {name, change: 'create'};
    }
};


/**
 * Calls setMapboxSourceAndLayers on multiple sets of mapSourceVisuals
 * @param mapboxMap
 * @param mapSourceVisuals
 * @param [zoomToSources] Default false, zoom to the sources
 */
export const setMapboxSourceAndLayersSets = (
    {mapboxMap, mapSourceVisuals, zoomToSources = false}:
        {
            mapboxMap: Map,
            mapSourceVisuals: MapSourceVisual[],
            zoomToSources: boolean
        }
): void => {
    const changeStatuses: ChangeStatus[] = map(
        mapSourceVisual => setMapboxSourceVisual({mapboxMap, mapSourceVisual}),
        mapSourceVisuals
    );
    // Zoom to the total bbox of the sources if zoomToSources is true and any of the sources actually changed
    if (zoomToSources &&
        any(status => Boolean(prop('change', status)), changeStatuses) &&
        length(mapSourceVisuals)
    ) {


        const bboxableFeatures = chain(
            (source: MapSourceInfo) => {
                const featureOrFeatureCollection = source.data
                return (featureOrFeatureCollection.type == 'FeatureCollection') ? featureOrFeatureCollection.features : toArrayIfNot(featureOrFeatureCollection)
            },
            mapObjToValues(prop('source'), mapSourceVisuals)
        )
        const encompassingBbox: BBox2d = reduce(
            (previousBbox: BBox2d, feature: FeatureU): BBox2d => {
                return addIndex(zipWith)(
                    (l: number, r: number, index: number): number => {
                        // take the min of the min lat lon and the max of the max lat lon
                        return index < 2 ? Math.min(l, r) : Math.max(l, r);
                    },
                    // SW pair to NE pair
                    previousBbox,
                    // SW pair to NE pair
                    bbox(feature) as BBox2d
                );
            },
            [Infinity, Infinity, -180, -90],
            bboxableFeatures
        );
        // For debugging
        // const geojson = bboxPolygon(encompassingBbox);
        mapboxMap.fitBounds(encompassingBbox, {
            essential: true,
        });
    }
};

/**
 * Given a Mapbox source and layers that style it, update or create the source
 * and remove/create the layers
 * @param mapboxMap
 * @param mapSourceVisual
 * @returns {Object} {name: source name, change: 'create'|'update'|null} where null is returned
 * if the data reference did not change since the last setData
 */
export const setMapboxSourceVisual = ({mapboxMap, mapSourceVisual}: {
    mapboxMap: Map,
    mapSourceVisual: MapSourceVisual
}): ChangeStatus => {
    const {source, layers} = mapSourceVisual;
    const changeStatus: ChangeStatus = updateOrCreateMapboxSourceIfNeeded(
        {
            mapboxMap,
            sourceInfo: source
        }
    );
    const layerIds = map(prop('id'), layers);
    const matchingLayerLookup = indexBy(
        prop('id'),
        filter(
            layer => {
                return includes(layer.id, layerIds);
            },
            mapboxMap.getStyle().layers
        )
    );

    forEach((layer: MapboxLayer): void => {
        if (has(layer.id, matchingLayerLookup)) {
            // TODO I don't know how bad this is for performance, preferably we could just update anything that changed
            mapboxMap.removeLayer(layer.id);
        } else {
            // New layer, see if any icons are defined
            if (layer.iconConfig) {
                const {iconConfigs, width, height} = layer.iconConfig
                forEach((mapboxIconConfig: MapboxIconConfig) => {
                        const {svg, name} = mapboxIconConfig
                        if (!mapboxMap.hasImage(name)) {
                          const img: HTMLImageElement = new Image(width, height);
                          img.onload = () => mapboxMap.addImage(name, img);
                          img.src = svg
                          return img;
                        }
                    },
                    iconConfigs
                )
            }
        }
        mapboxMap.addLayer(layer);
    }, layers);
    return changeStatus;
};

/***
 * Remove sources and layers matching the sourcePrefix and layerPrefix that are not in
 * the exclude mapSourceVisuals
 * @param layerPrefix
 * @param sourcePrefix
 * @param exclude mapSourceVisuals to not remove
 * @param mapboxMap
 */
export const removeMapboxLayersAndSources = (
    {
        layerPrefix,
        sourcePrefix,
        preserveSourceVisuals=[],
        mapboxMap
    }: {
        layerPrefix: string,
        sourcePrefix: string,
        preserveSourceVisuals: MapSourceVisual[],
        mapboxMap: Map
    }) => {
    // @ts-expect-error too complicated for now
    const excludeSourcesById = groupBy(
        prop('name'),
        map(prop('source'), preserveSourceVisuals)
    );
    // @ts-expect-error too complicated for now
    const excludeLayersById = groupBy(
        prop('id'),
        chain(prop('layers'), preserveSourceVisuals)
    );
    forEach(
        layer => {
            if (startsWith(layerPrefix, layer.id)) {
                if (!propOr(false, layer.id, excludeLayersById)) {
                    mapboxMap.removeLayer(layer.id);
                }
            }
        },
        mapboxMap.getStyle().layers
    );
    forEachObjIndexed(
        // @ts-expect-error too complicated for now
        (source, id) => {
            // @ts-expect-error too complicated for now
            if (startsWith(sourcePrefix, id)) {
                // @ts-expect-error too complicated for now
                if (!propOr(false, id, excludeSourcesById)) {
                    // @ts-expect-error too complicated for now
                    mapboxMap.removeSource(id);
                }
            }
        },
        mapboxMap.getStyle().sources
    );
};
