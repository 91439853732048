import React from 'react';
import {Box} from "@mui/material";
import {pieArcLabelClasses, PieChart} from '@mui/x-charts/PieChart';
import {any, map, values, prop, zip, zipWith} from 'ramda'
import {useElementSize} from "usehooks-ts";
import {RideComfortGauge} from "../../../../types/rideComfort/rideComfortGauge";

/**
 * Shows a donut chart of the ride comfort warning levels
 * @param parentRef
 * @param props
 * @constructor
 */
const RideComfortGaugeComponent = ({rideComfortGauge }: {rideComfortGauge: RideComfortGauge}) => {

    // Take the three RideComfortGaugeValue.value of RideComfortGauge
    // The order is deterministic
    const _data: number[] = map(
        prop('value'),
        values(rideComfortGauge)
    )

    const anyPositive = any(
        (datum: number) => datum > 0,
        _data
    )
    const colors = anyPositive ? ['#6FCF97', '#F2C94C', '#EB5757'] : ['#D0D0D010']
    const dataValues = anyPositive ? _data : [1]
    const labels = anyPositive ? ['Normal', 'Warning', 'Critical'] : ['No Data']

    const colorsAndLabels = zip(colors, labels)
    const data = zipWith(
        (value, [color, label]) => {
            return {value, label, color}
        },
        dataValues,
        colorsAndLabels
    )

    const [ref, {
        width,
        height
    }] = useElementSize();

    const radius = (Math.min(width, height) / 2) - 5
    const series = [
        {
            innerRadius: radius / 1.5,
            outerRadius: radius - 5,
            cx: radius,
            // Don't set cy since the height can be greater than the radius, let it center itself
            highlightScope: {faded: 'global', highlighted: 'item', innerRadius: 30},
            highlighted: {innerRadius: 0},
            faded: {color: 'gray'},
            data
        }
    ]

    return <Box {...{
        ref,
        sx: {
            flex: 1,
            p: 0.25,
        }
    }}>
        <PieChart {...{
            series,
            slotProps: {
                legend: {
                    hidden: true
                },
            },
            sx: {
                [`& .${pieArcLabelClasses.root}`]: {
                    fill: 'white',
                    fontWeight: 'bold',
                },
            }
        }}/>
    </Box>
}
RideComfortGaugeComponent.displayName = 'RideComfortGaugeComponent'
export default RideComfortGaugeComponent
