import { createElement, useEffect, useState } from 'react';
import { toArrayIfNot } from '@rescapes/ramda';
import { addIndex, map } from 'ramda';
import { createPortal } from 'react-dom';

/***
 * Saves explicitly specify props on the component.
 * Creates a React element from the given component with the given children functional components,
 * passing the props from the parent to the children.
 * @param {Object} component The component to createElement on
 * @param {Object|Function} children Children component(s) or a render function
 * @return {*}
 */
export const createElementWithChildren = (component, children) => {
  /**
   * @param {Object} props Props from the parent's render function that are passed to the component
   * when created
   * @return {Object} The created React element from the component
   */
  const c = props => {
    return createElement(component, { key: component.displayName || 'UnknownComponentWithChildren', ...props }, children);
  };
  c.displayName = `${component.displayName}Renderer`;
  return c;
};

/**
 * creatElement for each of children with the given props
 * @param props
 * @param children
 * @return {*}
 */
export const createChildElements = (props, children) => {
  return addIndex(map)(
    (child, index) => {
      // createElement for one or more children
      return createElement(child, { key: child.displayName || index, ...props });
    },
    toArrayIfNot(children)
  );
};


/**
 * Clears the portal container before setting the component
 * @constructor
 * @param component
 * @param container
 * @returns {ReactPortal}
 */
export const Portal = ({ component, container }) => {
  const [innerHtmlEmptied, setInnerHtmlEmptied] = useState(false)
  useEffect(() => {
    if (!innerHtmlEmptied) {
      container.innerHTML = ''
      setInnerHtmlEmptied(true)
    }
  }, [innerHtmlEmptied])
  if (!innerHtmlEmptied) return null
  return createPortal(component, container)
}
