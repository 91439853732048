import { reqStrPathThrowing } from '@rescapes/ramda';
import { map, length, any, join } from 'ramda';
import { extremes } from 'utils/functional/functionalUtils.js';
import { format } from 'date-fns';
import { trainDataFriendlyDatetimeFormatString } from 'appUtils/trainAppUtils/trainDataUtils.js';

/**
 * Returns a unique label for the TrainRun
 * @param formation
 * @returns {*}
 */
export const userTrainRunIntervalUniqueLabel = ({t, userTrainRunInterval}) => {
  return join('\n', [
    `${t('formation')}: ${join(' ', namesOfFormationExtremeVehicles(userTrainRunInterval.trainRunInterval.trainRun.trainFormation))}`,
    `${t('departure')}: ${format(userTrainRunInterval.trainRunInterval.trainRun.departureDatetime, trainDataFriendlyDatetimeFormatString)}`
  ])
}
/**
 * Returns the locomotive names of the formation (trainset)
 * @param formation
 * @returns {*}
 */
export const namesOfFormationExtremeVehicles = formation => {
  return map(reqStrPathThrowing('vehicle.name'), extremes(formation.orderedVehicles))
}

/**
 * Returns true if any formation.orderedVehicles[*].vehicle has vehicleCollectionDevices
 * @param formation
 * @returns {*}
 */
export const formationHasCollectionDevices = formation => {
  return any(orderedVehicle => {
    return length(orderedVehicle.vehicle.vehicleCollectionDevices);
  }, extremes(formation.orderedVehicles))
}