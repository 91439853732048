import { ROUTE_LINE_HEIGHT, STATION_DOT_HEIGHT } from 'theme/train/trainThemeConstants.js';
import { addIndex, always, cond, map, T } from 'ramda';
import { Box } from '@mui/material';
import { strPathOr } from '@rescapes/ramda';

/**
 * AreaChart. Use either a ref-setter function or React.createRef().
 * @param userTrainRunInterval
 * @param isOver
 * @param stopGaps
 * @param {Boolean} spaceGeospatially if true we are spacing absolutely by percent. Otherwise we space
 * based on the given stopGaps[*].offsetLefts, which are in pixels
 * @returns {JSX.Element}
 * @constructor
 */
const StationLine = (
  {
    userTrainRunInterval,
    isOver,
    stopGaps,
    spaceGeospatially
  }) => {
  const lines = addIndex(map)((stopGap, index) => {
    const [startStationOffsetLeft, endStationOffsetLeft] = stopGap.offsetLefts;
    const unit = spaceGeospatially ? '%' : 'px'
    const left = spaceGeospatially ?
      `${startStationOffsetLeft}${unit}` :
      `calc(${STATION_DOT_HEIGHT} / 2 + ${startStationOffsetLeft}${unit})`
    const width  =spaceGeospatially ?
      `${endStationOffsetLeft - startStationOffsetLeft}${unit}` :
      `calc(${STATION_DOT_HEIGHT} / 2 + ${endStationOffsetLeft - startStationOffsetLeft}${unit})`

    const color = cond([
      [always(isOver),  always('trainRunLine.action.isOver' )],
      [strPathOr(false, 'activity.isBaseline'),  always('compare.baseline.main' )],
      [T,  always('trainRunLine.main')]
    ])(userTrainRunInterval)
    return <Box
      key={`stationLine${index}`}
      sx={{
        position: 'absolute',
        top: `calc((${STATION_DOT_HEIGHT} / 2) - (${ROUTE_LINE_HEIGHT} / 2))`,
        left,
        width,
        height: ROUTE_LINE_HEIGHT,
        borderBottomWidth: ROUTE_LINE_HEIGHT,
        borderBottomStyle: stopGap.gap ? 'dashed' : 'solid',
        borderBottomColor: color
      }}
    />;
  }, stopGaps);
  return <Box sx={{width: '100%'}}>
    {lines}
  </Box>;
};
export default StationLine;