import mapboxgl, {AnyLayer, LngLatBoundsLike, Map, MapLayerMouseEvent, Popup} from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import {compact} from '@rescapes/ramda';
import {forEach, map} from 'ramda';
import {DependencyProps} from '../../../../railbedTypes/propTypes/dependencyProps';
import {ChartPayloadItemMinimized} from '../../../../railbedTypes/dataVisualizations/chartPayloadItem';
import {MapSourceVisual} from '../../../../railbedTypes/mapbox/mapSourceVisual';
import {TrainRunGroup} from '../../../../railbedTypes/trainRuns/trainRunGroup';
import {FeatureU} from '../../../../railbedTypes/geometry/geojsonUnions';
import {MapboxPosition} from '../../../../railbedTypes/mapbox/mapboxPosistion';
import {Setter} from '../../../../railbedTypes/hookHelpers/setter';
import {Perhaps} from '../../../../railbedTypes/typeHelpers/perhaps';
import {TRAIN_MAP_PITCH} from '../../../../railbedConfig/appConfigs/cemitAppConfigs/cemitMapConfig.ts';
import {AppSettings} from "../../../../railbedConfig/appConfigs/appConfig.ts";
import {useNotLoadingEffect} from "../../../../railbedUtils/hooks/useMemoHooks.ts";

const {MAPBOX_DIV_ID, MAPBOX_STYLE} = AppSettings;

// TODO Move to .env!
const CEMIT_MAPBOX_API_TOKEN = 'pk.eyJ1IjoiY2VtaXQiLCJhIjoiY2toeDUzbGhqMGYwcjM3bmJyeXE5NHp0MiJ9.HVOiUZ_w0rEg1gAncylB5Q';


/**
 * Mounts a map. TODO move style railbedConfig to a configuration
 * @param [bbox] Optional bounding box to pan and zoom to
 * @param [center] Optional center lon, lat array to pan to
 * @param [zoom] Optional zoom level
 * @returns {Object} The Mabox map instance
 */
const mountMap = (
    {
        bounds = undefined,
        center = undefined,
        zoom = undefined
    }: MapboxPosition): Map => {

    mapboxgl.accessToken = CEMIT_MAPBOX_API_TOKEN;

    const position = compact({
        center,
        zoom,
        bounds
    }) as MapboxPosition;

    return new mapboxgl.Map({
        container: MAPBOX_DIV_ID,
        style: MAPBOX_STYLE,
        antialias: true,
        pitch: TRAIN_MAP_PITCH,
        ...position
    });
};

/**
 * Mounts the mapbox map
 * @param loading
 * @param isTrainMapMounted
 * @param bbox
 * @param setTrainMap Setter to set the Mapbox map
 * @param setTrainMapLoading Setter to indicate loading
 * @param setIsTrainMapMounted Setter to indicate the map is mounted
 */
export const useTrainMap = (
    {
        loading,
        isTrainMapMounted,
        bounds,
        setTrainMap,
        setTrainMapLoading,
        setIsTrainMapMounted
    }: {
        loading: boolean,
        isTrainMapMounted: boolean,
        bounds: Perhaps<LngLatBoundsLike>,
        setTrainMap: Setter<Map | undefined>,
        setTrainMapLoading: Setter<boolean>,
        setIsTrainMapMounted: Setter<boolean>
    }): void => {

    useNotLoadingEffect(loading, () => {
        if (!isTrainMapMounted) {
            const trainMap = mountMap({bounds});
            setTrainMap(trainMap);

            trainMap.on('style.load', () => {
                trainMap.resize()
                // This has to be done because Mapbox doesn't correctly report
                // the loading of styles
                const waiting = () => {
                    if (!trainMap.isStyleLoaded()) {
                        setTimeout(waiting, 200);
                    } else {
                        setTrainMapLoading(false);
                    }
                };
                waiting();
            });
            setIsTrainMapMounted(true);
        }
    }, [isTrainMapMounted]);
};

/**
 *  When the user moves their mouse over the given layers,
 *  calls onHover on the first feature
 * @param appProps
 * @param organizationProps
 * @param trainMap The TrainMap
 * @param mapSourceVisuals Sets of {source, layers, trainRunGroup} where
 * @param createOnHover Currently only used to show a hover for the train switches on the map. Could be used
 * for other map based hover
 * @param t The translation property
 * @param onClickOnly Default false. If true, require a click to activate the onHover
 * trainRunGroup is only set if the layer is specific to a TrainRun
 */
export const setMapboxOnHover = (
    {
        appProps,
        organizationProps,
        trainProps,
        mapProps,
        dataProps: {
            mapSourceVisuals,
            createOnHover,
            onClickOnly = false
        }
    }: DependencyProps & {
        dataProps: {
            mapSourceVisuals: MapSourceVisual[],
            createOnHover: (
                {appProps, organizationProps, trainProps, mapProps, eventProps}: DependencyProps & {
                    eventProps: {
                        trainRunGroup: TrainRunGroup,
                        hoveredStateId: string,
                        e: MapLayerMouseEvent,
                        popup: Popup
                    }
                },
                payload: ChartPayloadItemMinimized[]
            ) => void,
            onClickOnly: boolean
        }
    }
) => {
    const {trainMap} = mapProps;
    forEach(({layers, trainRunGroup}) => {
            let hoveredStateId: string | undefined;
            forEach((layer: AnyLayer) => {
                    const popup = new mapboxgl.Popup({
                        closeButton: false,
                        closeOnClick: true
                    });
                    const mapboxEvent = onClickOnly ? 'click' : 'mouseenter';
                    if (onClickOnly) {
                        trainMap.on('mouseenter', layer.id, () => {
                            // Change the cursor style as a UI indicator.
                            trainMap.getCanvas().style.cursor = 'pointer';
                        });
                    }
                    // On mouseenter or onclick
                    trainMap.on(mapboxEvent, layer.id, (e: MapLayerMouseEvent) => {
                        if (!onClickOnly) {
                            // Change the cursor style as a UI indicator.
                            trainMap.getCanvas().style.cursor = 'pointer';
                        }
                        // Imitate recharts payload format
                        const chartStylePayloadFromFeatures = (features: FeatureU[]) => {
                            return map((feature: FeatureU): ChartPayloadItemMinimized => {
                                // @ts-expect-error too complicated for now
                                return {payload: feature};
                            }, features);
                        };

                        const payload: ChartPayloadItemMinimized[] = chartStylePayloadFromFeatures(e.features as FeatureU[]);
                        if (createOnHover) {
                            createOnHover({
                                appProps,
                                organizationProps,
                                trainProps,
                                mapProps,
                                eventProps: {
                                    e,
                                    popup,
                                    trainRunGroup,
                                    hoveredStateId: hoveredStateId as string
                                }
                            }, payload);
                        }
                    });

                    trainMap.on('mouseleave', layer.id, () => {
                        trainMap.getCanvas().style.cursor = '';
                    });
                },
                layers
            );
        },
        mapSourceVisuals
    );
};
