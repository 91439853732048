
// Deserialize json with keys as a list of expected date props occuring at any level
import { includes } from 'ramda';

export const parseDates = keys => {
  return (key, value) => {
    // Deserialize any dates
    return includes(key, keys) ? new Date(value) : value;
  };
};

//https://stackoverflow.com/questions/13861254/json-stringify-deep-objects/57193345#57193345
export const stringify = (val, depth, replacer, space, onGetObjID) => {
  depth = isNaN(+depth) ? 1 : depth;
  const recursMap = new WeakMap();
  function _build(val, depth, o, a, r) { // (JSON.stringify() has it's own rules, which we respect here by using it for property iteration)
    return !val || typeof val != 'object' ? val
      : (r = recursMap.has(val), recursMap.set(val,true), a = Array.isArray(val),
        r ? (o=onGetObjID&&onGetObjID(val)||null) : JSON.stringify(val, function(k,v){ if (a || depth > 0) { if (replacer) v=replacer(k,v); if (!k) return (a=Array.isArray(v),val=v); !o && (o=a?[]:{}); o[k] = _build(v, a?depth:depth-1); } }),
        o===void 0 ? (a?[]:{}) : o);
  }
  return JSON.stringify(_build(val, depth), null, space);
}