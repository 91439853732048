import { chain, fromPairs, map, prop, props } from 'ramda';
import { trackLineSegments } from 'appUtils/trainAppUtils/trainDataUtils.js';
import bbox from '@turf/bbox';
import { mapboxGeojsonSource } from 'utils/map/mapboxSourceUtils.js';
import { asFeatureCollection } from 'utils/geojson/geojsonUtils.js';
import { colors } from 'theme/colors.ts';
import center from '@turf/center';
import {
  MAP_RAILWAY_LAYER,
  MAP_RAILWAY_SOURCE,
  MAP_TRACK_SWITCH_LAYER,
  MAP_TRACK_SWITCH_SOURCE
} from 'appConfigs/trainConfigs/trainConfig.js';

/**
 * Uses derived geojson properties from trainRoute and adds a bounding box
 * @param {Object} config
 * @param {Object} config.trainRoute The current Route. The track data return matches the direction of the trainRoute
 * @returns { trackSingleLineString, lineSegments, trackPointsFeatureCollection, boundingBox, center };
 */
export const derivedTrackOfTrainRoute = ({ trainRoute }) => {
  const trackWithMaybeReversedLineStringFeature = trainRoute.orderedTracks;
  const trackSingleLineString = trainRoute.singleTrackLineString;
  const lineSegments = trackLineSegments(trackSingleLineString);
  const trackPointsFeatureCollection = {
    type: 'FeatureCollection',
    features: map(prop('lineStringFeature'), trackWithMaybeReversedLineStringFeature)
  };
  const boundingBox = bbox(trackSingleLineString);
  return {
    trackSingleLineString,
    lineSegments,
    trackPointsFeatureCollection,
    boundingBox,
    center: center(trackSingleLineString),
    // Report of what tracks are reversed
    // This helps us convert imuPoint s prop to meters, since s is a track specific from the reference stop
    sortedTrackPointFeaturesWithTrackAndReversedFlags: trackWithMaybeReversedLineStringFeature
  };
};

/**
 * Returns the tracks of the given railwayLines. We currently only expect one Track instance per RailwayLine,
 * but this could change in the future if we define track segments or similar
 * @param {[Object]} railwayLines
 * @return {[Object]}
 */
export const tracksOfRailways = railwayLines => {
  return chain(
    prop('tracks'),
    railwayLines
  );
};

/**
 * Converts tracks of the current railwayLines to Mapbox geojson sources
 * @param trainProps
 * @param trainProps.railwayLineProps
 * @param trainProps.railwayLineProps.railwayLines
 * @returns {*}
 */
export const railwayLineMapboxSourcesAndLayersSets = ({ trainProps }) => {
  // Adding multiple layers so we can have different colors on each different railroad
  // sources is [{source: source key, wasAdded: true|false}, ...]
  return map(
    track => {
      const sourceName = `${MAP_RAILWAY_SOURCE}-${track.sourceKey}`;
      const layerId = `${MAP_RAILWAY_LAYER}-${track.sourceKey}`;
      const layer = {
        id: layerId,
        type: 'line',
        source: sourceName,
        layout: {
          // TODO move to a Mapbox style config
          'line-join': 'round',
          'line-cap': 'round'
        },
        paint: {
          'line-color': [
            'case',
            ['boolean', ['feature-state', 'hover'], false],
            // TODO get styles from MUI theme
            colors.trackGreenLighter,
            colors.trackGreen
          ],
          'line-width': 5
        }
      };
      // Add the source if not yet defined
      return {
        source: mapboxGeojsonSource({
          sourceName,
          featureCollection: asFeatureCollection(track.geojson)
        }),
        layers: [layer]
      };
    },
    trainProps.trainRouteProps.trainRoute.orderedTracks
  );
};

/**
 * Converts tracks of the current railwayLines to Mapbox geojson sources
 * @param trainProps
 * @param trainProps.railwayLineProps
 * @param trainProps.railwayLineProps.railwayLines
 * @returns {*}
 */
export const trackSwitchesMapboxSourcesAndLayersSets = ({ trainProps }) => {
  // Adding multiple layers so we can have different colors on each different railroad
  // sources is [{source: source key, wasAdded: true|false}, ...]

  return map(
    track => {
      const sourceName = `${MAP_TRACK_SWITCH_SOURCE}-${track.sourceKey}`;
      const layerId = `${MAP_TRACK_SWITCH_LAYER}-${track.sourceKey}`;
      const layer = {
        id: layerId,
        'type': 'circle',
        source: sourceName,
        paint: {
          'circle-radius': 4,
          'circle-color': 'rgba(255,255,255,0.74)'
        }
        /*
        TODO svg symbol not working
        type: 'symbol',
        source: sourceName,
        layout: {
          'icon-image': 'track-switch', // reference the image
          'icon-size': 0.25
        }
         */
      };
      // Add the source if not yet defined
      return {
        source: mapboxGeojsonSource({
          sourceName,
          featureCollection: track.switchesGeojson
        }),
        layers: [layer]
      };
    },
    tracksOfRailways(trainProps.railwayLineProps.railwayLines)
  );
};

/**
 * Returns a lookup of railway sourceKey to the Railway's ReferenceScheduledStopPoint,
 * e.g. {'drammenbanen': {name: 'Oslo sentralstasjon, ...}, ...}
 * @param railwayLines
 * @returns {*}
 */
export const railwayLineSourceKeyToReferenceScheduledStopPoint = railwayLines => {
  return fromPairs(map(props(['sourceKey', 'referenceScheduledStopPoint']), railwayLines));
};
