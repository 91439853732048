import { Box } from '@mui/material';
import { strPathOr, toArrayIfNot } from '@rescapes/ramda';
import LoaderWithText from 'components/loading/LoaderWithText.js';
import { any } from 'ramda';
import UserTrainRunErrorButton
  from 'components/apps/trainAppComponents/trainRunChooserComponents/UserTrainRunErrorButton.js';
import { MAPBOX_DIV_ID } from 'appConfigs/appConfig.js';

/**
 * The Mapbox map for the TrainApp
 * @param componentProps
 * @param componentProps.loading
 * @param componentProps.updating
 * @param {Object|Array} sx Top-level style object
 * @returns {JSX.Element}
 * @constructor
 */
const TrainMap = ({ trainProps, mapProps, sx = [] }) => {
  // Loading comes from mapProps.loading. updating from mapProps.geojsonProps updating.
  const { loading, geojsonProps: { loading: updating }, loadingExplanation } = mapProps;
  // The former indicates loading the map initially. The latter indicates waiting for geojson to be ready
  const loadingMapLabel = loading ? 'loadingMap' : 'updatingMap';
  const error = any(
    userTrainRunInterval => {
      return strPathOr(false, 'trainRunInterval.trainRun.error', userTrainRunInterval) &&
        !strPathOr(false, 'trainRunInterval.trainRun.loading', userTrainRunInterval)
    },
    // If we have none active without errors, look for error in all that are active
    trainProps.userTrainRunIntervalProps.activeUserTrainRunIntervalsWithoutErrors ||
    trainProps.userTrainRunIntervalProps.activeUserTrainRunIntervals ||
    []
  );
  const loadingOrUpdating = loading || updating;
  return <Box
    sx={
      [{
        overflow: 'hidden',
        width: 'inherit',
        maxWidth: 'inherit',
        right: 0
      },
        ...toArrayIfNot(sx)
      ]}
  >

    <Box sx={{
      position: 'relative',
      top: 0,
      bottom: 0,
      width: '100%',
      height: '100%',

      '&:after': {
        position: 'absolute',
        content: '""',
        height: '100%',
        width: '20px',
        left: 0,
        top: 0,
        zZndex: 99,
      }
    }}>
      <Box sx={{
        width: '100%',
        height: '100%',
        position: 'relative'
      }}>
        <Box sx={{
          zIndex: 100,
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)'
        }}>{
          !error && loadingOrUpdating &&
          <LoaderWithText {...{ spinner: true, text: loadingMapLabel, loadingExplanation }} />
        }
          {
            error && <UserTrainRunErrorButton {...{
              userTrainRunIntervals: trainProps.userTrainRunIntervalProps.activeUserTrainRunIntervals,
              crudUserTrainRunIntervals: trainProps.userTrainRunIntervalProps.crudUserTrainRunIntervals
            }} />
          }
        </Box>
        <Box key='trainMap' id={MAPBOX_DIV_ID} sx={{
          top: 0,
          bottom: 0,
          width: '100%',
          height: '100%'
        }} />
      </Box>
    </Box>
  </Box>;
};

export default TrainMap;
