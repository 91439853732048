import React from 'react';
import { Box } from '@mui/material';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    console.error(error);
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    console.error({ error, errorInfo });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Box sx={{
          height: '100vh',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          background: '#3a4750',
          color: 'white',
          button: {
            margin: '20px',
            border: '#f6c90e 1px solid',
            borderRadius: '5px',
            padding: '10px',
            color: '#f6c90e'
          }
        }}>
          <p>
            Oops, something went wrong. Try reloading page or check if url is
            wrong
            {/* Check browser console */}
          </p>
          <button onClick={() => location.reload()}>
            Click to reload page
          </button>{' '}
        </Box>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
