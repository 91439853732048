import { add, always, equals, filter, fromPairs, head, ifElse, lensProp, map, over, props, set, when } from 'ramda';
import lineSliceAlong from '@turf/line-slice-along';
import { distanceRangeIsZero, featureWithinDistanceRange } from 'utils/distance/distanceUtils.js';
import { reqStrPathThrowing } from '@rescapes/ramda';
import { trainRunOfUserTrainRunInterval } from 'appUtils/trainAppUtils/userTrainRunIntervalUtil.js';
import {
  convertDistanceRangeFromAggregateToTrainRoute,
  createRouteDomainRangeFromAggregateConverter
} from 'appUtils/trainAppUtils/trainRouteUtils.js';
import { performanceWrapper } from 'utils/profiling/profilingUtils.js';
import { memoizedTransformGeojsonToNearest, transformLineSegment } from 'appUtils/trainAppUtils/trainDataUtils.js';

/**
 * Updates the given userTrainRunIntervals to have a geojson property with variaous featureCollections
 * and saves them to crudUserTrainRunIntervals
 * Here we limit the baseline UserTrainRunInterval, if included
 * to the min start and max end distances of the other userTrainRunIntervals
 * so that the chart doesn't always show the full distance range because
 * of the baseline
 * @param trainRouteAggregateInterval Contains the distanceRange that is adjustable by the user.
 * We map this to the domain of the each UserTrainRunInterval's underlying TrainRun TrainRoute
 * if aggregateTrainRouteOrGroup is a TrainRouteGroup and thus not the same as the TrainRoute
 * @param minimumTrainRunsWithImuPoints Check if this changes for the given TrainRun and if so recalcuate its geojson
 * @param userTrainRunIntervals
 * @returns {*}
 */
export const userTrainRunIntervalGeojsons = performanceWrapper(
  'userTrainRunIntervalGeojsons', (
    {
      trainRouteAggregateInterval,
      minimumTrainRunsWithImuPoints
    },
    userTrainRunIntervals) => {

    const trainRunIdToDistanceIntervalsAndRanges = fromPairs(map(
      props(['id', 'distanceIntervalsAndRanges']),
      minimumTrainRunsWithImuPoints
    ));

    // Filter userTrainRunIntervals to get those that need to update their geojson
    const userTrainRunIntervalsNeedingGeojson = userTrainRunIntervalsNeedingUpdatedGeojson({
        trainRunIdToDistanceIntervalsAndRanges,
        trainRouteAggregateInterval,
        userTrainRunIntervals
      }
    );

    // Set the geojson for the userTrainRunIntervals if not set
    const updatedUserTrainRunIntervals = map(
      userTrainRunInterval => {
        const trainRunId = userTrainRunInterval.trainRunInterval.trainRun.id;
        const distanceIntervalsAndRanges = trainRunIdToDistanceIntervalsAndRanges[trainRunId];

        // TODO this currently only takes userTrainRunInterval's cdc device data with the most data
        // Override the UserTrainRun interval to overrideDistanceRange which comes from the aggregate set by the user
        const featureCollections = userTrainRunIntervalGeojson(
          {
            distanceIntervalsAndRanges,
            trainRouteAggregateInterval
          },
          userTrainRunInterval
        );
        return set(
          lensProp('geojson'),
          featureCollections,
          userTrainRunInterval
        );
      },
      userTrainRunIntervalsNeedingGeojson
    );
    return updatedUserTrainRunIntervals;
  });

/**
 * Filters for the UserTrainRunIntervals that don't ye have their geojson set or don't have
 *  geojson.distanceRange matching  updatedUserTrainRunInterval.trainRunInterval.distanceRange,
 * @param trainRunIdToDistanceIntervalsAndRanges
 * @param trainRouteAggregateInterval Contains the distanceRange that is adjustable by the user.
 * We map this to the domain of the each UserTrainRunInterval's underlying TrainRun TrainRoute
 * if aggregateTrainRouteOrGroup is a TrainRouteGroup and thus not the same as the TrainRoute
 * @param userTrainRunIntervals
 * @returns {[Object]} The filtered UserTrainRunIntervals
 */
const userTrainRunIntervalsNeedingUpdatedGeojson = (
  {
    trainRunIdToDistanceIntervalsAndRanges,
    trainRouteAggregateInterval,
    userTrainRunIntervals
  }) => {
  const aggregateTrainRouteOrGroup = trainRouteAggregateInterval.trainRoute;
  const distanceRange = trainRouteAggregateInterval.distanceRange;
  return filter(userTrainRunInterval => {
      const trainRunId = userTrainRunInterval.trainRunInterval.trainRun.id;
      const distanceIntervalsAndRanges = trainRunIdToDistanceIntervalsAndRanges[trainRunId];

      // Override distanceRange of each UserTrainRunInterval
      const convertedDistanceRange = convertDistanceRangeFromAggregateToTrainRoute({
          aggregateTrainRouteOrGroup,
          trainRoute: trainRunOfUserTrainRunInterval(userTrainRunInterval).trainRoute
        },
        distanceRange
      );

      // Don't recalculate if the previous geojson calculation was with the same distanceRange
      // and same loaded distanceIntervalsAndRanges
      return !(userTrainRunInterval.geojson &&
        equals(
          convertedDistanceRange,
          userTrainRunInterval.geojson.distanceRange
        ) &&
        equals(
          distanceIntervalsAndRanges,
          userTrainRunInterval.geojson.distanceIntervalsAndRanges
        )
      );
    },
    userTrainRunIntervals
  );
};

/**
 * Calculate geojson for the UserTrainRunInterval and return the distanceRange used for the calculation
 * so we know when to recalcutate
 * @param trainRouteAggregateInterval
 * @param distanceIntervalsAndRanges the distance intervals and ranges in the trainRun imuPoints that we
 * are calculating geojson for. Only used to compare with future calculations to see if we need to
 * calculate geojson again
 * @param userTrainRunInterval
 *
 * @returns {{featureCollectionLine, featureCollectionPoints, distanceRange: (Object|Number|*)}}
 */
export const userTrainRunIntervalGeojson = (
  {
    trainRouteAggregateInterval,
    distanceIntervalsAndRanges
  }, userTrainRunInterval) => {

  const trainRoute = trainRunOfUserTrainRunInterval(userTrainRunInterval).trainRoute;
  const convert = createRouteDomainRangeFromAggregateConverter({
    aggregateTrainRouteOrGroup: trainRouteAggregateInterval.trainRoute, trainRoute
  });
  const convertedDistanceRange = convertDistanceRangeFromAggregateToTrainRoute(
    {
      aggregateTrainRouteOrGroup: trainRouteAggregateInterval.trainRoute,
      trainRoute
    },
    trainRouteAggregateInterval.distanceRange
  );

  // Using the full distanceRange, find the matching points.
  // We must use the full distanceRange because feature.meters are relative to full distanceRange
  const featureCollectionPoints = over(
    lensProp('features'),
    features => {
      return filter(
        feature => {
          // Make sure the feature is in the trainRunInterval.convertedDistanceRange
          // TODO adding 50 meters here to compensate for slight deviations in calculations
          return featureWithinDistanceRange({
            distanceRange: over(lensProp('end'), add(50), convertedDistanceRange),
            prop: properties => convert(properties['meters'])
          }, feature);
        },
        features
      );
    },
    userTrainRunInterval.trainRunInterval.trainRun.geojson
  );

  // This line FeatureCollection can be empty or have a single value.
  const featureCollectionLine = {
    type: 'FeatureCollection',
    features: ifElse(
      distanceRangeIsZero,
      () => [],
      convertedDistanceRange => {
        return [lineSliceAlong(
          trainRoute.singleTrackLineString,
          convertedDistanceRange.start,
          convertedDistanceRange.end,
          { units: 'meters' }
        )];
      }
    )(convertedDistanceRange)
  };

  return {
    featureCollectionPoints,
    featureCollectionLine,
    // These are stored for comparison to avoid recalculating geojson when non needed
    distanceRange: convertedDistanceRange,
    distanceIntervalsAndRanges
  };
};


/**
 * Returns geojson points of userTrainRunIntervals limited to the give distanceRange
 * if distanceRange is non-null. This assumes that each userTrainRunInterval already
 * has a property path geojson.featureCollectionPoints that came from userTrainRunIntervalGeojson
 * @param {Object} distanceRange Limits the returned points to this range, which is typically in meters
 * @param {Number} distanceRange.start
 * @param {Numbers} distanceRange.end
 * @param {[Object]} userTrainRunIntervals One or more UserTrainRunIntervals to extract limited or all
 * geojson.featureCollectionPoints of
 * @returns {[Object]} A list of possibly limited featureCollectionPoints for each userTrainRunInterval
 */
export const userTrainRunIntervalGeojsonsLimitedToDistanceRange = (
  {
    distanceRange
  }, userTrainRunIntervals) => {
  return map(
    userTrainRunInterval => {
      return when(
        // Limit userTrainRunInterval.geojson.featureCollectionPoints to the given limitedDistanceRange if non-null
        always(distanceRange),
        featureCollectionPoints => {
          return over(
            lensProp('features'),
            features => {
              return filter(
                feature => {
                  return featureWithinDistanceRange({
                      distanceRange: over(lensProp('end'), add(50), distanceRange),
                      prop: 'meters'
                    }, feature
                  );
                },
                features
              );
            },
            featureCollectionPoints
          );
        }
      )(reqStrPathThrowing('geojson.featureCollectionPoints', userTrainRunInterval));
    }, userTrainRunIntervals
  );
};

/**
 * Transforms the UserTrainRunIntervals geojson based on their index in the list so that we can display
 * lines on both sides of the actual track on the map. If a UserTrainRunInterval is .activity.isBaseline,
 * we don't transform so we can show the 3d geojson over the track
 * @param userTrainRunIntervalsWithGeojsonIndices UserTrainRunIntervals that each have a geojsonIndex added
 * geojsonIndex determines how far perpendicular and on which side of track the transformation occurs
 * @returns {[ {trainRunIntervalSensorPointsTransformed, trackLineTransformed}]} Array of objects
 * with  {trainRunIntervalSensorPointsTransformed, trackLineTransformed}, the first is the sensor points
 * transformed, the second is the track line transformed
 */
export const memoizedTransformUserTrainRunIntervalsGeojsonToNearest = userTrainRunIntervalsWithGeojsonIndices => {
  return map(
    userTrainRunInterval => {
      const geojsonIndex = userTrainRunInterval.geojsonIndex;
      const isBaseline = userTrainRunInterval.activity?.isBaseline;
      // We use the transformed geojson for the 3D columns
      // We use the transformed line to show the track for the portion of the UserTrainRunInterval distance range
      // underneath the 3D columns
      // If the line feature was empty, there will be no feature here, so do nothing
      const trackSingleLineString = head(userTrainRunInterval.geojson.featureCollectionLine.features);

      const trackLineTransformed = isBaseline || !trackSingleLineString ?
        trackSingleLineString :
        transformLineSegment({
            geojsonIndex,
            lineSegment: trackSingleLineString
          }
        );

      // Create a 3D column source and layers unless not dataColumns3DLayerAreVisible
      // Get TrainRunInterval sensor points. This is only the points within the interval
      const sensorPointsFeatureCollection = userTrainRunInterval.geojson.featureCollectionPoints;

      // Transform the points to the nearest point on our possibly transformed trackLine
      // Even if trackLine wasn't transformed because this is a baseline,
      // we want to normalize the sensor points to the center of the track
      const trainRunIntervalSensorPointsTransformed = trackLineTransformed && memoizedTransformGeojsonToNearest({
        userTrainRunInterval,
        lineString: trackLineTransformed,
        geojson: sensorPointsFeatureCollection
      });
      return { trainRunIntervalSensorPointsTransformed, trackLineTransformed };
    },
    userTrainRunIntervalsWithGeojsonIndices
  );
};