export const callWorkerAndGetPromise = ({ worker, workerProps }) => {
  return new Promise((resolve, reject) => {
      worker.postMessage(workerProps);
      worker.onerror = err => {
        reject(err)
      };
      worker.onmessage = e => {
        resolve(e.data)
      };
    }
  );
};