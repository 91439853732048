import {organizationConfigs} from "./rideComfortOrganizationConfig.ts"
import {equals, find} from "ramda";
import {RideComfortOrganization} from "../../types/rideComfort/rideComfortOrganization";

/**
 * Resolve the organization by key or throw if there is no match
 * @param organizationSourceKey
 */
export const resolveOrganizationConfig = (organizationSourceKey: string): RideComfortOrganization | never => {
    const organization = find(
        organizationConfig => {
            return equals(organizationSourceKey, organizationConfig.sourceKey)
        },
        organizationConfigs
    )
    if (!organization) {
        throw new Error(`Cannot find organization with key ${organizationSourceKey} in organizationConfigs`)
    }
    return organization
}
