import { strPathOr } from '@rescapes/ramda';

export const renderColorfulLegendText = value => {
  return <span
    style={{
      color: 'white',
      fontSize: 12,
      fontFamily: 'Roboto, sans-serif'
    }}
  >
        {value}
      </span>;
};
export const trackDataFeatures = trackData => {
  return strPathOr(
    [],
    `data.${trackData.featuresPath}`,
    trackData
  );
};