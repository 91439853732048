import { always, equals, map, props } from 'ramda';
import TrainMapLayerDependency from 'async/trainAppAsync/dependencies/TrainMapLayerDependency.js';
import { useMemo, useState } from 'react';
import { useNotLoadingEffect } from 'utils/hooks/useMemoHooks.js';
import { unlessLoadingProps } from 'utils/componentLogic/loadingUtils.js';
import { correctPayload } from 'appUtils/trainAppUtils/userTrainRunIntervalUtil.js';
import { useTranslation } from 'react-i18next';
/**
 * Keeps track of UserTrainRunInterval chart data
 * Depends directly on UserTrainRunIntervals' geojsonProps props
 * @param appProps
 * @param organizationProps
 * @param trainProps
 * @param children
 * @returns {*}
 * @constructor
 */
const UserTrainRunIntervalChartDependency = ({ appProps, organizationProps, trainProps, mapProps }) => {

  const loading = trainProps.userTrainRunIntervalProps.geojsonProps.loading;
  const {t} = useTranslation()
  // The xAxis domain for the charts based on trainProps.trainRouteProps.trainRouteAggregateInterval.distanceRange
  const [xAxisDomain, setXAxisDomain] = useState(null);

  // Stores the activeChartDataKey dataKey so that we can ignore the payload of the inactive one
  const [activeChartDataKey, setActiveChartDataKey] = useState(null);

  const { distanceRange, trainRunIntervalGeojsons } = unlessLoadingProps(loading, () => {
    const distanceRange = trainProps.trainRouteProps.trainRouteAggregateInterval.distanceRange;
    const trainRunIntervalGeojsons = map(
      userTrainRunInterval => {
        return userTrainRunInterval.geojson.featureCollectionPoints;
      },
      trainProps.userTrainRunIntervalProps.activeUserTrainRunIntervalsWithoutErrors
    );
    return { distanceRange, trainRunIntervalGeojsons };
  });

  // Whenever the distanceRange changes, update the xAxisDomain
  // The distanceRange can change when the user changes the aggregate TrainRunInterval bar or in the
  // future when the user zooms and pans on one of the charts or on the map if the latter is set to sync
  useNotLoadingEffect(loading,
    () => {
      const _xAxisDomain = props(
        ['start', 'end'],
        distanceRange
      );
      if (!equals(xAxisDomain, _xAxisDomain)) {
        setXAxisDomain(_xAxisDomain);
      }
    },
    [distanceRange]
  );
  // Normally the mostRecentTooltipPayload is set by hovering, but if the UserTrainRunIntervals change,
  // we need to update the mostRecentTooltipPayload here
  const userTrainRunIntervals = trainProps.userTrainRunIntervalProps.activeUserTrainRunIntervalsWithoutErrors
  useNotLoadingEffect(loading,() => {
    const correctedPayloadItems = correctPayload(
      ({
        t,
        trainRouteAggregateInterval: trainProps.trainRouteProps.trainRouteAggregateInterval,
        userTrainRunIntervals,
        payloadItems: trainProps.userTrainRunIntervalProps.sensorProps.mostRecentTooltipPayload
      })
    );
    trainProps.userTrainRunIntervalProps.sensorProps.setMostRecentTooltipPayload(correctedPayloadItems);
  }, [userTrainRunIntervals])


  const loadingExplanation = useMemo(always({
    'trainProps.userTrainRunIntervalProps.geojsonProps.loading': trainProps.userTrainRunIntervalProps.geojsonProps.loading
  }), [loading]);

  return <TrainMapLayerDependency {...{
    appProps,
    organizationProps,
    trainProps: {
      ...trainProps,
      userTrainRunIntervalProps: {
        ...trainProps.userTrainRunIntervalProps,
        geojsonProps: {
          ...trainProps.userTrainRunIntervalProps.geojsonProps,
          trainRunIntervalGeojsons
        },
        chartProps: {
          loading,
          loadingExplanation,
          xAxisDomain, setXAxisDomain,
          activeChartDataKey, setActiveChartDataKey
        }
      }
    },
    mapProps
  }} />;
};
UserTrainRunIntervalChartDependency.displayName = 'UserTrainRunIntervalChartDependency';
export default UserTrainRunIntervalChartDependency;