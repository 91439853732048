import { memoizedWith } from '@rescapes/ramda';
import { callWorkerAndGetPromise } from 'async/webWorker/workerCommunication.js';
import { memoizedTrainRunFeatureCollectionSensorPoints } from 'appUtils/trainAppUtils/trainRunGeojsonUtils.js';

/**
 * Calls memoizedTrainRunFeatureCollectionSensorPoints with a WebWorker
 */
export const workerizedTrainRunFeatureCollectionSensorPoints = memoizedWith(
  ({ trainRun, distanceIntervalsAndRanges }) => [trainRun.id, distanceIntervalsAndRanges],
  async ({ trainRoute, trainRun, distanceIntervalsAndRanges }) => {
    const trainRunGeojsonWorker = new Worker(new URL('async/webWorker/trainRunGeojsonWorker.js', import.meta.url));
    return Worker ? callWorkerAndGetPromise({
      worker: trainRunGeojsonWorker,
      workerProps: { trainRoute, trainRun, distanceIntervalsAndRanges }
    }) : memoizedTrainRunFeatureCollectionSensorPoints({ trainRoute, trainRun, distanceIntervalsAndRanges });
  }
);