import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { mapObjToValues } from '@rescapes/ramda';
import { Stack, Tooltip, Typography } from '@mui/material';
import { colors } from 'theme/colors.ts';

/**
 * @param [{Object}] apps Configured apps to show
 * @param currentPath
 * @param toggleSidebar
 * @return {JSX.Element}
 * @constructor
 */
export const CemitAppNavigationItems = ({ apps, currentPath, toggleSidebar }) => {
  const { t } = useTranslation();
  const items = mapObjToValues(({ access, name, to, Icon }, appKey) => {
      const selected = currentPath.includes(appKey);
      const color = selected ? colors.orange : (access ? 'white' : 'gray');
      const navButtonWrapper = <Tooltip  key={name} arrow {...{ title: access ? name : `${t('contactCemitForAccessTo')} ${name}` }}>
        <Stack direction='column' key={name} {...{
          sx: {
            alignItems: 'center',
            borderLeft: selected ? `3px ${colors.orange} solid` : `3px transparent solid`,
            '&:hover': {
              background: 'rgba(0, 0, 0, 0.15)'
            },
            p: {
              color,
              fontSize: '10px',
              textAlign: 'center',
              paddingBottom: '5px'
            },
            '*': {
              cursor: access ? 'cursor' : 'not-allowed'
            }
          }
        }}
        >
        <span {...{
          style: {
            width: '60px',
            height: '60px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '50%',
            marginTop: '8px',
            img: {
              width: '30px'
            }
          }
        }}>
          <Icon
            fill={color}
            stroke={color}
          />
        </span>
          <Typography sx={{ color }} variant='subitle2'>{name}</Typography>
        </Stack>
      </Tooltip>;

      return access ?
        <Link key={name} to={to} onClick={() => {
          toggleSidebar();
        }}> {navButtonWrapper}</Link> :
        navButtonWrapper;
    },
    apps
  );
  return <div> {items} </div>;
};