import TrainAppRoutes from 'components/apps/trainAppComponents/TrainAppRoutes.js';
import {toArrayIfNot} from '@rescapes/ramda';
import {Stack} from '@mui/material';
import TrainAppPageLinks from 'pages/trainApp/TrainAppPageLinks.js';
import UserTrainRunIntervalYardContainer
    from 'components/apps/trainAppComponents/trainRunChooserComponents/UserTrainRunIntervalYardContainer.js';
import TrainBoardSplitPaneHorizontal from 'pages/trainApp/trainBoard/TrainBoardSplitPaneHorizontal.js';
import {useDebounce} from 'usehooks-ts';
import {useState} from 'react';

/**
 * The left side of the TrainApp application that is not the left side menu
 * @param {Object} appProps
 * @param {Object} organizationProps
 * @param {Object} trainProps
 * @param {[Object]|Object} sx
 * @returns {JSX.Element}
 * @constructor
 */
const TrainBoard = (
    {
        appProps,
        organizationProps,
        trainProps,
        mapProps,
        sx = []
    }) => {

    const [panelSize, setPanelSize] = useState(null);
    const debouncedPanelSize = useDebounce(panelSize, 100);
    // Force change panelSize when the divider is moved
    const onChange = ({rightWidth, height}) => {
        setPanelSize({width: rightWidth, height});
    };


    return <Stack
        sx={[{
            minWidth: 0, minHeight: 0,
            overflowY: 'hidden',
            overflowX: 'hidden',
            height: '100vh',
            maxHeight: '100vh',
            spacing: 1,
            zIndex: 2
        },
            ...toArrayIfNot(sx)]
        }
    >
        <TrainAppPageLinks {...{
            key: 'trainAppPageLinks',
            currentAppPage: appProps.currentAppPage
        }} />

        {appProps.currentAppPage == 'ride_comfort' ?
            <TrainAppRoutes {...{
                key: 'trainAppRoutes',
                appProps,
                organizationProps,
                trainProps,
                mapProps,
                componentProps: {
                    panelSize: debouncedPanelSize
                }
            }} /> : <TrainBoardSplitPaneHorizontal {...{
                displayProps: appProps.displayProps,
                defaultLeftWidth: '30%',
                onChange
            }}>
                <UserTrainRunIntervalYardContainer  {...{
                    key: 'chosenTrainRunYard',
                    appProps,
                    organizationProps,
                    trainProps,
                    sx: {
                        overflow: 'hidden'
                    }
                }}
                />
                <TrainAppRoutes {...{
                    key: 'trainAppRoutes',
                    appProps,
                    organizationProps,
                    trainProps,
                    mapProps,
                    componentProps: {
                        panelSize: debouncedPanelSize
                    }
                }} />
            </TrainBoardSplitPaneHorizontal>
        }
    </Stack>;
};
export default TrainBoard;