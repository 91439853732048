import { useCallback, useEffect, useRef, useState } from 'react';
import { useResizeObserver } from './useResizeObserver.js';
import { objHasProp } from 'components/charts/stackedChart/sample/utils.js';

const CHART_AXIS_CLIP_PADDING = 50;

const CHART_CLASSES = {
  xAxis: 'xAxis',
  grid: 'recharts-cartesian-grid',
  line: 'chart-line'
};

const getZoomValues = (
  mousePosition,
  edgeTolerance = 0.05,
  zoomCoefficient = 0.25
) => {
  if (!mousePosition) {
    return { zoomLeft: 1, zoomRight: 1 };
  }

  const { x, width } = mousePosition;
  const zoomCoef = width * zoomCoefficient;
  let xToWidth = x / width;
  if (xToWidth <= edgeTolerance) {
    xToWidth = 0;
  } else if (xToWidth >= 1 - edgeTolerance) {
    xToWidth = 1;
  }

  const zoomLeft = xToWidth * zoomCoef;
  const zoomRight = zoomCoef - zoomLeft;
  return { zoomLeft, zoomRight };
};

/**
 * Enables zoom and padding. Modified from https://codesandbox.io/s/highlight-zomm-line-chart-forked-j560ov?file=/package.json
 * @param chartAxisClipPadding
 * @param chartLoaded
 * @returns {{mousePositionToGrid: *, onChartMouseDown, setWrapperRef, onChartMouseUp, zoomIn, onChartMouseMove, zoomOut, wrapperRef, xPadding: *, gridRef, clipPathRefs}}
 */
export const useZoomAndPan = (
  {
    chartAxisClipPadding = CHART_AXIS_CLIP_PADDING,
    chartLoaded
  }) => {

  // References. I don't know what these do yet

  // This is assigned to the ResponsiveContainer
  const wrapperRef = useRef(null);
  // Creates a callback for one-time setting of the wrapperRef the ResponsiveContainer passed to.
  const setWrapperRef = useCallback(e => {
    if (
      typeof e === 'object' &&
      e !== null &&
      objHasProp(e, ['current']) &&
      e.current instanceof HTMLElement
    ) {
      wrapperRef.current = e.current;
    }
  }, []);

  const clipPathRefs = useRef(null);
  const gridRef = useRef(null);
  // Reference to store the state of the chart mouse-down
  const chartMouseDown = useRef(null);
  // Reference to store the state of the chart x padding mouse-down
  const chartXPaddingOnMouseDown = useRef(null);


  // The state of x padding of the chart
  const [xPadding, setXPadding] = useState([0, 0]);

  // State to map the mouse position to the grid
  const [mousePositionToGrid, setMousePositionToGrid] = useState(null);

  // Sets the clipPathRefs.current.axis.current.width and
  // clipPathRefs.current.axis.current.style.transform and
  // clipPathRefs.current.grid.current.grid.width and
  // clipPathRefs.current.grid.current.style.transform and
  // gridRef.current.clip-path and
  // xAxis.clip-path
  const setClipPaths = useCallback(
    xAxis => {
      if (wrapperRef.current &&
        gridRef.current &&
        clipPathRefs?.current?.axis?.current &&
        clipPathRefs?.current?.grid?.current
      ) {
        const wrapperRect = wrapperRef.current.getBoundingClientRect();
        const gridRect = gridRef.current.getBoundingClientRect();
        console.log(wrapperRect);
        clipPathRefs.current.axis.current.setAttribute(
          'width',
          `${gridRect.width + chartAxisClipPadding}px`
        );
        clipPathRefs.current.axis.current.style.transform = `translateX(${
          gridRect.x - wrapperRect.x - chartAxisClipPadding / 2
        }px)`;

        clipPathRefs.current.grid.current.setAttribute(
          'width',
          `${gridRect.width}px`
        );
        clipPathRefs.current.grid.current.style.transform = `translateX(${
          gridRect.x - wrapperRect.x
        }px)`;

        gridRef.current?.setAttribute('clip-path', 'url(#chart-grid-clip)');
        xAxis.setAttribute('clip-path', 'url(#chart-xaxis-clip)');
      }
    },
    [chartAxisClipPadding]
  );

  // When the setClipsPath updates because chartAxisClipPadding, this also updates and sets
  // the cliPaths to the current value of the xAxis
  const resizeObserverCallback = useCallback(
    e => {
      console.log(e);
      if (wrapperRef.current) {
        const xAxis = wrapperRef.current.querySelector(
          `.${CHART_CLASSES.xAxis}`
        );
        if (xAxis) {
          setClipPaths(xAxis);
        }
      }
    },
    [setClipPaths]
  );

  const unobserve = useResizeObserver({
    element: wrapperRef,
    callback: resizeObserverCallback,
    delay: 100
  });

  useEffect(() => () => unobserve());

  const chartPan = (state)  => {
    if (
      chartMouseDown.current !== null &&
      state?.chartX &&
      state?.chartY &&
      chartXPaddingOnMouseDown.current
    ) {
      const xDistance = chartMouseDown.current.x - state.chartX;
      const [paddingLeft, paddingRight] = chartXPaddingOnMouseDown.current;

      const panPaddingLeft = paddingLeft - xDistance;
      const panPaddingRight = paddingRight + xDistance;

      if (panPaddingLeft > 0) {
        setXPadding(([, pr]) => [0, pr]);
        return;
      }
      if (panPaddingRight > 0) {
        setXPadding(([pl]) => [pl, 0]);
        return;
      }
      setXPadding([
        Math.min(paddingLeft - xDistance, 0),
        Math.min(paddingRight + xDistance, 0)
      ]);
    }
  };

  const onChartMouseMove = useCallback(
    (state, e) => {
      const target = e.target;
      if (chartMouseDown.current !== null) chartPan(state, e);
      if (target && clipPathRefs?.current?.axis?.current) {
        const {
          width,
          left
        } = clipPathRefs.current.axis.current.getBoundingClientRect();
        const x = Math.min(Math.max(e.clientX - left, 0), width);
        setMousePositionToGrid((state) => {
          if (!state?.width) return { x, width };
          return {
            ...state,
            x
          };
        });
      }
    },
    []
  );

  const onChartMouseDown = useCallback(
    (state) => {
      if (state) {
        const { chartX, chartY } = state;
        if (typeof chartX === 'number' && typeof chartY === 'number') {
          chartMouseDown.current = { x: chartX, y: chartY };
          chartXPaddingOnMouseDown.current = xPadding;
        }
      }
    },
    [xPadding]
  );

  const onChartMouseUp = useCallback(() => {
    chartMouseDown.current = null;
    chartXPaddingOnMouseDown.current = null;
  }, []);

  useEffect(() => {
    if (chartLoaded && wrapperRef.current) {
      const grid = wrapperRef.current.querySelector(
        `.${CHART_CLASSES.grid}`
      );

      const xAxis = wrapperRef.current.querySelector(
        `.${CHART_CLASSES.xAxis}`
      );
      gridRef.current = grid;
      if (xAxis) setClipPaths(xAxis);
    }
  }, [chartLoaded, setClipPaths]);

  const zoomOut = useCallback(() => {
    setXPadding((p) => {
      const [left, right] = p;
      const { zoomRight, zoomLeft } = getZoomValues(mousePositionToGrid);
      return [Math.min(left + zoomLeft, 0), Math.min(right + zoomRight, 0)];
    });
  }, [mousePositionToGrid]);

  const zoomIn = useCallback(() => {
    setXPadding((p) => {
      const [left, right] = p;
      const { zoomRight, zoomLeft } = getZoomValues(mousePositionToGrid);
      return [left - zoomLeft, right - zoomRight];
    });
  }, [mousePositionToGrid]);

  useEffect(() => {
    const ref = wrapperRef.current;
    const wheelHandler = (e) => {
      e.preventDefault();
      const delta = Math.sign(e.deltaY);
      if (delta < 0) {
        zoomOut();
      } else {
        zoomIn();
      }
    };

    if (chartLoaded && ref) {
      ref.addEventListener('wheel', wheelHandler, { passive: false });
    }

    return () => {
      if (ref) {
        ref.removeEventListener('wheel', wheelHandler);
      }
    };
  }, [chartLoaded, zoomIn, zoomOut]);

  return {
    wrapperRef,
    clipPathRefs,
    gridRef,
    xPadding,
    mousePositionToGrid,
    onChartMouseDown,
    onChartMouseUp,
    setWrapperRef,
    onChartMouseMove,
    zoomOut,
    zoomIn
  };
};
