import { FrontendView } from '../../railbedTypes/organizations/frontendView.ts';
import { CemitOrganization, CemitOrganizationMinimized } from '../../railbedTypes/organizations/cemitOrganization';
import { unlessLoadingValue } from '../componentLogic/loadingUtils.ts';
import { Perhaps } from '../../railbedTypes/typeHelpers/perhaps';

/**
 * Returns the frontend views configured for given organization if defined and loaded
 * @param organization
 */
export const getOrganizationViews = (organization: CemitOrganization | CemitOrganizationMinimized | undefined): FrontendView[] => {
  return organization?.frontendOptions?.views;
};

/**
 * Returns true if the organization has ServiceLines, which means they have TrainRoutes.
 * This is currently used throughout the application for conditional logic
 * Returns undefined if loading is true, meaning we can't say yet whether the organization has serviceLines
 */
export const doesOrganizationHaveServiceLines = (loading: boolean, organization: Perhaps<CemitOrganization>): Perhaps<boolean> => {
  return unlessLoadingValue<boolean>(loading, () => {
    return Boolean(organization!.lines);
  });
};
