import { useState } from 'react';
import { parseDates } from 'utils/json/jsonUtils.js';
import { equals, identity, is, propOr, unless } from 'ramda';
import { useNotLoadingEffect } from 'utils/hooks/useMemoHooks.js';

/**
 * Like useState but storse in localStorage
 * @param {Boolean} loading Avoids loading from local storage if true. Instead it set effect to load from local
 * storage when loading becomes false
 * @param {[String]} parserArgument Keys that are dates
 * @param {Function} customParser Defaults to parseDates. Override the JSON.parse second argument function
 * Accepts parserArgument as the only argument
 * @param key
 * @param initialValue
 * @param {Function} serializer Optional serializer Can return a string or object.
 * @param {Function} postProcessInitialValue
 * @param [expiry] When the cookie expires
 * If object, JSON.stringfy will stringify it
 * @returns {(*|setValue)[]}
 */
export function useLocalStorage(
  {
    loading=false,
    parserArgument = [],
    customParser = null,
    serializer = identity,
    postProcessInitialValue = identity,
    expiry = null
  }, key, initialValue) {

  const parser = customParser || parseDates
  const initFromLocalStorage = () => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      let parsed
      try {
        const parsedItem = item ?
          JSON.parse(item, parser(parserArgument)) :
          initialValue;
        if (propOr(false, 'expiry', parsedItem)) {
          const now = new Date()
          // compare the expiry time of the item with the current time
          if (now.getTime() > parsedItem.expiry) {
            // If the item is expired, delete the item from storage
            // and return null
            localStorage.removeItem(key)
            return null
          }
          parsed = parsedItem.value
        }
        else {
          parsed = parsedItem
        }
      }
      catch {
        // Not json, just use the item directly
        parsed = item
      }

      if (!parsed) {
        return null
      }
      return postProcessInitialValue(parsed);
    } catch (error) {
      // If error also return initialValue
      console.log(error);
    }
  }

  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    if (loading || typeof window === 'undefined') {
      return initialValue;
    }
    return initFromLocalStorage()
  });

  // If loading was initially true, call setStoredValue when loading becomes false
  useNotLoadingEffect(loading, () => {
      if (!storedValue || equals(initialValue, storedValue)) {
        setStoredValue(initFromLocalStorage())
      }
    }
  )

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = value => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Serialize to object or string
      const serialized = serializer(valueToStore)
      // Save to local storage
      if (typeof window !== 'undefined') {
        const item = expiry ? {
          value: serialized,
          expiry
        } : serialized
        const stringified = unless(is(String), item => JSON.stringify(item))(item);
        window.localStorage.setItem(key, stringified)
      }
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };
  return [storedValue, setValue];
}