/***
 * Logging app initialization message
 */
export const cemitLoggingAppInit = () => {
  console.debug('Initialized Cemit Web Application, environment=' + APP_ENV + ', release=' + APP_RELEASE + ', debug=' + JSON.stringify(DEBUG));
}

/***
 * Logs changes to the location object pathname
 * @param location
 */
export const cemitLogLocation = location => {
  console.debug(`Location is ${location.pathname}`);
}