import {RideComfortTrainInfo} from "../../../types/rideComfort/rideComfortTrainInfo.js";
import {RideComfortOrganization} from "../../../types/rideComfort/rideComfortOrganization.js";
import {
  tempFlytogetRailwayLines,
  tempFlytogetServiceLines
} from "../../../async/trainAppAsync/rideComfortDependencies/tempOrgnanzationConfig/flytoget/flytogetServiceLines.ts";

const flytogetTrains: RideComfortTrainInfo[] = [
  {
    trainId: '71102 BM',
    cdc: '0011',
    alertPointId: '6517e3932a6d1c2d635be7ec',
    trackPointId: "65660a543a146549d754bb22",
    trainPointId: "65660a8ee466e807a5653918"
  },
  {
    trainId: '71102 BFM',
    cdc: '0037',
    alertPointId: '6517e29076b46022a0c7a3c9',
    trackPointId: "65660a5d87996d0930ab364f",
    trainPointId: "65660a9687996dfbd3ab3688"
  },
  {
    trainId: '71111 BFM',
    cdc: '0033',
    alertPointId: '6517e3a42a6d1c62cb5be808',
    trackPointId: "65660a67e466e86deb6538ef",
    trainPointId: "65660a9e3a146572f554bb81"
  },
];

export const flytogetConfig: RideComfortOrganization = {
  sourceKey: 'flytoget',
  trains: flytogetTrains,
  serviceLines: tempFlytogetServiceLines,
  railwayLines: tempFlytogetRailwayLines
}
