import { applySpec, compose, cond, equals, filter, find, head, indexBy, join, map, prop, propOr, T } from 'ramda';
import { extremes, idsEqual } from 'utils/functional/functionalUtils.js';
import { reqStrPathThrowing } from '@rescapes/ramda';
import { scaleLinear } from 'd3-scale';

/**
 * Gets the scheduledStopPoints of the TrainRoute and adds the routeDistance from the routePoint to each
 * @param {Object} trainRoute The TrainRoute instance
 * @returns {[Object]} list ScheduledStopPoints
 */
export const scheduledStopPointsOfTrainRoute = trainRoute => {
  return map(
    routePoint => {
      return { ...scheduledStopPointOfRoutePoint(routePoint), routeDistance: routePoint.routeDistance };
    },
    routePointsOfTrainRoute(trainRoute)
  );
};

/**
 * The ordered RoutePoints of the TrainRoute
 * @param trainRoute
 * @returns {*}
 */
export const routePointsOfTrainRoute = trainRoute => {
  return map(
    prop('routePoint'),
    trainRoute.orderedRoutePoints
  );
};

/**
 * Returns the RoutePoint matching the ScheduledStopPoint
 * @param trainRoute
 * @param scheduledStopPoint
 * @returns {*}
 */
export const routePointOfTrainRouteAndScheduledStopPoint = (trainRoute, scheduledStopPoint) => {
  return find(
    routePoint => {
      return idsEqual(scheduledStopPointOfRoutePoint(routePoint), scheduledStopPoint);
    },
    routePointsOfTrainRoute(trainRoute));
};

/**
 * Currently only on projection is expected per RoutePoint and thus one ScheduledStopPoint
 * @param routePoint
 * @returns {*}
 */
export const scheduledStopPointOfRoutePoint = routePoint => {
  return head(map(projection => {
    return projection.projectedPoint;
  }, routePoint.projections));
};

export const scheduledStopPointOfOrderedRoutePoint = orderedRoutePoint => {
  return head(map(projection => {
    return projection.projectedPoint;
  }, orderedRoutePoint.routePoint.projections));
};
/**
 * Returns the origin name of the TrainRoute or TrainRouteGroup of t('allOrigins') if the TrainRouteGrou
 * doesn't restrict the origin
 * @param {Function} t Translation service
 * @param {Object} trainRouteOrGroup A TrainRoute or TrainRouteGroup instance
 * @returns {*}
 */
export const trainRouteOrGroupOriginName = ({ t }, trainRouteOrGroup) => {
  return trainRouteOrGroup.startScheduledStopPoint?.shortName || t('allOrigins');
};

/**
 * Returns the destination name of the TrainRoute or TrainRouteGroup of t('allDestinations') if the TrainRouteGrou
 * doesn't restrict the origin
 * @param {Function} t Translation service
 * @param {Object} trainRouteOrGroup A TrainRoute or TrainRouteGroup instance
 * @returns {*}
 */
export const trainRouteOrGroupDestinationName = ({ t }, trainRouteOrGroup) => {
  return trainRouteOrGroup.endScheduledStopPoint?.shortName || t('allDestinations');
};

export const trainRouteOrGroupName = ({ t }, trainRouteOrGroup) => {
  return join(' ', [
    trainRouteOrGroupOriginName({ t }, trainRouteOrGroup),
    t('to'),
    trainRouteOrGroupDestinationName({ t }, trainRouteOrGroup)
  ]);
};

/**
 * Gets the inverse route of the current trainRoute
 @param {[Object]} trainRoutesOrGroups Used to resolve the reverse instance from an id
 @param {Object} trainRouteOrGroup The current TrainRoute
 */
export const getReverseTrainRouteOrGroup = (trainRoutesOrGroups, trainRouteOrGroup) => {
  const reverseTrainRouteOrGroup = find(
    testTrainRoute => {
      return equals(reverseTrainRouteOrGroupId(trainRouteOrGroup), testTrainRoute.id);
    },
    trainRoutesOrGroups
  );
  return reverseTrainRouteOrGroup;
};

/**
 * Finds the reverse TrainRouteOrGroup and calls chooseTrainRoute(reverseRoute);
 * @param {[Object]} trainRoutesOrGroups Used to resolve the reverse instance from an id
 * @param {Function} setTrainRouteOrGroup Sstter to call with the reversed TrainRouteOrGroup
 * @param {Object} trainRouteOrGroup
 */
export const reverseTrainRouteOrGroup = ({ trainRoutesOrGroups, setTrainRouteOrGroup }, trainRouteOrGroup) => {
  const reversedTrainRouteOrGroup = getReverseTrainRouteOrGroup(trainRoutesOrGroups, trainRouteOrGroup);
  setTrainRouteOrGroup(reversedTrainRouteOrGroup);
};


/***
 * Returns the reverse TrainRouteGroup or TrainRoute
 * @param trainRouteOrGroup
 * @returns {*}
 */
export const reverseTrainRouteOrGroupId = trainRouteOrGroup => {
  return cond([
    [isTrainRouteGroup, prop('inverseTrainRouteGroupId')],
    [isTrainRoute, prop('inverseTrainRouteId')],
    [T, instance => {
      throw new Error(`Unexpected type ${instance}`);
    }]
  ])(trainRouteOrGroup);
};

/**
 * Returns True if the trainRoute has __typename equal to 'TrainRoute
 * @param trainRoute
 * @returns {*}
 */
export const isTrainRoute = trainRoute => {
  return equals('TrainRoute', trainRoute.__typename);
};

/**
 * Returns True if the trainRoute has __typename equal to 'TrainRoute'
 * @param trainRoute
 * @returns {*}
 */
export const isTrainRouteGroup = trainRoute => {
  return equals('TrainRouteGroup', trainRoute.__typename);
};

/**
 * Given a TrainRoute and one of it's ScheduledStopPoints, find the reference ScheduledStop point of
 * the Railway that the ScheduledStopPoint is on and return the formers routeDistance so that we can calculate
 * the absolute distance from the reference ScheduledStopPoint to the one given here
 * @param trainRoute
 * @param scheduledStopPoint
 * @param railwayLines Complete objects scheduledStopPoint.railwayLines or minimized so must be matched to these
 * @returns {*}
 */
export const referenceStopDistanceForTrainRoute = ({ trainRoute, scheduledStopPoint, railwayLines }) => {
  // Measure from the distance given by trainRoute.measureDistancesFrom or else the stop's routeDistance
  const scheduledStopPoints = scheduledStopPointsOfTrainRoute(trainRoute);
  // Assume the same reference ScheduledStopPoint if the stop is on more than one railway
  const routePoint = routePointOfTrainRouteAndScheduledStopPoint(trainRoute, scheduledStopPoint);
  // pseudo-stops won't match here
  if (!routePoint) {
    return null;
  }
  const railwayLineOfStopPoint = head(routePoint.railwayLines);
  const railwayLine = find(idsEqual(railwayLineOfStopPoint), railwayLines);
  const referenceScheduledStopPoint = find(idsEqual(railwayLine.referenceScheduledStopPoint || { id: null }), scheduledStopPoints);
  return referenceScheduledStopPoint?.routeDistance;
};

/**
 * Maps a distanceRange from one TrainRoute's distanceRange to another
 * @param {Object} aggregateTrainRouteOrGroup The TrainRoute or TrainRouteGroup. If a TrainRouteGroup, map
 * the distanceRange from the domain of aggregateTrainRouteOrGroup that matches trainRoute's range to
 * trainRoute's range
 * @param trainRoute
 * @param {Object} distanceRange  The distanceRange to map
 * @returns {Object} The possibly updated distanceRange, which can be 0 to 0 if a range was chosen
 * that was outside of trainRoute's range
 */
export const convertDistanceRangeFromAggregateToTrainRoute = (
  { aggregateTrainRouteOrGroup, trainRoute },
  distanceRange
) => {
  if (equals(aggregateTrainRouteOrGroup, trainRoute)) {
    // Nothing to change
    return distanceRange;
  } else {
    // Create a converter
    const convert = createRouteDomainRangeFromAggregateConverter({ aggregateTrainRouteOrGroup, trainRoute });
    // Map from the matching aggregate domain to the range that is the route min/max distances
    // Don't allow negative values, rather return a 0 distance range
    return compose(applySpec({
        start: ({ start }) => Math.max(start, 0),
        end: ({ end }) => Math.max(end, 0)
      }),
      map(
        value => convert(value)
      )
    )(distanceRange);
  }
};
/**
 * Creates a converter function to convert from the distanceRange domain of the given aggregateTrainRouteOrGroup
 * ot that of the given trainRoute, where the latter is always equal to or a subset of the aggregate TrainRoute
 * @param aggregateTrainRouteOrGroup
 * @param trainRoute
 * @returns {Function} function expecting a distance in the aggregateTrainRouteOrGroup domain and converting
 * to the trainRoute's range. The returned distance can be negative if the value is outside the TrainRoute's range
 */
export const createRouteDomainRangeFromAggregateConverter = (
  {
    aggregateTrainRouteOrGroup,
    trainRoute
  }
) => {
  const orderedRoutePoints = extremes(trainRoute.orderedRoutePoints);
  const aggregateOrderedRoutePointLookup = indexBy(compose(prop('id'), scheduledStopPointOfOrderedRoutePoint), aggregateTrainRouteOrGroup.orderedRoutePoints);
  const matchingAggregateRoutePointDistances = map(
    routePoint => {
      const orderedRoutePoint = aggregateOrderedRoutePointLookup[scheduledStopPointOfOrderedRoutePoint(routePoint).id];
      if (!orderedRoutePoint) {
        throw new Error('aggregateOrderedRoutePointLookup lacks the correct ScheduledStopPoint ids');
      }
      return orderedRoutePoint.routePoint.routeDistance;
    },
    orderedRoutePoints
  );
  const routePointDistances = map(reqStrPathThrowing('routePoint.routeDistance'), orderedRoutePoints);
  return scaleLinear().domain(matchingAggregateRoutePointDistances).range(routePointDistances);
};

/**
 * Maps from on TrainRoute distanceRange domain to another where there are common ScheduledStopPoints
 * @param fromTrainRoute
 * @param toTrainRoute
 * @returns {Function}
 */
export const createRouteDomainRangeConverter = (
  {
    fromTrainRoute,
    toTrainRoute
  }
) => {
  const toTrainRouteOrderedRoutePointLookup = indexBy(compose(prop('id'), scheduledStopPointOfOrderedRoutePoint), toTrainRoute.orderedRoutePoints);
  const fromTrainRouteOrderedRoutePoints = filter(
    orderedRoutePoint => propOr(false, scheduledStopPointOfOrderedRoutePoint(orderedRoutePoint).id, toTrainRouteOrderedRoutePointLookup),
    fromTrainRoute.orderedRoutePoints
  );
  const fromTrainRouteOrderedRoutePointLookup = indexBy(compose(prop('id'), scheduledStopPointOfOrderedRoutePoint), fromTrainRouteOrderedRoutePoints);
  // Find the to OrderedRoutePoints that match the from OrderedRoutePoints
  const toTrainRouteOrderedRoutePointExtremes = extremes(filter(
    orderedRoutePoint => propOr(false, scheduledStopPointOfOrderedRoutePoint(orderedRoutePoint).id, fromTrainRouteOrderedRoutePointLookup),
    toTrainRoute.orderedRoutePoints
  ));
  const domain = map(
    orderedRoutePoint => {
      return fromTrainRouteOrderedRoutePointLookup[scheduledStopPointOfOrderedRoutePoint(orderedRoutePoint).id].routePoint.routeDistance;
    },
    toTrainRouteOrderedRoutePointExtremes
  );
  const range = map(reqStrPathThrowing('routePoint.routeDistance'), toTrainRouteOrderedRoutePointExtremes);
  // Map the from OrderedRoutePoint distanceRange to the to OrderedRoutePoint distanceRange
  return scaleLinear().domain(domain).range(range);
};

/**
 * Returns the single TrainRoute as an array or TrainRouts of the TrainRouteGroup
 * @param {Object} trainRouteOrGroup A TrainRoute or TrainRouteGroup
 * @returns {[Object]} One or more TrainRoutes
 */
export const trainRoutesOfTrainRouteOrGroup = trainRouteOrGroup => {
  return isTrainRouteGroup(trainRouteOrGroup) ? trainRouteOrGroup.trainRoutes : [trainRouteOrGroup];
};