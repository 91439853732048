import { useNotLoadingEffect } from 'utils/hooks/useMemoHooks.js';
import { useResolveTrainApiData } from 'async/trainAppAsync/hooks/trainApiHooks/trainApiHooks.js';

/**
 * Sets the service lines from client.lines whenever the client changes
 * TODO this might go to the API in the future if client only retures ids
 * @param {Boolean} loading Do nothing if true
 * @param {Object} organization The client
 * @param {Function} setServiceLines Setter for the ServiceLines
 */
export const useTrainApiSetServiceLines = ({ loading, organization, setServiceLines }) => {
  const { data: serviceLines } = useResolveTrainApiData(
    {
      loading,
      organization,
      method: 'serviceLines'
    }
  );
  useNotLoadingEffect(loading,
    () => {
      if (serviceLines) {
        setServiceLines(serviceLines);
      }
    },
    [serviceLines]
  );
};