import { Box, Stack } from '@mui/material';
import { toArrayIfNot } from '@rescapes/ramda';
import { addIndex, always, cond, equals, length, map, T } from 'ramda';
import PropTypes from 'prop-types';
import TrainLineStationContainer
  from 'components/apps/trainAppComponents/trainLineComponents/TrainLineStationContainer.js';
import StationLineContainer from 'components/apps/trainAppComponents/trainRunChooserComponents/StationLineContainer.js';

/**
 * Defines a TrainRunLine based on a TrainRun or a Route
 * @param {Object} loadingExplanation Debugging only. Explains what's not loaded from the parent
 * @param moverDrop
 * @param isMoverOver
 * @param stopGaps
 * @param spaceGeospatially
 * @param ref
 * @param trainLineStationPropSets Props for creating TrainLineStation components offsetLefts must be initialized to an array
 * of null items by useInitStopOffsetLefts in trainRunLineReadyHookProps before this can run
 * @param {Object} hoverFeatureComponent Component of the current ImuPoint geojson feature that is hovered on the map relevant to this
 * TrainRunLine's TrainRoute or UserTrainRunInterval. This is positioned absolutely on the TrainRun line
 * @param draggableTrainRunIntervalBars
 * @param sx
 * @returns {JSX.Element}
 * @constructor
 */
const TrainRunLine = (
  {
    appProps,
    trainProps,
    componentProps: {
      loadingExplanation,
      moverDrop,
      isMoverOver,
      stopGaps,
      spaceGeospatially,
      containerRef,
      trainLineStationPropSets,
      draggableTrainRunIntervalBars,
      hoverFeatureComponent,
      isTrainRouteLine,
      isUserTrainRunLine,
      hoveredScheduledStopPoint,
      setHoveredScheduledStopPoint,
      panelSize
    },
    sx = []
  }) => {

  const trainLineStationComponents = addIndex(map)(
    (trainLineStationProps, index) => {
      return <TrainLineStationContainer key={trainLineStationProps.key} {...{
        appProps,
        trainProps,
        componentProps: {
          isTrainRouteLine,
          ...trainLineStationProps,
          stationPosition: cond([
            [({ index }) => equals(0, index), always('start')],
            [({ index, count }) => equals(count - 1, index), always('end')],
            [T, always(null)]
          ])({ index, count: length(trainLineStationPropSets) }),
          hoveredScheduledStopPoint,
          setHoveredScheduledStopPoint,
          panelSize
        }
      }}
      />;
    },
    trainLineStationPropSets
  );

  return <Stack
    ref={node => {
      moverDrop(node);
      containerRef(node);
    }}
    alignItems='center'
    sx={[{
      position: 'relative',
      justifyContent: 'center',
      color: 'secondary.main'
    },
      ...toArrayIfNot(sx)
    ]}
  >
    {
      /* This is the horizontal line. It is positioned absolute, starting from the first station node and ending at the last
       The top is based on half the height of the dots below minus half the line's own height. Thus STATION_DOT_HEIGHT/2 - 3px/2
       stopGaps can't be calculated until TrainLineStations are rendered because they set their offsetLeft, which
       stopGaps depends on
      */
      <StationLineContainer {...{
        loading: !stopGaps,
        loadingExplanation,
        trainProps,
        isUserTrainRunLine,
        isOver: isMoverOver,
        stopGaps,
        spaceGeospatially
      }} />
    }

    {/* Holds the draggable train interval bars. Make sure the zIndex is higher than the trainLineStationDots */}
    {draggableTrainRunIntervalBars}

    {/* alignItems: flex-start This lines the dots up with the TrainRun line */}
    {spaceGeospatially ?
      <Box key='trainLineStations' direction='row' spacing={0.5} sx={{
        position: 'relative',
        zIndex: 2,
        width: '100%',
        height: '100%',
        justifyContent: 'space-between',
        alignItems: 'flex-start'
      }}>
        {trainLineStationComponents}
        {hoverFeatureComponent}
      </Box> :
      <>
        <Stack key='trainLineStations' direction='row' spacing={0.5} sx={{
          zIndex: 2,
          width: 1,
          justifyContent: 'space-between',
          alignItems: 'flex-start'
        }}>
          {trainLineStationComponents}
        </Stack>
        {hoverFeatureComponent}
      </>
    }
  </Stack>;
};

TrainRunLine.propTypes = {
  appProps: PropTypes.shape().isRequired,
  trainProps: PropTypes.shape().isRequired,
  componentProps: PropTypes.shape({
    moverDrop: PropTypes.func.isRequired,
    isMoverOver: PropTypes.bool.isRequired,
    stopGaps: PropTypes.arrayOf(PropTypes.object),
    spaceGeospatially: PropTypes.bool.isRequired,
    trainLineStationPropSets: PropTypes.arrayOf(PropTypes.object).isRequired,
    draggableTrainRunIntervalBars: PropTypes.object
  }).isRequired,
  sx: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
  sxTrainRunIntervalBar: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)])
};

export default TrainRunLine;