import { shallowEquals } from 'utils/functional/functionalUtils.js';
import { mergeTrainRun } from 'appUtils/trainAppUtils/typeMerging/trainRunMerging.js';
import { mergeDeep } from '@rescapes/ramda';
import { all, always, cond, equals, is, last, mergeWithKey, T, unapply } from 'ramda';

/***
 * Updates the ImuPoints for the UserTrainRunInterval, sorting existing with incoming
 * @param existingTrainRunInterval
 * @param incomingTrainRunInterval
 * @returns {*}
 */
export const mergeTrainRunInterval = (existingTrainRunInterval, incomingTrainRunInterval) => {
  return mergeWithKey(
    (key, existingPropValue, incomingPropValue) => {
      // Merge trainRunInterval with it's merge function.
      // Take the incoming value of geojson.
      // Everything else uses the default mergeDeep,
      // which deep merges objects but not arrays and takes the incoming value
      return cond([
        // If references equals or the key is geojson, always take the incoming prop value
        [shallowEquals, (a, b) => {
          return b;
        }],
        [always(equals('geojson', key)), unapply(last)],
        // Call TrainRun's merge function
        [always(equals('trainRun', key)), mergeTrainRun],
        [T, (a, b) => {
          return all(is(Object), [a, b]) ? mergeDeep(a, b) : b;
        }]
      ])(existingPropValue, incomingPropValue);
    },
    existingTrainRunInterval,
    incomingTrainRunInterval
  );
};