import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useNotLoadingEffect } from 'utils/hooks/useMemoHooks.js';
import { isNil } from 'ramda';
import { useEventListener, useIsomorphicLayoutEffect } from 'usehooks-ts';

/**
 * Watches the offset left of the target
 * @param {Object} target Element defined by useRef
 * @param {Object} displayProps The window size
 * @param {Function} setOffsetLeft setter Hook
 * @param {Object} trainRunInterval. Changes to the trainRunInterval impact what stations show
 * if the stations are limited
 * @param {bool} limitedStations If true, the offsetLeft depends on the trainRunInterval's current size
 * @param {bool} recalculateOffsetLefts Toggle to tell the effect to recalculate the offset
 * @param {bool} [spaceGeospatially] Default false. Space the stations geospatioally
 * @returns {Void}
 */
export const useOffsetLeft = (
  {
    target,
    containerSize,
    setOffsetLeft,
    recalculateOffsetLefts,
    trainRunInterval,
    spaceGeospatially
  }) => {
  useLayoutEffect(() => {
    // If spaceGeospatially, we don't need to setOffsetLeft, it can be calculated by the TrainRunLine
    if (!(spaceGeospatially || isNil(target.current.offsetLeft))) {
      setOffsetLeft(target.current.offsetLeft);
    }
  }, [target, containerSize, trainRunInterval, recalculateOffsetLefts]);
};


/**
 * https://stackoverflow.com/questions/36862334/get-viewport-window-height-in-reactjs
 * @returns {{width: number, height: number}}
 */
const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
};

/**
 * React Hook to respond to browser window dimensions
 * @returns {{width: number, height: number}}
 */
export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}


/**
 * Generic useEffect hook that doesn't run initially
 * https://stackoverflow.com/questions/53253940/make-react-useeffect-hook-not-run-on-initial-render
 * @param func
 * @param deps
 */
export const useDidMountEffect = (func, deps) => {
  const didMount = useRef(false);
  useEffect(() => {
    if (didMount.current) {
      func();
    } else {
      didMount.current = true;
    }
  }, deps);
};

/**
 * Generic useEffect hook that doesn't run initially and waits for loading to be false
 * https://stackoverflow.com/questions/53253940/make-react-useeffect-hook-not-run-on-initial-render
 * @param loading
 * @param func
 * @param deps
 */
export const useNotLoadingDidMountEffect = (loading, func, deps) => {
  const didMount = useRef(false);
  useNotLoadingEffect(loading, () => {
    if (didMount.current) {
      func();
    } else {
      didMount.current = true;
    }
  }, deps);
};


function useElementSizeWithRef() {
  const [ref, setRef] = useState(null);
  const [size, setSize] = useState({
    width: 0,
    height: 0
  });
  const handleSize = useCallback(() => {
    setSize({
      width: ref?.offsetWidth || 0,
      height: ref?.offsetHeight || 0
    });
  }, [ref?.offsetHeight, ref?.offsetWidth]);
  useEventListener('resize', handleSize);
  useIsomorphicLayoutEffect(() => {
    handleSize();
  }, [ref?.offsetHeight, ref?.offsetWidth]);
  return [setRef, size, ref];
}

export default useElementSizeWithRef;