import { endOfDay, startOfDay } from 'date-fns';
import { useEffect } from 'react';

/**
 * Set the DateInterval to the selectedDate
 * @param selectedDate
 * @param setDateInterval
 */
export const useSetDateInterval = ({selectedDate, setDateInterval}) => {
  // Always mirror selectedDate
  useEffect(() => {
    if (selectedDate) {
      setDateInterval(
        {
          start: startOfDay(selectedDate),
          end: endOfDay(selectedDate)
        }
      );
    }
  }, [selectedDate]);
}