import { derivedTrackOfTrainRoute } from 'appUtils/trainAppUtils/railwayLineUtils.js';
import { strPathOr } from '@rescapes/ramda';
import { distanceAlongLine } from 'utils/distance/distanceUtils.js';
import {
  scheduledStopPointOfRoutePoint,
  scheduledStopPointsOfTrainRoute
} from 'appUtils/trainAppUtils/trainRouteUtils.js';
import { any, find, head, last, lensPath, lensProp, map, over, set } from 'ramda';
import { idsEqual } from 'utils/functional/functionalUtils.js';

/**
 * Calculates the distances along the Route to RoutePoints
 * Also generates derived track data by calling derivedTrackOfTrainRoute
 * @param {Object} organization The client
 * @param trainRoute The route to create the template TrainRun and TrainRunInterval for
 * @returns {Object} An updated version of the TrainRoute with
 *  routeInterval,
 *  measureDistancesFrom: Name of Reference Station (e.g. 'Oslo S'),
 *  distance: total distance of the Route in kilometers
 * and route.pointsOnRoute[*].routePoint.routeDistance: distance to the route point in km from the start point of the route
 */
export const trainRouteDerivatives = ({ organization, trainRoute }) => {

  // Compute the trackData if we haven't yet.
  const trackData = trainRoute.trackData || derivedTrackOfTrainRoute({
    trainRoute
  });

  // Calculate the routeDistance for each trainRoute.orderedRoutePoints.routePoint if we haven't yet

  // For measuring distance, define the start point as the first stop on the route.
  // Imupoints have a railway name and s distance property, the number of meters from the start of the railway at the station
  // of reference (e.g. Oslo S). We need to convert s to a km property, which is the distance along the
  // track of the route starting a the starting point of the route. We can do this by adding up the distances
  // of each railway and factoring in whether each railway's track line was reversed to match the route direction
  // So here we store the RoutePoint route distances from the first stop on the route, and when we later calculate km
  // for each imuPoint, we need to know to start measuring at the point on the track of the first stop, which
  // we stored

  const startPoint = head(scheduledStopPointsOfTrainRoute(trainRoute)).geojson;
  const orderedRoutePointsHaveRouteDistances = any(
    strPathOr(false, 'routePoint.routeDistance'),
    trainRoute.orderedRoutePoints
  );
  const updatedTrainRoute = orderedRoutePointsHaveRouteDistances ? trainRoute : over(
    lensProp('orderedRoutePoints'),
    orderedRoutePoints => {
      return map(
        orderedRoutePoint => {
          const distance = distanceAlongLine({
            units: 'meters',
            start: startPoint,
            end: scheduledStopPointOfRoutePoint(orderedRoutePoint.routePoint).geojson,
            line: trackData.trackSingleLineString
          });
          return set(
            lensPath(['routePoint', 'routeDistance']),
            distance,
            orderedRoutePoint);
        },
        orderedRoutePoints
      );
    },
    trainRoute
  );
  // Find the distance along the track to the reference stop of the client
  const referencePointDistance = organization.referenceStop ? find(
    scheduledStopPoint => {
      return idsEqual(organization.referenceStop, scheduledStopPoint);
    },
    scheduledStopPointsOfTrainRoute(updatedTrainRoute)
  ).routeDistance : null;

  // The route distance is the distance as measured at the end routePoint
  const routeDistance = last(updatedTrainRoute.orderedRoutePoints).routePoint.routeDistance;
  return {
    ...updatedTrainRoute,
    // Add referencePointDistance and routeDistance so we can convert an imuPoint s distance value,
    // which is track specific to the overall distance along the combined track
    trackData: { ...trackData, referencePointDistance, routeDistance },
    measureDistancesFrom: organization.referenceStop,
    routeDistance
  };
};