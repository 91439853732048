// Set the train collection points
// TODO we currently only have sample data for drammenbanen, not gardermobanen
import _along from '@turf/along';
import { defaultNode } from 'utils/functional/functionalUtils.js';
import { cond, equals, T } from 'ramda';
import {
  referenceStopDistanceForTrainRoute,
} from 'appUtils/trainAppUtils/trainRouteUtils.js';

const along = defaultNode(_along);

/**
 * Given a trainRoute and a distance, creates a pseud-ScheduledStopPoint
 * @param {Object} trainRoute
 * @param {Object} trainRoute.trackData
 * @param {Object} trainRoute.trackData.trackSingleLineString The geojson linestring
 * @param {Object} nextScheduledStopPoint The stop before or after this one that we want to measure the distance against for the label
 * @param {Number} distance The distance in kilometers
 * @returns {Object} An object with a geojson point property
 */
export const pseudoScheduledStopPointAtDistanceAlongRoute = (
  { trainRoute, nextScheduledStopPoint , railwayLines}, distance) => {
  const sourceKey = `pseudoScheduledStopPointForRoute:${trainRoute.sourceKey}|Distance:${distance}`;
  const nextScheduledStopPointDistance = referenceStopDistanceForTrainRoute(
    {
      trainRoute,
      scheduledStopPoint: nextScheduledStopPoint,
      railwayLines
    }
  );

  return {
    id: sourceKey,
    distanceLabel: cond([
      // If the distance is 0 name the stop the nextScheduledStopPoint, since it is. Otherwise name it "difference from nextScheduledStopPoint.name"
      [nextScheduledStopPoint => nextScheduledStopPoint && equals(Math.round(nextScheduledStopPoint.routeDistance), Math.round(nextScheduledStopPointDistance)),
        nextScheduledStopPoint => {
          return nextScheduledStopPoint.name;
        }
      ],
      // Else specify the distance the nearest stop
      [
        nextScheduledStopPoint => nextScheduledStopPoint,
        nextScheduledStopPoint => {
          return `(${(Math.abs(nextScheduledStopPoint.routeDistance - distance) / 1000).toFixed(1)}
       ${nextScheduledStopPoint.routeDistance < distance ? '←' : '→'} ${nextScheduledStopPoint.name})`;
        }
      ],
      [T,
        () => {
          // This shouldn't happen unless the interval is minimized to nothing
          return ''
        }
      ]
    ])(nextScheduledStopPoint),
    osmId: null,
    sourceKey,
    routeDistance: distance,
    // TODO this can be estimated from the real stops if needed
    geojson: along(trainRoute.trackData.trackSingleLineString, distance)
  };
};