import { useNotLoadingEffect } from 'utils/hooks/useMemoHooks.js';
import { mergeTrainRunFilters } from 'appUtils/trainAppUtils/trainFilterUtils.js';

/**
 * Updates/Creates a FormationFilter, which is like a
 * TrainRunFilter but can be used to set the date reange of multiple TrainRuns at the same time
 * The parentTrainRunFilter comes from a parent component and does not include a date range,
 * so adding formationsFilter to it always results in a new TrainRunFilter with just on date range
 * @param loading
 * @param parentTrainRunFilter
 * @param setTrainRunFilterWithFormationsFormationFilter Setter
 */
export const useUpdateOrCreateFormationFilter = (
  {
    loading,
    parentTrainRunFilter,
    trainRunFilterWithFormations,
    setTrainRunFilterWithFormations
  }) => {
  useNotLoadingEffect(loading, () => {
    // Just copy the parent, since we don't want to filter out any Formations initially
    setTrainRunFilterWithFormations(
      parentTrainRunFilter
    );
  }, []);

  // Whenever the parentTrainRunFilter updates, update this
  useNotLoadingEffect(loading || !trainRunFilterWithFormations, () => {
    const updatedTrainRunFilter = mergeTrainRunFilters({childFilterTypeName:'FormationFilter'}, parentTrainRunFilter, trainRunFilterWithFormations)
    setTrainRunFilterWithFormations(
      updatedTrainRunFilter
    );
  }, [parentTrainRunFilter])
};