/**
 * Creates a trainRunFilterWithTrainRoute, the template for TrainRunFilters
 * @param {Object} trainRoute The TrainRoute instance
 * @param distance
 * @returns trainRunFilterWithTrainRoute
 */
import { equals, find, lensProp, map, over } from 'ramda';
import { evalFilter } from 'appUtils/trainAppUtils/trainFilterEval.js';
import { extractAndEvaluateMatchingFilters } from 'appUtils/trainAppUtils/trainFilterUtils.js';

export const isTrainRoute = (trainRunFilter) => {
  return equals('TrainRouteFilter', trainRunFilter.__typename);
};
export const createTrainRunFilterWithTrainRoute = ({ trainRoute }) => {
  // filter and its components can match any Ramda function: https://ramdajs.com/
  // where keys are function names and values are arrays or scalar arguments
  // which can also be ramda functions or the trainRun being tested by using
  // prop: ['id'] or similar, where it's assumed that the second argument to prop
  // is the trainRun being tested
  // Assume that all possible TrainRuns are the argument to filter
  // The following translates to in Ramda
  // R.filter(
  //  R.allPass(
  //    R.equals(
  //      trainRoute.id,
  //      R.view(
  //        R.lensPath(['trainRoute', 'id']),
  //        trainRun
  //      )
  //    )
  //  )
  // )
  // and on the server is converted to SqlAlchemy
  // select(TrainRun).where(TrainRun.route_id == route.id)
  // When more filters are added in TrainRunFilters, they can add at
  // the allPass level to create ANDs, whose expressions can themselves
  // contains and/or/not or other logic
  return { equals: [trainRoute.id, { view: { lensPath: ['trainRun', 'id'] } }] };
};

export const evalTrainRoute = (trainRunFilterTrainRoute, props) => {
  return over(
    lensProp('equals'),
    equalsFilter => {
      return evalFilter(equalsFilter, props);
    },
    trainRunFilterTrainRoute
  );
};

export const extractTrainRouteId = trainRunFilterWithTrainRoute => {
  return trainRunFilterWithTrainRoute.equals[0];
};

/**
 * Extracts the TrainRoutes from the trainRunFilter
 * @param trainRunFilter
 * @param props
 * @param props.trainRouteGroups Required in order to resolve TrainRouteGroups from the filter ids
 * @param props.trainRoutes Required in order to resolve TrainRoutes from the filter ids
 * @returns {*}
 */
export const extractTrainRoutes = (trainRunFilter, props) => {
  const trainRouteEvaluatedFilters = extractAndEvaluateMatchingFilters(
    { filterTypeTest: isTrainRoute, filterTypeEval: evalTrainRoute },
    trainRunFilter, props
  );
  return map(
    trainRouteEvaluatedFilter => {
      const trainRouteId = trainRouteEvaluatedFilter.equals[0];
      const trainRoute = find(
        trainRoute => equals(trainRouteId, trainRoute.id),
        [...props.trainRouteGroups, ...props.trainRoutes]
      );
      if (!trainRoute) {
        throw new Error(`Expected props.trainRoutes to have the trainRoute with id ${trainRoute.id}`);
      }
      return trainRoute;
    },
    trainRouteEvaluatedFilters
  );
};

/**
 * We only allow one TrainRoute at a time currently
 * @param trainRunFilter
 * @param trainRunFilterWithTrainRoute
 * @returns {*}
 */
export const addTrainRunFilterWithTrainRoute = (trainRunFilter, trainRunFilterWithTrainRoute) => {
  return over(
    lensProp('allPass'),
    allPassObj => {
      return [
        ...allPassObj,
        {...trainRunFilterWithTrainRoute, __typename: 'TrainRouteFilter'}
      ];
    },
    trainRunFilter
  );
};

/**
 * Extracts the label for the TrainRoutes or TrainRouteGroups from the filter
 * @param trainRunFilter
 * @param props
 * @param props.trainRoutes List of TrainRoutes
 * @param props.trainRouteGroups List of TrainRouteGroups
 * @returns {[String]} List of names
 */
export const extractLabelsForTrainRoutes = (trainRunFilter, props) => {
  const trainRoutes = extractTrainRoutes(trainRunFilter, props);
  return map(trainRoute => trainRoute.name, trainRoutes);
};