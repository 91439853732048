import {
  all,
  curry,
  eqProps,
  equals,
  findIndex,
  head,
  identity,
  last,
  lt,
  lte,
  map,
  slice,
  when,
  zipWith
} from 'ramda';
import { isNode } from 'browser-or-node';
import { reqStrPathThrowing } from '@rescapes/ramda';
import {length} from 'ramda'
import { eqStrPath } from '@rescapes/ramda/src/propPathFunctions.js';

/**
 * @file functional utilities extracted from Andy's rescape-ramda library
 */


/**
 * Temporary helper to access the default in the node env and not in the browser env
 * @param module
 * @returns {*}
 */
export const defaultNode = module => {
  return isNode && typeof module !== 'function' ? module.default : module;
};

/**
 * Assuming a list that is ordered by the give date prop, limit
 * the list to items with dates within the interval inclusive
 * @param {Object} interval Standard ISO date interval
 * @param interval.start start Date
 * @param interval.end end Date
 * @param {String} datePath The string path to the date in orderedList, e.g. 'departureTime', or 'foo.departureTime'
 * @param {[Object] }orderedList The ordered list
 * @returns {[Object]} The orderedList sliced to match the interval. Can be empty
 */
export const limitByDatePropToDateInterval = ({ interval, dateProp }, orderedList) => {
  // Find the first item greater than/equal to the interval start
  const firstIndex = findIndex(item => {
    return lte(interval.start, reqStrPathThrowing(dateProp, item));
  }, orderedList);
  // If nothing matches, return empty
  if (equals(-1, firstIndex)) {
    return [];
  }
  // Find the first item greater than interval end
  const outsideIndex = firstIndex +
    // If there is nothing greater, use Infinity to slice to the end
    when(
      equals(-1),
      () => Infinity
    )(
      findIndex(item => {
        return lt(interval.end, reqStrPathThrowing(dateProp, item));
      }, slice(firstIndex, Infinity, orderedList))
    );
  // Return the slice, where outsideIndex is excluded. If both are -1, than [] is returned
  return slice(firstIndex, outsideIndex, orderedList);
};

export const extremes = list => {
  return [head(list), last(list)];
};

/**
 * Compares persisted instances by id
 * @param {Object} obj1 first instance to compare
 * @param {Object} obj2 instance to compare with obj1
 * @returns {Boolean} True if equal
 */
export const idsEqual = curry((obj1, obj2) => {
  return eqProps('id', obj1, obj2);
});
/***
 * Tests the length of the lists and id prop of each item for equality
 * The lists items must have objects with ids in the same order
 * @param {[Object]} list1 first list of objets to compare
 * @param {[Object]} list1 second list of objets to compare
 * @returns {Boolean} True if equal
 */
export const idListsEqual = curry((list1, list2) => {
  return equals(...map(length, [list1, list2])) && all(identity, zipWith(eqProps('id'), list1, list2))
});
/***
 * Tests the length of the lists and strPath to each item for equality
 * The lists items must have objects with ids in the same order
 * @param {String} strPath Dot-separate string path of props in the object, e.g. 'foo.bar.id'
 * @param {[Object]} list1 first list of objets to compare
 * @param {[Object]} list1 second list of objets to compare
 * @returns {Boolean} True if equal
 */
export const strPathListsEqual = curry((strPath, list1, list2) => {
  return equals(...map(length, [list1, list2])) && all(identity, zipWith(eqStrPath(strPath), list1, list1))
});


/**
 * Compares instances by sourceKey
 * @param {Object} obj1 first instance to compare
 * @param {Object} obj2 instance to compare with obj1
 * @returns {Boolean} True if equal
 */
export const sourceKeysEqual = (obj1, obj2) => {
  return eqProps('sourceKey', obj1, obj2);
};

/**
 * Validates that the list has only one item before return the item.
 * TODO This was in @rescapes/rambda but that throws an annoying deprecation warning from folktale
 * @param list
 * @returns {*}
 */
export const onlyOneValueOrThrow = list => {
  if (length(list) !== 1) {
    throw new Error(`list should be length 1, got length ${length(list)}`)
  }
  return head(list)
}

/**
 * Return the only item or null from the list
 * @param list
 * @returns {*}
 */
export const onlyOneValueOrNoneThrow = list => {
  if (length(list) > 1) {
    throw new Error(`list should be length 1, got length ${length(list)}`)
  }
  return head(list)
}

/**
 * Returns true if list1 and list2 are both nonnull and their lengths are equal
 * @param {[Object]} list1
 * @param {[Object]} list2
 * @returns {Boolean}
 */
export const lengthsEqual = (list1, list2) => {
  return list1 && list2 && equals(length(list1), length(list2))
}

/**
 * Returns true if list1 and list2 are both nonnull, equal in length, and have the same ids (in order)
 * @param {[Object]} list1
 * @param {[Object]} list2
 * @returns {Boolean}
 */
export const lengthsAndIdsEqual = (list1, list2) => {
  return lengthsEqual(list1, list2) && all(idsEqual, list1, list2)
}

/**
 * Performs a shallow compare of reference instead of ramda's default
 * equals, which is deep
 * @param {*} item1
 * @param {*} item2
 * @returns {boolean} True if the references or primitives are equal
 * based on ===
 */
export const shallowEquals = (item1, item2) => {
  return item1 === item2
}