import center from '@turf/center';
import circle from '@turf/circle';
import {memoizedWith, strPathOr} from '@rescapes/ramda';
import {ifElse, join, map as mapR} from 'ramda';

import {DataThreshold} from '../../../../railbedTypes/dataVisualizations/dataThreshold';
import {TrainRunGroup} from '../../../../railbedTypes/trainRuns/trainRunGroup';
import {FeatureCollectionU, FeatureU} from '../../../../railbedTypes/geometry/geojsonUnions';
import {MapboxIconConfig, MapboxIconsConfig} from '../../../../railbedTypes/mapbox/mapSourceVisual';
import {Feature, Units} from '@turf/helpers';
import {EXTRUSION_HEIGHT_FACTOR} from '../../../../railbedConfig/appConfigs/cemitAppConfigs/cemitMapConfig.ts';

import okSvgInline from '../../../../railbedAssets/icons/ok.svg'
import warningSvgInline from '../../../../railbedAssets/icons/warning.svg'
import errorSvgInline from '../../../../railbedAssets/icons/error.svg'
import {
    dataThresholdsToMapboxAttributeLevels,
    dataThresholdsToMapBoxZoomAttributeLevels
} from "../../mapboxExpressionUtils.ts";


/**
 * Extrude columns on the map for the given geojson
 * TODO Rewrite and move colors, zooms, etc to appProps and organizationProps
 * @param  {Object} Returned with source and layers to so we know what TrainRunGroup they belong to
 * @param trainMap The Mapbox map
 * @param featurePropPath The path in each feature.properties to use for the extrusion.
 * e.g. 'acceleration.sumMaxMean' or 'sumMaxMean'
 * @param geojson FeatureCollection where features are points or anything else whose center
 * is used for the columns
 * @param dataThresholds Objects with a {style: {color}, value}} where color colors column and value
 * determines the threshold for changing color
 * @param [mapAsseSuffix] Default 'singleton' Unique suffix to add to the Mabbox source and layer
 * in case multiple datasets need to be extruded, in which case each has a separate Mapbox source and layer
 * source: `3d-source-${mapAsseSuffix}`
 * layer: `3d-layer-${mapAsseSuffix}`
 * @returns {Object} {source: The Mapbox source config, layers: array of currently one Mapbox layer, trainRunGroup}
 */
export const memoizedTrainRunGroup3dColumnSourceAndLayers = memoizedWith(
    ({
         trainRunGroup,
         featurePropPath,
         dataThresholds,
         extrude,
         sourceNamePrefix,
         layerNamePrefix,
         geojson
     }: {
         trainRunGroup: TrainRunGroup
         featurePropPath: string,
         dataThresholds: DataThreshold[],
         extrude: boolean
         sourceNamePrefix: string,
         layerNamePrefix: string,
         geojson: FeatureCollectionU
     }
    ) => {
        return [trainRunGroup, featurePropPath, dataThresholds, extrude, sourceNamePrefix, layerNamePrefix, geojson];
    },
    ({
         trainRunGroup,
         featurePropPath,
         geojson,
         dataThresholds,
         extrude,
         sourceNamePrefix,
         layerNamePrefix
     }:
         {
             trainRunGroup: TrainRunGroup
             featurePropPath: string,
             geojson: FeatureCollectionU,
             dataThresholds: DataThreshold[],
             extrude: boolean
             sourceNamePrefix: string,
             layerNamePrefix: string,
         }
    ) => {
        // Makes the source and layer unique to the TrainRunGroup
        const mapAssetSuffix = trainRunGroup.sourceKey;

        const mapboxSourceName = join('-', [sourceNamePrefix, mapAssetSuffix]);
        const mapboxLayerId = join('-', [layerNamePrefix, mapAssetSuffix]);

        const source = {
            name: mapboxSourceName,
            type: 'geojson',
            data: extrude ? createExtrudableCoordinates({featurePropPath}, geojson) : geojson
        };

        const layer = ifElse(Boolean, () => {
            return {
                id: mapboxLayerId,
                type: 'fill-extrusion',
                source: mapboxSourceName,
                // Paint green, orange, or read based on the relationship between
                // the data value and the 3 dataThresholds
                // Convert silly mapbox expressions to javascript methods in  'railbedUtils/map/mapboxExpressionUtils.ts';
                paint: {
                    'fill-extrusion-color': [
                        'interpolate',
                        ['linear'],
                        ['get', 'extrudeHeight'],
                        ...dataThresholdsToMapboxAttributeLevels(dataThresholds)
                    ],
                    'fill-extrusion-height': ['get', 'extrudeHeight'],
                    'fill-extrusion-base': 0,
                    'fill-extrusion-vertical-gradient': true,
                    'fill-extrusion-opacity': 1
                },
                layout: {
                    'visibility': trainRunGroup.activity.isVisible ? 'visible' : 'none'
                }
            }
        }, () => {

            // Determines the icon size based on the config of each dataThreshold.size array,
            // in a DataThreshold such as:
            /*
            {
                sourceKey: 'warning',
                label: t('warning'),
                value: RideComfortAttributeAlertSValue.warning,
                style: {
                    color: '#F2C94C',
                    icon: 'warning',
                    size: [
                        // For value RideComfortAttributeAlertSValue.warning,
                        // show these outputValues for the icon at each zoom level
                        {zoom: 0, outputValue: 0.2} as ZoomLevelValue,
                        {zoom: 8, outputValue: 0.5} as ZoomLevelValue,
                        {zoom: 16, outputValue: 1} as ZoomLevelValue,
                    ]
                }
            },
             */
            // Meaning that feature.property[featurePropPath] >= RideComfortAttributeAlertSValue.warning
            // and feature.property[featurePropPath] < RideComfortAttributeAlertSValue.error
            // will produce the outputValue that matches the current zoom level
            const iconSizeExpression = {
                property: featurePropPath,
                stops: dataThresholdsToMapBoxZoomAttributeLevels(
                    dataThresholds,
                    'size'
                )
            }
            // Chooses the icon name based on feature.property[featurePropPath]
            const iconsInterpolations = dataThresholdsToMapboxAttributeLevels(
                dataThresholds, 'icon'
            )
            const iconImageExpression = [
                'step',
                ['number', ['get', featurePropPath]],
                ...iconsInterpolations,
            ]
            return {
                id: mapboxLayerId,
                type: 'symbol',
                source: mapboxSourceName,
                iconConfig: {
                    width: 20,
                    height: 20,
                    iconConfigs: [
                        {name: 'ok', svg: okSvgInline} as MapboxIconConfig,
                        {name: 'warning', svg: warningSvgInline} as MapboxIconConfig,
                        {name: 'error', svg: errorSvgInline} as MapboxIconConfig,
                    ]
                } as MapboxIconsConfig,
                layout: {
                    'icon-image': iconImageExpression,
                    // The higher value gets z-index priority
                    'symbol-sort-key': ["to-number", ["get", featurePropPath]],
                    'icon-allow-overlap': true,
                    'icon-size': iconSizeExpression,
                    'visibility': trainRunGroup.activity.isVisible ? 'visible' : 'none'
                }
            }
        })(extrude);
        return {
            source,
            layers: [layer],
            trainRunGroup
        };
    }
)


/**
 * Creates a FeatureCollection with features points that each have
 * an extrudeHeight property based on the given feature prop path
 * e.g. 'acceleration.sumMaxMean'
 * @param featurePropPath
 * @param data
 * @param data.features
 * @param data.features[*].properties
 * @param bigRadius
 * @returns {{features: *[], type: string}}
 */
const createExtrudableCoordinates = (
    {featurePropPath, bigRadius = false}: {
        featurePropPath: string,
        bigRadius?: boolean
    },
    data: FeatureCollectionU
) => {

    return {
        type: 'FeatureCollection',
        features: mapR((feature: FeatureU) => {
            const origin = center(feature as Feature);
            const radius = bigRadius ? 0.01 : 0.004;
            const options: {
                steps: number,
                units: Units,
                properties: Record<string, any>
            } = {
                steps: 4,
                units: 'kilometers',
                properties: {
                    ...feature.properties,
                    // TODO use Math.abs for now
                    extrudeHeight: Math.abs(strPathOr(0, featurePropPath, feature.properties) * EXTRUSION_HEIGHT_FACTOR)
                }
            };
            return circle(origin, radius, options);
        }, data?.features || [])
    };
};
