import { useNotLoadingEffect } from 'utils/hooks/useMemoHooks.js';
import { map, mergeRight } from 'ramda';
import { useResolveTrainApiData } from 'async/trainAppAsync/hooks/trainApiHooks/trainApiHooks.js';

/**
 * Resolves the TrainRoutes from the ServiceLines
 * @param {Boolean} loading
 * @param {Object} organization The client
 * @param {[Object]} serviceLines The ServiceLines to get the TrainRoutes of
 * @param {Function} setTrainRouteGroups Setter
 */
export const useTrainApiSetTrainRouteGroups = (
  {
    loading,
    organization,
    setTrainRouteGroups
  }) => {
  const { data: trainRouteGroups } = useResolveTrainApiData(
    {
      loading,
      organization,
      method: 'trainRouteGroups'
    }
  );
  useNotLoadingEffect(loading,
    () => {
      if (trainRouteGroups) {
        setTrainRouteGroups(
          // Add a __typename so we can distinguish TrainRouteGroup from TrainRoute
          map(
            mergeRight({ __typename: 'TrainRouteGroup' }),
            trainRouteGroups
          )
        );
      }
    },
    [trainRouteGroups]
  );
};