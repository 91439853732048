import React, { useState } from 'react';
import {AppPropsWithDerived} from "visualizer-railbed/railbedTypes/propTypes/appProps";
import {OrganizationProps} from "visualizer-railbed/railbedTypes/propTypes/organizationProps";
import {TrainProps} from "visualizer-railbed/railbedTypes/propTypes/trainProps";
import {MapProps, MapPropsWithDerived} from "visualizer-railbed/railbedTypes/propTypes/mapProps";
import {
  useRailwayLineMapboxSourcesAndLayers
} from "visualizer-railbed/railbedAsync/cemitAppAsync/cemitAppHooks/mapHooks/railwayLineMapHooks.ts";
import RideComfortMapSensorLayerDependency from "./RideComfortMapSensorLayerDependency.tsx";


/***
 * Loads/Updates TrainMap layer data into mapProps.trainMapLayerProps.trainMapLayerProps
 * Depends directly on TrainRunGroupSensor props in trainProps.trainRunGroupProps.sensorProps and TrainMap in
 * mapProps
 * @param appProps
 * @param organizationProps
 * @param trainProps
 * @param mapProps
 * @param children
 * @return {*}
 * @constructor
 */
const RideComfortMapLayerDependency = (
  { appProps, organizationProps, trainProps, mapProps }:
    { appProps: AppPropsWithDerived, organizationProps: OrganizationProps, trainProps: TrainProps, mapProps: MapProps }
) => {

  const loading =  mapProps.loading;

  // The RailwayLine being hovered over, TODO not currently in use
  const [hoveredRailwayLineId, setHoveredRailwayLineId] = useState<string | undefined>(undefined);

  // Update/Create RailwayLine Mapbox Sources
  useRailwayLineMapboxSourcesAndLayers({
    dependencyProps: {
      appProps,
      organizationProps,
      trainProps,
      mapProps
    },
    loading: loading
  });

  const mapPropsWithDerived: MapPropsWithDerived = {
    ...mapProps,
    // @ts-expect-error meh
    mapLayerProps: {
      loading: loading,
      trainMapLayerProps: {
        // Depends directly on trainProps.trainRunGroupSensorProps and mapProps
        loading,
        hoveredRailwayLineId, setHoveredRailwayLineId,
      }
    }
  };

  return <RideComfortMapSensorLayerDependency {...{
    appProps,
    organizationProps,
    mapProps: mapPropsWithDerived,
    trainProps
  }} />;
};
RideComfortMapLayerDependency.displayName = 'RideComfortMapLayerDependency';
export default RideComfortMapLayerDependency;
