export enum CemitTypename {
  organization = 'Organization',
  trainFormation = 'TrainFormation',
  trainFormationDetail = 'TrainFormationDetail',

  trainRunGroupMinimized = 'trainRunGroupMinimized',
  trainRunGroupIncomplete = 'TrainRunGroupIncomplete',
  trainRunGroup = 'TrainRunGroup',
  trainRunGroupGeojson = 'TrainRunGroupGeojson',
  // Indicates that derived geojson and similar has been calculated
  trainRunGroupWithDerived = 'TrainRunGroupWithDerived',
  trainRunGroupWithFormatting = 'TrainRunGroupWithFormatting',
  // TrainRunGroups have more derived railbedTypes based on loading of sensor data
  // TODO these need to be cleaned up
  sensorDataTrainRunGroup = 'SensorDataTrainRunGroup',
  sensorDataTrainRunGroupPreloaded = 'SensorDataTrainRunGroupPreloaded',
  equipmentAttributeSensorDataTrainRunGroupPreloaded = 'EquipmentAttributeSensorDataTrainRunGroupPreloaded',
  equipmentAttributeSensorDataTrainRunGroup = 'EquipmentAttributeSensorDataTrainRunGroup',
  dateRangeSensorDataTrainRunGroupPreloaded = 'DateRangeSensorDataTrainRunGroupPreloaded',

  // For references
  trainRouteMinimized = 'TrainRouteMinimized',
  // Loaded TrainRoute
  trainRoute = 'TrainRoute',
  // Indicates that derived geojson and similar has been calculated
  trainRouteWithDerived = 'TrainRouteWithDerived',

  trainRouteGroupMinimized = 'TrainRouteGroupMinimized',
  trainRouteGroup = 'TrainRouteGroup',
  trainRouteGroupWithDerived = 'TrainRouteGroupWithDerived',

  trackDataMinimized = 'TrackDataMinimized',
  trackData = 'TrackData',
  trackDataWithDerived = 'TrackDataWithDerived',

  railwayLine = 'RailwayLine',
  railwayLineMinimized = 'RailwayLineMinimized',

  trainRouteOrGroupMinimized = 'TrainRouteOrGroupMinimized',
  trainRouteOrGroup = 'TrainRouteOrGroup',
  trainRouteOrGroupWithDerived = 'TrainRouteOrGroupWithDerived',
  trainRouteOrGroupTrackData = 'TrainRouteOrGroupTrackData',
  trainRouteOrGroupTrackDataWithDerived = 'TrainRouteOrGroupTrackDataWithDerived',

  routePoint = 'RoutePoint',
  routePointWithDerived = 'RoutePointWithDerived',
  orderedRoutePoint = 'OrderedRoutePoint',
  orderedRoutePointWithDerived = 'OrderedRoutePointsWithDerived',
  pointProjection = 'PointProjection',
  scheduledStopPoint = 'ScheduledStopPoint',
  scheduledStopPointWithDerived = 'ScheduledStopPointWithDerived',
  scheduledStopPointAndMaybeDateTime = 'ScheduledStopPointAndMaybeDateTime',
  scheduledStopPointAndMaybeDateTimeWithDerived = 'ScheduledStopPointAndMaybeDateTimeWithDerived',

  containerFilter = 'ContainerFilter',
  trainRunFilterContainer = 'TrainRunFilterContainer',
  trainRun = 'TrainRun',
  dateRangeFilter = 'DateRangeFilter',
  dateRecurrenceFilter = 'DateRecurrenceFilter',
  attributeAlertFilter = 'AttributeAlertFilter',
  attributeAlertChartFilter = 'AttributeAlertChartFilter',
  attributeAlert = 'AttributeAlert',
  trainRouteFilter = 'TrainRouteFilter',
  formationFilter = 'FormationFilter',

  trainRunFilter = 'TrainRunFilter',
  trainRunFilterDateRecurrence = 'TrainRunFilterDateRecurrence',
  trainRunFilterDateRecurrenceDate = 'TrainRunFilterDateRecurrenceDate',
  trainRunFilterDateRecurrenceTime = 'TrainRunFilterDateRecurrenceTime',
  trainRunFilterDateRecurrenceDay = 'TrainRunFilterDateRecurrenceDay',

  trainRunGroupWheelAlert = 'TrainRunGroupWheelAlert',
  wheelGroup = 'WheelGroup',
  dateRange = 'DateRange',
  wheelAttributeTimeSeries = 'WheelAttributeTimeSeries',
  wheelAttributeTimeSeriesItem = 'WheelAttributeTimeSeriesItem',
  wheelAttributeTimeSeriesItemBackreferenced = 'WheelAttributeTimeSeriesItemBackreferenced',
  wheelWithWheelAttributeTimeSeries = 'WheelWithWheelAttributeTimeSeries',
  trend = 'Trend',
  trendValue = 'TrendValue',
  trendWithBackreference = 'TrendWithBackreference',
  userState = 'UserState',
  trainDistanceInterval = 'TrainDistanceInterval',
  typeAttributeTrendCollection = 'TypeAttributeTrendCollection',
  typeAttributeTrendCollectionWithBackreferences = 'TypeAttributeTrendCollectionWithBackreferences',
  typeAttributeFlattenedTrendCollection = 'TypeAttributeFlattenedTrendCollection',


  vehicle = 'Vehicle',
  vehicleDetail = 'VehicleDetail',
  orderedVehicle = 'OrderedVehicle',
  bogie = 'Bogie',
  wheelSet = 'WheelSet',
  wheel = 'Wheel',
  wheelWithEquipmentAssets = 'WheelWithEquipmentAssets',

  equipmentAssetMinimized = 'EquipmentAssetMinimized',
  equipmentAsset = 'EquipmentAsset',
  historyAndTrendEquipmentAsset = 'HistoryAndTrendEquipmentAsset',
  wheelSetAsset = 'WheelSetAsset',
  singleSideEquipmentAsset = 'SingleSideEquipmentAsset',

  activity = 'Activity',

  chartCategoryDataConfig = 'ChartCategoryDataConfig',
  chartCategoryDataCommonConfig = 'ChartCategoryDataCommonConfig',
  chartDataConfig = 'ChartDataConfig',
  dataFeatureSet = 'DataFeatureSet',
  trackDataFeatureSet = 'TrackDataFeatureSet',
  nonSpatialDataFeatureSetMinimized = 'ChartDataFeatureSetMinimized',
  nonSpatialDataFeatureSet = 'ChartDataFeatureSet',
  dataFeatureCollection = 'DataFeatureCollection',
  dataFeatureCollectionWithBackReference = 'DataFeatureCollectionWithBackReference',
  dataFeature = 'DateFeature',
  dataFeatureSetAndTrainRUnGroupWithFormatting = 'DataFeatureSetAndTrainRUnGroupWithFormatting',
  chartPayloadItem = 'ChartPayloadItem',
  chartPayloadItemWithDerived = 'ChartPayloadItemWithDerived',
  chartPayloadItemComplete = 'ChartPayloadItemComplete',

  loadingStatus = 'LoadingStatus',
  // Top-level props
  trainProps = 'TrainProps',
  trainPropsWithDerived = 'TrainPropsWithDerived',
  trainFormationProps = 'TrainFormationProps',
  trainRouteOrGroupProps = 'TrainRouteOrGroupProps',
  trainRouteOrGroupPropsWithDerived = 'TrainRouteOrGroupPropsWithDerived',
  railwayLineProps = 'RailwayLineProps',
  railwayLinePropsWithDerived = 'RailwayLinePropsWithDerived',
  serviceLineProps = 'ServiceLineProps',
  serviceLinePropsWithDerived = 'ServiceLinePropsWithDerived',
  filteredTrainRunGroupProps = 'FilteredTrainRunGroupProps',
  filteredTrainRunGroupPropsWithDerived = 'FilteredTrainRunGroupPropsWithDerived',
  trainRunGroupProps = 'TrainRunGroupProps',
  trainRunGroupPropsWithDerived = 'TrainRunGroupPropsWithDerived',
  distanceRange = 'DistanceRange',
  trainRouteDateProps = 'DateProps',
  trainRouteDatePropsWithDerived = 'DatePropsWithDerived',
  trainFormationDateProps = 'TrainFormationDateProps',
  trainFormationDatePropsWithDerived = 'TrainFormationDatePropsWithDerived',
  attributeAlertProps = 'AttributeAlertProps',
  attributeAlertPropsWithDerived = 'AttributeAlertPropsWithDerived',
  attributeAlertChartProps = 'AttributeAlertChartProps',
  attributeAlertChartPropsWithDerived = 'AttributeAlertChartPropsWithDerived',
  trainRunGroupSensorProps = 'TrainRunGroupSensorProps',
  trainRunGroupSensorPropsWithDerived = 'TrainRunGroupSensorPropsWithDerived',
  trainRunGroupEquipmentAttributeSensorProps = 'TrainRunGroupEquipmentSensorProps',
  trainRunGroupEquipmentSensorPropsWithDerived = 'TrainRunGroupEquipmentSensorPropsWithDerived',
  trainRunGroupGeojsonProps = 'TrainRunGroupGeojsonProps',
  trainRunGroupGeojsonPropsWithDerived = 'TrainRunGroupGeojsonPropsWithDerived',
  trainRunGroupChartProps = 'TrainRunGroupChartProps',
  trainRunGroupChartPropsDerived = 'TrainRunGroupChartPropsDerived',

  // Some props are passed around enough that they are treated as Cemit railbedTypes.
  trainRouteOrGroupLineProps = 'TrainRouteOrGroupLineProps',
  trainRouteOrGroupLineFinalizedProps = 'TrainRouteOrGroupLineFinalizedProps',
  wheelSetAssetLifetime = 'WheelSetAssetLifetime',
  wheelSetAssetLifetimeFeature = 'WheelSetAssetLifetimeFeature',
  chartDataConfigAndFilteredDataFeatureSets = 'ChartDataConfigAndFilteredDataFeatureSets',
  chartDataCommonConfigFinalized = 'ChartDataCommonConfigFinalized',
  attributeAlertSummary = 'AttributeAlertSummary',
  chartDataConfigWithCustomControls = 'ChartDataConfigWithCustomControls',
  chartDataCommonConfig = 'ChartDataCommonConfig',
  dateDistance = 'dateDistance',
  lifetimeInput = 'lifetimeInput',
  attributeTableCellConfig = 'AttributeTableCellConfig',
  serviceLine = 'ServiceLine',
  rideComfortReportRowSection = 'RideComfortReportRowSection',
  rideComfortExcelDownloadData = 'RideComfortExcelDownloadData',
  rideComfortGraphqlResponseReportData = 'RideComfortGraphqlResponseReportData',
  rideComfortReportData = 'RideComfortReportData',
  dateInterval = 'DateInterval',
  rideComfortAlertType = 'RideComfortAlertType'
}

