import { useMemoActiveUserTrainRunIntervalsDistanceRange } from 'async/trainAppAsync/hooks/typeHooks/userTrainRunIntervalHooks.js';
import { all, always, length } from 'ramda';
import { unlessLoadingValue } from 'utils/componentLogic/loadingUtils.js';
import { useUpdateTrainRunsGeojsonOfUserTrainRunIntervals } from 'async/trainAppAsync/hooks/typeHooks/trainRunHooks.js';
import { useMemo } from 'react';
import { useUpdateUserTrainRunIntervalGeojsons } from 'async/trainAppAsync/hooks/geojsonHooks/userTrainRunIntervalGeojsonHooks.js';
import TrainMapGeojsonDependency from 'async/trainAppAsync/dependencies/TainMapGeojsonDependency.js';

/**
 * Keeps track of UserTrainRunInterval geojson
 * Depends directly on UserTrainRunIntervals' sensor props, since the geojson data includes
 * data from the sensors in its properties
 * @param appProps
 * @param organizationProps
 * @param trainProps
 * @param children
 * @returns {*}
 * @constructor
 */
const UserTrainRunIntervalGeojsonDependency = ({ appProps, organizationProps, trainProps, mapProps }) => {

  const loading = trainProps.userTrainRunIntervalProps.sensorProps.loading;
  const minimumTrainRunsWithImuPoints = trainProps.userTrainRunIntervalProps.sensorProps.minimumTrainRunsWithImuPoints;
  // Take the actives UserTrainRunIntervals that don't have download errors
  const activeUserTrainRunIntervals = trainProps.userTrainRunIntervalProps.activeUserTrainRunIntervalsWithoutErrors

  // Sets a geojson property of sensor points
  // on the TrainRuns of the UserTrainRunIntervals if the former don't already have it calculated
  useUpdateTrainRunsGeojsonOfUserTrainRunIntervals({
    loading: loading || !length(trainProps.userTrainRunIntervalProps.userTrainRunIntervals),
    trainRoute: trainProps.trainRouteProps.trainRoute,
    trainRuns: trainProps.trainRunProps.trainRuns,
    crudUserTrainRunIntervals: trainProps.userTrainRunIntervalProps.crudUserTrainRunIntervals,
    userTrainRunIntervals: activeUserTrainRunIntervals,
    // Indicates if a TrainRun's imuPoints have updated
    minimumTrainRunsWithImuPoints
  });

  // Extract the geojson from the UserTrainRunIntervals.
  // Here we limit the baseline run to the min and max distances of the other userTrainRunIntervals
  // so that the chart doesn't always show the range

  // Update/Create geojson properties on all current UserTrainRunIntervals. This cannot happen until all the
  // sensor data for the active userTrainRunIntervals have loaded. Whenever a UserTrainRunInterval interval
  // is changed, the geojson needs to be recomputed to correspond to the new interval
  // TrainRuns calculate their own geojson when sensor data loads, so we need to wait for that
  const trainRunGeojsonLoaded = unlessLoadingValue(loading, () => all(
    userTrainRunInterval => userTrainRunInterval.trainRunInterval.trainRun.geojson,
    activeUserTrainRunIntervals
  ));

  useUpdateUserTrainRunIntervalGeojsons({
      loading: loading || !length(activeUserTrainRunIntervals) || !trainRunGeojsonLoaded,
      trainRoute: trainProps.trainRouteProps.trainRoute,
      trainRouteAggregateInterval: trainProps.trainRouteProps.trainRouteAggregateInterval,
      userTrainRunIntervals: activeUserTrainRunIntervals,
      crudUserTrainRunIntervals: trainProps.userTrainRunIntervalProps.crudUserTrainRunIntervals,
      minimumTrainRunsWithImuPoints: trainProps.userTrainRunIntervalProps.sensorProps.minimumTrainRunsWithImuPoints
    }
  );

  // The distance range encompassing all active UserTrainRunIntervals.
  const activeUserTrainRunIntervalsDistanceRange = useMemoActiveUserTrainRunIntervalsDistanceRange({
    loading: loading || !length(activeUserTrainRunIntervals),
    userTrainRunIntervals: activeUserTrainRunIntervals
  });
  const activeUserTrainIntervalsGeojsonLoaded = unlessLoadingValue(
    loading || !(
      !trainProps.userTrainRunIntervalProps.activeUserTrainRunIntervalsWithoutErrors.length || (
        trainRunGeojsonLoaded && activeUserTrainRunIntervalsDistanceRange
      )
    ), () => {
      return !length(activeUserTrainRunIntervals) || all(
        userTrainRunInterval => userTrainRunInterval.geojson,
        activeUserTrainRunIntervals
      );
    }
  );

  // We need activeUserTrainRunIntervalsDistanceRange computed unless there are no activeUserTrainRunIntervals
  const localPropsNotReady = loading || !(
    !trainProps.userTrainRunIntervalProps.activeUserTrainRunIntervalsWithoutErrors.length || (
      trainRunGeojsonLoaded && activeUserTrainRunIntervalsDistanceRange && activeUserTrainIntervalsGeojsonLoaded
    )
  );

  const loadingExplanation = useMemo(always({
    'trainProps.userTrainRunIntervalProps.sensorProps.loading': trainProps.userTrainRunIntervalProps.sensorProps.loading,
    'trainProps.userTrainRunIntervalProps.sensorProps.loadingExplanation': trainProps.userTrainRunIntervalProps.sensorProps.loadingExplanation,
    'length activeUserTrainRunIntervalsWithoutErorrs': length(activeUserTrainRunIntervals),
    'trainRunGeojsonLoaded': trainRunGeojsonLoaded
  }), [loading, activeUserTrainRunIntervals, trainRunGeojsonLoaded]);

  return <TrainMapGeojsonDependency {...{
    appProps,
    organizationProps,
    trainProps: {
      ...trainProps,
      userTrainRunIntervalProps: {
        ...trainProps.userTrainRunIntervalProps,
        activeUserTrainRunIntervalsDistanceRange,
        geojsonProps: {
          // Generated props are stored with the userTrainRunIntervals,
          // this just tells us if their geojsons are loaded
          loading: localPropsNotReady,
          loadingExplanation,
        },
      }
    },
    mapProps
  }} />;
};
UserTrainRunIntervalGeojsonDependency.displayName = 'UserTrainRunIntervalGeojsonDependency';
export default UserTrainRunIntervalGeojsonDependency;