import { Stack } from '@mui/material';
import { TrainRunFilterDateRangesComponent } from 'components/apps/trainAppComponents/trainFilterComponents/TrainRunFilterDateRangesComponent.js';
import { TrainRunFilterDateRecurrencesLabelComponent } from 'components/apps/trainAppComponents/trainFilterComponents/TrainRunFilterDateRecurrenceComponent.js';
import { toArrayIfNot } from '@rescapes/ramda';
import TrainRunFilterFormationsContainer  from 'components/apps/trainAppComponents/trainFilterComponents/TrainRunFilterFormationsContainer.js';


/**
 * Displays the currently active TrainRunFilter, including the
 * date range(s), date recurrence(s), and formations(s). The trainRunFilterWithTrainRoute
 * is not displayed since it's presumably shown elsewhere as the selected
 * TrainRoute
 * @param appProps
 * @param organizationProps
 * @param trainProps
 * @param componentProps
 * @param componentProps.showAsOverview
 * @param sx
 * @returns {JSX.Element}
 * @constructor
 */
const TrainFilterSettings = ({ appProps, trainProps, componentProps: { showAsOverview }, sx }) => {
  const setOpen = appProps.setTrainFilterOpen;

  return <Stack spacing={showAsOverview ? 2 : 1} {...{
    direction: showAsOverview ? 'row' : 'column',
    sx: [
      {
        // We want the x to scroll but this needs debugging
        //overflowX: 'auto'
      },
      ...toArrayIfNot((sx))
    ]
  }} >
    <TrainRunFilterDateRangesComponent key='trainRunFilterDateRangesFilterLabelComponent' {...{
      trainProps, componentProps: { setOpen }
    }} />
    <TrainRunFilterDateRecurrencesLabelComponent key='trainRunFilterDateRecurrencesLabelComponent' {...{
      trainProps, componentProps: { setOpen }
    }} />
    <TrainRunFilterFormationsContainer key='trainRunFilterFormationsLabelComponent' {...{
      trainProps, componentProps: { setOpen }
    }} />
  </Stack>;
};
export default TrainFilterSettings;