import { concat, equals, identity, includes, lensProp, map, or, set, when } from 'ramda';

export const trainAppNavigationConfig = ({ t }) => {

  const navigation = [
    {
      name: t('rideComfort'),
      id: 'rideComfort',
      access: false
    },
    {
      name: t('runs'),
      id: 'runs',
      access: false
    },
    {
      name: t('runningCharacteristics'),
      id: 'runningCharacteristics',
      access: false
    }
    /*
    {
      name: t('wheelEnergy'),
      id: 'wheelEnergy',
      access: false
    }
    {
      name: t('track'),
      id: 'track',
      access: false
    },
    {
      name: t('wheelDistance'),
      id: 'wheelDistance',
      access: false
    }
     */


    /*
    {
      name: t('track'),
      id: 'track'
      access: false,
    },
    {
      name: t('passings'),
      id: 'passings'
      access: false,
    },
    {
      name: t('cable'),
      id: 'cable'
      access: false,
    },
*/
  ];
  // See what apps the user has access to based on what group they are in
  // TODO temporarily give hard-code access to train
  const tabAccess = when(identity, json => JSON.parse(json)?.tabAccess)(localStorage.getItem('tabAccess'))
  const accessToTrain = concat(['train'], tabAccess || [])
  const group = JSON.parse(localStorage.getItem('group'))?.group || [];
  // All apps are allowed for admins and those matching tabAccess
  return map(
    navigationItem => {
      const normalizedName = navigationItem.name;
      // TODO TEMP
      const access = true || or(
        equals('sysadmin', group),
        includes(normalizedName, accessToTrain)
      );
      return set(lensProp('access'), access, navigationItem);
    },
    navigation
  );
};