import LoaderWithText from 'components/loading/LoaderWithText.js';
import StationLine from 'components/apps/trainAppComponents/trainRunChooserComponents/StationLine.js';

/**
 * AreaChart. Use either a ref-setter function or React.createRef().
 * @param loading Show spinner if loading
 * @param {Object} loadingExplanation Debug object to explain why the container is loading
 * @param isOver
 * @param stopGaps
 * @param {Boolean} spaceGeospatially if true we are spacing absolutely by percent. Otherwise we space
 * based on the given stopGaps[*].offsetLefts, which are in pixels
 * @returns {JSX.Element}
 * @constructor
 */
const StationLineContainer = (
  {
    loading,
    loadingExplanation,
    trainProps,
    isUserTrainRunLine,
    isOver,
    stopGaps,
    spaceGeospatially
  }) => {

  return loading ?
    <LoaderWithText {...{text:'loadingStationLine', loadingExplanation}}/> :
    <StationLine {...{
      userTrainRunInterval: isUserTrainRunLine ? trainProps.userTrainRunIntervalProps.userTrainRunInterval : null,
      isOver,
      stopGaps,
      spaceGeospatially
    }} />;
};
export default StationLineContainer;