import React, {useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import TrainRunningCharacteristics
    from 'components/apps/trainAppComponents/trainRunningCharacteristicsComponents/TrainRunningCharacteristics.js';
import {dataPathConfiguration} from 'appConfigs/trainConfigs/dataConfig.js';
import {useMemoResolveDataPathConfigsForCategoryAndItem} from 'appUtils/trainAppUtils/trainDataUtils.js';


/**
 * Left side of page presenting a list of countries and the railroads available for each country
 * @param {Boolean} loading Hook prop indicating if the train is loading
 * @param {[Object]} trainProps
 * @param {[Object]} trainProps.trainRuns Runs of trains, meaning a run of the train from an origin to a destination
 * along with the cdcData that has been collected for that run, if any
 * @param {Object} appProps
 * @param {Object} organizationProps
 * @param {Object} trainProps
 * @param {Object} mapProps
 * @returns {JSX.Element}
 * @constructor
 */
const TrainRunningCharacteristicsContainer = (
    {
        appProps,
        organizationProps,
        trainProps,
        mapProps
    }) => {

    const count = useRef(null);
    useEffect(() => {
        if (count.current == null) {
            mapProps.trainMap && mapProps.trainMap.resize();
        }
        return () => {
            if (mapProps.trainMap) {
                count.current = 1;
            }
        }
    }, [mapProps.trainMap]);
    const {t} = useTranslation();
    const {chosenAcceleration, setChosenAcceleration} = appProps;
    const resolvedDataPathConfiguration = dataPathConfiguration({
            t, appProps,
            organizationProps,
            trainProps
        }
    )
    const dataPathsConfigs = useMemoResolveDataPathConfigsForCategoryAndItem(
        resolvedDataPathConfiguration,
        'acceleration',
        chosenAcceleration.derivedProp
    )

    return <TrainRunningCharacteristics {...{
        appProps,
        organizationProps,
        trainProps,
        componentProps: {
            dataPathsConfigs,
            chosenAcceleration,
            setChosenAcceleration
        }
    }} />;
};
TrainRunningCharacteristicsContainer.propTypes = {};
export default TrainRunningCharacteristicsContainer;
