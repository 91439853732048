import { chainObjToValues, flattenObj, mapObjToValues, memoizedWith, reqStrPathThrowing } from '@rescapes/ramda';
import nearestPoint from '@turf/nearest-point';
import {
  always,
  ascend,
  chain,
  cond,
  equals,
  filter,
  find,
  findIndex,
  gte,
  identity,
  isNil,
  join,
  last,
  length,
  map,
  prop,
  propEq,
  propOr,
  slice,
  sortWith,
  T
} from 'ramda';
import { eqStrPath } from '@rescapes/ramda/src/propPathFunctions.js';
import { consolidateRanges, rangeDifferenceWithOrderedRanges } from 'utils/distance/distanceUtils.js';
import { trainRunImupointsWithMostData } from 'appUtils/trainAppUtils/trainDataUtils.js';

/**
 * Gets the ScheduledStopPoint of the TimetabledPassingDatetime
 * @param timetabledPassingDatetime
 * @returns {*}
 */
export const scheduledStopPointOfTimeTabledPassingDatetime = timetabledPassingDatetime => {
  return reqStrPathThrowing('timetabledPassingTime.stopPointInJourneyPattern.scheduledStopPoint', timetabledPassingDatetime);
};

/**
 * Gets the scheduledStopPoints of the TrainRun
 * @param trainRun
 */
export const scheduledStopPointsOfTrainRun = trainRun => {
  return map(
    scheduledStopPointOfTimeTabledPassingDatetime,
    trainRun.timetabledPassingDatetimes);
};

/**
 * Hashes the ids and activity state of the UserTrainRunInterval to detect change
 * TODO we should be able simply use an object reference to detect change
 * @param trainRunInterval
 * @param user
 * @param activity
 * @returns {*}
 */
export const userTrainRunIntervalHash = ({ trainRunInterval, user, activity }) => {
  return join(',', mapObjToValues((v, k) => `${k}:${v}`, {
    // TrainRunIntervals aren't yet persisted with an id, so use the sourceKey
    trainRunInterval: trainRunInterval.sourceKey,
    user: user.id.toString(),
    ...flattenObj(activity)
  }));
};
/**
 // TODO hashing to create an id until we have ids from the server
 * @param {[Object]} userTrainRunIntervals The UserTrainRunIntervals
 * @returns {String} The hash
 */
export const userTrainRunIntervalsHash = userTrainRunIntervals => {
  return join(';', chain(userTrainRunIntervalHash, userTrainRunIntervals));
};
/**
 * Returns the TrainRuns matching the TrainRoute based on each one's route
 * @param trainRoute
 * @param trainRuns
 * @return [{Object}] The matching TrainRuns
 */
export const trainRunsMatchingTrainRoute = ({ trainRoute, trainRuns }) => {
  return filter(
    trainRun => {
      return eqStrPath('route.id', trainRoute, trainRun);
    },
    trainRuns
  );
};

/**
 * Extract the trainRoute's start (always 0) and end distance in meter
 * @param trainRunInterval A TrainRouteInterval, TrainRunInterval, or UserTrainRunInterval
 * @returns {{start: number, end}}
 */
export const extractTrainRunIntervalExtremes = trainRunInterval => {
  const trainRoute = extractTrainRunIntervalTrainRoute(trainRunInterval);
  return { start: 0, end: trainRoute.routeDistance };
};

/**
 * Extracts the TrainRoute based on the type of trainRunInterval
 * @param trainRunInterval
 * @returns {*}
 */
export const extractTrainRunIntervalTrainRoute = trainRunInterval => {
  const trainRoute = cond([
    [obj => isTrainRouteInterval(obj), prop('trainRoute')],
    [obj => isTrainRunInterval(obj), reqStrPathThrowing('trainRun.trainRoute')],
    [obj => isUserTrainRunInterval(obj), reqStrPathThrowing('trainRunInterval.trainRun.trainRoute')],
    [T, () => {
      throw new Error('trainRunInterval is not a TrainRouteInterval, or TrainRunInterval, or UserTrainRunInterval');
    }]
  ])(trainRunInterval);
  return trainRoute;
};

/**
 * Returns the loading flag based on type of trainRunInterval
 * @param trainProps
 * @param trainRunInterval
 * @returns {*}
 */
export const loadingStateOfTrainRunLine = (trainProps, trainRunInterval) => {
  const loading = cond([
    [isNil, always(true)],
    [obj => isTrainRouteInterval(obj), always(trainProps.trainRouteProps.loading)],
    [obj => isTrainRunInterval(obj), always(trainProps.trainRunIntervalProps.loading)],
    [obj => isUserTrainRunInterval(obj), always(trainProps.userTrainRunIntervalProps.loading)],
    [T, () => {
      throw new Error('trainRunInterval is not a TrainRouteInterval, or TrainRunInterval, or UserTrainRunInterval');
    }]
  ])(trainRunInterval);
  return loading;
};
export const isTrainRouteInterval = trainRunInterval => {
  return equals('TrainRouteInterval', trainRunInterval.__type);
};
export const isTrainRunInterval = trainRunInterval => {
  return equals('TrainRunInterval', trainRunInterval.__type);
};
export const isUserTrainRunInterval = trainRunInterval => {
  return equals('UserTrainRunInterval', trainRunInterval.__type);
};

/**
 * A TrainRun type
 * @typedef {Object} TrainRun
 */

/**
 * An ImuPoint type
 * @typedef {Object} ImuPoint
 */


/**
 * TODO configure in settings. Determines what distanceInterval to load for the current
 * userTrainRunInterval.ditanceRange
 * @returns {*}
 */
export const distanceIntervalOfDistanceRange = distanceRange => {
  const distance = distanceRange.end - distanceRange.start;
  return cond([
    [gte(20000), () => {
      return 50;
    }],
    [gte(1000), () => {
      return 10;
    }],
    [T, always(100)]
  ])(distance);
};
/**
 * Calculates the distanceInterval and distanceRanges that need to be requested based on the current
 * userTrainRunInterval.distanceRange. The distanceInterval is based on how big the distanceRange is
 * The default distanceInterval is currently 100m, meaning
 * we request an ImuPoint every 100m. If we have a small distanceRange, we can afford to request at higher
 * resolution. For now just make every request for less than or equal 5000 meters a distance interval of 50m,
 * end every request for less than or equal to 1000 meters a distance interval of 10m. These resolutions
 * will need to be tuned over time and possibly per client.
 * The distanceRanges are calculated to be the distanceRanges that have not already been requested at this
 * distanceInterval, as stored in alreadyRequestedDateRangesAtDistanceIntervals
 * @param alreadyRequestedDateRangesAtDistanceIntervals List of objects in the form [
 *  {distanceInterval: 100, distanceRanges: [{start: startDistance1 end: endDistance1}, {start: startDistance2 end: endDistance2}, ...]}
 *  {distanceInterval: 50, distanceRanges: [{start: startDistance1 end: endDistance1}, {start: startDistance2 end: endDistance2}, ...]}
 *  ...
 * ]
 * indicating what distanceRanges have already been requested for a certain distanceInterval in meters
 * @param {Object} userTrainRunInterval The UserTrainRunInterval that has changed distanceRange or just been created/loaded and thus
 * needs to download its ImuPoints. If the distanceRange is over 5000 meters, we always download at 100m for the whole TrainRun interval.
 * This is the default download that occurs when the UserTrainRunInterval is create/loaded
 * @returns {distanceInterval, distanceRanges, consolidatedDistanceRanges} where distanceInterval
 * is the distance interval we need to request (e.g. 100, 50, or 10 meters), distanceRanges are the ranges we need,
 * and consolidatedDistanceRanges are the consolidated distanceRanges of what we already have and are requesting
 * at this distanceInterval.
 */
export const userTrainRunDistanceRangeAndIntervalDifference = (
  alreadyRequestedDateRangesAtDistanceIntervals,
  userTrainRunInterval
) => {
  // We want the distanceRange of the underlying TrainRun here, because we always requests ImuPoints from the
  // server based on that range. It may well be than that of the current TrainRouteGroup assign to
  const distanceRange = userTrainRunInterval.trainRunInterval.distanceRange;
  const distanceInterval = distanceIntervalOfDistanceRange(distanceRange);

  // Find the difference between what is already loaded and the distanceRange and distanceInterval
  // First find the existing item with the sough distanceInterval if it exists
  const trainRunLoadedImuPointDataAtDistanceInterval = find(
    propEq('distanceInterval', distanceInterval),
    alreadyRequestedDateRangesAtDistanceIntervals
  );

  // Take its distanceRanges
  const alreadyLoadedOrderedDistanceRanges = propOr([], 'distanceRanges', trainRunLoadedImuPointDataAtDistanceInterval);
  // Get the difference of the given distanceRange with those already existing so we know what ranges we need
  const differenceRanges = rangeDifferenceWithOrderedRanges(alreadyLoadedOrderedDistanceRanges, distanceRange);
  return {
    distanceInterval,
    // Indicates the distanceRanges that we want to query for
    distanceRanges: differenceRanges,
    // Combine the existing with the new so we can store what has been loaded after we query
    consolidatedDistanceRanges: consolidateRanges(alreadyLoadedOrderedDistanceRanges, differenceRanges)
  };
};

/**
 * Returns the index of the given distanceInterval in trainRunIdsRequestedWithImuPoints
 * for the given trainRunId
 * @param trainRunIdsRequestedWithImuPoints
 * @param trainRunId
 * @param distanceInterval
 * @returns {*}
 */
export const distanceIntervalIndexForTrainRunId = (
  {
    trainRunIdsRequestedWithImuPoints,
    trainRunId,
    distanceInterval
  }) => {
  return findIndex(
    obj => equals(distanceInterval, obj.distanceInterval),
    trainRunIdsRequestedWithImuPoints[trainRunId]
  );
};
/**
 * Syncs the trainRunInterval back to its trainRouteInterval's distanceRange. This can occur
 * when the user doesn't want a TrainRun to have a TrainRunInterval that is different from its trainRouteInterval's
 * distanceRange
 * @param {Object} crudTrainRunIntervals
 * @param {Function} crudTrainRunIntervals.updateOrCreate Called to update the trainRunInterval
 * @param {[Object]} crudTrainRunIntervals.list Used to find trainRunInterval.trainRouteInterval
 * @param {Object} trainRunInterval The trainRunInterval to update
 * @returns {Object} The updated trainRunInterval
 */
export const syncTrainRunIntervalToTrainRouteInterval = (crudTrainRunIntervals, trainRunInterval) => {
  const trainRouteInterval = find(
    trainRunInterval => {
      isTrainRouteInterval(trainRunInterval) && equals(trainRunInterval.sourceKey, trainRunInterval.trainRouteInterval.sourceKey);
    }, crudTrainRunIntervals.list
  );
  crudTrainRunIntervals.updateOrCreate({
    trainRunInterval,
    // Keep synced
    isSyncedToTrainRouteInterval: true,
    // Set to the template's current distanceRange
    distanceRange: trainRouteInterval.distanceRange
  });
};
/**
 * TrainRuns key their imuPoints by collection device sourceKey
 * @param trainRun
 * @returns {boolean|*}
 */
export const trainRunHasImuPoints = trainRun => {
  if (!trainRun.imuPoints) {
    return false;
  }
  // Check if any CollectionDevice has imuPoints
  return !!length(chainObjToValues(identity, trainRun.imuPoints));
};
/**
 * Get the imuPoints matching the time period and direction of the TrainRun.
 * This uses a buffer of TRAIN_RUN_IMUPOINT_MINUTE_BUFFER to compensate for late trains and removes any imuPoints at the end
 * that are going the wrong way.
 * @param trainRun
 * @returns {*}
 */
export const imuPointsOfTrainRun = memoizedWith(
  trainRun => [trainRun.id, trainRun.distanceIntervalsAndRanges],
  trainRun => {
    const endPoint = last(scheduledStopPointsOfTrainRun(trainRun)).geojson;
    const imuPointsWithinTimeInterval = trainRunImupointsWithMostData(trainRun);

    // Find the point that is closest to the end station and eliminate all points after that
    const imuPointsGeojson = map(prop('geojson'), imuPointsWithinTimeInterval);
    const { properties: { featureIndex } } = nearestPoint(endPoint, {
      type: 'FeatureCollection',
      features: imuPointsGeojson
    });
    // Take the slice
    return slice(0, featureIndex + 1, imuPointsWithinTimeInterval);
  }
);

export const sortTrainRuns = trainRuns => {
  return sortWith(
    [ascend(prop('departureDatetime')),
      ascend(prop('arrivalDatetime'))
    ])(trainRuns);
};