import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

/**
 * Sentry initialization for the Cemit application
 */
export const sentryInit = () => {
  Sentry.init({
    dsn:
      'https://64128b0857974f168073dce3e4ff31d9@o1047570.ingest.sentry.io/6164876',
    integrations: [new Integrations.BrowserTracing()],
    environment: APP_ENV,
    release: APP_RELEASE,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.05
  });
};

/**
 * Sets the user base on the Authentication response
 * @param json
 */
export const setUserFromAuthenticationResponse = json => {
  Sentry.setUser({ email: json.message.email });
}