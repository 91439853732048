import trainApiResolver from 'lib/fetch/cemitApi/trainApiResolver.js';
import useSWR from 'swr';
/**
 * Uses an SWR to get client data.
 * TODO In the future we won't have client-specific methods
 * {data, error} is returned when data is loaded or an error occurs
 * @param {Boolean} loading If true do nothing
 * @param {Object} client
 * @param {Object} client.sourceKey
 * label for the current client 'flytoget', 'cargonet', etc
 * @param {String} method Default null. Most clients have multiple API methods
 * @param {Object} props The same props accepted by trainApiResolver
 * @returns {{data, error: null}|*} The {data, error} structure when the load completes
 */
export const useResolveTrainApiData = (
  {
    loading,
    method = null,
    ...props
  }) => {

  // A null url tells SWR not to query
  const url = loading ? null : trainApiResolver({ method, ...props });
  return useSWR(
    url,
    props,
    props
  );
};