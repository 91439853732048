import {
    chain,
    compose,
    equals,
    filter,
    head,
    identity,
    includes,
    indexOf,
    join,
    last,
    length,
    lensProp,
    map,
    mergeRight,
    set,
    slice,
    sortBy,
    uniq,
    uniqBy
} from 'ramda';
import {
    TRAIN_RUN_INTERVAL_TRACK_LAYER_PREFIX,
    TRAIN_RUN_INTERVAL_TRACK_SOURCE_PREFIX,
    TRAIN_RUN_INTERVAL_EXTRUDED_COLUMN_LAYER_PREFIX,
    TRAIN_RUN_INTERVAL_EXTRUDED_COLUMN_SOURCE_PREFIX, MAP_RAILWAY_SOURCE, MAP_RAILWAY_LAYER
} from 'appConfigs/trainConfigs/trainConfig.js';
import {memoizedUserTrainRunInterval3dColumnSourceAndLayers} from 'utils/map/layers/3dColumns/3dColumnLayers.js';
import {compact, reqStrPathThrowing} from '@rescapes/ramda';
import {useNotLoadingMemo, useNotLoadingSetterEffect} from 'utils/hooks/useMemoHooks.js';
import {removeMapboxLayersAndSources, setMapboxSourceAndLayersSets} from 'utils/map/mapboxSourceUtils.js';
import {setMapboxOnHover} from 'async/trainAppAsync/hooks/mapHooks/trainMapHooks.js';
import {
    correctPayload,
    overrideUserTrainRunIntervalsDistanceRanges
} from 'appUtils/trainAppUtils/userTrainRunIntervalUtil.js';
import {userTrainRunIntervalUniqueLabel} from 'appUtils/trainAppUtils/formationUtils.js';
import {pointFeatureFromPayLoadItem} from 'components/charts/stackedChart/chartMapInteractionUtils.js';

/**
 * Draws the position of the cursor and sets up the map popup
 * @param appProps
 * @param trainProps
 * @param trainMap
 * @param e
 * @param popup
 * @param userTrainRunInterval
 * @param hoveredStateId
 * @param t
 * @param payload
 */
const onHoverUpdate3dForTrainMap = (
    {
        appProps,
        trainProps,
        userTrainRunInterval,
        t
    }, payload) => {

    const filledOutPayload = map(
        payloadItem => {
            // If the payloadItem.payload is not a Point feature, make it one
            const payloadItemAsPoint = set(
                lensProp('payload'),
                pointFeatureFromPayLoadItem(payloadItem),
                payloadItem
            );
            // This object matches the important properties created by recharts when we hover
            // It allows hovering on the map to produce the same style chart data as the charts
            return {
                name: userTrainRunIntervalUniqueLabel({t, userTrainRunInterval}),
                stroke: userTrainRunInterval.activity.isActiveColor,
                color: userTrainRunInterval.activity.isActiveColor,
                // This would normally be the value of the hovered property on the chart. The map layers
                // have no specified feature property
                value: null,
                userTrainRunInterval,
                // These two instruct ImuPointStatsComponentContainer to treat this payload item as the active one.
                // The previous items are marked not active below.
                metersOfHoveredItem: payloadItemAsPoint.payload.properties.meters,
                isActivePayloadItem: true,
                ...payloadItemAsPoint
            };
        },
        // TODO we currently can only show up to 3 items
        slice(0, 3, payload)
    );
    const metersOfHoveredItem = head(filledOutPayload).payload.properties.meters;

    // Update mostRecentTooltipPayload if it has changed. Since we can typically only hover on one feature
    // of the map at a time, we concat the new payload to the existing but make sure to only have one
    // payloadItem per TrainRun. We also mark the new one with the metersOfHoveredItem = its meters, which
    // instructs ImuPointsStatsComponentContainer to find update the other values to be the points closes to this
    // one
    if (length(payload) && !equals(payload, trainProps.userTrainRunIntervalProps.sensorProps.mostRecentTooltipPayload)) {
        const sourceKeys = uniq(
            map(
                reqStrPathThrowing('userTrainRunInterval.sourceKey'),
                filledOutPayload)
        );
        trainProps.userTrainRunIntervalProps.sensorProps.setMostRecentTooltipPayload(
            previousPayloadItems => {
                const keptPreviousPayloadItems = compose(
                    map(
                        // Overwrite the metersOfHoveredItem to the meters value of the now active map hover item.
                        previousPayloadItem => mergeRight(
                            previousPayloadItem, {
                                metersOfHoveredItem, isActivePayloadItem: false
                            }
                        )
                    ),
                    filter(
                        previousPayloadItem => {
                            return previousPayloadItem.userTrainRunInterval && !includes(previousPayloadItem.userTrainRunInterval.sourceKey, sourceKeys);
                        }
                    )
                )(previousPayloadItems || []);
                const sortedPayloadItems = sortBy(
                    payloadItem => indexOf(payloadItem.userTrainRunInterval, trainProps.userTrainRunIntervalProps.activeUserTrainRunIntervalsWithoutErrors),
                    // Don't allow duplicates UserTrainRunIntervals
                    uniqBy(
                        reqStrPathThrowing('userTrainRunInterval.sourceKey'),
                        [...keptPreviousPayloadItems, ...filledOutPayload]
                    )
                );
                return correctPayload(
                    ({
                        t,
                        trainRouteAggregateInterval: trainProps.trainRouteProps.trainRouteAggregateInterval,
                        userTrainRunIntervals: trainProps.userTrainRunIntervalProps.activeUserTrainRunIntervalsWithoutErrors,
                        payloadItems: sortedPayloadItems
                    })
                );
            }
        );
    }
    appProps.moveCursorAlongTrainMapLine(userTrainRunInterval, filledOutPayload);
};

/**
 * Calls trainMap3dLayers and updates the trainMap to the results
 * @param {Boolean} loading If true, do nothing
 * @param {Function} set3dLayersUpdating Setter set true while the effect is running
 * @param {Object} props See trainMap3dLayers
 */
export const useUpdate3dForTrainMap = (
    {
        loading,
        appProps,
        organizationProps,
        trainProps,
        trainMap,
        trainRoute,
        set3dLayersUpdating,
        featurePropPath,
        dataThresholds,
        dataColumns3DLayerAreVisible,
        t
    }) => {

    const {userTrainRunIntervals, userTrainRunIntervalsHaveChanged} = useNotLoadingMemo(loading, () => {
            return {
                // For the purposes of the map, override the UserTrainRunInterval.distanceRange
                // to that of the aggregate in case our scope is a TrainRouteGroup which is longer than
                // the UserTrainRunInterval's TrainRoute. TODO verify that this is still necessary
                userTrainRunIntervals: overrideUserTrainRunIntervalsDistanceRanges(
                    trainProps.trainRouteProps.trainRouteAggregateInterval.distanceRange,
                    trainProps.userTrainRunIntervalProps.activeUserTrainRunIntervalsWithoutErrors
                ),
                userTrainRunIntervalsHaveChanged: trainProps.userTrainRunIntervalProps.instancesChanged
            };
        }, [
            !loading && trainProps.userTrainRunIntervalProps.instancesChanged,
            !loading && trainProps.userTrainRunIntervalProps.activeUserTrainRunIntervalsWithoutErrors,
            !loading && trainProps.trainRouteAggregateInterval
        ]
    ) || {};

    return useNotLoadingSetterEffect({
            loading,
            inProcessSetter: set3dLayersUpdating
        },
        trainMap3dLayers,
        {
            trainRoute,
            userTrainRunIntervals,
            featurePropPath,
            dataThresholds,
            dataColumns3DLayerAreVisible
        },
        // Update the trainMap to the sources and layers
        sourceAndLayersSetPairs => {
            const sourceAndLayersSets = chain(identity, sourceAndLayersSetPairs);
            // Set the sources and layers on TrainMap
            setMapboxSourceAndLayersSets(
                {
                    mapboxMap: trainMap,
                    sourceAndLayersSets,
                    zoomToSources: true
                }
            );

            // Sets the 3D column layers to respond to a mouse over so we can tell the app what the hover feature(s) are
            /// We can't currently do this with the UserTrainRunInterval track layers because they are lines, not single features.
            // However if we switch them to line segments then they can detect what feature(s) is hovered over
            const extrudeSourceAndLayersSets = map(sourceAndLayersSetPair => last(sourceAndLayersSetPair), sourceAndLayersSetPairs);

            setMapboxOnHover({
                appProps,
                organizationProps,
                trainProps,
                mapboxMap: trainMap,
                sourceAndLayersSets: extrudeSourceAndLayersSets,
                onHover: onHoverUpdate3dForTrainMap,
                t
            });

            // Hack don't show this layer on the ride_comfort page
            if (appProps.currentAppPage == 'ride_comfort') {
                // If dataColumns3DLayerAreVisible is false, make sure the sources/layers are gone
                removeMapboxLayersAndSources({
                    layerPrefix: TRAIN_RUN_INTERVAL_EXTRUDED_COLUMN_LAYER_PREFIX,
                    sourcePrefix: TRAIN_RUN_INTERVAL_EXTRUDED_COLUMN_SOURCE_PREFIX,
                    mapboxMap: trainMap
                });
                removeMapboxLayersAndSources({
                    layerPrefix: TRAIN_RUN_INTERVAL_TRACK_LAYER_PREFIX,
                    sourcePrefix: TRAIN_RUN_INTERVAL_TRACK_SOURCE_PREFIX,
                    mapboxMap: trainMap
                });
                removeMapboxLayersAndSources({
                    sourcePrefix: MAP_RAILWAY_SOURCE,
                    layerPrefix: MAP_RAILWAY_LAYER,
                    mapboxMap: trainMap,
                    preserveSourceVisuals: []
                });
            } else {
                // Remove any MAP_USER_TRAIN_RUN_LAYER_PREFIX layers from previous iterations that are no longer present
                removeMapboxLayersAndSources({
                    layerPrefix: TRAIN_RUN_INTERVAL_EXTRUDED_COLUMN_LAYER_PREFIX,
                    sourcePrefix: TRAIN_RUN_INTERVAL_EXTRUDED_COLUMN_SOURCE_PREFIX,
                    excludeSourceAndLayersSets: sourceAndLayersSetPairs,
                    mapboxMap: trainMap
                });
                removeMapboxLayersAndSources({
                    layerPrefix: TRAIN_RUN_INTERVAL_TRACK_LAYER_PREFIX,
                    sourcePrefix: TRAIN_RUN_INTERVAL_TRACK_SOURCE_PREFIX,
                    excludeSourceAndLayersSets: sourceAndLayersSetPairs,
                    mapboxMap: trainMap
                });
            }
        },
        [
            // If this array changes, it means one of the UserTrainRunIntervals'
            // trainRunInterval.distanceRanges changed.
            userTrainRunIntervals,
            userTrainRunIntervalsHaveChanged,
            // Update if the user toggles columns
            dataColumns3DLayerAreVisible,
            // Update if the feature props the user is looking at change
            featurePropPath,
            appProps?.currentAppPage
        ]
    );
};

/**
 * @param {[Object]} userTrainRunIntervals The UserTrainRunInterval instances that are being displayed
 * @param {Boolean} userTrainRunIntervalsHaveChanged Flag to indicate that the 3d layers need to update
 * to reflect difference instances in userTrainRunIntervals
 * @param {[Object]} userTrainRunIntervalGeojsons Geojson representation of the UserTrainRunIntervals
 * @param {String} featurePropPath A property path in the geojson data whose value we visualize in 3d
 * @param {Object} dataThresholds Thresholds for coloring the 3d data based on value
 * @param {Function} set3dLayersUpdating Setter to indicate an update of the 3d is in process
 * @param {Boolean} dataColumns3DLayerAreVisible True if the user wants to see the 3D columns
 * @param {Object} trackData The TrainRoute's trackData
 */
const trainMap3dLayers = (
    {
        userTrainRunIntervals,
        featurePropPath,
        dataThresholds,
        dataColumns3DLayerAreVisible
    }) => {

    // Creates a flat list of sourceAndLayers sets, where each userTrainRunInterval produces
    // a track line shifted perpendicular from the real track (unless the userTrainRunInterval is baseline,
    // since baseline is visualized over the actual track).
    // Also optionally produces a 3d column sourceAndLayers set if dataColumns3DLayerAreVisible is true
    const sourceAndLayersSets = map(
        userTrainRunInterval => {
            const isBaseline = userTrainRunInterval.activity?.isBaseline;
            // Name layers and sources by the UserTrainRunInterval sourceKey
            const sourceName = join('-', [TRAIN_RUN_INTERVAL_TRACK_SOURCE_PREFIX, userTrainRunInterval.sourceKey]);
            const trackLayerId = join('-', [TRAIN_RUN_INTERVAL_TRACK_LAYER_PREFIX, userTrainRunInterval.sourceKey]);

            // Get the track line that is transformed perpendicular to the original track
            const trackLineTransformed = userTrainRunInterval.geojson.trackLineTransformed;
            const trackLineSource = {
                name: sourceName,
                type: 'geojson',
                data: {
                    type: 'FeatureCollection',
                    features: [trackLineTransformed]
                }
            };

            // Draw the track layer if not the baseline run. The baseline
            // is already represented by the actual track
            const trackLayer = !isBaseline ? {
                id: trackLayerId,
                type: 'line',
                source: sourceName,
                paint: {
                    'line-color': userTrainRunInterval.activity.isActiveColor,
                    'line-width': 5
                },
                layout: {
                    'visibility': userTrainRunInterval.activity.isVisible ? 'visible' : 'none'
                }
            } : null;

            const trackSourceAndLayers = trackLayer ? {
                source: trackLineSource,
                layers: [trackLayer],
                userTrainRunInterval
            } : null;

            const trainRunIntervalSensorPointsTransformed = userTrainRunInterval.geojson.trainRunIntervalSensorPointsTransformed;
            const columnSourceAndLayers = memoizedUserTrainRunInterval3dColumnSourceAndLayers({
                userTrainRunInterval,
                featurePropPath,
                geojson: trainRunIntervalSensorPointsTransformed,
                dataThresholds,
                extrude: dataColumns3DLayerAreVisible
            });

            return compact([trackSourceAndLayers, columnSourceAndLayers]);
        },
        userTrainRunIntervals
    );
    return sourceAndLayersSets;
};
