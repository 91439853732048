import { unlessLoadingValue } from 'utils/componentLogic/loadingUtils.js';
import { always, compose, concat, equals, length, prop, sortBy, uniqBy, when } from 'ramda';
import { useNotLoadingMemo } from 'utils/hooks/useMemoHooks.js';
import {
  useMemoCreateStopGaps,
  useMemoZipVisibleScheduledStopPointsAndMaybeTimesWithOffsetLefts
} from 'appUtils/trainAppUtils/trainRunLineUtils.js';
import { compact } from '@rescapes/ramda';
import { useNotLoadingDidMountEffect } from 'utils/hooks/domHooks.js';
import { useMemoVisibleScheduledStopPoints } from 'async/trainAppAsync/hooks/typeHooks/scheduledStopPointHooks.js';
import { useInitStopOffsetLefts } from 'async/trainAppAsync/hooks/typeHooks/trainRunLineHooks.js';
import { useMemo } from 'react';

/**
 * Hook-dependent props  of TrainRUnLineReadyContainer
 * @param loading
 * @param trainProps
 * @param trainRoute
 * @param trainRouteOrRunInterval
 * @param scheduledStopPointsAndMaybeTimes
 * @param spaceGeospatially
 * @param onlyStopsNearInterval
 * @param isTrainRouteLine
 * @param limitedDistanceRange
 * @param offsetLefts
 * @param setOffsetLefts
 * @param setRecalculateOffsetLefts
 * @returns {{stopGaps: Object[]}}
 */
export const trainRunLineReadyHookProps = (
  {
    loading,
    trainProps,
    trainRoute,
    trainRouteOrRunInterval,
    scheduledStopPointsAndMaybeTimes,
    spaceGeospatially,
    onlyStopsNearInterval,
    isTrainRouteLine,
    limitedDistanceRange,
    offsetLefts,
    setOffsetLefts,
    setRecalculateOffsetLefts,
  }
) => {
  // Determine which ScheduledStopPoints of the TrainRoute
  // or TimetabledPassingTimes of a TrainRun (ScheduledStopPoints with times) are visible on the TrainRunLine
  // If onlyStopsNearInterval, calculate which TimetabledPassingTime instances are visible
  // based on the routeDistance of each timetabledPassingTime and the trainRouteOrRunInterval.
  // We also need to include the first and last TimetabledPassingTimes for when the user drags the bar beyond
  // the start and end unless includeEndStops is false
  const visibleScheduledStopPointsAndMaybeTimes = useMemoVisibleScheduledStopPoints(
    {
      loading,
      trainProps,
      trainRoute,
      trainRouteOrRunInterval,
      config: {
        spaceGeospatially,
        onlyStopsNearInterval,
        limitedDistanceRange,
        isTrainRouteLine,
        scheduledStopPointsAndMaybeTimes
      }
    }
  );

  const distanceRange = useNotLoadingMemo(loading,
    () => {
      return trainRouteOrRunInterval.distanceRange;
    },
    [trainRouteOrRunInterval.distanceRange]
  );

  // Offset lefts need to be set to absolutely based on spaceGeospatially or initialized to an array of null
  // if not spaceGeospatially. In the latter case the TrainLineStationDots fill this array once they know
  // their flex position.
  // If the distanceRange changes and we have a limitedDistanceRange, we recalculate the offset lefts, in
  // which case the if not spaceGeospatially, the offets are nulled an TrainLineStationDots are informed
  // that the need to set their offset again in this array:w
  useInitStopOffsetLefts({
    loading,
    spaceGeospatially,
    limitedDistanceRange,
    distanceRange,
    setOffsetLefts,
    scheduledStopPoints: visibleScheduledStopPointsAndMaybeTimes,
    offsetLefts
  });

  // Don't calculate stopGaps until our offsetLeftsLength have been set based on visibleScheduledStopPoints in TrainLineStations
  // Always true when geospatially spacing stops
  const areOffsetLeftsReady = unlessLoadingValue(
    loading,
    () => equals(length(compact(offsetLefts || [])), length(visibleScheduledStopPointsAndMaybeTimes))
  );

  // After mounting, every time distanceRange changes, reset or recompute the offset lefts
  useNotLoadingDidMountEffect(loading, () => {
    // We don't need to reset unless we have a limited distance range or space geospatially because the stations don't move.
    if (!areOffsetLeftsReady || (limitedDistanceRange || spaceGeospatially)) {
      // Flip the toggle to tell children to recalculate their offsetLeft
      setRecalculateOffsetLefts(Date.now())
    }
  }, [distanceRange, visibleScheduledStopPointsAndMaybeTimes]);

  // Create an array to hold the leftOffsets of each of the station dots on the line
  // We need these to draw the line itself between the start and end station,
  // and we need to convert the kilometer distances to TrainLineStation on the line based on the position of each station.
  const visibleScheduledStopPointsAndMaybeTimesWithOffsetLefts = useMemoZipVisibleScheduledStopPointsAndMaybeTimesWithOffsetLefts(
    loading || !offsetLefts,
    visibleScheduledStopPointsAndMaybeTimes,
    offsetLefts
  );

  // List of timetabledPassingTime pairs and whether there is a gap in the TrainRunLine
  // because we always show the first and last station along with the
  // stations in the UserTrainRunInterval
  // We need the offsetLefts values to be ready so that we know start and stop points of the stop gaps
  const stopGaps = useMemoCreateStopGaps(
    {
      loading: !areOffsetLeftsReady,
      offsetLefts,
      //
      // Add the pseudo stops at the ends when spaceGeospatially
      routeStops: when(
        always(spaceGeospatially),
        timetabledPassingDatetimes => {
          return compose(
            // Sort by route distance
            sortBy(prop('routeDistance')),
            // Remove duplicates by route distance TODO can we use ids here now?
            uniqBy(prop('routeDistance')),
            // Combine all visible scheduledStopPointsAndMaybeTimes (stops) with the stops of the route
            routeStops => concat(visibleScheduledStopPointsAndMaybeTimes, routeStops)
          )(timetabledPassingDatetimes);
        }
      )(scheduledStopPointsAndMaybeTimes),
      visibleStops: visibleScheduledStopPointsAndMaybeTimes
    }
  );
  const loadingExplanation = useMemo(always({
    areOffsetLeftsReady,
    offsetLefts,
    visibleScheduledStopPointsAndMaybeTimes,
  }), [areOffsetLeftsReady, offsetLefts, visibleScheduledStopPointsAndMaybeTimes]);

  return {
    loadingExplanation,
    visibleScheduledStopPointsAndMaybeTimes,
    visibleScheduledStopPointsAndMaybeTimesWithOffsetLefts,
    distanceRange,
    stopGaps,
    areOffsetLeftsReady
  };
};