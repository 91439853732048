import { SWRConfig } from 'swr';
import { includes, map, when } from 'ramda';
import { TRAIN_API_DATE_ATTRIBUTES } from 'appConfigs/trainConfigs/trainConfig.js';

/**
 * Deserialize keys that include 'date' and 'time' back to a Date start_date, 'source:date', departureDatetime, arrivalDatetime, medianDatetime
 * @param key
 * @param value
 * @return {*}
 */
export const reviver = (key, value) => {
  return when(
    () => {
      return includes(key,
        TRAIN_API_DATE_ATTRIBUTES
      );
    },
    date => {
      return Array.isArray(date) ?
        map(date => {
          return new Date(date);
        }, date) :
        new Date(date);
    }
  )(value);
};

/**
 * Fetcher to fetch within SWRConfig or without. SWRConfig Seems buggy. It doesn't always fetch
 * Returns [{...args, error}] if all retries fail
 * @param abortController
 * @param retries Default 3. The number of retries
 * @param args
 * @returns {Promise<string>}
 */
export const fetcher = ({ abortController = new AbortController(), retries = 3, timeout = null }, ...args) => {
  let id
  if (timeout) {
    id = setTimeout(() => abortController.abort(), timeout);
  }
  return fetch(...args, {
    headers: {
      'Content-Type': 'application/json',
      JWT_TOKEN: localStorage.getItem('token')
    },
    signal: abortController.signal
  }).then((res) => {
      return res.text();
    }
  ).then(txt => {
      return JSON.parse(txt, reviver);
    }
  ).catch((error) => {
    console.error(error);
    if (retries > 0) {
      return fetcher({ abortController, retries: retries - 1, timeout }, ...args);
    } else {
      return [{...args, error, errorDate: Date.now()}]
    }
  }).finally(() => {
    if (timeout) {
      clearTimeout(id);
    }
  });
};

const TrainSWRContainer = ({ abortController, children }) => {
  return <SWRConfig
    value={{
      revalidateOnFocus: false,
      fetcher: (...args) => {
        return fetcher({ abortController }, ...args);
      }
    }}>{children}</SWRConfig>;
};
export default TrainSWRContainer;