import { compact, strPathOr, toArrayIfNot } from '@rescapes/ramda';
import {
  all,
  complement,
  equals,
  filter,
  includes,
  indexBy,
  keys,
  length,
  lensPath,
  lensProp,
  map,
  mergeRight,
  mergeWith,
  omit,
  over,
  prop,
  propEq,
  propOr,
  set,
  unless
} from 'ramda';

import { useNotLoadingEffect, useNotLoadingMemo } from 'utils/hooks/useMemoHooks.js';
import { updateTrainRunOfUserTrainRunIntervalsPreMerge } from 'appUtils/trainAppUtils/userTrainRunIntervalUtil.js';
import { userTrainRunDistanceRangeAndIntervalDifference } from 'appUtils/trainAppUtils/trainRunUtils.js';
import { hashDistanceRange, removeSubsetRanges } from 'utils/distance/distanceUtils.js';
import { convertDistanceRangeFromAggregateToTrainRoute } from 'appUtils/trainAppUtils/trainRouteUtils.js';

/**
 * Uses minimum TrainRuns with imuPoints downloaded to minimumTrainRunsWithImuPoints to updated
 * The UserTrainRunIntervals
 * @param {Boolean} Do nothing if true
 * @param crudUserTrainRunIntervals
 * @param minimumTrainRunsWithImuPointsForTrainRouteOrGroup
 */
export const useSetTrainRunsCompletedWithImuPoints = (
  {
    loading,
    crudUserTrainRunIntervals,
    minimumTrainRunsWithImuPointsForTrainRouteOrGroup
  }) => {

  useNotLoadingEffect(loading, () => {
    // If there are no loaded trainRuns in minimumTrainRunsWithImuPoints it indicates that all the current crudUserTrainRunIntervals
    // distanceRanges are loaded, there is nothing to do
    const areUserTrainRunIntervalsSynced = !length(minimumTrainRunsWithImuPointsForTrainRouteOrGroup) || all(
      userTrainRunInterval => {
        const trainIdToMinimumTrainRunsWithImuPoints = indexBy(
          prop('id'),
          minimumTrainRunsWithImuPointsForTrainRouteOrGroup
        );
        const trainRunId = userTrainRunInterval.trainRunInterval.trainRun.id;
        const minimumTrainRun = propOr(null, trainRunId, trainIdToMinimumTrainRunsWithImuPoints);
        if (!minimumTrainRun) {
          // Nothing hass just loaded for this trainRunId
          return true;
        }
        const distanceIntervalsAndRanges = strPathOr([], 'distanceIntervalsAndRanges', minimumTrainRun);
        // If the downloaded TrainRun has an error and it doesn't match a stored error date
        // we need to update this userTrainRunInterval
        if (minimumTrainRun.error) {
          return equals(
            minimumTrainRun.errorDate,
            propOr(null, 'errorDate', userTrainRunInterval.trainRunInterval.trainRun)
          );
        } else {
          // Else test if the distanceIntervalsAndRanges have changed
          return equals(
            propOr([], 'distanceIntervalsAndRanges', userTrainRunInterval.trainRunInterval.trainRun),
            distanceIntervalsAndRanges
          );
        }
      },
      crudUserTrainRunIntervals.list
    );
    if (areUserTrainRunIntervalsSynced) {
      return;
    }
    // Set the corresponding UserTrainRunInterval with the trainRun containing new ImuPoints.
    // updateOrCreateAll then deepMerges these values with existing value
    const userTrainRunIntervalsToMerge = updateTrainRunOfUserTrainRunIntervalsPreMerge({
      crudUserTrainRunIntervals,
      // Clear the retry and loading flag
      // Loading is set whenever loading is needed
      // Retry is set by the user to retry the request
      trainRuns: map(
        mergeRight({ loading: false, retry: false }),
        minimumTrainRunsWithImuPointsForTrainRouteOrGroup
      )
    });

    // Update those that have changed
    crudUserTrainRunIntervals.updateOrCreateAll(userTrainRunIntervalsToMerge);
  }, [minimumTrainRunsWithImuPointsForTrainRouteOrGroup, crudUserTrainRunIntervals]);
};

/**
 * If trainRun.retries > trainRun.retryIndex, clear distanceRanges that errored for the given distanceInterval
 * from trainRunIdsRequestedWithImuPoints so we can request. Errors are marked with the distanceInterval
 * object's distanceRangeErrors property , which is nulled in the return value
 * @param trainRun
 * @param distanceInterval
 * @param trainRunIdsRequestedWithImuPoints
 * @returns {*}
 */
const resetTrainRunIdsRequestedWithImuPointsThatErrored = (
  {
    trainRun,
    trainRunIdsRequestedWithImuPoints
  }) => {
  // If there is no error downloading this trainRun or no request to retry, return
  if (!trainRun.error || !trainRun.retry) {
    return trainRunIdsRequestedWithImuPoints;
  }
  // Look for distanceRangeErrors and clear them to force a retry
  return over(
    lensProp(trainRun.id),
    distanceIntervalObjs => map(
      distanceIntervalObj => {
        const distanceRangeHashes = keys(distanceIntervalObj.distanceRangeErrors);
        // Remove distanceRanges marked as errored in distanceRangeErrors and clear distanceRangeErrors
        return mergeRight(distanceIntervalObj,
          {
            distanceRanges: compact(map(
              distanceRange => {
                // If we have an exact match, remove this distanceRange by returning null
                if (includes(hashDistanceRange(distanceRange), distanceRangeHashes)) {
                  return null;
                }
                else if (length(distanceIntervalObj.distanceRangeErrors)) {
                  // If any distanceIntervalObj.distanceRangeErrors that overlap distanceRange, take the
                  // non-intersecting ranges of distanceRange
                  return removeSubsetRanges(distanceRange, distanceIntervalObj.distanceRangeErrors)
                }
                return {
                  distanceRange
                }
              },
              distanceIntervalObj.distanceRanges || []
            )),
            distanceRangeErrors: null
          }
        );
      },
      distanceIntervalObjs
    ),
    trainRunIdsRequestedWithImuPoints
  );
};

/**
 * Calculates what TrainRun data needs to load and returns it
 * in updatedTrainRunIdsRequestedWithImuPoints and trainRunDataToLoad
 * @param loading
 * @param trainRouteOrGroup The current TrainRoute TrainRouteGroup This the TrainRoute or TrainRouteGroup
 * of the trainRouteAggregateInterval, so we need convert trainRouteAggregateInterval.distanceRange
 * to the UserTrainRunInterval's TrainRoute if it is different than trainRouteOrGroup
 * @param trainRouteAggregateInterval
 * @param activeUserTrainRunIntervals
 * @param trainRunIdsRequestedWithImuPoints
 * @param setTrainRunIdsRequestedWithImuPoints
 */
export const useMemoCalculateTrainRunDataToLoad = (
  {
    loading,
    trainRouteOrGroup,
    trainRouteAggregateInterval,
    activeUserTrainRunIntervals,
    trainRunIdsRequestedWithImuPoints
  }) => {
  return useNotLoadingMemo(loading, () => {
    // Returns only the TrainRun data that needs to load, if any
    const trainRunDataToLoad = calculateTrainRunDataToLoad(
      {
        trainRouteAggregateInterval,
        trainRouteOrGroup,
        trainRunIdsRequestedWithImuPoints,
        activeUserTrainRunIntervals
      }
    );

    if (length(trainRunDataToLoad)) {
      // Store the data by trainRunId, to merge existing with new, chain the values of existing
      // and concat with trainRunDataToLoad, the groupBy id
      const trainRunIdToTrainRunDataToLoad = mergeWith(
        (existingForTrainRun, newForTrainRun) => {
          // For matching trainRunIds combine the newly queried distancesRanges with the old for each distanceInterval
          const {
            trainRunId,
            distanceInterval,
            consolidatedDistanceRanges
          } = newForTrainRun;
          return [
            // Remove the matching distanceInterval from existing
            ...filter(
              complement(propEq('distanceInterval', distanceInterval)),
              existingForTrainRun
            ),
            // Use consolidatedDistanceRanges, the combined values data already loaded and the new data loaded
            // for this distanceInterval
            { trainRunId: trainRunId, distanceInterval, distanceRanges: consolidatedDistanceRanges }
          ];
        },
        trainRunIdsRequestedWithImuPoints,
        indexBy(prop('trainRunId'), trainRunDataToLoad)
      );

      // Force new trainRunId entries that didn't merge with existing to be arrays
      const updatedTrainRunIdsRequestedWithImuPoints = map(trainRunDataToLoad => {
        return toArrayIfNot(unless(
          Array.isArray,
          omit(['consolidatedDistanceRanges']),
          trainRunDataToLoad
        ));
      }, trainRunIdToTrainRunDataToLoad);

      // If something actually changed, call setTrainRunIdsRequestedWithImuPoints, which triggers the effect again
      if (!equals(trainRunIdsRequestedWithImuPoints, updatedTrainRunIdsRequestedWithImuPoints)) {
        return { updatedTrainRunIdsRequestedWithImuPoints, trainRunDataToLoad };
      } else {
        return { trainRunDataToLoad };
      }
    }

  }, [trainRunIdsRequestedWithImuPoints, activeUserTrainRunIntervals, trainRouteAggregateInterval]);
};


/**
 * Given the trainRouteAggregateInterval.distanceRange, calculates if any ImuPoint date is missing for
 * any of the activeUserTrainRunIntervals for the distanceInterval (100m, 50m, etc) used for the
 * length of the distanceRange.
 * @param trainRouteAggregateInterval
 * @param trainRouteOrGroup The current TrainRoute or TrainRouteGroup. If the latter, we need ot convert
 * the trainRouteAggregateInterval.distanceRange to the range of each UserTrainRunInterval's TrainRoute,
 * which might be a subset of the TrainRoute Group distance
 * @param {[Object]} trainRunIdsRequestedWithImuPoints A report of what ImuPoints for each TrainRun
 * have already been requested at each distanceInterval. This is compared to what is in the activeUserTrainRunIntervals
 *
 * @param activeUserTrainRunIntervals
 * @returns {[Object]} Returns only the TrainRuns that have data to load along with what needs to be loaded
 */
export const calculateTrainRunDataToLoad = (
  {
    trainRouteAggregateInterval,
    trainRouteOrGroup,
    trainRunIdsRequestedWithImuPoints,
    activeUserTrainRunIntervals
  }) => {
  // Load the imuPoints for activeUserTrainRunIntervals that lack them and aren't already loading
  return compact(
    map(
      userTrainRunInterval => {
        const trainRun = userTrainRunInterval.trainRunInterval.trainRun;
        const trainRunId = trainRun.id;

        // Clear errored distanceRange requests to retry to the requests if the use has opted to retry
        // and thus increased trainRun.retries beyond trainRun.retryIndex
        const maybeUpdatedTrainRunIdsRequestedWithImuPoints = resetTrainRunIdsRequestedWithImuPointsThatErrored({
          trainRun,
          trainRunIdsRequestedWithImuPoints
        });
        // If the TrainRun has a loading error, and the user isn't retrying, return nul to omit this
        // from trainRunDataToLoading
        if (propOr(false, 'error', trainRun) && !propOr(false, 'retry', trainRun)) {
          return null;
        }

        const convertedDistanceRange = convertDistanceRangeFromAggregateToTrainRoute(
          {
            aggregateTrainRouteOrGroup: trainRouteOrGroup,
            trainRoute: userTrainRunInterval.trainRunInterval.trainRun.trainRoute
          },
          trainRouteAggregateInterval.distanceRange
        );
        const trainRunIntervalWithMappedDistanceRange = set(
          lensPath(['trainRunInterval', 'distanceRange']),
          convertedDistanceRange,
          userTrainRunInterval
        );
        // Find out what distanceRanges we need to request for the distanceInterval
        const {
          distanceInterval,
          distanceRanges,
          consolidatedDistanceRanges
        } = userTrainRunDistanceRangeAndIntervalDifference(
          propOr({}, trainRunId, maybeUpdatedTrainRunIdsRequestedWithImuPoints),
          trainRunIntervalWithMappedDistanceRange
        );
        // Query only if we have resolved at least one distanceRanges that we need to download
        return length(distanceRanges) ?
          {
            trainRunId, distanceInterval, distanceRanges, consolidatedDistanceRanges,
            // This is just for error handling
            trainRun: userTrainRunInterval.trainRunInterval.trainRun,
            trainRoute: userTrainRunInterval.trainRunInterval.trainRun.trainRoute
          } :
          // Nothing needs to be downloaded for this TrainRun
          null;
      },
      activeUserTrainRunIntervals
    ));
};