import { Box, Stack, Typography, Tooltip } from '@mui/material';
import { FLYTOGET_GRAY } from 'appConfigs/trainConfigs/trainConfig.js';
import { toArrayIfNot } from '@rescapes/ramda';
import { useTranslation } from 'react-i18next';
import { RailwayAlertOutlined } from '@mui/icons-material';
import { and, propEq } from 'ramda';

/**
 * Description of the TrainRunService
 * @param {Boolean} showTitle Default true
 * @param {Object} serviceLine The ServiceLine
 * @param {Object} serviceLine.name The ServiceLine name to display
 * @param {Object} [source] Optional for the context of a TrainRun to show if the train data came from
 * sensor data directly and not the NeTEx schedule
 * @param {[Object]|Object} sx Optional Style Object
 * @returns {JSX.Element}
 * @constructor
 */
export const TrainRouteOrGroupServiceDescription = (
  {
    showTitle = true,
    serviceLine,
    source = null,
    isBaseline = false,
    sx = {},
    sxBox = {
      // TODO configure
      bgcolor: FLYTOGET_GRAY
    }
  }) => {
  const { t } = useTranslation();
  return <Stack {...{
    direction: 'row',
    spacing: 1,
    sx: [{ maxHeight: '20px' }, ...toArrayIfNot(sx)]
  }}>
    {showTitle && <Box sx={{ width: '40px' }}>
      <Typography key='serviceTitle' variant='caption' sx={{ color: 'grey.400' }}>
        {t('service')}
      </Typography>
    </Box>}
    <Box sx={[{
      px: 0.5, color: 'secondary.main'
    }, ...toArrayIfNot(sxBox)]}>
      <Typography key='serviceValue' variant='body2'>
        {serviceLine.name}
      </Typography>
    </Box>
    {
      // If the source is based on sensor data and not the schedule,
      // and this is not a baseline run, show an alert
      and(!isBaseline, propEq('name', 'Sensor Data', source)) && <Box>
      <Tooltip arrow title={t('unscheduledTrainRun')}>
        <RailwayAlertOutlined {...{ variant: 'tiny' }} />
      </Tooltip>
    </Box>}
  </Stack>;
};