import TrainRunIntervalBarMoverDragLayer
  from 'components/apps/trainAppComponents/trainRunChooserComponents/TrainRunInveralBarMoverDragLayer.js';
import TrainRunIntervalBarExpanderDragLayer
  from 'components/apps/trainAppComponents/trainRunChooserComponents/TrainRunInveralBarExpanderDragLayer.js';

/**
 * Shows draggable train run interval bars
 * @param trainProps
 * @param componentProps
 * @param {Function} componentProps.resolveOffsetLeft A function that resolves the offset of each bar on the line given
 * the distance property of the bar
 * @param {[Object]} componentProps.draggableIntervalBars Just one draggable bar representing
 * a TrainRunInterval or UserTrainRunInterval
 * @returns {JSX.Element}
 * @constructor
 */
const DraggableTrainRunIntervalBars = (
  {
    trainProps,
    componentProps: {
      isTrainRouteLine,
      resolveOffsetLeft,
      draggableIntervalBars,
      spaceGeospatially,
      limitedDistanceRange,
      parentWidth
    }
  }) => {
  const trainRun = trainProps.trainRunProps.trainRun;
  const userTrainRunIntervals = trainProps.userTrainRunIntervalProps.userTrainRunIntervals;

  return <>
    {/* These top three components are custom drag layers
    that render the user is moving a bar or expanding left or right.
     The need to always have a higher zIndex then the components below */}
    <TrainRunIntervalBarExpanderDragLayer
      key='left'
      side='left'
      isTrainRouteLine={isTrainRouteLine}
      spaceGeospatially={spaceGeospatially}
      limitedDistanceRange={limitedDistanceRange}
      trainRun={trainRun}
      isUserScope={!!userTrainRunIntervals}
      parentWidth={parentWidth}
      resolveOffsetLeft={resolveOffsetLeft}
    />
    <TrainRunIntervalBarExpanderDragLayer
      key='right'
      side='right'
      isTrainRouteLine={isTrainRouteLine}
      spaceGeospatially={spaceGeospatially}
      limitedDistanceRange={limitedDistanceRange}
      trainRun={trainRun}
      isUserScope={!!userTrainRunIntervals}
      parentWidth={parentWidth}
      resolveOffsetLeft={resolveOffsetLeft}
    />
    <TrainRunIntervalBarMoverDragLayer
      key='mover'
      isTrainRouteLine={isTrainRouteLine}
      spaceGeospatially={spaceGeospatially}
      limitedDistanceRange={limitedDistanceRange}
      trainRun={trainRun}
      isUserScope={!!userTrainRunIntervals}
      parentWidth={parentWidth}
      resolveOffsetLeft={resolveOffsetLeft}
    />
    {
      draggableIntervalBars
    }
  </>;
};
export default DraggableTrainRunIntervalBars;