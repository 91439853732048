import { useNotLoadingEffect } from 'utils/hooks/useMemoHooks.js';
import { mergeTrainRunFilters } from 'appUtils/trainAppUtils/trainFilterUtils.js';

/**
 * Updates/Creates a DateRecurrenceFilter, which is like a
 * TrainRunFilter but can be used to set the date reange of multiple TrainRuns at the same time
 * The parentTrainRunFilter comes from a parent component and does not include a date range,
 * so adding dateRangeFilter to it always results in a new TrainRunFilter with just on date range
 * @param loading
 * @param parentTrainRunFilter
 * @param dateRange The dateRange to create a filter for
 * @param setTrainRunFilterWithDateRecurrencesDateRecurrenceFilter Setter
 */
export const useUpdateOrCreateDateRecurrenceFilter = (
  {
    loading,
    dateRange,
    parentTrainRunFilter,
    trainRunFilterWithDateRecurrences,
    setTrainRunFilterWithDateRecurrences
  }) => {
  useNotLoadingEffect(loading, () => {
    // Just copy parentTrainRunFilter initially
    setTrainRunFilterWithDateRecurrences(
      parentTrainRunFilter
    );
  }, [dateRange]);

  // Whenever the parentTrainRunFilter updates, update this TrainRunFilter
  useNotLoadingEffect(loading || !trainRunFilterWithDateRecurrences, () => {
    const updatedTrainRunFilter = mergeTrainRunFilters({childFilterTypeName:'DateRecurrenceFilter'}, parentTrainRunFilter, trainRunFilterWithDateRecurrences)
    setTrainRunFilterWithDateRecurrences(
      updatedTrainRunFilter
    );
  }, [parentTrainRunFilter])
};