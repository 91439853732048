import CemitLogo from '/src/assets/icons/cemit_logo_responsive.svg';
import { LoadingWrapper, Logo } from './styles.js';

const Loading = ({error}) => (
  <LoadingWrapper>
    <Logo src={CemitLogo} />
    <p>{error ? error : 'Loading'}</p>
  </LoadingWrapper>
);

export default Loading;
