import {useMemo, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {resolveTabFromLocation} from 'appUtils/trainAppUtils/trainUtils.js';
import {useLocalStorage} from 'utils/hooks/useLocalStorage.js';
import {useWindowDimensions} from 'utils/hooks/domHooks.js';
import ClientDependency from 'async/cemitAppAsync/dependencies/ClientDependency.js';
import {accelerationConfig} from 'appConfigs/trainConfigs/dataConfig.js';
import {always} from 'ramda';
import {useTranslation} from "react-i18next";

/**
 * Top-level app configuration.
 * This could load top-level non-client specific information about the server environment of global
 * data such as all the railroads that Cemit knows about
 * @param {Object} i18n Language translation support
 * @param {Function} children child components to create, passing the existing props and loaded data
 * @return {*}
 * @constructor
 */
const AppDependency = ({clientChildren}) => {
    const location = useLocation();

    const {t} = useTranslation()
    const [layoutState, setLayoutState] = useState({
        backButton: false,
        callback: () => {
        },
        showSidebarOnSmallScreen: false,
        showMapOnSmallScreen: false,
        locationUrl: null
    });

    const displayProps = {windowSize: useWindowDimensions()};

    // The current page of the app
    const currentAppPage = resolveTabFromLocation(location);

    // The template TrainRunFilter, extended by other Dependencies
    const appTranRunFilter = useMemo(
        always({allPass: []}),
        []
    );

    const [collapsed, setCollapsed] = useLocalStorage(
        {}, 'trainPanelCollapsed', false
    );
    const [expanded, setExpanded] = useLocalStorage(
        {}, 'trainPanelExpanded', false
    );

    // TrainFilterDrawer
    const [trainFilterOpen, setTrainFilterOpen] = useState(false);

    // The feature currently hovered over on the TrainMap so Charts can position there
    // The obj is keyed by userTrainRunInterval.sourceK/ey or by the trainRoute.id
    const [hoverFeature, setHoverFeature] = useState({});

    // The acceleration type toggle on Running Characteristics
    const [chosenAccelerationKey, setChosenAcceleration] = useLocalStorage(
        {}, 'accelerationType', 'xyz'
    );
    const chosenAcceleration = accelerationConfig[chosenAccelerationKey];

    return <ClientDependency {...{
        appProps: {
            // For now the appProps are never in a loading state
            loading: false,
            distanceUnit: 'km',
            displayProps,
            currentAppPage,
            collapsed, setCollapsed, expanded, setExpanded,
            chosenAcceleration, chosenAccelerationKey, setChosenAcceleration,
            layoutState, setLayoutState,
            hoverFeature, setHoverFeature,
            trainFilterOpen, setTrainFilterOpen,
            appTranRunFilter,
            t
        }
    }}>{clientChildren}</ClientDependency>;
};
AppDependency.displayName = 'AppDependency';
export default AppDependency;