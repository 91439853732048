import { Stack } from '@mui/material';
import { Logo } from 'components/loading/styles.js';
import CemitLogo from 'assets/icons/cemit_logo_responsive.svg';
import CemitAppNavigationItemsContainer from 'components/apps/cemitAppComponents/CemitAppNavigationItemsContainer.js';
import { CemitAppProfileItem } from 'components/apps/cemitAppComponents/CemitAppProfileItem.js';
import { colors } from 'theme/colors.ts';

const CemitAppNavigation = ({currentPath, logoTitle}) => {
  return <Stack id='navigation' direction='column' {...{
    sx: {
      justifyContent: 'space-between',
      maxWidth: '70px',
      width: '70px',
      left: 0,
      background: colors.darkBlue,
      zIndex: 3,
      py: '15px'
    }
  }}>
    <Stack direction='column' {...{
      sx: {
        justifyContent: 'center',
        alignItems: 'center',
        borderLeft: '5px transparent solid',
        px: '15px'
      }
    }} >
      <Logo src={CemitLogo} title={logoTitle} />
    </Stack>
    <Stack direction='column' {...{
      sx: {
        width: '100%',
        overflowX: 'hidden',
        alignItems: 'center',
        justifyContent: 'space-between',
        position: 'relative'
      }
    }} >
      <CemitAppNavigationItemsContainer
        currentPath={currentPath}
      />
      <CemitAppProfileItem
        currentPath={currentPath}
      />
    </Stack>
  </Stack>;
};

export default CemitAppNavigation;