import {compact, strPathOr, toArrayIfNot} from '@rescapes/ramda';
import {Grid} from '@mui/material';
import {equals, is, join, map, when} from 'ramda';
import CaptionTypography from 'components/atoms/typography/CaptionTypography.js';

export const angleToCardinalDirection = angle => {
  const directions = ['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW'];
  return directions[Math.round(((angle + 360) % 360) / 45) % 8];
};

export const createPropertyPathGridItems = ({ propertyOrDataPathConfigs, dataKey, data }) => {
  return propertyOrDataPathConfigs ?
    map(
      propertyConfig => {
        // This can be null
        const chosenDataPath = equals(dataKey, propertyConfig.dataPath);
        const labelYAxis = propertyConfig.labelYAxis;
        const values = compact(map(dataPath => {
          const value = strPathOr(undefined, dataPath, data);
          return when(is(Number), value => value.toFixed(propertyConfig.toFixedValue || 1))(value);
        }, toArrayIfNot(propertyConfig.dataPath)));
        return <Grid key={labelYAxis} container spacing={1}>
          <Grid item>
            <CaptionTypography sx={{ fontWeight: chosenDataPath ? 'bold' : 'normal' }}>{
              // Label can be static or takes values as input}
              when(is(Function), func => {
                return func(values);
              })(propertyConfig.label)
            }</CaptionTypography>
          </Grid>
          <Grid item>
            <CaptionTypography>{
              // Combine the values with the type label (if available)
              join(' ', compact([
                  join(', ',
                    // dataPath can be scalar or an array. If array join with ','
                    values
                  ),
                  labelYAxis]
                )
              )
            }</CaptionTypography>
          </Grid>
        </Grid>;
      },
      propertyOrDataPathConfigs
    ) : [];
};