import { ascend, descend, length, prop, propOr, sortWith } from 'ramda';
import { useTranslation } from 'react-i18next';
import {
  getReverseTrainRouteOrGroup,
  trainRouteOrGroupDestinationName,
  trainRouteOrGroupOriginName
} from 'appUtils/trainAppUtils/trainRouteUtils.js';
import TrainRouteChooser from 'components/apps/trainAppComponents/trainRouteChooserComponents/TrainRouteChooser.js';
import { strPathOr } from '@rescapes/ramda';

/**
 * Shows the start and end of the current Train route and an arrow for direction.
 * The start and ends are drop downs for selecting other TrainRoutes or TrainRouteGroups.
 * The arrow can be clicked to change the TrainRoute to its corresponding reverse TrainRoute
 * @param {Object} trainProps
 * @param {Object} trainProps.trainRouteProps
 * @param {Object} trainProps.trainRouteProps.trainRoute
 * @param {Object} componentProps
 * @param {Function} componentProps.reverseTrainRouteOrGroup Function to reverse the route when the arrow is clicked
 * @param {Function} componentProps.chooseTrainRouteOrGroup Function to bring up to choose the route
 * @returns {JSX.Element}
 */
const TrainRouteChooserContainer = (
  { trainProps, componentProps: { reverseTrainRouteOrGroup, chooseTrainRouteOrGroup } }
) => {
  const { t } = useTranslation();
  const reverseDisabled = !getReverseTrainRouteOrGroup(
    trainProps.trainRouteProps.trainRoutesOrGroups,
    trainProps.trainRouteProps.trainRoute
  );
  // The current TrainRoute
  const trainRouteOrGroup = trainProps.trainRouteProps.trainRoute;
  // All TrainRouteGroups or TrainRoutes. The current one will be disabled
  const trainRouteOrGroups = trainProps.trainRouteProps.trainRoutesOrGroups;

  const trainRoutesSorted = sortWith([
      // Order by number of trainRoutes in the TrainRouteGroup if it is not a TrainRoute
      descend(trainRouteOrGroup => length(propOr([], 'trainRoutes', trainRouteOrGroup))),
      // Next order by Service Line label
      ascend(trainRouteOrGroup => strPathOr('', 'serviceLine.name', trainRouteOrGroup)),
      descend(trainRouteOrGroup => prop('routeDistance', trainRouteOrGroup)),
      ascend(trainRouteOrGroup => trainRouteOrGroupOriginName({ t }, trainRouteOrGroup))
    ],
    trainRouteOrGroups
  );
  const originName = trainRouteOrGroupOriginName({ t }, trainRouteOrGroup);
  const destinationName = trainRouteOrGroupDestinationName({ t }, trainRouteOrGroup);

  return <TrainRouteChooser {...{
    trainProps: {
      trainRouteProps: {
        trainRouteOrGroup
      }
    },
    componentProps: {
      trainRoutesSorted,
      reverseDisabled,
      originName,
      destinationName,
      reverseTrainRouteOrGroup,
      chooseTrainRouteOrGroup
    }
  }} />;
};

export default TrainRouteChooserContainer;