import * as XLSX from 'xlsx';
import React, {useMemo, useState} from 'react';
import RideComfortExportExcelButton from './RideComfortExportExcelButton.tsx';
import {TrainDependencyProps} from "../../../../../types/propTypes/trainDependencyProps.ts";
import {setRideComfortReportWithQueryData} from "../../../../../async/rideComfortAsync/rideComfortHooks.ts";
import {queryRideComfortReportAndMutate} from 'async/rideComfortAsync/rideComfortReportQueries.ts';
import {find, includes, join, map, split} from "ramda";
import {formationCollectionDevices} from "../../../../../appUtils/trainAppUtils/trainUtils.js";
import { RideComfortScopeProps } from 'types/rideComfort/rideComfortScopeProps';

const RideComfortExportExcelButtonContainer = ({trainProps}: TrainDependencyProps) => {
  const trainInfo = trainProps.rideComfortProps.trainInfo
  const handleDownloadClick = () => {

    // This doesn the download
    queryRideComfortReportAndMutate(
      {
        trainInfo,
        dateInterval: {end: trainProps.rideComfortProps.chosenDateTime},
        trainFormation: {name: trainInfo.trainId},
        rideComfortConfigProps: {intervalDescription: trainProps.rideComfortProps.intervalDescription}
      },
    )
  };

  return (
    <RideComfortExportExcelButton
      {...{
        handleDownloadClick,
      }}
    />
  );
};

RideComfortExportExcelButtonContainer.displayName =
  'RideComfortExportExcelButtonContainer';
export default RideComfortExportExcelButtonContainer;
