import { visibleScheduledStopPointsOfTrainRouteOrRunInterval } from 'appUtils/trainAppUtils/trainRunLineUtils.js';
import { includes, lensProp, map, mergeRight, set, uniq } from 'ramda';
import { useNotLoadingMemo } from 'utils/hooks/useMemoHooks.js';

/**
 * Calculates the visible TimetabledPassingTimes based on the config
 *  If onlyStopsNearInterval, calculate which TimetabledPassingTime instances are visible
 * based on the routeDistance of each timetabledPassingTime and the trainRouteOrRunInterval.
 * We also need to include the first and last TimetabledPassingTimes for when the user drags the bar beyond
 * the start and end unless includeEndStops is false
 * @param {Boolean} loading Return undefined if true
 * @param client
 * @param trainRoute
 * @param trainRouteOrRunInterval
 * @param {Object} config
 * @param {Boolean} config.spaceGeospatially If true, space based on geospatially distances instaed of even
 * spacing between stops
 * @param {Boolean} config.onlyStopsNearInterval If true, limit to the stops near the TrainRunInterval
 * @param {Boolean} config.limitedDistanceRange TODO not sure if this is needed here, it's based on the
 * min distance range of all active UserTrainRunIntervals. I had it retriggering this function when it
 * changes, but I'm not sure it that's needed
 * @returns {*}
 */
export const useMemoVisibleScheduledStopPoints = (
  {
    loading,
    trainProps,
    trainRoute,
    trainRouteOrRunInterval,
    config: {
      spaceGeospatially,
      onlyStopsNearInterval,
      limitedDistanceRange,
      scheduledStopPointsAndMaybeTimes
    }
  }) => {
  return useNotLoadingMemo(loading, () => {
      const visibleScheduledStopPointsAndMaybeTimes = ((onlyStopsNearInterval || limitedDistanceRange) && trainRouteOrRunInterval) ?
        visibleScheduledStopPointsOfTrainRouteOrRunInterval({
            buffer: 0,
            // Currently we hide intermediate stops if onlyStopsNearInterval is true
            removeIntermediate: !!onlyStopsNearInterval,
            includeEndStops: !spaceGeospatially,
            // Only needed for the spaceGeospatially case to calculate the end points of the interval along the route line
            trainRoute,
            railwayLines: trainProps.railwayLineProps.railwayLines
          },
          scheduledStopPointsAndMaybeTimes,
          // Set the limited distanceRange if defined. This will already be set on userTrainRunIntervals
          // but not on trainRouteInterval. The latter always maintains its full range and the limitedDistanceRange
          // is always the combined range of the active UserTrainRunIntervals
          limitedDistanceRange ?
            set(lensProp('distanceRange'), limitedDistanceRange, trainRouteOrRunInterval) :
            trainRouteOrRunInterval
        ) :
        scheduledStopPointsAndMaybeTimes;

      // Give the railways' referenceScheduledStopPoints higher priority.
      const railwayLines = trainProps.railwayLineProps.railwayLines;
      const referenceScheduledStopPointIds = uniq(map(railwayLine => railwayLine.referenceScheduledStopPoint.id, railwayLines));
      return map(
        scheduledStopPoint => {
          return mergeRight(
            scheduledStopPoint,
            { priority: includes(scheduledStopPoint.id, referenceScheduledStopPointIds) ? 2 : 1 }
          );
        },
        visibleScheduledStopPointsAndMaybeTimes
      );
    },
    [spaceGeospatially, onlyStopsNearInterval, limitedDistanceRange, trainRouteOrRunInterval]
  );
};
