import {add, length, lensPath, over, reduce, split, toPairs} from 'ramda'
import {
    RideComfortAlertCount,
    RideComfortAlertLevelDatum,
    RideComfortGraphqlResponseAlertData
} from "../../types/rideComfort/rideComfortMapData";
import {GetTotalCount} from "../../types/rideComfort/rideComfortFunctions";
import {RideComfortPeriodMapData} from "../../types/rideComfort/rideComfortPeriodMapData";
import {
    levelToAttribute,
    PeriodEnum,
    periodLabelToAttribute,
    RideComfortAttributeAlertLevel
} from "../../types/rideComfort/rideComfortAlertLevel.ts";
import {RideComfortLevels} from "../../types/rideComfort/rideComfortAlertLevels";
import {RideComfortPeriods} from "../../types/rideComfort/rideComfortPeriods";


/**
 * TODO remove mutation and return value
 * @param data
 * @param getTotalCount
 * @param today
 * @param week
 * @param month
 */
export const totalMapDataValues = (
    data: RideComfortGraphqlResponseAlertData,
    getTotalCount: GetTotalCount,
): RideComfortPeriodMapData => {
    const templatePeriod: RideComfortAlertLevelDatum = {normal: 0, warning: 0, critical: 0}
    // Each element of the reduction is a pair from the data Record
    // the first of the pair is a label in the form L(1|2|3)_(Day|Week|Month)
    // and the second of the pair is an array of values to total
    return reduce(
        (accum: RideComfortPeriodMapData, element: [string, RideComfortAlertCount[]]) => {
            const [label, rideComfortAlertCounts] = length(element) ?
                element :
                [undefined, undefined];
            // If something isn't defined, skip it
            if (!rideComfortAlertCounts || !length(rideComfortAlertCounts)) {
                return accum
            }
            const [
                level,
                periodLabel
            ] = split('_', label) as [
                keyof RideComfortLevels, keyof RideComfortPeriods
            ]

            const period: PeriodEnum = periodLabelToAttribute[periodLabel]
            const alertLevel: RideComfortAttributeAlertLevel = levelToAttribute[level]

            const total = getTotalCount(rideComfortAlertCounts);
            // Set accum[period][alertLevel] += total
            return over(
                lensPath([period, alertLevel]),
                (existing: number = 0) => {
                    return add(existing, total)
                },
                accum
            )
        },
        {
            [PeriodEnum.today]: templatePeriod,
            [PeriodEnum.week]: templatePeriod,
            [PeriodEnum.month]: templatePeriod
        } as RideComfortPeriodMapData,
        toPairs(data?.data || [])
    )
};
