import { useDragLayer } from 'react-dnd';
import { both, cond, either, equals, isNil, prop, T } from 'ramda';
import { getMaybeConstrainedOffset } from 'utils/hooks/dragAndDropHooks.js';
import { ItemTypes } from 'appConfigs/trainConfigs/trainDragAndDropConfig.js';

/**
 * A custom moverDrag layer that renders the same shape as is selected but is optionally
 * constrained to the y axis
 * @param {String} handleItemType The item type that this CustomDragLayer handles. itemType from useDragLayer
 * must match this in order to render anything
 * @param {Object} trainRun
 * @param {Boolean} isUserScope if true, indicates the dragged components has a userTrainRunInterval in
 * its item. Otherwise it has a trainRunInterval
 * @param {Boolean} constrainX If true constrain to the Y axis
 * @param {Boolean} constrainY If true constrain to the X axis
 * @param {Function} render Children render function that expects the props from the
 * DragMonitor
 * @returns {null|*}
 * @constructor
 */
export const TrainRunIntervalBarCustomDragLayer = (
  {
    isTrainRouteLine,
    handleItemType,
    trainRun,
    constrainY,
    isUserScope,
    render
  }) => {
  const { itemType, isDragging, item, initialOffset, currentOffset } = useDragLayer(
    (monitor) => {
      const item = monitor.getItem();
      const isDragging = cond([
        [
          // monitor.item.trainRunInterval and the given trainRun are marked isTrainRouteLine
          // Since there is only one template TrainRunLine and one template TrainRunIntervalBar, we must have a match
          prop('isTrainRouteLine'),
          () => {
            return true;
          }
        ],
        [
          // If there is no trainRun.id, never match
          ({ id }) => isNil(id),
          () => {
            return false;
          }
        ],
        [
          // If the CustomDragLayer and the dragged object are user scoped
          both(prop('isUserScope'), prop('itemUserTrainRunInterval')),
          ({ id, itemUserTrainRunInterval }) => {
            return equals(itemUserTrainRunInterval.trainRunInterval.trainRun?.id, id);
          }
        ],
        [
          // If either (but not both) item is user scope or CustomDragLayer is user scope, never match
          either(prop('isUserScope'), prop('itemUserTrainRunInterval')),
          () => {
            return false;
          }
        ],
        [
          // Otherwise neither are user scope
          T,
          ({ id, itemTrainRunInterval }) => {
            return equals(itemTrainRunInterval?.trainRun?.id, id);
          }
        ]
      ])({
        isUserScope,
        isTrainRouteLine,
        id: trainRun?.id,
        itemTrainRunInterval: item?.trainRunInterval,
        itemUserTrainRunInterval: item?.userTrainRunInterval
      });

      return {
        item: monitor.getItem(),
        itemType: monitor.getItemType(),
        initialOffset: monitor.getInitialSourceClientOffset(),
        currentOffset: monitor.getSourceClientOffset(),
        isDragging
      };
    }
  );

  const isInitialized = initialOffset && currentOffset;
  if (!isDragging || !isInitialized || handleItemType !== itemType) {
    return null;
  }
  const visibility = !isDragging ? 'hidden' : 'visible';
  switch (itemType) {
    // Currently all types are processed the same way
    case ItemTypes.TRAIN_RUN_INTERVAL_BAR_MOVER:
    case ItemTypes.TRAIN_RUN_INTERVAL_BAR_LEFT_EXPANDER:
    case ItemTypes.TRAIN_RUN_INTERVAL_BAR_RIGHT_EXPANDER:
      // Return the dragOffset, which is the x,y offset from the object's position at the start of the drag,
      // but y is always constrained to the initial y position
      // We use this to render the custom drag layer and to place the object when the drag layer is dropped
      return render({
        sx: { visibility },
        ...isDragging ? { dragOffset: getMaybeConstrainedOffset({ constrain: constrainY }, initialOffset, currentOffset) } : {}, ...item
      });
    default:
      return null;
  }
};
