import LoaderWithText from 'components/loading/LoaderWithText.js';
import TrainFilterSettings from 'components/apps/trainAppComponents/trainFilterComponents/TrainFilterSettings.js';


/**
 * Displays the currently active TrainRunFilter, including the
 * date range(s), date recurrence(s), and formations(s). The trainRunFilterWithTrainRoute
 * is not displayed since it's presumably shown elsewhere as the selected
 * TrainRoute
 * @param appProps
 * @param organizationProps
 * @param trainProps
 * @param sx
 * @returns {JSX.Element}
 * @constructor
 */
const TrainFilterSettingsContainer = ({ appProps, organizationProps, trainProps, componentProps, sx }) => {
  const loading = trainProps.dateProps.loading

  return loading ?
    <LoaderWithText text={'loadingFilter'} loadingExplanation={trainProps.trainRunProps.loadingExplanation}/> :
    <TrainFilterSettings {...{
      appProps, organizationProps, trainProps, componentProps, sx
    }} />;
};

export default TrainFilterSettingsContainer;