import {Box, Stack, Typography} from "@mui/material";
import React from "react";
import {tempColors} from "../../../../theme/tempColors.ts";
import {RideComfortGauge} from "../../../../types/rideComfort/rideComfortGauge";


type RideComfortErrorLevelComponentProps = {
    rideComfortGauge: RideComfortGauge,
    labelVariant: string,
    level: keyof RideComfortGauge,
    label: string,
    image: string,
    iconSize: number,
    showCount: boolean
}

/**
 * Shows the number of errors for a certain RideComfortGauge attribute: normal, warning, critical
 * @param rideComfortGauge
 * @param showCount
 * @param labelVariant
 * @param level
 * @param label
 * @param image
 * @param iconSize
 * @constructor
 */
const RideComfortErrorLevelComponent = (
    {
        rideComfortGauge,
        showCount,
        labelVariant,
        level,
        label,
        image,
        iconSize
    }: RideComfortErrorLevelComponentProps
) => {

    const value = isNaN(rideComfortGauge?.[level]?.value) ? 'N/A' : rideComfortGauge[level].value.toFixed(2)
    const count = showCount && label != 'Normal' && rideComfortGauge?.[level]?.count ? rideComfortGauge[level].count : null
    return <Stack {...{
        key: label,
        direction: 'row',
        spacing: 0,
        sx: {
            width: '100%',
            minWidth: 0,
            alignItems: 'center',
            justifyContent: 'space-between'
        }
    }}
    >
        <Stack {...{
            direction: 'row',
            spacing: 1,
            sx: {
                alignItems: 'center',
                flex: 1
            }
        }
               }>
            <Box {...{
                key: 'box',
                sx: {
                    alignSelf: 'center',
                    width: iconSize,
                    height: iconSize,
                }
            }}>
                <img {...{
                    width: iconSize,
                    height: iconSize,
                    src: image
                }} />
            </Box>
            <Typography {...{
                key: 'label',
                variant: labelVariant,
                sx: {
                    color: tempColors.white,
                }
            }} >{label}</Typography>
        </Stack>
        <Box {...{
            key: 'box',
            sx: {
                flex: 2,
            }
        }}>
            <Typography {...{
                variant: labelVariant,
                align: 'right',
                key: 'rideComfortGaugeLevel',
                sx: {
                    color: tempColors.white,
                    padding: '4px 0 4px 16px',
                }
            }} >{value} {count ? `(Total: ${count})` : ''}</Typography>
        </Box>
    </Stack>

}
RideComfortErrorLevelComponent.displayName = 'RideComfortErrorLevelComponent'
export default RideComfortErrorLevelComponent
