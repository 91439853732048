/**
 * TODO This is old code that needs to be update to MUI components
 * All responsiveness should be removed and then redone from scratch
 */

export const NavOuter = () => {
  return <div {...{
    style: {
      zIndex: '2',
      width: '100%',
      height: '100%',
      right: '0'
    }
  }} />;
};

export const FlexItems = () => {
  return <div {...{
    style: {
      width: '100%',
      height: '80%',
      overflowX: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
      position: 'relative'
    }
  }} />;
};

export const NavInner = () => {
  return <div {...{
    style: {
      height: '100%',
      width: '100%',
      overflowX: 'hidden'
    }
  }} />;
};

export const LogoWrapper = () => {
  return <div {...{
    style: {
      height: '20%',
      justifyContent: 'center',
      /* margin: '40px 0px',*/
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      borderLeft: '5px transparent solid',
      p: {
        marginTop: '8px',
        color: 'white',
        fontSize: '8px'
      }
    }
  }} />;
};

export const BackBtnWrapper = () => {
  return <div {...{
    style: {
      height: '20%',
      justifyContent: 'center',
      borderLeft: '10px transparent solid',
      display: 'flex',
      flexDirection: 'column'
      /* justifyContent: 'center', */
      /* alignItems: 'center', */
    }
  }} />;
};

export const ProfileText = () => {
  return <p {...{
    style: {
      paddingBottom: '10px !important'
    }
  }}
  />;
};

export const NavBtn = ({ children }) => {
  return <span {...{
    style: {
      width: '60px',
      height: '60px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '50%',
      marginTop: '8px',
      img: {
        width: '30px'
      }
    }
  }}>
    {children}
  </span>;
};
