import {chain, filter, join, last, map, propEq} from 'ramda';
import {compact, toArrayIfNot} from '@rescapes/ramda'
import {Map, MapLayerMouseEvent, Popup} from 'mapbox-gl';
import {ChartPayloadItemMinimized} from "visualizer-railbed/railbedTypes/dataVisualizations/chartPayloadItem";
import {Setter} from "visualizer-railbed/railbedTypes/hookHelpers/setter";
import {DataThreshold} from "visualizer-railbed/railbedTypes/dataVisualizations/dataThreshold";
import {DependencyProps} from "visualizer-railbed/railbedTypes/propTypes/dependencyProps";
import {
    MapboxIconConfig,
    MapboxIconsConfig,
    MapboxLayer,
    MapSourceVisual
} from "visualizer-railbed/railbedTypes/mapbox/mapSourceVisual";
import {TrainRunGroup, TrainRunGroupWithDerived} from "visualizer-railbed/railbedTypes/trainRuns/trainRunGroup";
import {
    memoizedTrainRunGroup3dColumnSourceAndLayers
} from "visualizer-railbed/railbedUtils/map/layers/3dColumns/3dColumnLayers.ts";

import {
    removeMapboxLayersAndSources,
    setMapboxSourceAndLayersSets
} from "visualizer-railbed/railbedUtils/map/mapboxSourceUtils.ts";
import {
    RIDE_COMFORT_SCHEDULED_STOP_POINT_LAYER_PREFIX,
    RIDE_COMFORT_SCHEDULED_STOP_POINT_SOURCE_PREFIX,
    RIDE_COMFORT_TRACK_LAYER_PREFIX,
    RIDE_COMFORT_TRACK_SOURCE_PREFIX,
    RIDE_COMFORT_TRG_LAYER_PREFIX,
    RIDE_COMFORT_TRG_SOURCE_PREFIX
} from "../../visualizer-railbed/railbedConfig/trainConfigs/trainConfig.ts";
import {
    unlessLoadingProps,
    unlessLoadingValue
} from "../../visualizer-railbed/railbedUtils/componentLogic/loadingUtils.ts";
import {useNotLoadingSetterEffect} from "../../visualizer-railbed/railbedUtils/hooks/useMemoHooks.ts";
import {
    setMapboxOnHover
} from "../../visualizer-railbed/railbedAsync/cemitAppAsync/cemitAppHooks/mapHooks/trainMapHooks.ts";
import {typeObject} from "../../visualizer-railbed/railbedAppUtils/typeUtils/typenameUtils.ts";
import {CemitTypename} from "../../visualizer-railbed/railbedTypes/cemitTypename.ts";
import {RailwayLine} from "../../visualizer-railbed/railbedTypes/railways/railwayLine";
import {FeatureCollectionU, FeatureU, PointU} from "../../visualizer-railbed/railbedTypes/geometry/geojsonUnions";
import {getValidPoints} from "../../appUtils/rideComfortUtils/rideComfortDataUtils.ts";
import {CEMIT_TRAIN_RUN_LINE_DARKER, CEMIT_WHITE} from "../../theme/colors.ts";
import {TrainRouteOrGroup} from "../../visualizer-railbed/railbedTypes/trainRoutes/trainRouteOrGroup";
import {
    singlePointFeatureFromPayload
} from "../../visualizer-railbed/railbedUtils/dataFeatures/dataFeaturePayloadUtils.ts";
import {DependencyPropsWithRideComfortProps} from "../../types/rideComfort/trainPropsWithRideComfortProps";
import {TrainRunGroupGeojson} from "../../visualizer-railbed/railbedTypes/trainRuns/trainRunGroupGeojson";

import stationSvgInline from '../../assets/icons/subway.svg'

export type UseUpdate3dForRideComfortTrainMapHookProps = {
    trainMap: Map
    trainRouteOrGroup: TrainRouteOrGroup,
    set3dLayersUpdating: Setter<boolean>
    featurePropPath: string,
    dataThresholds: DataThreshold[]
    dataColumns3DLayerAreVisible: boolean
    extrude: boolean
}
/**
 * Calls trainMap3dLayers and updates the trainMap to the results
 * @param loading If true, do nothing
 * @param set3dLayersUpdating Setter set true while the effect is running
 * @param props See trainMap3dLayers
 */
export const useUpdateAlertLayerForRideComfortTrainMap = (
    {
        loading,
        appProps,
        organizationProps,
        trainProps,
        mapProps,
        hookProps
    }: DependencyPropsWithRideComfortProps & {
        loading: boolean,
        hookProps: UseUpdate3dForRideComfortTrainMapHookProps
    }) => {

    const {
        trainMap,
        set3dLayersUpdating,
        featurePropPath,
        dataThresholds,
        dataColumns3DLayerAreVisible,
        extrude
    } = unlessLoadingProps(loading, () => hookProps as UseUpdate3dForRideComfortTrainMapHookProps);

    const trainInfo = trainProps?.rideComfortProps?.trainInfo
    const {
        trackLineTransformed,
        sensorPointsTransformed,
        scheduledStopPointGeojson
    } = unlessLoadingProps(loading, () => {
        const railwayFeatureCollections: FeatureCollectionU[] = chain(
            (railwayLine: RailwayLine) => railwayLine.geojsons,
            trainProps.rideComfortRailwayLineProps.railwayLines
        )
        const trackLineTransformed: FeatureU[] = chain(
            (railwayFeatureCollection: FeatureCollectionU) => filter(
                (feature: FeatureU) => {
                    return feature.geometry.type == 'LineString'
                },
                railwayFeatureCollection.features
            ),
            railwayFeatureCollections
        )
        const scheduledStopPointGeojson: FeatureCollectionU = organizationProps.organization.stops || {
            type: 'FeatureCollection',
            features: chain(
                (railwayFeatureCollection: FeatureCollectionU) => filter(
                    (feature: FeatureU) => {
                        return feature.geometry.type == 'Point' &&
                            propEq('stop_position', 'public_transport', feature.properties)
                    },
                    railwayFeatureCollection.features
                ),
                railwayFeatureCollections
            )
        } as FeatureU

        const features: FeatureU<PointU> = getValidPoints(trainProps.rideComfortProps.heatMapData)
        const sensorPointsTransformed: FeatureCollectionU = {
            type: 'FeatureCollection',
            features: features
        }
        return {railwayFeatureCollections, trackLineTransformed, sensorPointsTransformed, scheduledStopPointGeojson}
    })

    const trainRunGroups = unlessLoadingValue(!trainInfo, () => {
        const geojson = typeObject<TrainRunGroupGeojson>(CemitTypename.trainRunGroupGeojson, {
            trackLineTransformed,
            sensorPointsTransformed,
            scheduledStopPointGeojson,
            featureCollectionPoints: undefined,
        })
        return [typeObject<TrainRunGroup>(CemitTypename.trainRunGroup, {
            ...trainInfo,
            id: trainInfo.trainId,
            sourceKey: trainInfo.trainId,
            activity: {isVisible: true},
            geojson
        })]
    }) || []


    return useNotLoadingSetterEffect({
            loading,
            inProcessSetter: set3dLayersUpdating
        },
        trainMap3dLayers,
        {
            trainRunGroups,
            featurePropPath,
            dataThresholds,
            dataColumns3DLayerAreVisible,
            extrude,
        },
        // Update the trainMap to the sources and layers
        (mapSourceVisualSetPairs: MapSourceVisual[][]) => {

            const mapSourceVisuals = chain<MapSourceVisual[], MapSourceVisual>(
                (x: MapSourceVisual[]) => x,
                mapSourceVisualSetPairs
            );
            // Set the sources and layers on TrainMap
            setMapboxSourceAndLayersSets(
                {
                    mapboxMap: trainMap,
                    mapSourceVisuals,
                    zoomToSources: true
                }
            );

            // Sets the 3D column layers to respond to a mouse over so we can tell the app what the hover feature(s) are
            /// We can't currently do this with the TrainRunGroup track layers because they are lines, not single features.
            // However if we switch them to line segments then they can detect what feature(s) is hovered over
            const extrudeSourceAndLayersSets = map(mapSourceVisualSetPair => last(mapSourceVisualSetPair), mapSourceVisualSetPairs);

            setMapboxOnHover({
                appProps,
                organizationProps,
                trainProps,
                mapProps,
                dataProps: {
                    mapSourceVisuals: extrudeSourceAndLayersSets,
                    createOnHover: rideComfortMapboxSourcesAndLayersSetsOnClick,
                    onClickOnly: true
                }
            });

            // Hack always remove unless the page is ride_comfort
            if (!dataColumns3DLayerAreVisible || appProps.currentAppPage != 'ride_comfort') {
                // If dataColumns3DLayerAreVisible is false, make sure the sources/layers are gone
                removeMapboxLayersAndSources({
                    sourcePrefix: RIDE_COMFORT_TRG_SOURCE_PREFIX,
                    layerPrefix: RIDE_COMFORT_TRG_LAYER_PREFIX,
                    mapboxMap: trainMap,
                    preserveSourceVisuals: []
                });
                removeMapboxLayersAndSources({
                    sourcePrefix: RIDE_COMFORT_TRACK_SOURCE_PREFIX,
                    layerPrefix: RIDE_COMFORT_TRACK_LAYER_PREFIX,
                    mapboxMap: trainMap,
                    preserveSourceVisuals: []
                });
            } else {
                // Remove any MAP_USER_TRAIN_RUN_LAYER_PREFIX layers from previous iterations that are no longer present
                removeMapboxLayersAndSources({
                    layerPrefix: RIDE_COMFORT_TRG_LAYER_PREFIX,
                    sourcePrefix: RIDE_COMFORT_TRG_SOURCE_PREFIX,
                    preserveSourceVisuals: mapSourceVisuals,
                    mapboxMap: trainMap
                });
            }
        },
        [
            // If this array changes, it means one of the TrainRunGroups'
            //onlyTrainRun.trainRoute.distanceRanges changed.
            trainRunGroups,
            // Update if the user toggles columns
            dataColumns3DLayerAreVisible,
            // Update if the feature props the user is looking at change
            featurePropPath,
            trainProps?.rideComfortProps?.heatMapData,
            appProps?.currentAppPage
        ]
    );
};

/**
 * @param trainRunGroups The TrainRunGroup instances that are being displayed
 * @param trainRunGroupsHaveChanged Flag to indicate that the 3d layers need to update
 * to reflect difference instances in trainRunGroups
 * @param trainRunGroupGeojsons Geojson representation of the TrainRunGroups
 * @param featurePropPath A property path in the geojson data whose value we visualize in 3d
 * @param dataThresholds Thresholds for coloring the 3d data based on value
 * @param set3dLayersUpdating Setter to indicate an update of the 3d is in process
 * @param dataColumns3DLayerAreVisible True if the user wants to see the 3D columns
 * @param trackData The TrainRoute's trackData
 */
const trainMap3dLayers = (
    {
        trainRunGroups,
        featurePropPath,
        dataThresholds,
        extrude,
    }:
        {
            trainRunGroups: TrainRunGroup[],
            featurePropPath: string,
            dataThresholds: DataThreshold[],
            dataColumns3DLayerAreVisible: boolean
            extrude: boolean
        }
) => {

    // Creates a flat list of mapSourceVisual sets, where each trainRunGroup produces
    // a track line shifted perpendicular from the real track (unless the trainRunGroup is baseline,
    // since baseline is visualized over the actual track).
    // Also optionally produces a 3d column mapSourceVisual set if dataColumns3DLayerAreVisible is true
    const mapSourceVisuals = map(
        (trainRunGroup: TrainRunGroupWithDerived) => {
            const isBaseline = trainRunGroup.activity?.isBaseline;
            // Name layers and sources by the TrainRunGroup sourceKey
            const trackSourceName = join('-', [RIDE_COMFORT_TRACK_SOURCE_PREFIX, trainRunGroup.sourceKey]);
            const trackLayerId = join('-', [RIDE_COMFORT_TRACK_LAYER_PREFIX, trainRunGroup.sourceKey]);
            const scheduledStopPointSourceName = join('-', [RIDE_COMFORT_SCHEDULED_STOP_POINT_SOURCE_PREFIX, trainRunGroup.sourceKey]);
            const scheduledStopPointLayerId = join('-', [RIDE_COMFORT_SCHEDULED_STOP_POINT_LAYER_PREFIX, trainRunGroup.sourceKey]);

            // Get the track line that is transformed perpendicular to the original track
            const trackLineTransformed = trainRunGroup.geojson.trackLineTransformed;
            const trackLineSource = {
                name: trackSourceName,
                type: 'geojson',
                data: {
                    type: 'FeatureCollection',
                    features: toArrayIfNot(trackLineTransformed)
                }
            };

            // Draw the track layer if not the baseline run. The baseline
            // is already represented by the actual track
            const trackLayer = !isBaseline ? {
                id: trackLayerId,
                type: 'line',
                source: trackSourceName,
                paint: {
                    'line-color': CEMIT_TRAIN_RUN_LINE_DARKER,
                    'line-width': 5
                },
                layout: {
                    'visibility': 'visible'
                }
            } : null;

            const trackSourceAndLayers = trackLayer ? {
                source: trackLineSource,
                layers: [trackLayer],
                trainRunGroup
            } : null;

            const scheduledStopPointGeojson = trainRunGroup.geojson.scheduledStopPointGeojson
            const scheduledStopPointSource = {
                name: scheduledStopPointSourceName,
                type: 'geojson',
                data: scheduledStopPointGeojson
            };

            // Draw the track layer if not the baseline run. The baseline
            // is already represented by the actual track
            const scheduledStopPointLayer: MapboxLayer = {
                id: scheduledStopPointLayerId,
                type: 'symbol',
                source: scheduledStopPointSourceName,
                iconConfig: {
                    width: 20,
                    height: 20,
                    iconConfigs: [{name: 'station', svg: stationSvgInline} as MapboxIconConfig]
                } as MapboxIconsConfig,
                paint: {
                    "text-color": CEMIT_WHITE
                },
                layout: {
                    'text-size': {
                        'stops': [
                            [0, 0],
                            [9, 0],
                            [10, 10],
                            [13, 14]
                        ]
                    },
                    'text-justify': 'auto',
                    "text-rotation-alignment": "auto",
                    'text-field': ['get', 'name'],
                    'icon-image': 'station',
                    'icon-anchor': 'center',
                    'icon-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        0, 0,
                        9, 0,
                        10, 1,
                        17, 1,
                    ],
                    'text-offset': [0.5, 0.5],
                    'text-variable-anchor': ['top-left']
                }
            }

            const scheduledStopPointSourceAndLayers = {
                source: scheduledStopPointSource,
                layers: [scheduledStopPointLayer],
                trainRunGroup
            };

            const sensorPointsTransformed = trainRunGroup.geojson.sensorPointsTransformed;
            const columnSourceAndLayers = memoizedTrainRunGroup3dColumnSourceAndLayers({
                trainRunGroup,
                featurePropPath,
                geojson: sensorPointsTransformed,
                dataThresholds,
                extrude,
                sourceNamePrefix: RIDE_COMFORT_TRG_SOURCE_PREFIX,
                layerNamePrefix: RIDE_COMFORT_TRG_LAYER_PREFIX
            });

            return compact([trackSourceAndLayers, scheduledStopPointSourceAndLayers, columnSourceAndLayers]);
        },
        trainRunGroups
    );
    return mapSourceVisuals;
};


/**
 * Popup to describe ride comfort
 * @param mapProps
 * @param eventProps
 * @param payload
 */
export const rideComfortMapboxSourcesAndLayersSetsOnClick = (
    {mapProps, eventProps}: DependencyProps & {
        eventProps: {
            hoveredStateId: string,
            e: MapLayerMouseEvent,
            popup: Popup
        }
    },
    payload: ChartPayloadItemMinimized[]
): void => {
    eventProps.popup.remove();

    const feature = singlePointFeatureFromPayload(payload);
    const properties = feature.properties
    if (!feature || !properties) {
        return
    }
    const description = join('<br/>', [
        `${properties.timestamp}`,
        `Level: ${properties.value.toFixed(1)}`,
        `Lat-lon: ${feature.geometry.coordinates[1].toFixed(4)}, ${feature.geometry.coordinates[0].toFixed(4)}`
    ])

    // Populate the popup and set its coordinates
    // based on the feature found.
    eventProps.popup.setLngLat(eventProps.e.lngLat).setHTML(
        `<div style="font-size: 12px;">${description}</div>`
    ).addTo(mapProps.trainMap);
};
