/**
 * Primitive function wrapper to log performance
 * @param funcName A label for the function
 * @param func The function
 * @returns {function(...[*]): *}
 */
export const performanceWrapper = (funcName, func) => (...args)  => {
  const t0 = performance.now();
  const result = func(...args)
  const t1 = performance.now();
  console.debug(`Call to ${funcName} took ${(t1 - t0)} milliseconds.`);
  return result
}