import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { TRAIN_MAP_PITCH } from 'appConfigs/trainConfigs/trainMapConfig.js';
import { useNotLoadingEffect } from 'utils/hooks/useMemoHooks.js';
import { compact } from '@rescapes/ramda';
import { forEach, map } from 'ramda';
import { MAPBOX_DIV_ID, MAPBOX_STYLE } from 'appConfigs/appConfig.js';

// TODO Move to .env!
const CEMIT_MAPBOX_API_TOKEN = 'pk.eyJ1IjoiY2VtaXQiLCJhIjoiY2toeDUzbGhqMGYwcjM3bmJyeXE5NHp0MiJ9.HVOiUZ_w0rEg1gAncylB5Q';

/**
 * Mounts a map. TODO move style config to a configuration
 * @param {Object} [bbox] Optional bounding box to pan and zoom to
 * @param {[Number]} [center] Optional center lon, lat array to pan to
 * @param {Number} [zoom] Optional zoom level
 * @returns {Object} The Mabox map instance
 */
const mountMap = ({ bbox = null, center = null, zoom = null }) => {
  mapboxgl.accessToken = CEMIT_MAPBOX_API_TOKEN;

  const mapObj = new mapboxgl.Map({
    container:  MAPBOX_DIV_ID,
    style: MAPBOX_STYLE,
    anchor: MAPBOX_DIV_ID,
    antialias: true,
    essential: true,
    pitch: TRAIN_MAP_PITCH,
    light: {
      anchor: MAPBOX_DIV_ID,
      color: 'white',
      intensity: 1
    },
    ...compact({
      center,
      zoom,
      bbox
    })
  });

  return mapObj;
};

/**
 * Mounts the mapbox map
 * @param loading
 * @param isTrainMapMounted
 * @param bbox
 * @param {Function} setTrainMap Setter to set the Mapbox map
 * @param {Function} setTrainMapLoading Setter to indicate loading
 * @param {Function} setIsTrainMapMounted Setter to indicate the map is mounted
 */
export const useTrainMap = (
  {
    loading,
    isTrainMapMounted,
    bbox,
    setTrainMap,
    setTrainMapLoading,
    setIsTrainMapMounted
  }) => {

  useNotLoadingEffect(loading, () => {
    if (!isTrainMapMounted) {
      const trainMap = mountMap({ bbox });
      setTrainMap(trainMap);

      trainMap.on('style.load', () => {
        // This has to be done because Mapbox doesn't correctly report
        // the loading of styles
        const waiting = () => {
          if (!trainMap.isStyleLoaded()) {
            setTimeout(waiting, 200);
          } else {
            setTrainMapLoading(false);
          }
        };
        waiting();
      });
      setIsTrainMapMounted(true);
    }
  }, [isTrainMapMounted]);
};

/**
 *  When the user moves their mouse over the given layers,
 *  calls onHover on the first feature
 * @param {Object} appProps
 * @param {Object} organizationProps
 * @param {Object} trainMap The TrainMap
 * @param {[Object]} sourceAndLayersSets Sets of {source, layers, userTrainRunInterval} where
 * @param {Function} onHover
 * @param {Object} t The translation property
 * @param {Boolean} onClickOnly Default false. If true, require a click to activate the onHover
 * userTrainRunInterval is only set if the layer is specific to a TrainRun
 */
export const setMapboxOnHover = (
  {
    appProps,
    organizationProps,
    trainProps,
    mapboxMap: trainMap,
    sourceAndLayersSets,
    onHover,
    onClickOnly=false,
    t
  }
) => {
  forEach(({ layers, userTrainRunInterval }) => {
      let hoveredStateId = null;
      forEach(layer => {
          const popup = new mapboxgl.Popup({
            closeButton: true,
            closeOnClick: true
          });
          const mapboxEvent = onClickOnly ? 'click' : 'mouseenter'
          if (onClickOnly) {
            trainMap.on('mouseenter', layer.id, () => {
              // Change the cursor style as a UI indicator.
              trainMap.getCanvas().style.cursor = 'pointer';
            })
          }
          // On mouseenter or onclick
          trainMap.on(mapboxEvent, layer.id, e => {
            if (!onClickOnly) {
              // Change the cursor style as a UI indicator.
              trainMap.getCanvas().style.cursor = 'pointer';
            }
            // Imitate recharts payload format
            const chartStylePayloadFromFeatures = features => {
              return map(feature => {
                return { payload: feature };
              }, features);
            };

            const payload = chartStylePayloadFromFeatures(e.features);
            if (onHover) {
              onHover({
                appProps,
                organizationProps,
                trainProps,
                trainMap,
                e,
                popup,
                userTrainRunInterval,
                hoveredStateId,
                t
              }, payload );
            }
          });

          trainMap.on('mouseleave', layer.id, () => {
            trainMap.getCanvas().style.cursor = '';
          });
        },
        layers
      );
    },
    sourceAndLayersSets
  );
};