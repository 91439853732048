import {Box, Grid} from '@mui/material';
import {pick} from 'ramda';
import {toArrayIfNot} from '@rescapes/ramda';
import LoaderWithText from 'components/loading/LoaderWithText.js';
import UserTrainRunIntervalYardActive
    from 'components/apps/trainAppComponents/trainRunChooserComponents/UserTrainRunIntervalYardActive.js';
import {unlessLoadingProps} from 'utils/componentLogic/loadingUtils.js';
import React from "react";

/**
 * The collection of UserTrainRunIntervals, meaning TrainRuns chosen by the user at for certain distance
 * intervals for comparison. The same run may appear multiple times for different intervals and different times/dates
 * Depends directly on trainProps.userTrainRunIntervalProps
 * @prop {Boolean } loading,
 * @prop {Object} sx
 * @prop {Object} trainProps
 * @prop {Object} trainRoute The current TrainRoute
 * @pram {Object} client
 * @returns {null}
 * @constructor
 */
const UserTrainRunIntervalYardContainer = (
    {
        appProps,
        organizationProps,
        trainProps,
        sx
    }) => {

    // This container can visualize as long as crudUserTrainRunIntervals.list is initialized,
    // though interaction is disabled while trainProps.userTrainRunIntervalProps.loading
    const {crudUserTrainRunIntervals, loadingExplanation} = trainProps.userTrainRunIntervalProps
    const loading = !crudUserTrainRunIntervals?.list

    const componentProps = unlessLoadingProps(loading, () => {
        const crudSelectedUserTrainRunIntervals = trainProps.userTrainRunIntervalProps.crudUserTrainRunIntervals;
        return {
            crudSelectedUserTrainRunIntervals
        };
    });

    // Render this structure with loading or ready components below
    return <Box
        key='userTrainRunIntervalYard'
        sx={[{
            bgcolor: 'background.lightBlue',
            borderRadius: '8px',
            p: '14px',
            boxShadow: '2px 1px 1px rgba(0, 0, 0, 0.15)',
            height: '100%',
            maxHeight: '100%',
            overflowY: 'hidden'
        }, ...toArrayIfNot(sx)]}
    >
        <Grid container spacing={2}
              sx={{
                  height: 'calc(100% + 16px)',
                  overflowY: 'auto'
              }}
        >
            <Grid item key='selectedRuns' xs={12} sx={{
                height: '100%',
                maxHeight: '100%'
            }}>
                {loading ?
                    <LoaderWithText {...{text: 'loadingSelectedRuns', loadingExplanation}} /> :
                    <UserTrainRunIntervalYardActive {...{
                        appProps,
                        organizationProps,
                        trainProps,
                        componentProps: pick(
                            ['crudSelectedUserTrainRunIntervals'],
                            componentProps
                        )
                    }} />
                }
            </Grid>
        </Grid>
    </Box>;
};
UserTrainRunIntervalYardContainer.propTypes = {};
export default UserTrainRunIntervalYardContainer;