import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContent, AuthFormInner, Button, ButtonWrapper, Feedback, StyledInput } from './styles.js';
import { getLambdaUrl } from 'lib/fetch/cemitApi/config.js';
import { APPS_PATH } from 'appConfigs/appConfig.js';
import { concat, identity, when } from 'ramda';
import LoaderWithText from 'components/loading/LoaderWithText.js';

export const parseString = (str) => (str ? `/${str}` : '');

const AuthForm = () => {
  const navigate = useNavigate();
  const [authState, setAuthState] = useState({
    email: '',
    password: '',
    feedback: '',
    status: '',
    spinner: false
  });

  const useAuthenticate = token => {
    useEffect(() => {
      if (!token) {
        return;
      }
      // TODO temporarily hardcode access to train
      // TODO rename tabAccess, since the term tab is too unclear
      const tabAccess = when(identity, json => JSON.parse(json)?.tabAccess)(localStorage.getItem('tabAccess'))
      const accessToTrain = concat(['train'], tabAccess || [])

      // TODO always true
      if (accessToTrain) {
        // eslint-disable-next-line no-undef
        navigate(`/${APPS_PATH}/train/runs`);
      }
    }, [token]);
  };

  useAuthenticate(localStorage.getItem('token'));

  const onChangeListener = (e) =>
    setAuthState({
      ...authState,
      [`${e.target.id}`]: e.target.value
    });

  const submit = async (e) => {
    console.log(process.env.REACT_APP_MAKE);
    e.preventDefault();
    let lambda = getLambdaUrl();
    let localhost = 'http://localhost:3000/dev' == lambda;
    setAuthState((prevState) => ({ ...prevState, spinner: true }));
    const response = await fetch(`${lambda}/makejwt`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email: authState.email,
        url: localhost ? 'http://localhost:1234' : process.env.REACT_APP_MAKE
      })
    });

    const jsonResponse = await response.json();
    setAuthState((prevState) => ({ ...prevState, spinner: false }));

    setAuthState({
      ...authState,
      feedback: jsonResponse.message,
      status: response.status
    });
  };
  return (
    <AuthFormInner>
      <AuthContent>
        <h1>Login with one click</h1>
        <p>Submit your email, and we will send you a magic link to login</p>
        <StyledInput
          onChange={onChangeListener}
          placeholder='e-mail'
          value={authState.email}
          type='text'
          id='email'
        />

        <ButtonWrapper>
          <Button key='button' onClick={(e) => submit(e)} disabled={!authState.email.length}>
            Login
          </Button>
          {authState.spinner && <LoaderWithText key='loader' {...{spinner: authState.spinner}} />}
        </ButtonWrapper>
        {authState.feedback.length ? (
          <Feedback id='login-feedback' status={authState.status}>
            {authState.feedback}
          </Feedback>
        ) : null}
      </AuthContent>
    </AuthFormInner>
  );
};

export default AuthForm;
