import React, { useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import Loading from 'components/loading/index.js';
import { cemitLogLocation } from 'monitoring/cemitLogging.js';
import { useAuthenticate } from 'async/authentication/authenticationHooks.js';
import { ifElse } from 'ramda';

/**
 * Container that enforces Authentication using a token prior
 * to render the children
 * @param {Object} appProps
 * @param {Object} organizationProps TODO Currently used to initialize userState to a {sourceKey} This will
 * change in the future
 * @param children
 * @return {JSX.Element}
 */
const ProtectedRouteDependency = ({ organizationProps, children }) => {
  const {userState, setUserState} = organizationProps
  const navigate = useNavigate();

  const location = useLocation();
  useEffect(() => {
    cemitLogLocation(location);
  }, [location.pathname]);

  // Parse the token from the URL or failing that LocalStorage
  const token = ifElse(
    tokenUrl => {
      return /token=/.test(tokenUrl);
    },
    tokenUrl => {
      const token = tokenUrl.split('token=', 2)[1];
      localStorage.setItem('token', token);
    },
    () => {
      return localStorage.getItem('token');
    }
  )(location.search);

  if (!token) {
    return <Navigate noThrow to='/' />;
  }

  // Calls authenticate if the token is set and sets the userState on success
  useAuthenticate({ userState, setUserState, navigate, token });

  // If authenticated, render the children. Else show an error
  return userState.email !== null ?
    <> {children} </> :
    <Loading error={userState.error} />;
};

export default ProtectedRouteDependency;
