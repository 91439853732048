

export const CEMIT_ALERT_BLUE = '#4385FF'
export const CEMIT_ALERT_RED = '#FA5760'
export const CEMIT_ALERT_YELLOW = '#EBBD1A'

export const CEMIT_YELLOW = '#FFD952';
export const CEMIT_WHITE = '#FFFFFF';
export const CEMIT_GRAY = '#323F47';
export const CEMIT_GRAY_ON_DARK = '#2E383F';
export const CEMIT_ORANGE = '#f0541e';
// Graph comparison colors
export const CEMIT_COMPARE_BASELINE = '#339900';
export const CEMIT_COMPARE_LINES = [
  '#E0B034',
  '#D1CCBA',
  '#3f00ff'
];
export const CEMIT_COMPARE_FILL_1 = '#A5CB5D';
export const CEMIT_COMPARE_FILL_2 = '#DC1D51';
// Stuff made up for the TrainPage overview Figma sketch
export const CEMIT_TRAIN_RUN_LINE = '#35E9E9';
export const CEMIT_TRAIN_RUN_LINE_DARKER = '#357979';
// TODO don't know how to use this in the theme
export const CEMIT_TRAIN_RUN_INTERVAL_BAR_OPACITY = 0.5;
export const CEMIT_TRAIN_BUTTON_BACKGROUND = '#1B3547';
export const CEMIT_DARK_RED = '#880000';
export const CEMIT_GREEN = '#007700';

export const CEMIT_HISTORY = '#86378D'

export const colors = {
  darkBlue: '#22292e',
  orange: '#FFD952',
  lightBlue: '#485863',
  labelText: '#A3ABB1',
  red: '#F7005B',
  reallyRed: '#FF0000',
  green: '#badc58',
  deepGreen: '#27ae60',
  errorTwist: '#D7FCD4',
  errorTwistAlt: '#48E5C2',
  errorDistance: '#9D9DFA',
  errorDistanceAlt: '#7AB5D8',
  trackNormal: CEMIT_TRAIN_RUN_LINE,
  trackHighlighted: CEMIT_TRAIN_RUN_LINE,
  trackGreen: '#009900',
  trackGreenLighter: '#00FF00'
};
