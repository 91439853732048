import React from 'react';
import ServiceLineDependency from 'async/trainAppAsync/dependencies/ServiceLineDependency.js';

/**
 * TrainContainer and the main container of the Train App. It loads
 * dependencies before rendering the Train component
 * @param appProps
 * @param organizationProps
 * @returns {*}
 * @constructor
 */
const TrainContainer = ({ appProps, organizationProps }) => {
  // Creates containers that depend on Client props
  return <ServiceLineDependency key={'ServiceLineDependency'} {...{ appProps, organizationProps }}/>
};
TrainContainer.displayName = 'TrainContainer'
export default TrainContainer;
