import {MapProps} from "../../visualizer-railbed/railbedTypes/propTypes/mapProps";
import {RideComfortHeatMapDatum} from "../../types/rideComfort/rideComfortHeatMapDatum";
import {filter, lte, map, prop} from "ramda";
import {RideComfortAttributeAlertSValue} from "../../types/rideComfort/rideComfortAlertLevel.ts";
import {TrainPropsWithRideComfortProps} from "../../types/rideComfort/trainPropsWithRideComfortProps";
import {FeatureU, PointU} from "../../visualizer-railbed/railbedTypes/geometry/geojsonUnions";
import bbox from '@turf/bbox'
import {BBox2d} from "@turf/helpers/dist/js/lib/geojson";

export const zoomMapToCriticalAlerts = (trainProps: TrainPropsWithRideComfortProps, mapProps: MapProps) => {
    const boundingBox: BBox2d =  bboxOfCriticalHeatMapData(trainProps)
    mapProps.trainMap.fitBounds(boundingBox, {
        essential: true,
    })
}

export const criticalHeatMapData = (trainProps: TrainPropsWithRideComfortProps) => {
    return filter(
        (heatMapDatum: RideComfortHeatMapDatum) => {
            // if value is >= critical
            return lte(RideComfortAttributeAlertSValue.critical, heatMapDatum.numericValue)
        },
        trainProps.rideComfortProps.heatMapData
    )
}

export const bboxOfCriticalHeatMapData = (trainProps: TrainPropsWithRideComfortProps): BBox2d => {
    const pointFeatures: FeatureU<PointU>[] = map(prop('point'), criticalHeatMapData(trainProps))
    return bbox(pointFeatures) as BBox2d
}
