import { useNotLoadingEffect } from 'utils/hooks/useMemoHooks.js';
import { idsEqual } from 'utils/functional/functionalUtils.js';
import { createTrainRouteInterval } from 'appUtils/trainAppUtils/trainRouteIntervalUtils.js';

/**
 * Updates/Creates a TrainRouteInterval, which is like a TrainRoute-scope
 * interval used as a template for creating TrainRun intervals
 * TrainRouteInterval can be used to update interval of multiple TrainRuns at the same time
 * @param loading
 * @param trainRoute
 * @param trainRouteInterval
 * @param setTrainRouteInterval
 * @param [sourceKeyPrefix] Default ''
 */
export const useUpdateOrCreateTrainRouteInterval = (
  {
    loading,
    trainRoute,
    trainRouteInterval,
    setTrainRouteInterval,
    sourceKeyPrefix=''
  }) => {
  useNotLoadingEffect(loading, () => {
    if (!trainRouteInterval || !idsEqual(trainRouteInterval.trainRoute, trainRoute)) {
      // TODO, we'll want to store trainRouteIntervals for each TrainRoute in the near future
      // so we don't lose the user's selected interval
      setTrainRouteInterval(
        createTrainRouteInterval({ sourceKeyPrefix, trainRoute })
      );
    }
  }, [sourceKeyPrefix, trainRoute, trainRouteInterval]);
};