/**
 * Returns true if the object represents a Ramda view
 * @param obj
 * @param props
 * @returns {*}
 */
import { has } from 'ramda';

export const isView = (obj) => {
  return has('view', obj);
};
export const isEquals = (obj) => {
  return has('equals', obj);
};