import { colors } from 'theme/colors.ts';
import { extractTrainRunIntervalTrainRoute, isUserTrainRunInterval } from 'appUtils/trainAppUtils/trainRunUtils.js';
import { compact } from '@rescapes/ramda';
import { compose, cond, equals, fromPairs, head, identity, map, pick, prop, T } from 'ramda';
import { getType } from '@turf/invariant';
import center from '@turf/center';

export const CHART_HOVER_SOURCE = 'chart-hover-trace-source';
export const CHART_HOVER_LAYER = 'chart-hover-trace-layer';

const hoverFeatureKeys = ({ trainProps, userTrainRunInterval }) => {
  // Mark the userTrainRunInterval.sourceKey position with the feature
  return compact([
    isUserTrainRunInterval(userTrainRunInterval) ? [userTrainRunInterval.sourceKey] : null,
    // Set the same feature for the TrainRoute for now so we can mark the feature on the SampleChart Page UserTrainRunLine
    // TODO this might not ever be needed. It might be enough to set the same TrainRoute or TrainRouteGroup below
    // if there is never a case where we need to distinguish an individual TrainRoute from the TrainRouteGroup it is in
    extractTrainRunIntervalTrainRoute(userTrainRunInterval).id,
    // Set the same feature for the current TrainRoute in case it is a TrainRouteGroup that differs from the
    // TrainRoute of userTrainRunInterval.
    trainProps.trainRouteProps.trainRoute.id
  ]);
};

/**
 * Responds to a map layer hover, telling the app what feature is hovered over for the given UserTrainRunInterval
 * @param appProps
 * @returns {(function(*=): void)|*}
 */
export const moveCursorAlongTrainMapLine = ({ appProps, trainProps }) => (userTrainRunInterval, payload) => {
  const feature = singlePointFeatureFromPayload(payload);
  appProps.setHoverFeature(obj => {
    // Merge new keys with the current values to handle hovering over multiple UserTrainRunIntervals
    return {
      ...obj,
      ...fromPairs(
        map(
          key => [key, feature],
          hoverFeatureKeys({
            appProps, trainProps, userTrainRunInterval
          })
        )
      )
    };
  });
};

/**
 * Responds to hover ending on the map and clears the hover feature for the given UserTrainRunInterval
 * @param appProps
 * @returns {(function(*=): void)|*}
 */
export const removeCursorFromTrainMap = ({ appProps }) => () => {
  appProps.setHoverFeature(() => {
    return fromPairs(map(key => [key, null], hoverFeatureKeys));
  });
};

export const removeCursorFromChart = trainMap => () => {
  if (!trainMap) {
    return;
  }
  if (trainMap.getSource(CHART_HOVER_SOURCE)) {
    trainMap.removeLayer(CHART_HOVER_LAYER);
    trainMap.removeSource(CHART_HOVER_SOURCE);
  }
};

/**
 * When the user hovers on the chart, this creates a layer on the trainMap
 * to match the user's hover position on the chart.
 * It also update the chart's TrainRunLine vertical hash to mark the same position
 * @param updateTrainRunLinePosition
 * @parma payload  Contains the most recent payload from hovering on the charts
 * @param trainMap
 * @returns {(function(*): void)|*}
 */
export const moveCursorAlongChartLine = ({ updateTrainRunLinePosition, trainMap }) => payload => {
  // Update the chart's TrainRunLine hash mark
  updateTrainRunLinePosition(payload);

  // Get the Point Feature from each payload item
  const featureCollection = featureCollectionFromPayload(payload);
  if (!featureCollection) {
    return;
  }

  // Update the data if the source exists
  if (trainMap.getSource(CHART_HOVER_SOURCE)) {
    trainMap.getSource(CHART_HOVER_SOURCE).setData(featureCollection);
    return;
  }
  // Otherwise create the source and layer
  trainMap.addSource(CHART_HOVER_SOURCE, { type: 'geojson', data: featureCollection });
  trainMap.addLayer({
    id: CHART_HOVER_LAYER,
    type: 'circle',
    source: CHART_HOVER_SOURCE,
    paint: {
      'circle-color': 'rgba(0,0,0,0)',
      'circle-radius': 13,
      'circle-stroke-color': colors.orange,
      'circle-stroke-width': 1.5
    }
  });
};

/**
 * Creates a FeatureCollection from the first item of a recharts payload. If the payload has no items, null is returned
 * @param payloadItems
 * @returns {*|{features: *[], type: string}}
 */
export const singleItemFeatureCollectionFromPayload = payloadItems => {
  const feature = singlePointFeatureFromPayload(payloadItems);
  return feature && {
    type: 'FeatureCollection',
    features: [feature]
  };
};

/**
 * Convert the payloadItems[*].payload features to a FeatureCollection
 * @param payloadItems
 * @returns {{features, type: string}}
 */
export const featureCollectionFromPayload = payloadItems => {
  return payloadItems && {
    type: 'FeatureCollection',
    features: map(prop('payload'), payloadItems)
  };
}

/***
 * Extracts or creates a Point Feature from the first item of a recharts payload. If the payload has no items, null is returned
 * @param payload
 * @returns {*}
 */
export const singlePointFeatureFromPayload = payload => {
  // TODO we currently use head, but maybe we should consider all payload items
  const payloadItem = head(payload || []);
  if (!payloadItem) {
    return null;
  }
  return pointFeatureFromPayLoadItem(payloadItem)
}

/**
 * Converts the payloadItem.paylaod feature to a Point feature if it isn't one, preserving
 * the properties, id, and centroid of the feature
 * @param payloadItem
 * @returns {*}
 */
export const pointFeatureFromPayLoadItem = payloadItem => {

  // Converts the feature to a Point feature, preserving the given attributes if defined
  const featureToPoint = feature => {
    return center(feature, pick(['properties', 'centroid', 'id'], feature));
  };
  // Return the point or the center Point of a LineString or Polygon
  return cond([
    [
      compose(equals('Polygon'), getType),
      featureToPoint
    ],
    [
      compose(equals('Linestring'), getType),
      featureToPoint
    ],
    // Leave it alone
    [compose(equals('Point'), getType), identity],
    [T, feature => {
      throw Error(`Feature expected to be a Polygon or Point, but got a ${getType(feature)}`);
    }]
  ])(payloadItem.payload);
};