import { addIndex, map as mapR } from 'ramda';
import { Stack } from '@mui/material';
import { forwardRef, useRef } from 'react';
import PropTypes from 'prop-types';
import StackedAreaChartContainer from 'components/charts/stackedChart/StackedAreaChartContainer.js';

/**
 * Compares track data for two different week
 * @param {Object} appProps
 * @param {Function} appProps.moveCursorAlongChartLine Send messages about moving on the chart
 * @param {Function} appProps.onMouseLeave Send messages about leaving the chart
 * @param {Number} yAxisWidth The chart y-axis width in pixels. Set from ancestor containers
 * so we can align the TrainRunLine
 * @param {[String]} dataPaths The str paths within geojson properties object to display
 * E.g. 'acceleration.sumMaxMean'
 * @param {[Object]} comparisonTrackDataSets[*].data The dataset
 * @param {[Object]} comparisonTrackDataSets[*].label The label of the dataset
 * @param {[Object]} comparisonTrackDataSets[*].featuresPath The string path to the geojson features, e.g. 'features' or 'foo.geojson.features'
 * @param {Number} width Width of the charts
 * @param {Number} height Height of the charts
 * @param {Function} setChartWidth Setter function to tell the TrainRunLine how wide the svg chart component is
 * @returns {JSX.Element}
 */
const StackedDataPathComparisonChart = forwardRef((
  {
    appProps,
    trainProps,
    componentProps: {
      yAxisWidth,
      dataPathsConfigs,
      trackDataFeatureSets,
      trainDataPathStackedChartRefWidth,
      showUpdating = false,
    },
    sx
  }, ref) => {

  //
  const chartAndMapDataContainerRef = useRef(null);


  return <Stack sx={{
    direction: 'column',
    overflow: 'hidden',
    overflowY: 'scroll',
    marginTop: 0
  }}>
    {addIndex(mapR)(({ dataPath, label, labelYAxis, domain, customControls, referenceLines=[] }, index) => {
      return <StackedAreaChartContainer key={index} {...{
        appProps,
        trainProps,
        componentProps: {
          yAxisWidth,
          index,
          // This is the dataPath being graphed
          dataPath,
          // Send the rest of the configs so that the legend/popup space
          // can show the other values as well
          dataPathsConfigs,
          label,
          labelYAxis,
          yAxisDomainOfDataPathConfig: domain,
          showUpdating,
          trainDataPathStackedChartRefWidth,
          customControls,
          referenceLines
        },
        trackDataFeatureSets,
        sx,
        forwardedRef: ref,
        chartAndMapDataContainerRef
      }} />;
    }, dataPathsConfigs)}
  </Stack>;
});
StackedDataPathComparisonChart.displayName = 'StackedDataPathComparisonChart';
StackedDataPathComparisonChart.propTypes = {
  componentProps: PropTypes.shape({
    dataPathsConfigs: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    // This doesn't have to be loaded. We can show the charts with a loading message.
    comparisonTrackDataSets: PropTypes.arrayOf(PropTypes.shape())
  }).isRequired,
  sx: PropTypes.shape()
};
export default StackedDataPathComparisonChart;