import React, { useEffect, useState } from 'react';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { useZoomAndPan } from './hooks/useZoomAndPan.js';
import { useTooltipSorting } from './hooks/useTooltipSorter.js';
import RechartsClipPaths from 'components/charts/stackedChart/sample/RechartsClipPaths.js';

const DEFAULT_CHART_PROPS = {
  width: 500,
  height: 400,
  margin: {
    top: 25,
    right: 10,
    left: 10,
    bottom: 0
  }
};

const DEFAULT_GRID_PROPS = {
  strokeDasharray: '3 3'
};

const SampleChart = (
  {
    data,
    lines,
    chartOptions,
    gridOptions,
    axis,
    tooltip,
    legend
  }) => {
  const [loaded, setLoaded] = useState(false);

  const {
    clipPathRefs,
    xPadding,
    onChartMouseDown,
    onChartMouseUp,
    setWrapperRef,
    onChartMouseMove
  } = useZoomAndPan({
    chartLoaded: loaded
  });

  const { onSeriesMouseOver, tooltipSorter } = useTooltipSorting();

  useEffect(() => {
    setTimeout(() => {
      setLoaded(true);
    }, 100);
  }, []);

  return (
    <ResponsiveContainer
      className='noselect'
      width='100%'
      height='100%'
      debounce={100}
      ref={setWrapperRef}
    >
      <LineChart
        {...{
          ...DEFAULT_CHART_PROPS,
          margin: {
            ...DEFAULT_CHART_PROPS.margin,
            ...chartOptions?.margin
          }
        }}
        data={data}
        onMouseMove={onChartMouseMove}
        onMouseDown={onChartMouseDown}
        onMouseUp={onChartMouseUp}
      >
        <defs>
          <RechartsClipPaths ref={clipPathRefs} />
        </defs>
        <CartesianGrid
          {...{
            ...(DEFAULT_GRID_PROPS),
            ...gridOptions,
            stroke: gridOptions?.hide ? 'transparent' : gridOptions?.stroke
          }}
        />
        {axis?.x?.hide ? null : (
          <XAxis
            {...axis?.x}
            padding={{ left: xPadding[0], right: xPadding[1] }}
            domain={['dataMin', 'dataMax']}
            className='x-axis'
          />
        )}
        {axis?.y?.hide ? null : <YAxis {...axis?.y} />}
        {tooltip?.hide ? null : (
          <Tooltip {...tooltip} itemSorter={tooltipSorter} />
        )}
        {legend?.hide ? null : <Legend {...(legend)} />}
        {lines.map((l, i) => (
          <Line
            key={`${l.key}-${i}`}
            id={l.key}
            {...(l)}
            className={`${l.className || ''}`}
            activeDot={{
              onMouseOver: () => onSeriesMouseOver(String(l.dataKey))
            }}
            onMouseOver={() => onSeriesMouseOver(String(l.dataKey))}
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default SampleChart;
