import { useTrainApiClient } from 'async/trainAppAsync/hooks/trainApiHooks/trainApiClientHooks.js';
import { useState } from 'react';
import { createChildElements } from 'utils/componentLogic/createComponentUtils.js';
import { defaultAccelerationThresholds } from 'appConfigs/trainConfigs/trainMapConfig.js';
import { useTranslation } from 'react-i18next';

/**
 * Loads the client if needed
 * This currently comes from a hard coded appProps.clientConfig.clientSourceKey, but the client should of
 * course be identified by the logged-in user
 * @param {Object} appProps
 * @param {Object} appProps.clientConfig
 * @param {String} appProps.clientConfig.clientSourceKey
 * @param {Function} children child components to create, passing the existing props and loaded data
 * @return {*}
 * @constructor
 */
const ClientDependency = ({ appProps, children }) => {
  const [organization, setOrganization] = useState(null);
  const [userState, setUserState] = useState({
    id: null,
    email: null,
    iat: null,
    error: null,
    // Hard code to the only client for now. This should come from authentication
    clientSourceKey: 'Flytoget'
  });
  const {t} = useTranslation()

  // TODO Thresholds probably need to be configured on a per RailwayLine or Track basis. For now make a global
  const dataThresholds = defaultAccelerationThresholds({t});

  // Loads the Client instance TODO get the clientSourceKey from the authenticate user
  useTrainApiClient({
    organization: {
      sourceKey: userState.clientSourceKey
    },
    setOrganization,
  });

  return createChildElements({
      appProps,
      organizationProps: {
        // organizationProps depend on appProps
        loading: appProps.loading || !organization,
        organization, setOrganization,
        userState, setUserState,
        dataThresholds,
      }
    },
    children
  );
};
ClientDependency.displayName = 'ClientDependency'
export default ClientDependency;