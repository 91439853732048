import { useState } from 'react';
import { useTrainApiSetRailwayLines } from 'async/trainAppAsync/hooks/trainApiHooks/trainApiRailwayLineHooks.js';
import TrainRouteDependency from 'async/trainAppAsync/dependencies/TrainRouteDependency.js';

/**
 * Loads updates RailwayLine props in trainProps.railwayLineProps
 * Depends directly on trainProps.serviceLineProps
 * @param appProps
 * @param organizationProps
 * @param trainProps
 * @param children
 * @return {*}
 * @constructor
 */
const RailwayLineDependency = ({ appProps, organizationProps, trainProps }) => {

  const loading = trainProps.serviceLineProps.loading;

  // The unique physical railway lines of the client's serviceLines
  // These contain the geojson we need to render the lines on the map
  const [railwayLines, setRailwayLines] = useState(null)
  const serviceLines = trainProps.serviceLineProps.serviceLines;

  // Load the physical RailwayLines and augment them with frontend track computations
  useTrainApiSetRailwayLines({
    loading,
    serviceLines,
    setRailwayLines
  });

  return <TrainRouteDependency {...{
    appProps,
    organizationProps,
    trainProps: {
      ...trainProps,
      railwayLineProps: {
        // Depends directly on organizationProps
        loading: loading || !railwayLines,
        railwayLines,
        setRailwayLines
      }
    }
  }} />;
};
RailwayLineDependency.displayName = 'RailwayLineDependency';
export default RailwayLineDependency;