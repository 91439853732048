import { ToggleLabelShowHide } from 'components/labels/ToggleLabels.js';

/**
 * TODO Outdated cleanup or remove
 * @param toggleSidebar
 * @param toggleMapView
 * @param showSidebarOnSmallScreen
 * @param showMapOnSmallScreen
 * @returns {JSX.Element}
 * @constructor
 */
export const CemitAppFooter = (
  {
    toggleSidebar,
    toggleMapView,
    showSidebarOnSmallScreen,
    showMapOnSmallScreen
  }) => {
    return <FooterInner>
      <FooterItem
        id='showMapToggleButton'
        key='showMapToggleButton'
        onClick={toggleMapView}
      ><ToggleLabelShowHide {...{ label:'map', value: showMapOnSmallScreen}} /></FooterItem>
      <FooterItem
        id='showSidebarToggleButton'
        key='showSidebarToggleButton'
        onClick={toggleSidebar}
      ><ToggleLabelShowHide {...{label:'sidebar', value: showSidebarOnSmallScreen}} /></FooterItem>
    </FooterInner>
};

export const FooterInner = () => {
  return <div {...{
    style: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'row-reverse',
      paddingTop: '5px'
    }
  }}
  />;
};

export const FooterItem = () => {
  return <div {...{
    style: {
      padding: '10px 15px',
      color: 'white'
    }
  }}
  />;
};
