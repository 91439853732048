import { consolidateRanges } from 'utils/distance/distanceUtils.js';
import { shallowEquals } from 'utils/functional/functionalUtils.js';
import { mergeDeep } from '@rescapes/ramda';
import {
  all,
  always,
  compose, concat,
  cond,
  equals,
  indexBy,
  is,
  values,
  map,
  mergeDeepWithKey,
  mergeRight,
  mergeWith,
  mergeWithKey, prop,
  propOr, sortBy, T, uniqBy
} from 'ramda';

/**
 * Merge TrainRun does a simple merge except for imuPoints. If new imuPoints come in that differ
 * from the previous, they are sorted and combined
 * @param {TrainRun} existingTrainRun The existing TrainRun
 * @param {TrainRun} incomingTrainRun The incoming TrainRun
 * @returns {*}
 */
export const mergeTrainRun = (existingTrainRun, incomingTrainRun) => {
  const distanceIntervalsAndRanges = compose(
    // Remove the distanceIntervalKeys
    values,
    // Merge, consolidating distanceRanges from existing and incoming
    ([a, b]) => {
      return mergeDeepWithKey(
        (key, existing, incoming) => {
          if (key === 'distanceRanges') {
            return consolidateRanges(existing, incoming);
          }
          return incoming;
        },
        a, b
      );
    },
    // Get distanceIntervalsAndRanges lists for existing and incoming and index each by distanceInterval
    map(
      compose(
        indexBy(prop('distanceInterval')),
        propOr([], 'distanceIntervalsAndRanges')
      )
    )
  )([
    existingTrainRun,
    incomingTrainRun
  ]);

  const mergedTrainRun = mergeRight(
    mergeWithKey(
      (key, existingPropValue, incomingPropValue) => {
        return cond([
          // If references equals or key is geojson, always take the incoming prop value
          [shallowEquals, (a, b) => {
            return b;
          }],
          [always(equals('geojson', key)),
            (a, b) => {
              return b;
            }
          ],
          // If imuPoints, run the custom merge
          [always(equals('imuPoints', key)), mergeDifferingImuPoints],
          [T, (a, b) => {
            return all(is(Object), [a, b]) ? mergeDeep(a, b) : b;
          }]
        ])(existingPropValue, incomingPropValue);
      },
      existingTrainRun,
      incomingTrainRun
    ),
    { distanceIntervalsAndRanges }
  );
  return mergedTrainRun;
};

/**
 For imuPoints where existing and new are different, we need to merge the array of ImuPoints for each cdc Key. Example:
 existing: imuPoints: {cdc0011: [...1000 points over railway...], cdc0037: [...1000 points over railway..]}
 new: imuPoints: {cdc0011: [...1000 more points between two stops...], cdc0037: [...1000 more points between two stops..]}
 would merge the array of points by time for each set for cdc0011 and cdc0037
 TODO there might be a better algorithm to sort this data, since the newData is always clumped
 It might be better to find start and end indexes of the existData where the newData is
 being inserted, then just sort the existing with the new between that index range
 but either way it is basically order 2N
 The backend prevents some duplicates, but we must remove the rest here
 * @param {[ImuPoint]} existingImuPoints The existing ImuPoints of the TrainRun, meaning those already downloaded
 * @param {[ImuPoint]} incomingImuPoints The incoming ImuPoints of the TrainRun that are different from the existing
 * @returns {[ImuPoint]}
 */
const mergeDifferingImuPoints = (existingImuPoints, incomingImuPoints) => {
  return mergeWith(
    (a, b) => {
      const merged = compose(
        combinedImuPoints => {
          return uniqBy(prop('time'), combinedImuPoints);
        },
        combinedImuPoints => {
          return sortBy(prop('time'), combinedImuPoints);
        },
        (a, b) => {
          return concat(a, b);
        }
      )(a, b);
      return merged
    }
  )(existingImuPoints, incomingImuPoints);
};