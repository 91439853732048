import { TrainRunIntervalBarCustomDragLayer } from 'components/apps/trainAppComponents/trainRunChooserComponents/TrainRunIntervalBarCustomDragLayer.js';
import { TrainRunIntervalBar } from 'components/apps/trainAppComponents/trainRunChooserComponents/TrainRunIntervalBar.js';
import { STATION_DOT_HEIGHT } from 'theme/train/trainThemeConstants.js';
import { toArrayIfNot } from '@rescapes/ramda';
import { TRAIN_RUN_INTERVAL_BAR_HEIGHT } from 'appConfigs/trainConfigs/trainRunConfig.js';
import { cond, equals, T } from 'ramda';
import { ItemTypes } from 'appConfigs/trainConfigs/trainDragAndDropConfig.js';
import { computedIntervalBarPosition } from 'appUtils/trainAppUtils/trainRunIntervalUtils.js';

/**
 * This is the component that moves when we are dragging. It draws the same TrainRunIntervalBar
 as is drawn below in DraggableTrainRunIntervalBar but it's position and width changes according
 to the user's moverDrag and expansion/contraction of the edges of the bar
 * @param isTrainRouteLine,
 * @param spaceGeospatially,
 * @param limitedDistanceRange,
 * @param {String} side Either 'left' or 'right' to indicate what side of the bar we are expanding
 * @param {Object} trainRun The TrainRun
 * @param {Boolean} isUserScope if true, then the objects being dragged our UserTrainRunIntervals, if false
 * they are TrainRunIntervals
 * @param {Function} resolveOffsetLeft Function that expects a start distance or end distance and resolves the offset of the
 * moverDrag layer's left and width properties
 * @returns {JSX.Element}
 * @constructor
 */
const TrainRunIntervalBarExpanderDragLayer = (
  {
    isTrainRouteLine,
    spaceGeospatially,
    limitedDistanceRange,
    parentWidth,
    side,
    trainRun,
    isUserScope,
    resolveOffsetLeft
  }) => {
  return <TrainRunIntervalBarCustomDragLayer
    handleItemType={side === 'left' ? ItemTypes.TRAIN_RUN_INTERVAL_BAR_LEFT_EXPANDER : ItemTypes.TRAIN_RUN_INTERVAL_BAR_RIGHT_EXPANDER}
    isTrainRouteLine={isTrainRouteLine}
    trainRun={trainRun}
    isUserScope={isUserScope}
    side={side}
    constrainY={true}
    render={
      /**
       * The render function. dragOffset is continuously recalculated as the difference of {x, y} from the
       * initial position of the element. We constrain y, so only x changes
       * @param sx
       * @param trainRunInterval
       * @param dragOffset
       * @returns {JSX.Element}
       */
        ({ sx, trainRunInterval, dragOffset }) => {
        const id = `dragComponent-${trainRunInterval}-${isTrainRouteLine ? 'isTrainRoute' : trainRun?.id || 'undefined'}`;
        const xOffset = dragOffset?.x || 0;
        const percentOrPixel = prop => `${prop}${spaceGeospatially ? '%' : 'px'}`;

        const _computedIntervalBarPosition = (value, xOffset) => computedIntervalBarPosition({
          trainRunInterval,
          spaceGeospatially,
          limitedDistanceRange,
          resolveOffsetLeft,
          parentWidth
        }, value, xOffset);

        const { left, width } = cond([
          [equals('left'),
            () => {
              const left = _computedIntervalBarPosition(trainRunInterval.distanceRange.start, xOffset);
              // Compute the position of the end of the range and subtract left to get the width
              const width = _computedIntervalBarPosition(trainRunInterval.distanceRange.end, 0) - left;
              return {
                // Left side expands/contracts
                left: percentOrPixel(left),
                // Right side is fixed, meaning the width is the expanding/contracting left minus the offset of the fixed end
                width: percentOrPixel(width)
              };
            }
          ],
          [equals('right'),
            () => {
              const left = _computedIntervalBarPosition(trainRunInterval.distanceRange.start, 0);
              const width = _computedIntervalBarPosition(trainRunInterval.distanceRange.end, xOffset) - left;
              return {
                // Left side is fixed
                left: percentOrPixel(left),
                // Right side expands/contracts
                width: percentOrPixel(width)
              };
            }
          ],
          [T, () => {
            throw new Error(`Side must be either 'left' or 'right', not ${side || 'undefined/null'}`);
          }]
        ])(side);
        return <TrainRunIntervalBar
          key={id}
          trainRunInterval={trainRunInterval}
          id={id}
          isDragLayer={true}
          sx={[
            {
              left,
              width,
              height: TRAIN_RUN_INTERVAL_BAR_HEIGHT,
              top: `calc((${STATION_DOT_HEIGHT} / 2) - (${TRAIN_RUN_INTERVAL_BAR_HEIGHT} / 2))`
            }, ...toArrayIfNot(sx)]
          }
        />;
      }
    } />;
};

export default TrainRunIntervalBarExpanderDragLayer;