import Menu from '@mui/material/Menu/index.js';
import MenuItem from '@mui/material/MenuItem/index.js';
import { useState } from 'react';
import { is, map, omit } from 'ramda';
import { IconButton } from '@mui/material';
import { AccessTime } from '@mui/icons-material';
import { matchesDateRecurrence } from 'appUtils/trainAppUtils/trainFilterDateRecurrenceUtils.js';

/**
 * Component to choose from a reasonable choice of date recurrences
 * days are the seven days of the week, weekdays, and weekend
 * dates are the 31 calendar days
 * times are list of all departure times for the availalble  TrainRuns
 * @param allKnownTrainRunDepartureTimes ordered lists of all TrainRun departure times that have been loaded into the app
 * @param addDateRecurrenceToFilter
 * @returns {JSX.Element}
 * @constructor
 */
export const DateRecurrenceDepartureTimesMenu = (
  {
    chosenDateRecurrences,
    allKnownTrainRunDepartureTimes,
    handleAddDateRecurrenceToFilter
  }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e, dateRecurrence) => {
    setAnchorEl(null);
    // if dateRecurrence comes in as the string 'backDrop',
    // it means the user clicked outside the menu
    if (!is(String, dateRecurrence)) {
      // Indicate that we are adding a time dateRecurrence
      handleAddDateRecurrenceToFilter(omit(['label'], dateRecurrence))
    }
  };
  // Get the unique times and make into DateRecurrences
  const timeDateRecurrences = map(
    time => {
      return { type: 'getTime', values: time.fullTime, label: time.label };
    },
    allKnownTrainRunDepartureTimes
  );

  return <div>
    <IconButton
      key='button'
      id='dateRecurrence-departureTime-button'
      color='secondary'
      aria-controls={open ? 'dateRecurrence-departureTime-positioned-menu' : undefined}
      aria-haspopup='true'
      aria-expanded={open ? 'true' : undefined}
      onClick={handleClick}
      sx={{padding:0}}
    >
      <AccessTime />
    </IconButton>
    <Menu
      key='departureTimesMenu'
      id='dateRecurrence-departureTime-positioned-menu'
      aria-labelledby='dateRecurrence-departureTime-button'
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
    >
      {
        map(dateRecurrence => {
            return <MenuItem
              key={dateRecurrence.values}
              disabled={matchesDateRecurrence(dateRecurrence, chosenDateRecurrences)}
              onClick={e => handleClose(e, omit(['label'], dateRecurrence))}
            >{dateRecurrence.label}</MenuItem>;
          },
          timeDateRecurrences
        )
      }
    </Menu>
  </div>;
};