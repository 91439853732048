import { useState } from 'react';
import { useUpdate3dForTrainMap } from 'async/trainAppAsync/hooks/mapHooks/trainRunLayersHooks.js';
import { unlessLoadingProps } from 'utils/componentLogic/loadingUtils.js';
import Train from 'pages/trainApp/Train.js';
import { useTranslation } from 'react-i18next';


/***
 * Loads/Updates MapData layers dependent on sensor data
 * Depends directly on UserTrainRunIntervalSensor props in trainProps.userTrainRunIntervalProps.geojsonProps and TrainMap props
 * @param appProps
 * @param organizationProps
 * @param trainProps
 * @param mapProps
 * @param children
 * @return {*}
 * @constructor
 */
const TrainMapSensorLayerDependency = ({ appProps, organizationProps, trainProps, mapProps }) => {

  const loading = trainProps.userTrainRunIntervalProps.geojsonProps.loading || mapProps.loading;
  const [are3dLayersUpdating, set3dLayersUpdating] = useState(false);
  const [mapDependenciesLoading, setMapDependenciesLoading] = useState(false);

  // The 3d layers are dependent on sensor data
  const dataThresholds = organizationProps.dataThresholds;

  const {t} = useTranslation()

  useUpdate3dForTrainMap(
    {
      loading,
      ...unlessLoadingProps(loading, () => ({
        appProps,
        organizationProps,
        trainProps,
        mapProps,
        trainMap: mapProps.trainMap,
        trainRoute: trainProps.trainRouteProps.trainRoute,
        featurePropPath: appProps.chosenAcceleration.derivedProp,
        dataColumns3DLayerAreVisible: mapProps.trainMapLayerProps.trainMapButtonStates.dataColumns3DLayerAreVisible,
        dataThresholds,
        set3dLayersUpdating,
        t
      }))
    }
  );


  return <Train {...{
    appProps,
    organizationProps,
    mapProps: {
      trainMapSensorLayerProps: {
        // Depends directly on UserTrainRunIntervalSensor props in trainProps.userTrainRunIntervalProps.sensorProps
        // and TrainMap in mapProps
        loading: trainProps.userTrainRunIntervalProps.sensorProps.loading || mapProps.loading,
        are3dLayersUpdating, set3dLayersUpdating,
        mapDependenciesLoading, setMapDependenciesLoading
      },
      ...mapProps
    },
    trainProps
  }} />
};
TrainMapSensorLayerDependency.displayName = 'TrainMapSensorLayerDependency'
export default TrainMapSensorLayerDependency;