import { useTrainApiSetServiceLines } from 'async/trainAppAsync/hooks/trainApiHooks/trainApiServiceLineHooks.js';
import { useState } from 'react';
import FormationDependency from 'async/trainAppAsync/dependencies/FormationDepedency.js';

/**
 * Loads/Updates ServiceLine props
 * Depends directly on organizationProps
 * @param appProps
 * @param organizationProps
 * @param children
 * @return {*}
 * @constructor
 */
const ServiceLineDependency = ({ appProps, organizationProps }) => {
  const loading = organizationProps.loading;

  // The service lines of the client
  const [serviceLines, setServiceLines] = useState(null);

  // We don't require anything to be in UserTrainRunIntervals, because not every TrainRoute has
  // a baseline TrainRun, which would otherwise be the default active UserTrainRunInterval
  useTrainApiSetServiceLines({ loading, organization: organizationProps.organization, setServiceLines });

  return <FormationDependency {...{
    appProps,
    organizationProps,
    trainProps: {
      serviceLineProps: {
        loading: loading || !serviceLines,
        serviceLines, setServiceLines
      }
    }
  }} />;
};
ServiceLineDependency.displayName = 'ServiceLineDependency';
export default ServiceLineDependency;