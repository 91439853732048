import { useElementSize } from 'usehooks-ts';
import TrainChartDisplay from 'components/apps/trainAppComponents/trainChartComponents/TrainChartDisplay.js';
import { unlessLoadingValue } from 'utils/componentLogic/loadingUtils.js';
import { railwayLineSourceKeyToReferenceScheduledStopPoint } from 'appUtils/trainAppUtils/railwayLineUtils.js';

const TrainChartDisplayContainer = (
  {
    appProps,
    organizationProps,
    trainProps,
    componentProps: {
      dataPathsConfigs,
    }
  }) => {
  const loading = trainProps.userTrainRunIntervalProps.geojsonProps.loading;

  const [trainDataPathStackedChartRef, { width: trainDataPathStackedChartRefWidth }] = useElementSize();
  const referenceStopLookup = unlessLoadingValue(loading,
    () => {
      return railwayLineSourceKeyToReferenceScheduledStopPoint(trainProps.railwayLineProps.railwayLines);
    }
  );
  return <TrainChartDisplay {...{
    appProps,
    organizationProps,
    trainProps,
    componentProps: {
      loading,
      referenceStopLookup,
      dataPathsConfigs,
      trainDataPathStackedChartRef,
      trainDataPathStackedChartRefWidth
    }
  }} />;
};
export default TrainChartDisplayContainer;