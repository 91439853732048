import { useState } from 'react';
import { useTrainApiSetFormations } from 'async/trainAppAsync/hooks/trainApiHooks/trainApiFormationHooks.js';
import RailwayLineDependency from 'async/trainAppAsync/dependencies/RailwayLineDependency.js';
import { useNotLoadingMemo } from 'utils/hooks/useMemoHooks.js';
import { filter } from 'ramda';
import { formationHasCollectionDevices } from 'appUtils/trainAppUtils/formationUtils.js';
import { useUpdateOrCreateFormationFilter } from 'async/trainAppAsync/hooks/trainRunFilterHooks/trainFilterFormationHooks.js';

/**
 * Loads/Updates Formations (train sets) into trainProps.formationProps
 * Depends directly on organizationProps
 * @param appProps
 * @param organizationProps
 * @param trainProps
 * @param {Function} children Render prop function to render dependents
 * @return {Object} Rendered child components
 */
const FormationDependency = ({ appProps, organizationProps, trainProps }) => {
  const loading = organizationProps.loading;
  // Train Formations (trainset)
  const [formations, setFormations] = useState(null);

  // Store a TrainRunFilter that combines the parent trainRunFilterWithTrainRoute
  // with DateRangeFilters
  const [trainRunFilterWithFormations, setTrainRunFilterWithFormations] = useState(null);
  // Tracks the modal to create a Formations filter
  const [choosingFormations, setChoosingFormations] = useState(false);

  useTrainApiSetFormations({
    loading: organizationProps.loading,
    organization: organizationProps.organization,
    setFormations
  });

  const formationsWithSensors = useNotLoadingMemo(loading || !formations, () => {
    return filter(formation => formationHasCollectionDevices(formation), formations);
  }, [formations]);

  // Creates the most basic TrainRunFilter based
  // TODO for now the initial FormationFilter is empty so that all formations with sensors are allowed
  useUpdateOrCreateFormationFilter({
    loading: loading || !formationsWithSensors,
    parentTrainRunFilter: appProps.appTranRunFilter,
    trainRunFilterWithFormations,
    setTrainRunFilterWithFormations
  });

  const localPropsNotReady = loading || !formations;
  const loadingExplanation = useNotLoadingMemo(loading, () => {
      return {
        'organizationProps.loading': organizationProps.loading,
        formations
      };
    },
    [organizationProps.loading, formations]
  );
  return <RailwayLineDependency {...{
    appProps,
    organizationProps,
    trainProps: {
      ...trainProps,
      formationProps: {
        // Depends directly on organizationProps
        loading: localPropsNotReady,
        loadingExplanation,
        formations, setFormations,
        formationsWithSensors,
        trainRunFilterWithFormations, setTrainRunFilterWithFormations,
        choosingFormations, setChoosingFormations
      }
    }
  }} />;
};
FormationDependency.displayName = 'FormationDependency';
export default FormationDependency;