import { Box, IconButton, Stack, Tooltip } from '@mui/material';
import { toArrayIfNot } from '@rescapes/ramda';
import { SkipNext, SkipPrevious } from '@mui/icons-material';
import {
  always,
  any,
  compose,
  cond,
  divide,
  equals, flip,
  identity,
  ifElse,
  isNil,
  join,
  map,
  mergeAll,
  pick,
  prop,
  T
} from 'ramda';
import { useTranslation } from 'react-i18next';
import { CEMIT_TRAIN_RUN_INTERVAL_BAR_OPACITY } from 'theme/colors.ts';

/**
 *  Shows a draggable train run interval bar
 * @param {Object} trainRunInterval The TrainRunInterval of this bar, currently just used to show the range
 * distance in the tooltip
 * @param {Boolean} isDragLayer. Indicates that this component is the component that is animated during
 * dragging that follows the mouse or finger. It is created temporarily by DraggableTrainRunIntervalBar
 * @param {Object} moverDrag The moverDrag reference from useDrag
 * @param {Object} expanderLeftDrag The expanderLeftDrag reference from useDrag
 * @param {Object} expanderRightDrag The expanderRightDrag reference from useDrag
 * @param {Boolean} moverIsDragging Indicates if the mover expander is actively dragging
 * @param {Boolean} expanderLeftIsDragging Indicates if the left expander is actively dragging
 * @param {Boolean} expanderRightIsDragging Indicates if the right expander is actively dragging
 * @param {Object|[Object]} sx Required to specify width and left
 * @param {String | Number} sx.width The width of the absolutely positioned bar
 * @param {String | Number} sx.left The left of the absolutely position bar
 * @param {Function} maximizeOrRestoreTrainRunIntervalBar Maximizes one or both sides of the bar
 * or retracts it to it's saved value on double-click
 * @returns {JSX.Element}
 */
export const TrainRunIntervalBar = (
  {
    sx = [],
    trainRunInterval,
    isDragLayer = false, moverDrag = null, moverIsDragging = false,
    expanderLeftDrag = null, expanderRightDrag = null, expanderLeftIsDragging = false,
    expanderRightIsDragging = false,
    maximizeOrRestoreTrainRunIntervalBar,
    isMaximized
  }) => {

  const { t } = useTranslation();

  const bgcolor = isDragLayer ? 'trainRunIntervalBar.action.moverDrag' :
    (moverIsDragging || expanderLeftIsDragging || expanderRightIsDragging ? 'orange' : 'trainRunIntervalBar.main');
  const opacity = cond([
    [prop('isDragLayer'), always(0.9)],
    [compose(any(identity), prop('dragging')), always(0.4)],
    [T, always(CEMIT_TRAIN_RUN_INTERVAL_BAR_OPACITY)]
  ])({isDragLayer, dragging: [moverIsDragging, expanderLeftIsDragging, expanderRightIsDragging]});

  /**
   * If the user double-clicks,
   * If distanceRangeKeys is ['left', 'right'}, expand the bar fully or restore to the previous distance range
   * If distanceRangeKeys is ['left'], just expand the left side fully or restore it to the previous left side
   * If distanceRangeKeys is ['right'], just expand the right side fully or restore it to the previous right side
   */
  const handlerBarDoubleClick = distanceRangeKeys => e => {
    e.stopPropagation();
    if (equals(2, e.detail)) {
      maximizeOrRestoreTrainRunIntervalBar(distanceRangeKeys);
    }
  };

  return <Stack direction='row'>
    <Tooltip arrow title={
      join('\n', [
        isMaximized ? t('revertBar') : t('maximizeBar'),
        join(': ', [t('startDistance'), (trainRunInterval.distanceRange.start / 1000).toFixed(1)]),
        join(': ', [t('endDistance'), (trainRunInterval.distanceRange.end / 1000).toFixed(1)]),
        join(': ', [
          t('unmaximizedStartDistance'),
          ifElse(
            isNil,
            always(t('notSet')),
            compose(num => num.toFixed(1), flip(divide)(1000))
          )(trainRunInterval.unmaximizedDistanceRange?.start)
        ]),
        join(': ', [
          t('unmaximizedEndDistance'),
          ifElse(
            isNil,
            always(t('notSet')),
            compose(num => num.toFixed(1), flip(divide)(1000))
          )(trainRunInterval.unmaximizedDistanceRange?.end)
        ])
      ])
    }>
      <Box
        ref={moverDrag}
        onClick={handlerBarDoubleClick(['start', 'end'])}
        sx={[
          {
            position: 'absolute',
            '&:hover': {
              cursor: 'grab'
            },
            cursor: isDragLayer ? 'grabbing' : 'auto',
            borderRadius: '20%',
            border: 1,
            borderColor: 'secondary.main',
            bgcolor,
            opacity,
            zIndex: 1
          },
          ...toArrayIfNot(sx)
        ]}>
        <Tooltip arrow title={t('expandBarLeft')}>
          <IconButton
            ref={expanderLeftDrag}
            onClick={handlerBarDoubleClick(['start'])}
            color={expanderLeftIsDragging ? 'trainRunIntervalBarExtenderDrag' : 'trainRunIntervalBarExtender'}
            sx={{
              opacity: 1,
              padding: 0,
              position: 'absolute',
              top: '-1px',
              zIndex: 2,
              width: '5px',
              left: 0,
              '&:hover': {
                cursor: 'w-resize'
              },
              height: mergeAll(map(pick(['height']), toArrayIfNot(sx)))
            }}>
            <SkipPrevious fontSize='12' />
          </IconButton>
        </Tooltip>
        <Tooltip arrow title={t('expandBarRight')}>
          <IconButton
            ref={expanderRightDrag}
            onClick={handlerBarDoubleClick(['end'])}
            color={expanderRightIsDragging ? 'trainRunIntervalBarExtenderDrag' : 'trainRunIntervalBarExtender'}
            sx={{
              opacity: 1,
              padding: 0,
              position: 'absolute',
              top: '-1px',
              zIndex: 2,
              width: '5px',
              right: 0,
              '&:hover': {
                cursor: 'e-resize'
              },
              height: mergeAll(map(pick(['height']), toArrayIfNot(sx)))
            }}>
            <SkipNext fontSize='12' />
          </IconButton>
        </Tooltip>
      </Box>
    </Tooltip>
  </Stack>;
};