import {DependencyProps} from '../../../../railbedTypes/propTypes/dependencyProps';
import {MapSourceVisual} from '../../../../railbedTypes/mapbox/mapSourceVisual';
import {MapLayerMouseEvent, Popup} from 'mapbox-gl';
import {ChartPayloadItemMinimized} from '../../../../railbedTypes/dataVisualizations/chartPayloadItem';
import {
    TrainRunImuPointFeaturePropertiesWithDerived
} from '../../../../railbedTypes/featureProperties/trainRunFeatureProperties';

import {useNotLoadingSetterEffect} from '../../../../railbedUtils/hooks/useMemoHooks.ts';
import {setMapboxSourceAndLayersSets} from '../../../../railbedUtils/map/mapboxSourceUtils.ts';
import {singlePointFeatureFromPayload} from "../../../../railbedUtils/dataFeatures/dataFeaturePayloadUtils.ts";
import {railwayLineMapboxSourcesAndLayersSets} from "../../../../railbedAppUtils/cemitAppUtils/railwayLineUtils.ts";
import {RailwayLine} from "../../../../railbedTypes/railways/railwayLine";
import {Perhaps} from "../../../../railbedTypes/typeHelpers/perhaps";


/**
 * Call railwayLineMapboxSourcesAndLayersSets and update/creates the resulting sources on the trainMap
 * @param loading Do nothing if true
 * @param trainProps
 * @param trainProps.railwayLineProps
 * @param trainProps.railwayLineProps.railwayLines The railwayLines whose tracks we create sources for
 * @param mapProps
 * @param mapProps.trainMap The Mapbox map
 * @param t The translation function
 */
export const useRailwayLineMapboxSourcesAndLayers = (
  {
    dependencyProps: {
      trainProps,
      mapProps
    },
    loading

  }: { dependencyProps: DependencyProps, loading: boolean }) => {
  // TODO we used to used ordered tracks, now we need the railwayLines of the TrainRouteOrGroup,
  // This is passing all railways
  const railwayLines: Perhaps<RailwayLine[]> = trainProps.rideComfortRailwayLineProps.railwayLines
  // trainProps.trainRouteOrGroupProps.trainRouteOrGroup.orderedTracks
  useNotLoadingSetterEffect(
    {loading},
      ({railwayLines}: { railwayLines: RailwayLine[] }) => {
          return railwayLineMapboxSourcesAndLayersSets({railwayLines})
      },
    {railwayLines},
    (mapSourceVisuals: MapSourceVisual[]) => {
      // railwayLineMapboxSourcesAndLayersSets produces a source and one or more layers
      // for each RailwayLine Track. Set Mapbox to them here
      setMapboxSourceAndLayersSets({mapboxMap: mapProps.trainMap, mapSourceVisuals, zoomToSources: true});
    },
    [trainProps.rideComfortRailwayLineProps.railwayLines]
  );

  /*
  TODO this icon doesn't work. Copy the code of trainMap.addImage('trains-highlighted', imgHighlighted) instead,
  which did work at some point
  useNotLoadingEffect(loading, () => {
    const img = new Image(20, 20);
    img.onload = () => {
      mapProps.trainMap.addImage('track-switch', img);
      setTrainSwitchLoaded(true);
    };
    img.src = TrackSwitchIconSvg;
  });
  */

  // TrainPage switches layer
  // useNotLoadingSetterEffect(
  //   { loading: loading || !trainSwitchLoaded },
  //   trackSwitchesMapboxSourcesAndLayersSets,
  //   { trainProps },
  //   (mapSourceVisuals: MapSourceVisual[]) => {
  //     // railwayLineMapboxSourcesAndLayersSets produces a source and one or more layers
  //     // for each RailwayLine Track. Set Mapbox to them here
  //     setMapboxSourceAndLayersSets({ mapboxMap: mapProps.trainMap, mapSourceVisuals, zoomToSources: false });
  //     setMapboxOnHover({
  //       appProps,
  //       organizationProps,
  //       trainProps,
  //       mapProps,
  //       dataProps: {
  //         mapSourceVisuals,
  //         createOnHover: trackSwitchesMapboxSourcesAndLayersSetsOnClick,
  //         // Only disable the switch popup on click
  //         onClickOnly: true
  //       }
  //     });
  //   },
  //   [trainProps.railwayLineProps.railwayLines]
  // );
};

/**
 * Popup to describe a railway switch feature point
 * @param mapProps
 * @param eventProps
 * @param payload
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const trackSwitchesMapboxSourcesAndLayersSetsOnClick = (
  {mapProps, eventProps}: DependencyProps & {
    eventProps: {
      hoveredStateId: string,
      e: MapLayerMouseEvent,
      popup: Popup
    }
  },
  payload: ChartPayloadItemMinimized[]
): void => {
  eventProps.popup.remove();

  const feature = singlePointFeatureFromPayload(payload);
  const properties: TrainRunImuPointFeaturePropertiesWithDerived = feature?.properties as TrainRunImuPointFeaturePropertiesWithDerived;
  // TODO Convert to React component
  const description = `Direction: ${properties.direction}<br/>Type: ${properties.attribute}<br/>Km: ${properties.km}`;
  // Populate the popup and set its coordinates
  // based on the feature found.
  eventProps.popup.setLngLat(eventProps.e.lngLat).setHTML(description).addTo(mapProps.trainMap);
};
