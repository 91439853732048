import {Box, IconButton, Typography, Tooltip} from "@mui/material";
import {CalendarToday} from "@mui/icons-material";
import {tempColors} from "../../../../theme/tempColors.ts";
import {getDate} from "date-fns";
import React from "react";

const RideComfortNowButton = ({setChosenDateTime, mostRecentDate}) => {
    return <Tooltip key='calendarDayTooltip' arrow title={'Update to current time'}>
        <Box>
            <IconButton {...{
                edge: 'end',
                sx: {
                    color: tempColors.yellow
                },
                onClick: () => {
                    setChosenDateTime(mostRecentDate);
                }
            }}>
                <CalendarToday {...{
                    // Disable if the start and date match mostRecentDate
                    color: tempColors.white
                }} />
                <Box {...{
                    position: 'absolute',
                    // Positions the number in the frame of the calendar icon
                    top: '29%',
                    width: '100%', height: '100%'
                }}>
                    <Typography {...{variant: 'subtitle2', color: 'primary'}}>{getDate(mostRecentDate)}</Typography>
                </Box>
            </IconButton>
        </Box>
    </Tooltip>
}
export default RideComfortNowButton
