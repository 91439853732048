import { forwardRef, useImperativeHandle, useRef } from 'react';

/**
 * Forwards a ref to two refs, a grid and axis, which are both
 * used as references to an SVG structure of two clipPaths.
 * The axis clipPath is rectangle that stores the current clipping
 * area for the x-axis and the grid clip path is a rectangle that
 * stores the current clipping area for the grid.
 *
 * I don't know what I mean by xAxis and grid yet, but will explain here
 * when I find out
 */
const RechartsClipPaths = forwardRef((_, ref) => {
  const grid = useRef(null);
  const axis = useRef(null);
  useImperativeHandle(ref, () => ({
    grid,
    axis
  }));

  return (
    <>
      <clipPath id='chart-xaxis-clip'>
        <rect fill='rgba(0,0,0,0)' height='100%' ref={axis} />
      </clipPath>
      <clipPath id='chart-grid-clip'>
        <rect fill='rgba(0,0,0,0)' height='100%' ref={grid} />
      </clipPath>
    </>
  );
});
RechartsClipPaths.displayName = 'RechartsClipPaths'
export default RechartsClipPaths