import {
  any,
  complement,
  concat,
  differenceWith,
  equals,
  filter,
  findIndex,
  groupBy,
  has,
  head,
  indexBy,
  is,
  join,
  length,
  lensIndex,
  lensProp,
  map,
  none,
  not,
  over,
  prop,
  reduce,
  set,
  uniqBy
} from 'ramda';
import { useMemo } from 'react';
import { useNotLoadingMemo } from 'utils/hooks/useMemoHooks.js';
import { mergeDeep, strPathOr } from '@rescapes/ramda';

// The saved list of TrainRunIntervals, including what their usage is.
// A sample trainRunIntervalSaved item is
// {
//  trainRunInterval,
//  activity {isActive: true|false} // indicates if the trainRunInterval is currently being compared to another
// }

const remove = ({ equality, list, setList }, obj) => {
  const updated = differenceWith(equality, list, [obj]);
  setList(updated);
};
const clear = ({ setList }) => {
  setList([]);
};
/**
 * Update based on equality or create if the object doesn't equal anything in the list
 * @param equality
 * @param list
 * @param setList
 * @param merge The merge function to merge an existing instance with a new one
 * @param objs
 * @returns {Object} The updated/created objects
 */
export const updateOrCreate = ({ equality, list, setList, merge }, objs) => {
  // In order to be matching everything currently has to be equal,
  // which makes this essentially a no-op since we insert the same
  // thing as was already there, but at least it prevents duplicates
  const { true: matchingItems = [], false: nonMatchingItems = [] } = groupBy(
    obj => {
      return any(existing => equality(obj, existing), list);
    },
    objs
  );
  const updatedList = concat(
    reduce(
      (accum, matchingItem) => {
        const index = findIndex(existing => equality(matchingItem, existing), list);
        // Update the existing object in the list of userTrainRunIntervals
        return over(
          lensIndex(index),
          existing => {
            // Use the configured merge function for the type
            // This will eventually merge recursively similarly to apollo-cache
            const merged = merge(existing, matchingItem);
            return merged;
          },
          accum
        );
      },
      list,
      matchingItems
    ),
    nonMatchingItems
  );
  setList(updatedList);
  const idLookup = indexBy(prop('sourceKey'), objs);
  return filter(item => has(idLookup, item), updatedList);
};

/**
 * Memoized to only run once
 * Simple crud methods for manipulating state that is a list of objects.
 * TODO this needs an id function to match incoming objects with existing, such as (existing, incoming) => propEq('sourceKey', existing, incoming)
 * Right now it just does a deep compare with ramda.equals
 * @param {Object} config
 * @param {Function} config.equality binary function expecting an incoming object and an existing. Returns true
 * if the objects are considered equal, other false
 * @param {Object} [additionalOperations] Optional additional operations, keyed by operation name and valued by
 * a function that always receives the crud object as it's first argument and can use any number of other arguments
 * @param {[Object]} list useState getter or similar
 * @param {Function} setList useState setter
 * @param {Function} setListCrud Function to store this crud object
 * @param {Function} [postSetList] Default null. Post processing to call after setting
 * @param {Function} [merge] Binary function to call on an existing and new value of the same id.
 * Defaults to mergeDeep, merging deep objects but not arrays
 * @param {[*]} additionalDependencies These serve as additional dependencies for the useMemo in addition to list and
 * are also passed as the first argument to postInit if the latter is defined. Functions in additionalDependencies
 * are filtered out and not used as dependencies.
 * @returns {{set, addOrUpdate: (function(*=): void), get, clear: (function(): void), removeUserTrainRunInterval: (function(*): void)}}
 */
export const useEffectCreateListCrud = (
  {
    equality = equals,
    additionalOperations = {},
    list,
    setList,
    listCrud,
    setListCrud,
    postSetList,
    merge = mergeDeep,
    additionalDependencies = []
  }) => {

  // Filter out functions. They are passed to postInit but not used as dependencies.
  const dependencies = filter(complement(is)(Function), additionalDependencies);

  return useMemo(() => {
    // If list or any additionalDependencies are null or nothing has changed in the list, do nothing
    if (!list || any(not, dependencies)) {
      return listCrud;
    }
    const _setList = newList => {
      const uniqueList = uniqBy(prop('sourceKey'), newList);
      // It should not be possible to insert duplicates, but if it happens, warn and remove them
      if (length(newList) !== length(uniqueList)) {
        console.warn(`Duplicates found: ${join(', ', map(prop('sourceKey'), newList))}`);
      }
      setList(uniqueList);
      if (postSetList) {
        postSetList(additionalDependencies, uniqueList);
      }
    };
    const crud = {
      list,
      set: objs => {
        return setList(objs);
      },
      updateOrCreate: obj => {
        return head(updateOrCreate({ equality, list, setList: _setList, merge }, [obj]));
      },
      updateOrCreateAll: objs => {
        return updateOrCreate({ equality, list, setList: _setList, merge }, objs);
      },
      // Syncs the list items with id-based references located at strPath of the item
      // E.g. strPath: 'foo.bar' and references: [{id:1, ...barStuff}, {id:2, ...barStuff}]
      // updates each item's item.foo.bar = reference with matching id if one is found
      // TODO not currently used
      syncReferences: (strPath, idField, references) => {
        const referencesById = indexBy(prop(idField), references);
        const objsAndMatches = map(
          item => {
            const reference = strPathOr(false, strPath, item);
            // Update the item if it's reference id is among the references
            const match = reference && strPathOr(false, reference[idField], referencesById);
            return [match ? set(strPath, referencesById[reference[idField]], item) : item, match];
          },
          list
        );
        if (none(objsAndMatches => objsAndMatches[1], objsAndMatches)) {
          return list;
        }
        return updateOrCreate(
          { equality, list, setList: _setList, merge },
          map(objsAndMatches => objsAndMatches[0], objsAndMatches)
        );
      },
      remove: obj => {
        return remove({ equality, list, setList: _setList }, obj);
      },
      clear: () => {
        return clear({ list, setList: _setList });
      }
    };
    setListCrud({
      ...crud,
      // Pass crud as the first argument to any additionalOperations functions that are defined
      ...map(func => {
        return (...args) => {
          return func(crud, ...args);
        };
      }, additionalOperations)
    });
  }, [list, ...dependencies]);
};

/**
 * Applies a filter the crudList.list so that items are limited.
 * TODO this is currently done by filtering the list but need to be done as a query in the future to deal with
 * large data
 * @param {Function} filterItemFunc Unary filter function to apply to each item of crudList.list
 * @param {Object} crudList
 * @param {[Object]} crudList.list The list being filtered
 * @returns {Object} a copy of crudList with a list property that returns the filtered items
 */
export const useFilterCrudList = (filterItemFunc, crudList) => {
  return useMemo(() => {
      return filterCrudList(filterItemFunc, crudList);
    },
    [crudList.list]
  );
};

/**
 * useFilterCrudList that returns null does nothing if loading is true
 * @param {Boolean} loading Returns null if true
 * @param {Function} filterItemFunc Unary filter function to apply to each item of crudList.list
 * @param {Object} crudList
 * @param {[Object]} crudList.list The list being filtered
 * @returns {Object} a copy of crudList with a list property that returns the filtered items
 */
export const useNotLoadingFilterCrudList = (loading, filterItemFunc, crudList) => {
  return useNotLoadingMemo(loading, () => {
      return filterCrudList(filterItemFunc, crudList);
    },
    [crudList]
  );
};

export const filterCrudList = (filterItemFunc, crudList) => {
  return over(
    lensProp('list'),
    list => {
      return filter(filterItemFunc, list);
    },
    crudList
  );
};

export const useLimitedCrudList = (list, crudList) => {
  return useMemo(() => {
      return limitedCrudList(list, crudList);
    },
    [crudList]
  );
};

export const limitedCrudList = (list, crudList) => {
  return set(
    lensProp('list'),
    list,
    crudList
  );
};

/**
 * Memoized filtering of a list
 * @param filterItemFunc
 * @param list
 * @returns {*}
 */
export const useFilterList = (filterItemFunc, list) => {
  return useMemo(() => {
      return filter(filterItemFunc, list);
    },
    [list]
  );
};