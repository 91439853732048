import { useNotLoadingEffect } from 'utils/hooks/useMemoHooks.js';
import { useResolveTrainApiData } from 'async/trainAppAsync/hooks/trainApiHooks/trainApiHooks.js';

/**
 * Resolves the Formations from the API
 * @param {Boolean} loading If true do nothing
 * @param {Object} organization The client
 * @param {Function} setFormations Setter
 */
export const useTrainApiSetFormations = ({ loading, organization, setFormations }) => {
  const { data: formations } = useResolveTrainApiData(
    {
      loading,
      organization,
      method: 'formations'
    }
  );
  useNotLoadingEffect(loading,
    () => {
      if (formations) {
        setFormations(formations);
      }
    },
    [formations]
  );
};