import { Stack, Typography } from '@mui/material';

import UserTrainRunIntervals
  from 'components/apps/trainAppComponents/trainRunChooserComponents/UserTrainRunIntervals.js';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { USER_TRAIN_RUN_INTERVAL_MAX_ACTIVE_COUNT } from 'appConfigs/trainConfigs/trainConfig.js';
import { toggleUserTrainRunIntervalVisibility } from 'appUtils/trainAppUtils/typeCrud/userTrainRunIntervalCrud.js';

/**
 * The collection of UserTrainRunIntervals, meaning TrainRuns chosen by the user at for certain distance
 * intervals for comparison. The same run may appear multiple times for different intervals and different times/dates
 * @prop {Boolean } loading,
 * @prop {Object} sx
 * @prop {Object} trainProps
 * @prap {Boolean} [displaySavedRuns] Default true. Set false to hide saved runs
 * @prop {Object} trainRoute The current TrainRoute
 * @pram {Object} client
 * @returns {null}
 * @constructor
 */
const UserTrainRunIntervalYardActive = (
  {
    appProps,
    organizationProps,
    trainProps,
    componentProps: {
      crudSelectedUserTrainRunIntervals
    }
  }) => {

  const { t } = useTranslation();
  return <Stack spacing={0.5} sx={{
    height: '100%',
    maxHeight: '100%'
  }}>
    <Typography
      key='selectedRuns'
      {...{
        variant: 'subtitle2',
        sx: { color: 'secondary.main' }
      }}
    >
      {`${t('selectedRuns')} (${t('limitedTo')} ${USER_TRAIN_RUN_INTERVAL_MAX_ACTIVE_COUNT})`}
    </Typography>
    <UserTrainRunIntervals {...{
      appProps,
      organizationProps,
      trainProps: {
        ...trainProps,
        userTrainRunIntervalProps: {
          ...trainProps.userTrainRunIntervalProps,
          crudUserTrainRunIntervals: crudSelectedUserTrainRunIntervals
        }
      },
      componentProps: {
        isSelectedTrainRuns: true,
        toggleUserTrainRunIntervalVisibility: userTrainRunInterval => {
          toggleUserTrainRunIntervalVisibility(
            { crudSelectedUserTrainRunIntervals, userTrainRunInterval }
          );
        }
      }
    }}
    />
  </Stack>;
};

UserTrainRunIntervalYardActive.propTypes = {
  appProps: PropTypes.object.isRequired,
  organizationProps: PropTypes.object.isRequired,
  trainProps: PropTypes.object.isRequired,
  componentProps: PropTypes.shape({
    crudSelectedUserTrainRunIntervals: PropTypes.object.isRequired
  }).isRequired
};
export default UserTrainRunIntervalYardActive;