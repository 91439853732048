import React, {useRef} from 'react';
import RideComfortApp from 'components/apps/trainAppComponents/rideComfortComponents/RideComfortApp.tsx';
import {Box, Stack} from "@mui/material";
import RideComfortTrainsComponent
    from "../../../components/apps/trainAppComponents/rideComfortComponents/RideComfortTrainsComponent.tsx";
import {
    RideComfortChoosersComponent
} from "../../../components/apps/trainAppComponents/rideComfortComponents/RideComfortChoosersComponent.tsx";
import RideComfortMain from "../../../components/apps/trainAppComponents/rideComfortComponents/RideComfortMain.tsx";
import {useNotLoadingEffect} from "../../../visualizer-railbed/railbedUtils/hooks/useMemoHooks.ts";

export default function RideComfortPage({appProps, trainProps, mapProps}) {
    const {
        loading,
        trainInfos,
        chosenDateTime, setChosenDateTime,
        intervalDescription, setIntervalDescription, intervalDescriptions,
        rideComfortAlertType, setRideComfortAlertType, rideComfortAlertTypes,
        setHeatMapData,
        trainInfo, setTrainInfo,
        date,
        getTotalCount,
        totalMapDataValues,
        queryRideComfortApiAndMutate,
        rideComfortGraphqlDataLoading
    } = trainProps.rideComfortProps
    // Resize the map the first time this component renders. Only relevant in the old visualizer case with multiple tabs
    const count = useRef(null);
    useNotLoadingEffect(loading, () => {
        if (count.current == null) {
            mapProps.trainMap && mapProps.trainMap.resize();
        }
        return () => {
            if (mapProps.trainMap) {
                count.current = 1;
            }
        }
    }, [mapProps.trainMap]);
    if (loading) {
        return undefined
    }
    return <RideComfortApp>
        <Stack {...{
            direction: 'row', key: 'pageAndMap',
            sx: {
                minHeight: 0, overflow: 'hidden', position: 'relative',
                height: '100%'
            }
        }}>
            <Stack {...{
                direction: 'column', key: 'headerAndContent',
                sx: {
                    minHeight: 0, overflow: 'hidden', position: 'relative',
                    height: '100%',
                    flex: 2,
                    justifyContent: 'stretch'
                }
            }}
            >
                <Stack {...{
                    direction: 'row',
                    spacing: 1,
                    sx: {
                        justifyContent: 'stretch',
                        minHeight: 0, overflow: 'hidden', position: 'relative',
                        height: '100%',
                        marginLeft: '5px',
                        marginRight: '5px',
                    }
                }}>
                    <Stack {...{
                        spacing: 1,
                        sx: {
                            justifyContent: 'center',
                            minHeight: 0, overflow: 'hidden', position: 'relative',
                            width: '100%',
                            marginLeft: '5px',
                            marginRight: '5px',
                            height: '100%'
                        }
                    }}>
                        <RideComfortChoosersComponent {...{
                            chosenDateTime,
                            setChosenDateTime,
                            intervalDescription,
                            setIntervalDescription,
                            intervalDescriptions,
                            rideComfortAlertType,
                            setRideComfortAlertType,
                            rideComfortAlertTypes,
                            rideComfortGraphqlDataLoading
                        }}/>

                        <Stack {...{
                            direction: 'row',
                            spacing: 1,
                            sx: {
                                minWidth: 0, minHeight: 0, flex: 1, overflow: 'hidden', position: 'relative',
                                height: '100%'
                            }
                        }}>
                            <Box {...{sx: {flex: 1}}} >
                                <RideComfortTrainsComponent {...{
                                    appProps,
                                    trainProps,
                                    trainInfos,
                                    trainInfo,
                                    setTrainInfo,
                                    queryRideComfortApiAndMutate,
                                    date,
                                    getTotalCount,
                                    setHeatMapData,
                                    totalMapDataValues,
                                    intervalDescription,
                                    rideComfortAlertType
                                }}/>
                            </Box>
                            <Box {...{sx: {flex: 3}}}>
                                <RideComfortMain {...{trainProps, mapProps}}/>
                            </Box>
                        </Stack>
                    </Stack>

                </Stack>
            </Stack>
        </Stack>
    </RideComfortApp>
}
