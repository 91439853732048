import { join, map, zipWith } from 'ramda';
import { forwardRef } from 'react';
import { unlessLoadingProps } from 'utils/componentLogic/loadingUtils.js';
import {
  namesOfFormationExtremeVehicles,
  userTrainRunIntervalUniqueLabel
} from 'appUtils/trainAppUtils/formationUtils.js';

import StackedDataPathComparisonChartContainer
  from 'components/charts/stackedChart/StackedDataPathComparisonChartContainer.js';
import { trackDataFeatures } from 'components/charts/stackedChart/stackedChartUtils.js';
import { useTranslation } from 'react-i18next';

/**
 *
 * This Component shows a stacked chart, mostly based on DashboardContext, TODO these props should be passed
 * into the component instead of coming from a context. Context props:
 *       track, trackCompare, reconstructedTrack, reconstructedTrackCompare, comparisonLabelPair, toWeek
 * See DashboardContextProvider for documentation of these props
 * @param {Object} appProps
 * @param {Object} trainProps
 * @param {[Object]} componentProps
 * @param {Number} componentProp.yAxisWidth SampleChart y-axis width in pixels
 * @param {[Object]} componentProps.dataPathsConfigs the paths and labels to the data to display in each of the two trackData objects.
 * A unary function can also be used that expects the {geometry, properties, type} (Feature) object and computes the value
 * e.g. {dataPath: 'properties.acceleration.sumMaxMean', label: 'Mean Acceleration'}
 * @param sx
 * @returns {JSX.Element}
 */
const TrainDataPathStackedChartContainer = forwardRef((
  {
    appProps,
    trainProps,
    componentProps: {
      yAxisWidth,
      dataPathsConfigs,
      trainDataPathStackedChartRefWidth
    }
  }, ref
) => {

  const { t } = useTranslation();
  // The sx for each chart
  const chartSx = {
    height: 170,
    width: '100%'
  };
  // Depends on userTrainRunIntervalProps.geojson being done loading
  const loading = trainProps.userTrainRunIntervalProps.geojsonProps.loading;

  const chartProps = unlessLoadingProps(loading, () => {
    const activeUserTrainRunIntervals = trainProps.userTrainRunIntervalProps.activeUserTrainRunIntervalsWithoutErrors;

    // Label formattings
    const userTrainRunIntervalsWithFormatting = map(
      userTrainRunInterval => {
        return {
          userTrainRunInterval,
          formationsName: join(', ', namesOfFormationExtremeVehicles(userTrainRunInterval.trainRunInterval.trainRun.trainFormation)),
          label: userTrainRunIntervalUniqueLabel({ t, userTrainRunInterval }),
          color: userTrainRunInterval.activity.isActiveColor,
          isVisible: userTrainRunInterval.activity.isVisible
        };
      },
      activeUserTrainRunIntervals
    );

    const comparisonTrackDataSets = zipWith(
      (formatting, trainRunIntervalGeojson) => {
        return { data: trainRunIntervalGeojson, featuresPath: 'features', ...formatting };
      },
      userTrainRunIntervalsWithFormatting,
      trainProps.userTrainRunIntervalProps.geojsonProps.trainRunIntervalGeojsons
    );
    const trackDataFeatureSets = map(
      comparisonTrackDataSet => {
        return { ...comparisonTrackDataSet, features: trackDataFeatures(comparisonTrackDataSet) };
      },
      comparisonTrackDataSets
    );

    return {
      trackDataFeatureSets,
      comparisonTrackDataSets
    };
  });

  const loadingExplanation = trainProps.userTrainRunIntervalProps.geojsonProps.loadingExplanation;
  return <StackedDataPathComparisonChartContainer
    {...{
      ref,
      appProps,
      trainProps,
      componentProps: {
        loading,
        loadingExplanation,
        yAxisWidth,
        dataPathsConfigs,
        trainDataPathStackedChartRefWidth,
        ...chartProps
      },
      sx: chartSx
    }} />;
});
TrainDataPathStackedChartContainer.displayName = 'TrainDataPathStackedChartContainer';
export default TrainDataPathStackedChartContainer;