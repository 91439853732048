import React, {useEffect, useState} from 'react';

import RideComfortDependency from "./RideComfortDependency.tsx";

import {RailwayLineProps, RailwayLinePropsWithDerived} from "visualizer-railbed/railbedTypes/propTypes/railwayLineProps.ts";
import {RailwayLine} from "visualizer-railbed/railbedTypes/railways/railwayLine";
import {
    useTrainApiSetRailwayLines
} from "visualizer-railbed/railbedAsync/cemitAppAsync/cemitAppHooks/cemitApiHooks/trainApiRailwayLineHooks.ts";
import {mergeTrainProps} from "visualizer-railbed/railbedAppUtils/cemitAppUtils/cemitAppTypeMerging/trainPropsMerging.ts";
import {typeObject} from "visualizer-railbed/railbedAppUtils/typeUtils/typenameUtils.ts";
import {asCemitTypeOrThrow, maybeAsCemitType} from "visualizer-railbed/railbedClasses/cemitClassResolvers.ts";
import {CemitTypename} from "visualizer-railbed/railbedTypes/cemitTypename.ts";
import {doesOrganizationHaveServiceLines} from "visualizer-railbed/railbedUtils/organization/organizationUtils.ts";
import {TrainDependencyPropsWithRideComfortProps} from "../../../types/rideComfort/trainPropsWithRideComfortProps";

/**
 * Loads updates RailwayLine props in trainProps.railwayLineProps
 * Depends directly on trainProps.serviceLineProps
 * @param appProps
 * @param organizationProps
 * @param trainProps
 * @param children
 * @return {*}
 * @constructor
 */
const RideComfortRailwayLineDependency = ({appProps, organizationProps, trainProps}: TrainDependencyPropsWithRideComfortProps) => {

        const loading = trainProps.dateProps.loading

        const organizationHasServiceLines = doesOrganizationHaveServiceLines(
            loading,
            organizationProps.organization
        )

        // The unique physical railway lines of the organization's serviceLines
        // These contain the geojson we need to render the lines on the map
        const [railwayLines, setRailwayLines] = useState<RailwayLine[] | undefined>(undefined);

        // Load the physical RailwayLines and augment them with frontend track computations
        // useTrainApiSetRailwayLines({
        //     loading,
        //     serviceLines: trainProps.serviceLineProps.serviceLines,
        //     railwayLines: trainProps.rideComfortRailwayLineProps.railwayLines,
        //     setRailwayLines
        // });
        useEffect(() => {
           if (trainProps.rideComfortRailwayLineProps.railwayLines && trainProps.rideComfortRailwayLineProps.railwayLines != railwayLines) {
               setRailwayLines(trainProps.rideComfortRailwayLineProps.railwayLines)
           }
        }, [trainProps.rideComfortRailwayLineProps.railwayLines])
        const localPropsNotReady = loading || !railwayLines || organizationHasServiceLines ? !railwayLines : false;
        const loadingExplanation = {
            'trainProps.serviceLineProps.loading': trainProps.serviceLineProps.loading,
            ...organizationHasServiceLines ?
                {railwayLines} :
                {}
        };

        const mergedTrainProps = mergeTrainProps(
            trainProps,
            'rideComfortRailwayLineProps',
            typeObject<Omit<RailwayLineProps, 'loading'>>(CemitTypename.railwayLineProps, {
                loadingExplanation,
                railwayLines,
                setRailwayLines,
                maybeAsDerived: () => {
                    return maybeAsCemitType<RailwayLinePropsWithDerived>(CemitTypename.railwayLinePropsWithDerived, this);
                },
                asDerivedOrThrow: () => {
                    asCemitTypeOrThrow<RailwayLinePropsWithDerived>(CemitTypename.railwayLinePropsWithDerived, this);
                }
            }),
            // No new TrainRunFilter
            undefined,
            localPropsNotReady,
            loadingExplanation
        )
        return <RideComfortDependency {...{
            appProps,
            organizationProps,
            trainProps: mergedTrainProps
        }} />;
    }
;
RideComfortRailwayLineDependency.displayName = 'RideComfortRailwayLineDependency';
export default RideComfortRailwayLineDependency;
