// Default tolerances
// TODO define styles in MUI theme
import { map, prop } from 'ramda';
import { colors } from 'theme/colors.ts';

/**
 * Data threshold config
 * @param {Function} t Translation function for the human-readable label
 * @returns {[{sourceKey: string, style: {color: string}, label}, {sourceKey: string, style: {color: string}, label}, {sourceKey: string, style: {color: string}, label}]}
 * @constructor
 */
export const dataThresholdConfig = ({ t }) => {
  return [
    { sourceKey: 'good', label: t('good'), style: { color: colors.green } },
    { sourceKey: 'warning', label: t('warning'), style: { color: colors.orange } },
    { sourceKey: 'error', label: t('error'), style: { color: colors.reallyRed } }
  ];
};

/**
 * Default acceleration thresholds. TODO values are made up and not currently in use
 * @param t
 * @returns {[Object]} Thresholds in the form
 * { sourceKey: 'good', label: t('good'), style: { color: 'green' }, unit: 'kmh^2', value: 5 },
 * @constructor
 */
export const defaultAccelerationThresholds = ({ t }) => {
  // TODO configure
  const valueLookup = {
    good:  5000,
    warning: 7000,
    error: 9000
  }
  return map (
    threshold => {
      return {...threshold, unit: 'kmh^2', value: prop(threshold.sourceKey, valueLookup)}
    },
    dataThresholdConfig({t})
  )

};
export const MAP_PITCH = 50;
export const TRAIN_MAP_PITCH = MAP_PITCH;

// Multiply extrusion columns on the map by this factor to make them more visible
export const EXTRUSION_HEIGHT_FACTOR = 200;
export const TRAIN_RUN_POSITION_SOURCE = 'train-run-position-source';