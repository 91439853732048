export const numberAxisData = [
  { name: 1, cost: 25.5, impression: 100 },
  { name: 2, cost: 25.39, impression: 120 },
  { name: 3, cost: 18.37, impression: 150 },
  { name: 4, cost: 18.16, impression: 180 },
  { name: 5, cost: 26.29, impression: 200 },
  { name: 6, cost: 39, impression: 499 },
  { name: 7, cost: 50.53, impression: 50 },
  { name: 8, cost: 82.52, impression: 100 },
  { name: 9, cost: 91.79, impression: 200 },
  { name: 10, cost: 52.94, impression: 222 },
  { name: 11, cost: 84.3, impression: 210 },
  { name: 12, cost: 54.41, impression: 300 },
  { name: 13, cost: 2.1, impression: 50 },
  { name: 14, cost: 58, impression: 190 },
  { name: 15, cost: 20, impression: 300 },
  { name: 16, cost: 19, impression: 400 },
  { name: 17, cost: 36, impression: 200 },
  { name: 18, cost: 92, impression: 50 },
  { name: 19, cost: 83, impression: 100 },
  { name: 20, cost: 78, impression: 100 }
];

export const chartNumericAxisProps = {
  x: {
    dataKey: "name",
    type: "number",
    scale: "linear",
    allowDataOverflow: true,
    allowDecimals: false,
    interval: 0
  }
};

export const lines = [
  {
    dataKey: "impression",
    strokeWidth: 1,
    name: "impression",
    stroke: "green",
    connectNulls: true
  },
  {
    dataKey: "cost",
    strokeWidth: 1,
    name: "impression",
    stroke: "blue",
    connectNulls: true
  }
];


