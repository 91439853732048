// These variables are written in by Webpack
import {useMemo} from 'react';
import {
    DataThreshold,
    DataThresholdStyles,
    ZoomLevelValue
} from "../../visualizer-railbed/railbedTypes/dataVisualizations/dataThreshold";
import {
    RideComfortAttributeAlertLevel,
    RideComfortAttributeAlertSValue
} from "../../types/rideComfort/rideComfortAlertLevel.ts";
import {useTranslation} from "react-i18next";


export const ridcComfortDataThresholds = (i18n: any) => {
    const {t} = useTranslation()
    return useMemo(() => {
        return [
            {
                sourceKey: RideComfortAttributeAlertLevel.normal,
                label: t('good'),
                // Below RideComfortAttributeAlertSValue.warning
                value: RideComfortAttributeAlertSValue.none,
                style: {
                    color: '#6FCF97',
                    icon: 'ok',
                    size: [
                        // Always hidden
                        {zoom: 0, outputValue: 0} as ZoomLevelValue,
                        {zoom: 22, outputValue: 0} as ZoomLevelValue,
                    ]
                } as Partial<DataThresholdStyles>
            } as DataThreshold,
            {
                sourceKey: RideComfortAttributeAlertLevel.warning,
                label: t('warning'),
                // from RideComfortAttributeAlertSValue.warning to RideComfortAttributeAlertSValue.critical
                value: RideComfortAttributeAlertSValue.warning,
                style: {
                    color: '#F2C94C',
                    icon: 'warning',
                    size: [
                        // For value 2, show these outputValues for the icon at each zoom level
                        {zoom: 0, outputValue: 0} as ZoomLevelValue,
                        {zoom: 8, outputValue: 0} as ZoomLevelValue,
                        {zoom: 16, outputValue: 0} as ZoomLevelValue,
                    ]
                } as Partial<DataThresholdStyles>
            } as DataThreshold,
            {
                sourceKey: RideComfortAttributeAlertLevel.critical,
                label: t('error'),
                // All values above RideComfortAttributeAlertSValue.critical
                value: RideComfortAttributeAlertSValue.critical,
                style: {
                    color: '#EB5757',
                    icon: 'error',
                    size: [
                        // For value 2, show these outputValues for circle-radius at each zoom level
                        {zoom: 8, outputValue: 0.5} as ZoomLevelValue,
                        {zoom: 8, outputValue: 1} as ZoomLevelValue,
                        {zoom: 16, outputValue: 2} as ZoomLevelValue,
                    ]
                } as Partial<DataThresholdStyles>
            } as DataThreshold,
        ];
    }, [i18n])
}
