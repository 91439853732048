import {Box, Stack, Tooltip, Typography} from '@mui/material';
import {tooltipClasses} from '@mui/material/Tooltip/index.js';
import {useTranslation} from 'react-i18next';
import {compactEmpty, toArrayIfNot} from '@rescapes/ramda';
import {always, isNil, join, length, map, when} from 'ramda';
import {stringify} from 'utils/json/jsonUtils.js';
import {CEMIT_GRAY_ON_DARK} from "theme/colors.ts";

export const Loader = ({ size, show }) => {
  return <Box {...{
    sx: {
      display: show ? `block` : `none`,
      border: `5px solid ${CEMIT_GRAY_ON_DARK}`,
      borderRadius: '50%',
      borderTop: '5px solid #f6c90e',
      width: size ? `${size}px` : '20px',
      height: size ? `${size}px` : '20px',
      padding: 0,
      alignSelf: 'center',
      animation: 'spin 2s linear infinite',

      '@keyframes spin': {
        '0%': {
          transform: 'rotate(0deg)'
        },
        '100%': {
          transform: 'rotate(360deg)'
        }
      }
    }
  }} />;
};

/**
 *
 * @param {Object} Optional sx
 * @param {String|[String]} text Set to empty string to show nothing. Set null to show t('loading'). Set to
 * any label to send to t of { t } = useTranslation();
 * @param {Boolean} [spinner] Default false, show a spinnser
 * @param {Object} loadingExplanation Default null For local env only. Shows a dump of the given object
 * to explain what is loading
 * @return {JSX.Element}
 * @constructor
 */
const LoaderWithText = ({ sx = [], text, spinner = false, loadingExplanation = null }) => {
  const { t } = useTranslation();
  const texts = join(
    ', ',
    map(
      str => {
        return t(str);
      },
      compactEmpty(toArrayIfNot(when(isNil, always('loading'))(text)))
    )
  );
  const title = ((true || APP_ENV === 'local') && loadingExplanation) ?
    <pre> {stringify(loadingExplanation, 2, null, 2)} </pre> :
    t('loading');

  return <Tooltip {...{
    sx: {
      // TODO axWidth does not work
      [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 'none'
      },
    }, arrow: true, title
  }} >
    <Stack spacing={1} sx={[{ }, ...toArrayIfNot(sx)]} direction='row'>
      {length(text) ? <Typography key='label' color='secondary' variant='caption'>{texts}</Typography> : null}
      {spinner && <Loader key='loader' show />}
    </Stack>
  </Tooltip>;
};
export default LoaderWithText;