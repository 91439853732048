import { useEffect } from 'react';
import merge from '@mui/system/legacy/merge.js';
import { head } from 'ramda';
import { useResolveTrainApiData } from 'async/trainAppAsync/hooks/trainApiHooks/trainApiHooks.js';

/**
 * Retrieves the Client from the TrainApi based on the client sourceKey
 * @param {Boolean} loading If true, do nothing
 * @param organization
 * @param clientSourceKey
 * @param setOrganization
 */
export const useTrainApiClient = ({ loading, organization, setOrganization }) => {
  // Resolve the client data
  const { data: clientData } = useResolveTrainApiData(
    {
      loading,
      organization,
      method: 'client'
    }
  );
  useEffect(
    () => {
      if (clientData) {
        // Merge the static values from frontend with the queried values from the backend
        setOrganization(merge(organization, head(clientData)))
      }
    },
    [clientData]
  );
};