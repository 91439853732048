import {FeatureU, PointU} from "../../visualizer-railbed/railbedTypes/geometry/geojsonUnions";
import {RideComfortHeatMapDatum} from "../../types/rideComfort/rideComfortHeatMapDatum";
import {compose, filter, map} from "ramda";

/**
 * Just removes values that are null and returns the remaining points
 * @param rideComfortHeatMapData
 */
export const getValidPoints = (rideComfortHeatMapData: RideComfortHeatMapDatum[]): FeatureU<PointU>[] => {
    return compose(
        map((datum: RideComfortHeatMapDatum) => datum.point),
        rideComfortHeatMapData => filter(
            ({numericValue}: RideComfortHeatMapDatum) => {
                return numericValue != 0 && !isNaN(numericValue)
            },
            rideComfortHeatMapData
        ),
    )(rideComfortHeatMapData)
}
