import { Grid, Stack } from '@mui/material';
import React, { forwardRef } from 'react';

import UserTrainRunAddButton
  from 'components/apps/trainAppComponents/trainRunChooserComponents/UserTrainRunAddButton.js';
import TrainRunLineContainer from 'components/apps/trainAppComponents/trainLineComponents/TrainRunLineContainer.js';
import PropTypes from 'prop-types';
import TrainRunDescriptionContainer
  from 'components/apps/trainAppComponents/trainRunChooserComponents/TrainRunDescriptionContainer.js';


// Only Once in your app you can set whether to enable hooks tracking or not.
// In CRA(create-react-app) e.g. this can be done in src/index.js

/**
 * A run of a train, meaning a specific train an time and service name if the service is revenue service
 * Can also be the representation of the Route without specific times
 * @param appProps
 * @param organizationProps
 * @param trainProps
 * @param trainProps.trainRun The specific TrainRun being displayed
 * @param componentProps
 * @param componentProps.addButtonProps
 * @returns {JSX.Element}
 * @constructor
 */
const TrainRun = forwardRef((
  {
    appProps,
    organizationProps,
    trainProps,
    componentProps: {
      addButtonProps,
      panelSize
    }
  }, ref) => {
  return <Stack
    ref={ref}
    spacing={2}
    sx={{
      borderRadius: 2,
      border: 1,
      p: 1
    }}
  >
    <TrainRunDescriptionContainer {...{
      trainProps,
      componentProps: {
        buttons: [
          <UserTrainRunAddButton key='addButton' {...addButtonProps} />
        ],
        showDate: true,
        isBaseline: false,
        userTrainRunInterval: null,
        showTrainRunLine: true
      }
    }}
    />
    <Grid item>
      <TrainRunLineContainer {...{
        appProps,
        organizationProps,
        trainProps,
        componentProps: {
          onlyStopsNearInterval: false,
          spaceGeospatially: false,
          limitedDistanceRange: null,
          // We no longer show interval bars on TrainRuns
          showTrainRunIntervalBars: false,
          isTrainRouteLine: false,
          isUserTrainRunLine: false,
          panelSize,
        }
      }} />
    </Grid>
  </Stack>;
});
TrainRun.propTypes = {
  trainProps: PropTypes.shape({
    trainRun: PropTypes.object
  }).isRequired,
  componentProps: PropTypes.shape({
    addButtonProps: PropTypes.object.isRequired
  }).isRequired
};
TrainRun.displayName = 'TrainRun'
export default TrainRun;
