import React from 'react';
import { equals, head, length } from 'ramda';
import { useNotLoadingFilterCrudList } from 'utils/hooks/crudHooks.js';
import TrainRun from 'components/apps/trainAppComponents/trainRunChooserComponents/TrainRun.js';
import { setFilteredCrudOnTrainProps } from 'appUtils/trainAppUtils/trainRunLineUtils.js';
import { unlessLoadingProps } from 'utils/componentLogic/loadingUtils.js';
import LoaderWithText from 'components/loading/LoaderWithText.js';
import PropTypes from 'prop-types';
import { onlyOneValueOrThrow } from 'utils/functional/functionalUtils.js';
import { USER_TRAIN_RUN_INTERVAL_MAX_ACTIVE_COUNT } from 'appConfigs/trainConfigs/trainConfig.js';

/**
 * A run of a train, meaning a specific train an time and service name if the service is revenue service
 * Depends most directly on trainProps.userTrainRunIntervalProps.
 * @param appProps
 * @param organizationProps
 * @param trainProps
 * @param trainProps.trainRunProps
 * @param trainProps.trainRunProps.trainRun The TrainRun in scope
 * @param trainProps.trainRunIntervalProps
 * @param trainProps.trainRunIntervalProps.crudTrainRunIntervals All TrainRunIntervals
 * @param trainProps.userTrainRunIntervalProps
 * @param trainProps.userTrainRunIntervalProps.crudUserTrainRunIntervals All UserTrainRunIntervals
 * @param trainProps.trainRouteProps
 * @param trainProps.trainRouteProps.trainRoute The current TrainRoute
 * @returns {JSX.Element}
 * @constructor
 */
const TrainRunContainer = (
  {
    appProps,
    organizationProps,
    trainProps,
    componentProps: {
      panelSize
    }
  }) => {

  const loading = trainProps.userTrainRunIntervalProps.loading;

  const trainRun = trainProps.trainRunProps.trainRun;

  // Update the list to be filtered by the current trainRun

  const filteredCrudTrainRunIntervals = useNotLoadingFilterCrudList(
    loading,
    trainRunInterval => {
      return equals(trainRun.id, trainRunInterval.trainRun.id);
    },
    trainProps.trainRunIntervalProps.crudTrainRunIntervals
  );

  // Filter the crud list to only container the one or zero UserTrainRunInterval/s for this TrainRun
  // We use this to add a UserTrainRunInterval if the user clicks the add button
  const filteredCrudUserTrainRunIntervals = useNotLoadingFilterCrudList(
    loading,
    userTrainRunInterval => equals(trainRun.id, userTrainRunInterval.trainRunInterval.trainRun.id),
    trainProps.userTrainRunIntervalProps.crudUserTrainRunIntervals
  );

  const childComponentProps = unlessLoadingProps(loading, () => {
    // Disable the add button if the TrainRun is already in the active runs
    // TODO currently disable if the active runs are full so the client
    // isn't confused
    const addButtonProps = {
      disabled: !!length(filteredCrudUserTrainRunIntervals.list) ||
        length(trainProps.userTrainRunIntervalProps.activeUserTrainRunIntervals || []) >= USER_TRAIN_RUN_INTERVAL_MAX_ACTIVE_COUNT,
      organization: organizationProps.organization,
      crudUserTrainRunIntervals: trainProps.userTrainRunIntervalProps.crudUserTrainRunIntervals,
      // null if not yet a userTrainRunInterval, else the already created UserTrainRunInterval for this TrainRunInterval
      userTrainRunInterval: head(filteredCrudUserTrainRunIntervals.list),
      trainRunInterval: onlyOneValueOrThrow(filteredCrudTrainRunIntervals.list),
      trainRoute: trainProps.trainRouteProps.trainRoute
    };
    return {
      addButtonProps,
      panelSize
    };
  });

  return loading ?
    <LoaderWithText {...{ sx: { visibility: 'hidden' }, text: 'loadingTrainRuns' }} /> :
    <TrainRun {...{
      appProps,
      organizationProps,
      // Constrain the TrainRunIntervals and UserTrainRunIntervals
      trainProps: setFilteredCrudOnTrainProps({
        filteredCrudTrainRunIntervals,
        filteredCrudUserTrainRunIntervals,
        trainProps,
      }),
      componentProps: childComponentProps
    }} />;
};

TrainRunContainer.propTypes = {
  appProps: PropTypes.object.isRequired,
  organizationProps: PropTypes.object.isRequired,
  // filteredCrudTrainRunIntervals.list can't be empty unless we are loading
  trainProps: PropTypes.shape({
    trainRunProps: PropTypes.shape({
      trainRun: PropTypes.object.isRequired
    }).isRequired
  }).isRequired
};

export default TrainRunContainer;