import { useMemo, useState } from 'react';
import { all, always, includes, length, map } from 'ramda';
import { useEffectCreateCrudTrainRunIntervals } from 'appUtils/trainAppUtils/trainRunIntervalUtils.js';
import { unlessLoadingValue } from 'utils/componentLogic/loadingUtils.js';
import UserTrainRunIntervalDependency from 'async/trainAppAsync/dependencies/UserTrainRunIntervalDepedency.js';
import { useCreateCrudTrainRunIntervals } from 'async/trainAppAsync/hooks/typeHooks/userTrainRunIntervalHooks.js';
import { reqStrPathThrowing } from '@rescapes/ramda';
// import { useWhatChanged } from '@simbathesailor/use-what-changed';

/**
 * Loads/Updates TrainRunInterval props in trainProps.trainRunIntervalProps
 * Depends directly on TrainRun props in trainProps.trainRunProps
 * @param appProps
 * @param organizationProps
 * @param trainProps
 * @param children
 * @return {*}
 * @constructor
 */
const TrainRunIntervalDependency = ({ appProps, organizationProps, trainProps, mapProps }) => {

  const loading = trainProps.trainRunProps.loading;

  // A list of objects where each object combines a TrainRun, DateInterval, and Usage, where Usage
  // indicates if the DraggableTrainRunIntervalBar is one of the TrainRun Intervals being actively compared or whether
  // its simply in the user's saved list. SelectedTrainRunIntervals can also have TrainRun set to null
  // to indicate that they are a template DateInterval created on the template TrainRunLine
  const [trainRunIntervals, setTrainRunIntervals] = useState([]);
  const [crudTrainRunIntervals, setCrudTrainRunIntervals] = useState(null);
  useEffectCreateCrudTrainRunIntervals(
    trainRunIntervals,
    setTrainRunIntervals,
    crudTrainRunIntervals,
    setCrudTrainRunIntervals
  );

  const loadingExplanation = useMemo(always({
    'trainProps.trainRunProps.loading': trainProps.trainRunProps.loading,
    'trainProps.trainRunProps.loadingExplanation': trainProps.trainRunProps.loadingExplanation
  }), [loading]);
  // Created every time the TrainRuns change
  useCreateCrudTrainRunIntervals({
    loading,
    loadingExplanation,
    trainRoutes: trainProps.trainRouteProps.trainRoutes,
    trainRouteInterval: trainProps.trainRouteProps.trainRouteInterval,
    trainRuns: trainProps.trainRunProps.trainRuns,
    railwayLines: trainProps.trainRunProps.railwayLines,
    dateInterval: trainProps.dateProps.dateInterval,
    crudTrainRunIntervals
  });

  // When we switch Routes, it's possible for the crudTrainRunIntervals to be out of sync with the trainRuns
  const trainRunsLoaded = !!unlessLoadingValue(loading, () => {
    const trainRuns = trainProps.trainRunProps.trainRuns;
    const crudTrainRunsIds = map(reqStrPathThrowing('trainRun.id'), crudTrainRunIntervals.list);
    const trainRunsLoaded = !!(length(trainProps.trainRunProps.trainRuns) && all(
      trainRun => includes(trainRun.id, crudTrainRunsIds),
      trainRuns
    ));
    return trainRunsLoaded;
  });

  // Update the TrainRuns of the crud objects
  // TODO This is because we replace the TrainRuns rather than mutate them.
  // It would be better to have a reference system via Apollo caching or similar
  const localPropsNotReady = loading || !trainRunsLoaded;
  return <UserTrainRunIntervalDependency {...{
    appProps,
    organizationProps,
    trainProps: {
      ...trainProps,
      trainRunIntervalProps: {
        loading: localPropsNotReady,
        trainRunIntervals, setTrainRunIntervals,
        crudTrainRunIntervals
      }
    },
    mapProps
  }} />;
};
TrainRunIntervalDependency.displayName = 'TrainRunIntervalDependency';
export default TrainRunIntervalDependency;