import React, {useEffect, useRef} from 'react';
import {F, filter, indexBy, lensPath, over, prop, propOr, set} from 'ramda';
import LoaderWithText from 'components/loading/LoaderWithText.js';
import TrainOverview from 'components/apps/trainAppComponents/trainOverviewComponents/TrainOverview.js';
import {useFilterCrudList} from 'utils/hooks/crudHooks.js';
import {reverseTrainRouteOrGroup} from 'appUtils/trainAppUtils/trainRouteUtils.js';
import {unlessLoadingValue} from 'utils/componentLogic/loadingUtils.js';


/**
 * The runs tab for the trains application, which shows a list of trains that can be selected
 * Depends directly on trainProps.dateProps
 * @param {Object} appProps,
 * @param {Object} organizationProps,
 * @param {Object} trainProps,
 * @returns {JSX.Element}
 */
const TrainOverviewContainer = (
    {
        appProps,
        organizationProps,
        trainProps,
        mapProps,
        componentProps: {
            panelSize
        }
    }) => {
    const loading = trainProps.dateProps.loading;
    const count = useRef(null);
    useEffect(() => {
        if (count.current == null) {
            mapProps.trainMap && mapProps.trainMap.resize();
        }
        return () => {
            if (mapProps.trainMap) {
                count.current = 1;
            }
        }
    }, [mapProps.trainMap]);

    const modifiedTrainProps = unlessLoadingValue(loading || !trainProps.trainRunProps.trainRuns, () => {
        const outsideFilterIdLookup = indexBy(prop('id'), trainProps.trainRunProps.outsideFilterTrainRuns || []);
        // Remove the TrainRuns that are from the user's saved runs but don't match the current filter
        return over(
            lensPath(['trainRunProps', 'trainRuns']),
            trainRuns => {
                // Filter out trainRuns that are stored in the user's saved TrainRuns but do not match the filter
                return filter(
                    trainRun => !propOr(false, trainRun.id, outsideFilterIdLookup),
                    trainRuns
                );
            },
            trainProps
        );
    }) || trainProps;

    // We don't want any TrainRunIntervals for the TrainRoute version of the TrainRunLine
    // It uses trainProps.trainRouteProps.trainRouteInterval
    const trainPropsWithoutTrainRunIntervals = set(
        lensPath(['trainRunIntervalProps', 'crudTrainRunIntervals']),
        useFilterCrudList(
            F,
            trainProps.trainRunIntervalProps.crudTrainRunIntervals
        ),
        trainProps
    );


    const handleDrawerOpen = () => {
        appProps.setTrainFilterOpen(true);
    };

    const handleDrawerClose = () => {
        appProps.setTrainFilterOpen(false);
    };

    /**
     * Called when the user changes the TrainRouteOrGroup.
     * @param trainRoute
     */
    const chooseTrainRouteOrGroup = trainRoute => {
        trainProps.trainRouteProps.setTrainRoute(trainRoute);
    };

    const _reverseTrainRouteOrGroup = trainRouteOrGroup => {
        reverseTrainRouteOrGroup({
                trainRoutesOrGroups: trainProps.trainRouteProps.trainRoutesOrGroups,
                setTrainRouteOrGroup: trainProps.trainRouteProps.setTrainRoute
            }, trainRouteOrGroup
        );
    };

    if (loading) {
        return <LoaderWithText {...{
            text: 'loadingTrainOverview',
            loadingExplanation: trainProps.dateProps.loadingExplanation
        }} />;
    } else {
        return <TrainOverview {...{
            appProps,
            organizationProps,
            trainProps: modifiedTrainProps,
            componentProps: {
                trainPropsWithoutTrainRunIntervals,
                reverseTrainRouteOrGroup: _reverseTrainRouteOrGroup,
                chooseTrainRouteOrGroup,
                handleDrawerOpen,
                handleDrawerClose,
                panelSize
            }
        }} />;
    }
};
export default TrainOverviewContainer;