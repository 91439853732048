import { IconButton } from '@mui/material';
import { RemoveCircleOutline } from '@mui/icons-material';

/**
 * Removes the UserTrainRunInterval form the crudUserTrainRunIntervals
 * @param isSelectedTrainRuns
 * @param userTrainRunInterval
 * @param crudUserTrainRunIntervals
 * @param [disabled] Default false
 * @returns {JSX.Element}
 * @constructor
 */
const UserTrainRunRemoveButton = ({ isSelectedTrainRuns, userTrainRunInterval, crudUserTrainRunIntervals, disabled=false }) => {
  return <IconButton
    key='removeButton'
    disabled={disabled}
    color='remove'
    aria-label='remove'
    sx={{
      float: 'right', alignItems: 'flex-start', p: 0
    }}
    onClick={() => {
      if (!isSelectedTrainRuns) {
        throw new Error('Not implemented for non-selected runs')
      }
      crudUserTrainRunIntervals.remove(userTrainRunInterval);
    }}
  >
    <RemoveCircleOutline />
  </IconButton>
};
export default UserTrainRunRemoveButton;