import { useDrag } from 'react-dnd';
import { equals } from 'ramda';

/**
 *
 * @param {Object} item Values to give the CustomDragLayer and Drop target
 * @param {Number|String} item.id Required id to match item on
 * @param {String} type the name of the supported drag type
 * @returns {*}
 */
export const useMoverDrag = ({ item, type }) => {
  return useDrag(() => {
    return {
      type,
      // These props are given to the CustomDragLayer and Drop target
      item,
      options: {
        dropEffect: 'move',
        // Note workaround for critical bug in react-dnd https://github.com/react-dnd/react-dnd/issues/3345
        // We must add dependencies to the spec options to get the connector to reconnect
        item
      },
      isDragging: monitor => {
        return monitor.getItem()?.id === item.id;
      },
      collect: (monitor) => {
        const offset = {
          getInitialSourceClientOffset: monitor.getInitialSourceClientOffset,
          getDifferenceFromInitialOffset: monitor.getDifferenceFromInitialOffset,
          getSourceClientOffset: monitor.getSourceClientOffset
        };

        return {
          isDragging: monitor.isDragging(),
          offset,
        };
      }
    };
  }, [item]);
};
/**
 * Monitors expanding of an draggable object. The useDrag memoization depends on the reference value of item, so whenever item
 * changes useDrag will be refreshed with the new item.
 * @param {String} side 'left' or 'right' to indicate what side of the bar the expander is on.
 * @param {Object} item Values to give the CustomDragLayer and Drop target
 * @param {Number|String} item.id Required id to match item on
 * @param {String} The type handled by the drag. Should be specific to the side
 */
export const useExpanderDrag = ({ side, item, type }) => {
  return useDrag(() => {
    return {
      side,
      // Type is based on the side of the expander.
      type,
      // These props are given to the CustomDragLayer and Drop target
      item,
      options: {
        dropEffect: 'move',
        // Note workaround for critical bug in react-dnd https://github.com/react-dnd/react-dnd/issues/3345
        // We must add dependencies to the spec options to get the connector to reconnect
        item
      },
      isDragging: monitor => {
        return monitor.getItem()?.id === item.id;
      },
      collect: (monitor) => {
        return {
          isDragging: monitor.isDragging()
        };
      }
    };
  }, [item]);
};


/**
 * Constrains the bar to the x axis and calculates the y-offset based on the difference between
 * the initial and current x offsets
 *
 * @param config
 * @param {String} constrain 'x', 'y', or null/undfefined
 * @param {Object} initialOffset Initial x, y position
 * @param {Object} currentOffset Current x, y position
 * @returns {{x, y}|{x: number, y}}
 */
export const getMaybeConstrainedOffset = ({ constrain }, initialOffset, currentOffset) => {
  const { x: initialX, y: initialY } = initialOffset
  const { x, y } = currentOffset;
  return {
    x: equals('x', constrain) ? initialX: x - initialX,
    y: equals('y', constrain) ? initialY : y - initialY
  };
};