import {Box, Button, Slide, Stack} from '@mui/material';
import {not} from 'ramda';
import {ArrowBackRounded, ArrowForwardRounded} from '@mui/icons-material';
import TrainMap from 'pages/trainApp/mapbox/TrainMap.js';
import TrainBoard from 'pages/trainApp/trainBoard/TrainBoard.js';
import UserTrainRunIntervalYardContainer
    from 'components/apps/trainAppComponents/trainRunChooserComponents/UserTrainRunIntervalYardContainer.js';

/**
 * Train is the main component of the Train App. It delegates
 * to different tabs (pages) and displays common data to all tabs
 * @return {JSX.Element}
 * @constructor
 */
const Train = (
    {
        appProps,
        organizationProps,
        trainProps,
        mapProps
    }) => {



    // [ <Slide><TrainBoard/></Slide> (collapsable to <UserTrainRunIntervalYard>) | <TrainMap/> ]
    // TrainBoard display non-map data. TrainMap is a Mapbox map
    // TrainBoard collapses left using Slide and a small <UserTrainRunIntervalYard> takes its place along
    // with the expanded TrainMap
    return <Stack direction='row' sx={{
        maxHeight: '100vh',
        overflowY: 'hidden',
        overflowX: 'hidden',
        position: 'relative'
    }}>
        <Slide
            mountOnEnter={true}
            unmountOnExit={true}
            direction='right'
            in={
                !appProps.collapsed
            }
            onChange={
                (e) => {
                    // Hack change type comes from the DateTimePicker
                    if (e.type !== 'change') {
                        appProps.setCollapsed(not)
                    }
                }
            }
            addEndListener={() => {
                // Set a timeout because the Slide child is not actually unmounted quite yet :/
                // If this is problematic then put a ref on the child and check when its unmounted
                mapProps.trainMap && setTimeout(() => {
                    mapProps.trainMap.resize();
                }, 500);
            }}
            sx={{
                minWidth: appProps.expanded ? '95%' : '65%',
                height: '100%',
                zIndex: 2
            }}>
            <Box
                sx={{
                    maxHeight: '100vh',
                    height: '100vh',
                    overflowY: 'hidden',
                    overflowX: 'hidden'
                }}
            >
                { /* Renders the page component of the active app page */}
                <TrainBoard {...{
                    appProps,
                    organizationProps,
                    trainProps,
                    mapProps,
                    sx: {width: '100%'}
                }}/>
            </Box>
        </Slide>
        { /* Only renders when TrainBoard is collapsed with Slide */
        }
        {
            appProps.collapsed &&
            <UserTrainRunIntervalYardContainer key='chosenTrainRunYard' {...{
                appProps,
                organizationProps,
                trainProps,
                sx: {
                    zIndex: 100,
                    position: 'absolute',
                    left: 0,
                    overflow: 'hidden',
                    height: '400px',
                    width: '400px',
                    bottom: 0
                }
            }}
            />
        }
        { /* TODO this styling should be cleaned up. It's "legacy" */
        }
        <Box
            sx={{
                flexGrow: 1,
                width: '100%',
                position: 'relative',
                maxHeight: '100%',
                height: '100%'
            }}>
            <Box sx={{
                position: 'absolute',
                paddingTop: '10px',
                paddingLeft: '10px',
                float: 'left',
                width: '30px',
            }}>
                <Stack spacing={1} direction='row' sx={{
                    width: 'inherit',
                    minWidth: 'inherit',
                    minHeight: 'inherit',
                    height: 'inherit',
                    zIndex: 100,
                    borderRadius: '100%'
                }}>
                    {!appProps.expanded && <Button
                        variant='outlined'
                        key='LeftExpandCollapseButton'
                        color='secondary'
                        aria-label='left expand/collapse'
                        sx={{
                            width: 'inherit',
                            minWidth: 'inherit',
                            minHeight: 'inherit',
                            height: 'inherit',
                            borderRadius: '100%',
                            zIndex: 100
                        }}
                        onClick={(e) => {
                            // Hack change type comes from the DateTimePicker
                            if (e.type !== 'change') {
                                appProps.setCollapsed(not)
                            }
                        }}
                    >
                        {appProps.collapsed ?
                            <ArrowForwardRounded/> :
                            <ArrowBackRounded/>
                        }
                    </Button>
                    }
                    {!appProps.collapsed && <Button
                        variant='outlined'
                        key='RightExpandCollapseButton'
                        color='secondary'
                        aria-label='right expand/collapse'
                        sx={{
                            width: 'inherit',
                            minWidth: 'inherit',
                            minHeight: 'inherit',
                            height: 'inherit',
                            borderRadius: '100%',
                            zIndex: 100
                        }}
                        onClick={() => {
                            appProps.setExpanded(not);
                        }}
                    >
                        {appProps.expanded ?
                            <ArrowBackRounded/> :
                            <ArrowForwardRounded/>
                        }
                    </Button>
                    }
                </Stack>
            </Box>
            <TrainMap {...{
                appProps,
                organizationProps,
                trainProps,
                mapProps,
                sx: {
                    ...appProps.expanded ? {width: 0} : {},
                    height: appProps.displayProps.windowSize.height
                }
            }}
            />
        </Box>
    </Stack>
};

export default Train;
