import { useFilterCrudList } from 'utils/hooks/crudHooks.js';
import { equals, length } from 'ramda';
import UserTrainRunVisibilityButton
  from 'components/apps/trainAppComponents/trainRunChooserComponents/UserTrainRunVisibilityButton.js';
import { compact } from '@rescapes/ramda';
import { setFilteredCrudOnTrainProps } from 'appUtils/trainAppUtils/trainRunLineUtils.js';
import UserTrainRunLine from 'components/apps/trainAppComponents/trainRunChooserComponents/UserTrainRunLine.js';
import UserTrainRunRemoveButton from './UserTrainRunRemoveButton.js';
import UserTrainRunErrorButton
  from 'components/apps/trainAppComponents/trainRunChooserComponents/UserTrainRunErrorButton.js';
import { trainRunOfUserTrainRunInterval } from 'appUtils/trainAppUtils/userTrainRunIntervalUtil.js';

/**
 * A TrainRunLine with specific user settings about the distance range of interest
 * @param appProps
 * @param organizationProps
 * @param trainProps
 * @param userTrainRunInterval
 * @param isSelectedTrainRuns
 * @param toggleUserTrainRunIntervalVisibility
 * @returns {JSX.Element}
 * @constructor
 */
const UserTrainRunLineContainer = (
  {
    appProps,
    organizationProps,
    trainProps,
    componentProps: {
      isSelectedTrainRuns,
      toggleUserTrainRunIntervalVisibility
    }
  }) => {
  const loading = trainProps.userTrainRunIntervalProps.loading;
  const userTrainRunInterval = trainProps.userTrainRunIntervalProps.userTrainRunInterval;
  const color = userTrainRunInterval.activity.isActiveColor;
  const trainRun = userTrainRunInterval.trainRunInterval.trainRun;
  const sxTrainRunIntervalBar = isSelectedTrainRuns ? {
    backgroundColor: color
  } : {};

  // Filter by the TrainRun
  const filteredCrudTrainRunIntervals = useFilterCrudList(
    trainRunInterval => {
      return equals(trainRun.id, trainRunInterval.trainRun.id);
    },
    trainProps.trainRunIntervalProps.crudTrainRunIntervals
  );
  // Filter by the TrainRun
  const filteredCrudUserTrainRunIntervals = useFilterCrudList(
    userTrainRunInterval => equals(trainRun.id, userTrainRunInterval.trainRunInterval.trainRun.id),
    trainProps.userTrainRunIntervalProps.crudUserTrainRunIntervals
  );
  if (length(filteredCrudUserTrainRunIntervals) === 0) {
    throw Error(`No UserTrainRunInterval found in crudUserTrainRunIntervals matching trainRUn id ${trainRun.id}`);
  }
  const filteredTrainProps = setFilteredCrudOnTrainProps({
    filteredCrudTrainRunIntervals,
    filteredCrudUserTrainRunIntervals,
    trainProps
  });

  // TODO We currently don't have any unselected UserTrainRunIntervals, so this is never used
  const activateButton = <UserTrainRunVisibilityButton {...{
    key: 'activateButton',
    disabled: loading,
    toggleUserTrainRunIntervalVisibility,
    userTrainRunInterval
  }}
  />;


  // isPreconfigured TrainRuns can't currently be removed because they automatically reload
  // If automatic reload was disabled, the user would need a way to re-enable them.
  const removeButton = !trainRunOfUserTrainRunInterval(userTrainRunInterval).isPreconfigured &&
    <UserTrainRunRemoveButton {...{
      key: 'removeButton',
      disabled: loading,
      isSelectedTrainRuns,
      userTrainRunInterval,
      crudUserTrainRunIntervals: trainProps.userTrainRunIntervalProps.crudUserTrainRunIntervals
    }} />;

  // If the TrainRun is tagged with an error property, it means the data download failed
  const errorButton = trainProps.trainRunIntervalProps.trainRunInterval.trainRun.error &&
  !trainProps.trainRunIntervalProps.trainRunInterval.trainRun.loading ?
    <UserTrainRunErrorButton {...{
      key: 'errorButton',
      disabled: loading,
      isSelectedTrainRuns,
      userTrainRunIntervals: [userTrainRunInterval],
      crudUserTrainRunIntervals: trainProps.userTrainRunIntervalProps.crudUserTrainRunIntervals
    }}
    /> : null;

  /**
   * TODO this is no longer used. It might be useful for other operators in the future, but Flytoget
   * preconfigures it's baseline runs
   * Toggle the UserTrainRunInterval as the baseline, removing activity.isBaseline from all other
   * UserTrainRunIntervals
   * @param event
   */
  // const handleChangeIsBaseline = event => {
  //   event.stopPropagation();
  //   const trainRunIds = map(
  //     reqStrPathThrowing('trainRunInterval.trainRun.id'),
  //     trainProps.userTrainRunIntervalProps.crudUserTrainRunIntervals.list
  //   );
  //   const index = indexOf(userTrainRunInterval.trainRunInterval.trainRun.id, trainRunIds);
  //   if (index < 0) {
  //     console.warn(`TrainRunId ${userTrainRunInterval.trainRunInterval.trainRun.id} not found in ${join(', ', trainRunIds)}`);
  //     return;
  //   }
  //   // Set all UserTrainRunIntervals.activity.isBaseline to false except the current one, which we toggle true/false
  //   const list = addIndex(map)(
  //     (userTrainRunInterval, i) => {
  //       return over(
  //         lensPath(['activity', 'isBaseline']),
  //         value => {
  //           return equals(index, i) ? !value : false;
  //         },
  //         userTrainRunInterval);
  //     },
  //     trainProps.userTrainRunIntervalProps.crudUserTrainRunIntervals.list
  //   );
  //   trainProps.userTrainRunIntervalProps.crudUserTrainRunIntervals.updateOrCreateAll(list);
  // };

  setFilteredCrudOnTrainProps({
    filteredCrudTrainRunIntervals,
    filteredCrudUserTrainRunIntervals,
    trainProps
  });

  const componentProps = {
    // handleChangeIsBaseline,
    buttons: compact([activateButton, removeButton, errorButton]),
    userTrainRunInterval,
    isSelectedTrainRuns,
    toggleUserTrainRunIntervalVisibility,
    color
  };
  return <UserTrainRunLine key='userTrainRunLine'  {...{
    appProps,
    organizationProps,
    trainProps: filteredTrainProps,
    componentProps,
    sxTrainRunIntervalBar
  }} />;
};
export default UserTrainRunLineContainer;