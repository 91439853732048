import Menu from '@mui/material/Menu/index.js';
import MenuItem from '@mui/material/MenuItem/index.js';
import { useState } from 'react';
import { includes, is, join, map } from 'ramda';
import { namesOfFormationExtremeVehicles } from 'appUtils/trainAppUtils/formationUtils.js';
import { IconButton } from '@mui/material';
import { Train } from '@mui/icons-material';

/**
 * Menu to choose from the available Formations
 * @param formations All available formations
 * @param chosenFormations Formations already chosen for the filter
 * @param handleAddFormationToFilter
 * @returns {JSX.Element}
 * @constructor
 */
export const FormationMenu = ({  formations, chosenFormations, handleAddFormationToFilter }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e, formation) => {
    setAnchorEl(null);
    // if formation comes in as the string 'backDrop',
    // it means the user clicked outside the menu
    if (!is(String, formation)) {
      handleAddFormationToFilter(formation);
    }
  };
  return <div>
    <IconButton
      key='button'
      id='formation-button'
      color='secondary'
      aria-controls={open ? 'formation-positioned-menu' : undefined}
      aria-haspopup='true'
      aria-expanded={open ? 'true' : undefined}
      onClick={handleClick}
    >
      <Train />
    </IconButton>
    <Menu
      key='menu'
      id='formation-positioned-menu'
      aria-labelledby='formation-button'
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
    >
      {
        map(formation => {
            const name = join('/', namesOfFormationExtremeVehicles(formation))
            return <MenuItem
              key={name}
              disabled={includes(formation, chosenFormations)}
              onClick={e => handleClose(e, formation)}>{
              name
            }</MenuItem>;
          },
          formations
        )
      }
    </Menu>
  </div>;
};