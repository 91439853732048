import { Box, IconButton, Stack, Typography } from '@mui/material';
import { Clear } from '@mui/icons-material';
import { toArrayIfNot } from '@rescapes/ramda';
import ImuPointStatsComponentContainer
  from 'components/apps/trainAppComponents/trainRunningCharacteristicsComponents/ImuPointStatsComponentContainer.js';
import { always, cond, length, T } from 'ramda';
import { useTranslation } from 'react-i18next';
import LoaderWithText from 'components/loading/LoaderWithText.js';

/**
 * This container is populate via ReactDOM portals from the chart and map
 * The previous data is maintained until the user clears the the window
 * so that the data does not disappear when the user hovers away from the
 * chart or maps
 * @returns {JSX.Element}
 * @constructor
 */
const ChartAndMapDataContainer = (
  {
    trainProps,
    componentProps: {
      userTrainRunIntervals,
      dataPathsConfigs,
      referenceStopLookup,
      mostRecentTooltipPayload, setMostRecentTooltipPayload
    },
    sx
  }
) => {
  const { t } = useTranslation();
  const loading = trainProps.userTrainRunIntervalProps.geojsonProps.loading;
  return <Stack direction='row' key='chartAndMapDataContainer' {...{
    sx: [
      {
        width: '100%',
        minHeight: '150px',
        minWidth: '100%',
        overflowX: 'auto',
        overflowY: 'auto',
        flexGrow: 100
      },
      ...toArrayIfNot(sx)
    ]
  }}>
    <ImuPointStatsComponentContainer
      key='imuPointStatsComponent'
      {...{
        trainProps,
        componentProps: {
          payloadItems: mostRecentTooltipPayload || [],
          userTrainRunIntervals,
          dataPathsConfigs,
          referenceStopLookup,
        }
      }}
    />
    {
      cond([
        [always(loading), always(<LoaderWithText text='awaitingChartsAndMaps'/>)],
        [length, mostRecentTooltipPayload => {
          return <Box {...{ sx: { maxWidth: '20px', maxHeight: '20px' } }}>
            <IconButton
              key='removeButton'
              color='remove'
              aria-label='remove'
              sx={{
                visibility: mostRecentTooltipPayload ? 'visible' : 'hidden',
                float: 'right', alignItems: 'flex-start', p: 0
              }}
              onClick={() => {
                setMostRecentTooltipPayload(null);
              }}
            >
              <Clear />
            </IconButton>
          </Box>;
        }
        ],
        [T, () => {
          return <Box>
            <Typography color='secondary'>{t('hoverForData')}</Typography>
          </Box>;
        }]
      ])(mostRecentTooltipPayload)}
  </Stack>;
};


export default ChartAndMapDataContainer;