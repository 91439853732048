import {equals, is, map} from "ramda";
import React, {SyntheticEvent, useMemo, useState} from "react";
import {Button, Menu, MenuItem, Stack, Typography} from "@mui/material";
import {tempColors} from "../../../../theme/tempColors.ts";
import {RailwayAlert} from "@mui/icons-material";
import {Setter} from "../../../../visualizer-railbed/railbedTypes/hookHelpers/setter";
import {RideComfortAlertType} from "../../../../types/rideComfort/rideComfortAlertType";

const RideComfortAlertTypePicker = (
    {
        rideComfortAlertType,
        setRideComfortAlertType,
        rideComfortAlertTypes,
    }: {
        rideComfortAlertType: RideComfortAlertType,
        setRideComfortAlertType:  Setter<RideComfortAlertType>,
        rideComfortAlertTypes: RideComfortAlertType[]
    }) => {
    const [anchorEl, setAnchorEl] = useState(undefined);

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const onClose = (_e: SyntheticEvent, alertType: RideComfortAlertType) => {
        setAnchorEl(undefined);
        // if alertType comes in as the string 'backDrop',
        // it means the user clicked outside the menu
        if (!is(String, alertType)) {
            setRideComfortAlertType(alertType)
        }
    }
    const alertTypeComponents = useMemo<JSX.Element[]>(
        () => {
            return map(
                (alertType: RideComfortAlertType) => {
                    return <MenuItem {...{
                        key: alertType.label,
                        disabled: equals(alertType, rideComfortAlertType),
                        onClick: (e: SyntheticEvent) => onClose(e, alertType)
                    }}
                    >{alertType.label}</MenuItem>
                },
                rideComfortAlertTypes
            )
        }, [rideComfortAlertTypes, rideComfortAlertType]
    )

    return <Stack {...{sx: {alignSelf: 'self-start'}}}>
        <Typography {...{
            sx: {
                color: tempColors.white,
                fontSize: '0.8rem',
                lineHeight: '1.4375em', letterSpacing: '0.00938em',
            }
        }}>Alert Type</Typography>
        <Button {...{
            key: 'button',
            id: 'alertType-button',
            color: 'secondary',
            'aria-controls': open ? 'alertType-positioned-menu' : undefined,
            'aria-haspopup': 'true',
            'aria-expanded': open ? 'true' : undefined,
            onClick: handleClick
        }}>
            <Stack {...{direction: 'row', spacing: 1, sx: {alignItems: 'center'}}}>
                <RailwayAlert {...{key: 'icon'}}/>
                <Typography {...{key: 'label'}}>{rideComfortAlertType.label}</Typography>
            </Stack>
        </Button>
        <Menu {...{
            key: 'menu',
            id: 'formation-positioned-menu',
            'aria-labelledby': 'formation-button',
            anchorEl,
            open,
            onClose,
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'left'
            },
            transformOrigin: {
                vertical: 'top',
                horizontal: 'left'
            }
        }}>
            {alertTypeComponents}
        </Menu>
    </Stack>
}
RideComfortAlertTypePicker.displayName = 'RideComfortAlertTypePicker'
export default RideComfortAlertTypePicker
