import { useNotLoadingEffect } from 'utils/hooks/useMemoHooks.js';
import { initStopOffsetLefts } from 'appUtils/trainAppUtils/trainRunLineUtils.js';
import { length } from 'ramda';
import { compact } from '@rescapes/ramda';

/**
 * Initializes the offsetLefts of the stops.
 * If config.spaceGeospatially is true it means we are postioning the stops absoultuely based on their geospatial
 * position along the TrainLine. We can calculate them immediately. Otherwise we are spacing the stops evenly
 * with flex and initialize the state to an empty array matching the number of visible stops. We then rely on
 * the TrainLineStations to call their setOffsetLeft to fill one of the items of this array
 * @param {Boolean} loading Do nothing if loading
 * @param {Boolean} spaceGeospatially if true init the list with absolution positions. Else
 * init to empty and wait to get values
 * @param {Object} limitedDistanceRange If non-null, then the offsetLefts have to be nulled when the
 * distanceRange changes because the position of the stops is changing
 * @param {Object} distanceRange The distanceRange of the TrainRunInterval, used only for geospatially positioning
 * to adjust stops that to 0 or 100% if they are outside the interval
 * are
 * @param {[Object]} scheduledStopPoints The stops we are showing. We create a TrainLineStation for each of these
 * and draw lines between them
 * @param {Function} setOffsetLefts The setter
 * @returns {[Object, Function]} The getter and setter
 */
export const useInitStopOffsetLefts = (
  {
    loading,
    spaceGeospatially,
    limitedDistanceRange,
    distanceRange,
    setOffsetLefts,
    scheduledStopPoints,
    offsetLefts
  }) => {
  useNotLoadingEffect(loading, () => {
    if (!spaceGeospatially && !limitedDistanceRange && length(compact(offsetLefts))) {
      // There's no need to null the offsetLefts if they are non-null and limitedDistanceRange is null
      // because changing distanceRange does nothing to the position of the stops
      return;
    }
    const stopOffsetLefts = initStopOffsetLefts({
      spaceGeospatially,
      limitedDistanceRange,
      distanceRange,
      scheduledStopPoints
    });
    setOffsetLefts(stopOffsetLefts);
  }, [spaceGeospatially, limitedDistanceRange, distanceRange, scheduledStopPoints]);
};