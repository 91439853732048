import { Stack } from '@mui/material';
import React from 'react';
import { addIndex, keys, lensPath, map, mergeRight, over } from 'ramda';
import ImuPointDescription
  from 'components/apps/trainAppComponents/trainRunningCharacteristicsComponents/ImuPointDescription.js';
import PropTypes from 'prop-types';

/**
 *
 * @param payload The Recharts payload, which is a list of geojson features (usually 0 or 1) that the user
 * hovered over
 * @param dataPathsConfigs
 * @param referenceStopLookup
 * @returns {JSX.Element}
 * @constructor
 */
const ImuPointStatsComponent = ({ payloadItems, dataPathsConfigs, referenceStopLookup }) => {
  // Make the flex-items equal
  const width = `${100 / payloadItems.length}%`;
  const imuPointDescriptions = addIndex(map)((payloadItem, index) => {
      const featureIndex = payloadItem.userTrainRunInterval.geojson.featureCollectionPoints.features.indexOf(payloadItem.payload);
      // Resolve the Railway of this feature to resolve the reference stop
      // TODO We should be never missing this attribute, but if we are just assume the that all railways have
      // the same reference stop for now
      const referenceStop = referenceStopLookup[payloadItem.payload.properties.railway || keys(referenceStopLookup)[0]];
      return <ImuPointDescription key={index} {...{
        dataPathsConfigs,
        referenceStop,
        relativePositionPropertyPath: 'properties.s',
        timePropertyPath: 'properties.time',
        payloadItem: over(lensPath(['payload', 'properties']), mergeRight({ featureIndex }), payloadItem),
        sx: {
          flexBasis: width,
          flexGrow: 0
        }
      }} />;
    },
    payloadItems
  );
  return <Stack {...{ direction: 'row', spacing: 1, sx: { flex: `1 1 ${width}` } }}>
    {imuPointDescriptions}
  </Stack>;
};
ImuPointStatsComponent.propTypes = {
  payloadItems: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  dataPathsConfigs: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  referenceStopLookup: PropTypes.shape().isRequired
};

export default ImuPointStatsComponent;