import { useEffect, useMemo } from 'react';
import { concat } from 'ramda';

/**
 * Calls useMemo with the given memoFunc and dependencies but returns
 * null without calling the memoFunc if loading is True.
 * loading is always concatted to the dependencies
 * @param {Boolean} loading
 * @param {Function} memoFunc The func called by useMemo if loading is false
 * @param [dependencies] Default [] The useMemo dependencies, not including loaidng
 * @returns {*}
 */
export const useNotLoadingMemo = (loading, memoFunc, dependencies=[]) => {
  if (typeof(loading) === 'undefined')
    throw Error('loading prop must be either truthy or false, got undefined')
  return useMemo(() => {
    if (loading)
      return null;
    return memoFunc()
  }, concat(dependencies, [loading]))
}

/**
 * Calls useEffect with the given effectFunc and dependencies.
 * Returns before calling effectFunc if loading is false
 * @param {Boolean} loading
 * @param {Function} effectFunc The func called by useMemo if loading is false
 * @param [dependencies] Default [] The useMemo dependencies, not including loaidng
 * @returns {VoidFunction}
 *
 */
export const useNotLoadingEffect = (loading, effectFunc, dependencies = []) => {
  if (typeof (loading) === 'undefined')
    throw Error('loading prop must be either truthy or false, got undefined');
  return useEffect(() => {
    if (loading)
      return;
    return effectFunc();
  }, concat(dependencies, [loading]));
};

/**
 * Calls the given function in an effect with dependencies that only runs if loading is false and calls setter on its results
 * The intention of this function is to separate effects from non-mutating code
 * @param {Boolean} loading If true, do nothing
 * @param {Function} [inProcessSetter] Optional setter that is called with true when the loading is false and effect
 * begins and called with false when the effect ends.

 * @param {Function} func The non-mutating function to call
 * @param {Object} props props for func
 * @param {Function} setter The setter function called with the result of func. This can be a useState setter or
 * a custom mutation function, like for setting layers on a Mapbox map
 * @param {[Object]} dependencies useEffect dependencies
 */
export const useNotLoadingSetterEffect = ({ loading, inProcessSetter }, func, props, setter, dependencies) => {
  useNotLoadingEffect(
    loading,
    () => {
      if (inProcessSetter) {
        inProcessSetter(true);
      }
      const result = func(props);
      setter(result);
      if (inProcessSetter) {
        inProcessSetter(false);
      }
    },
    dependencies
  );
};