import { useEffect } from 'react';
import {
  any,
  compose,
  concat,
  equals,
  filter,
  find,
  flip,
  head,
  includes,
  indexBy,
  isNil,
  join,
  last,
  length,
  lensProp,
  map,
  none,
  prop,
  propOr,
  props,
  set,
  sortBy,
  uniqBy
} from 'ramda';
import { compact, pathOr } from '@rescapes/ramda';
import { endOfDay, isWithinInterval, parseISO, startOfDay } from 'date-fns';
import { useNotLoadingEffect, useNotLoadingMemo } from 'utils/hooks/useMemoHooks.js';
import { idListsEqual, idsEqual } from 'utils/functional/functionalUtils.js';
import { updateTrainRunOfUserTrainRunIntervalsPreMerge } from 'appUtils/trainAppUtils/userTrainRunIntervalUtil.js';
import { unlessLoadingProps } from 'utils/componentLogic/loadingUtils.js';
import { workerizedTrainRunFeatureCollectionSensorPoints } from 'appUtils/trainAppUtils/trainDataUtilsWorkerized.js';
import { useResolveTrainApiData } from 'async/trainAppAsync/hooks/trainApiHooks/trainApiHooks.js';
import { sortTrainRuns } from 'appUtils/trainAppUtils/trainRunUtils.js';
import { trainRoutesOfTrainRouteOrGroup } from 'appUtils/trainAppUtils/trainRouteUtils.js';
import { LOCAL_STORAGE_TRAIN_ROUTE, LOCAL_STORAGE_USER_TRAIN_RUN_INTERVALS } from 'appConfigs/appConfig.js';
import { trainRunFilterDateRange } from 'async/trainAppAsync/hooks/trainRunFilterHooks/trainFilterDateRangeHooks.js';
import { addDateRangeFilters, createDateRangeFilter } from 'appUtils/trainAppUtils/trainFilterDateRangeUtils.js';
import { mergeTrainRunFilters } from 'appUtils/trainAppUtils/trainFilterUtils.js';

/**
 * TODO currently unused since we only load TrainRuns of the current TrainRoute
 * Sets the TrainRuns matching the current route
 * @param {Object} trainRoute The current Route
 * @param {[Object]} allTrainRuns All TrainRuns of the current date interval
 * @param {Function} setTrainRuns Setter
 */
export const useSetTrainRunsOfRoute = ({ trainRoute, allTrainRuns, setTrainRuns }) => {
  useEffect(() => {
    if (!(trainRoute && allTrainRuns)) {
      return;
    }
    const filteredTrainRunsByRoute = filter(
      trainRun => {
        return idsEqual(trainRoute, trainRun.trainRoute);
      },
      allTrainRuns
    );
    setTrainRuns(
      sortTrainRuns(filteredTrainRunsByRoute)
    );
  }, [trainRoute, allTrainRuns]);
};


/**
 * Stores all TrainRuns for the current dateRange and the date-independent baseline TrainRuns
 * @param {Boolean} loading
 * @param {Object} organization
 * @param {Object} filterProps The props to filter by
 * @param {[Object]} filterProps.dateRange The date range
 * @param [{Object}] [filterProps.formations] Limits to these formations if set
 * @param [{Object}] [filterProps.dateRecurrences] Limits to dateRecurrences, which
 * can currently be certain days of the week or departureTimes
 * @param {[Object]} filterProps.trainRoute The TrainRoute or TrainRouteGroup we want TrainRuns for
 * @param {[Objects]} trainRoutes A TrainRoute from here is assigned to TrainRun.trainRoute based
 * on trainRun.journeyPattern.trainRun. The latter lacks derived data about the the trainRoute, like trackData
 * @param {[Object}] trainRuns The existing loaded TrainRuns. Compared to what is loaded to see if
 * we are in a stable state
 * @param {Function} setTrainRuns The TrainRuns setter
 * @param {Object} outsideFilterTrainRuns Used to check if we are in a stable state
 * @param {Function} setOutsideFilterTrainRuns The setter for TrainRuns that need to be loaded that aren't part
 * @param [{Number}] outsideFilterTrainRunIds The ids of TrainRuns that are outside the filter that we need to always load and
 * store in setOutsideFilterTrainRuns. These are loaded after the filter trainRuns if any don't overlap
 */
export const useSetTrainRuns = (
  {
    loading, organization,
    filterProps,
    trainRoutes,
    trainRuns,
    setTrainRuns,
    outsideFilterTrainRuns,
    setOutsideFilterTrainRuns,
    trainRouteTrainRunsWithMaybeTrainRouteOverrides
  }
) => {
  const { dateRange, trainRoute: trainRouteOrGroup, formations, dateRecurrences } = unlessLoadingProps(
    loading,
    () => filterProps
  );
  // Fetch train data for the current dateRange
  const {
    data: newlyLoadedTrainRuns,
    error: trainRunsError
  } = useResolveTrainApiData({
    loading,
    organization,
    method: 'trainRuns',
    trainRoute: trainRouteOrGroup,
    dateRange,
    formations,
    dateRecurrences
  });

  // Get all TrainRunId for the TrainRoute, some of which might be preconfigured an outside the current TrainRoute
  const { maybeOutsideFilterTrainRunIds, maybeOutsideFilterTrainRunIdLookup } = unlessLoadingProps(loading, () => {
      return {
        maybeOutsideFilterTrainRunIds: map(prop('id'), trainRouteTrainRunsWithMaybeTrainRouteOverrides),
        maybeOutsideFilterTrainRunIdLookup: indexBy(prop('id'), trainRouteTrainRunsWithMaybeTrainRouteOverrides)
      };
    }
  );

  // Fetch the outside-filter TrainRuns separately after trainRuns. These are queried by TrainRun id
  // If storedTrainRunIds this should give a null URL
  const missingOutsideFilterTrainRunIds = useNotLoadingMemo(loading || !newlyLoadedTrainRuns || !maybeOutsideFilterTrainRunIds, () => {
    const loadedTrainRunIds = map(prop('id'), newlyLoadedTrainRuns || []);
    return filter(
      trainRunId => !includes(trainRunId, loadedTrainRunIds),
      maybeOutsideFilterTrainRunIds
    );
  }, [newlyLoadedTrainRuns, maybeOutsideFilterTrainRunIds]);

  const {
    data: newlyLoadedOutsideFilterTrainRuns,
    error: outsideFilterTrainRunsError
  } = useResolveTrainApiData({
    loading: loading || !newlyLoadedTrainRuns,
    organization,
    method: 'trainRuns',
    trainRunIds: length(missingOutsideFilterTrainRunIds) ? missingOutsideFilterTrainRunIds : null
  });

  if (outsideFilterTrainRunsError || trainRunsError) {
    if (newlyLoadedTrainRuns) {
      throw new Error('Error fetching trainRuns. TODO, use a retry here for certain error types');
    }
    if (outsideFilterTrainRunsError) {
      throw new Error('Error fetching outside-filter TrainRuns. TODO, use a retry here for certain error types');
    }
  }

  useEffect(() => {
    // We are done if we have trainRuns and either got outsideFilterTrainRuns or there were none to query
    if (newlyLoadedTrainRuns && (equals(0, length(missingOutsideFilterTrainRunIds)) || newlyLoadedOutsideFilterTrainRuns)) {

      // Mark outside filter TrainRuns as isPreconfigured. This limits the ability to remove them.
      const newlyLoadedOutsideFilterTrainRunsMarkedPreconfigured = newlyLoadedTrainRuns && map(
        trainRun => {
          const isPreconfigured = includes(trainRun.id, pathOr([], ['preconfiguredRouteIdToTrainRunIds', trainRun.journeyPattern.trainRouteId], organization));
          return set(lensProp('isPreconfigured'), isPreconfigured, trainRun);
        },
        newlyLoadedOutsideFilterTrainRuns || []
      );
      // Set the outside-filter TrainRuns
      if (!outsideFilterTrainRuns || !idListsEqual(outsideFilterTrainRuns, newlyLoadedOutsideFilterTrainRunsMarkedPreconfigured || [])) {
        setOutsideFilterTrainRuns(newlyLoadedOutsideFilterTrainRunsMarkedPreconfigured || outsideFilterTrainRuns || []);
      }

      // Combine the outside-filter TrainRuns with the others
      const orderedTrainRuns = compose(
        sortBy(prop('departureDateTime')),
        uniqBy(prop('id')),
        concat(
          newlyLoadedOutsideFilterTrainRunsMarkedPreconfigured || []
        )
      )(newlyLoadedTrainRuns);

      if (idListsEqual(trainRuns, orderedTrainRuns)) {
        // Stable state
        return;
      }

      // Get the full version of the TrainRoutes that are in the current TrainRouteOrGroup
      const eligibleTrainRouteById = indexBy(prop('id'), trainRoutesOfTrainRouteOrGroup(trainRouteOrGroup));
      const eligibleTrainRoutes = filter(trainRoute => propOr(false, trainRoute.id, eligibleTrainRouteById), trainRoutes);

      // Add a short-cut to trainRoute. Note that this is not what we filtered on, a TrainRoute or TrainRouteGroup,
      // rather it is the TrainRoute of the TrainRun
      const orderedTrainRunsWithRoute = map(trainRun => {
        // If we set an explicit overrideTrainRouteId for outsideFilterTrainRun, use it. Otherwise use that of the TrainRun.journeyPattern
        const forceTrainRouteId = pathOr(
          null,
          [trainRun.id, 'overrideTrainRouteId'],
          maybeOutsideFilterTrainRunIdLookup
        );
        // Find the matching TrainRoute, either of the default trainRun.journeyPattern.trainRoute or the overridden
        // forceTrainRouteId
        const trainRoute = find(
          eligibleTrainRoute => {
            return idsEqual(forceTrainRouteId ?
                forceTrainRouteId :
                trainRun.journeyPattern.trainRouteId,
              eligibleTrainRoute.id
            );
          },
          eligibleTrainRoutes
        );

        if (!trainRoute) {
          // Indicates that the user changed the TrainRoute whilst one or more of orderedTrainRuns was loading
          // Abandon the loading
          return null;
        }
        return { ...trainRun, trainRoute };
      }, orderedTrainRuns);

      // The User changed TrainRoute during loading of TrainRuns, give up an wait for the TrainRuns of the
      // newly selected TrainRoute
      if (any(isNil, orderedTrainRunsWithRoute)) {
        return;
      }

      const trainRouteIds = map(prop('id'), eligibleTrainRoutes);
      const mismatchingTrainRuns = filter(
        outsideFilterTrainRun => !includes(outsideFilterTrainRun.trainRoute.id, trainRouteIds),
        orderedTrainRunsWithRoute
      );
      if (length(mismatchingTrainRuns)) {
        localStorage.removeItem(LOCAL_STORAGE_TRAIN_ROUTE);
        localStorage.removeItem(LOCAL_STORAGE_USER_TRAIN_RUN_INTERVALS);
        throw Error(`TrainRuns with ids ${join(', ', map(prop('id'), mismatchingTrainRuns))} don't match the TrainRouteOrGroup that they were cached with and will be ignored`);
      }
      const correctedTrainRuns = filter(mismatchingTrainRun => !includes(mismatchingTrainRun, mismatchingTrainRuns), orderedTrainRunsWithRoute);
      setTrainRuns(correctedTrainRuns);
    }
  }, [newlyLoadedTrainRuns, newlyLoadedOutsideFilterTrainRuns, outsideFilterTrainRuns, trainRouteOrGroup, maybeOutsideFilterTrainRunIds]);
};


/**
 * If trainRunsForDateInterval stops matching the trainRuns, set TrainRuns and BaselineTrainRun to null
 * to tell the app that we are loading a new date
 * @param loading
 * @param filterProps
 * @param setTrainRuns
 * @param setBaselineTrainRun
 * @param trainRuns
 */
export const useUnsetTrainRunsIfFilterChanges = (
  {
    loading,
    filterProps,
    setTrainRuns,
    setBaselineTrainRun,
    trainRuns
  }) => {
  useNotLoadingEffect(loading, () => {
      // TODO for now just clear it every time the filter changes
      if (trainRuns) {
        setTrainRuns(null);
        setBaselineTrainRun(null);
      }
    },
    [filterProps]
  );
};


/**
 * Sets the dateRange used for the TrainRun
 * @param {Boolean} loading Do nothing if this is True
 * @param {Object} organization The client instance
 * @param {Object} trainRoute The TrainRoute instance
 * @param {Function} setAvailableDates Set the dates of TrainRuns for this TrainRoute
 * @param {Date} The currently selectedDate, which is synced with dateRange.start
 * @param {Function} setSelectedDate Set to the latest available date
 */
export const useTrainApiSetAvailableDatesAndDateRangeFilter = (
  {
    loading,
    organization,
    trainRoute,
    setAvailableDates,
    selectedDate,
    setSelectedDate,
    parentTrainRunFilter,
    trainRunFilterWithDateRanges,
    setTrainRunFilterWithDateRanges
  }) => {

  // Find out the dates of TrainRuns that have CDC data for the give trainRoute
  const { data: availableDateStrings } = useResolveTrainApiData(
    { loading, organization, trainRoute, method: 'availableDates' }
  );

  const dateRange = trainRunFilterDateRange(trainRunFilterWithDateRanges);
  const setDateRangeFilter = dateRange => {
    const dateRangeFilter = createDateRangeFilter(dateRange);
    // TODO, we'll want to store dateRangeFilters for each DateRange in the near future
    // so we don't lose the user's selected interval
    const updatedTrainRunFilter = addDateRangeFilters(parentTrainRunFilter, dateRangeFilter);
    setTrainRunFilterWithDateRanges(
      updatedTrainRunFilter
    );
  };

  useNotLoadingEffect(loading || !availableDateStrings, () => {
      const parsedAvailableDates = map(dateStr => parseISO(dateStr), availableDateStrings);
      const availableDateRange = {
        start: startOfDay(head(parsedAvailableDates)),
        end: endOfDay(last(parsedAvailableDates))
      };
      let updatedDateRange = dateRange;
      if (!length(parsedAvailableDates)) {
        // Set the date range to today. This will be compared to the dateProps.dateRange to
        // and tell components that no dates are available
        const now = new Date();
        updatedDateRange = { start: startOfDay(now), end: endOfDay(now) };
        setDateRangeFilter(updatedDateRange);
        // Set the selectedDate to match dateRange
        setSelectedDate(dateRange.start);
        setAvailableDates(parsedAvailableDates);
      }
      else {
        const latestDate = last(parsedAvailableDates);
        setAvailableDates(parsedAvailableDates);
        // Only update the dateRange if none of the previous dataRange is in the new availableDateRange
        // Otherwise leave it alone
        if (!dateRange || none(
          compose(
            flip(isWithinInterval)(availableDateRange)
          ),
          props(['start', 'end'], dateRange)
        )
        ) {
          // Default the date interval to the most recent date. The user can change this date later
          updatedDateRange = { start: startOfDay(latestDate), end: endOfDay(latestDate) };
          setDateRangeFilter(updatedDateRange);
        }
        // Set the selectedDate to match dateRange if needed
        if (!equals(selectedDate, updatedDateRange.start)) {
          setSelectedDate(updatedDateRange.start);
        }
      }
    },
    [availableDateStrings]
  );

  // Whenever the parentTrainRunFilter updates, update this TrainRunFilter
  useNotLoadingEffect(loading || !trainRunFilterWithDateRanges, () => {
    const updatedTrainRunFilter = mergeTrainRunFilters({ childFilterTypeName: 'DateRangeFilter' }, parentTrainRunFilter, trainRunFilterWithDateRanges);
    setTrainRunFilterWithDateRanges(
      updatedTrainRunFilter
    );
  }, [parentTrainRunFilter]);
};

/**
 * Creates a geojson property for TrainRuns that have sensor data loaded
 * This only updates the userTrainRunInterval.trainRunInterval.trainRun.imuPoints
 * This updates whenever train
 * @param loading
 * @param userTrainRunIntervals
 * @param trainRoute
 * @param trainRuns
 * @param {Object} crudUserTrainRunIntervals used to read and sync UserTrainRunIntervals
 * @param {[Object]} minimumTrainRunsWithImuPoints Indicates if the TrainRuns' imuPoints have updated
 */
export const useUpdateTrainRunsGeojsonOfUserTrainRunIntervals = (
  {
    loading,
    trainRoute,
    trainRuns,
    crudUserTrainRunIntervals,
    userTrainRunIntervals,
    minimumTrainRunsWithImuPoints
  }) => {

  useNotLoadingEffect(loading, () => {
      const func = async () => {
        const updatedTrainRuns = compact(await Promise.all(map(
          async (userTrainRunInterval) => {
            const trainRun = userTrainRunInterval.trainRunInterval.trainRun;
            const distanceIntervalsAndRanges = find(idsEqual(trainRun), minimumTrainRunsWithImuPoints).distanceIntervalsAndRanges;
            // This will only recalculate if distanceIntervalsAndRanges have changed
            const features = await workerizedTrainRunFeatureCollectionSensorPoints({
              trainRoute,
              trainRun,
              distanceIntervalsAndRanges
            });
            // If the memoized function didn't need to run, return null
            if (trainRun?.geojson?.features === features) {
              return null;
            }
            const featureCollection = {
              type: 'FeatureCollection',
              features
            };
            return { ...trainRun, geojson: featureCollection };
          },
          userTrainRunIntervals
        )));

        if (!length(updatedTrainRuns)) {
          return;
        }

        // Set the corresponding UserTrainRunInterval with the trainRun containing new ImuPoints.
        // updateOrCreateAll then deepMerges these values with existing value
        const userTrainRunIntervalsToMerge = updateTrainRunOfUserTrainRunIntervalsPreMerge({
          crudUserTrainRunIntervals,
          trainRuns: updatedTrainRuns
        });
        // Update those that have changed
        // TODO do we need to call crudTrainRunIntervals here so its trainRuns have the geojson?
        crudUserTrainRunIntervals.updateOrCreateAll(userTrainRunIntervalsToMerge);
      };
      func();
    }, [
      trainRoute,
      trainRuns,
      crudUserTrainRunIntervals,
      userTrainRunIntervals,
      minimumTrainRunsWithImuPoints
    ]
  );
};