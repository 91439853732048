import { Box, Button, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { always, cond, includes, prop, T } from 'ramda';
import { createUserTrainRunInterval } from 'appUtils/trainAppUtils/typeCrud/userTrainRunIntervalCrud.js';

/**
 * Makes A UserTrainRunInterval from the Train run and sets it to active.
 * @param {Object} organization
 * @param crudUserTrainRunIntervals
 * @param {Object} userTrainRunInterval null if no UserTrainRunInterval has been created for this TrainRunInterval yet
 * @param trainRunInterval
 * @param trainRoute
 * @param [disabled] Default false. Set true to disable the button
 * @returns {JSX.Element}
 * @constructor
 */
const UserTrainRunAddButton = (
  {
    organization,
    crudUserTrainRunIntervals,
    userTrainRunInterval,
    trainRunInterval,
    disabled = false
  }) => {

  const tooltipKey = cond([
    [prop('alreadyInList'), always('trainRunAlreadyAdded')],
    [prop('disabled'), always('activeTrainRunsFull')],
    [T, always('addTrainRun')]
  ])({
    disabled,
    alreadyInList: includes(userTrainRunInterval, crudUserTrainRunIntervals.list)
  });

  const { t } = useTranslation();
  return <Tooltip arrow title={t(tooltipKey)}>
    <Box>
      <Button
        key='addButton'
        disabled={disabled}
        sx={{ bgcolor: 'scheduleButton.main', color: 'secondary.main' }}
        variant='contained'
        onClick={() => {
          crudUserTrainRunIntervals.updateOrCreate(
            createUserTrainRunInterval({
              user: organization,
              trainRunInterval
            })
          );
        }}
      >
        {t('add')}
      </Button></Box></Tooltip>;
};
export default UserTrainRunAddButton;