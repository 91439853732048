import {equals, is, map} from "ramda";
import React, {SyntheticEvent, useMemo, useState} from "react";
import {Button, Stack, Typography, Menu, MenuItem} from "@mui/material";
import {tempColors} from "../../../../theme/tempColors.ts";
import {Timer} from "@mui/icons-material";
import {Setter} from "../../../../visualizer-railbed/types/hookHelpers/setter";
import {RideComfortIntervalDescription} from "../../../../types/rideComfort/rideComfortintervalDescription";

const RideComfortIntervalPicker = (
    {
        intervalDescription,
        setIntervalDescription,
        intervalDescriptions
    }: {
        intervalDescription: RideComfortIntervalDescription,
        setIntervalDescription:  Setter<RideComfortIntervalDescription>,
        intervalDescriptions: RideComfortIntervalDescription
    }) => {
    const [anchorEl, setAnchorEl] = useState(undefined);

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const onClose = (_e: SyntheticEvent, interval: RideComfortIntervalDescription) => {
        setAnchorEl(undefined);
        // if interval comes in as the string 'backDrop',
        // it means the user clicked outside the menu
        if (!is(String, interval)) {
            setIntervalDescription(interval)
        }
    }
    const intervalComponents = useMemo<JSX.Element[]>(
        () => {
            return map(
                (interval: RideComfortIntervalDescription) => {
                    return <MenuItem {...{
                        key: interval.label,
                        disabled: equals(interval, intervalDescription),
                        onClick: (e: SyntheticEvent) => onClose(e, interval)
                    }}
                    >{interval.label} ({interval.durationText})</MenuItem>
                },
                intervalDescriptions
            )
        }, [intervalDescriptions, intervalDescription]
    )

    return <Stack {...{sx: {alignSelf: 'self-start'}}}>
            <Typography {...{
                sx: {
                    color: tempColors.white,
                    fontSize: '0.8rem',
                    lineHeight: '1.4375em', letterSpacing: '0.00938em',
                }
            }}>Interval</Typography>
            <Button {...{
                key: 'button',
                id: 'interval-button',
                color: 'secondary',
                'aria-controls': open ? 'interval-positioned-menu' : undefined,
                'aria-haspopup': 'true',
                'aria-expanded': open ? 'true' : undefined,
                onClick: handleClick
            }}>
                <Stack {...{direction: 'row', spacing: 1, sx: {alignItems: 'center'}}}>
                    <Timer {...{key: 'icon'}}/>
                    <Typography {...{key: 'label'}}>{intervalDescription.label} ({intervalDescription.durationText})</Typography>
                </Stack>
            </Button>
            <Menu {...{
                key: 'menu',
                id: 'formation-positioned-menu',
                'aria-labelledby': 'formation-button',
                anchorEl,
                open,
                onClose,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'left'
                },
                transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left'
                }
            }}>
                {intervalComponents}
            </Menu>
        </Stack>
}
RideComfortIntervalPicker.displayName = 'RideComfortIntervalPicker'
export default RideComfortIntervalPicker
