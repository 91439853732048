import { useEffectCreateListCrud } from 'utils/hooks/crudHooks.js';
import { unsyncTrainRunIntervalFromTemplate } from 'appUtils/trainAppUtils/trainUtils.js';
import {
  ascend,
  clone,
  cond,
  eqProps,
  equals,
  lensProp,
  map,
  map as mapR,
  pick,
  prop,
  set,
  sortWith,
  T,
  when
} from 'ramda';
import {
  extractTrainRunIntervalTrainRoute,
  syncTrainRunIntervalToTrainRouteInterval
} from 'appUtils/trainAppUtils/trainRunUtils.js';
import { calculatePercentageOfDistanceRange } from 'appUtils/trainAppUtils/trainRunLineUtils.js';
import { reqStrPathThrowing } from '@rescapes/ramda';
import { mergeTrainRunInterval } from 'appUtils/trainAppUtils/typeMerging/trainRunIntervalMerging.js';

/**
 * Syncs all eligible TrainRunInterval distances ranges to a a copy of the trainRouteInterval
 * @param crudTrainRunIntervals
 * @param trainRouteInterval
 * @returns {Object} The unaltered trainRouteInterval
 */
export const syncEligibleTrainRunIntervals = (crudTrainRunIntervals, trainRouteInterval) => {
  // Update everything, but only change the distanceRange of those with isSyncedToTrainRouteInterval = true
  crudTrainRunIntervals.set(
    mapR(
      maybeSyncedTrainRunInterval => {
        return when(
          prop('isSyncedToTrainRouteInterval'),
          trainRunInterval => {
            return set(
              lensProp('distanceRange'),
              clone(trainRouteInterval.distanceRange),
              trainRunInterval
            );
          }
        )(maybeSyncedTrainRunInterval);
      },
      crudTrainRunIntervals.list
    )
  );
  return trainRouteInterval;
};
/**
 * Create a crud object for managing TrainRunIntervals
 * @param trainRunIntervals
 * @param {Function} setTrainRunIntervals Setter for trainRunIntervals
 * @param {Object} crudTrainRunIntervals
 * @param setCrudTrainRunIntervals
 * @returns {Object} A Crud object for managing lists
 */
export const useEffectCreateCrudTrainRunIntervals = (trainRunIntervals, setTrainRunIntervals, crudTrainRunIntervals, setCrudTrainRunIntervals) => {
  return useEffectCreateListCrud(
    {
      equality: (incoming, existing) => {
        // eslint-disable-next-line no-undef
        return eqProps('sourceKey', incoming, existing);
      },
      additionalOperations: {
        // Tells the TrainRunInterval to timetabledPassingTime tracking changes to its trainRouteInterval
        unsyncTrainRunIntervalFromTemplate,
        // Tells the TrainRunInterval to start tracking changes to its trainRouteInterval
        syncTrainRunIntervalToTemplate: syncTrainRunIntervalToTrainRouteInterval,
        // Syncs all eligible TrainRunIntervals to the template
        syncEligibleTrainRunIntervals
      },
      list: trainRunIntervals,
      setList: setTrainRunIntervals,
      listCrud: crudTrainRunIntervals,
      setListCrud: setCrudTrainRunIntervals,
      merge: mergeTrainRunInterval
    }
  );
};
/**
 * Create the TrainRun intervals for the given TrainRoute and the trainRuns matching it by start and end station
 * @param trainRoute
 * @param trainRuns
 * @returns {*}
 */
export const createTrainRunIntervals = ({ trainRouteInterval, trainRuns }) => {
  // Add a TrainRunInterval based on the matching trainRoute's trainRouteInterval
  return map(
    trainRun => {
      return createTrainRunInterval({
        trainRouteInterval,
        trainRun
      });
    },
    trainRuns
  );
};

/***
 * Creates a TrainRunInverval from the given TrainRouteInterval and TrainRun
 * @param trainRouteInterval
 * @param trainRun Only needs to have an id
 * @returns {*&{sourceKey: string, isSyncedToTrainRouteInterval: boolean, __type: string, trainRun}}
 */
export const createTrainRunInterval = ({ trainRouteInterval, trainRun }) => {
  return {
    ...pick(['distanceRange', 'distance'], trainRouteInterval),
    trainRun,
    __type: 'TrainRunInterval',
    // The TrainRunInterval changes to the trainRouteInterval
    // until distanceRange is changed by the user. Then we set this flag to false
    // This flag can be set back to true to sync to the template again
    isSyncedToTrainRouteInterval: true,
    // We currently assume there is only one TrainRunInterval per TrainRun
    // Because we can update baserun TrainRuns' TrainRoutes to match the current TrainRoute,
    // we must include the TrainRoute.id here to distinguish a baseline TrainRun cloned for different TrainRoutes
    // Otherwise we'll have duplicate UserTrainRunIntervals when we switch TrainRoutes, which messes
    // up change detection in mergeFullTrainRunsIntoUserTrainRunIntervals
    // TODO we probably need first class UserTrainRunIntervals with guid ids and properly cloned TrainRuns
    // with new guid
    sourceKey: `trainRunIntervalTrainRoute:${trainRouteInterval.trainRoute.id}TrainRun:${trainRun.id}`
  };
};
/**
 *
 * Computes the position of an TrainRunInterval bar, where the input value is either
 * the left position or the right position (where right is used after to calculate the width)
 * @param {Object} trainRunInterval
 * @param {Object} trainRunInterval.distanceRange
 * @param {Object} trainRunInterval.distanceRange.start
 * @param {Object} trainRunInterval.distanceRange.end
 * @param {Boolean} spaceGeospatially If true the position is calcuated by normalizing the distanceRange.
 * Otherwise it's calculated using resolveOffsetLeft, which calculates the position relative to the flex
 * position the TrainStationDot components
 * @param {Boolean} limitedDistanceRange If true, use trainRunInterval.distanceRange as the normalized.
 * If false, use the full distance from 0 to routeDistance of the underlying TrainRoute
 * @param {Function} resolveOffsetLeft Resolves a flex based offSetLeft is spaceGeospatially is false
 * @param {Number} parentWidth
 * @param {Number} value The input value, which is typically a dragged trainRunInterval and thus
 * either trainRunInterval.distanceRange.start or trainRunInterval.distanceRange.end
 * @param {Numbrer} xOffset The number of pixels offset
 * @returns {*}
 */
export const computedIntervalBarPosition = (
  {
    trainRunInterval,
    spaceGeospatially,
    limitedDistanceRange,
    resolveOffsetLeft,
    parentWidth
  }, value, xOffset
) => {
  return cond([
    [
      () => {
        return spaceGeospatially && limitedDistanceRange;
      },
      value => {
        const xOffsetPercentageOfParentWidth = 100 * xOffset / parentWidth;
        const percentOfDistanceRange = calculatePercentageOfDistanceRange(
          trainRunInterval.distanceRange, value
        ) + xOffsetPercentageOfParentWidth;
        return percentOfDistanceRange;
      }
    ],
    [
      () => {
        return spaceGeospatially;
      },
      value => {
        // If we don't have a limited distance range, we need to normalize from 0 to the routeDistance
        const xOffsetPercentageOfParentWidth = 100 * xOffset / parentWidth;
        const trainRouteInterval = extractTrainRunIntervalTrainRoute(trainRunInterval);
        return calculatePercentageOfDistanceRange({
            start: 0,
            end: trainRouteInterval.routeDistance
          }, value
        ) + xOffsetPercentageOfParentWidth;
      }
    ],
    [
      T,
      value => {
        // If not spaceGeospatially, we need to calculate the offset relative to the station stops, which are evenly spaced
        // and have routeDistances that tell us where our offset should be
        return resolveOffsetLeft(value + xOffset);
      }
    ]
  ])(value);
};

/**
 *  Creates a default unmaximized distanceRange of 1km in the middle of the TrainRoute.
 *  If the user double clicks a maximized TrainRunInterval that didn't previously smaller interval,
 *  it defaults to this.
 * @param startOrEnd
 * @param trainRouteDistanceRange
 * @returns {Number} The distance
 */
export const defaultUnmaximizedDistanceRange = ({ startOrEnd }, trainRouteDistanceRange) => {
  return (trainRouteDistanceRange['end'] / 2) + (equals('start', startOrEnd) ? -1000 : 1000);
};

export const sortTrainRunIntervals = trainRuns => {
  return sortWith([
    ascend(reqStrPathThrowing('trainRun.departureDatetime')),
    ascend(reqStrPathThrowing('trainRun.arrivalDatetime'))
  ])(trainRuns);

};