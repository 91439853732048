import { useState } from 'react';
import { useRailwayLineMapboxSourcesAndLayers } from 'async/trainAppAsync/hooks/mapHooks/railwayLineMapHooks.js';
import TrainMapSensorLayerDependency from 'async/trainAppAsync/dependencies/TrainMapSensorLayerDependency.js';
import { useTranslation } from 'react-i18next';

/***
 * Loads/Updates TrainMap layer data into mapProps.trainMapLayerProps
 * Depends directly on UserTrainRunIntervalSensor props in trainProps.userTrainRunIntervalProps.sensorProps and TrainMap in
 * mapProps
 * @param appProps
 * @param organizationProps
 * @param trainProps
 * @param mapProps
 * @param children
 * @return {*}
 * @constructor
 */
const TrainMapLayerDependency = ({ appProps, organizationProps, trainProps, mapProps  }) => {

  const {t} = useTranslation()
  const loading = trainProps.userTrainRunIntervalProps.geojsonProps.loading || mapProps.loading;

  // The RailwayLine being hovered over, TODO not currently in use
  const [hoveredRailwayLineId, setHoveredRailwayLineId] = useState(null);

  // Used to indicate if the trip trainSwitchLoaded
  const [trainSwitchLoaded, setTrainSwitchLoaded] = useState(false)

  // TODO terrainLayer not in use
  const [trainMapButtonStates, setTrainMapButtonStates] = useState({
    terrainLayer: false,
    dataColumns3DLayerAreVisible: true,
    dataThresholds3DLayersAreVisible: true
  });

  // Update/Create RailwayLine Mapbox Sources
  useRailwayLineMapboxSourcesAndLayers({
    loading,
    appProps,
    organizationProps,
    trainProps,
    mapProps,
    t
  });


  // Individual Train Layers and Handler. Currently not in use
  /*
    useTrainRunMapboxSources({
    mapProps,
    trainProps,
  });
  useTrainRunMapboxLayers({
    mapProps,
    trainProps,
  });
  */

  return <TrainMapSensorLayerDependency {...{
    appProps,
    organizationProps,
    mapProps: {
      trainMapLayerProps: {
        // Depends directly on trainProps.userTrainRunIntervalSensorProps and mapProps
        loading,
        hoveredRailwayLineId, setHoveredRailwayLineId,
        trainMapButtonStates, setTrainMapButtonStates,
        trainSwitchLoaded, setTrainSwitchLoaded
      },
      ...mapProps
    },
    trainProps
  }} />
};
TrainMapLayerDependency.displayName = 'TrainMapLayerDependency'
export default TrainMapLayerDependency;