import { Grid, Stack, Typography, Box } from '@mui/material';
import { TrainDescription } from 'components/apps/trainAppComponents/trainRunChooserComponents/TrainDescription.js';
import { TrainRunEquipment } from 'components/apps/trainAppComponents/trainRunChooserComponents/TrainRunEquipment.js';
import { TrainRouteOrGroupServiceDescription } from 'components/apps/trainAppComponents/trainRunChooserComponents/TrainRouteOrGroupServiceDescription.js';
import { pick } from 'ramda';

/**
 * Description of the TrainRun along with optional buttons and icons: add, remove, and loading icon
 * @param trainRun
 * @param buttons
 * @param {String} trainRouteLabel The label for the TrainRoute. If all TrainRuns are the same TrainRoute, this
 * could be as simple as the destination or perhaps nothing at all. If the TrainRuns are different TrainRoute because
 * a TrainRouteGroup is being displayed, it should show the origin and destination for clarity
 * @param {Boolean} showTrainRunLine Changes formatting based on the presence of a TrainRunLine that shows a line
 * with the stops
 * @returns {JSX.Element}
 * @constructor
 */
const TrainRunDescriptionWithTrainRunLine = (
  {
    trainRun,
    buttons,
    dateAndOrTimeString,
    trainRouteLabel,
    rmseIcons
  }) => {


  return <Grid container key={trainRun.id}
               justifyContent='space-between'
               sx={{}}>
    <Grid item
          xs={5}
          sx={{}}>
      <Stack spacing={0.5}>
        <Stack {...{direction:'row', spacing: 1}}>{rmseIcons}</Stack>
        <TrainDescription trainRun={trainRun} />
        <TrainRunEquipment trainRun={trainRun} />
      </Stack>
    </Grid>
    <Grid item
          xs={4}
          sx={{}}
    >
      <Stack spacing={0.5} direction='column'>
        <TrainRouteOrGroupServiceDescription {...pick(['serviceLine', 'source'], trainRun)} />
        <Box>
          <Typography key='destination' sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }} variant='caption'>
            {trainRouteLabel}
          </Typography>
        </Box>
        <Typography variant='caption'>{
          dateAndOrTimeString
        }</Typography>
      </Stack>
    </Grid>
    <Grid item sx={{}}>
      <Stack spacing={(buttons || []).length > 1 ? 0.5 : 0} sx={{ alignItems: 'end' }}> {
        buttons
      } </Stack>
    </Grid>
  </Grid>;
};
export default TrainRunDescriptionWithTrainRunLine;