import {Route, Routes} from 'react-router-dom';
import {Box} from '@mui/material';
import TrainOverviewContainer
    from 'components/apps/trainAppComponents/trainOverviewComponents/TrainOverviewContainer.js';
import TrainRunningCharacteristicsContainer
    from 'components/apps/trainAppComponents/trainRunningCharacteristicsComponents/TrainRunningCharacteristicsContainer.js';
import SampleChart from 'components/charts/stackedChart/sample/SampleChart.js';
import {chartNumericAxisProps, lines, numberAxisData} from 'components/charts/stackedChart/sample/sampleData.js';
import RideComfortPage from "../../../pages/trainApp/rideComfort/RideComfortPage.tsx";

/**
 * Routes to the the various TrainApp app top tab routes, like TrainOverview and TrainRunningCharacteristics
 * @param {Object} appProps,
 * @param {Object} organizationProps,
 * @param {Object} trainProps,
 * @returns {JSX.Element}
 */
const TrainAppRoutes = (
    {
        appProps,
        organizationProps,
        trainProps,
        mapProps,
        componentProps: {
            panelSize
        }
    }
) => {

    return <Box
        justifyContent='space-between'
        sx={{
            height: '100%',
            maxHeight: '100%',
            overflowY: 'hidden',
            overflowX: 'hidden'
        }}
    >
        <Routes primary={false} style={{height: '100%', maxHeight: '100%'}}>
            <Route path='runs' element={
                <TrainOverviewContainer {...{
                    path: 'runs',
                    appProps,
                    organizationProps,
                    trainProps,
                    mapProps,
                    componentProps: {
                        panelSize
                    }
                }}
                />
            }/>
            <Route path='running_characteristics' element={
                <TrainRunningCharacteristicsContainer {...{
                    path: 'running_characteristics',
                    appProps,
                    organizationProps,
                    trainProps,
                    mapProps,
                }}
                />}/>
            <Route path='ride_comfort' element={
                <RideComfortPage {...{
                    appProps,
                    organizationProps,
                    trainProps,
                    mapProps,
                }}
                />}/>
            <Route path='wheel_energy' element={
                <div style={{width: "90vw", height: "500px"}}>
                    <h2>Numeric Axis</h2>
                    <SampleChart data={numberAxisData} lines={lines} axis={chartNumericAxisProps}/>
                </div>
                // <TrainWheelContainer {...{
                //   path: 'wheel_energy',
                //   appProps,
                //   organizationProps,
                //   trainProps,
                //}}
            }/>
        </Routes>
    </Box>;
};

export default TrainAppRoutes;
