import { forwardRef } from 'react';
import StackedDataPathComparisonChart from 'components/charts/stackedChart/StackedDataPathComparisonChart.js';

/**
 * Compares track data for two different week
 * @param {Object} map Mapbox map for adding a hover layer when the user hovers on the chart
 * @param {Number} yAxisWidth Chart y-axis width in pixels
 * @param {[Object]} comporisionTracks
 * @param {[Object]} comparisonTrackDataSets The two datasets to compare. They should both cover the same geojson
 * points a long a track. They can represent aggregated runs of trains or single runs of trains.
 * @param {[String]} dataPaths The str paths within geojson properties object to display
 * E.g. 'acceleration.sumMaxMean'
 * @param {[Object]} comparisonTrackDataSets[*].data The dataset
 * @param {[Object]} comparisonTrackDataSets[*].label The label of the dataset
 * @param {[Object]} comparisonTrackDataSets[*].featuresPath The string path to the geojson features, e.g. 'features' or 'foo.geojson.features'
 * string based on the item and comparisonPair.
 * E.g. (item, comparisonPair) => item?.stroke === '#FAF4DE' ? comparisonLabelPair[0] : comparisonLabelPair[1]
 * @param {Number} width Width of the charts
 * @param {Number} height Height of the charts
 * @param {Function} setChartWidth Setter function to tell the TrainRunLine how wide the svg chart component is
 * @returns {JSX.Element}
 */
const StackedDataPathComparisonChartContainer = forwardRef((
  {
    appProps,
    trainProps,
    componentProps: {
      yAxisWidth,
      dataPathsConfigs,
      comparisonTrackDataSets,
      trainDataPathStackedChartRefWidth,
      referenceStopLookup,
      trackDataFeatureSets,
    },
    sx
  }, ref) => {

  const showUpdating = trainProps.userTrainRunIntervalProps.sensorProps.additionalImuPointsAreLoading;
  return <StackedDataPathComparisonChart {...{
    ref,
    appProps,
    trainProps,
    componentProps: {
      yAxisWidth,
      dataPathsConfigs,
      referenceStopLookup,
      comparisonTrackDataSets,
      showUpdating,
      trainDataPathStackedChartRefWidth,
      trackDataFeatureSets,
    },
    sx
  }}
  />;
});
StackedDataPathComparisonChartContainer.displayName = 'StackedDataPathComparisonChartContainer';
export default StackedDataPathComparisonChartContainer;