import { toArrayIfNot } from '@rescapes/ramda';
import { Box, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

/**
 * Shows the hover line over the UserTrainRunInterval based on the map or chart hover
 * @param componentProps
 * @param {Function} componentProps.hoverFeature The Stored geojson feature that is being hovered over
 * @param {Function} componentProps.resolveOffsetLeft A function that resolves the offset of each bar on the line given
 * the distance property of the bar
 * @param {Object|[Object]} componentProps.sxTrainRunIntervalBar sx overrides for the TrainRunIntervalBar
 * @returns {JSX.Element}
 * @constructor
 */
const HoverFeature = (
  {
    componentProps: {
      hoverFeature,
      resolveOffsetLeft,
      spaceGeospatially
    },
    sx
  }) => {
  const { t } = useTranslation();
  const percentOrPixel = prop => `${prop}${spaceGeospatially ? '%' : 'px'}`;
  const left = resolveOffsetLeft(hoverFeature.properties.meters);
  const width = resolveOffsetLeft(hoverFeature.properties.meters + (spaceGeospatially ? 1 : 5)) - left;
  const bgcolor = 'trainRunIntervalBar.main';
  return <Tooltip arrow title={t('hoverFeature')}>
    <Box
      sx={[
        {
          position: 'absolute',
          left: percentOrPixel(left),
          width: percentOrPixel(width),
          top: '-5px',
          height: '20px',
          borderRadius: '20%',
          border: 1,
          borderColor: 'secondary.main',
          bgcolor,
          zIndex: 10
        },
        ...toArrayIfNot(sx)
      ]}>
    </Box>
  </Tooltip>;
};
export default HoverFeature;