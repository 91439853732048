import React from 'react';
import {Stack} from "@mui/material";
import {tempColors} from "../../../../theme/tempColors.ts";

export default function RideComfortApp(
    {
        children,
    }) {
    return <Stack {...{
        sx: {
            width: '100%',
            height: '100%',
            //height: '100vh',
            //maxHeight: '100vh',
            overflowY: 'auto',
            overflowX: 'hidden',
        }
    }}>
        {children}
    </Stack>
}
