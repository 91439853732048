import { Link } from 'react-router-dom';
import { NavBtn, ProfileText } from 'components/apps/cemitAppComponents/CemitAppOldStyleComponents.js';
import { Profile } from 'components/apps/cemitAppComponents/sidebar/sidebarSvg.js';
import { APPS_PATH } from 'appConfigs/appConfig.js';
import { colors } from 'theme/colors.ts';

/**
 * TODO Old code. Redo
 * @param currentPath
 * @return {JSX.Element}
 * @constructor
 */
export const CemitAppProfileItem = ({ currentPath }) => {
  const access = true;
  return <div>
    <Link
      to={`/${APPS_PATH}/profile`}
    >
      <div {...{
        style: {
          borderLeft: currentPath.includes('profile') ? `3px ${colors.orange} solid` : `3px transparent solid`,
          '&:hover': {
            background: 'rgba(0, 0, 0, 0.15)'
          },
          p: {
            color: currentPath.includes('profile') ? colors.orange : (access ? 'white' : 'gray'),
            fontSize: '10px',
            textAlign: 'center',
            paddingBottom: '5px'
          },
          '*': {
            cursor: access ? 'cursor' : 'not-allowed'
          }
        }
      }}
      >
        <NavBtn>
          <Profile
            fill={currentPath.includes('profile') ? colors.orange : 'white'}
          />
        </NavBtn>
        <ProfileText>PROFILE</ProfileText>
      </div>
    </Link>
  </div>;
};