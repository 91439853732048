import { useMemo, useState } from 'react';
import { useSetTrainRuns } from 'async/trainAppAsync/hooks/typeHooks/trainRunHooks.js';
import {
  always,
  any,
  compose,
  concat,
  equals,
  filter,
  includes,
  indexBy,
  map,
  prop,
  propOr,
  sortBy,
  uniqBy
} from 'ramda';
import { extractFormations } from 'appUtils/trainAppUtils/trainFilterTrainFormationUtils.js';
import { extractTrainRoutes } from 'appUtils/trainAppUtils/trainFilterTrainRouteUtils.js';
import { extractDateRanges } from 'appUtils/trainAppUtils/trainFilterDateRangeUtils.js';
import { extractDateRecurrences } from 'appUtils/trainAppUtils/trainFilterDateRecurrenceUtils.js';
import { useNotLoadingEffect, useNotLoadingMemo } from 'utils/hooks/useMemoHooks.js';
import { localizedTime, localizedTimeNoTimezone } from 'utils/date/dateFormatUtils.js';
import { reqStrPathThrowing, strPathOr } from '@rescapes/ramda';
import { useLocalStorage } from 'utils/hooks/useLocalStorage.js';
import { LOCAL_STORAGE_USER_TRAIN_RUN_INTERVALS } from 'appConfigs/appConfig.js';
import TrainMapDependency from 'async/trainAppAsync/dependencies/TainMapDependency.js';
import {
  minimizedStoredUserTrainRunIntervals
} from 'appUtils/trainAppUtils/userTrainRunIntervalUtil.js';
import { maybeAddPreconfiguredUserTrainRunIntervalsByRoute } from 'appUtils/trainAppUtils/typeCrud/userTrainRunIntervalCrud.js';

/**
 * Loads/Updates TrainRuns into trainProps.trainRunProps
 * Depends directly on date props at trainProps.dateProps
 * @param appProps
 * @param organizationProps
 * @param trainProps
 * @param children
 * @return {*}
 * @constructor
 */
const TrainRunDependency = ({ appProps, organizationProps, trainProps, mapProps }) => {
  const loading = trainProps.dateProps.loading;

  // TrainRuns with the selected line and direction
  const [trainRuns, setTrainRuns] = useState(null);
  // This is currently just used to check if we queried for outsideFilterTrainRuns but the result was empty
  const [outsideFilterTrainRuns, setOutsideFilterTrainRuns] = useState(null);

  const [allKnownTrainRunDepartureTimes, setAllKnownTrainRunDepartureTimes] = useState([]);

  const { organization } = organizationProps;

  const [filterProps, setFilterProps] = useState(null);
  // We use trainRunFilterWithDateRecurrences because it's currently the most customized
  // filter in the dependency chain
  useNotLoadingEffect(loading, () => {
    const trainRunFilter = trainProps.dateProps.trainRunFilterWithDateRecurrences;
    const [trainRoute] = extractTrainRoutes(trainRunFilter, {
      trainRoutes: trainProps.trainRouteProps.trainRoutes,
      trainRouteGroups: trainProps.trainRouteProps.trainRouteGroups
    });
    const formations = extractFormations(trainRunFilter, { formations: trainProps.formationProps.formations });
    const [dateRange] = extractDateRanges(trainRunFilter, {});
    const dateRecurrences = extractDateRecurrences(trainRunFilter, {});

    if (!equals(filterProps, { trainRoute, formations, dateRange, dateRecurrences })) {
      // Only setFilterProps if something actually changed since this clears the TrainRuns and forces a reload
      setFilterProps({ trainRoute, formations, dateRange, dateRecurrences });
      setTrainRuns(null);
    }
  }, [trainProps.dateProps.trainRunFilterWithDateRecurrences]);

  // TODO userTrainRunIntervalsForAllTrainRoutes is really in the domain of UserTrainRunInterval
  // and belongs in that Dependency. But we have to extract the TrainRuns and load them here.
  // This can be cleaned up when we move to a TrainRunGroup solution, which means that TrainRunGroups
  // will be loaded here as needed and UserTrainRunIntervals can reference them.
  const [userTrainRunIntervalsForAllTrainRoutes, setUserTrainRunIntervalsForAllTrainRoutes] = useLocalStorage({
    // We need all dates and times to be deserialized to Datetimes.
    // Add more keys as needed or use something better than local storage
    parserArgument: ['departureDatetime'],
    serializer: minimizedStoredUserTrainRunIntervals,
    loading: organizationProps.loading || !trainProps.trainRouteProps.trainRouteInterval
  }, LOCAL_STORAGE_USER_TRAIN_RUN_INTERVALS, {});

  const userTrainRunIntervalsForTrainRoute = useNotLoadingMemo(loading || !userTrainRunIntervalsForAllTrainRoutes, () => {
    // Take the values from localStorage and add preconfigured values for the TrainRoute if they exist and aren't
    // what are already in the localStorage
    return maybeAddPreconfiguredUserTrainRunIntervalsByRoute(
      {
        organization: organizationProps.organization,
        trainRouteInterval: trainProps.trainRouteProps.trainRouteInterval,
        userTrainRunIntervalsFromStorageMinimized: userTrainRunIntervalsForAllTrainRoutes[trainProps.trainRouteProps.trainRoute.id] || []
      }
    );
  }, [userTrainRunIntervalsForAllTrainRoutes, trainProps.trainRouteProps?.trainRoute?.id]);

  // We need to load TrainRuns from the localStorage even if they don't match the current filter settings,
  // The user's cached UserTrainRunIntervals aren't impacted by the filter.
  // This stores the ids we need to load
  const trainRouteTrainRunsWithMaybeTrainRouteOverrides = useNotLoadingMemo(loading || !userTrainRunIntervalsForAllTrainRoutes, () => {
    const trainRouteTrainRuns = map(
      reqStrPathThrowing('trainRunInterval.trainRun'),
      userTrainRunIntervalsForTrainRoute
    );
    // Return the trainRunId and possible trainRoute id to indicate that we want to force the TrainRoute
    return map(
      trainRun => {
        return {
          id: prop('id', trainRun),
          overrideTrainRouteId: strPathOr(null, 'overrideTrainRoute.id', trainRun)
        };
      },
      trainRouteTrainRuns
    );
  }, [userTrainRunIntervalsForTrainRoute]);

  // When we have loaded all TrainsRuns for the filter and outsideFilterTrainRuns (only id independent), we can store them
  // Once storedTrainRunIds is set to an array (can be empty), we can query
  useSetTrainRuns({
    loading: loading || !filterProps || !trainRouteTrainRunsWithMaybeTrainRouteOverrides,
    organization: organization,
    filterProps,
    trainRoutes: trainProps.trainRouteProps.trainRoutes,
    trainRuns,
    setTrainRuns,
    outsideFilterTrainRuns,
    setOutsideFilterTrainRuns,
    trainRouteTrainRunsWithMaybeTrainRouteOverrides
  });

  const eligibleTrainRunLookup = indexBy(prop('id'), trainRouteTrainRunsWithMaybeTrainRouteOverrides || [])
  // Tracks the OrderedTrainRuns within the filter.
  // This cannot compute until outsideFilterTrainRuns are synced with trainRouteTrainRunsWithMaybeTrainRunOverrides
  const orderedTrainRunsWithinFilter = useNotLoadingMemo(
    loading || any(
      outsideFilterTrainRun => !propOr(false, outsideFilterTrainRun.id, eligibleTrainRunLookup),
      outsideFilterTrainRuns || []
    ),
    () => {
      const outsideFilterTrainRunsIds = map(prop('id'), outsideFilterTrainRuns || []);
      return filter(
        trainRun => !includes(trainRun.id, outsideFilterTrainRunsIds),
        trainRuns || []
      );
    }, [trainRuns, trainRouteTrainRunsWithMaybeTrainRouteOverrides, outsideFilterTrainRuns]
  );

  useNotLoadingEffect(loading || !filterProps || !trainRuns, () => {
    setAllKnownTrainRunDepartureTimes(existing => {
      return compose(
        times => {
          return sortBy(prop('fullTime'), times);
        },
        times => {
          return uniqBy(prop('fullTime'), times);
        },
        concat(existing),
        map(
          trainRun => {
            return {
              label: localizedTimeNoTimezone(trainRun.departureDatetime),
              fullTime: localizedTime(trainRun.departureDatetime)
            };
          }
        )
      )(trainRuns);
    });
  }, [trainRuns]);

  const localPropsNotReady = loading ||
    !filterProps ||
    !trainRuns ||
    !orderedTrainRunsWithinFilter ||
    !outsideFilterTrainRuns;

  const loadingExplanation = useMemo(always({
    'trainProps.dateProps.loading': trainProps.dateProps.loading,
    filterProps,
    trainRuns,
    outsideFilterTrainRuns,
    orderedTrainRunsWithinFilter
  }), [loading, trainRuns, outsideFilterTrainRuns]);
  return <TrainMapDependency {...{
    appProps,
    organizationProps,
    trainProps: {
      ...trainProps,
      trainRunProps: {
        loadingExplanation,
        loading: localPropsNotReady,
        trainRuns, setTrainRuns, outsideFilterTrainRuns, setOutsideFilterTrainRuns,
        allKnownTrainRunDepartureTimes,
        userTrainRunIntervalsForAllTrainRoutes, setUserTrainRunIntervalsForAllTrainRoutes,
        userTrainRunIntervalsForTrainRoute,
        filterProps,
        orderedTrainRunsWithinFilter
      }
    },
    mapProps
  }} />;
};
TrainRunDependency.displayName = 'TrainRunDependency';
export default TrainRunDependency;