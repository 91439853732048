import {useMemo, useState} from 'react';
import {useTrainApiSetAvailableDatesAndDateRangeFilter} from 'async/trainAppAsync/hooks/typeHooks/trainRunHooks.js';
import {useSetDateInterval} from 'async/trainAppAsync/hooks/typeHooks/trainDateHooks.js';
import {trainRunFilterDateRange} from 'async/trainAppAsync/hooks/trainRunFilterHooks/trainFilterDateRangeHooks.js';
import {always, is, toLower} from 'ramda';
import {
  useUpdateOrCreateDateRecurrenceFilter
} from 'async/trainAppAsync/hooks/trainRunFilterHooks/trainFilterDateRecurrenceHooks.js';
import {useLocalStorage} from 'utils/hooks/useLocalStorage.js';
import {LOCAL_STORAGE_TRAIN_RUN_FILTER_DATE_RANGE} from 'appConfigs/appConfig.js';
import {endOfDay, isValid, parseISO} from 'date-fns';
import {addDateRangeFilters, extractDateRangeFilters} from 'appUtils/trainAppUtils/trainFilterDateRangeUtils.js';
import RideComfortRailwayLineDependency from "../rideComfortDependencies/RideComfortRailwayLineDependency.tsx";
import {ridcComfortDataThresholds} from "../../../appConfigs/rideComfortConfigs/rideComfortDataThresholdsConfig.ts";
import {
  resolveOrganizationConfig
} from "../../../config/rideComfortOrganizationConfigs/rideComfortOrganizationResolver.ts";


/**
 * Loads/Updates date props into trainProps.dateProps
 * Depends directly on trainRouteProps
 * @param appProps
 * @param organizationProps
 * @param trainProps
 * @param children
 * @return {*}
 * @constructor
 */
const DateDependency = ({ appProps, organizationProps, trainProps }) => {
  const loading = trainProps.trainRouteProps.loading;

  // The date chosen from TrainScheduleDateChooser. This makes the list of TrainRuns show the top item
  // as the first TrainRun of the selectedDate whenever it is set. However when the user scrolls, the
  // setSelectedDate is called whenever the top visible item is a new date
  const [selectedDate, setSelectedDate] = useState(null);
  // Selected date interval. For now this is just the interval of selected date
  const [dateInterval, setDateInterval] = useState(null);

  const [availableDates, setAvailableDates] = useState(null);

  // trainRunFilterWithTrainRoute is the parent filter of trainRunFilterWithDateRanges
  const parentTrainRunFilter = trainProps.trainRouteProps.trainRunFilterWithTrainRoute

  // Store a TrainRunFilter that combines the parent trainRunFilterWithTrainRoute with DateRangeFilters
  const [trainRunFilterWithDateRanges, setTrainRunFilterWithDateRanges] = useLocalStorage({
    loading,
    serializer: filter => {
      // Extract the dateRangeFilters to serialize
      return extractDateRangeFilters(filter, {})
    },
    postProcessInitialValue: dateRangeFilter => {
      // Rehydrate with parentTrainRunFilter
      return addDateRangeFilters(parentTrainRunFilter, dateRangeFilter);
    },
    // We need all dates and times to be deserialized to Datetimes.
    customParser: always((key, value) => {
      if (is(String, value)) {
        const parsedDate = is(String, value) && parseISO(value)
        return isValid(parsedDate) ? parsedDate : value
      }
      return value
    }),
    parserArgument: null,
    // Expire at the end of the day so that the user sees the latest date
    expiry: endOfDay(new Date()).getTime()
  }, LOCAL_STORAGE_TRAIN_RUN_FILTER_DATE_RANGE, null);

  // Memoized call to extract the single dateRange from trainRunFilterWithDateRanges
  const dateRange = trainRunFilterDateRange(trainRunFilterWithDateRanges);

  // Store a TrainRunFilter that combines the parent trainRunFilterWithTrainRoute
  // with DateRecurrence
  const [trainRunFilterWithDateRecurrences, setTrainRunFilterWithDateRecurrences] = useState(null);

  const organization = organizationProps.organization;
  const trainRoute = trainProps.trainRouteProps.trainRoute;

  // Tracks the modal to create a DateRange filter
  const [choosingDateRange, setChoosingDateRange] = useState(false);
  // Tracks the modal to create a DateRecurrence filter
  const [choosingDateRecurrence, setChoosingDateRecurrence] = useState(false);

  // Get the date range and available dates of TrainRuns with sensor data for the current trainRoute
  useTrainApiSetAvailableDatesAndDateRangeFilter({
    loading,
    organization,
    trainRoute,
    setAvailableDates,
    selectedDate,
    setSelectedDate,
    parentTrainRunFilter,
    trainRunFilterWithDateRanges,
    setTrainRunFilterWithDateRanges
  });

  // The date interval mirrors the selected date
  useSetDateInterval({ selectedDate, setDateInterval });

  // Adds a TrainRunDateRecurrenceFilter to the parent filter TrainRunDateRangeFilter
  // There is initially no DateRecurrenceFilter, so this initially matches TrainRunDateRangeFilter
  useUpdateOrCreateDateRecurrenceFilter({
    loading: loading || !trainRunFilterWithDateRanges,
    parentTrainRunFilter: trainRunFilterWithDateRanges,
    trainRunFilterWithDateRecurrences,
    setTrainRunFilterWithDateRecurrences
  });

  const localPropsNotReady = loading || !dateRange || !selectedDate || !dateInterval || !trainRunFilterWithDateRanges ||
    !trainRunFilterWithDateRecurrences;
  const loadingExplanation = useMemo(always({
    'trainProps.trainRouteProps.loading': trainProps.trainRouteProps.loading,
    dateRange,
    selectedDate,
    dateInterval,
    trainRunFilterWithDateRanges,
    trainRunFilterWithDateRecurrences
  }), [
    trainProps.trainRouteProps.loading,
    dateRange,
    selectedDate,
    dateInterval,
    trainRunFilterWithDateRanges,
    trainRunFilterWithDateRecurrences
  ]);
  const organizationConfig = trainProps.trainRouteProps.loading ?
      null :
      resolveOrganizationConfig(toLower(organization.sourceKey));

  return <RideComfortRailwayLineDependency {...{
    appProps,
    organizationProps: {
      ...organizationProps,
      organization: {
        ...organizationProps.organization,
        trains: organizationConfig?.trains
      }
    },
    trainProps: {
      ...trainProps,
      dateProps: {
        loading: localPropsNotReady,
        loadingExplanation,
        dateRange, selectedDate, setSelectedDate, dateInterval, setDateInterval,
        availableDates,
        trainRunFilterWithDateRanges, setTrainRunFilterWithDateRanges,
        trainRunFilterWithDateRecurrences, setTrainRunFilterWithDateRecurrences,
        choosingDateRange, setChoosingDateRange,
        choosingDateRecurrence, setChoosingDateRecurrence
      },
      rideComfortRailwayLineProps: {loading: !organizationProps, railwayLines: organizationConfig?.railwayLines},
      trainFormationProps: {
        dataThresholds: ridcComfortDataThresholds(null)
      },
    }
  }} />;
};
DateDependency.displayName = 'DateDependency';
export default DateDependency;