import { strPathEq } from '@rescapes/ramda';
import {
  nameOfRoutePointStopNamePath,
} from 'appUtils/trainAppUtils/trainDataUtils.js';
import { routePointsOfTrainRoute } from 'appUtils/trainAppUtils/trainRouteUtils.js';
import { addIndex, always, find, identity, ifElse, lensIndex, map, prop, set } from 'ramda';
import { useNotLoadingMemo } from 'utils/hooks/useMemoHooks.js';

/**
 * Creates the props for each needed TrainLineStation
 * @param {Boolean} loading Returns undefined if true
 * @param scheduledStopPointsAndMaybeTimes
 * @param offsetLefts
 * @param recalculateOffsetLefts
 * @param scheduledStopPointsAndMaybeTimesWithOffsetLefts
 * @param trainRoute
 * @param trainRun
 * @param trainRouteOrRunInterval
 * @param onlyStopsNearInterval
 * @param spaceGeospatially
 * @param width
 * @param distanceRange
 * @param setsetOffsetLefts
 * @returns {*}
 */
const useMemoTrainLineStationProps = (
  {
    loading,
    trainRoute,
    trainRun,
    trainRouteOrRunInterval,
    scheduledStopPointsAndMaybeTimes,
    offsetLefts,
    recalculateOffsetLefts,
    scheduledStopPointsAndMaybeTimesWithOffsetLefts,
    onlyStopsNearInterval,
    spaceGeospatially,
    width,
    distanceRange,
    setOffsetLefts
  }) => {

  return useNotLoadingMemo(loading, () => {
    // Find the distance to the reference stop (e.g. Oslo S) if it is defined on the route
    const measureDistanceFromDistance = ifElse(
      prop('measureDistancesFrom'),
      trainRoute => {
        const routePoint = find(
          routePoint => strPathEq(
            nameOfRoutePointStopNamePath,
            trainRoute.measureDistancesFrom,
            routePoint
          ),
          routePointsOfTrainRoute(trainRoute)
        );
        return routePoint.routeDistance;
      },
      always(null)
    )(trainRoute);

    return addIndex(map)((scheduledStopPointAndMaybeDateTime, index) => {
        return {
          // Pseudo end-stations lack a label
          key: scheduledStopPointAndMaybeDateTime.shortName || scheduledStopPointAndMaybeDateTime.routeDistance,
          offsetLeft: spaceGeospatially ? offsetLefts[index] : null,
          scheduledStopPointsAndMaybeTimesWithOffsetLefts,
          setOffsetLeft:
          // If we position with flex, not geospatially, we rely on the stations' computed positions
          // to set offsetLefts, which are are used to draw the lines between stations.
          // If we are spacing geospatially, we already know what the offests are
            spaceGeospatially ?
              identity :
              offsetLeft => {
                return setOffsetLefts(offsetLefts => {
                  return set(lensIndex(index),
                    offsetLeft,
                    offsetLefts);
                });
              },
          recalculateOffsetLefts,
          scheduledStopPointAndMaybeDateTime,
          trainRun,
          trainRoute,
          measureDistanceFromDistance,
          trainRouteOrRunInterval,
          limitedStations: onlyStopsNearInterval,
          spaceGeospatially,
          parentWidth: width,
          distanceRange
        };
      },
      scheduledStopPointsAndMaybeTimes
    );
  }, [
    trainRoute,
    trainRun,
    trainRouteOrRunInterval,
    scheduledStopPointsAndMaybeTimes,
    offsetLefts,
    scheduledStopPointsAndMaybeTimesWithOffsetLefts,
    onlyStopsNearInterval,
    spaceGeospatially,
    width,
    distanceRange,
  ])
};
export default useMemoTrainLineStationProps