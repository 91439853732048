// Default tolerances
// TODO define styles in MUI railbedTheme
import { colors } from 'theme/colors.ts';
import { TFunction } from 'i18next';
import { DataThresholdConfig } from '../../../railbedTypes/dataVisualizations/dataThreshold';

/**
 * Data threshold railbedConfig
 * @param t Translation function for the human-readable label
 * @returns
 * @constructor
 */
export const dataThresholdConfigs = ({ t }: { t: TFunction }): DataThresholdConfig[] =>
  [
    { sourceKey: 'good', label: t('good'), style: { color: colors.green } },
    { sourceKey: 'warning', label: t('warning'), style: { color: colors.orange } },
    { sourceKey: 'error', label: t('error'), style: { color: colors.reallyRed } }
  ];

export const MAP_PITCH = 50;
export const TRAIN_MAP_PITCH = MAP_PITCH;

// Multiply extrusion columns on the map by this factor to make them more visible
export const EXTRUSION_HEIGHT_FACTOR = 200;
