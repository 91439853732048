import { evalFilter } from 'appUtils/trainAppUtils/trainFilterEval.js';
import { equals, lensProp, over } from 'ramda';

/**
 * Returns true if the object represents a Formation filter in the form
 any: [{
 equals: [
 value or { view: { lensPath: ['prop', 'path', 'to', 'formation', 'id'] } }
 new Date('2022-09-01'),
 { view: { lensPath: ['trainRun', 'formation', 'id] } }
 ],
 ...
 }]
 * @param obj
 * @returns {{return}}
 */
export const isFormation = obj => {
  return equals('FormationFilter', obj.__typename);
};
/**
 * Evals a FormationFilter
 * @param trainRunFilterFormation
 * @param props
 * @returns {*}
 */
export const evalFormation = (trainRunFilterFormation, props) => {
  return over(
    lensProp('equals'),
    equalsFilter => {
      return evalFilter(equalsFilter, props);
    },
    trainRunFilterFormation
  );
};