import React from 'react';
import TrainChartDisplayContainer
  from 'components/apps/trainAppComponents/trainChartComponents/TrainChartDisplayContainer.js';

/**
 * Left side of page presenting a list of countries and the railroads available for each country
 * @param appProps
 * @param organizationProps
 * @param trainProps
 * @param {Object} componentProps
 * @param {[Object]} componentProps.dataPathsConfigs
 * @param {String} componentProps.chosenAcceleration 'y' or 'xyz'
 * @param {Function} setChosenAcceleration
 * @returns {JSX.Element}
 * @constructor
 */
const TrainRunningCharacteristics = (
  {
    appProps,
    organizationProps,
    trainProps,
    componentProps: {
      dataPathsConfigs,
    }
  }) => {
  return <TrainChartDisplayContainer {...{
    appProps,
    organizationProps,
    trainProps,
    componentProps: {
      dataPathsConfigs,
    }
  }}
  />;
};

TrainRunningCharacteristics.propTypes = {};
export default TrainRunningCharacteristics;
