import { LoadingExplanation } from '../../../railbedTypes/async/loadingExplanation';
import { lensPath, mergeAll, mergeRight, over, split } from 'ramda';
import { TrainProps, TrainPropsWithDerived } from '../../../railbedTypes/propTypes/trainProps';
import { CemitTypename } from '../../../railbedTypes/cemitTypename.ts';
import { typeObject } from '../../typeUtils/typenameUtils.ts';
import { useMemo } from 'react';
import { asCemitTypeOrThrow, maybeAsCemitType } from '../../../railbedClasses/cemitClassResolvers.ts';
import { PropStatus } from '../../../railbedTypes/propTypes/propStatus';
import { TrainRunFilter } from '../../../railbedTypes/trainRunFilters/trainRunFilter';
import { Perhaps } from '../../../railbedTypes/typeHelpers/perhaps';

/**
 * Merge in TrainProps
 * @param trainProps The existing trainProps
 * @param childTrainPropsKeyOrPath Dot-separated string path whither to insert new trainProps
 * @param childTrainProps The child trainProps to insert
 * @param trainRunFilter The child't trainRunFilter, which is set to trainProps.trainRunFilter to serve
 * as the parent TrainRunFilter to the next dependency and to be the most complete trainRunFilter available
 * to the containers and railbedComponents
 * @param localPropsNotReady True if the child's props (or parent's) are not loaded
 * @param childLoadingExplanation Explanation of the state of the child props if localPropsNotReady is true, else {}
 */
export const mergeTrainProps = (
  trainProps: TrainProps | TrainPropsWithDerived,
  // TODO can be nested deep in trainProps, but I don't know how to represent that in TS yet
  childTrainPropsKeyOrPath: keyof TrainProps | string,
  // This should be all the deep prop objects of TrainProps. localPropsNotReady is used for loading
  childTrainProps: Omit<TrainProps[keyof TrainProps], 'scope' | 'loading'>,
  trainRunFilter: Perhaps<TrainRunFilter>,
  localPropsNotReady: boolean,
  childLoadingExplanation: LoadingExplanation
) => {

  // merges the child loadingExplanation (if loading) with the existing trainProps.loadingExplanation
  // The child one is always keyed at the top level by childTrainPropsKeyOrPath
  const loadingExplanation: LoadingExplanation = useMemo<LoadingExplanation>(() => {
    return mergeRight(
      trainProps.loadingExplanation,
      { [childTrainPropsKeyOrPath]: localPropsNotReady ? childLoadingExplanation : {} }
    );
  }, [trainProps.loading, localPropsNotReady, trainProps.loadingExplanation, childLoadingExplanation]);

  // Update the top-level loading status and asDerived fetchers
  const mergedTrainProps: TrainProps | TrainPropsWithDerived = mergeAll([
    trainProps,
    // If one is defined, set the trainRunFilter to the top level, overwriting what is in trainProps
    trainRunFilter ? { trainRunFilter } : {},
    {
      loading: trainProps.loading || localPropsNotReady,
      loadingExplanation,
      maybeAsDerived: () => {
        // This indicates if the type is TrainPropsWithDerived.
        // TODO Whether or not the trainProps have child props that are ...WithDerived needs to be calculated
        // by the Dependencies
        return maybeAsCemitType<TrainPropsWithDerived>(CemitTypename.trainPropsWithDerived, trainProps, this);
      },
      // Used to get TrainPropsWithDerived when they are expected. This will throw if
      asDerivedOrThrow: () => {
        // TODO this throws if the type is not TrainPropsWithDerived
        return asCemitTypeOrThrow<TrainPropsWithDerived>(CemitTypename.trainPropsWithDerived, trainProps, this);
      }
    } as PropStatus<TrainProps, TrainPropsWithDerived>
  ]);

  /**
   * Set child props at the childTrainPropsKeyOrPath level.
   * Normally there shouldn't be anything defined beforehand at existingLensProps, but we merge them just in case.
   * TOOD it might be better to throw and error if two Dependency railbedComponents attempt to updated the same
   * childTrainPropsKeyOrPath
   */
  const mergedTrainPropsWithChildProps = over<TrainPropsWithDerived, PropStatus<TrainProps, TrainPropsWithDerived>>(
    lensPath(split('.', childTrainPropsKeyOrPath)),
    existingLensProps => {
      return mergeRight(
        existingLensProps,
        {
          ...childTrainProps,
          loading: localPropsNotReady
        }
      );
    },
    mergedTrainProps
  );
  return typeObject<TrainProps>(
    CemitTypename.trainProps,
    mergedTrainPropsWithChildProps
  ) as TrainProps;
};
