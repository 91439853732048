import {
  addDateRangeFilters,
  createDateRangeFilter,
  extractDateRanges,
  isDateRange
} from 'appUtils/trainAppUtils/trainFilterDateRangeUtils.js';
import { clearFilterAny } from 'appUtils/trainAppUtils/trainFilterUtils.js';
import { compose, equals } from 'ramda';
import { onlyOneValueOrNoneThrow } from 'utils/functional/functionalUtils.js';
import { memoized } from '@rescapes/ramda';

/**
 * Update trainRunFilterWithDateRanges to the given dateRange
 * @param trainRunFilterWithDateRanges
 * @param setTrainRunFilterWithDateRanges
 * @param dateRange
 */
export const updateTrainRunFilterDateRange = (
  {
    trainRunFilterWithDateRanges,
    setTrainRunFilterWithDateRanges
  }, dateRange) => {

  const currentDateRange = trainRunFilterDateRange(trainRunFilterWithDateRanges);
  if (equals(currentDateRange, dateRange)) {
    return;
  }
  const dateRangeFilter = createDateRangeFilter(dateRange);
  const updatedTrainRunFilterWithDateRanges = compose(
    trainRunFilter => {
      return addDateRangeFilters(
        trainRunFilter,
        dateRangeFilter
      );
    },
    trainRunFilter => {
      // TODO since we only allow one DateRange filter, clear the previous first
      return clearFilterAny(
        isDateRange,
        trainRunFilter
      );
    }
  )(trainRunFilterWithDateRanges);
  setTrainRunFilterWithDateRanges(updatedTrainRunFilterWithDateRanges);
};

/***
 * Return the single dateRange from trainRunFilterWithDateRanges
 * @param trainRunFilterWithDateRanges
 * @returns {*}
 */
export const trainRunFilterDateRange = memoized(trainRunFilterWithDateRanges => {
  return onlyOneValueOrNoneThrow(extractDateRanges(trainRunFilterWithDateRanges, {}));
});