import { TrainRoute, TrainRouteMinimized, TrainRouteWithDerived } from '../railbedTypes/trainRoutes/trainRoute';
import { DirectionType } from '../railbedTypes/trainRoutes/directionType.ts';
import { ServiceLine } from '../railbedTypes/trainRoutes/serviceLine';
import { OrderedRoutePoint, OrderedRoutePointWithDerived } from '../railbedTypes/trainRoutes/orderedRoutePoint';
import { TrackData, TrackDataMinimized, TrackDataWithDerived } from '../railbedTypes/railways/trackData';
import { ScheduledStopPoint } from '../railbedTypes/stops/scheduledStopPoint';
import { Cemited } from '../railbedTypes/cemited';
import { Identified } from '../railbedTypes/identified';
import { CemitTypename } from '../railbedTypes/cemitTypename.ts';
import {
  TrainRouteGroup,
  TrainRouteGroupMinimized,
  TrainRouteGroupWithDerived
} from '../railbedTypes/trainRoutes/trainRouteGroup';
import { Operator } from '../railbedTypes/operators/operator';
import { FeatureCollectionU, FeatureU, LineStringU, MultiLineStringU, PointU } from '../railbedTypes/geometry/geojsonUnions';

import {
  TrainRouteOrGroupMinimized,
  TrainRouteOrGroupTrackData,
  TrainRouteOrGroupTrackDataWithDerived
} from '../railbedTypes/trainRoutes/trainRouteOrGroup';
import { TrainRunGroupPreloaded, TrainRunGroupMinimized } from '../railbedTypes/trainRuns/trainRunGroup';
import { ClassFromObj } from '../railbedTypes/classes';
import {
  TrainRunFilter,
  TrainRunFilterComponent,
  TrainRunFilterExpression
} from '../railbedTypes/trainRunFilters/trainRunFilter';
import {
  DaysOrDatesOrTimes,
  TrainRunFilterCall,
  TrainRunFilterDateRecurrence,
  TrainRunFilterDateRecurrenceDate,
  TrainRunFilterDateRecurrenceDay,
  TrainRunFilterDateRecurrenceTime
} from '../railbedTypes/trainRunFilters/trainRunFilterDateRecurrence';
import { TrainRunFilterWithDateRange } from '../railbedTypes/trainRunFilters/trainRunFilterDateRange';
import { Track } from '../railbedTypes/railways/track';
import { Perhaps } from '../railbedTypes/typeHelpers/perhaps';
import { int } from 'utrie/dist/types/Trie';
import { RoutePoint, RoutePointWithDerived } from '../railbedTypes/trainRoutes/routePoint';
import { PointProjection } from '../railbedTypes/geometry/pointProjection';
import { RailwayLineMinimized } from '../railbedTypes/railways/railwayLine';
import {
  EquipmentAttributeDateRange,
  EquipmentAttributeSensorDataTrainRunGroup,
  EquipmentAttributeSensorDataTrainRunGroupPreloaded
} from '../railbedTypes/trainRuns/equipmentAttributeSensorDataTrainRunGroup';
import { SensorDataTrainRunGroupPreloaded } from '../railbedTypes/trainRuns/sensorDataTrainRunGroupPreloaded';
import { Minimized } from '../railbedTypes/cemitTypes/minimized';
import { Activity } from '../railbedTypes/userState/activity';
import { WheelGroup } from '../railbedTypes/trains/wheelGroup';
import { User } from '@sentry/react';
import { CemitApiLoadingStatus } from '../railbedTypes/apis/cemitApiLoadingStatus.ts';
import { WheelAttributeTimeSeries } from '../railbedTypes/attributeAlerts/wheelAttributeTimeSeries.ts';

export class CemitedClass implements Cemited {
  __typename: CemitTypename;

  constructor(obj: Cemited) {
    this.__typename = obj.__typename;
  }
}

export class IdentifiedClass extends CemitedClass implements Identified {
  id?: string;
  sourceKey?: string;
  name?: string;
  description?: string;

  constructor(obj: Identified) {
    super(obj);
    this.id = obj.id;
    this.sourceKey = obj.sourceKey;
    this.name = obj.name;
    this.description = obj.description;
  }
}

export class TrainRouteMinimizedClass extends IdentifiedClass implements TrainRouteMinimized {
  constructor(obj: TrainRunGroupPreloaded) {
    super(obj);
  }
}

export class TrainRouteClass extends TrainRouteMinimizedClass implements TrainRoute {
  __typename = CemitTypename.trainRoute;
  version?: number;
  directionType?: DirectionType;
  inverseTrainRouteId?: string;
  serviceLine: ServiceLine;
  serviceLineId: string;
  orderedRoutePoints: OrderedRoutePoint[];
  // Calculated on the frontend
  routeDistance: number;
  trackData: TrainRouteOrGroupTrackData;
  orderedTracks: [Track];


  constructor(obj: TrainRoute) {
    super(obj);
    this.version = obj.version;
    this.directionType = obj.directionType;
    this.inverseTrainRouteId = obj.inverseTrainRouteId;
    this.serviceLine = obj.serviceLine;
    this.serviceLineId = obj.serviceLineId;
    this.orderedRoutePoints = obj.orderedRoutePoints;
    this.trackData = obj.trackData;
  }
}

export class TrackDataMinimizedClass extends CemitedClass implements TrackDataMinimized {


  constructor(obj: TrackDataMinimized) {
    super(obj);
    this.__typename = CemitTypename.trackDataMinimized;

  }
}

/**
 * Different geojson Feature representations of a railway track
 */
export class TrackDataClass extends TrackDataMinimizedClass implements TrackData {
  trackAsLineString: FeatureU<LineStringU>;
  trackAsMultiLineString: FeatureU<MultiLineStringU>;
  trackAsPointFeatureCollection: FeatureCollectionU<PointU>;
  lineSegments: FeatureU<LineStringU>[];

  constructor(obj: TrackData) {
    super(obj);
    this.__typename = CemitTypename.trackData;
    this.trackAsLineString = obj.trackAsLineString;
    this.trackAsMultiLineString = obj.trackAsMultiLineString;
    this.trackAsPointFeatureCollection = obj.trackAsPointFeatureCollection;
    this.lineSegments = obj.lineSegments;
  }
}

export class TrackDataWithDerivedClass extends TrackDataClass implements TrackDataWithDerived {
  boundingBox: number[];
  center: FeatureU<PointU>;

  constructor(obj: TrackDataWithDerived) {
    super(obj);
    this.__typename = CemitTypename.trackDataWithDerived;
    this.boundingBox = obj.boundingBox;
    this.center = obj.center;
  }
}


class TrainRouteWithDerivedClass extends TrainRouteClass implements TrainRouteWithDerived {
  __typename = CemitTypename.trainRouteWithDerived;
  trackData: TrainRouteOrGroupTrackDataWithDerived;

  constructor(obj: TrainRouteWithDerived) {
    super(obj);
    this.trackData = obj.trackData;
  }
}

export class TrainRouteGroupMinimizedClass extends IdentifiedClass implements TrainRouteGroupMinimized {
  constructor(obj: TrainRunGroupPreloaded) {
    super(obj);
  }
}

export class TrainRunFilterClass extends IdentifiedClass implements TrainRunFilter {
  allPass?: (TrainRunFilter | TrainRunFilterExpression)[];
  any?: TrainRunFilterExpression[] | TrainRunFilter[];
  equals?: [TrainRunFilterComponent, TrainRunFilterComponent];
  and?: TrainRunFilterExpression;
  or?: TrainRunFilterExpression;

  constructor(obj: TrainRunFilter) {
    super(obj);
    this.__typename = CemitTypename.trainRunFilter;
    this.allPass = obj.allPass;
    this.any = obj.any;
    this.equals = obj.equals;
    this.and = obj.and;
    this.or = obj.or;
  }
}

export class TrainRunFilterDateRangeClass extends TrainRunFilterClass implements TrainRunFilterWithDateRange {
  constructor(obj: TrainRunFilterWithDateRange) {
    super(obj);
  }
}

export class TrainRunFilterDateRecurrenceClass extends TrainRunFilterClass implements TrainRunFilterDateRecurrence {
  includes?: [DaysOrDatesOrTimes, TrainRunFilterCall];

  constructor(obj: TrainRunFilterDateRecurrence) {
    super(obj);
    obj.__typename = CemitTypename.trainRunFilterDateRecurrence;
    obj.includes = this.includes;
  }
}

export class TrainRunFilterDateRecurrenceDateClass extends TrainRunFilterClass implements TrainRunFilterDateRecurrenceDate {
  constructor(obj: TrainRunFilterDateRecurrenceDate) {
    super(obj);
    obj.__typename = CemitTypename.trainRunFilterDateRecurrenceDate;
  }
}

export class TrainRunFilterDateRecurrenceDayClass extends TrainRunFilterClass implements TrainRunFilterDateRecurrenceDate {
  constructor(obj: TrainRunFilterDateRecurrenceDay) {
    super(obj);
    obj.__typename = CemitTypename.trainRunFilterDateRecurrenceDay;
  }
}

export class TrainRunFilterDateRecurrenceTimeClass extends TrainRunFilterClass implements TrainRunFilterDateRecurrenceDate {
  constructor(obj: TrainRunFilterDateRecurrenceTime) {
    super(obj);
    obj.__typename = CemitTypename.trainRunFilterDateRecurrenceTime;
  }
}

export class TrainRouteGroupClass extends TrainRouteGroupMinimizedClass implements TrainRouteGroup {

  directionType?: DirectionType;

  startScheduledStopPoint?: ScheduledStopPoint;
  endScheduledStopPoint?: ScheduledStopPoint;

  inverseTrainRouteGroupId?: string;
  operator?: Operator;
  operatorId?: string;

  serviceLine: ServiceLine;
  serviceLineId?: string;
  trainRoutes: TrainRoute[];
  orderedRoutePoints: OrderedRoutePoint[];

  trackData: TrainRouteOrGroupTrackDataWithDerived;

  constructor(obj: TrainRouteGroup) {
    super(obj);
    this.__typename = CemitTypename.trainRouteGroup;
    this.directionType = obj.directionType;

    this.startScheduledStopPoint = obj.startScheduledStopPoint;
    this.endScheduledStopPoint = obj.endScheduledStopPoint;

    this.inverseTrainRouteGroupId = obj.inverseTrainRouteGroupId;
    this.operator = obj.operator;
    this.operatorId = obj.operatorId;

    this.serviceLine = obj.serviceLine;
    this.serviceLineId = obj.serviceLineId;
    this.trainRoutes = obj.trainRoutes;
    this.orderedRoutePoints = obj.orderedRoutePoints;
  }
}

export class TrainRouteGroupWithDerivedClass extends TrainRouteGroupClass implements TrainRouteGroupWithDerived {

  trackData: TrainRouteOrGroupTrackDataWithDerived;

  constructor(obj: TrainRouteGroupWithDerived) {
    super(obj);
    this.__typename = CemitTypename.trainRouteGroupWithDerived;
    this.trackData = obj.trackData;
  }
}

export class TrainRouteOrGroupTrackDataMinimizedClass extends CemitedClass implements TrackDataMinimized {

  constructor(obj: TrackDataMinimized) {
    super(obj);
    this.__typename = CemitTypename.trackDataMinimized;
  }
}

export class TrainRouteOrGroupTrackDataClass extends TrainRouteOrGroupTrackDataMinimizedClass implements TrainRouteOrGroupTrackData {
  trackAsLineString: FeatureU<LineStringU>;
  orderedTracks: [Track];

  constructor(obj: TrainRouteOrGroupTrackData) {
    super(obj);
    this.__typename = CemitTypename.trainRouteOrGroupTrackData;
    this.trackAsLineString = obj.trackAsLineString;
    this.orderedTracks = obj.orderedTracks;
  }
}

export class TrainRouteOrGroupTrackDataWithDerivedClass extends TrainRouteOrGroupTrackDataClass implements TrainRouteOrGroupTrackDataWithDerived {
  lineSegments: FeatureU<LineStringU>[];
  trackAsLineFeatureCollection: FeatureCollectionU<LineStringU>;
  boundingBox: number[];
  center: FeatureU<PointU>;
  referencePointDistance?: Perhaps<number>;
  measureDistancesFrom?: Perhaps<ScheduledStopPoint>;

  constructor(obj: TrainRouteOrGroupTrackDataWithDerived) {
    super(obj);
    this.__typename = CemitTypename.trainRouteOrGroupTrackDataWithDerived;
    this.lineSegments = obj.lineSegments;
    this.trackAsLineFeatureCollection = obj.trackAsLineFeatureCollection;
    this.boundingBox = obj.boundingBox;
    this.center = obj.center;
    this.referencePointDistance = obj.referencePointDistance;
    this.measureDistancesFrom = obj.measureDistancesFrom;
  }
}

export class RoutePointClass extends IdentifiedClass implements RoutePoint {
  version: number;
  projections: PointProjection[];
  railwayLines: RailwayLineMinimized[];

  constructor(obj: RoutePoint) {
    super(obj);
    this.id = obj.id;
    this.version = obj.version;
    this.projections = obj.projections;
    this.railwayLines = obj.railwayLines;
  }
}

export class RoutePointWithDerivedClass extends RoutePointClass implements RoutePointWithDerived {
  routeDistance: number;

  constructor(obj: RoutePointWithDerived) {
    super(obj);
    this.routeDistance = obj.routeDistance;
  }
}

export class OrderedRoutePointClass extends IdentifiedClass implements OrderedRoutePoint {
  version?: int;
  order: int;
  routePoint: RoutePoint;

  constructor(obj: OrderedRoutePoint) {
    super(obj);
    this.version = obj.version;
    this.order = obj.order;
    this.routePoint = obj.routePoint;
  }
}

export class OrderedRoutePointWithDerivedClass extends OrderedRoutePointClass implements OrderedRoutePointWithDerived {
  routePoint: RoutePointWithDerived;

  constructor(obj: OrderedRoutePointWithDerived) {
    super(obj);
    this.routePoint = obj.routePoint;
  }
}

export class MinimizedClass extends IdentifiedClass implements Minimized {
}

export class TrainRunGroupMinimizedClass extends MinimizedClass implements TrainRunGroupMinimized {
  trainRouteOrGroup?: TrainRouteOrGroupMinimized;
  isPreconfigured?: boolean;
  overrideTrainRoute?: TrainRoute;

  constructor(obj: TrainRunGroupMinimized) {
    super(obj);
    this.trainRouteOrGroup = obj.trainRouteOrGroup;
    this.isPreconfigured = obj.isPreconfigured;
    this.overrideTrainRoute = obj.overrideTrainRoute;
  }
}

export class TrainRunGroupIncompleteClass extends TrainRunGroupMinimizedClass implements TrainRunGroupPreloaded {
  activity?: Activity;
  wheelGroups?: WheelGroup[];
  user?: User;
  loadingStatus?: CemitApiLoadingStatus;

  constructor(obj: TrainRunGroupPreloaded) {
    super(obj);
    this.activity = obj.activity;
    this.wheelGroups = obj.wheelGroups;
    this.user = obj.user;
    this.loadingStatus = obj.loadingStatus;
  }
}

export class SensorDataTrainRunGroupClass extends TrainRunGroupIncompleteClass implements SensorDataTrainRunGroupPreloaded {

}

export class EquipmentAttributeSensorDataTrainRunGroupPreloadedClass extends SensorDataTrainRunGroupClass implements EquipmentAttributeSensorDataTrainRunGroupPreloaded {
  equipmentAttributeSensorLoadingStatus: CemitApiLoadingStatus;

  constructor(obj: EquipmentAttributeSensorDataTrainRunGroupPreloaded) {
    super(obj);
    this.equipmentAttributeSensorLoadingStatus = obj.equipmentAttributeSensorLoadingStatus;
  }
}

export class EquipmentAttributeSensorDataTrainRunGroupClass extends EquipmentAttributeSensorDataTrainRunGroupPreloadedClass implements EquipmentAttributeSensorDataTrainRunGroup {
  equipmentAttributeDateRanges: EquipmentAttributeDateRange[]
  equipmentAttributeDateRangeErrors: EquipmentAttributeDateRange[]
  wheelAttributeTimeSeriesSets: WheelAttributeTimeSeries[]
  constructor(obj:EquipmentAttributeSensorDataTrainRunGroupClass) {
    super(obj)
    this.equipmentAttributeDateRanges = obj.equipmentAttributeDateRanges
    this.equipmentAttributeDateRangeErrors = obj.equipmentAttributeDateRangeErrors
    this.wheelAttributeTimeSeriesSets = obj.wheelAttributeTimeSeriesSets
  }
}

export const cemitTypeNameToClasses: Record<CemitTypename, ClassFromObj<CemitedClass> | ClassFromObj<CemitedClass>[]> = {
  [CemitTypename.trainRoute]: TrainRouteClass,
  [CemitTypename.trainRouteWithDerived]: TrainRouteWithDerivedClass,
  [CemitTypename.trainRouteGroup]: TrainRouteGroupClass,
  [CemitTypename.trainRouteGroupWithDerived]: TrainRouteGroupWithDerivedClass,
  [CemitTypename.trainRouteOrGroup]: [TrainRouteClass, TrainRouteGroupClass],
  [CemitTypename.trainRouteOrGroupWithDerived]: [TrainRouteWithDerivedClass, TrainRouteGroupWithDerivedClass],
  [CemitTypename.trainRouteOrGroupMinimized]: [TrainRouteMinimizedClass, TrainRouteGroupMinimizedClass],
  [CemitTypename.trainRouteMinimized]: TrainRouteMinimizedClass,
  [CemitTypename.trainRouteGroupMinimized]: TrainRouteGroupMinimizedClass,
  [CemitTypename.trainRunFilter]: TrainRunFilterClass,
  [CemitTypename.trainRunFilterDateRecurrence]: TrainRunFilterDateRecurrenceClass,
  [CemitTypename.trainRunFilterDateRecurrenceDate]: TrainRunFilterDateRecurrenceDateClass,
  [CemitTypename.trainRunFilterDateRecurrenceTime]: TrainRunFilterDateRecurrenceTimeClass,
  [CemitTypename.trainRunFilterDateRecurrenceDay]: TrainRunFilterDateRecurrenceDayClass,
  [CemitTypename.trainRouteOrGroupTrackData]: TrainRouteOrGroupTrackDataClass,
  [CemitTypename.trainRouteOrGroupTrackDataWithDerived]: TrainRouteOrGroupTrackDataWithDerivedClass,
  [CemitTypename.routePoint]: RoutePointClass,
  [CemitTypename.routePointWithDerived]: RoutePointWithDerivedClass,
  [CemitTypename.orderedRoutePoint]: OrderedRoutePointClass,
  [CemitTypename.orderedRoutePointWithDerived]: OrderedRoutePointWithDerivedClass,
  [CemitTypename.trainRunGroupMinimized]: TrainRunGroupMinimizedClass,
  [CemitTypename.trainRunGroupIncomplete]: TrainRunGroupIncompleteClass,
  [CemitTypename.sensorDataTrainRunGroup]: SensorDataTrainRunGroupClass,
  [CemitTypename.equipmentAttributeSensorDataTrainRunGroupPreloaded]: EquipmentAttributeSensorDataTrainRunGroupPreloadedClass,
  [CemitTypename.equipmentAttributeSensorDataTrainRunGroup]: EquipmentAttributeSensorDataTrainRunGroupClass
};
