import {Stack} from "@mui/material";
import okImage from "assets/images/rideComfortImages/ok.png";
import warningImage from "assets/images/rideComfortImages/warning.png";
import dangerImage from "assets/images/rideComfortImages/danger.png";
import React from "react";
import {map} from "ramda";
import {RideComfortGauge} from "../../../../types/rideComfort/rideComfortGauge";
import RideComfortErrorLevelComponent from "./RideComfortErrorLevelComponent.tsx";

type RideComfortErrorLevelsComponentProps = {
    rideComfortGauge: RideComfortGauge, labelVariant: string, iconSize: number, showCount: boolean
}
type RideComfortErrorLevelConfig = {
    level: keyof RideComfortGauge
    label: string
    image: string
}
const RideComfortErrorLevelsComponent = ({rideComfortGauge, labelVariant, iconSize, showCount = false}: RideComfortErrorLevelsComponentProps) => {

    const levels: JSX.Element[] = map(({level, label, image}: RideComfortErrorLevelConfig) => {
            return <RideComfortErrorLevelComponent {...{
                key: level,
                rideComfortGauge,
                showCount,
                labelVariant,
                level,
                label,
                image,
                iconSize
            }} />
        },
        [
            {level: 'normal', label: 'Normal', image: okImage} as RideComfortErrorLevelConfig,
            {level: 'warning', label: 'Warning', image: warningImage} as RideComfortErrorLevelConfig,
            {level: 'critical', label: 'Critical', image: dangerImage} as RideComfortErrorLevelConfig
        ]
    )

    return <Stack  {...{
        direction: 'row',
        sx: {
            flex: 1,
            minWidth: 0
        }
    }}>
        <Stack {...{
            key: 'iconLevels',
            sx: {
                minWidth: 0, minHeight: 0, flex: 1, overflow: 'hidden', position: 'relative',
                width: '100%',
                justifyContent: 'center',
            }
        }}>
            {levels}
        </Stack>
    </Stack>
}
RideComfortErrorLevelsComponent.displayName = 'RideComfortErrorLevelsComponent'
export default RideComfortErrorLevelsComponent
