import PropTypes from 'prop-types';
import { always, cond, head, lensPath, prop, propOr, set, T } from 'ramda';
import { useMemoTrainRunIntervalFromCrud } from 'async/trainAppAsync/hooks/typeHooks/trainRunIntervalHooks.js';
import TrainRunLineReadyContainer
  from 'components/apps/trainAppComponents/trainLineComponents/TrainRunLineReadyContainer.js';
import { loadingStateOfTrainRunLine } from 'appUtils/trainAppUtils/trainRunUtils.js';
import LoaderWithText from 'components/loading/LoaderWithText.js';
import { unlessLoadingValue } from 'utils/componentLogic/loadingUtils.js';

/**
 *  Displays a Train Run as a line with the stops and time of stops
 *  in the context of a TrainRunInterval or UserTrainRunInterval
 *  Alternatively displays a Route as a line with the stops and the distance of the stops
 * @param appProps
 * @param organizationProps
 * @param trainProps
 * @param componentProps
 * @param componentProps.spaceGeospatially,
 * @param componentProps.onlyStopsNearInterval
 * @param componentProps.limitedDistanceRange,
 * @param componentProps.showTrainRunIntervalBars,
 * @param componentProps.isTrainRouteLine
 * @param sx
 * @param sxTrainRunIntervalBar
 * @returns {JSX.Element|null}
 * @constructor
 */
export const TrainRunLineContainer = (
  {
    appProps,
    organizationProps,
    trainProps,
    componentProps: {
      spaceGeospatially,
      onlyStopsNearInterval,
      limitedDistanceRange,
      showTrainRunIntervalBars,
      isTrainRouteLine,
      isAggregate,
      isUserTrainRunLine,
      panelSize
    },
    sx = [],
    sxTrainRunIntervalBar = []
  }) => {

  // A TrainRunLine only supports one TrainRunInterval, and crudUserTrainRunIntervals
  // or crudTrainRunIntervals have already been filtered down to one TrainRunInterval that is defined
  // on the TrainRunLine
  // If we are in a loading state, this returns null
  const trainRunInterval = useMemoTrainRunIntervalFromCrud({
    loading: false,
    isUserTrainRunLine,
    isTrainRouteLine,
    limitedDistanceRange,
    trainRouteInterval: isAggregate ?
      trainProps.trainRouteProps.trainRouteAggregateInterval :
      trainProps.trainRouteProps.trainRouteInterval,
    crudUserTrainRunIntervals: trainProps.userTrainRunIntervalProps.crudUserTrainRunIntervals,
    crudTrainRunIntervals: trainProps.trainRunIntervalProps.crudTrainRunIntervals
  });

  const loading = loadingStateOfTrainRunLine(trainProps, trainRunInterval);

  // Get the current hover feature (from TrainMap) for the UserTrainRunLine (isUserTrainRunLine) or TrainRoute (isTrainRouteLine)
  // We currently don't support a hover feature for TrainRunInterval TrainRunLineContainers because they
  // don't show on the TrainMap
  const hoverFeature = unlessLoadingValue(loading,
    () => cond([
      [prop('isTrainRouteLine'), () => {
        // Get the TrainRoute's current hover feature if defined.
        // Current, this hover feature is simply the most recent UserTrainRunInterval feature hovered over on TrainMap
        return propOr(
          null,
          trainProps.trainRouteProps.trainRoute.id,
          appProps.hoverFeature);
      }],
      [prop('isUserTrainRunLine'), () => {
        // Get the UserTrainRunInterval's current hover feature if defined
        return propOr(null,
          head(trainProps.userTrainRunIntervalProps.crudUserTrainRunIntervals.list).sourceKey,
          appProps.hoverFeature
        );
      }],
      [T, always(null)]
    ])({ isUserTrainRunLine, isTrainRouteLine })
  );

  // Send the trainRunInterval to the children if it exists
  const trainPropsWithTrainRunInterval = trainRunInterval ? set(
    lensPath(['trainRunIntervalProps', 'trainRunInterval']),
    trainRunInterval,
    trainProps
  ) : trainProps;

  if (loading) {
    return <TrainRunLineLoading />;
  } else {
    return <TrainRunLineReadyContainer {...{
      appProps,
      organizationProps,
      trainProps: trainPropsWithTrainRunInterval,
      componentProps: {
        spaceGeospatially,
        onlyStopsNearInterval,
        limitedDistanceRange,
        showTrainRunIntervalBars,
        isTrainRouteLine,
        isAggregate,
        isUserTrainRunLine,
        hoverFeature,
        panelSize
      },
      sx,
      sxTrainRunIntervalBar
    }} />;
  }
};

TrainRunLineContainer.propTypes = {
  appProps: PropTypes.object.isRequired,
  organizationProps: PropTypes.object.isRequired,
  trainProps: PropTypes.shape().isRequired,
  //'trainProps.train': validateNotNilUnlessAnyTruthy(['userTrainRunIntervalProps.loading', 'componentProps.isTrainRouteLine']),
  componentProps: PropTypes.shape({
    limitedDistanceRange: PropTypes.object,
    isTrainRouteLine: PropTypes.bool.isRequired,
    isUserTrainRunLine: PropTypes.bool.isRequired,
    spaceGeospatially: PropTypes.bool.isRequired,
    onlyStopsNearInterval: PropTypes.bool.isRequired,
    showTrainRunIntervalBars: PropTypes.bool.isRequired
  }).isRequired,
  sx: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
  sxTrainRunIntervalBar: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)])
};

export default TrainRunLineContainer;

const TrainRunLineLoading = () => {
  return <LoaderWithText text='loadingTrainRunLine' />;
};