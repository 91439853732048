import {
  addIndex, any, equals,
  filter,
  forEach,
  forEachObjIndexed,
  groupBy,
  has,
  includes,
  indexBy,
  length,
  map,
  prop,
  propOr,
  reduce,
  slice,
  startsWith,
  zipWith
} from 'ramda';
import { chainMDeep, mapObjToValues } from '@rescapes/ramda';
import { MAP_PITCH } from 'appConfigs/trainConfigs/trainMapConfig.js';
import bbox from '@turf/bbox';

/**
 * Create a Mapbox geojson source configuration.
 * @param {Object} featureCollection Geojson FeatureCollection
 * @param {String} sourceName The name of the source
 * @param {Object} [options] Options for addSource.
 * @return {Object} The source configuration
 */
export const mapboxGeojsonSource = ({ sourceName, featureCollection, options = {} }) => {
  return {
    name: sourceName,
    type: 'geojson',
    data: featureCollection,
    ...options
  };
};

/**
 * Calls updateOrCreateMapboxSourceIfNeeded on multiple sources
 * @param {Object} mapboxMap The mapbox map
 * @param {[Object]} sources
 * @param {[Object]} Change statuses in the form {name: source name, change: 'update'|'create'|null} where
 * null occurs if the source data did not change compared to what is already stored on the map
 */
export const updateOrCreateMapboxSources = ({ mapboxMap, sources }) => {
  const changeStatuss = map(
    source => {
      updateOrCreateMapboxSourceIfNeeded({ mapboxMap, ...source });
    },
    sources
  );
  return changeStatuss;
};

/**
 * Update or create the given Mapbox source
 * @param {Object} mapboxMap The mapbox map
 * @param {String} name The source name
 * @param [type] Defaults to 'geojson'
 * @param {Object} data The data
 * @param {Object} options Options for addSource, such as
 *      cluster: true,
 *      clusterProperties: {
 *       train_ids: ['concat', ['concat', ['get', 'train_id'], ',']]
 *     }
 * @returns {Object} The name passed in and a change field that is 'update', 'create', or null, where
 *     null occurs if no change to the data was detected with a reference check
 */

export const updateOrCreateMapboxSourceIfNeeded = ({ mapboxMap, name, type = 'geojson', data, options = {} }) => {
  const dataSource = mapboxMap.getSource(name);
  if (dataSource) {
    // Mapbox doesn't publicly offer the source data, and _data is the same object with a different reference
    // So compare feature length and then do a deep equals if needed
    if (data.features.length !== dataSource._data.features.length || !equals(data, dataSource._data)) {
      dataSource.setData(data);
      return { name, change: 'update' };
    }
    return { name, change: null };
  } else {
    mapboxMap.addSource(name, { type, data, ...options });
    return { name, change: 'create' };
  }
};


/**
 * Calls setMapboxSourceAndLayers on multiple sets of sourceAndLayerSets
 * @param mapboxMap
 * @param sourceAndLayerSets
 * @param [zoomToSources] Default false, zoom to the sources
 */
export const setMapboxSourceAndLayersSets = ({ mapboxMap, sourceAndLayersSets, zoomToSources = false }) => {
  const changeStatuses = map(
    sourceAndLayers => setMapboxSourceAndLayers({ mapboxMap, sourceAndLayers }),
    sourceAndLayersSets
  );
  // Zoom to the total bbox of the sources if zoomToSources is true and any of the sources actually changed
  if (zoomToSources && any(prop('change'), changeStatuses) && length(sourceAndLayersSets)) {
    const encompassingBbox = reduce(
      (previousBbox, source) => {
        return addIndex(zipWith)(
          (l, r, index) => {
            // take the min of the min lat lon and the max of the max lat lon
            return index < 2 ? Math.min(l, r) : Math.max(l, r);
          },
          previousBbox,
          bbox(source.data)
        );
      },
      [Infinity, Infinity, -180, -90],
      mapObjToValues(prop('source'), sourceAndLayersSets)
    );
    // For debugging
    // const geojson = bboxPolygon(encompassingBbox);
    mapboxMap.fitBounds([slice(0, 2, encompassingBbox), slice(2, 4, encompassingBbox)], {
      antialias: true,
      essential: true,
      // TODO we should probably leave the pitch to what the user set
      pitch: MAP_PITCH
    });
  }
};

/**
 * Given a Mapbox source and layers that style it, update or create the source
 * and remove/create the layers
 * @param mapboxMap
 * @param sourceAndLayers
 * @returns {Object} {name: source name, change: 'create'|'update'|null} where null is returned
 * if the data reference did not change since the last setData
 */
export const setMapboxSourceAndLayers = ({ mapboxMap, sourceAndLayers }) => {
  const { source, layers } = sourceAndLayers;
  const changeStatus = updateOrCreateMapboxSourceIfNeeded(
    {
      mapboxMap,
      ...source
    }
  );
  const layerIds = map(prop('id'), layers);
  const matchingLayerLookup = indexBy(
    prop('id'),
    filter(
      layer => {
        return includes(layer.id, layerIds);
      },
      mapboxMap.getStyle().layers
    )
  );
  forEach(layer => {
    if (has(layer.id, matchingLayerLookup)) {
      // TODO I don't know how bad this is for performance, preferably we could just update anything that changed
      mapboxMap.removeLayer(layer.id);
    }
    mapboxMap.addLayer(layer);
  }, layers);
  return changeStatus;
};

/***
 * Remove sources and layers matching the sourcePrefix and layerPrefix that are not in
 * the exclude sourceAndLayersSets
 * @param layerPrefix
 * @param sourcePrefix
 * @param {Object} exclude sourceAndLayersSets to not remove
 * @param mapboxMap
 */
export const removeMapboxLayersAndSources = (
  {
    layerPrefix,
    sourcePrefix,
    excludeSourceAndLayersSets=[],
    mapboxMap
  }) => {
  const excludeSourcesById = groupBy(prop('name'), chainMDeep(2, prop('source'), excludeSourceAndLayersSets));
  const excludeLayersById = groupBy(prop('id'), chainMDeep(2, prop('layers'), excludeSourceAndLayersSets));
  forEach(
    layer => {
      if (startsWith(layerPrefix, layer.id)) {
        if (!propOr(false, layer.id, excludeLayersById)) {
          mapboxMap.removeLayer(layer.id);
        }
      }
    },
    mapboxMap.getStyle().layers
  );
  forEachObjIndexed(
    (source, id) => {
      if (startsWith(sourcePrefix, id)) {
        if (!propOr(false, id, excludeSourcesById)) {
          mapboxMap.removeSource(id);
        }
      }
    },
    mapboxMap.getStyle().sources
  );
};