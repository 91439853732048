/**
 * Shows the start and end of the current Train route.
 * In the future it will be possible to click the arrow to reverse the route
 * and click the start and end stations to change them
 * @param {Object} trainProps
 * @param {Object} trainProps.trainRouteProps
 * @param {Object} trainProps.trainRouteProps.trainRoute
 * @param {Function} componentProps.reverseFormation Function to reverse the route when the arrow is clicked
 * @param {Function} componentProps.chooseFormation Function to bring up to choose the route
 * @returns {JSX.Element}
 */
import { FormationMenu } from 'components/apps/trainAppComponents/formationChooserComponents/FormationMenu.js';

/***
 * Shows a menu that allows adding a Formation to the FormationFIlter
 * @param trainProps
 * @param addFormationToFilter
 * @returns {JSX.Element}
 * @constructor
 */
const FormationChooser = ({ formations, chosenFormations, handleAddFormationToFilter }) => {
  return <FormationMenu {...{
    formations,
    chosenFormations,
    handleAddFormationToFilter,
  }} />;
};

export default FormationChooser;