import {RailwayLine} from "visualizer-railbed/railbedTypes/railways/railwayLine";
import {typeObject} from "visualizer-railbed/railbedAppUtils/typeUtils/typenameUtils.ts";
import {CemitTypename} from "visualizer-railbed/railbedTypes/cemitTypename.ts";

// Temp solution until the railways api is available
import {ServiceLine} from "../../../visualizer-railbed/railbedTypes/trainRoutes/serviceLine";
import {FeatureCollectionU} from "../../../visualizer-railbed/railbedTypes/geometry/geojsonUnions";
import {mapObjToValues} from '@rescapes/ramda'
import fly1 from './fly1.json'
import fly2 from './fly2.json'

export const tempFlytogetRailwayLines: RailwayLine[] = mapObjToValues(
  (value: FeatureCollectionU, key: string) => {
    return typeObject<RailwayLine>(CemitTypename.railwayLine, {
      id: key,
      name: key,
      sourceKey: key,
      geojsons: [value],
      reversedGeojsons: [],
      tracks: [],
    })
  }, {
    ...fly1,
    ...fly2,
  }
)

enum FlytogetServiceLineSourceKey {
  fly1 = 'FLY1',
  fly2 = 'FLY2'
}

enum FlytogetServiceLineOfficialName {
  fly1 = 'FLY1',
  fly2 = 'FLY2'
}

export const tempFlytogetServiceLines = [
  typeObject<ServiceLine>(CemitTypename.serviceLine, {
    id: FlytogetServiceLineSourceKey.fly1,
    sourceKey: FlytogetServiceLineSourceKey.fly1,
    name: FlytogetServiceLineOfficialName.fly1,
    railwayLines: tempFlytogetRailwayLines
  }),
  typeObject<ServiceLine>(CemitTypename.serviceLine, {
    id: FlytogetServiceLineSourceKey.fly2,
    sourceKey: FlytogetServiceLineSourceKey.fly2,
    name: FlytogetServiceLineOfficialName.fly2,
    railwayLines: tempFlytogetRailwayLines
  }),
]
