import { Box, Stack, Typography } from '@mui/material';
import ImuPointsCustomTooltip from 'components/charts/stackedChart/ImuPointsCustomTooltip.js';
import { equals, map as mapR } from 'ramda';
import { Area, AreaChart, ResponsiveContainer, Tooltip, XAxis, YAxis, ReferenceLine } from 'recharts';
import CustomCursor from 'components/charts/stackedChart/RechartsCustomCursor.js';


/**
 * Shows the graph for one dataPath for all selected TrainRuns (e.g. acceleration or velocity)
 * dataPathsConfigs is passed in addition to dataPath so that the legend/info are can show all configured values
 * @param loading
 * @param yAxisWidth
 * @param {Number} index The chart index If 0, we set ref to this component
 * @param dataPath
 * @param label
 * @param labelYAxis
 * @param xAxisDomain
 * @param yAxisDomain
 * @param maybeLoaderComponent Component to show if the chart is loading or updating
 * @param onMouseMove
 * @param onMouseLeave
 * @param trackDataFeatureSets
 * @param sx
 * @param ref
 * @returns {JSX.Element}
 * @constructor
 */
const StackedAreaChart = (
  {
    trainProps,
    componentProps: {
      yAxisWidth,
      index,
      dataPath,
      label,
      labelYAxis,
      xAxisDomain,
      yAxisDomain,
      referenceLines,
      onMouseMove, onMouseLeave, onMouseEnter, onMouseOver,
      mostRecentTooltipPayload,
      setMostRecentTooltipPayload,
      customControls = null,
      activeChartDataKey,
      metersOfHoveredItem,
      maybeLoaderComponent
    },
    trackDataFeatureSets,
    sx,
    forwardedRef: ref
  }
) => {

  // const {
  //   clipPathRefs,
  //   xPadding,
  //   onChartMouseDown,
  //   onChartMouseUp,
  //   setWrapperRef,
  //   onChartMouseMove
  // } = useZoomAndPan({
  //   chartLoaded: !loading
  // });

  return <Box key={dataPath}  {...equals(0, index) ? { ref } : {}}>
    <Stack direction='row' spacing={3}>
      <Stack {...{
        direction: 'row',
        sx: {
          alignItems: 'center'
        }
      }}><Typography color='secondary.main'>{label}</Typography></Stack>
      {customControls}
    </Stack>
    <Box sx={{ position: 'relative', ...sx }}>
      {
        maybeLoaderComponent
      }

      <ResponsiveContainer>
        <AreaChart key='areaChart' {...{
          id: label,
          syncId: 'sameSyncIdForAllAreaCharts',
          // syncMethod: 'value' tells tooltips and brushes to sync items by value, not index
          // This is essential since the datasets have different lengths and we want to select those
          // close to one another: https://recharts.org/en-US/api#syncMethod
          // TODO this only works across AreaCharts, not within the series
          syncMethod: 'value',
          // onMouseDown: onChartMouseDown,
          // onMouseUp: onChartMouseUp,
          // onMouseMove: compose(args => onChartMouseMove(...args), onMouseMove),
          onMouseMove,
          onMouseLeave,
          onMouseEnter,
          onMouseOver,

          margin: {
            top: 10,
            right: 25,
            left: 0,
            bottom: 0
          }
        }}
        >
          <defs>
            {/* Stores the clip area for panning and zooming */}
            {/*<RechartsClipPaths ref={clipPathRefs} />*/}

            {/* These are the titles for each TrainRun on the graph
              This solidifies the are drawn under each graph
            */}
            {trackDataFeatureSets && mapR(
              trackDataFeatureSet => {
                return <linearGradient id={`linearGradient${trackDataFeatureSet.color}`} key={trackDataFeatureSet.label}
                                       x1='0' y1='0' x2='0' y2='1'>
                  <stop {...{ offset: '0%', stopColor: trackDataFeatureSet.color, stopOpacity: 0.2 }} />
                  <stop {...{ offset: '95%', stopColor: trackDataFeatureSet.color, stopOpacity: 0 }} />
                </linearGradient>;
              }, trackDataFeatureSets
            )}
          </defs>
          {referenceLines}
          <Tooltip {...{
            // This receives the payload as well from Recharts.
            // ImuPointsCustomTooltip doesn't render anything. It sets a state so that we can render the data
            // elsewhere
            content: <ImuPointsCustomTooltip {...{
              trainRouteAggregateInterval: trainProps.trainRouteProps.trainRouteAggregateInterval,
              trackDataFeatureSets,
              mostRecentTooltipPayload,
              setMostRecentTooltipPayload,
              activeChartDataKey,
              metersOfHoveredItem,
              userTrainRunIntervals: trainProps.userTrainRunIntervalProps.activeUserTrainRunIntervalsWithoutErrors
            }} />,
            cursor: <CustomCursor />
          }} />
          {
            trackDataFeatureSets &&
            mapR(
              trackDataFeatureSet => {
                // One Area per UserTrainRunInterval
                return <Area {...{
                  dot: false,
                  activeDot: false,
                  key: trackDataFeatureSet.label,
                  data: trackDataFeatureSet.features,
                  type: 'monotone',
                  name: trackDataFeatureSet.label,
                  dataKey: dataPath,
                  stroke: trackDataFeatureSet.isVisible ? trackDataFeatureSet.color : 'none',
                  fill: trackDataFeatureSet.isVisible ? `url(#linearGradient${trackDataFeatureSet.color})` : 'none'
                }} />;
              },
              trackDataFeatureSets
            )
          }

          <XAxis {...{
            type: 'number',
            // padding: { left: xPadding[0], right: xPadding[1] },
            allowDataOverflow: true,
            allowOverflow: true,
            // Important, this allows the selection of a payload item
            // from each line on the same chart, however is stupidly copies the values
            // of the active line if the other lines don't have a domain where the cursor is.
            // This bug is addressed in ImuPointStatsContainer.js
            allowDuplicatedCategory: true,
            dataKey: 'properties.meters',
            domain: xAxisDomain,
            // Use the reference line at 0
            axisLine: false,
            tick: false,
          }} />
          {/* Draw the xAxis at 0 with the default color used for the axes */}
          <ReferenceLine y={0} stroke='rgb(102, 102, 102)' fill='none' />
          <YAxis {...{
            type: 'number',
            allowDataOverflow: true,
            allowOverflow: true,
            tick: {
              fontSize: 12,
              fill: 'white',
              fontFamily: 'Arial'
            },
            padding: { right: 10, left: 10, top: 10, bottom: 10 },
            label: {
              value: labelYAxis,
              angle: -90,
              fontSize: 12,
              fill: 'white',
              fontFamily: 'Arial'
            },
            width: yAxisWidth,
            allowDecimals: false,
            domain: yAxisDomain
          }}
          />
        </AreaChart>
      </ResponsiveContainer>
    </Box>
  </Box>;
};
StackedAreaChart.displayName = 'StackedAreaChart';
export default StackedAreaChart;