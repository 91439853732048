import { useRef, useState } from 'react';
import { useOffsetLeft } from 'utils/hooks/domHooks.js';
import TrainLineStationDot from 'components/apps/trainAppComponents/trainLineComponents/TrainLineStationDot.js';

const EVENT_CODES = [0, 1];

/**
 * The Train Line Station Dot
 * @param sx
 * @param diameter
 * @param displayProps the windowSize used to trigger rerenders
 * @param setOffsetLeft
 * @param trainRunInterval
 * @param limitedStations
 * @param recalculateOffsetLefts
 * @param panelSize Tracks changes in size the the TrainLine container when the window
 * size or panel drag occurs
 * @returns {JSX.Element}
 * @constructor
 */
const TrainLineStationDotContainer = (
  {
    componentProps: {
      diameter,
      setOffsetLeft,
      recalculateOffsetLefts,
      trainRunInterval,
      limitedStations,
      panelSize
    },
    sx
  }) => {

  const stationRef = useRef(null);
  const [trainRouteFunctionChooserVisible, setTrainRouteFunctionChooserVisible] = useState(false);

  const showTrainRouteFunctionChooser = e => {
    if (EVENT_CODES.includes(e.nativeEvent.which)) {
      setTrainRouteFunctionChooserVisible(true);
    }
  };

  const hideTrainRouteFunctionChooser = e => {
    if (EVENT_CODES.includes(e.nativeEvent.which)) {
      setTrainRouteFunctionChooserVisible(false);
    }
  };

  useOffsetLeft({
    target: stationRef,
    containerSize: panelSize,
    setOffsetLeft,
    recalculateOffsetLefts,
    trainRunInterval,
    limitedStations
  });
  return <TrainLineStationDot {...{
    sx,
    diameter,
    stationRef,
    showTrainRouteFunctionChooser,
    hideTrainRouteFunctionChooser,
    showMenu: trainRouteFunctionChooserVisible
  }} />;
};

export default TrainLineStationDotContainer;