import { concat, equals, join, map, values } from 'ramda';
import { isLocalEnvironment } from 'lib/fetch/cemitApi/config.js';
import { FormControlLabel, Switch } from '@mui/material';
import React from 'react';

export const accelerationConfig = {
    xyz: {
        props: ['accXMean', 'accYMean', 'accZMean'],
        label: 'meanAcceleration',
        derivedProp: 'accXZYMean'
    },
    y: {
        props: ['accYMean'],
        label: 'meanYAcceleration',
        derivedProp: 'accYMean'
    }
};

/**
 * The data visualization configuration. Determines what imuPoint properties to show on charts and graphs
 * @param t
 * @returns {[{configs, category: string}, {configs: [{labelYAxis: string, label, dataPath: string}], category: string}]}
 */
export const dataPathConfiguration = (
    {
        t,
        appProps
    }) => {
    const AccelerationToggle = ({t, appProps}) => {
        return <FormControlLabel
            key='formControlLabel'
            color='secondary'
            onChange={
                // checked indicates to use Y only acceleration. Off is the default XYZ
                e => {
                    const {target: {checked}} = e;
                    e.stopPropagation();
                    appProps.setChosenAcceleration(checked ? 'y' : 'xyz');
                }
            }
            control={<Switch checked={appProps.chosenAccelerationKey === 'y'}/>}
            label={t('sidewaysAccelerationOnly')}/>;
    };

    return [
        // {
        //     category: 'rmse2hz',
        //     configs: [{
        //         dataPath: 'properties.rmse2hz',
        //         label: t('rmse2hz'),
        //         labelYAxis: 'rmse 0.1-2 hz',
        //         domain: [0, 3],
        //         referenceLines:[
        //             <ReferenceLine key='critical' y={3} label="Critical" stroke="#FA5760"  alwaysShow={true}/>,
        //             <ReferenceLine key='warngin' y={2} label="Warning" stroke="#EBBD1A"   alwaysShow={true} />
        //         ]
        // }]
        // },
        // {
        //     category: 'rmse4hz',
        //     configs: [{
        //         dataPath: 'properties.rmse4hz',
        //         label: t('rmse4hz'),
        //         labelYAxis: 'rmse 2-4 hz',
        //         domain: [0, 3],
        //         referenceLines:[
        //             <ReferenceLine key='critical' y={3} label="Critical" stroke="#FA5760" alwaysShow={true} />,
        //             <ReferenceLine key='warngin' y={2} label="Warning" stroke="#EBBD1A"  alwaysShow={true} />
        //         ]
        //     }]
        // },
        {
            category: 'acceleration',
            // Mean XYZ acceleration and Y acceleration
            configs: values(map(({derivedProp, label}) => ({
                dataPath: `properties.${derivedProp}`,
                label: t(label),
                labelYAxis: 'm/s²',
                domain: equals('accYMean', derivedProp) ? [-1, 1] : [0, 2]
            }), accelerationConfig)),
            customControls: [
                AccelerationToggle({t, appProps})
            ]
        },
        {
            category: 'velocity',
            // Mean velocity
            configs: [{
                dataPath: 'properties.kmh',
                label: t('velocity'),
                labelYAxis: 'kmh'
            }
            ]
        },
    ];
};

export const dataPathConfigurationWithLatLon = t => {
    return concat(
        dataPathConfiguration(t),
        isLocalEnvironment() ? [
            {
                category: 'position',
                // latitude, longitude
                configs: [{
                    dataPath: ['properties.lat', 'properties.lon'],
                    toFixedValue: 5,
                    label: values => {
                        return `<a target='google-map' href='https://www.google.com/maps/place/${join(',', values)}'>Validate</a>`;
                    }
                }
                ]
            },
            {
                category: 'railway',
                configs: [{
                    dataPath: 'properties.railway',
                    toFixedValue: 5,
                    label: t('railway')
                }
                ]
            }
        ] : []
    );
};

