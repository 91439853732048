import { Typography } from '@mui/material';

/**
 * Shows the name and position of a collectionDevice as a simple label
 * @param collectionDevice
 * @returns {JSX.Element}
 * @constructor
 */
const CollectionDevice = ({ collectionDevice }) => {
  return <Typography variant='caption'>
    {collectionDevice.name}
  </Typography>;
};
export default CollectionDevice