import { mapObjToValues, toArrayIfNot } from '@rescapes/ramda';
import { format } from 'date-fns';
import { trainDataFriendlyDateFormatString } from 'appUtils/trainAppUtils/trainDataUtils.js';
import {
  extractAndEvaluateMatchingFilters,
  updateFilterTypeInFilters
} from 'appUtils/trainAppUtils/trainFilterUtils.js';
import {
  concat,
  filter,
  includes,
  is,
  join,
  length,
  lensProp,
  map,
  mergeRight,
  omit,
  over,
  propEq,
  props as rProps,
  when
} from 'ramda';
import { evalFilter } from 'appUtils/trainAppUtils/trainFilterEval.js';

/**
 * Returns true if the object represents a Date range comparison
 * @param obj
 * @returns {{return}}
 */
export const isDateRange = (obj) => {
  // Date range comparison test
  return propEq('__typename', 'DateRangeFilter', obj);
};

/**
 * Converts the first argument of each comparison function to a value if
 * it is an expression like a view
 * @param trainRunFilterDateRange
 * @param props
 * @returns {*}
 */
export const evalDateRange = (trainRunFilterDateRange, props) => {
  return over(
    lensProp('and'),
    // We ignore the keys here: lt, lte, gt, gte since we can't evaluate fully
    andObj => map(
      ([comparatorFirstValue, ...rest]) => {
        return [when(
          // If the first comparator argument is an object, evaluate it as
          // a filter
          is(Object),
          comparatorFirstValue => {
            // Must be a view that resolves to a date
            return evalFilter(comparatorFirstValue, props);
          }
        )(comparatorFirstValue), ...rest];
      },
      andObj
    ),
    trainRunFilterDateRange
  );
};

/**
 * Extracts DateRange filters from the give trainRunFilter
 */
export const extractDateRangeFilters = (trainRunFilter, props) => {
  const dateRangeEvaluatedFilters = extractAndEvaluateMatchingFilters(
    { filterTypeTest: isDateRange, filterTypeEval: evalDateRange },
    trainRunFilter, props
  );
  return dateRangeEvaluatedFilters;
};

/**
 * Extracts dateRanges from the trainRunFilter in the form {start: date, end: date}
 * @param trainRunFilter
 * @param props
 * @returns {[{start, end}, ...]}
 */
export const extractDateRanges = (trainRunFilter, props) => {
  const dateRangeEvaluatedFilters = extractDateRangeFilters(trainRunFilter, props);
  return map(
    dateRangeEvaluatedFilter => {
      const [start, end] = mapObjToValues(
        ([comparatorFirstValue]) => {
          // TODO we could evaluate the comparator here and return inclusive or exclusive along wiht the value
          return comparatorFirstValue;
        },
        dateRangeEvaluatedFilter['and']
      );
      return { start, end };
    },
    dateRangeEvaluatedFilters
  );
};

/**
 * Given a filter that represents a Date range comparison, convert it to
 * a readable string
 * @param t
 * @param trainRunFilter
 * @param props
 * @returns {[String]} Returns a label for each date range comparison.
 * These can be combined as needed by the caller
 */
export const extractLabelsForDateRanges = ({ t }, trainRunFilter, props) => {
  const dateRanges = extractDateRanges(trainRunFilter, props);
  return map(
    dateRange => {
      return dateRangeLabel({ t }, dateRange);
    },
    dateRanges
  );
};

/**
 * Creates a label for the date range in the form t('between') start date t('and') end date
 * @param t
 * @param dateRange
 * @returns {*}
 */
export const dateRangeLabel = ({ t }, dateRange) => {
  const friendlyDateTimeStrings = map(
    dateTime => {
      return format(
        dateTime,
        trainDataFriendlyDateFormatString
      );
    }, rProps(['start', 'end'], dateRange));
  return join(' ', [
    t('between'),
    join(` ${t('and')} `, friendlyDateTimeStrings)
  ]);
};


/**
 * Primitively adds a new dateRangeFilters the top level of the trainRunFilter
 * @param trainRunFilter
 * @param {Object|[Object]} dateRangesFilters One DateRangeFilter or list of DateRangeFilter to add
 * @returns {*}
 */
export const addDateRangeFilters = (trainRunFilter, dateRangesFilters, props) => {
  const updateFunc = existingDateRangesFilter => {
    return over(
      lensProp('any'),
      dateRangeFilters => {
        return concat(
          dateRangeFilters,
          map(
            mergeRight({ __typename: 'DateRangeFilter' }),
            toArrayIfNot(dateRangesFilters)
          )
        );
      },
      existingDateRangesFilter
    );
  };
  return updateFilterTypeInFilters(trainRunFilter, isDateRange, updateFunc, props);
};

/**
 * Removes the DateRanges specified by id from the trainRunFilter
 * @param trainRunFilter
 * @param {[Object]|Object} dateRanges A single dateRange or array of dateRanges to remove
 * @param props
 * @returns {*}
 */
export const removeDateRangeFilters = (trainRunFilter, dateRanges, props) => {
  const updateFunc = existingDateRangesFilter => {
    const removed = over(
      lensProp('any'),
      existingFilters => {
        return filter(
          existingFilter => {
            // TODO I don't know if this matches correctly on equals
            return !includes(existingFilter, dateRanges);
          },
          existingFilters
        );
      },
      existingDateRangesFilter
    );
    // Clear the empty any if empty
    return !length(removed.any) ? omit(['any'], removed) : removed;
  };
  return updateFilterTypeInFilters(trainRunFilter, isDateRange, updateFunc, props);
};


/**
 *  and: {
  lte: [
    new Date('2022-09-01'),
    { view: { lensPath: ['trainRun', 'departureDatetime'] } }
  ],
    gte: [
    new Date('2022-10-01')
    { view: { lensPath: ['trainRun', 'departureDatetime'] } }
  ]
}
 * @param dateRange
 */

/**
 * Creates a new TrainRunDateRangeFilter
 * @param dateRange
 * @param {DateTime} dateRange.start
 * @param {DateTime} dateRange.end
 * @returns {Object}
 */
export const createDateRangeFilter = dateRange => {
  return {
    __typename: 'DateRangeFilter',
    and: {
      lte: [
        dateRange.start,
        { view: { lensPath: ['trainRun', 'departureDatetime'] } }
      ],
      gte: [
        dateRange.end,
        { view: { lensPath: ['trainRun', 'departureDatetime'] } }
      ]
    }
  };
};