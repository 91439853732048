export const GRAPHQL_ENDPOINT = `https://staging-api.dimensionfour.io/cemit/graph`;
export const SUB_GRAPHQL_ENDPOINT = `wss://staging-api.dimensionfour.io/subscription`;
export const APPS_PATH = 'dashboard';
// Used to correct y-acceleration by accounting for gravity
export const GRAVITY_ACCELERATION = -9.80665;
// Minimum number of meters for a TrainRun Interval so that we are sure to have at least 2 points with the 100m view
export const TRAIN_RUN_INTERVAL_MINIMUM = 5000;

// TODO Train is hardcoded until we reform authenticate
export const tabAccess = ['train'];

// Version the local storage since ids might change between releases for now
const LOCAL_STORAGE_VERSION = 'V20240110';

export const LOCAL_STORAGE_TRAIN_ROUTE = `trainRoute${LOCAL_STORAGE_VERSION}`;
export const LOCAL_STORAGE_USER_TRAIN_RUN_INTERVALS = `userTrainRunIntervals${LOCAL_STORAGE_VERSION}`;
export const LOCAL_STORAGE_TRAIN_RUN_FILTER_DATE_RANGE = `trainRunFilterDateRange${LOCAL_STORAGE_VERSION}`

// Current limit for number of TrainRuns to download at once. TODO replace with paging
export const MAX_DAYS = 3;

// The Mapbox style as created in Mapbox studio
export const MAPBOX_STYLE = 'mapbox://styles/cemit/ckhxf27rc0rxq19p9cvrm7txa'

export const MAPBOX_DIV_ID = 'train-map'