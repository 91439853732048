import formatISO from 'date-fns/formatISO/index.js';
import { always, compose, concat, cond, is, join, length, split, T } from 'ramda';
import { mapObjToValues } from '@rescapes/ramda';
import { colors } from 'theme/colors.ts';
import { darken } from '@mui/material/styles/index.js';

export const TRAIN_API_DATE_ATTRIBUTES =
  ['start_date', 'source:date', 'departureDatetime', 'arrivalDatetime', 'medianDatetime', 'datetimeIntervals', 'serviceDatetimeRange', 'startTime', 'finishTime', 'time'];
// Default interpolations for the prop that is styled. This assumes normalized data.
export const DEFAULT_TRAIN_ROUTE_INTERPOLATIONS = [[0, colors.green], [0.5, colors.orange], [1, colors.red]];
// TODO move to client style override object
export const FLYTOGET_GRAY = '#767676';
export const FLYTOGET_GRAY_DARKENED = darken('#767676', .5);
export const TRAIN_RUN_POSITION_LAYER = 'train-run-position-layer';
// TODO currently how in km far we transform track perpendicular to the main track to show data
export const MAP_TRANSFORM_TRACK_PERPENDICULAR_DISTANCE_INTERVAL = 0.01;

export const TRAIN_RUN_INTERVAL_EXTRUDED_COLUMN_SOURCE_PREFIX = 'train_run_interval_extruded_column_source_prefix';
export const TRAIN_RUN_INTERVAL_EXTRUDED_COLUMN_LAYER_PREFIX = 'train_run_interval_extruded_column_layer_prefix';

// These are the sources and layers used to visualize UserTrainRunIntervals on the map. We add
// a UserTrainRunInterval id to make them unique
export const TRAIN_RUN_INTERVAL_TRACK_SOURCE_PREFIX = 'train_run_interval_track_source_prefix';
export const TRAIN_RUN_INTERVAL_TRACK_LAYER_PREFIX = 'train_run_interval_track_layer_prefix';

// Non TrainRun specific Railway and Switches
export const MAP_RAILWAY_SOURCE = 'railway-source';
export const MAP_RAILWAY_LAYER = 'railway-layer';
export const MAP_TRACK_SWITCH_SOURCE = 'track-switch-source';
export const MAP_TRACK_SWITCH_LAYER = 'track-switch-layer';

export const TRAIN_API = {
  BASE_PATH: 'api',
  VERSION: 2,

  CLIENTS: {
    route: 'clients'
  },
  // Service lines
  SERVICE_LINES: {
    route: 'service_lines'
  },
  RAILWAY_LINES: {
    route: 'railway_lines'
  },
  TRAIN_ROUTE_GROUPS: {
    route: 'train_route_groups'
  },
  TRAIN_ROUTES: {
    route: 'train_routes'
  },
  TRAIN_RUNS: {
    route: 'train_runs',
    paths: {
      withImuPoints: 'with_imu_points'
    }
  },
  FORMATIONS: {
    route: 'formations'
  }
};
// We currently allow only 3 active UserTrainRunIntervals, including the baseline UserTrainRunInterval
export const USER_TRAIN_RUN_INTERVAL_MAX_ACTIVE_COUNT = 3;


// Default to accept imuPoints within 15 minutes of a trainRun start and end time. This compensates for
// late trains.
export const TRAIN_RUN_IMUPOINT_MINUTE_BUFFER = 15;

/**
 * Forms a Train API GET request based on the given query string and path
 * @param {String} route The request path after the base path
 * @param {Object} query Used to create a query string
 * @returns {String} The full URL
 */
export const trainApiGetUrl = ({ route, query }) => {
  const queryString = join('&', mapObjToValues(
    compose(
      pair => join('=', pair),
      (value, key) => {
        return [key,
          cond([
            [is(Date), value => encodeURIComponent(formatISO(value))],
            [T, always(value)]
          ])(value)
        ];
      }
    ),
    query
  ));
  // Only put a / in front of the query string if the root is single
  // segment. For some reason fastapi redirects /train_runs/with_imu_points/ to /train_runs/with_imuPoints
  // and /train_runs to /train_runs/
  return concat(join('/', [
      process.env.TRAIN_APP_API,
      TRAIN_API.BASE_PATH,
      `v${TRAIN_API.VERSION}`,
      route
    ]), `${length(split('/', route)) > 1 ? '' : '/'}?${queryString}`
  );
};

/**
 * Resolves the correct API for the client based on the given method if any
 * TODO clients will not have specific treatment in the future
 */
export const clientTrainUrlLookup = (
  {
    cargonetResolveApiUrl,
    flytogetResolveApiUrl
  }) => {
  return {
    Cargonet: cargonetResolveApiUrl,
    Flytoget: flytogetResolveApiUrl
  };
};

