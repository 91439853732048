import { useDrop } from 'react-dnd';
import { ItemTypes } from 'appConfigs/trainConfigs/trainDragAndDropConfig.js';
import { updateUserTrainRunIntervalDistance } from 'async/trainAppAsync/hooks/typeHooks/trainRunIntervalHooks.js';

/**
 * useDrop hook to accept the moving of a TrainRunLine
 * @param areOffsetLeftsReady
 * @param crudTrainRunIntervals
 * @param [crudUserTrainRunIntervals] Only needed for TrainRunIntervalBars specific to the user
 * @param trainRun
 * @param trainRoute
 * @param stopsWithOffsetLefts
 * @returns {*}
 */
export const useTrainRunLineMoverDrop = (
  {
    trainProps,
    isTrainRouteLine,
    areOffsetLeftsReady,
    crudTrainRunIntervals,
    crudUserTrainRunIntervals,
    trainRun,
    trainRoute,
    routeDistancesWithOffsetLefts
  }) => {
  return useDrop(
    () => {
      return {
        accept: [
          ItemTypes.TRAIN_RUN_INTERVAL_BAR_MOVER,
          ItemTypes.TRAIN_RUN_INTERVAL_BAR_RIGHT_EXPANDER,
          ItemTypes.TRAIN_RUN_INTERVAL_BAR_LEFT_EXPANDER
        ],
        drop: ({
                 trainRunInterval,
                 userTrainRunInterval,
                 spaceGeospatially,
                 limitedDistanceRange,
                 parentWidth,
                 isAggregate
               }, monitor) => {
          if (!areOffsetLeftsReady) {
            return;
          }
          const offsetDifference = monitor.getDifferenceFromInitialOffset()?.x;
          if (!offsetDifference)
            return;
          return updateUserTrainRunIntervalDistance({
            trainRouteProps: trainProps.trainRouteProps,
            trainRoute,
            trainRunInterval,
            userTrainRunInterval,
            crudTrainRunIntervals,
            crudUserTrainRunIntervals,
            routeDistancesWithOffsetLefts,
            parentWidth,
            offsetDifference,
            itemType: monitor.getItemType(),

            spaceGeospatially,
            limitedDistanceRange,
            isTrainRouteLine,
            isAggregate
          });
        },
        collect: monitor => {
          return {
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop()
          };
        }
      };
    },
    [areOffsetLeftsReady, trainRun, crudTrainRunIntervals?.list, crudUserTrainRunIntervals?.list]
  );
};

