import { Stack, Typography } from '@mui/material';
import FormationChooser from 'components/apps/trainAppComponents/formationChooserComponents/FormationChooser.js';
import { ChosenFormations } from 'components/apps/trainAppComponents/trainFilterComponents/ChosenFormations.js';
import { length } from 'ramda';
import { extractFormations } from 'appUtils/trainAppUtils/trainFilterTrainFormationUtils.js';

/**
 * Shows current TrainRunFormationFilters and if not readonly gives an interface to add and delete them
 * @param trainProps
 * @param setOpen
 * @param readonly
 * @returns {JSX.Element}
 * @constructor
 */
export const TrainRunFilterFormationsComponent = (
  {
    trainProps,
    componentProps: {
      readonly = false,
      handleAddFormationToFilter,
      formationLabelsFromFilter,
      filterProps,
      handleRemoveFormationFromFilters,
      formationsLabel
    }
  }) => {
  const trainRunFilterWithFormations = trainProps.formationProps.trainRunFilterWithFormations;
  // Get the chosenFormations in the filter
  const chosenFormations = extractFormations(trainRunFilterWithFormations, filterProps);
  const formations = trainProps.formationProps.formationsWithSensors;
  return <Stack {...{ spacing: 0.5, direction: 'row', sx: { alignItems: 'center' } }} >
    <FormationChooser {...{ formations, chosenFormations, handleAddFormationToFilter }} />
    {
      // Show Chips for Formations in the filter or show an all chosenFormations message.
      !readonly && length(formationLabelsFromFilter) ?
        <ChosenFormations {...{ chosenFormations, handleRemoveFormationFromFilters }} /> :
        <Typography>
          {formationsLabel}
        </Typography>
    }
  </Stack>;
};
