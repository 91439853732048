import {
  always,
  any,
  chain,
  compose,
  eqProps,
  equals,
  filter,
  find,
  forEach,
  groupBy,
  indexBy,
  indexOf,
  join,
  length,
  lensPath,
  lensProp,
  lt,
  map,
  omit,
  pick,
  prop,
  propOr,
  reduce,
  set,
  sortBy,
  unless,
  values,
  view,
  when
} from 'ramda';
import { compact, mapMDeep, pathOr, reqStrPathThrowing, strPathOr } from '@rescapes/ramda';
import { extremes } from 'utils/functional/functionalUtils.js';
import {
  trainRunHasImuPoints,
  userTrainRunDistanceRangeAndIntervalDifference
} from 'appUtils/trainAppUtils/trainRunUtils.js';
import { max, min } from 'date-fns';
import { trainRunIntervalCollectionDevices } from 'appUtils/trainAppUtils/trainUtils.js';
import { userTrainRunIntervalUniqueLabel } from 'appUtils/trainAppUtils/formationUtils.js';
import { convertDistanceRangeFromAggregateToTrainRoute } from 'appUtils/trainAppUtils/trainRouteUtils.js';

/**
 * Finds UserTrainRunIntervals with the given trainRuns to update their TrainRun reference.
 * This step is taken to create a new UserTrainRunInterval before we call crudUserTrainRunIntervals.updateOrCreateAll,
 * which performs the deep merge of each updated UserTrainRunInterval with the existing value
 *
 * Only TrainRuns current in crudUserTrainRunIntervals will be returned. If the user removes
 * a TrainRun from crudUserTrainRunIntervals we will discard a trainRun in trainRuns that doesn't match it
 * If the user recreates a UserTrainRunInterval that we previously loaded data for, it will merge the loaded data in.
 * @param crudUserTrainRunIntervals
 * @param trainRuns
 * @returns {[Object]} The updatedUserTrainRunIntervalUtils.js UserTrainRunIntervals
 */
export const updateTrainRunOfUserTrainRunIntervalsPreMerge = ({ crudUserTrainRunIntervals, trainRuns }) => {
  return compact(map(
    trainRun => {
      const userTrainRunInterval = find(
        item => {
          return eqProps('id', trainRun, item.trainRunInterval.trainRun);
        },
        crudUserTrainRunIntervals.list
      );
      if (!userTrainRunInterval) {
        return null;
      }
      // Sets the ImuPoints for the UserTrainRunInterval
      return set(
        lensPath(['trainRunInterval', 'trainRun']),
        trainRun,
        userTrainRunInterval
      );
    },
    trainRuns
  ));
};


/**
 * Checks that the ImuPoint data for the userTrainRunInterval's current distanceRange is loaded for the
 * distanceInterval that the distanceRange resolves to
 * @param minimumTrainRunsWithImuPoints
 * @param userTrainRunInterval
 * @returns {*}
 */
export const outstandingTrainRunIntervalImuPointsForDistanceIntervalAndRange = (
  {
    trainRouteOrGroup,
    trainRouteAggregateInterval,
    minimumTrainRunsWithImuPoints,
    userTrainRunInterval
  }) => {
  const trainIdToMinimumTrainRunsWithImuPoints = indexBy(
    prop('id'),
    minimumTrainRunsWithImuPoints
  );
  const trainRunId = userTrainRunInterval.trainRunInterval.trainRun.id;
  const distanceIntervalsAndRanges = pathOr([], [trainRunId, 'distanceIntervalsAndRanges'], trainIdToMinimumTrainRunsWithImuPoints);
  if (!length(distanceIntervalsAndRanges)) {
    // If we haven't loaded anything for the current UserTrainRun.trainRunInterval.distanceInterval, return false
    return [];
  }
  const convertedDistanceRange = convertDistanceRangeFromAggregateToTrainRoute(
    {
      aggregateTrainRouteOrGroup: trainRouteOrGroup,
      trainRoute: userTrainRunInterval.trainRunInterval.trainRun.trainRoute
    },
    trainRouteAggregateInterval.distanceRange
  );
  const userTrainRunIntervalWithMappedDistanceRange = set(
    lensPath(['trainRunInterval', 'distanceRange']),
    convertedDistanceRange,
    userTrainRunInterval
  );
  // If distanceRanges is empty, it means we have already downloaded the range of UserTrainRunInterval.trainRunInterval.distanceRange
  const { distanceRanges } = userTrainRunDistanceRangeAndIntervalDifference(
    distanceIntervalsAndRanges,
    userTrainRunIntervalWithMappedDistanceRange
  );
  return distanceRanges;
};

/**
 * Creates UserTrainRunIntervals with their distanceRanges overridden to the given distanceRange.
 * We do this when the user selects a distanceRange and we want to render all UserTrainRunIntervals
 * with that range, but we don't persist the distanceRange on the UserTrainRunIntervals themselvs
 * @param {Object} distanceRange
 * @param {Number} distanceRange.start start distance in meters
 * @param {Number} distanceRange.end end distance in meters
 * @param {[Object]} userTrainRunIntervals The instances to create updates of
 * @returns {*}
 */
export const overrideUserTrainRunIntervalsDistanceRanges = (distanceRange, userTrainRunIntervals) => {
  return map(
    userTrainRunInterval => {
      return overrideUserTrainRunIntervalDistanceRange(distanceRange, userTrainRunInterval);
    },
    userTrainRunIntervals
  );
};

/**
 * Creates a UserTrainRunInterval with its distanceRanges overridden to the given distanceRange.
 * We do this when the user selects a distanceRange and we want to render all UserTrainRunIntervals
 * with that range, but we don't persist the distanceRange on the UserTrainRunIntervals themselves
 * @param {Object} distanceRange
 * @param {Number} distanceRange.start start distance in meters
 * @param {Number} distanceRange.end end distance in meters
 * @param {[Object]} userTrainRunInterval The instance to make an update of
 * @returns {*}
 */
export const overrideUserTrainRunIntervalDistanceRange = (distanceRange, userTrainRunInterval) => {
  return set(
    lensPath(['trainRunInterval', 'distanceRange']),
    distanceRange,
    userTrainRunInterval
  );
};

/**
 * We can't store the imuPoints in local storage, so remove them here before using local storage
 * We only store the TrainRun id and the interval itself
 * @param userTrainRunIntervalsByRouteId
 */
export const minimizedStoredUserTrainRunIntervals = userTrainRunIntervalsByRouteId => {
  forEach(userTrainRunIntervals => {
      const bySourceKey = groupBy(prop('sourceKey'), userTrainRunIntervals);
      const byTrainRunId = groupBy(reqStrPathThrowing('trainRunInterval.trainRun.id'), userTrainRunIntervals);
      if (
        any(compose(lt(1), length), values(bySourceKey)) ||
        any(compose(lt(1), length), values(byTrainRunId))
      ) {
        throw new Error(`Attempt to store UserTrainRunIntervalsByRouteId with the same UserTrainRunInterval.sourceKey or UserTrainRunInterval.trainRunInterval.trainRun.id`);
      }
    }, values(userTrainRunIntervalsByRouteId)
  );
  const minimized = mapMDeep(2,
    userTrainRunInterval => {
      return {
        ...omit(['geojson'], userTrainRunInterval),
        user: pick(['id'], userTrainRunInterval.user),
        trainRunInterval: {
          ...omit(['trainRun'], userTrainRunInterval.trainRunInterval),
          trainRun: pick(['id'], userTrainRunInterval.trainRunInterval.trainRun)
        }
      };
    }, userTrainRunIntervalsByRouteId
  );
  return minimized;
};
/**
 * Extracts the min and max range of the given userTrainRunIntervals, or if there are none the
 * range of the given baselineUserTrainRunInterval, or failing the latter's existence 0,0
 * @param {Object} [baselineUserTrainRunInterval] The Baseline UserTrainRunInterval if one exists
 * @param [{Object}] userTrainRunIntervals Those to take the min and max of
 * @returns {{start, end}|*}
 */
export const rangeOfUserTrainRunIntervals = ({ baselineUserTrainRunInterval = null }, userTrainRunIntervals) => {
  // Gets the min or max distance
  const f = (minMax, startEnd, init) => {
    return reduce((acc, obj) => minMax(
        acc,
        view(lensPath(['trainRunInterval', 'distanceRange', startEnd]), obj)
      ),
      init,
      userTrainRunIntervals
    );
  };
  // Get the min start and max end of the others
  return length(userTrainRunIntervals) ? {
    start: f(min, 'start', Infinity),
    end: f(max, 'end', 0)
  } : pick(['start', 'end'], baselineUserTrainRunInterval?.trainRunInterval?.distanceRange || { start: 0, end: 0 });
};

/**
 * Check if imuPoints are loaded
 * @param userTrainRunInterval
 * @returns {*}
 */
export const userTrainRunIntervalHasImuPoints = userTrainRunInterval => {
  return trainRunHasImuPoints(userTrainRunInterval.trainRunInterval.trainRun);
};
export const logImuPointsOfUserTrainRunIntervals = (userTrainRunIntervals, label = '') => {
  forEach(
    userTrainRunInterval => {
      const imuPoints = chain(strPathOr([], 'collectionDevice.imuPoints'), trainRunIntervalCollectionDevices(userTrainRunInterval.trainRunInterval));
      console.debug(
        join('\n',
          compact([
            label,
            userTrainRunInterval.sourceKey,
            `has: ${userTrainRunIntervalHasImuPoints(userTrainRunInterval)}`,
            `extremes: ${
              map(prop('time'), extremes(sortBy(imuPoint => imuPoint.time, imuPoints)))
            }`]
          )
        )
      );
    },
    userTrainRunIntervals
  );
};

/**
 * Return the TrainRun of the userTrainRunInterval
 * @param userTrainRunInterval
 * @returns {*}
 */
export const trainRunOfUserTrainRunInterval = userTrainRunInterval => {
  return reqStrPathThrowing('trainRunInterval.trainRun', userTrainRunInterval);
};

/**
 * Return the TrainRun.id of the userTrainRunInterval
 * @param userTrainRunInterval
 * @returns {*}
 */
export const trainRunIdOfUserTrainRunInterval = userTrainRunInterval => {
  return reqStrPathThrowing('trainRunInterval.trainRun.id', userTrainRunInterval);
};

/**
 * Return the TrainRoute of the TrainRun of the UserTrainRunInterval
 * @param userTrainRunInterval
 * @returns {*}
 */
export const trainRouteOfUserTrainRunInterval = userTrainRunInterval => {
  return reqStrPathThrowing('trainRunInterval.trainRun.trainRoute', userTrainRunInterval);
};

/**
 * The TrainRoute distanceRange of the TrainRoute of the userTrainRunInterval's TrainRun.
 * This is different than userTrainRunInterval.trainRunInterval.distanceRange, which is based on the
 * currently selected TrainRoute or TrainRouteGroup. If a TrainRouteGroup is active, it can be longer
 * than the range returned here.
 * @param userTrainRunInterval
 * @returns {{start: number, end: *}}
 */
export const trainRunTrainRouteDistanceRangeOfUserTrainRunInterval = userTrainRunInterval => {
  return { start: 0, end: trainRunOfUserTrainRunInterval(userTrainRunInterval).trainRoute.routeDistance };
};

/**
 * Correct problems with the way Recharts picks the payloadItems, and/or find the closest points of
 * the UserTrainRunIntervals that were not hovered over
 * @param {Function} t Translation function
 * @param {[Object]} userTrainRunIntervals The active UserTrainRunIntervals, which correspond to the
 * payloadItems
 * @param {[Object]} payloadItems 0 to length(userTrainRunIntervals) payloadItems from recharts or generated
 * manually from a Mapbox hover. Each has a payload, the feature of the UserTrainRunInterval.geojson.featureCollectionPoints
 * and attributes such as the UserTrainRunInterval label
 * @returns {[Object]} The corrected payloadItems
 */
export const correctPayload = ({ t, userTrainRunIntervals, payloadItems }) => {

  const sortedPayloadItems = sortBy(
    // Sort by the index of userTrainRunIntervals so it matches the active UserTrainRunIntervals order
    compose(
      userTrainRunInterval => indexOf(userTrainRunInterval, userTrainRunIntervals),
      prop('userTrainRunInterval')
    ),
    compact(map(payloadItem => {
          // TODO we should be able to pass userTrainRunInterval with the payloadItems
          const userTrainRunInterval = find(
            userTrainRunInterval => {
              const name = userTrainRunIntervalUniqueLabel({ t, userTrainRunInterval });
              return equals(
                payloadItem.name,
                name
              );
            },
            userTrainRunIntervals
          );
          // If we don't have featureCollectionPoints, it means the user-selected distance range
          // is outside this TrainRun's TrainRoute, or we didn't get sensor data where expected.
          // If userTrainRunInterval.activity.isVisible is true, don't show this payloadItem.
          if (!userTrainRunInterval ||
            !length(userTrainRunInterval.geojson.featureCollectionPoints.features) ||
            !userTrainRunInterval.activity.isVisible) {
            if (!userTrainRunInterval) {
              console.warn('Payload item lacks a userTrainRunInterval. This should never happen');
            }
            // Return null to discard this payloadItem
            return null;
          }
          const featureCollectionPoints = userTrainRunInterval.geojson.trainRunIntervalSensorPointsTransformed;
          const metersAtHoverPoint = payloadItem.metersOfHoveredItem;
          // Mark as the active payloadItems item if metersAtHoverPoint is a value in the features of the UserTrainRunInterval
          //  payloadItem.isActivePayloadItem is set true ahead of time for the Mapbox hover case
          const isActivePayloadItem = payloadItem.isActivePayloadItem || !!find(
            ({ properties: { meters } }) => {
              return equals(metersAtHoverPoint, meters);
            },
            featureCollectionPoints.features
          );
          return { ...payloadItem, userTrainRunInterval, isActivePayloadItem };
        },
        payloadItems || [])
    ));

  // payloadItem.userTrainRunInterval can be null when we remove UserTrainRuns
  const validPayloadItems = filter(prop('userTrainRunInterval'), sortedPayloadItems);
  // When there is no corresponding dataIndex for the non active UserTrainRunInterval chart lines,
  // Recharts incorrectly assigns data from the active UserTrainRunInterval. Se we need to detect this
  // and replace the data with the closest point, if it's within 500 meters
  const getMeters = reqStrPathThrowing('payload.properties.meters');
  const getFeatures = reqStrPathThrowing('userTrainRunInterval.geojson.trainRunIntervalSensorPointsTransformed.features');
  const updatedPayloadItems = compact(
    map(
      payloadItem => {
        const compareWithMeter = payloadItem.metersOfHoveredItem;
        // If have no metersOfHoveredItem (Mapbox hover case) then do nothing.
        // Otherwise find the closest feature point to use as the payloadItems, since Recharts doesn't find the correct points
        // We do this on the active payloadItem as well because Recharts seems to somehow select the wrong
        // item sometimes.
        const maybedUpdatedPayloadItem = !payloadItem.metersOfHoveredItem ? payloadItem :
          compose(
            // If this payloadItems item isn't within 500 of headPayloadItem, don't show it
            unless(
              payloadItem => {
                return payloadItem.payload &&
                  Math.abs(getMeters(payloadItem) - compareWithMeter) <= 500;
              },
              always(null)
            ),
            // Update the value to this feature if dataKey is defined (defined in charts only, not maps)
            when(
              payloadItem => propOr(false, 'dataKey', payloadItem),
              set(
                lensProp('value'),
                strPathOr(null, payloadItem.dataKey || 'noDataKey', payloadItem.payload || {})
              )
            ),
            // For non-active payloadItems, the closest payloadItems data is always wrongly based on the feature index
            // of the active item or incorrectly copied from the
            // active item by recharts when the index doesn't exist for the non-active payloadItem's features.
            // So we need to find the closest feature for this UserTrainRunInterval
            // to payloadItem.metersOfHoveredItem, which is the meters values of the mouse hover on the active line
            payloadItem => {
              // If this UserTrainRunInterval has any features, find the one closest in meters property
              const replacement = length(getFeatures(payloadItem)) ?
                sortBy(
                  featureCollectionPoint => {
                    return Math.abs(featureCollectionPoint.properties.meters - payloadItem.metersOfHoveredItem);
                  },
                  getFeatures(payloadItem)
                )[0] :
                null;
              return set(
                lensProp('payload'),
                replacement,
                payloadItem
              );
            }
          )(payloadItem);
        return maybedUpdatedPayloadItem;
      },
      validPayloadItems
    )
  );
  return updatedPayloadItems;
};