import { Grid, Stack, Typography } from '@mui/material';
import { TrainDescription } from 'components/apps/trainAppComponents/trainRunChooserComponents/TrainDescription.js';
import { TrainRunEquipment } from 'components/apps/trainAppComponents/trainRunChooserComponents/TrainRunEquipment.js';
import { TrainRouteOrGroupServiceDescription } from 'components/apps/trainAppComponents/trainRunChooserComponents/TrainRouteOrGroupServiceDescription.js';
import {concat, pick} from 'ramda';

/**
 * Description of the TrainRun along with optional buttons and icons: add, remove, and loading icon
 * @param trainRun
 * @param buttons
 * @param {String} trainRouteLabel The label for the TrainRoute. If all TrainRuns are the same TrainRoute, this
 * could be as simple as the destination or perhaps nothing at all. If the TrainRuns are different TrainRoute because
 * a TrainRouteGroup is being displayed, it should show the origin and destination for clarity
 * @param {Boolean} showTrainRunLine Changes formatting based on the presence of a TrainRunLine that shows a line
 * with the stops
 * @returns {JSX.Element}
 * @constructor
 */
const TrainRunDescriptionWithoutTrainRunLine = (
  {
    trainRun,
    isBaseline,
    buttons,
    dateAndOrTimeString,
    trainRouteLabel,
      rmseIcons
  }) => {

  return <Grid container key={trainRun.id}
               justifyContent='space-between'
               sx={{}}>
    <Grid item
          xs={10}
          sx={{}}>
      <Stack spacing={0.5}>
        <Stack {...{ direction: 'row', spacing: 1 }}>

          <TrainRouteOrGroupServiceDescription  {...{
            showTitle: false,
            isBaseline,
            ...pick(['serviceLine', 'source'], trainRun)
          }} />
          <Typography key='destination' sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }} variant='caption'>
            {trainRouteLabel}
          </Typography>
        </Stack>
        <TrainDescription {...{ showTitle: false, trainRun }} />
        <TrainRunEquipment  {...{ showTitle: false, trainRun }} />
        <Typography variant='caption'>{
          dateAndOrTimeString
        }</Typography>
      </Stack>
    </Grid>
    <Grid item xs={2} sx={{}}>
      {<Stack spacing={0.5} sx={{ alignItems: 'flex-end' }}>{concat(
          rmseIcons,
          buttons
      )}</Stack>}
    </Grid>
  </Grid>;
};
export default TrainRunDescriptionWithoutTrainRunLine;