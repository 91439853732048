import {add, reduce} from "ramda";
import {RideComfortAlertCount} from "../../types/rideComfort/rideComfortMapData";

/**
 * Adds up the count attirbute value of each RideComfortAlertCount
 * @param rideComfortAlertCounts
 */
export const getTotalCount = (rideComfortAlertCounts: RideComfortAlertCount[]): number => {
    return reduce(
        (accum, rideComfortAlertCount: RideComfortAlertCount) => {
            return add(accum, rideComfortAlertCount.count)
        },
        0,
        rideComfortAlertCounts
    )
};
