import {
  always,
  clone,
  cond,
  equals,
  filter,
  findIndex,
  identity,
  includes,
  isNil,
  join,
  length,
  lensIndex,
  lensPath,
  map,
  mergeRight,
  not,
  omit,
  over,
  prepend,
  prop,
  propOr,
  set,
  T,
  when
} from 'ramda';
import { pathOr, reqStrPathThrowing, strPathOr } from '@rescapes/ramda';
import { isTrainRouteGroup } from 'appUtils/trainAppUtils/trainRouteUtils.js';
import { createTrainRunInterval } from 'appUtils/trainAppUtils/trainRunIntervalUtils.js';

/**
 * Toggles the visibility of the UserTrainRunInterval by toggling and saving activity.isVisible
 * @param {Object} crudSelectedUserTrainRunIntervals CRUD operations for saving
 * @param {Object} userTrainRunInterval The UserTrainRunInterval
 */
export const toggleUserTrainRunIntervalVisibility = ({ crudSelectedUserTrainRunIntervals, userTrainRunInterval }) => {
  const modifiedUserTrainRunInterval = over(
    lensPath(['activity', 'isVisible']),
    not,
    userTrainRunInterval
  );
  crudSelectedUserTrainRunIntervals.updateOrCreate(modifiedUserTrainRunInterval);
};

/**
 * Called by crudUserTrainRunInterval after a Crud operation to store
 * the state of crudUserTrainRunInterval in userTrainRunIntervalsForAllTrainRoutes thereby caching
 * the state of crudUserTrainRunInterval for the current TrainRoute
 * @param {Object} trainRoute
 * @param {Function} setUserTrainRunIntervalsForAllTrainRoutes
 * @param {[Object]} userTrainRunIntervals The UserTrainRunIntervals that were just set
 * @returns {(function(*): void)|*}
 */
export const postSetList = ([trainRoute, setUserTrainRunIntervalsForAllTrainRoutes], userTrainRunIntervals) => {
  // Update our UserTrainRunIntervalsForAllTrainRoutes for the current trainRoute
  setUserTrainRunIntervalsForAllTrainRoutes(
    userTrainRunIntervalsForAllTrainRoutes => {
      const trainRouteIds = map(prop('id'), isTrainRouteGroup(trainRoute) ? trainRoute.trainRoutes : [trainRoute]);
      const mismatchingTrainRuns = filter(
        userTrainRunInterval => !includes(userTrainRunInterval.trainRunInterval.trainRun.trainRoute.id, trainRouteIds),
        userTrainRunIntervals
      );
      if (length(mismatchingTrainRuns)) {
        throw new Error(`TrainRuns with ids ${join(', ', map(prop('id'), mismatchingTrainRuns))} don't match the TrainRouteOrGroup they are being cached with`);
      }
      return { ...userTrainRunIntervalsForAllTrainRoutes, [trainRoute.id]: userTrainRunIntervals };
    }
  );
};
/**
 * Syncs active UserTrainRunIntervals to the given trainRouteInterval distanceRange
 * @param crudTrainRunIntervals
 * @param trainRouteInterval
 * @param trainRouteInterval.distanceRange. The distanceRange to sync to
 * @returns {*}
 */
export const syncActiveUserTrainRunIntervals = (crudTrainRunIntervals, trainRouteInterval) => {
  // Update everything, but only change the distanceRange of those with isSyncedToTrainRouteInterval = true
  crudTrainRunIntervals.set(
    map(
      userTrainRunInterval => {
        return when(
          strPathOr(false, 'activity.isActive'),
          userTrainRunInterval => {
            return set(
              lensPath(['trainRunInterval', 'distanceRange']),
              clone(trainRouteInterval.distanceRange),
              userTrainRunInterval
            );
          }
        )(userTrainRunInterval);
      },
      crudTrainRunIntervals.list
    )
  );
  return trainRouteInterval;
};

/**
 * Creates a UserTrainRunInterval from a user and TrainRunInterval
 * @param {Object} user The user (can be the client)
 * @param {Object} trainRunInterval The TrainRunInterval
 * @param {Object} [activity] Defaults to { isActive: true }
 */
export const createUserTrainRunInterval = (
  {
    user,
    trainRunInterval,
    activity = null
  }) => {
  return {
    sourceKey: `userTrainRunInterval${trainRunInterval.sourceKey}`,
    __type: 'UserTrainRunInterval',
    trainRunInterval,
    user,
    // Default isActive and isVisible to true
    activity: mergeRight({ isActive: true, isVisible: true }, activity)
  };
};

/**
 * Uses client.preconfiguredRouteIdToTrainRunIds to add preconfigured TrainRuns based on the TrainRoute
 * Note that these TrainRuns don't have to match the current TrainRoute, so we update their
 * TrainRoute to the current TrainRoute if the current TrainRoute is not a TrainRouteGroup to prevent
 * the other code from complaining that the TrainRun has a wrong TrainRoute
 * @param organization
 * @param trainRouteInterval
 * @param {[Object]} userTrainRunIntervalsFromStorageMinimized The UserTrainRunIntervals in the storage. Prevents stored preconfigured
 * UserTrainRunIntervals from being duplicated here
 * @returns {[Object]} The preconfigured UserTrainRunIntervals prepended to userTrainRunIntervalsFromStorageMinimized
 */
export const maybeAddPreconfiguredUserTrainRunIntervalsByRoute = (
  {
    organization,
    trainRouteInterval,
    userTrainRunIntervalsFromStorageMinimized
  }) => {
  const trainRouteOrGroupId = trainRouteInterval.trainRoute.id;
  // TODO for now just take the 1st preconfigured value. This is a special case for Flytoget's baselines,
  // where we had two runs per direction but only want one.
  const preconfiguredTrainRunId = pathOr(null, [trainRouteOrGroupId, 0], organization.preconfiguredRouteIdToTrainRunIds);
  const maybeIndex = findIndex(
    userTrainRunIntervalFromStorageMinimized => {
      return equals(
        preconfiguredTrainRunId,
        reqStrPathThrowing('trainRunInterval.trainRun.id', userTrainRunIntervalFromStorageMinimized)
      );
    },
    userTrainRunIntervalsFromStorageMinimized
  );
  const createOrMergePredefinedUserTrainRunInterval = (matchingUserTrainRUnInterval = {}) => {
    return createUserTrainRunInterval({
      user: organization,
      trainRunInterval: createTrainRunInterval({
        trainRouteInterval,
        trainRun: {
          id: preconfiguredTrainRunId,
          isPreconfigured: true,
          // Because preconfigured TrainRuns might be a different TrainRoute, override to the current TrainRoute
          // We don't need to do this in the context of a TrainRouteGroup, since the TrainRun's TrainRoute
          // will be a member of that TrainRouteGroup
          overrideTrainRoute: isTrainRouteGroup(trainRouteInterval.trainRoute) ? null : trainRouteInterval.trainRoute
        }
      }),
      // Favor the activity properties of the existing but make sure isBaseline is always true
      activity: mergeRight(
        { isActive: true, isBaseline: true, isVisible: true },
        omit(['isBaseline'], propOr({}, 'activity', matchingUserTrainRUnInterval))
      )
    });
  };
  // If the preconfigured TrainRun was already in storage, merge it. Otherwise create it.
  // We only take userTrainRunInterval.activity properties from the stored version. Nothing else can be edited
  // by the user
  const merged = cond([
    // if no preconfigured id, just return userTrainRunIntervalsFromStorageMinimized
    [always(isNil(preconfiguredTrainRunId)), identity],
    // Prepend The predefined UserTrainRun if not yet stored
    [
      always(equals(-1, maybeIndex)),
      userTrainRunIntervalsFromStorageMinimized => prepend(
        createOrMergePredefinedUserTrainRunInterval(),
        userTrainRunIntervalsFromStorageMinimized
      ),
    ],
    // If already stored, merge, just taking the properties of the stored instance that are editable by the user
    [T, over(
      lensIndex(maybeIndex),
      matchingUserTrainRunInterval => {
        return createOrMergePredefinedUserTrainRunInterval(matchingUserTrainRunInterval);
      }
    )]
  ])(userTrainRunIntervalsFromStorageMinimized);
  return merged;
};