// https://tinloof.com/blog/how-to-make-your-own-splitpane-react-component-with-0-dependencies
import { createRef, forwardRef, useEffect, useRef, useState } from 'react';
import { Box, Divider, Stack } from '@mui/material';
import { toArrayIfNot } from '@rescapes/ramda';
import { includes } from 'ramda';

/**
 * Creates a Split-pane based on https://tinloof.com/blog/how-to-make-your-own-splitpane-react-component-with-0-dependencies
 * @param defaultLeftWidth Optional starting width of the left element
 * @param minLeftWidth Defaults to 25%
 * @param {JSX.Element|[JSX.Element]} children
 * @returns {JSX.Element}
 */
const TrainBoardSplitPaneHorizontal = forwardRef((
  {
    displayProps,
    defaultLeftWidth = null,
    minLeftWidth = '25%',
    children,
    onChange
  }, ref) => {
  const [leftWidth, setLeftWidth] = useState(defaultLeftWidth);
  const separatorXPosition = useRef(null);
  const splitPaneRef = createRef();
  // Report changes with onChange
  useEffect(() => {
      // Assume that bothh sides have the same height for now
      onChange({leftWidth, rightWidth: ref?.current?.width, height: ref?.current?.height})
  },
  [leftWidth, displayProps])

  return <Stack {...{
    spacing: 0.1,
    sx: {
      display: 'flex',
      flexDirection: 'row',
      overflowY: 'hidden',
      overflowX: 'hidden',
      mr: '5px',
      width: '100%',
      maxWidth: '100%'
    },
    ref: splitPaneRef
  }}>
    <TrainBoardSplitPaneLeft
      sx={{ width: '100%', height: '100%', minWidth: '25%', maxWidth: '100%', maxHeight: '100%' }}
      leftWidth={leftWidth}
      setLeftWidth={setLeftWidth}
    >
      {children[0]}
    </TrainBoardSplitPaneLeft>
    <TrainBoardSplitPaneDivider
      leftWidth={leftWidth}
      setLeftWidth={setLeftWidth}
      minLeftWidth={minLeftWidth}
      separatorXPosition={separatorXPosition}
      splitPaneRef={splitPaneRef}
    />
    <TrainBoardSplitPaneRight ref={ref} sx={{ width: '100%', height: '100%', maxWidth: '75%', maxHeight: '100%' }}>
      {children[1]}
    </TrainBoardSplitPaneRight>
  </Stack>;
})

const TrainBoardSplitPaneLeft = function SplitPaneLeft({ leftWidth, setLeftWidth, ...props }) {
  const leftRef = createRef();

  useEffect(() => {
    if (!leftWidth) {
      setLeftWidth(leftRef.current.clientWidth);
      leftRef.current.style.flex = 'none';
    }
    // If leftWidth is a percent, convert it to a percent of clientWidth so we can do arithmetic on it below
    else if (includes('%', leftWidth.toString())) {
      setLeftWidth(leftRef.current.parentElement.clientWidth * parseFloat(leftWidth) / 100);
    } else {
      leftRef.current.style.width = `${leftWidth}px`;
      leftRef.current.style.maxWidth = `${leftWidth}px`;
    }
  }, [leftWidth]);
  return <Box ref={leftRef} {...props} sx={{
    flex: 1,
    overflow: 'hidden'
  }} />;
};

const TrainBoardSplitPaneRight = forwardRef((props, ref) => {
  return <Box {...{
    ref,
    ...props,
    sx: {
      flex: 1,
      overflow: 'hidden'
    }
  }} />;
});
TrainBoardSplitPaneRight.displayName = 'TrainBoardSplitPaneRight'

/**
 * The Divider
 * @param sx
 * @param leftWidth
 * @param setLeftWidth
 * @param separatorXPosition
 * @param splitPaneRef
 * @param {Number|String} minLeftWidth  Default 0. The minimum allowed width in absolute pixels or in percent relative to
 * the parent container width
 * @param {Number|String} maxLeftWidth Default splitPaneRef width. THe maximum allowed width in the absolute pixels or
 * in percent relative to the parent container width
 * @returns {JSX.Element}
 * @constructor
 */
const TrainBoardSplitPaneDivider = (
  {
    sx,
    leftWidth, setLeftWidth,
    separatorXPosition,
    splitPaneRef,
    minLeftWidth = 0
  }) => {

  // Have the Document react to moves and up
  useEffect(() => {
    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);

    return () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    };
  });
  const onMouseDown = (e) => {
    separatorXPosition.current = e.clientX;
  };

  const onMouseMove = (e) => {
    if (!separatorXPosition.current) {
      return;
    }

    // Incrementally change the width based on the offset
    const newLeftWidth = leftWidth + e.clientX - separatorXPosition.current;
    separatorXPosition.current = e.clientX;

    const splitPaneWidth = splitPaneRef.current?.clientWidth || 0;
    const calculatedMinLeftWidth = splitPaneWidth * (minLeftWidth ? parseFloat(minLeftWidth) / 100 : 0);
    // If too low, set to 0
    if (newLeftWidth < calculatedMinLeftWidth) {
      leftWidth !== 0 && setLeftWidth(calculatedMinLeftWidth);
    }
    // If too wide, set to the max width
    else if (newLeftWidth > splitPaneWidth) {
      leftWidth !== splitPaneWidth && setLeftWidth(splitPaneWidth);
    } else {
      // Call set
      setLeftWidth(newLeftWidth);
    }
  };

  const onMouseUp = () => {
    // Reset the ref value
    separatorXPosition.current = null;
  };

  return <Divider
    orientation='vertical'
    flexItem
    onMouseDown={onMouseDown}
    sx={
      [
        {
          p: 0,
          cursor: 'col-resize',
          borderColor: 'secondary',
          width: '20px',
          '& .MuiDivider-root::hover::before': {
            // TODO doesn't match
            borderColor: 'secondary'
          },
          '& .MuiDivider-root:hover::after': {
            // TODO doesn't match
            borderColor: 'secondary'
          },
          '& .MuiDivider-wrapperVertical': {
            p: 0
          }
        },
        ...toArrayIfNot(sx)
      ]}>

    <Box
      sx={{
        // This box must be present or else MUI doesn't center the vertical line drawn by :before and :after borders
        display: 'none', width: '0', height: '0'
      }}
    />
  </Divider>;
};

TrainBoardSplitPaneHorizontal.displayName = 'TrainBoardSplitPaneHorizontal'
export default TrainBoardSplitPaneHorizontal;