import { equals } from 'ramda';
import {
  trainApiAvailableTrainRunDates,
  trainApiClient,
  trainApiFormations,
  trainApiRailwayLines,
  trainApiServiceLines,
  trainApiTrainRouteGroups,
  trainApiTrainRoutes,
  trainApiTrainRuns
} from 'lib/fetch/cemitApi/trainApi.js';

export const organization = {};


/**
 * Resolves the correct APi method URL
 * @param {String} method A name to match. This doesn't currently always match the API method that is actually called
 * @param {Object} dateRange
 * @param {Object} dateRange.start The start date to search for
 * @param {Object} dateRange.end The end date to search for
 * @param {[Object]} [formations] Limits TrainRuns by formation if specified
 * @param {[Object]} [dateRecurrences] Limits TrainRuns by days of week and/or departure times
 * @param {Object} baselineConfigs Only used when loading baseline TrainRuns
 * @return {String|null} The URL or or null if needed parameters for the method are missing
 */
const trainApiResolver = (
  {
    method,
    dateRange,
    organization,
    trainRoute,
    formations,
    dateRecurrences,
    serviceLines,
    trainRunIds
  }) => {
  if (equals('client', method)) {
    return trainApiClient(organization);
  } else if (equals('trainRuns', method)) {
    return trainApiTrainRuns(dateRange, formations, dateRecurrences, trainRoute, trainRunIds);
  } else if (equals('availableDates', method)) {
    return trainApiAvailableTrainRunDates(trainRoute);
  } else if (equals('serviceLines', method)) {
    return trainApiServiceLines(organization);
  } else if (equals('railwayLines', method)) {
    return trainApiRailwayLines(organization);
  }
  else if (equals('trainRouteGroups', method)) {
    return trainApiTrainRouteGroups(organization);
  }
  else if (equals('trainRoutes', method)) {
    return trainApiTrainRoutes(serviceLines);
  } else if (equals('formations', method)) {
    return trainApiFormations(organization);
  }
  throw new Error(`Method ${method} does not match the client's available API methods`);
};
export default trainApiResolver;