
import { memo, useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

// Wrapper for DndProvider to prevent exception "Cannot have two HTML5 backends at the same time"
// https://github.com/react-dnd/react-dnd/issues/186:
// DndProvider has an options prop in where you can set rootElement which bounds DnD to that specified context, and
// unfortunately it isn't documented well. This approach solved all my issues, as I had other component which was using
// DnD and they were out of my boundary and I wasn't able to make HTML5Backend singleton. I tried this approach with
// "react-dnd": "^14.0.2"
export const DndWrapper = memo((props) => {
  const [context, setContext] = useState(null);

  useEffect(() => {
    setContext(document.getElementById(props.id));
  }, [props.id]);

  return context ? (
    <DndProvider backend={HTML5Backend} options={{ rootElement: context }}>
      {props.children}
    </DndProvider>
  ) : null;
});
DndWrapper.displayName = 'DndWrapper';