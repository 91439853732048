import { Button, ButtonWrapper, Profile } from 'pages/profile/profileOldStyles.js';
import { Link, useNavigate } from 'react-router-dom';
import { Stack, Typography } from '@mui/material';
import { always, identity, ifElse, join, map, compose } from 'ramda';
import {capitalize} from '@rescapes/ramda'

/**
 * TODO old undocumented code. Fix or remove
 * User profile page showing user information and a logout button
 * @param appProps
 * @param organizationProps
 * @returns {JSX.Element}
 * @constructor
 */
const ProfilePage = ({ organizationProps }) => {
  const { userState } = organizationProps;
  const navigate = useNavigate();

  const logout = () => {
    localStorage.removeItem('token');
    // TODO trackAccess, tabAccess, and wheelAccess are too primitive and need to be replaced by real authentication
    localStorage.removeItem('trackAccess');
    localStorage.removeItem('tabAccess');
    localStorage.removeItem('wheelAccess');
    localStorage.removeItem('group');
    navigate('/');
  };

  return (
    <Profile>
      <h1 style={{ textAlign: 'center' }}>
        <Stack justifyContent='start' sx={{ textAlign: 'left' }}>
          <Typography key='orgName'>Organization: {userState.clientSourceKey}</Typography>
          <Typography key='accountEmail'>Account email: {userState.email}</Typography>
          {APP_ENV === 'local' ?
            [
              <Typography key='appAccess'>Application access: {
                ifElse(identity, join(', '), always('None'))(userState.tabAccess)
              }</Typography>,
              <Typography key='railwayAccess'>Railway access: {
                ifElse(identity, compose(join(', '), map(capitalize)), always('None'))(userState.trackAccess)
              }</Typography>
            ] : []
          }
        </Stack>
      </h1>
      <ButtonWrapper>
        <Link to='/'>
          <Button onClick={logout}>Logout</Button>
        </Link>
      </ButtonWrapper>
    </Profile>
  );
};

export default ProfilePage;
