import { applyDeepWithKeyWithRecurseArraysAndMapObjs, reqPathThrowing, reqStrPathThrowing } from '@rescapes/ramda';
import { evalDateRange, isDateRange } from 'appUtils/trainAppUtils/trainFilterDateRangeUtils.js';
import { evalDateRecurrence, isDateRecurrence } from 'appUtils/trainAppUtils/trainFilterDateRecurrenceUtils.js';
import { cond, equals, head, identity, lensProp, map, over, T } from 'ramda';
import { isEquals, isView } from 'appUtils/trainAppUtils/trainFilterAtoms.js';
import { evalFormation, isFormation } from 'appUtils/trainAppUtils/trainFilterTrainFormation.js';

/**
 * Partially evaluates the filter for any view that that matches a
 * path in props such that the props values can be substituted in for the view
 * @param trainRunFilter
 * @param props
 * @returns {*}
 */
export const evalFilter = (trainRunFilter, props) => {
  // Evaluate any props in lensPath that aren't trainRUn
  return applyDeepWithKeyWithRecurseArraysAndMapObjs(
    (l, r) => r,
    (key, obj) => {
      return cond([
        [
          obj => isDateRange(obj, props),
          obj => {
            // Replace the view with the corresponding props value if no a trainRun vidw
            return evalDateRange(obj, props);
          }
        ],
        [
          obj => isDateRecurrence(obj, props),
          obj => {
            return evalDateRecurrence(obj, props);
          }
        ],
        [
          obj => isFormation(obj, props),
          obj => {
            return evalFormation(obj, props);
          }
        ],
        [
          obj => isView(obj, props),
          obj => {
            // Replace the view with the corresponding props value if not a trainRun view
            return evalView(obj, props);
          }
        ],
        [
          obj => isEquals(obj, props),
          obj => {
            // Partially eval the arguments of equals. We expect the second to be a trainRun and not get evaluated
            return evalEquals(obj, props);
          }
        ],
        // Otherwise return obj as is
        [T, identity]
      ])(obj);
    },
    trainRunFilter
  );
};

/***
 * Processes a an object representing a ramda view function.
 * If the view's lensPath does not start with trainRun, which must be
 * interpreted on the server, it converts the view to the value of the
 * props corresponding to the views lensPath
 * @param {Object} trainRunFilterView A ramda view function in object form
 * @param {Object} props Props to apply to the view function
 * @returns {Object|*} The unchanged view if the lensPath started with 'trainRun'
 * Otherwise the prop value correpsonding to the viewPath
 */
const evalView = (trainRunFilterView, props) => {
  // Assume that view args are a scalar object, not an array of objects
  const viewArgs = reqStrPathThrowing('view', trainRunFilterView);
  const lensPath = reqStrPathThrowing('lensPath', viewArgs);
  if (equals('trainRun', head(lensPath))) {
    // trainRun views represent filtering of TrainRuns, which currently happens server-side
    return trainRunFilterView;
  }
  // Evaluate the corresponding prop path and return
  return reqPathThrowing(lensPath, props);
};

/**
 * Partially evaluates the two arguments of equals
 * @param trainRunFilterView
 * @param props
 * @returns {*}
 */
const evalEquals = (trainRunFilterView, props) => {
  return over(
    lensProp('equals'),
    map(
      arg => {
        return evalFilter(arg, props);
      }
    ),
    trainRunFilterView
  );
};
