import { ChartPayloadItemMinimized } from '../../railbedTypes/dataVisualizations/chartPayloadItem';
import { head } from 'ramda';
import { pointFeatureFromPayLoadItem } from '../chart/chartMapInteractionUtils.ts';

/***
 * Extracts or creates a Point Feature from the first item of a recharts payload. If the payload has no items, null is returned
 * @param payload
 * @returns {*}
 */
export const singlePointFeatureFromPayload = (payload: ChartPayloadItemMinimized[]) => {
  // TODO we currently use head, but maybe we should consider all payload items
  const payloadItem = head(payload || []);
  if (!payloadItem) {
    return null;
  }
  return pointFeatureFromPayLoadItem(payloadItem);
};
