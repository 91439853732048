/**
 * Checks if an object has properties
 * @param potentialObj
 * @param keys
 * @returns
 */
export function objHasProp(
  potentialObj,
  keys
) {
  if (
    typeof potentialObj !== 'object' ||
    potentialObj === null ||
    potentialObj instanceof Date ||
    potentialObj instanceof Array
  ) {
    return false;
  }

  if (keys.every((key) => key in potentialObj)) {
    return true;
  }
  return false;
}
