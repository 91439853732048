//  https://stackoverflow.com/questions/53446020/how-to-compare-oldvalues-and-newvalues-on-react-hooks-useeffect

import { useEffect, useRef } from 'react';
import { useNotLoadingEffect } from 'utils/hooks/useMemoHooks.js';
import { concat } from 'ramda';

/**
 * Used next to useEffect to see if a value has changed
 * @param value
 * @returns {boolean}
 */
export const useHasChanged = value => {
  const prevVal = usePrevious(value);
  return prevVal !== value;
};
/**
 * Version of useHasChanged that does nothing is loading is true
 * @param loading
 * @param toValue Called with no arguments to produce the value if not loading
 * @param otherDependencies Default [] Other dependencies to check in addition to loading and value
 * @returns {boolean} Whether the value has changed. Returns null if loading
 */
export const useNotLoadingHasChanged = (loading, toValue, otherDependencies) => {
  const value = loading ? null : toValue()
  const prevVal = useNotLoadingPrevious(loading, value, otherDependencies);
  return loading ? null : prevVal !== value;
};


/**
 * Tracks the value of something
 * @param value
 * @returns {*}
 */
export const usePrevious = value => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

/**
 * Stores the value of the previous value and updates whenever loading and values or otherDependencies change
 * @param loading
 * @param value
 * @param otherDependencies Default []
 * @returns {*}
 */
export const useNotLoadingPrevious = (loading, value, otherDependencies=[]) => {
  const ref = useRef();
  useNotLoadingEffect(loading, () => {
    ref.current = value;
  }, concat([value], otherDependencies));
  return ref.current;
};