import {Tooltip, Box, IconButton} from '@mui/material'
import {RideComfortProps} from "../../../../types/rideComfort/rideComfortProps.ts";
import {RideComfortGaugeByTimePeriod} from "../../../../types/rideComfort/rideComfortGauge";
import {TravelExplore} from '@mui/icons-material'
import React from "react";
import {CEMIT_YELLOW} from "../../../../theme/colors.ts";
import {darken} from "@mui/material/styles/index.js";

const RideComfortTimePeriodForMapChooser = ({rideComfortProps, scopedRideComfortGaugeByTimePeriod}: {
    rideComfortProps: RideComfortProps,
    scopedRideComfortGaugeByTimePeriod: keyof RideComfortGaugeByTimePeriod
}) => {
    const {rideComfortTimePeriodForMap, setRideComfortTimePeriodForMap} = rideComfortProps
    const disabled = rideComfortTimePeriodForMap == scopedRideComfortGaugeByTimePeriod
    return <Tooltip {...{key: 'timePeriodForMapChooser', arrow: true, title: `${disabled ? 'Showing': 'Show'} this data on the map`}}>
        <Box>
            <IconButton {...{
                key: 'timePeriodForMapChooserButton',
                disabled,
                color: 'secondary',
                'aria-label': 'Show this data on the map',
                sx: {
                    float: 'right',
                    alignItems: 'flex-start',
                    p: 0,
                    "&.Mui-disabled": {
                        color: darken(CEMIT_YELLOW, .25)
                    }
                },
                onClick: () => {
                    setRideComfortTimePeriodForMap(scopedRideComfortGaugeByTimePeriod)
                }
            }}
            >
                <TravelExplore/>
            </IconButton>
        </Box>
    </Tooltip>;
}
RideComfortTimePeriodForMapChooser.displayProps = 'RideComfortTimePeriodForMapChooser'
export default RideComfortTimePeriodForMapChooser
