const static_cache_version = 'static-' + APP_ENV + '-' + APP_RELEASE;
const js_cache_version = 'js-' + APP_ENV + '-' + APP_RELEASE;
//const caches_to_add = ['./index.html'];

self.addEventListener('activate', (e) => {
  e.waitUntil(
    caches.keys().then((keys) => {
      return Promise.all(
        keys.map((key) => {
          if (
            key !== js_cache_version &&
            key !== static_cache_version &&
            key !== 'mapbox-tiles'
          ) {
            if (DEBUG) {
              console.log(`deletes cache ${key}`);
              console.log(static_cache_version);
              console.log(js_cache_version);
              console.log('mapbox-tiles');
            }
            return caches.delete(key);
          }
        })
      );
    })
  );
});

/*
self.addEventListener('install', (event) => {
  self.skipWaiting();
  event.waitUntil(
    caches.open(static_cache_version).then((cache) => {
      // console.log('install event finished');
      return cache.addAll(caches_to_add);
    })
  );
});
 */

self.addEventListener('fetch', (e) => {
  let res = caches.match(e.request).then((response) => {
    return (
      response ||
      fetch(e.request).then((responseFromFetch) => {
        if (
          e.request.url.endsWith('.svg') ||
          (e.request.url.endsWith('.png') && !e.request.url.includes('/minio'))
        ) {
          let responseClone = responseFromFetch.clone();
          if (DEBUG) {
            console.log('new static file cached');
          }
          caches.open(static_cache_version).then((cache) => {
            cache.put(e.request, responseClone);
          });
        }

        if (
          (e.request.url.endsWith('.js') || e.request.url.endsWith('.css')) &&
          !e.request.url.startsWith('chrome-extension')
        ) {
          let responseClone = responseFromFetch.clone();
          if (DEBUG) {
            console.log('new .js or .css file cached');
          }
          caches.open(js_cache_version).then((cache) => {
            cache.put(e.request, responseClone);
          });
        }
        return responseFromFetch;
      }).catch(error => {
        console.error(error);
        throw new Error(error);
      })
    );
  });

  e.respondWith(res);
});
