import { useEffect, useState } from 'react';
import StackedAreaChart from 'components/charts/stackedChart/StackedAreaChart.js';
import { always, cond, equals, head, pick, prop, T } from 'ramda';
import LoaderWithText from 'components/loading/LoaderWithText.js';

/***
 *
 * @param appProps
 * @param componentProps
 * @param componentProps.dataPath This is the dataPath being graphed
 * @param componentProps.dataPathsConfigs Send the rest of the configs so that the legend/popup space
 * can show the other values as well
 * @param componentProps.yAxisWidth
 * @param componentProps.index
 * @param componentProps.dataPath
 * @param componentProps.label
 * @param componentProps.labelYAxis
 * @param componentProps.domain
 * @param componentProps.xAxisDomain
 * @param componentProps.setXAxisDomain
 * @param componentProps.showUpdating
 * @param componentProps.strackDataFeatureSets
 * @param componentProps.sx
 * @param forwardedRef
 * @returns {JSX.Element}
 * @constructor
 */
const StackedAreaChartContainer = (
  {
    appProps,
    trainProps,
    componentProps: {
      yAxisWidth,
      index,
      dataPath,
      dataPathsConfigs,
      label,
      labelYAxis,
      yAxisDomainOfDataPathConfig,
      showUpdating,
      trainDataPathStackedChartRefWidth,
      customControls,
      referenceLines
    },
    trackDataFeatureSets,
    sx,
    forwardedRef: ref,
    chartAndMapDataContainerRef
  }
) => {
  const loading = trainProps.userTrainRunIntervalProps.geojsonProps.loading;
  const loadingExplanation = trainProps.userTrainRunIntervalProps.geojsonProps.loadingExplanation;

  // Set yAxisDomain to the default for the dataPath, e.g. 0 to 2 for Y-acceleration
  const [yAxisDomain, setYAxisDomain] = useState(yAxisDomainOfDataPathConfig);
  useEffect(() => {
    if (!equals(yAxisDomain, yAxisDomainOfDataPathConfig)) {
      setYAxisDomain(yAxisDomainOfDataPathConfig);
    }
  }, [yAxisDomainOfDataPathConfig]);

  const [metersOfHoveredItem, setMetersOfHoveredItem] = useState(null);
  const onMouseEnter = state => {
    if (!state?.activePayload) {
      return;
    }
    setMetersOfHoveredItem(state.activeLabel);
    const payloadItem = head(state.activePayload);
    if (payloadItem && !equals(trainProps.userTrainRunIntervalProps.chartProps.chartDataKey, payloadItem.dataKey)) {
      // Indicate that this chart is active and that we should ignore the payload from other chart
      trainProps.userTrainRunIntervalProps.chartProps.setActiveChartDataKey(payloadItem.dataKey);
    }
  };
  const onMouseMove = state => {
    if (!state?.activePayload) {
      return;
    }
    setMetersOfHoveredItem(state.activeLabel);
  };
  const maybeLoaderComponent = cond([
    [prop('showUpdating'), () => {
      return <LoaderWithText key='loadingMoreData' text='loadingMoreData' sx={{ width: '200px' }} spinner={true} />;
    }],
    [prop('loading'), () => {
      return <LoaderWithText key='loadingChart' {...{ text: 'loadingChart', spinner: true, loadingExplanation }} />;
    }],
    [T, always(null)]
  ])({ showUpdating, loading });

  return <StackedAreaChart {...{
    appProps,
    trainProps,
    componentProps: {
      maybeLoaderComponent,
      yAxisWidth,
      index,
      dataPath,
      dataPathsConfigs,
      label,
      labelYAxis,
      xAxisDomain: trainProps.userTrainRunIntervalProps.chartProps.xAxisDomain,
      yAxisDomain, setYAxisDomain,
      referenceLines,
      trainDataPathStackedChartRefWidth,
      activeChartDataKey: trainProps.userTrainRunIntervalProps.chartProps.activeChartDataKey,
      customControls: customControls || [],
      onMouseEnter,
      onMouseMove,
      onMouseOver: onMouseEnter,
      onMouseLeave: () => {
        trainProps.userTrainRunIntervalProps.chartProps.setActiveChartDataKey(null);
      },
      metersOfHoveredItem,
      ...pick(
        ['mostRecentTooltipPayload', 'setMostRecentTooltipPayload'],
        trainProps.userTrainRunIntervalProps.sensorProps
      )
    },
    trackDataFeatureSets,
    sx,
    forwardedRef: ref,
    chartAndMapDataContainerRef
  }} />;
};
StackedAreaChartContainer.displayName = 'StackedAreaChartContainer';
export default StackedAreaChartContainer;