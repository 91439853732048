import { useNotLoadingEffect, useNotLoadingMemo } from 'utils/hooks/useMemoHooks.js';
import {
  addIndex,
  applySpec,
  clone,
  complement,
  compose,
  concat,
  eqProps,
  equals,
  filter,
  forEach,
  head,
  includes,
  indexBy,
  length,
  lensPath,
  lensProp,
  map,
  over,
  pick,
  prop,
  propOr,
  set,
  slice,
  when
} from 'ramda';
import { pickDeepPaths, reqStrPathThrowing, strPathOr, strPathsOr } from '@rescapes/ramda';
import { CEMIT_COMPARE_BASELINE, CEMIT_COMPARE_LINES } from 'theme/colors.ts'
import { USER_TRAIN_RUN_INTERVAL_MAX_ACTIVE_COUNT } from 'appConfigs/trainConfigs/trainConfig.js';
import { createTrainRunIntervals } from 'appUtils/trainAppUtils/trainRunIntervalUtils.js';
import { useEffectCreateListCrud } from 'utils/hooks/crudHooks.js';
import { trainRoutesOfTrainRouteOrGroup } from 'appUtils/trainAppUtils/trainRouteUtils.js';
import {
  mergeFullTrainRunsIntoUserTrainRunIntervals,
  mergeUserTrainRunInterval
} from 'appUtils/trainAppUtils/typeMerging/userTrainRunIntervalMerging.js';
import { trainApiTrainRunWithImuPoints } from 'lib/fetch/cemitApi/trainApi/trainApiTrainRun.js';
import { postSetList } from 'appUtils/trainAppUtils/typeCrud/userTrainRunIntervalCrud.js';

/**
 * Computes the distance range encompassing all given UserTrainRunIntervals in
 * userTrainRunIntervalGeojsons
 * @param {Boolean} loading Do nothing if null
 * @param {[{userTrainRunInterval, userTrainRunIntervalFeatureCollectionPoints,userTrainRunIntervalFeatureCollectionLine }]} userTrainRunIntervalGeojsons
 * Gets the min, max distance range of all userTrainRunIntervalGeojsons combined.
 * @returns { start: min, end: max } The min and max distance in km along the TrainRoute or
 * null if loading or userTrainRunIntervalGeojsons is empty
 */
export const useMemoActiveUserTrainRunIntervalsDistanceRange = (
  {
    loading,
    userTrainRunIntervals
  }) => {

  return useNotLoadingMemo(loading,
    () => {
      return compose(
        values => {
          return length(values) > 1 ?
            applySpec({
              start: compose(Math.min, prop('start')),
              end: compose(Math.max, prop('end'))
            })(...values) :
            pick(['start', 'end'], head(values));
        },
        userTrainRunIntervals => {
          return map(reqStrPathThrowing('trainRunInterval.distanceRange'), userTrainRunIntervals);
        }
      )(userTrainRunIntervals);
    }, [userTrainRunIntervals]
  );
};

/**
 * Deserializes the stored UserTrainRunIntervals for the current TrainRoute
 * @param loading
 * @param trainRuns
 * @param userTrainRunIntervalsForTrainRoute
 * @param userTrainRunIntervals
 * @param setUserTrainRunIntervals
 */
export const useSyncUserTrainRunIntervalsToStoredAndPreconfigured = (
  {
    loading,
    trainRuns,
    userTrainRunIntervalsForTrainRoute,
    userTrainRunIntervals,
    setUserTrainRunIntervals
  }) => {
  // The UserTrainRunIntervals of the current TrainRoute
  useNotLoadingEffect(loading, () => {
    // If the trainRoute changes, set the setUserTrainRunIntervals to those in storage
    const storedUserTrainRunIntervalsForRoute = userTrainRunIntervalsForTrainRoute || [];
    // Deserialize, setting the TrainRuns
    const completeUserTrainRunIntervals = mergeFullTrainRunsIntoUserTrainRunIntervals({
      trainRuns,
      userTrainRunIntervals: storedUserTrainRunIntervalsForRoute || []
    });

    // Compare the properties that distinguish UserTrainRunIntervals
    // The TrainRoute id check exists because we override the TrainRoue of Baseline userTrainRunIntervals
    // to match the current TrainRoute even though they come from a different same-direction TrainRout
    const existingIdSets = map(
      strPathsOr(null, ['trainRunInterval.trainRun.id', 'trainRunInterval.trainRun.trainRoute.id']),
      userTrainRunIntervals
    );
    const incomingIdSets = map(
      strPathsOr(null, ['trainRunInterval.trainRun.id', 'trainRunInterval.trainRun.trainRoute.id']),
      completeUserTrainRunIntervals
    );
    if (!equals(incomingIdSets, existingIdSets)) {
      // prevent infinite dependency loops
      setUserTrainRunIntervals(clone(completeUserTrainRunIntervals));
    }
  }, [trainRuns, userTrainRunIntervalsForTrainRoute, userTrainRunIntervals]);
};

/**
 * We currently limit the active UserTrainRunIntervals to 2. TODO this will change soon
 * @param loading
 * @param crudUserTrainRunIntervals
 * @returns {*}
 */
export const useMemoActiveUserTrainRunIntervals = ({ loading, crudUserTrainRunIntervals }) => {
  return useNotLoadingMemo(loading, () => {
    // Baseline is always first here, plus any others added
    const activeUserTrainRunIntervals = filter(strPathOr(false, 'activity.isActive'), crudUserTrainRunIntervals.list);
    const maxCount = USER_TRAIN_RUN_INTERVAL_MAX_ACTIVE_COUNT;
    // any(userTrainRunInterval => userTrainRunInterval.activity.isBaseline, crudUserTrainRunIntervals) ? USER_TRAIN_RUN_INTERVAL_MAX_ACTIVE_COUNT : USER_TRAIN_RUN_INTERVAL_MAX_ACTIVE_COUNT - 1;
    const visibleActiveUserTrainRunIntervals = slice(0, maxCount, activeUserTrainRunIntervals);
    const invisibleActiveUserTrainRunIntervals = slice(maxCount, Infinity, activeUserTrainRunIntervals);
    // Give the MAX_ACTIVE_COUNT ones a color
    const updatedActiveUserTrainRunIntervals = concat(
      addIndex(map)(
        (userTrainRunInterval, index) => {
          return over(
            lensPath(['activity', 'isActiveColor']),
            color => {
              return color || (userTrainRunInterval.activity?.isBaseline ? CEMIT_COMPARE_BASELINE : CEMIT_COMPARE_LINES[index]);
            },
            userTrainRunInterval
          );
        },
        visibleActiveUserTrainRunIntervals
      ),
      map(
        userTrainRunInterval => {
          return compose(
            userTrainRunInterval => set(lensPath(['activity', 'isActiveColor']), null, userTrainRunInterval),
            userTrainRunInterval => set(lensPath(['activity', 'isActive']), false, userTrainRunInterval)
          )(userTrainRunInterval);
        },
        invisibleActiveUserTrainRunIntervals
      )
    );

    // Inject the updated into our original userTrainRunIntervals
    const sourceKeyToActiveUserTrainRunInterval = indexBy(prop('sourceKey'), updatedActiveUserTrainRunIntervals);
    return map(
      userTrainRunInterval => {
        return propOr(userTrainRunInterval, userTrainRunInterval.sourceKey, sourceKeyToActiveUserTrainRunInterval);
      },
      visibleActiveUserTrainRunIntervals
    );
  }, [crudUserTrainRunIntervals]);
};

/**
 * Extract the activeUserTrainRunIntervals without errors
 * @param loading
 * @param activeUserTrainRunIntervals
 * @returns {*}
 */
export const useMemoActiveUserTrainRunIntervalsWithoutErrors = ({ loading, activeUserTrainRunIntervals }) => {
  return useNotLoadingMemo(loading, () => {
    return filter(
      complement(strPathOr)(false, 'trainRunInterval.trainRun.error'),
      activeUserTrainRunIntervals
    );
  }, [activeUserTrainRunIntervals]);
};

/**
 * Creates TrainRunIntervals for each TrainRun if not set
 * @param {Boolean} loading. Does nothing is ture
 * @param trainRoutes
 * @param trainRouteInterval
 * @param trainRuns,
 * @param railwayLines,
 * @param setTrainRoutes
 * @param crudTrainRunIntervals
 */
export const useCreateCrudTrainRunIntervals = (
  {
    loading,
    trainRouteInterval,
    trainRuns,
    crudTrainRunIntervals
  }
) => {
  useNotLoadingEffect(loading, () => {
    // Create and store the TrainRunIntervals. We create one per TrainRun
    // Sort by departureDatetime, and if those are equal, by arrivalDatetime
    const allTrainRunIntervals = createTrainRunIntervals({
      trainRouteInterval,
      trainRuns
    });
    // Set crudTrainRunIntervals to TrainRunIntervals
    crudTrainRunIntervals.set(allTrainRunIntervals);
  }, [trainRuns]);
};
/**
 * Create a crud object for managing UserTrainRunIntervals
 * @param {[Object]} userTrainRunIntervals
 * @param {Function} setUserTrainRunIntervals Setter for trainUserRunIntervals
 * @param {Object} trainRoute Used for updating setUserTrainRunIntervalsForAllTrainRoutes
 * @param {Function} setUserTrainRunIntervalsForAllTrainRoutes updated userTrainRunIntervalsForAllTrainRoutes
 * when we do an updateOrCreate to the new values of this list
 * @param {Object} crudUserTrainRunIntervals The getter for the created CrudUserTrainRunInterval object to
 * retrieve it
 * @param {Function} setCrudUserTrainRunIntervals The setter for the created CrudUserTrainRunInterval object
 * to store it in state
 * @returns {Object} A Crud object for managing lists
 */
export const useEffectCreateCrudUserTrainRunIntervals = (
  {
    userTrainRunIntervals,
    setUserTrainRunIntervals,
    trainRoute,
    setUserTrainRunIntervalsForAllTrainRoutes,
    crudUserTrainRunIntervals,
    setCrudUserTrainRunIntervals
  }
) => {

  return useEffectCreateListCrud({
      equality: (incoming, existing) => {
        return eqProps('sourceKey', incoming, existing);
      },
      additionalOperations: {
        // Override updateOrCreate to have syncing side effects
        updateOrCreateWithSideEffects: (crud, userTrainRunInterval) => {
          const limitedUserTrainRunInterval = pickDeepPaths(
            ['sourceKey', 'trainRunInterval.distanceRange', 'trainRunInterval.unmaximizedDistanceRange'],
            userTrainRunInterval
          );
          // Update or create. Besides sourceKey as the id, the given prop paths are currently the only ones allowed to update
          return crud.updateOrCreate(
            limitedUserTrainRunInterval
          );
        }
      },
      list: userTrainRunIntervals,
      setList: setUserTrainRunIntervals,
      listCrud: crudUserTrainRunIntervals,
      setListCrud: setCrudUserTrainRunIntervals,
      postSetList,
      merge: mergeUserTrainRunInterval,
      additionalDependencies: [trainRoute, setUserTrainRunIntervalsForAllTrainRoutes]
    }
  );
};

/**
 * Sets the 'loading' flag on all userTrainRunIntervals in updatedTrainRunIdsRequestedWithImuPoints.
 * if there is something that needs to load based on trainRunDataToLoad.
 * If there is anything to load,
 * it calls crudUserTrainRunIntervals.updateOrCreateAll with the added loading flag and then
 * iterates through trainRunDataToLoad and calls trainApiTrainRunWithImuPoints on each, which calls the
 * API
 * @param loading
 * @param updatedTrainRunIdsRequestedWithImuPoints
 * @param trainRunDataToLoad
 * @param crudUserTrainRunIntervals
 * @param setTrainRunIdsRequestedWithImuPoints
 * @param setMinimumTrainRunsWithImuPoints
 */
export const useEffectMaybeUpdateUserTrainRunIntervalsStatusToLoading = (loading, {
  trainRouteOrGroup,
  updatedTrainRunIdsRequestedWithImuPoints,
  trainRunDataToLoad,
  crudUserTrainRunIntervals,
  setTrainRunIdsRequestedWithImuPoints,
  setMinimumTrainRunsWithImuPoints
}) => {

  useNotLoadingEffect(loading, () => {
    // Do not update until the TrainRoute and UserTrainRunIntervals are synced
    const trainRouteIds = map(prop('id'), trainRoutesOfTrainRouteOrGroup(trainRouteOrGroup));
    const mismatchingTrainRuns = filter(
      userTrainRunInterval => !includes(userTrainRunInterval.trainRunInterval.trainRun.trainRoute.id, trainRouteIds),
      crudUserTrainRunIntervals.list
    );
    if (length(mismatchingTrainRuns)) {
      return;
    }

    // Update trainRunIdsRequestedWithImuPoints to updatedTrainRunIdsRequestedWithImuPoints
    // Each trainApiTrainRunWithImuPoints can modify this further for their TrainRun if there is a request error
    if (updatedTrainRunIdsRequestedWithImuPoints) {
      setTrainRunIdsRequestedWithImuPoints(updatedTrainRunIdsRequestedWithImuPoints);
    }
    const trainRunIdsToLoad = map(prop('trainRunId'), trainRunDataToLoad);

    const maybeLoadingUserTrainRunIntervals = filter(
      strPathOr(false, 'trainRunInterval.trainRun.loading'),
      map(
        userTrainRunInterval => {
          return over(
            lensPath(['trainRunInterval', 'trainRun']),
            trainRun => {
              // Set the loading flag
              return when(
                trainRun => {
                  return includes(trainRun.id, trainRunIdsToLoad);
                },
                set(lensProp('loading'), true)
              )(trainRun);
            },
            userTrainRunInterval
          );
        },
        crudUserTrainRunIntervals.list
      )
    );
    if (length(maybeLoadingUserTrainRunIntervals)) {
      crudUserTrainRunIntervals.updateOrCreateAll(maybeLoadingUserTrainRunIntervals);
    }

    // Request data for each TrainRun in parallel
    forEach(({ trainRunId, distanceInterval, distanceRanges, trainRun, trainRoute }) => {
      trainApiTrainRunWithImuPoints({
          trainRunId,
          distanceInterval,
          distanceRanges,
          trainRun,
          trainRoute,
          trainRunIdsRequestedWithImuPoints: updatedTrainRunIdsRequestedWithImuPoints,
          setTrainRunIdsRequestedWithImuPoints,
          setMinimumTrainRunsWithImuPoints
        }
      );
    }, trainRunDataToLoad);
  }, [trainRouteOrGroup, crudUserTrainRunIntervals?.list, updatedTrainRunIdsRequestedWithImuPoints, trainRunDataToLoad]);
};