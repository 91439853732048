import { toArrayIfNot } from '@rescapes/ramda';
import {
  extractAndEvaluateMatchingFilters,
  updateFilterTypeInFilters
} from 'appUtils/trainAppUtils/trainFilterUtils.js';
import { namesOfFormationExtremeVehicles } from 'appUtils/trainAppUtils/formationUtils.js';
import { concat, equals, filter, find, indexBy, join, length, lensProp, map, omit, over, prop, propOr } from 'ramda';
import { evalFormation, isFormation } from 'appUtils/trainAppUtils/trainFilterTrainFormation.js';

/**
 * Returns the resolved filter id
 * @param trainRunFilterFormation
 * @param props
 * @returns {*}
 */
export const extractFormationId = (trainRunFilterFormation, props) => {
  return evalFormation(trainRunFilterFormation, props).equals[0];
};

/**
 * Extracts the Formations from the filter
 * @param t
 * @param trainRunFilter
 * @param props
 * @param props.formations Required to match formations by id
 */
export const extractFormations = (trainRunFilter, props) => {
  // Formation Filters are currently always stored under an the only 'any' function of the 'allPass' arguments
  // extractAndEvaluateMatchingFilters doesn't currently dig into the any objects to find them,
  // because we define a formationFilter to have an any. So this returns a single array of any
  // TODO in the future they should be storable anywhere
  const formationEvaluatedFilters = extractAndEvaluateMatchingFilters(
    { filterTypeTest: isFormation, filterTypeEval: evalFormation },
    trainRunFilter, props
  );
  return map(
    formationEvaluatedFilter => {
      const formationId = formationEvaluatedFilter.equals[0];
      const formation = find(
        formation => equals(formationId, formation.id),
        props.formations
      );
      if (!formation) {
        throw new Error(`Expected props.formations to have the formation with id ${formation.id}`);
      }
      return formation;
    },
    formationEvaluatedFilters
  );
};

/**
 * Given a filter that represents a Date range comparison, convert it to
 * a readable string
 * @param t
 * @param trainRunFilterFormation
 * @param props
 * @param props.formations Available formations. Required to resolve the formation label from the id
 * @returns {[String]} Returns a label for each date range comparison.
 * These can be combined as needed by the caller
 */
export const extractLabelsForFormations = (trainRunFilter, props) => {
  const formations = extractFormations(trainRunFilter, props);
  return map(
    formation => {
      // Extract the vehicles from the Formation and return the vehicle name
      // Separate the two vehicle names by a slash for now
      return join('/', namesOfFormationExtremeVehicles(formation));
    },
    formations
  );
};

/**
 * Like addFormationFilters but accepts formations that are converted to simple filters
 * @param trainRunFilter
 * @param {Object|[Object]} formations One or many formation instances
 * @param props
 * @returns {*}
 */
export const addFormationsToFilters = (trainRunFilter, formations, props) => {
  const formationFilters = map(formation => {
    return {
      __typename: 'FormationFilter',
      equals: [
        formation.id,
        { view: { lensPath: ['trainRun', 'formation', 'id'] } }
      ]
    };
  }, toArrayIfNot(formations));
  return addFormationFilters(trainRunFilter, formationFilters, props);
};

/**
 * Primitively adds a new formationFilters the top level of the trainRunFilter
 * @param trainRunFilter
 * @param {Object|[Object]} formationsFilters One FormationFilter or list of FormationFilters to add
 * @returns {*}
 */
export const addFormationFilters = (trainRunFilter, formationsFilters, props) => {
  const updateFunc = existingFormationsFilter => {
    return over(
      lensProp('any'),
      formationFilters => concat(formationFilters, toArrayIfNot(formationsFilters)),
      existingFormationsFilter
    );
  };
  return updateFilterTypeInFilters(trainRunFilter, isFormation, updateFunc, props);
};

/**
 * Removes the Formations specified by id from the trainRunFilter
 * @param trainRunFilter
 * @param {[Object]|Object} formations A single formation or array of formations to remove
 * @param props
 * @returns {*}
 */
export const removeFormationFilters = (trainRunFilter, formations, props) => {
  const updateFunc = existingFormationsFilter => {
    const formationIdsToRemove = indexBy(prop('id'), toArrayIfNot(formations));
    const removed = over(
      lensProp('any'),
      existingFilters => {
        return filter(
          existingFilter => {
            return !propOr(false, extractFormationId(existingFilter, props), formationIdsToRemove);
          },
          existingFilters
        );
      },
      existingFormationsFilter
    );
    // Clear the empty any if empty
    return !length(removed.any) ? omit(['any'], removed) : removed;
  };
  return updateFilterTypeInFilters(trainRunFilter, isFormation, updateFunc, props);
};

