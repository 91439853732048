import {
  extractAndEvaluateMatchingFilters,
  updateFilterTypeInFilters
} from 'appUtils/trainAppUtils/trainFilterUtils.js';
import {
  all,
  any,
  both,
  chain,
  compose,
  concat,
  cond,
  difference,
  eqProps,
  equals,
  filter,
  find,
  flatten,
  identity,
  includes,
  is,
  join,
  length,
  lensProp,
  map,
  mergeRight,
  omit,
  over,
  prop,
  propOr,
  range,
  sort,
  sortBy,
  T,
  when
} from 'ramda';
import { toArrayIfNot } from '@rescapes/ramda';


/**
 * Primitively checks if a filter has an includes or equals key
 * and if so if the second argument is a call to getDay|getDate
 * to determine if the filter is a dateRecurrence
 * Currently supports searching for days of MONDAY - SUNDAY, WEEKEND, WEEKDAY or 1st or every month
 * but not things like 'every 3rd Wednesday`
 * {
          includes: [
            {
              flatten: [MONDAY, TUESDAY, WEEKEND]
            },
          or equals: MONDAY
          or equals: 15
            {
              call: [
                'getDay'|'getDate',
                { view: { lensPath: ['trainRun', 'departureDatetime'] } }]
            }
          ]
        }
 */
export const isDateRecurrence = obj => {
  return equals('DateRecurrenceFilter', obj.__typename);
};

/**
 * Partially evaluates a date recurrence
 * Currently this just flattens unflat days like weekends
 * @param trainRunFilterDateRecurrence
 * @returns {*}
 */
export const evalDateRecurrence = (trainRunFilterDateRecurrence) => {
  const topLevelKey = find(prop => propOr(null, prop, trainRunFilterDateRecurrence), ['includes', 'equals']);
  return over(
    lensProp(topLevelKey),
    // We ignore the keys here: lt, lte, gt, gte since we can't evaluate fully
    ([flattenOrArrayOrValue, ...rest]) => {
      // Flatten unflat days or dates
      const daysOrDates = when(
        // Flatten the array if the flatten function is given
        both(is(Object), propOr(false, 'flatten')),
        obj => flatten(obj['flatten'])
      )(flattenOrArrayOrValue);
      return [daysOrDates, ...rest];
    },
    trainRunFilterDateRecurrence
  );
};

/**
 * Extract DateRecurrences from the filter
 * @param trainRunFilter
 * @param props
 * @returns {[Object]} A list of { type: dayOrDateOrTime, values: daysOrDatesOrTimes };
 */
export const extractDateRecurrences = (trainRunFilter, props) => {
  const trainRunFilterDateRecurrenceFilters = extractAndEvaluateMatchingFilters(
    { filterTypeTest: isDateRecurrence, filterTypeEval: evalDateRecurrence },
    trainRunFilter, props
  );
  return map(
    trainRunFilterDateRecurrenceFilter => {
      const topLevelKey = find(prop => propOr(null, prop, trainRunFilterDateRecurrenceFilters), ['includes', 'equals']);
      const [daysOrDatesOrTimes, callFunc] = trainRunFilterDateRecurrenceFilter[topLevelKey];
      // Get the first argument of the callFunc to see if it's a getDay or getDate or getTime
      const dayOrDateOrTime = callFunc['call'][0];
      return { type: dayOrDateOrTime, values: daysOrDatesOrTimes };
    },
    trainRunFilterDateRecurrenceFilters
  );
};

/**
 * Given a filter that represents a Date range comparison, convert it to
 * a readable string
 * @param t
 * @param trainRunFilter
 * @param props
 * @returns {[String]} Returns a label for each date range comparison.
 * These can be combined as needed by the caller
 */
export const extractLabelsForDateRecurrences = ({ t }, trainRunFilter, props) => {
  const dateRecurrenceDayDateOrTimeSets = extractDateRecurrences(trainRunFilter, props);
  return map(
    dateRecurrenceDayDateOrTimeSet => {
      return dateRecurrenceLabel({ ...dateRecurrenceDayDateOrTimeSet, t });
    },
    dateRecurrenceDayDateOrTimeSets
  );
};

/**
 * Gets the label for the DateRecurrence
 * @param {String} type 'getDate', 'getDay', or 'getTime'
 * @param {[*]} dayDateOrTimes
 * @param {Function} t The translation function
 * @returns {String}
 */
export const dateRecurrenceLabel = ({ type, values: dayDateOrTimes, t }) => {
  const dayOrDateLabel = cond([
    [equals('getDay'), () => {
      // get the key for the day Sunday...Saturday
      return compose(
        // Combine weekend and weekdays with remaining days
        ({ labels, dayDateOrTimes }) => {
          return concat(labels, map(day => t(`day${day}s`), dayDateOrTimes));
        },
        when(
          // Combine weekdays if all 5 present
          ({ dayDateOrTimes }) => all(day => includes(day, dayDateOrTimes), range(1, 6)),
          ({ labels, dayDateOrTimes }) => ({
            labels: concat(labels, [t('dayWeekdays')]),
            dayDateOrTimes: difference(dayDateOrTimes, range(1, 6))
          })
        ),
        when(
          // Combine weekend if both present
          ({ dayDateOrTimes }) => all(day => includes(day, dayDateOrTimes), [0, 6]),
          ({ dayDateOrTimes }) => ({
            labels: [t('dayWeekends')],
            dayDateOrTimes: difference(dayDateOrTimes, [0, 6])
          })
        )
      )({ dayDateOrTimes: flatten(dayDateOrTimes), labels: [] });
    }],
    [equals('getDate'), () => {
      // Get the ordinal for the month number (e.g. 1st, 3rd, 23rd)
      return map(date => cond([
          [date => equals(1, date % 10), date => `${date}${t('first')}`],
          [date => equals(2, date % 10), date => `${date}${t('second')}`],
          [date => equals(3, date % 10), date => `${date}${t('third')}`],
          // Add more ordinal overrides if other languages need them
          [T, date => `${date}${t('ordinalSuffixes')}`]
        ])(date),
        dayDateOrTimes);
    }],
    [equals('getTime'), () => {
      // get the key for the day Sunday...Saturday
      // TODO this shouldn't come in with a type and values
      // why is it different that getDay?
      return map(
        // Remove the timezone. TODO Hacky
        time => time.substr(0, 5),
        chain(obj => {
          return is(Object, obj) ? prop('values', obj) : toArrayIfNot(obj);
        }, dayDateOrTimes)
      );
    }]
  ])(type);

  // Use labels based on the type of getDayOrDateTime
  return join(' ',
    cond([
      [equals('getDate'), () => {
        return [t('everyDate'), join(', ', dayOrDateLabel), 'of the month'];
      }],
      [equals('getDay'), () => {
        return [t('onDays'), join(', ', dayOrDateLabel)];
      }],
      [equals('getTime'), () => {
        return [join(', ', dayOrDateLabel)];
      }]
    ])(type)
  );
};

/**
 * Primitively adds a new dateRecurrenceFilters the top level of the trainRunFilter
 * @param trainRunFilter
 * @param {Object|[Object]} dateRecurrencesFilters One DateRecurrenceFilter or list of DateRecurrenceFilter to add
 * @returns {*}
 */
export const addDateRecurrenceFilters = (trainRunFilter, dateRecurrencesFilters, props) => {
  const updateFunc = existingDateRecurrencesFilter => {
    return over(
      lensProp('any'),
      dateRecurrenceFilters => {
        return concat(
          dateRecurrenceFilters,
          map(
            mergeRight({ __typename: 'DateRecurrenceFilter' }),
            toArrayIfNot(dateRecurrencesFilters)
          )
        );
      },
      existingDateRecurrencesFilter
    );
  };
  return updateFilterTypeInFilters(trainRunFilter, isDateRecurrence, updateFunc, props);
};

/**
 * Removes the DateRecurrences specified by id from the trainRunFilter
 * @param trainRunFilter
 * @param {[Object]|Object} dateRecurrences A single dateRecurrence or array of dateRecurrences to remove
 * @param props
 * @returns {*}
 */
export const removeDateRecurrenceFilters = (trainRunFilter, dateRecurrences, props) => {
  const dateRecurrenceAsArray = toArrayIfNot(dateRecurrences);
  const updateFunc = existingDateRecurrencesFilter => {
    const removed = over(
      lensProp('any'),
      existingFilters => {
        return filter(
          existingFilter => {
            const topLevelKey = find(prop => propOr(null, prop, existingFilter), ['includes', 'equals']);
            return !any(
              dateRecurrence => {
                // If any of the dateRecurrences match the existingFilter on type and value remove the existingFilter
                return equals(dateRecurrence.type, existingFilter[topLevelKey][1].call[0]) &&
                  equals(
                    sortBy(identity, flatten(dateRecurrence.values)),
                    sortBy(identity, flatten(existingFilter[topLevelKey][0]))
                  );
              },
              dateRecurrenceAsArray
            );
          },
          existingFilters
        );
      },
      existingDateRecurrencesFilter
    );
    // Clear the empty any if empty
    return !length(removed.any) ? omit(['any'], removed) : removed;
  };
  return updateFilterTypeInFilters(trainRunFilter, isDateRecurrence, updateFunc, props);
};


/**
 * Creates a DateRecurrenceDateFilter for one or move dates of the month (0-31)
 * @param values
 * @returns {{__typename: string, includes: *[][]}}
 */
export const createDateRecurrenceDateFilter = values => {
  return {
    __typename: 'DateRecurrenceFilter',
    includes: [
      toArrayIfNot(values),
      {
        call: [
          'getDate',
          { view: { lensPath: ['trainRun', 'departureDatetime'] } }]
      }
    ]
  };
};

/**
 * Creates a DateRecurrenceDayFilter for a day or day grouping, like [0,6]
 * for weekends and [1,2,3,4,5] for the weekdays
 * @param {[[Number]|Number]} valuesSets Sets of numbers, e.g. [[0,6], 3]
 * for weekends and Weendesdays
 * @returns {{__typename: string, includes: *[][]}}
 */
export const createDateRecurrenceDayFilter = valuesSets => {
  return {
    __typename: 'DateRecurrenceFilter',
    includes: [
      map(toArrayIfNot, valuesSets),
      {
        call: [
          'getDay',
          { view: { lensPath: ['trainRun', 'departureDatetime'] } }]
      }
    ]
  };
};

/**
 * Creates a DateRecurrenceDateFilter for one or move time
 * Times are in the format HH:mmXXXX where XXXX is the timezone (e.g. 02:00)
 * @param values
 * @returns {{__typename: string, includes: *[][]}}
 */
export const createDateRecurrenceTimeFilter = values => {
  return {
    __typename: 'DateRecurrenceFilter',
    includes: [
      toArrayIfNot(values),
      {
        call: [
          'getTime',
          { view: { lensPath: ['trainRun', 'departureDatetime'] } }]
      }
    ]
  };
};

/**
 * Returns true if the give day of week or time or date recurrence is
 * in the list of dateRecurrences
 * @param dateRecurrenceOrDay
 * @param dateRecurrences
 * @returns {*}
 */
export const matchesDateRecurrence = (dateRecurrenceOrDay, dateRecurrences) => {
  const matchingDateRecurrencesByType = filter(
    eqProps('type', dateRecurrenceOrDay),
    dateRecurrences
  );
  const matchingDateRecurrencesValues = chain(
    matchingDateRecurrenceByType => {
      return sort(identity, flatten(matchingDateRecurrenceByType.values));
    },
    matchingDateRecurrencesByType
  );
  const values = sort(identity, toArrayIfNot(dateRecurrenceOrDay.values));

  return all(
    value => {
      return includes(value, matchingDateRecurrencesValues);
    },
    values
  );
};