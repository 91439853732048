import { Stack, Typography } from '@mui/material';
import {
  addDateRecurrenceFilters,
  createDateRecurrenceDateFilter,
  createDateRecurrenceDayFilter,
  createDateRecurrenceTimeFilter, extractDateRecurrences,
  extractLabelsForDateRecurrences,
  removeDateRecurrenceFilters
} from 'appUtils/trainAppUtils/trainFilterDateRecurrenceUtils.js';
import { always, cond, ifElse, join, length, propEq } from 'ramda';
import DateRecurrenceChooser
  from 'components/apps/trainAppComponents/dateRecurrenceChooserComponents/DateRecurrenceChooser.js';
import ChosenDateRecurrences from 'components/apps/trainAppComponents/trainFilterComponents/ChosenDateRecurrences.js';

import { useTranslation } from 'react-i18next';

/**
 * Creates A TrainRunFilterDateRecurrences component that shows the current DateRecurrences in the filter
 * and has an interface for adding and remove them
 * @param trainProps
 * @param setOpen
 * @param readonly
 * @returns {JSX.Element}
 * @constructor
 */
export const TrainRunFilterDateRecurrencesLabelComponent = (
  {
    trainProps,
    componentProps: { readonly = false }
  }) => {
  const { t } = useTranslation();
  // The only props the filter needs are the current TrainRuns to suggest times to search on
  const filterProps = { trainRuns: trainProps.trainRunProps.trainRuns };
  const trainRunFilterWithDateRecurrences = trainProps.dateProps.trainRunFilterWithDateRecurrences;
  const dateRecurrenceLabelsFromFilter = extractLabelsForDateRecurrences(
    { t },
    trainRunFilterWithDateRecurrences,
    filterProps
  );

  const dateRecurrencesLabel = ifElse(
    length,
    join(', '),
    always(t('allOccurrences'))
  )(dateRecurrenceLabelsFromFilter);

  /**
   * Add the given date recurrence to the trainRunFilterWithDateRecurrences
   * @param dateRecurrence
   */
  const handleAddDateRecurrenceToFilter = dateRecurrence => {
    const dateRecurrenceFilter = cond([
      [propEq('type', 'getDate'), ({ values }) => {
        return createDateRecurrenceDateFilter(values);
      }],
      [propEq('type', 'getDay'), ({ values }) => {
        return createDateRecurrenceDayFilter(values);
      }],
      [propEq('type', 'getTime'), ({ values }) => {
        return createDateRecurrenceTimeFilter(values);
      }]
    ])(dateRecurrence);

    const updatedTrainRunFilterWithDateRecurrences = addDateRecurrenceFilters(
      trainRunFilterWithDateRecurrences,
      dateRecurrenceFilter,
      filterProps
    );
    trainProps.dateProps.setTrainRunFilterWithDateRecurrences(updatedTrainRunFilterWithDateRecurrences);
  };

  /**
   * Remove the given dateRecurrence from the trainRunFilterWithDateRecurrences
   * @param dateRecurrence
   */
  const handleRemoveDateRecurrenceFromFilters = dateRecurrence => {
    const updatedTrainRunFilterWithDateRecurrences = removeDateRecurrenceFilters(trainRunFilterWithDateRecurrences, dateRecurrence, filterProps);
    trainProps.dateProps.setTrainRunFilterWithDateRecurrences(updatedTrainRunFilterWithDateRecurrences);
  };
  // Get the chosenDateRecurrences in the filter
  const chosenDateRecurrences = extractDateRecurrences(trainRunFilterWithDateRecurrences, filterProps);
  const allKnownTrainRunDepartureTimes = trainProps.trainRunProps.allKnownTrainRunDepartureTimes
  return <Stack {...{ spacing: 0.5, direction: 'row', sx: { alignItems: 'center' } }} >
    <DateRecurrenceChooser {...{ allKnownTrainRunDepartureTimes, chosenDateRecurrences, handleAddDateRecurrenceToFilter }} />
    {
      // Show Chips for DateRecurrences in the filter or show nothing
      !readonly && length(dateRecurrenceLabelsFromFilter) ?
        <ChosenDateRecurrences {...{ chosenDateRecurrences, filterProps, handleRemoveDateRecurrenceFromFilters }} /> :
        <Typography> {dateRecurrencesLabel} </Typography>
    }
  </Stack>;
};