import {Stack} from "@mui/material";
import RideComfortTypesOfErrorsComponent from "./RideComfortTypesOfErrorsComponent.tsx";
import React from "react";
import {MapProps} from "../../../../visualizer-railbed/types/propTypes/mapProps";
import {TrainPropsWithRideComfortProps} from "../../../../types/rideComfort/trainPropsWithRideComfortProps";
import {PeriodEnum} from "../../../../types/rideComfort/rideComfortAlertLevel.ts";

const RideComfortMain = ({trainProps}: {trainProps: TrainPropsWithRideComfortProps, mapProps: MapProps, maxWidth: number}) => {
    const {
        intervalDescription,
    } = trainProps.rideComfortProps

    return <Stack {...{
        direction: 'row',
        spacing: 1, sx: {flex: 2, width: '100%', height: '100%'}
    }}>
        <Stack {...{
            direction: 'column',
            spacing: 1,
            sx: {
                minWidth: 0, minHeight: 0, flex: 2, overflow: 'auto', position: 'relative',
                borderRadius: '8px',
                width: '100%',
                height: '100%',
                justifyContent: 'stretch'
            }
        }}>
            <Stack {...{
                spacing: 1,
                sx: {
                    minWidth: 0, minHeight: 0, flex: 2, overflow: 'auto', position: 'relative',
                }
            }}>
                <RideComfortTypesOfErrorsComponent {...{
                    rideComfortProps:trainProps.rideComfortProps,
                    scopedRideComfortGaugeByTimePeriod: PeriodEnum.today,
                    titleVariant: 'h5',
                    labelVariant: 'h7',
                    iconSize: 30,
                    label: `${intervalDescription.durationText} Before`,
                    showCount: true
                }}/>
            </Stack>
            {intervalDescription.label != 'Short' ? <Stack {...{
                direction: 'row',
                spacing: 1,
                sx: {
                    minWidth: 0, minHeight: 0, flex: 1, overflow: 'auto', position: 'relative',
                    justifyContent: 'space-between'
                }
            }}>
                <RideComfortTypesOfErrorsComponent {...{
                    rideComfortProps:trainProps.rideComfortProps,
                    scopedRideComfortGaugeByTimePeriod: PeriodEnum.week,
                    titleVariant: 'h7',
                    labelVariant: 'subtitle2',
                    iconSize: 20,
                    label: 'Week Before'
                }}/>
                <RideComfortTypesOfErrorsComponent {...{
                    rideComfortProps:trainProps.rideComfortProps,
                    scopedRideComfortGaugeByTimePeriod: PeriodEnum.month,
                    titleVariant: 'h7',
                    labelVariant: 'subtitle2',
                    iconSize: 20,
                    label: 'Month Before'
                }}/>
            </Stack> : null}
        </Stack>

    </Stack>
}
export default RideComfortMain
