export const getLambdaUrl = () => {
  let hostname;
  if (window?.location?.hostname) {
    const { location } = window;
    hostname = location.hostname;
  }

  if (hostname === 'localhost') {
    return `http://localhost:3000/dev`;
  } else {
    return process.env.REACT_APP_LAMBDA;
  }
};

export const isLocalEnvironment = () => APP_ENV === 'local'
export const ERROR_NO_IMU_POINTS = 'noImuPoints'

// DistanceRange start/end distances are round to .02 kilometers to prevent comparison errors
export const DISTANCE_RANGE_PRECISION = 2

// API statuses
export const STATUS_PROPS = ['error', 'errorDate', 'retry', 'status', 'loading'];