import { colors } from 'theme/colors.ts';
import { darken } from '@mui/material/styles/index.js';

export const TRAIN_API_DATE_ATTRIBUTES =
    ['start_date',
        'source:date',
        'departureDatetime',
        'arrivalDatetime',
        'medianDatetime',
        'datetimeIntervals',
        'serviceDatetimeRange',
        'timestamp'
    ];
// Default interpolations for the prop that is styled. This assumes normalized data.
export const DEFAULT_TRAIN_ROUTE_INTERPOLATIONS = [[0, colors.green], [0.5, colors.orange], [1, colors.red]];
// TODO move to organization style override object
export const DEFAULT_SERVICE_LINE_COLOR = '#767676';
export const DEFAULT_SERVICE_LINE_DARKENED = darken('#767676', .5);
export const TRAIN_RUN_POSITION_LAYER = 'trains-run-position-layer';
// TODO currently how in km far we transform track perpendicular to the main track to show data
export const MAP_TRANSFORM_TRACK_PERPENDICULAR_DISTANCE_INTERVAL = 0.01;
export const RIDE_COMFORT_TRG_SOURCE_PREFIX = 'ride_comfort_trg_source_prefix';
export const RIDE_COMFORT_TRG_LAYER_PREFIX = 'ride_comfort_trg_layer_prefix';

// We currently allow only 3 active TrainRunGroups, including the baseline TrainRunGroup
export const USER_TRAIN_RUN_INTERVAL_MAX_ACTIVE_COUNT = 3;

// These are the sources and layers used to visualize TrainRunGroups on the map. We add
// a TrainRunGroup id to make them unique
export const RIDE_COMFORT_TRACK_SOURCE_PREFIX = 'ride_comfort_track_source_prefix';
export const RIDE_COMFORT_TRACK_LAYER_PREFIX = 'ride_comfort_track_layer_prefix';

export const RIDE_COMFORT_SCHEDULED_STOP_POINT_SOURCE_PREFIX = 'ride_comfort_scheduled_stop_point_source_prefix';
export const RIDE_COMFORT_SCHEDULED_STOP_POINT_LAYER_PREFIX =  'ride_comfort_scheduled_stop_point_layer_prefix'

// Default to accept imuPoints within 15 minutes of a trainRun start and end time. This compensates for
// late trains.
export const TRAIN_RUN_IMUPOINT_MINUTE_BUFFER = 15;

/**
 * Resolves the correct API for the organization based on the given method if any
 * TODO organizations will not have specific treatment in the future
 */
export const organizationTrainUrlLookup = (
    {
        // @ts-expect-error too complicated for now
        cargonetResolveApiUrl,
        // @ts-expect-error too complicated for now
        flytogetResolveApiUrl
    }) => {
    return {
        Cargonet: cargonetResolveApiUrl,
        Flytoget: flytogetResolveApiUrl
    };
};

