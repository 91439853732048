import { equals, head, join, length, lensProp, map, prop, set } from 'ramda';
import { useEffect } from 'react';
import { correctPayload } from 'appUtils/trainAppUtils/userTrainRunIntervalUtil.js';
import { useTranslation } from 'react-i18next';
import { reqStrPathThrowing } from '@rescapes/ramda';

/**
 * Creates a Recharts CustomTooltip but React.portals it to component referenced by chartAndMapDataContainerRef
 * so that we show the tooltip in a component instead of as a popup that blocks the view of the graphs
 * The hook is called when payloadItems or userTrainRunIntervals changes. We could download more imuPoints
 * and change userTrainRunIntervals without changing the payload, so we must check userTrainRunIntervals
 * @param [{Object}] payload Is the data passed from recharts' Tooltip component
 * We iterate through the payload and show labels for each item. Normally
 * there is only zero or one object in the payload
 * @param {Boolean} delta If true the label t('delta') is shown instead
 * of the comparisonLabelPair, where t is the translator
 * @param {String} The dataKey of the chart that the user is hovering over. If this doesn't
 * match the incoming payload's dataKey, we ignore the payload
 * @returns null
 * @constructor
 */
const ImuPointsCustomTooltip = (
  {
    payload: payloadItems,
    trainRouteAggregateInterval,
    mostRecentTooltipPayload,
    setMostRecentTooltipPayload,
    activeChartDataKey,
    metersOfHoveredItem,
    userTrainRunIntervals
  }) => {
  const { t } = useTranslation();

  // Hash payloads by name and time. This is required because payloadItems keep updating on hover without moving
  const payloadHash = join(';', map(
    payloadItem => join(',', [prop('name', payloadItem), reqStrPathThrowing('payload.properties.time', payloadItem)]),
    payloadItems || []
  ))
  useEffect(() => {
    if (
      length(payloadItems) &&
      !equals(payloadItems, mostRecentTooltipPayload) &
      // Ignore if this payload is not from the active chart
      equals(head(payloadItems).dataKey, activeChartDataKey)
    ) {
      // Set the metersOfHoveredItem value on each payloadItem so we can later determine which payloadItem
      // is the active line on the graph
      const payloadItemsWithMetersOfHoveredItem = metersOfHoveredItem ?
        map(
          payloadItem => {
            return set(lensProp('metersOfHoveredItem'), metersOfHoveredItem, payloadItem);
          },
          payloadItems
        ) :
        payloadItems;

      const correctedPayloadItems = correctPayload(
        ({
          t,
          trainRouteAggregateInterval,
          userTrainRunIntervals,
          payloadItems: payloadItemsWithMetersOfHoveredItem
        })
      );
      setMostRecentTooltipPayload(correctedPayloadItems);
    }
  }, [payloadHash, userTrainRunIntervals]);

  return null;
};

export default ImuPointsCustomTooltip;
