import {RideComfortGraphqlResponseReportData,} from '../../types/rideComfort/rideComfortMapData';
import {compose, map, prop} from 'ramda';
import {VehicleCollectionDevice} from '../../types/sensors/vehicleCollectionDevice';
import {TrainFormationCollectionDevice} from '../../types/sensors/trainFormationCollectionDevice';
import {RideComfortScopeProps} from '../../types/rideComfort/rideComfortScopeProps';
import {Perhaps} from '../../types/typeHelpers/perhaps';
import {TrainRunGroupOnlyTrainFormation} from '../../types/trainRunGroups/trainRunGroupOnlyTrainFormation';
import {TrainGroup} from '../../types/trainRunGroups/trainGroup.d.ts';
import {getRideComfortReportGraphqlData} from './rideComfortReportGraphqlQueries.ts';
import {setRideComfortReportWithQueryData} from './rideComfortHooks.ts';
import {findOrThrow} from "../../visualizer-railbed/railbedUtils/functional/functionalUtils.ts";
import {formationCollectionDevices} from "../../appUtils/trainAppUtils/trainUtils.js";
import {flytogetConfig} from "../../config/rideComfortOrganizationConfigs/flytoget/flytogetConfig.ts";


/**
 * Queries the RideComfort API based on the current TrainRunGroup and DateInterval
 * for a report of alert counts for the last 60 days for alert, train, and track type alerts
 * @param rideComfortScopeProps
 * @param tempTrainRunGroupOnlyTrainFormation
 * @param setRideComfortDownloadData
 */
export const queryRideComfortReportAndMutate = async <T extends TrainGroup>(
  rideComfortScopeProps: RideComfortScopeProps<T>,
): Promise<RideComfortGraphqlResponseReportData[]> => {
  // Perform the query
  const dataSets: Perhaps<RideComfortGraphqlResponseReportData[]> =
    await getRideComfortReportGraphqlData(
      rideComfortScopeProps.dateInterval,
      rideComfortScopeProps.trainInfo
    );

  setRideComfortReportWithQueryData(
    rideComfortScopeProps,
    dataSets,
  );
};
