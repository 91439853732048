/**
 * Shows the start and end of the current Train route.
 * In the future it will be possible to click the arrow to reverse the route
 * and click the start and end stations to change them
 * @param {Object} trainProps
 * @param {Object} trainProps.trainRouteProps
 * @param {Object} trainProps.trainRouteProps.trainRoute
 * @param {Function} componentProps.reverseFormation Function to reverse the route when the arrow is clicked
 * @param {Function} componentProps.chooseFormation Function to bring up to choose the route
 * @returns {JSX.Element}
 */
import { DateRecurrenceDepartureTimesMenu } from 'components/apps/trainAppComponents/dateRecurrenceChooserComponents/DateRecurrenceDepartureTimesMenu.js';
import { Stack } from '@mui/material';
import { DateRecurrenceDaysMenu } from 'components/apps/trainAppComponents/dateRecurrenceChooserComponents/DateRecurrenceDaysMenu.js';

/***
 * Shows a menu that allows adding a Formation to the FormationFIlter
 * @param allKnownTrainRunDepartureTimes Used to build the times and days of weeks to choose from
 * @param chosenDateRecurrences The dateRecurrences already in the filter
 * @param addDateRecurrenceToFilter
 * @returns {JSX.Element}
 * @constructor
 */
const DateRecurrenceChooser = ({ allKnownTrainRunDepartureTimes, chosenDateRecurrences, handleAddDateRecurrenceToFilter }) => {
  return <Stack spacing={0.5} direction='row'>
    <DateRecurrenceDaysMenu {...{
      chosenDateRecurrences,
      handleAddDateRecurrenceToFilter
    }} />
    <DateRecurrenceDepartureTimesMenu {...{
      allKnownTrainRunDepartureTimes,
      chosenDateRecurrences,
      handleAddDateRecurrenceToFilter,
    }} />
  </Stack>;
};

export default DateRecurrenceChooser;