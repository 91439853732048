import { useNotLoadingEffect } from 'utils/hooks/useMemoHooks.js';
import {
  addTrainRunFilterWithTrainRoute,
  createTrainRunFilterWithTrainRoute,
  extractTrainRoutes
} from 'appUtils/trainAppUtils/trainFilterTrainRouteUtils.js';
import { either, head, not } from 'ramda';
import { idsEqual } from 'utils/functional/functionalUtils.js';
import { mergeTrainRunFilters } from 'appUtils/trainAppUtils/trainFilterUtils.js';

/**
 * Updates/Creates a trainRunFilterWithTrainRoute, which is like a
 * TrainRunFilter but can be used to set the interval of multiple TrainRuns at the same time
 * @param loading
 * @param parentTrainRunFilter
 * @param trainRouteGroups
 * @param trainRoutes
 * @param trainRoute
 * @param trainRunFilterWithTrainRoute
 * @param setTrainRunFilterWithTrainRoute
 */
export const useUpdateOrCreateTrainRunFilterWithTrainRoute = (
  {
    loading,
    parentTrainRunFilter,
    trainRouteGroups,
    trainRoutes,
    trainRoute,
    trainRunFilterWithTrainRoute,
    setTrainRunFilterWithTrainRoute
  }) => {
  useNotLoadingEffect(loading, () => {
    if (either(
      not,
      trainRunFilterWithTrainRoute => {
        const existingTrainRoute = head(extractTrainRoutes(
          trainRunFilterWithTrainRoute,
          { trainRouteGroups, trainRoutes }
        ));
        return !idsEqual(existingTrainRoute, trainRoute);
      }
    )(trainRunFilterWithTrainRoute)) {
      const existingTrainRoute = trainRunFilterWithTrainRoute && head(extractTrainRoutes(trainRunFilterWithTrainRoute, { trainRoutes, trainRouteGroups }));
      if (!trainRunFilterWithTrainRoute || !idsEqual(existingTrainRoute, trainRoute)) {
        // TODO, we'll want to store trainRunFilterWithTrainRoutes for each TrainRoute in the near future
        // so we don't lose the user's selected interval
        const trainRunFilterWithTrainRoute = createTrainRunFilterWithTrainRoute({ trainRoute });
        const updatedTrainRunFilter = addTrainRunFilterWithTrainRoute(parentTrainRunFilter, trainRunFilterWithTrainRoute, {});
        setTrainRunFilterWithTrainRoute(
          updatedTrainRunFilter
        );
      }
    }
  }, [trainRoute, trainRunFilterWithTrainRoute]);

  // Whenever the parentTrainRunFilter updates, update this TrainRunFilter
  useNotLoadingEffect(loading || !trainRunFilterWithTrainRoute, () => {
    const updatedTrainRunFilter = mergeTrainRunFilters({ childFilterTypeName: 'TrainRouteFilter' }, parentTrainRunFilter, trainRunFilterWithTrainRoute);
    setTrainRunFilterWithTrainRoute(
      updatedTrainRunFilter
    );
  }, [parentTrainRunFilter]);
};