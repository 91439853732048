/**
 * Uses the Apollo-style __typename property to identify instanc railbedTypes
 */
import { all, curry, map, omit, prop, propOr } from 'ramda';
import { Cemited } from '../../railbedTypes/cemited';
import { CemitTypename } from '../../railbedTypes/cemitTypename.ts';
import { memoized, memoizedWith } from '@rescapes/ramda';

export const typenameEquals = curry((typename: string, instance: Cemited): boolean => {
  // propEq didn't work here. I don't know why
  const eq = propOr(undefined, '__typename', instance) === typename;
  return eq;
});


/**
 * Create an object of the given type T with the corresponding typename
 * @param typename
 * @param t
 */
export const typeObject = <T extends Cemited>(
  typename: CemitTypename,
  t: T | Omit<T, '__typename'>
): T => {
  return {
    __typename: typename,
    ...omit<T, '__typename'>(['__typename'], t)
  } as T;
};


/**
 * Create an instance of the given type T with the corresponding typename
 * that is memoized to return the same reference when called multiple times
 * TODO this relies on memoizing deep t, which takes a long time. Instead
 * there should be an explicit memoize function that picks the attributes
 * of the instance that matter, such as id and values that can be updated
 * @param typename
 * @param t
 */
export const typeObjectMemoized = memoized(
  <T extends Cemited>(typename: CemitTypename, t: T): T => {
    return typeObject(typename, t);
  }
);


/**
 * Create instances memoized of the given type T with the corresponding typename
 * Returns the same instance references if they already have been created, but the
 * array itself is always new
 * TODO this relies on memoizing deep t, which takes a long time. Instead
 * there should be an explicit memoize function that picks the attributes
 * of the instance that matter, such as id and values that can be updated
 * @param typename
 * @param ts
 */
export const typeObjectsMemoized = memoizedWith(
  // Memoized with just the sourceKeys to save time when sourceKey exists
  <T extends Cemited>(typename: CemitTypename, ts: T[]): any => {
    const canUseSourceKey = all(propOr(undefined, 'sourceKey'), ts);
    return {
      typename,
      ts: map<T, string | T>(
        (t: T) => canUseSourceKey ? prop<string>('sourceKey', t) : t,
        ts
      )
    };
  },
  <T extends Cemited>(typename: CemitTypename, ts: T[]): T[] => {
    return map(
      t => typeObjectMemoized(typename, t),
      ts
    );
  });

export const typeObjects = <T extends Cemited>(
  typename: CemitTypename,
  ts: (T | Omit<T, '__typename'>)[]
): T[] => {
  return map((t: T) => typeObject<T>(typename, t), ts);
};
