import { userTrainRunIntervalGeojsons } from 'appUtils/trainAppUtils/userTrainRunIntervalGeojsonUtils.js';
import { addIndex, indexBy, length, lensProp, map, mergeRight, omit, over, prop, propOr, zipWith } from 'ramda';
import { useNotLoadingEffect } from 'utils/hooks/useMemoHooks.js';
import { workerizedUserTrainRunIntervalFeatureCollectionSensorPoints } from 'appUtils/trainAppUtils/userTrainRunIntervalDataUtilsWorkerized.js';


/**
 * Calculates geojson of the given UserTrainRunIntervals and then updates them in starage
 * @param loading
 * @param trainRoute
 * @param trainRouteAggregateInterval The userTrainRunIntervals are overridden with the distanceRange of
 * this when we calculate the geojson. This means that the distanceRange userTrainRunIntervals are not changed
 * but for calculations purposes we give them all the same distanceRange as set by the user on
 * trainRouteAggregateInterval. TODO When we create UserTrainRunIntervalGroups similar later, we will store
 * the aggregate distance range in UserTrainRunIntervalGroups and likewise not alter the UserTrainRunInterval
 * distanceRanges
 * @param userTrainRunIntervals
 * @param crudUserTrainRunIntervals
 * @param minimumTrainRunsWithImuPoints
 */
export const useUpdateUserTrainRunIntervalGeojsons = (
  {
    loading,
    trainRoute,
    trainRouteAggregateInterval,
    userTrainRunIntervals,
    crudUserTrainRunIntervals,
    minimumTrainRunsWithImuPoints
  }) => {
  return useNotLoadingEffect(loading, () => {
      const func = async () => {
        // Add a geojon index for workerizedUserTrainRunIntervalFeatureCollectionSensorPoints below
        const userTrainRunIntervalsWithGeojsonIndex = addIndex(map)((userTrainRunInterval, geojsonIndex) => {
          return mergeRight(userTrainRunInterval, { geojsonIndex });
        }, userTrainRunIntervals);

        const userTrainRunIntervalsThatNeededGeojsonUpdates = userTrainRunIntervalGeojsons(
          {
            trainRouteAggregateInterval,
            minimumTrainRunsWithImuPoints
          },
          userTrainRunIntervalsWithGeojsonIndex
        );
        if (!length(userTrainRunIntervalsThatNeededGeojsonUpdates)) {
          // Nothing has changed.
          return;
        }

        const userTrainRunIntervalsThatNeededGeojsonUpdatesBySourceKey = indexBy(prop('sourceKey'), userTrainRunIntervalsThatNeededGeojsonUpdates);

        // Merge the old and new
        const userTrainRunIntervalsMaybeUpdated = map(
          userTrainRunInterval => {
            return propOr(userTrainRunInterval, userTrainRunInterval.sourceKey, userTrainRunIntervalsThatNeededGeojsonUpdatesBySourceKey);
          },
          userTrainRunIntervalsWithGeojsonIndex
        );

        // Transform all userTrainRunIntervals. This is memoized to only run each if something has changed
        // and returns {trainRunIntervalSensorPointsTransformed, trackLineTransformed} for each
        const transformedGeojsonOfUserTrainRunIntervals = await workerizedUserTrainRunIntervalFeatureCollectionSensorPoints(
          userTrainRunIntervalsMaybeUpdated
        );

        // Merge the featureCollectionSensorPoints into the geojosn prop and remove geojsonIndex
        const userTrainRunIntervalsUpdated = zipWith(
          (userTrainRunInterval, transformedGeojsonOfUserTrainRunIntervals) => {
            return over(
              lensProp('geojson'),
              geojson => {
                return mergeRight(geojson, transformedGeojsonOfUserTrainRunIntervals);
              },
              omit(['geojosnIndex'], userTrainRunInterval)
            );
          },
          userTrainRunIntervalsMaybeUpdated,
          transformedGeojsonOfUserTrainRunIntervals
        );

        // Update only those UserTrainRunIntervals that had their geojson modified
        if (length(userTrainRunIntervalsUpdated)) {
          crudUserTrainRunIntervals.updateOrCreateAll(userTrainRunIntervalsUpdated);
        }
      };
      func();
    }, [trainRoute, userTrainRunIntervals, trainRouteAggregateInterval, minimumTrainRunsWithImuPoints]
  );
};
