export enum RideComfortAlertTypeKey {
  // Overall
  alertPointId = 'alertPointId',
  // Train specific
  trainPointId = 'trainPointId',
  // Track specific
  trackPointId = 'trackPointId',
}
export enum RideComfortAlertTypeLabels {
  alertPointId = 'Ride',
  // Train specific
  trainPointId = 'Train',
  // Track specific
  trackPointId = 'Track',
}
