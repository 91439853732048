import {format, parseISO} from 'date-fns';
import {trainDataFriendlyDatetimeFormatString} from 'appUtils/trainAppUtils/trainDataUtils.js';
import {compose, concat, is, join, prop, uniqBy, when} from 'ramda';
import {
  East,
  North,
  NorthEast,
  NorthWest,
  South,
  SouthEast,
  SouthWest,
  TimerOutlined,
  TrainOutlined,
  West
} from '@mui/icons-material';
import {Grid, Stack} from '@mui/material';
import rhumbBearing from '@turf/rhumb-bearing';
import {reqStrPathThrowing, strPathOr, toArrayIfNot} from '@rescapes/ramda';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {namesOfFormationExtremeVehicles} from 'appUtils/trainAppUtils/formationUtils.js';
import {
  angleToCardinalDirection,
  createPropertyPathGridItems
} from 'components/apps/trainAppComponents/trainRunningCharacteristicsComponents/imuPointDescriptionComponentUtils.js';
import CaptionTypography from 'components/atoms/typography/CaptionTypography.js';

const directionIconLookup = {
  N: North, S: South, E: East, W: West, NW: NorthWest, SW: SouthWest, SE: SouthEast, NE: NorthEast
};

/**
 * Returns a description of the imuPoint for use on a chart or graph
 * @param dataPathsConfigs All dataPathConfigs we wish to show data for, including the specific property
 * that the user is hovering over if on the graph. That property value is also represented by data.value
 * @param {Object} referenceStop ScheduledStopPoint The reference stop to label the distances from
 * @param relativePositionPropertyPath The property path to the relative position from the referenceStop
 * @param timePropertyPath
 * @param {[Object]} [propertyPathConfigs] Optional if a property value is to be shown
 * Format is [{propertyPath: 'properties.someProp', label: 'propertyLabel'}]
 * @param {Object} data Only needed if propertyPathConfigs are defined
 * @param {String} dataKey The dataPath of the values being hovered over
 * @param {Function} t translation function
 * @returns {JSX.Element}
 */
const ImuPointDescription = (
  {
    dataPathsConfigs,
    referenceStop,
    relativePositionPropertyPath,
    timePropertyPath,
    propertyPathConfigs = null,
    payloadItem,
    sx
  }) => {
  const { t } = useTranslation();
  const { payload: data, dataKey, userTrainRunInterval, color } = payloadItem;
  const formattedDateTime = compose(
    time => {
      return format(time, trainDataFriendlyDatetimeFormatString);
    },
    time => {
      return when(
        is(String),
        time => parseISO(time)
      )(time);
    },
    data => reqStrPathThrowing(timePropertyPath, data)
  )(data);
  const relativePosition = strPathOr('N/A', relativePositionPropertyPath, data);
  const formation = userTrainRunInterval.trainRunInterval.trainRun.trainFormation;

  // Combine the dataPathConfigs and propertyPathConfigs, which have the same format
  const propertyOrDataPathConfigs = uniqBy(prop('dataPath'), concat(dataPathsConfigs || [], propertyPathConfigs || []));
  const propertyPathGridItems = createPropertyPathGridItems({ propertyOrDataPathConfigs, dataKey, data });

  const direction = angleToCardinalDirection(rhumbBearing(referenceStop.geojson, data));
  const DirectionIcon = directionIconLookup[direction];

  return <Stack key='pointData' {...{
    sx: [{
      fontSize: '5px',
      color: '#fff',
      marginBottom: '5px',
      padding: '5px'
    }, ...toArrayIfNot(sx)]
  }}>
    {/* The train label */}
    <Grid container spacing={0.25}>
      <Grid item>
        <TrainOutlined {...{ variant: 'tiny', htmlColor: color }} />
      </Grid>
      <Grid item>
        <CaptionTypography>
          {join(',', namesOfFormationExtremeVehicles(formation))}
        </CaptionTypography>
      </Grid>
    </Grid>
    {/* The Formation Datetime of the Point */}
    <Grid container spacing={0.25}>
      <Grid item>
        <TimerOutlined  {...{ variant: 'tiny', htmlColor: color }} />
      </Grid>
      <Grid item>
        <CaptionTypography>
          {formattedDateTime}
        </CaptionTypography>
      </Grid>
    </Grid>
    {/* The position and direction from the reference stop */}
    <Grid container spacing={1}>
      <Grid item>
        <Stack direction='row' spacing={0}>
          <DirectionIcon variant='tiny' />
          <CaptionTypography sx={{ fontSize: '2em' }}> {t(direction.toLowerCase())} </CaptionTypography>
        </Stack>
      </Grid>
      <Grid item>
        <CaptionTypography>
          {join(' ', [(relativePosition / 1000).toFixed(1), 'km', t('from'), referenceStop.shortName])}
        </CaptionTypography>
      </Grid>
      {/*{isLocalEnvironment() && <Grid item>*/}
      {/*  <CaptionTypography>*/}
      {/*    {join(' ', [*/}
      {/*      'ImuPoint Count:',*/}
      {/*      compose(length, imuPointsOfTrainRun, trainRunOfUserTrainRunInterval)(payloadItem.userTrainRunInterval),*/}
      {/*      'Meters',*/}
      {/*      payloadItem.payload.properties.meters*/}
      {/*    ])}*/}
      {/*  </CaptionTypography>*/}
      {/*</Grid>}*/}
    </Grid>
    {/* The configured property paths */}
    {propertyPathGridItems}
  </Stack>;
};
export default ImuPointDescription;

