import { Stack } from '@mui/material';
import { compose, lensPath, map, set } from 'ramda';
import PropTypes from 'prop-types';
import UserTrainRunLineContainer
  from 'components/apps/trainAppComponents/trainRunChooserComponents/UserTrainRunLineContainer.js';

/**
 *
 * @param appProps
 * @param organizationProps
 * @param trainProps
 @param {Boolean } [isSelectedTrainRuns] Default false, if true presents the TrainTunIntervals that are currently
 * active, meaning {activity: {isActive: true}}
 * @param {Boolean} [isBaseline] Default false, true if this is the baseline run
 * @param toggleUserTrainRunIntervalVisibility
 * @returns {JSX.Element}
 * @constructor
 */
const UserTrainRunIntervals = (
  {
    appProps,
    organizationProps,
    trainProps,
    componentProps: {
      isSelectedTrainRuns,
      toggleUserTrainRunIntervalVisibility
    }
  }) => {
  return <Stack
    spacing={.5}
    key='userTrainRunIntervals'
    sx={{
      height: '100%',
      overflowY:  'auto'
    }}>
    {map(
      userTrainRunInterval => {
        return <UserTrainRunLineContainer key={userTrainRunInterval.sourceKey} {...{
          appProps,
          organizationProps,
          trainProps: compose(
            // Scope the component to the userTrainRunInterval and the latter's trainRunInterval and trainRun
            set(lensPath(['trainRunProps', 'trainRun']), userTrainRunInterval.trainRunInterval.trainRun),
            set(lensPath(['trainRunIntervalProps', 'trainRunInterval']), userTrainRunInterval.trainRunInterval),
            set(lensPath(['userTrainRunIntervalProps', 'userTrainRunInterval']), userTrainRunInterval)
          )(trainProps),
          componentProps: {
            isSelectedTrainRuns,
            toggleUserTrainRunIntervalVisibility
          }
        }}
        />;
      },
      isSelectedTrainRuns ?
        // Selected list including those with download errors
        trainProps.userTrainRunIntervalProps.activeUserTrainRunIntervals || [] :
        // All
        trainProps.userTrainRunIntervalProps.crudUserTrainRunIntervals.list
    )}
  </Stack>;
};
UserTrainRunIntervals.propTypes = {
  appProps: PropTypes.object.isRequired,
  organizationProps: PropTypes.object.isRequired,
  trainProps: PropTypes.object.isRequired,
  componentProps: PropTypes.shape({
    // Limit the single baseline userTrainRunInterval
    isSelectedTrainRuns: PropTypes.bool.isRequired,
    toggleUserTrainRunIntervalVisibility: PropTypes.func.isRequired
  }).isRequired
};
export default UserTrainRunIntervals;