import { getEmptyImage } from 'react-dnd-html5-backend';
import { useEffect, useMemo } from 'react';
import { TrainRunIntervalBar } from 'components/apps/trainAppComponents/trainRunChooserComponents/TrainRunIntervalBar.js';
import { toArrayIfNot } from '@rescapes/ramda';
import { useExpanderDrag, useMoverDrag } from 'utils/hooks/dragAndDropHooks.js';
import { distanceRangesEqualForProp, useTrainRunIntervalMaximizer } from 'async/trainAppAsync/hooks/typeHooks/trainRunIntervalHooks.js';
import { all } from 'ramda';
import { ItemTypes } from 'appConfigs/trainConfigs/trainDragAndDropConfig.js';

import PropTypes from 'prop-types';

/**
 * The bar drawn over a TrainRunLine that represents a distance interval selected for comparison
 * @param {String} sourceKey A unique sourceKey for the component based on trainRun and trainRunInterval.
 * Used by useDrag to pass to the drop target
 * @param {Object} trainRun The TrainRun that this bar is on.
 * @param {Boolean} isTrainRouteLine. If true indicates that the trainRun is a template used at the top of the
 * page that shows the stations but not a specific departure time.
 * @param {Object} trainRunInterval A TrainRunInterval represents a chose distance interval of the train.
 * @param {Object} [userTrainRunInterval]
 * @param {Object} [userTrainRunInterval.trainRunInterval] Used instead of trainRunInterval when defined
 * @param {Object} sx
 * @param {Function} resolveOffsetLeft Unary function that, given an distance in kilometers along the route
 * returns the position on the TrainRunLine that pixels of the length of the TrainRunLine between
 * the start and end station
 * @param {Function} crudTrainRunIntervals CRUD functions for trainRunIntervals
 * @param {Object} routeDistancesWithOffsetLefts
 * @returns {JSX.Element}
 */
const DraggableTrainRunIntervalBar = (
  {
    trainProps,
    componentProps: {
      trainRoute,
      sourceKey,
      resolveOffsetLeft,
      spaceGeospatially,
      limitedDistanceRange,
      isAggregate,
      isTrainRouteLine,
      parentWidth,
      routeDistancesWithOffsetLefts
    },
    sx = []
  }) => {

  const trainRun = trainProps.trainRunIntervalProps.trainRun;
  const trainRunInterval = trainProps.trainRunIntervalProps.trainRunInterval;
  const crudTrainRunIntervals = trainProps.trainRunIntervalProps.crudTrainRunIntervals;
  const userTrainRunInterval = trainProps.userTrainRunIntervalProps.userTrainRunInterval;
  const crudUserTrainRunIntervals = trainProps.userTrainRunIntervalProps.crudUserTrainRunIntervals;

  const maximizeOrRestoreTrainRunIntervalBar = useTrainRunIntervalMaximizer(
    {
      trainRouteProps: trainProps.trainRouteProps,
      trainRoute,
      crudTrainRunIntervals,
      crudUserTrainRunIntervals,
      trainRunInterval,
      userTrainRunInterval,
      parentWidth,
      isTrainRouteLine,
      spaceGeospatially,
      routeDistancesWithOffsetLefts,
      isAggregate
    }
  );

  // Memoize so that our effects don't rerun unless something in item actually changes
  // uwc-debug
  const item = useMemo(() => {
      return {
        sourceKey,
        trainRun,
        trainRunInterval,
        userTrainRunInterval,
        spaceGeospatially,
        limitedDistanceRange,
        isAggregate,
        parentWidth
      };
    },
    [sourceKey,
      trainRun,
      trainRunInterval,
      userTrainRunInterval,
      spaceGeospatially,
      limitedDistanceRange,
      parentWidth]
  );
  // A useDrag hook that tracks expanding of a TrainRunIntervalBar
  const [{ isDragging: expanderLeftIsDragging }, expanderLeftDrag, expanderLeftPreview] = useExpanderDrag({
    side: 'left',
    // item is passed CustomDragLayer so that it can draw the same shape as this component
    item,
    type: ItemTypes.TRAIN_RUN_INTERVAL_BAR_LEFT_EXPANDER
  });

  const [{ isDragging: expanderRightIsDragging }, expanderRightDrag, expanderRightPreview] = useExpanderDrag({
    side: 'right',
    // time is passed to the CustomDragLayer so that it can draw the same shape as this component
    item,
    type: ItemTypes.TRAIN_RUN_INTERVAL_BAR_RIGHT_EXPANDER
  });

  // A useDrag hook that oldTrackConfig moving of a TrainRunIntervalBar
  const [{ isDragging: moverIsDragging }, moverDrag, moverPreview] = useMoverDrag({
    // item passed to the CustomDragLayer so that it can draw the same shape as this component
    item,
    type: ItemTypes.TRAIN_RUN_INTERVAL_BAR_MOVER
  });

  useEffect(() => {
    // Hide the moverDrag preview since we are using CustomDragLayer
    moverPreview(getEmptyImage(), { captureDraggingState: true });
    expanderRightPreview(getEmptyImage(), { captureDraggingState: true });
    expanderLeftPreview(getEmptyImage(), { captureDraggingState: true });
  }, []);

  const percentOrPixel = prop => `${prop}${spaceGeospatially ? '%' : 'px'}`;
  const left = resolveOffsetLeft(trainRunInterval.distanceRange.start);
  const width = resolveOffsetLeft(trainRunInterval.distanceRange.end) - left;

  return <TrainRunIntervalBar
    key='trainRunIntervalBar'
    trainRunInterval={trainRunInterval}
    sx={[...toArrayIfNot(sx), { left: percentOrPixel(left), width: percentOrPixel(width) }]}
    moverDrag={moverDrag}
    moverIsDragging={moverIsDragging}
    expanderLeftDrag={expanderLeftDrag}
    expanderLeftIsDragging={expanderLeftIsDragging}
    expanderRightDrag={expanderRightDrag}
    expanderRightIsDragging={expanderRightIsDragging}
    maximizeOrRestoreTrainRunIntervalBar={maximizeOrRestoreTrainRunIntervalBar}
    isMaximized={
      all(key => {
        return !distanceRangesEqualForProp(key, trainRunInterval.distanceRange, trainRunInterval.unmaximizedDistanceRange || {});
      }, ['start', 'end'])}
  />;
};
DraggableTrainRunIntervalBar.propTypes = {
  trainProps: PropTypes.object.isRequired,
  componentProps: PropTypes.shape({
    sourceKey: PropTypes.string.isRequired,
    resolveOffsetLeft: PropTypes.func.isRequired,
    spaceGeospatially: PropTypes.bool.isRequired
  }).isRequired,
  sx: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)])
};
export default DraggableTrainRunIntervalBar;

