import { useNotLoadingEffect } from 'utils/hooks/useMemoHooks.js';
import { indexBy, map, mergeRight, prop } from 'ramda';
import { useResolveTrainApiData } from 'async/trainAppAsync/hooks/trainApiHooks/trainApiHooks.js';

/**
 * Resolves the TrainRoutes from the ServiceLines
 * @param {Boolean} loading
 * @param {Object} organization The client
 * @param {[Object]} serviceLines The ServiceLines to get the TrainRoutes of
 * @param {Function} setTrainRoutes Setter
 */
export const useTrainApiSetTrainRoutes = (
  {
    loading,
    organization,
    serviceLines,
    setTrainRoutes
  }) => {
  const { data: trainRoutes } = useResolveTrainApiData(
    {
      loading,
      organization,
      serviceLines,
      method: 'trainRoutes'
    }
  );
  useNotLoadingEffect(loading,
    () => {
      const serviceLineLookup = indexBy(prop('id'), serviceLines);
      if (trainRoutes) {
        setTrainRoutes(
          // Add a __typename so we can distinguish TrainRoute from TrainRouteGroup
          map(
            trainRoute => {
              return mergeRight({
                __typename: 'TrainRoute',
                // Resolve the serviceLine from serviceLineId
                serviceLine: serviceLineLookup[trainRoute.serviceLineId]
              }, trainRoute);
            },
            trainRoutes
          )
        );
      }
    },
    [trainRoutes]
  );
};