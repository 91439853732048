import _styled from '@emotion/styled';
import { defaultNode } from 'utils/functional/functionalUtils.js';

const styled = defaultNode(_styled);

export const ButtonWrapper = styled.div`
  margin-top: 20px;
  display: grid;
`;

export const B = styled.button`
  width: 245px;
  height: 50px;
  background: #f6c90e;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
  border-radius: 79px;
  font-weight: bold;
  font-size: 22px;
`;
export const Button = styled(B)`
  font-size: 16px;
  padding: 12px 25px;

  &:disabled {
    background: #fafafa;
    opacity: 0.8;
  }
`;

export const Profile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 80vh;
  justify-content: center;

  h1 {
    text-shadow: 2px 1px rgba(0, 0, 0, 0.15);
    padding: 20px 10px;
    color: #fff;
    font-weight: bold;
    font-size: 18px;
  }
`;
