import { namesOfFormationExtremeVehicles } from 'appUtils/trainAppUtils/formationUtils.js';
import { Chip, Stack } from '@mui/material';
import { join, length, map } from 'ramda';

/**
 * Creates Chips for the Chosen FormationFilters with the ability to remove them from the filter
 * @param chosenFormations The chosen chosenFormations
 * @parm handleRemoveFormationFromFilters
 * @returns {JSX.Element}
 * @constructor
 */
export const ChosenFormations = ({ chosenFormations, handleRemoveFormationFromFilters }) => {
  const formationChips = map(
    formation => {
      const formationLabel = join('/', namesOfFormationExtremeVehicles(formation));
      return <Chip {...{
        color: 'primary',
        key: formation.id,
        label: formationLabel, variant: 'outlined', onDelete: () => {
          handleRemoveFormationFromFilters(formation)
        }
      }} />;
    },
    chosenFormations
  );
  return length(formationChips) ? <Stack direction='column' spacing={1}>
    {formationChips}
  </Stack>: null;
};
export default ChosenFormations;