import {
  any,
  compose,
  concat,
  cond,
  equals,
  filter,
  findIndex,
  identity,
  is,
  length,
  lensProp,
  omit,
  over,
  prop,
  propOr,
  T
} from 'ramda';
import {
  applyDeepWithKeyWithRecurseArraysAndMapObjs,
  compactEmpty,
  flattenObjUntil,
  mapObjToValues
} from '@rescapes/ramda';


/**
 * Given a filterTypeTest and filterTypeEval function, returns a flat list
 * of the matching filters that have been evaled with filterTypeEval from trainRunFilter
 * @param filterTypeTest
 * @param filterTypeEval
 * @param trainRunFilter
 * @param props
 * @returns {any}
 */
export const extractAndEvaluateMatchingFilters = ({ filterTypeTest, filterTypeEval }, trainRunFilter, props) => {
  return compose(
    // Take the __pickMe values
    mapObjToValues(prop('__pickMe')),
    // Remove values without the __pickMe prop
    filter(obj => is(Object, obj) && obj.__pickMe),
    obj => {
      // Flatten to the point that __pickMe is a key
      return flattenObjUntil(
        obj => propOr(false, '__pickMe', obj),
        obj
      );
    },
    obj => {
      return applyDeepWithKeyWithRecurseArraysAndMapObjs(
        (l, r) => r,
        (key, obj) => {
          return cond([
            [
              obj => {
                return filterTypeTest(obj, props);
              },
              obj => {
                // Replace the view with the corresponding props value if not a trainRun view
                return { __pickMe: filterTypeEval(obj, props) };
              }
            ],
            // Otherwise return obj as is
            [T, identity]
          ])(obj);
        },
        obj
      );
    }
  )(trainRunFilter);
};
/**
 * Adds or removes a filter type
 * @param trainRunFilter
 * @param {Function} isTypeFunc Checks for the desired type
 * @param updateFunc
 * @param props
 * @returns {*}
 */
export const updateFilterTypeInFilters = (trainRunFilter, isTypeFunc, updateFunc, props) => {
  return over(
    lensProp('allPass'),
    allPassObjs => {
      // Try to find an any that already has the filter type so we can add/remove to/from there
      const existingFilterIndex = findIndex(
        allPassObj => {
          return any(
            obj => isTypeFunc(obj, props),
            allPassObj.any || []
          );
        },
        allPassObjs
      );

      // If the updateFunc clears a filter, compactEmpty will remove the empty filter object
      return compactEmpty(
        over(
          // If an existing filter exists of th type, add the new filter to the any property
          // Otherwise concat a {any:[]} to allPassObjs and add it to that any
          lensProp(existingFilterIndex >= 0 ? existingFilterIndex : length(allPassObjs)),
          existingFilter => {
            return updateFunc(existingFilter);
          },
          existingFilterIndex >= 0 ? allPassObjs : concat(allPassObjs, [{ any: [] }])
        )
      );
    },
    trainRunFilter
  );
};

/**
 * Can be used to clear any filter that is in an any array in the allPass array
 * @param isFilterType, e.g. isDateRange to remove DateRange filters
 * @param trainRunFilter
 * @returns {*}
 */
export const clearFilterAny = (isFilterType, trainRunFilter) => {
  const updateFunc = existingDateRangesFilter => {
    // Remove the any for this filter so no filters exist
    return omit(['any'], existingDateRangesFilter);
  };
  return updateFilterTypeInFilters(trainRunFilter, isFilterType, updateFunc, {});
};

/**
 * Merges parentTrainRunFilter and childTrainRunFilter when the parent changes
 * For now this just merges at the top and second level. TODO it should do a deep merge
 * @param childFilterTypeName
 * @param parentTrainRunFilter
 * @param childTrainRunFilter
 * @returns {*}
 */
export const mergeTrainRunFilters = ({ childFilterTypeName }, parentTrainRunFilter, childTrainRunFilter) => {
  return over(
    lensProp('allPass'),
    allPass => {
      return concat(
        allPass,
        filter(
          obj => {
            return equals(childFilterTypeName, obj.__typename) ||
              any(subObj => equals(childFilterTypeName, subObj.__typename), obj.any || []);
          },
          childTrainRunFilter.allPass || []
        )
      );
    },
    parentTrainRunFilter
  );
};