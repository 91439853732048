import 'react-input-range/lib/css/index.css';
import { compose, length, lensIndex, min, pathOr, view } from 'ramda';
import { toArrayIfNot } from '@rescapes/ramda';

/**
 * Get the float property of the feature of features at the given index
 * @param {Object} props
 * @param {Number} [props.precision] Default 1
 * @param {Object} props.prop The property in features[*].properties[props]
 * @param {[Object]} features The features
 * @param {Number} index The index of the item in features
 * @returns {Object} Object with a min and max property
 */
export const getFeaturePropertyAtIndex = ({ precision = 1, prop, features, index }) => {
  return compose(
    float => float.toFixed(precision),
    parseFloat,
    feature => pathOr(0, ['properties', prop], feature),
    features => view(
      // If the index is greater than the feature length, set it to the the last feature
      lensIndex(min(length(features) - 1, index)),
      features
    )
  )(features);
};

/***
 * Puts the given geojson feature or features in a FeatureCollection
 * @param {Object|[Object]} geojson
 * @return {{features: *[], type: string}}
 */
export const asFeatureCollection = geojson => {
  return {
    type: 'FeatureCollection',
    // Set a 1-based id on on each feature
    features: toArrayIfNot(geojson)
  };
}