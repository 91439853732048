import { Box, Stack, Tooltip, tooltipClasses, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { compactEmpty, toArrayIfNot } from '@rescapes/ramda';
import { always, identity, isNil, join, length, map, when } from 'ramda';
import { stringify } from 'railbedUtils/json/jsonUtils.ts';
import { AppSettings } from '../../railbedConfig/appConfigs/appConfig.ts';
import React from 'react';
import { SxProps } from '@mui/system';
import { LoadingExplanation } from '../../railbedTypes/async/loadingExplanation';

/**
 * Optionally shows a loading indicator of an optional size
 * @param size Width and Height in pixels
 * @param show Wheter to show the loader or not
 */
export const Loader = ({ size, show }: { size?: number, show: boolean }) => {
  return <Box {...{
    sx: {
      display: show ? `block` : `none`,
      border: '5px solid #fff',
      borderRadius: '50%',
      borderTop: '5px solid #f6c90e',
      width: size ? `${size}px` : '20px',
      height: size ? `${size}px` : '20px',
      padding: 0,
      alignSelf: 'center',
      animation: 'spin 2s linear infinite',

      '@keyframes spin': {
        '0%': {
          transform: 'rotate(0deg)'
        },
        '100%': {
          transform: 'rotate(360deg)'
        }
      }
    }
  }} />;
};

/**
 *
 * @param Optional sx
 * @param text Set to empty string to show nothing. Set null to show t('loading'). Set to
 * any label to send to t of { t } = useTranslation();
 * @param [spinner] Default false, show a spinnser
 * @param loadingExplanation Default null For local env only. Shows a dump of the given object
 * to explain what is loading
 * @return {JSX.Element}
 * @constructor
 */
const LoaderWithText = (
  { text, noTranslation=false,  sx = [], spinner = false, loadingExplanation = undefined }:
    { text?: string, noTranslation?: boolean, sx?: SxProps, spinner?: boolean, loadingExplanation?: LoadingExplanation }
) => {
  const { t } = noTranslation ? { t: identity} : useTranslation();
  const texts = join(
    ', ',
    map(
      (str: string) => {
        return t(str);
      },
      compactEmpty(toArrayIfNot(when(isNil, always('loading'))(text)))
    )
  );
  const explanation = stringify(loadingExplanation, 2, undefined, 2);
  const title = (AppSettings.APP_ENV === 'local' && loadingExplanation) ?
    <pre> {explanation} </pre> :
    t('loading');

  return <Tooltip {...{
    sx: {
      // TODO axWidth does not work
      [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 'none'
      }
    }, arrow: true, title
  }} >
    <Stack spacing={1} sx={[{}, ...toArrayIfNot(sx)]} direction='row'>
      {length(text as string) ? <Typography key='label' color='secondary' variant='caption'>{texts}</Typography> : null}
      {spinner && <Loader key='loader' show />}
    </Stack>
  </Tooltip>;
};
export default LoaderWithText;
