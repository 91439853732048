import {
  all, complement,
  compose,
  curry,
  eqProps,
  equals,
  filter,
  find,
  findIndex,
  fromPairs,
  groupBy, has,
  head,
  identity,
  includes,
  is,
  keys,
  last,
  length,
  lt,
  lte,
  map,
  mergeRight,
  prop,
  slice,
  when,
  zip,
  zipWith
} from 'ramda';
import { eqStrPath, filterWithKeys, overDeep, reqStrPathThrowing, transformKeys } from '@rescapes/ramda';
import { Interval } from 'date-fns';
import { Identified } from '../../railbedTypes/identified';
import { Perhaps } from '../../railbedTypes/typeHelpers/perhaps';
import * as R from 'ramda';

/**
 * @file functional utilities extracted from Andy's rescape-ramda library
 */

/**
 * Assuming a list that is ordered by the give date prop, limit
 * the list to items with dates within the interval inclusive
 * @param interval Standard ISO date interval
 * @param interval.start start Date
 * @param interval.end end Date
 * @param datePath The string path to the date in orderedList, e.g. 'departureTime', or 'foo.departureTime'
 * @paramorderedList The ordered list
 * @returns {[Object]} The orderedList sliced to match the interval. Can be empty
 */
export const limitByDatePropToDateInterval = (
  { interval, dateProp }: { interval: Interval, dateProp: string }, orderedList: any[]) => {
  // Find the first item greater than/equal to the interval start
  const firstIndex = findIndex(item => {
    // @ts-expect-error No type support for Date
    return lte(interval.start, reqStrPathThrowing(dateProp, item));
  }, orderedList);
  // If nothing matches, return empty
  if (equals(-1, firstIndex)) {
    return [];
  }
  // Find the first item greater than interval end
  const outsideIndex = firstIndex +
    // If there is nothing greater, use Infinity to slice to the end
    when(
      equals(-1),
      () => Infinity
    )(
      findIndex(item => {
        // @ts-expect-error No type support for Date
        return lt(interval.end, reqStrPathThrowing(dateProp, item));
      }, slice(firstIndex, Infinity, orderedList))
    );
  // Return the slice, where outsideIndex is excluded. If both are -1, than [] is returned
  return slice(firstIndex, outsideIndex, orderedList);
};

export const extremes = <T>(list: T[]): [T, T] => {
  return [head<T>(list) as T, last<T>(list) as T];
};

/**
 * Compares persisted instances by id
 * @param obj1 first instance to compare
 * @param obj2 instance to compare with obj1
 * @returns {Boolean} True if equal
 */
export const idsEqual = curry((obj1: Perhaps<Pick<Identified, 'id'>>, obj2: Perhaps<Pick<Identified, 'id'>>): boolean => {
  return Boolean(obj1 && obj2) && eqProps('id', obj1, obj2);
});
/***
 * Tests the length of the lists and id prop of each item for equality
 * The lists items must have objects with ids in the same order
 * @param list1 first list of objets to compare or undefined
 * @param list2 second list of objets to compare of undefined
 * @returns True if equal
 */
export const idListsEqual = <T>(list1: T[] | undefined, list2: T[] | undefined): boolean => {
  if (!list1 || !list2) {
    return false;
  }
  return equals<T>(
    ...map<T[], number>(
      length<T[]>,
      [list1, list2]
    ) as [T, T]
  ) && all<boolean>(
    identity<boolean>,
    zipWith<T, T, boolean>(
      (item1: T, item2: T) => eqProps<T, T>('id', item1, item2),
      list1,
      list2)
  );
};

/***
 * Tests the length of the lists and strPath to each item for equality
 * The lists items must have objects with ids in the same order
 * @param strPath Dot-separate string path of props in the object, e.g. 'foo.bar.id'
 * @param list1 first list of objets to compare
 * @param list2 second list of objets to compare
 * @returns {Boolean} True if equal
 */
export const strPathListsEqual = <T>(strPath: string, list1: T[], list2: T[]): boolean => {
  return equals<T>(...map<T[], number>(
    length<T[]>,
    [list1, list2]
  ) as [T, T]) && all<boolean>(
    identity<boolean>,
    zipWith<T, T, boolean>(eqStrPath(strPath), list1, list1)
  );
};


/**
 * Compares instances by sourceKey
 * @param obj1 first instance to compare
 * @param obj2 instance to compare with obj1
 * @returns {Boolean} True if equal
 */
export const sourceKeysEqual = (obj1: Identified, obj2: Identified): boolean => {
  return eqProps('sourceKey', obj1, obj2);
};

/**
 * Validates that the list has only one item before return the item.
 * TODO This was in @rescapes/rambda but that throws an annoying deprecation warning from folktale
 * @param list
 */
export const onlyOneValueOrThrow = <T extends any>(list: T[]): T => {
  if (length(list) !== 1) {
    throw new Error(`list should be length 1, got length ${length(list)}`);
  }
  return head(list);
};

/**
 * Return the only item or null from the list
 * @param list
 * @returns {*}
 */
export const onlyOneValueOrNoneThrow = (list: any[]): boolean => {
  if (length(list) > 1) {
    throw new Error(`list should be length 1, got length ${length(list)}`);
  }
  return head(list);
};


/**
 * Returns true if list1 and list2 are both nonnull and their lengths are equal
 * @param list1
 * @param list2
 * @returns {Boolean}
 */
export const lengthsEqual = (list1: any[], list2: any[]): boolean => {
  return list1 && list2 && equals(length(list1), length(list2));
};

/**
 * Performs a shallow compare of reference instead of ramda's default
 * equals, which is deep
 * @param item1
 * @param item2
 * @returns {boolean} True if the references or primitives are equal
 * based on ===
 */
export const shallowEquals = (item1: any, item2: any): boolean => {
  return item1 === item2;
};

/**
 * Convert a list to a set of consecutive pairs of list N - 1 compared to the list of length N
 * Throws an error if the List is less than length 2
 * @param list
 */
export const listToPairs = <T extends any, PairType = [T, T]>(list: T[]): PairType[] => {
  if (length(list) < 2) {
    throw new Error('Cannot process a list of length 1 to pairs');
  }
  return zip<T, T>(
    slice<T>(0, -1, list),
    slice<T>(1, Infinity, list)
  ) as PairType[];
};

/**
 * Version of head that throws if the list is empty
 * @param list
 */
export const headOrThrow = <T extends any>(list: T[]): T => {
  const item = head(list);
  if (!item) {
    throw Error('List is empty');
  }
  return item;
};

/**
 * Version of last that throws if the list is empty
 * @param list
 */
export const lastOrThrow = <T extends any>(list: T[]): T => {
  const item = last(list);
  if (!item) {
    throw Error('List is empty');
  }
  return item;
};

/**
 * Find the first matching value or throw
 * @param pred
 * @param list
 */
export const findOrThrow = <T>(pred: (val: T) => boolean, list: readonly T[]): T | never => {
  const value = find(pred, list);
  if (!value) {
    throw new Error('find did not match anything');
  }
  return value;
};

/**
 * Filters for one and only one value. 0 or more than one matches leads to an Error
 * @param pred
 * @param list
 */
export const findOnlyOneOrThrow = <T extends object>(pred: (val: T) => boolean, list: readonly T[]): T | never => {
  const values: readonly T[] = filter(pred, list);
  if (!equals(1, length(values))) {
    throw new Error(`Should have found exactly one value. Found: ${map<T, string>(value => value.toString(), values)}`);
  }
  return head(values!) as T;
};

export const camelCase = (str: string) =>
  R.toLower(str).replace(
    /_(\w|$)/g,
    (_, x) => x.toUpperCase()
  );

export const camelizeDeep = (obj: Record<string, any>) => {
  return overDeep(
    // Keep is null at the top level
    (_k: string, v: any) => transformKeys(camelCase, v),
    obj
  );
};
export const camelToSnakeCase = (str: string) => {
  return str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
};

export const camelCaseAndRemoveSpaces = (str: string) => {
  return camelCase(str).replace(/\s/g, '');
};

export const slugifyDeep = (obj: Record<string, any>) => {
  return overDeep(
    // Keep is null at the top level
    (_k: string, v: any) => {
      return transformKeys(camelToSnakeCase, v);
    },
    obj
  );
};

/**
 * Groups by the given groupByProp and then checks that each key of the group matches one of groupByPropList
 * and filters out those that do not
 * @param groupByProp a prop of T that resolves to value P or a unary function expecting T and return a value P
 * @param groupByPropList a list of eligible values resolved by groupByProp. Keys not matching this are filtered out
 * @param list
 * @param includeEmptyListForMissingPropValues Default true. Any key of groupByPropList not in the filtered object
 * will be added to the filtered object with an empty array for a value
 */
export const groupByAndFilter = <T, P>(
  groupByProp: string | ((t: T) => P),
  groupByPropList: P[],
  list: T[],
  includeEmptyListForMissingPropValues: boolean = true
) => {
  return compose(
    obj => {
      if (!includeEmptyListForMissingPropValues) {
        return obj;
      }
      // For any value of groupByPropList not in keys of obj, create a key with an empty list
      const keysOfObj = keys(obj);
      return mergeRight(
        obj,
        fromPairs(map(key => [key, []], filter(key => !includes(key.toString(), keysOfObj), groupByPropList)))
      );
    },
    obj => filterWithKeys(
      (_list: T[], groupByPropValue: string) => includes(groupByPropValue, map(i => i.toString(), groupByPropList)),
      obj
    ),
    list => groupBy(
      // Group by the groupByProp prop or by calling groupByProp
      item => is(Function, groupByProp) ? groupByProp(item) : prop(groupByProp, item),
      list
    )
  )(list);
};

/**
 * Returns the items ids that match the keys of the lookup
 * @param itemIds
 * @param lookup
 */
export const itemsInLookup = <S extends string>(lookup: Record<S, any | boolean>, itemIds: S[]): string[] => {
  return _itemsInLookup(has, lookup, itemIds);
};
export const itemsNotInLookup = <S extends string>(lookup: Record<S, any | boolean>, itemIds: S[]): string[] => {
  return _itemsInLookup(complement(has), lookup, itemIds);

};
const _itemsInLookup = <S extends string>(
  pred: ((item: S, lookup: Record<S, any | boolean>) => string),
  lookup: Record<S, any | boolean>,
  itemIds: S[]
) => {
  return filter(
    itemId => {
      return Boolean(pred(itemId, lookup));
    },
    itemIds
  );
};

/**
 * Returns the items mapped to ids that match the keys of the lookup
 * @param lookup
 * @param mapper
 * @param items
 */
export const mappedItemsInLookup = <T>(lookup: Record<string, T | boolean>, mapper: ((item: T) => string), items: T[]): T[] => {
  return filter(
    (item: T): boolean => {
      return has<string>(mapper(item), lookup);
    },
    items
  );
};
