import { useLocation } from 'react-router-dom';
import { CemitAppFooter } from 'components/apps/cemitAppComponents/CemitAppFooter.js';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CemitAppNavigation from 'components/apps/cemitAppComponents/CemitAppNavigation.js';
import { colors } from 'theme/colors.ts';

/**
 * The top level App that contains the TrainApp.
 * TODO This level is full of old code and needs to be redone
 * @param children
 * @return {JSX.Element}
 * @constructor
 */
const CemitApp = ({ children }) => {
  const location = useLocation();

  const {t}  = useTranslation()
  const currentPath = location?.pathname;
  const appRelease = APP_RELEASE;
  const logoTitle =  `${t('cemitVisualizer')} - Build: ${appRelease}`;

  return <>
    <Stack direction='row' spacing={0.5} sx={{
      width: '100%',
      minHeight: '100%',
      height: '100%',
      maxHeight: '100vh',
      overflowY: 'hidden',
      overflowX: 'hidden',
      background: colors.darkBlue,
    }}>
      <CemitAppNavigation {...{
        currentPath,
        appRelease,
        logoTitle
      }}/>
      <main {...{
        style: {
          flexGrow: 2,
          position: 'relative',
          height: '100vh',
          zIndex: '1'
        }
      }} >
        {children}
      </main>
    </Stack>
    <CemitAppFooter
      showSidebarOnSmallScreen
      showMapOnSmallScreen
    />;
  </>;
};

export default CemitApp;
