import { compose, either, range, slice, startsWith, when } from 'ramda';
import { format, parseISO } from 'date-fns';

// Date indices used by the Date object
export const [SUNDAY, MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY, SATURDAY] = range(0, 7)
export const [WEEKDAY, WEEKEND] = [range(1, 6), [SUNDAY, SATURDAY]];

export const humanReadableDate = (date = null) => {
  let dateObj = null;
  if (date) dateObj = new Date(date);
  else dateObj = new Date();

  return `${dateObj.getFullYear()}.${
    dateObj.getMonth() + 1
  }.${dateObj.getDate()}`;
};

export const urlDate = (date = null) => {
  let dateObj = null;
  if (date) dateObj = new Date(date);
  else dateObj = new Date();

  return `${dateObj.getFullYear()}-${
    dateObj.getMonth() + 1
  }-${dateObj.getDate()}`;
};

export const sameDay = (d1, d2) => {
  return (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()
  );
};


/**
 * Converts a string 'Week 12' to '12', 'Week 02' into '2', or 'W9' to '9'
 * @param {String} weekStr
 * @returns {String}
 */
export const parseWeekString = weekStr => {
  return compose(
    // Remove leading 0 or W
    when(either(startsWith('W'), startsWith('0')), slice(-1, Infinity)),
    // 'Week 24' to '24'
    slice(-2, Infinity)
  )(weekStr);
};

/**
 * Combines a datetime with a timeString, using the timeString's, H, M, S, and timezone
 * @param datetime
 * @param timeString
 * @returns {*}
 */
export const dateWithTimeOffset = (datetime, timeString) => {
  const str = `${format(datetime, 'yyyy-MM-dd')}T${timeString}`;
  const date = parseISO(str);
  return date;
};

/**
 * Returns just the time and timezone
 * @param datetime
 * @returns {string}
 */
export const localizedTime = datetime => {
  return format(datetime, 'HH:mm xx');
};
export const localizedTimeNoTimezone = datetime => {
  return format(datetime, 'HH:mm');
};