import { callWorkerAndGetPromise } from 'async/webWorker/workerCommunication.js';
import { memoizedTransformUserTrainRunIntervalsGeojsonToNearest } from 'appUtils/trainAppUtils/userTrainRunIntervalGeojsonUtils.js';

/**
 * Calls memoizedTrainRunFeatureCollectionSensorPoints with a WebWorker
 */
export const workerizedUserTrainRunIntervalFeatureCollectionSensorPoints = userTrainRunIntervals => {
  const trainRunGeojsonWorker = new Worker(new URL('async/webWorker/userTrainRunIntervalGeojsonWorker.js', import.meta.url));
  return Worker ? callWorkerAndGetPromise({
    worker: trainRunGeojsonWorker,
    workerProps: userTrainRunIntervals
  }) : memoizedTransformUserTrainRunIntervalsGeojsonToNearest(userTrainRunIntervals);
};
