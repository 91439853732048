import DraggableTrainRunIntervalBars
  from 'components/apps/trainAppComponents/trainRunChooserComponents/DraggableTrainRunIntervalBars.js';
import { toArrayIfNot } from '@rescapes/ramda';
import { STATION_DOT_HEIGHT } from 'theme/train/trainThemeConstants.js';
import { TRAIN_RUN_INTERVAL_BAR_HEIGHT } from 'appConfigs/trainConfigs/trainRunConfig.js';
import DraggableTrainRunIntervalBar
  from 'components/apps/trainAppComponents/trainRunChooserComponents/DraggableTrainRunIntervalBar.js';
import PropTypes from 'prop-types';


/**
 * Shows draggable train run interval bars
 * @param trainProps
 * @param componentProps
 * @param {Function} componentProps.resolveOffsetLeft A function that resolves the offset of each bar on the line given
 * the distance property of the bar
 * @param {Object|[Object]} componentProps.sxTrainRunIntervalBar sx overrides for the TrainRunIntervalBar
 * @returns {JSX.Element}
 * @constructor
 */
const DraggableTrainRunIntervalBarsContainer = (
  {
    trainProps,
    componentProps: {
      isTrainRouteLine,
      isAggregate,
      isUserTrainRunLine,
      resolveOffsetLeft,
      spaceGeospatially,
      limitedDistanceRange,
      routeDistancesWithOffsetLefts,
      parentWidth
    },
    sxTrainRunIntervalBar
  }) => {
  const loading = isUserTrainRunLine ?
    trainProps.userTrainRunIntervalProps.loading  ||  !trainProps.userTrainRunIntervalProps.userTrainRunInterval :
    trainProps.trainRunIntervalProps.loading || !trainProps.trainRunIntervalProps.trainRunInterval;
  if (loading) {
    return null
  }
  const trainRun = trainProps.trainRunProps.trainRun;
  // This is either a TrainRunInterval or TrainRouteInterval
  const trainRunInterval = trainProps.trainRunIntervalProps.trainRunInterval;
  // This is the UserTrainRunInterval. Null unless is UserTrainRunLine
  const userTrainRunInterval = trainProps.userTrainRunIntervalProps.userTrainRunInterval;

  // TODO there is only every 1 draggableIntervalBar
  // We don't put a bar on TrainRuns anymore, only UserTrainRunLine and TrainRouteLine
  const draggableIntervalBar = isUserTrainRunLine ?
         <DraggableTrainRunIntervalBar key={userTrainRunInterval.sourceKey}
          {...draggableTrainRunIntervalBarProps({
            isTrainRouteLine,
            isAggregate,
            trainProps,
            trainRun,
            trainRunInterval,
            userTrainRunInterval,
            resolveOffsetLeft,
            spaceGeospatially,
            parentWidth,
            routeDistancesWithOffsetLefts,
            sxTrainRunIntervalBar,
          })}
        /> :
        isTrainRouteLine ? <DraggableTrainRunIntervalBar key={trainRunInterval.sourceKey}
          {...draggableTrainRunIntervalBarProps({
            isTrainRouteLine,
            isAggregate,
            trainProps,
            trainRun,
            trainRunInterval,
            resolveOffsetLeft,
            spaceGeospatially,
            limitedDistanceRange,
            parentWidth,
            routeDistancesWithOffsetLefts,
            sxTrainRunIntervalBar
          })}
        /> : null;
  return <DraggableTrainRunIntervalBars {...{
    trainProps,
    componentProps: {
      isTrainRouteLine,
      resolveOffsetLeft,
      spaceGeospatially,
      limitedDistanceRange,
      parentWidth,
      draggableIntervalBars: [draggableIntervalBar]
    }
  }} />
};

const draggableTrainRunIntervalBarProps = (
  {
    isTrainRouteLine,
    isAggregate,
    trainProps,
    trainRun,
    trainRunInterval,
    userTrainRunInterval,
    resolveOffsetLeft,
    sxTrainRunIntervalBar,
    spaceGeospatially,
    limitedDistanceRange,
    routeDistancesWithOffsetLefts,
    parentWidth
  }) => {
  const sourceKey = userTrainRunInterval ? userTrainRunInterval.sourceKey : trainRunInterval.sourceKey;
  trainRunInterval = trainRunInterval || userTrainRunInterval.trainRunInterval;
  return {
    componentProps: {
      sourceKey,
      isTrainRouteLine,
      resolveOffsetLeft,
      spaceGeospatially,
      isAggregate,
      limitedDistanceRange,
      routeDistancesWithOffsetLefts,
      parentWidth
    },
    trainProps: {
      trainRouteProps: trainProps.trainRouteProps,
      // Set the trainRun
      trainRunProps: {
        ...trainProps.trainRunProps,
        trainRun
      },
      // Set the trainRunInterval
      trainRunIntervalProps: {
        ...trainProps.trainRunIntervalProps,
        trainRunInterval
      },
      // Set the UserTrainRunInterval
      userTrainRunIntervalProps: {
        ...trainProps.userTrainRunIntervalProps,
        userTrainRunInterval
      }
    },
    sx: [{
      height: TRAIN_RUN_INTERVAL_BAR_HEIGHT,
      top: `calc((${STATION_DOT_HEIGHT} / 2) - (${TRAIN_RUN_INTERVAL_BAR_HEIGHT} / 2))`
    }, ...toArrayIfNot(sxTrainRunIntervalBar)]
  };
};
DraggableTrainRunIntervalBarsContainer.propTypes = {
  trainProps: PropTypes.object.isRequired,
  componentProps: PropTypes.shape({
    isUserTrainRunLine: PropTypes.bool.isRequired,
    resolveOffsetLeft: PropTypes.func.isRequired
  }).isRequired,
  sxTrainRunIntervalBar: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.object]),
}

export default DraggableTrainRunIntervalBarsContainer;