import {DependencyList, EffectCallback, useEffect, useMemo} from 'react';
import {concat} from 'ramda';

/**
 * Calls useMemo with the given memoFunc and dependencies but returns
 * null without calling the memoFunc if loading is True.
 * loading is always concatted to the dependencies
 * @param loading
 * @param memoFunc The func called by useMemo if loading is false
 * @param [dependencies] Default [] The useMemo dependencies, not including loaidng
 * @param defaultValue
 * @returns {*}
 */
export const useNotLoadingMemo = <T>(
  loading: boolean,
  memoFunc: () => T,
  dependencies: DependencyList = [],
  defaultValue: any = undefined
): T | undefined => {
  return useMemo<T | undefined>((): T | undefined => {
    if (loading)
      return defaultValue;
    return memoFunc();
  }, concat(dependencies, [loading]));
};

/**
 * Calls useEffect with the given effectFunc and dependencies.
 * Returns before calling effectFunc if loading is false
 * @param loading
 * @param effectFunc The func called by useMemo if loading is false
 * @param dependencies Default [], the useMemo dependencies, not including loading
 * @returns {VoidFunction}
 *
 */
export const useNotLoadingEffect = (loading: boolean, effectFunc: EffectCallback, dependencies: DependencyList = []) => {
  if (typeof (loading) === 'undefined')
    throw Error('loading prop must be either truthy or false, got undefined');
  return useEffect(() => {
    if (loading)
      return;
    return effectFunc();
    // @ts-expect-error too complicated for now
  }, concat(dependencies, [loading]));
};

/**
 * Calls the given function in an effect with dependencies that only runs if loading is false and calls setter on its results
 * The intention of this function is to separate effects from non-mutating code
 * @param loading If true, do nothing
 * @param [inProcessSetter] Optional setter that is called with true when the loading is false and effect
 * begins and called with false when the effect ends.

 * @param func The non-mutating function to call
 * @param props props for func
 * @param setter The setter function called with the result of func. This can be a useState setter or
 * a custom mutation function, like for setting layers on a Mapbox map
 * @param dependencies useEffect dependencies
 */
export const useNotLoadingSetterEffect = <T, P extends Record<string, any>>(
  { loading, inProcessSetter }: { loading: boolean, inProcessSetter: (value: boolean) => void },
  func: (props: P) => T,
  props: P,
  setter: (value: T) => void,
  dependencies: DependencyList
) => {
  useNotLoadingEffect(
    loading,
    () => {
      if (inProcessSetter) {
        inProcessSetter(true);
      }
      const result = func(props);
      setter(result);
      if (inProcessSetter) {
        inProcessSetter(false);
      }
    },
    dependencies
  );
};
