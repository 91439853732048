import {
  add,
  always,
  compose,
  cond,
  eqProps,
  equals,
  find,
  head,
  includes,
  length,
  lensProp,
  mapObjIndexed,
  mergeRight,
  over,
  propOr,
  set,
  T,
  when
} from 'ramda';
import { resolveDistance, resolveOffsetLeft } from 'appUtils/trainAppUtils/trainRunLineUtils.js';
import { useNotLoadingMemo } from 'utils/hooks/useMemoHooks.js';
import { onlyOneValueOrThrow } from 'utils/functional/functionalUtils.js';
import {
  extractTrainRunIntervalExtremes,
  extractTrainRunIntervalTrainRoute,
  isTrainRouteInterval,
  isTrainRunInterval,
  isUserTrainRunInterval
} from 'appUtils/trainAppUtils/trainRunUtils.js';
import {
  computedIntervalBarPosition,
  defaultUnmaximizedDistanceRange
} from 'appUtils/trainAppUtils/trainRunIntervalUtils.js';
import { ItemTypes } from 'appConfigs/trainConfigs/trainDragAndDropConfig.js';
import { TRAIN_RUN_INTERVAL_MINIMUM } from 'appConfigs/appConfig.js';
import { findMapped } from '@rescapes/ramda';

/**
 * Returns true if 'start' or 'end' values are equal for the two distanceRanges
 * @param startOrEnd
 * @param distanceRnage1
 * @param distanceRange2
 * @returns {*}
 */
export const distanceRangesEqualForProp = (startOrEnd, distanceRnage1, distanceRange2) => {
  return eqProps(startOrEnd, distanceRnage1, distanceRange2);
};

/**
 * Depending on the type of TrainRunInterval, return the stored instance that correponds to the id of
 * what was dragged
 * @param crudUserTrainRunIntervals
 * @param crudTrainRunIntervals
 * @param userTrainRunInterval
 * @param trainRunInterval
 * @returns {*}
 */
export const extractMatchingTrainRunInterval = ({
                                                  crudUserTrainRunIntervals,
                                                  crudTrainRunIntervals,
                                                  trainRunInterval,
                                                  userTrainRunInterval
                                                }) => {
  const matchingTrainRunInterval = cond([
    [
      isUserTrainRunInterval,
      () => {
        // TODO Can't we just update the incoming UserTrainRunInterval?
        return find(
          current => eqProps('sourceKey', userTrainRunInterval, current),
          crudUserTrainRunIntervals.list
        )?.trainRunInterval;
      }
    ],
    [
      isTrainRouteInterval,
      () => {
        return trainRunInterval;
      }
    ],
    [
      isTrainRunInterval,
      () => {
        // TODO Can't we just update the incoming TrainRunInterval?
        return find(
          current => eqProps('sourceKey', trainRunInterval, current),
          crudTrainRunIntervals.list
        );
      }
    ],
    [T, () => {
      throw new Error('trainRunInterval was not an expected type');
    }]
  ])(userTrainRunInterval || trainRunInterval);
  return matchingTrainRunInterval;
};

function computeDistanceRange(
  {
    trainRunInterval,
    spaceGeospatially,
    limitedDistanceRange,
    routeDistancesWithOffsetLefts,
    parentWidth,
    offsetDifference,
    itemType,
    trainRouteDistanceRange,
    matchingTrainRunInterval
  }) {
  return over(
    lensProp('distanceRange'),
    ({ start, end }) => {
      const offSetResolver = (value, xOffset) => {
        return computedIntervalBarPosition({
          trainRunInterval,
          spaceGeospatially,
          limitedDistanceRange,
          resolveOffsetLeft: resolveOffsetLeft({ routeDistancesWithOffsetLefts }),
          parentWidth
        }, value, xOffset);
      };
      const distanceResolver = resolveDistance({
        routeDistancesWithOffsetLefts,
        trainRunInterval,
        spaceGeospatially,
        parentWidth
      });

      // We first resolve the offsetLeft position then translate to the distance in meters that we
      // want to store
      const resolveOffsetDistanceForStartOrEnd = startOrEnd => {
        return compose(
          offset => {
            return distanceResolver(offset);
          },
          startOrEnd => {
            return offSetResolver(startOrEnd, offsetDifference);
          }
        )(startOrEnd);
      };

      const resolver = cond([
        [itemType => includes(
          itemType,
          [
            ItemTypes.TRAIN_RUN_INTERVAL_BAR_MAXIMIZER,
            ItemTypes.TRAIN_RUN_INTERVAL_BAR_LEFT_MAXIMIZER,
            ItemTypes.TRAIN_RUN_INTERVAL_BAR_RIGHT_MAXIMIZER
          ]),
          () => ({ side }, startOrEndValue) => {
            if (
              (equals(ItemTypes.TRAIN_RUN_INTERVAL_BAR_LEFT_MAXIMIZER, itemType) && equals('right', side)) ||
              (equals(ItemTypes.TRAIN_RUN_INTERVAL_BAR_RIGHT_MAXIMIZER, itemType) && equals('left', side))
            ) {
              // Do nothing to the other side if the left or right side was clicked
              return startOrEndValue;
            }

            const startOrEnd = { left: 'start', right: 'end' }[side];
            const unmaximizedDistanceRange = cond([
                [
                  // Get the set unmaximizedDistanceRange if it has been stored
                  propOr(false, 'unmaximizedDistanceRange'),
                  ({ unmaximizedDistanceRange }) => unmaximizedDistanceRange
                ],
                [
                  // If not, use the previous distanceRange if not maximized
                  ({ distanceRange }) => {
                    return !distanceRangesEqualForProp(startOrEnd, distanceRange, trainRouteDistanceRange);
                  },
                  ({ distanceRange }) => distanceRange
                ],
                [
                  // If maximized, make up a value that is -/+ 500 from the middle of the route
                  T,
                  () => {
                    return { [startOrEnd]: defaultUnmaximizedDistanceRange({ startOrEnd }, trainRouteDistanceRange) };
                  }
                ]
              ]
            )(matchingTrainRunInterval);

            // Toggle the start and ends to maximized/unmaximized independently
            return distanceRangesEqualForProp(startOrEnd, matchingTrainRunInterval.distanceRange, unmaximizedDistanceRange) ?
              // If the distanceRange equals unmaximizedDistanceRange, we are not maximized and should maximize
              trainRouteDistanceRange[startOrEnd] :
              // Otherwise restore to the unmaximized values
              unmaximizedDistanceRange[startOrEnd];
          }
        ],
        [equals(ItemTypes.TRAIN_RUN_INTERVAL_BAR_MOVER),
          () => (_, startOrEnd) => {
            // Offset both start and end
            return resolveOffsetDistanceForStartOrEnd(startOrEnd);
          }
        ],
        [equals(ItemTypes.TRAIN_RUN_INTERVAL_BAR_LEFT_EXPANDER),
          () => ({ side }, startOrEnd) => {
            // Offset just the start
            return equals('right', side) ?
              startOrEnd :
              resolveOffsetDistanceForStartOrEnd(startOrEnd);
          }
        ],
        [equals(ItemTypes.TRAIN_RUN_INTERVAL_BAR_RIGHT_EXPANDER),
          () => ({ side }, startOrEnd) => {
            // Offset just the end
            return equals('left', side) ?
              startOrEnd :
              resolveOffsetDistanceForStartOrEnd(startOrEnd);
          }
        ],
        [T, itemType => {
          throw new Error(`Unexpected item type: ${itemType}`);
        }]
      ])(itemType);

      const routeDistance = extractTrainRunIntervalTrainRoute(trainRunInterval).routeDistance
      // Don't allow start to be within 100 meters of the routeDistance
      const startDistance = Math.min(
        add(-TRAIN_RUN_INTERVAL_MINIMUM, routeDistance),
        resolver({ side: 'left' }, start)
      );
      const endDistance = Math.max(
          add(TRAIN_RUN_INTERVAL_MINIMUM, startDistance),
          resolver({ side: 'right' }, end)
      )
      return {
        start: startDistance,
        // Don't allow end to be within 100 meters of start
        end: endDistance
      };
    }
  );
}

/**
 * If the updated distanceRange is unmaximized, set unmaximizedDistanceRange to it
 * If the updated distanceRange is maximized, set unmaximizedDistanceRange the old distanceRange
 * unless that too is maximized, in which case set unmaximizedDistanceRange to a default
 * @param trainRouteDistanceRange
 * @param matchingTrainRunInterval
 * @param updatedTrainRunInterval
 * @returns {function(*=): *}
 */
const computeUnmaximizedDistanceRange = (
  {
    trainRouteDistanceRange,
    matchingTrainRunInterval,
    updatedTrainRunInterval
  }) => {
  return over(
    lensProp('unmaximizedDistanceRange'),
    () => {

      return mapObjIndexed((value, startOrEnd) => {
          return findMapped(
            f => {
              const value = f(startOrEnd);
              // Return null if maximized to trainRouteDistanceRange
              return distanceRangesEqualForProp(startOrEnd, trainRouteDistanceRange, { [startOrEnd]: value }) ?
                null :
                value;
            },
            [
              // Prefer the new value
              always(value),
              // Else the previous value
              startOrEnd => matchingTrainRunInterval.distanceRange[startOrEnd],
              // Else a def
              startOrEnd => defaultUnmaximizedDistanceRange({ startOrEnd }, trainRouteDistanceRange)
            ]
          );
        },
        updatedTrainRunInterval.distanceRange
      );
    },
    updatedTrainRunInterval
  );
};

/**
 * Updates the UserTrainRunInterval or TrainRouteInterval
 * @param trainRouteProps
 * @param trainRoute
 * @param trainRunInterval
 * @param userTrainRunInterval
 * @param crudTrainRunIntervals
 * @param crudUserTrainRunIntervals
 * @param routeDistancesWithOffsetLefts
 * @param parentWidth
 * @param {Number} [offsetDifference] The drag offset. Can be null for itemType = TRAIN_RUN_INTERVAL_BAR_MAXIMIZER
 * @param itemType
 * @param spaceGeospatially
 * @param limitedDistanceRange
 * @param isTrainRouteLine
 * @param isAggregate
 * @returns {{isTrainRouteLine, trainRunInterval, trainRoute}}
 */
export const updateUserTrainRunIntervalDistance = (
  {
    trainRouteProps,
    trainRoute,
    trainRunInterval,
    userTrainRunInterval,
    crudTrainRunIntervals,
    crudUserTrainRunIntervals,
    routeDistancesWithOffsetLefts,
    parentWidth,
    offsetDifference,
    itemType,
    spaceGeospatially,
    limitedDistanceRange,
    isTrainRouteLine,
    isAggregate
  }) => {

  // When we finish dropping, update the trainRunInterval's distanceRange.start and end properties
  // to match the distances that we convert from offsetDifference--the x distance we dragged
  const matchingTrainRunInterval = extractMatchingTrainRunInterval({
    crudUserTrainRunIntervals,
    crudTrainRunIntervals,
    trainRunInterval,
    userTrainRunInterval
  });
  const trainRouteDistanceRange = extractTrainRunIntervalExtremes(matchingTrainRunInterval);

  const updatedTrainRunInterval = compose(
    // After the TrainRunInterval is moved, store an unmaximizedDistanceRange to mark the old
    // value if one or both sides have been maximized or store the unmaximizedDistanceRange as the new
    // distanceRange if not maximized
    updatedTrainRunInterval => computeUnmaximizedDistanceRange({
      trainRouteDistanceRange,
      matchingTrainRunInterval,
      updatedTrainRunInterval
    }),
    computeDistanceRange({
      trainRunInterval,
      spaceGeospatially,
      limitedDistanceRange,
      routeDistancesWithOffsetLefts,
      parentWidth,
      offsetDifference,
      itemType,
      trainRouteDistanceRange,
      matchingTrainRunInterval
    })
  )(matchingTrainRunInterval);

  return {
    isTrainRouteLine,
    trainRoute,
    trainRunInterval: cond([
      [
        isUserTrainRunInterval,
        () => {
          return crudUserTrainRunIntervals.updateOrCreateWithSideEffects(
            mergeRight(
              userTrainRunInterval,
              { trainRunInterval: updatedTrainRunInterval }
            )
          );
        }
      ],
      [
        isTrainRouteInterval,
        () => {
          // If this is the TrainRouteInterval, save it now.
          // It's not part of the crud operations below, rather it forces
          // TrainRunIntervals to sync to it if they are in sync mode
          if (isAggregate) {
            trainRouteProps.setTrainRouteAggregateInterval(updatedTrainRunInterval);
          } else {
            trainRouteProps.setTrainRouteInterval(updatedTrainRunInterval);
          }
          // If we moved the Aggregate TrainRoute, update the active UserTrainRunIntervals to be synced with it
          // This does nothing to the trainRouteInterval's distanceRange, which is saved external to this
          // TODO We don't want to update the UserTrainRunInterval intervals anymore. Instead the aggregate
          // UserTrainRunInterval's interval is used by the charts and maps to limit the range
          /*
          if (isAggregate) {
            syncActiveUserTrainRunIntervals(
              crudUserTrainRunIntervals,
              pick(['distanceRange'], updatedTrainRunInterval)
            );
          }
          */
          return trainRunInterval;
        }
      ],
      [
        isTrainRunInterval,
        () => {
          console.warn('TrainRunIntervals are no longer expected to have Draggable TrainRun intervals');
          // Update this TrainRunInterval, with template-syncing and unsyncing side effects
          //crudTrainRunIntervals.updateOrCreateWithSideEffects(updatedTrainRunInterval);
          return trainRunInterval;
        }
      ],
      [T, () => {
        throw new Error('trainRunInterval was not an expected type');
      }]
    ])(trainRunInterval)
  };
};
/**
 * Creates a function to maximize/restore the start and/or end of a trainRunInterval distanceRange
 * @param {Object} trainRouteProps
 * @parma {Object} trainRoute
 * @param {Object} crudTrainRunIntervals CRUD utils to update the trainRunInterval
 * @param {Object} [crudUserTrainRunIntervals] Only needed if editing a UserTrainRunLine CRUD utils to update the userTrainRunInterval
 * @param {Object} trainRunInterval The TrainRunInterval
 * @param {Object} userTrainRunInterval The UserTrainRunInterval
 * @param {Object} routeDistancesWithOffsetLefts Lookup of station offsetLefts
 * @param {Number} parentWidth
 * @param {Boolean} spaceGeospatially
 * @param {Boolean} isTrainRouteLine
 * @param {Boolean} isAggregate
 * @returns {Function} A function expecting distanceRangeKeys where distanceRangeKeys can be
 * ['start', 'end'] or one of those values. Maximizes or restores the start and/or end of the distance
 * range to the min 0 or max trainRunInterval.trainRoute.routeDistance based on the values stored in local state
 */
export const useTrainRunIntervalMaximizer = (
  {
    trainRouteProps,
    trainRoute,
    crudTrainRunIntervals,
    crudUserTrainRunIntervals,
    trainRunInterval, userTrainRunInterval,
    parentWidth,
    routeDistancesWithOffsetLefts,
    spaceGeospatially,
    isTrainRouteLine,
    isAggregate
  }
) => {

  return distanceRangeKeys => {
    updateUserTrainRunIntervalDistance(
      {
        trainRouteProps,
        trainRoute,
        trainRunInterval,
        userTrainRunInterval,
        crudTrainRunIntervals,
        crudUserTrainRunIntervals,
        routeDistancesWithOffsetLefts,
        parentWidth,
        // No offsetDiffernece, TRAIN_RUN_INTERVAL_BAR_MAXIMIZER
        // informs the code that it shall maximize or restore the
        // interval to the previous unmaximzed vlaue
        offsetDifference: null,
        itemType: cond(
          [
            // Choose the maximizer operation base on if distanceRangeKeys contains 'start', 'end' or both
            [compose(equals(2), length), always(ItemTypes.TRAIN_RUN_INTERVAL_BAR_MAXIMIZER)],
            [compose(equals('start'), head), always(ItemTypes.TRAIN_RUN_INTERVAL_BAR_LEFT_MAXIMIZER)],
            [compose(equals('end'), head), always(ItemTypes.TRAIN_RUN_INTERVAL_BAR_RIGHT_MAXIMIZER)]
          ])(distanceRangeKeys),
        spaceGeospatially,
        limitedDistanceRange: null,
        isTrainRouteLine,
        isAggregate
      });
  };
};

/**
 * Returns the memoized TrainRunInterval of crudUserTrainRunIntervals or if not defined crudTrainRunIntervals
 * @param {Boolean} loading If true this always returns null
 * @param {Boolean} isUserTrainRunLinea If true we are in a UserTrainRunInterval context
 * @param {Object} [limitedDistanceRange] Default null. If defined set the TrainRunInterval's range to this,
 * which should be a sub-range of the TrainRunInterval's
 * @param {Object} crudUserTrainRunIntervals
 * @param {Object} crudUserTrainRunIntervals.list Take the head of this
 * @param {Object} crudTrainRunIntervals
 * @param {Object} crudTrainRunIntervals.list Take the head of this if crudUserTrainRunIntervals is null
 * @returns {Object} The TrainRunInterval or null if there are no TrainRuns
 */
export const useMemoTrainRunIntervalFromCrud = (
  {
    loading,
    isUserTrainRunLine,
    isTrainRouteLine,
    limitedDistanceRange,
    trainRouteInterval,
    crudUserTrainRunIntervals,
    crudTrainRunIntervals
  }) => {
  return useNotLoadingMemo(loading, () => {
    return compose(
      // If we have a limited distance range, pretend the trainRunInterval has that range to help us shape the
      // TrainRunLine
      trainRouteOrRunInterval => {
        return when(
          trainRunOrRouteInterval => {
            return trainRunOrRouteInterval && limitedDistanceRange;
          },
          trainRunOrRouteInterval => {
            return set(lensProp('distanceRange'), limitedDistanceRange, trainRunOrRouteInterval);
          }
        )(trainRouteOrRunInterval);
      },
      ({ crudUserTrainRunIntervals, crudTrainRunIntervals, trainRouteInterval }) => {
        // Get the Train(Route|Run)Interval based on the scope
        return cond([
          [
            () => isTrainRouteLine,
            ({ trainRouteInterval }) => trainRouteInterval
          ],
          [
            () => isUserTrainRunLine,
            // We only ever expect one crudUserTrainRunIntervals.list item for a single UserTrainRunInterval
            ({ crudUserTrainRunIntervals }) => onlyOneValueOrThrow(crudUserTrainRunIntervals.list)?.trainRunInterval
          ],
          [
            T,
            // We only ever expect one crudTrainRunIntervals.list item for a single TrainRunInterval
            ({ crudTrainRunIntervals }) => onlyOneValueOrThrow(crudTrainRunIntervals.list)
          ]
        ])({ crudUserTrainRunIntervals, crudTrainRunIntervals, trainRouteInterval });
      }
    )({ crudUserTrainRunIntervals, crudTrainRunIntervals, trainRouteInterval });
  }, [loading, limitedDistanceRange, crudUserTrainRunIntervals, crudTrainRunIntervals, trainRouteInterval]);
};
