import {any, chain, fromPairs, map, not, prop, props} from 'ramda';
import {mapboxGeojsonSource} from 'railbedUtils/map/mapboxSourceUtils.ts';
import {asFeatureCollection} from 'railbedUtils/geojson/geojsonUtils.ts';
import {colors} from 'theme/colors.ts';
import {
    MAP_RAILWAY_LAYER,
    MAP_RAILWAY_SOURCE,
    MAP_TRACK_SWITCH_LAYER,
    MAP_TRACK_SWITCH_SOURCE
} from 'railbedConfig/appConfigs/cemitAppConfigs/railwayLineConfig.ts'
import {RailwayLine} from '../../railbedTypes/railways/railwayLine';
import {Track} from '../../railbedTypes/railways/track';
import {TrainProps} from '../../railbedTypes/propTypes/trainProps';

/**
 * Returns the tracks of the given railwayLines. We currently only expect one Track instance per RailwayLine,
 * but this could change in the future if we define track segments or similar
 * @param railwayLines
 * @return {Track[])}
 */
export const tracksOfRailways = (railwayLines: RailwayLine[]): Track[] => {
    return chain(
        prop('tracks'),
        railwayLines
    );
};

/**
 * Converts tracks of the current railwayLines to Mapbox geojson sources
 * @param trainProps
 * @param trainProps.railwayLineProps
 * @param trainProps.railwayLineProps.railwayLines
 * @returns {*}
 */
export const railwayLineMapboxSourcesAndLayersSets = ({railwayLines}: { railwayLines: RailwayLine[] }) => {
    // Adding multiple layers so we can have different colors on each different railroad
    // sources is [{source: source key, wasAdded: true|false}, ...]
    return map(
        (railwayLine: RailwayLine) => {
            const sourceName = `${MAP_RAILWAY_SOURCE}-${railwayLine.sourceKey}`;
            const layerId = `${MAP_RAILWAY_LAYER}-${railwayLine.sourceKey}`;
            const layer = {
                id: layerId,
                type: 'line',
                source: sourceName,
                layout: {
                    // TODO move to a Mapbox style railbedConfig
                    'line-join': 'round',
                    'line-cap': 'round'
                },
                paint: {
                    'line-color': [
                        'case',
                        ['boolean', ['feature-state', 'hover'], false],
                        // TODO get styles from MUI railbedTheme
                        colors.trackHighlighted,
                        colors.trackNormal
                    ],
                    'line-width': 5
                }
            };
            // Add the source if not yet defined
            const featureCollection = asFeatureCollection(railwayLine.geojsons[0])
            if (any(not, featureCollection.features)) {
                throw Error('Got an undefined feature in a FeatureCollection')
            }
            return {
                source: mapboxGeojsonSource({
                    sourceName,
                    featureCollection
                }),
                layers: [layer]
            };
        },
        railwayLines
    );
};

/**
 * Converts tracks of the current railwayLines to Mapbox geojson sources
 * @param trainProps
 * @param trainProps.railwayLineProps
 * @param trainProps.railwayLineProps.railwayLines
 * @returns {*}
 */
export const trackSwitchesMapboxSourcesAndLayersSets = ({trainProps}: { trainProps: TrainProps }) => {
    // Adding multiple layers so we can have different colors on each different railroad
    // sources is [{source: source key, wasAdded: true|false}, ...]

    return map(
        (track: Track) => {
            const sourceName = `${MAP_TRACK_SWITCH_SOURCE}-${track.sourceKey}`;
            const layerId = `${MAP_TRACK_SWITCH_LAYER}-${track.sourceKey}`;
            const layer = {
                id: layerId,
                type: 'circle',
                source: sourceName,
                paint: {
                    'circle-radius': 4,
                    'circle-color': 'rgba(255,255,255,0.74)'
                }
                /*
                TODO svg symbol not working
                type: 'symbol',
                source: sourceName,
                layout: {
                  'icon-image': 'track-switch', // reference the image
                  'icon-size': 0.25
                }
                 */
            };
            // Add the source if not yet defined
            return {
                source: mapboxGeojsonSource({
                    sourceName,
                    // @ts-expect-error too complicated for now
                    featureCollection: track.switchesGeojson
                }),
                layers: [layer]
            };
        },
        tracksOfRailways(trainProps.railwayLineProps.railwayLines)
    );
};

/**
 * Returns a lookup of railway sourceKey to the Railway's ReferenceScheduledStopPoint,
 * e.g. {'drammenbanen': {name: 'Oslo sentralstasjon, ...}, ...}
 * @param railwayLines
 * @returns {string[]}
 */
export const railwayLineSourceKeyToReferenceScheduledStopPoint = (railwayLines: RailwayLine[]): string[] => {
    // @ts-expect-error too complicated for now
    return fromPairs(map(props(['sourceKey', 'referenceScheduledStopPoint']), railwayLines));
};
