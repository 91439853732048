import UserTrainRunIntervalChartDependency
  from 'async/trainAppAsync/dependencies/UserTrainRunIntervalChartDepedency.js';

/**
 * Currently this just tracks trainProps.userTrainRunIntervalProps.geojsonProps.loading
 * but it can do more fine-grained map statuses in the future, such as
 * having messages that say "Updating data for train x" whild the map continues to function
 * @param appProps
 * @param organizationProps
 * @param trainProps
 * @param children
 * @return {*}
 * @constructor
 */
const TrainMapGeojsonDependency = ({ appProps, organizationProps, trainProps, mapProps }) => {

  const loading = trainProps.userTrainRunIntervalProps.geojsonProps.loading;

  const loadingExplanation = {
    'trainProps.userTrainRunIntervalProps.geojsonProps.loading': trainProps.userTrainRunIntervalProps.geojsonProps.loading
  };

  return <UserTrainRunIntervalChartDependency {...{
    appProps,
    organizationProps,
    mapProps: {
      geojsonProps: {
        loadingExplanation,
        // Depends on trainProps.userTrainRunIntervalProps.geojsonProps.loading
        loading
      },
      ...mapProps
    },
    trainProps
  }} />;
};

TrainMapGeojsonDependency.displayName = 'TrainMapGeojsonDependency';
export default TrainMapGeojsonDependency;