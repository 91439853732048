import 'react-input-range/lib/css/index.css';
import { compose, head, last, length, lensIndex, map, min, pathOr, view } from 'ramda';
import { toArrayIfNot } from '@rescapes/ramda';
import { Feature } from 'geojson';
import { UnaryOneFromMany } from '../../railbedTypes/unaryOneFromMany';
import { FeatureU, LineStringU, PositionU } from '../../railbedTypes/geometry/geojsonUnions';

/**
 * Get the float property of the feature of features at the given index
 * @param prop The property in features[*].properties[props]
 * @param features The features
 * @param index The index of the item in features
 * @returns the property attribute number with the given precision
 */
export const getFeaturePropertyAtIndex = (
  {
    precision = 1,
    prop,
    features,
    index
  }: {
    precision?: number,
    prop: string,
    features: FeatureU[],
    index: number
  }
) => {
  return compose<[FeatureU[]], FeatureU, string, number>(
    (float: number): string => float.toFixed(precision),
    (floatStr: string | number): number => parseFloat(floatStr.toString()),
    (feature: FeatureU): string | number => pathOr<string | number>(0, ['properties', prop], feature),
    // @ts-expect-error Don't understand the error
    (features: FeatureU[]): number => {
      return view<FeatureU[], number>(
        // If the index is greater than the feature length, set it to the the last feature
        // @ts-expect-error Don't understand the error
        lensIndex<FeatureU[], number>(min(length(features) - 1, index)),
        features
      );
    }
  )(features);
};

/***
 * Puts the given geojson feature or features in a FeatureCollection
 * @param features
 * @return {{features: *[], type: string}}
 */
export const asFeatureCollection = (features: (FeatureU | FeatureU)[]) => {
  return {
    type: 'FeatureCollection',
    // Set a 1-based id on on each feature
    features: toArrayIfNot(features)
  };
};


/**
 * Takes the two extreme coordinate pairs from the lineSegment
 * @param lineSegmentFeature
 * @returns the two pairs
 */
export const lineSegmentExtremeCoordinates = (lineSegmentFeature: Feature<LineStringU>): [PositionU, PositionU] => {
  return map<UnaryOneFromMany<PositionU>, PositionU>(
    (f: (coords: PositionU[]) => PositionU): PositionU => {
      return f(lineSegmentFeature.geometry.coordinates);
    },
    [head, last]
  ) as [PositionU, PositionU];
};
