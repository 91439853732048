import {always, ifElse, join} from "ramda";
import {compact} from "@rescapes/ramda";

export const durationText = (duration) => {
    const hourLabel = ifElse(
        hours => hours > 0,
        hours => `${hours} ${hours > 1 ? 'Hours' : 'Hour'}`,
        always(null)
    )(Math.floor(duration / 60))
    const minuteLabel = ifElse(
        minutes => minutes > 0,
        minutes => `${minutes} Minutes`,
        always(null)
    )(Math.floor(duration % 60))
    return join(', ', compact([hourLabel, minuteLabel]))
}
