import { Box, IconButton, Stack, Tooltip } from '@mui/material';
import { UTurnLeft } from '@mui/icons-material';
import { TrainRouteMenu } from 'components/apps/trainAppComponents/trainRouteChooserComponents/TrainRouteMenu.js';
import { useTranslation } from 'react-i18next';

/**
 * Shows the start and end of the current Train route.
 * In the future it will be possible to click the arrow to reverse the route
 * and click the start and end stations to change them
 * @param {Object} trainProps
 * @param {Object} trainProps.trainRouteProps
 * @param {Object} trainProps.trainRouteProps.trainRouteOrGroup The TrainRoute or TrainRouteGroup that is active
 * @param {Object} componentProps
 * @param {[Object]} componentProps.trainRoutesSorted TrainRoutes and TrainRouteGroups sorted for the origin
 * @param {[Object]} componentProps.trainRoutesSortedForDestination  TrainRoutes and TrainRouteGroups sorted for the
 * destination
 * @param {Function} componentProps.reverseDisabled Function to reverse the route when the arrow is clicked
 * @param {String} componentProps.originName The TrainRoute or TrainRouteGroup's origin name or t('allOrigins') if not
 * restricted
 * @param {String} componentProps.destinationName  The TrainRoute or TrainRouteGroup's destination name or t('allOrigins') if not
 * restricted
 * @param {Function} componentProps.chooseTrainRoute Unary functionary expecting a TrainRoute or TrainRouteGroup
 * Sets a new TrainRoute or TrainRouteGroup as current
 * @returns {JSX.Element}
 */
const TrainRouteChooser = (
  {
    trainProps: {
      trainRouteProps: {
        trainRouteOrGroup
      }
    },
    componentProps: {
      trainRoutesSorted,
      reverseDisabled,
      chooseTrainRouteOrGroup,
      reverseTrainRouteOrGroup,
    }
  }) => {
  const {t} = useTranslation()
  return <Stack key='fromTo' direction='row' justifyContent='center' spacing={2}>
    <Stack direction='row' alignItems='center' justifyContent='center'>
      <TrainRouteMenu {...{
        trainRouteOrGroup,
        chooseTrainRouteOrGroup,
        trainRoutes: trainRoutesSorted
      }} />
    </Stack>
    <Tooltip arrow title={t(reverseDisabled ? 'reverseTrainRouteNotAvailable' : 'reverseTrainRoute')}>
      <Box sx={{ width: 24, height: 24 }}>
        <IconButton
          color='secondary'
          size='large'
          onClick={() => {
            reverseTrainRouteOrGroup(trainRouteOrGroup);
          }}
          disabled={reverseDisabled}
          sx={{ padding: 0, marginTop: '5px' }}
        >
          <UTurnLeft sx={{ alignSelf: 'center' }} />
        </IconButton>
      </Box>
    </Tooltip>
  </Stack>;
};

export default TrainRouteChooser;