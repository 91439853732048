import { useMemo, useState } from 'react';
import { useTrainApiSetTrainRoutes } from 'async/trainAppAsync/hooks/trainApiHooks/trainApiTrainRouteHooks.js';
import {
  useCreateTrainRoutesDerivatives,
  useDeserializeTrainRouteInStorage,
  useSetTrainRoute
} from 'async/trainAppAsync/hooks/typeHooks/trainRouteHooks.js';
import { useNotLoadingEffect, useNotLoadingMemo } from 'utils/hooks/useMemoHooks.js';
import { all, always, pick } from 'ramda';
import DateDependency from 'async/trainAppAsync/dependencies/DateDepedency.js';
import { useUpdateOrCreateTrainRouteInterval } from 'async/trainAppAsync/hooks/typeHooks/trainRouteIntervalHooks.js';
import { unlessLoadingValue } from 'utils/componentLogic/loadingUtils.js';
import { useUpdateOrCreateTrainRunFilterWithTrainRoute } from 'async/trainAppAsync/hooks/trainRunFilterHooks/trainFilterTrainRouteHooks.js';
import { useTrainApiSetTrainRouteGroups } from 'async/trainAppAsync/hooks/trainApiHooks/trainApiTrainRouteGroupHooks.js';
import { useLocalStorage } from 'utils/hooks/useLocalStorage.js';
import { LOCAL_STORAGE_TRAIN_ROUTE } from 'appConfigs/appConfig.js';


/**
 * Loads/Updates TrainRoute dependencies into trainProps.trainRouteProps
 * Depends directly on RailwayLine at trainProps.railwayLineProps
 * @param appProps
 * @param organizationProps
 * @param trainProps
 * @param children
 * @return {*}
 * @constructor
 */
const TrainRouteDependency = ({ appProps, organizationProps, trainProps }) => {

  const loading = trainProps.railwayLineProps.loading;
  // All TrainRouteGroups of the client
  const [trainRouteGroups, setTrainRouteGroups] = useState(null);
  // All TrainRoutes of the client's ServiceLines
  const [trainRoutes, setTrainRoutes] = useState(null);
  // The current TrainRoute. The current trainRoute is that with trainRoute.trainRoute match this trainRoute
  const [trainRoute, setTrainRoute] = useLocalStorage(
    {
      // Serialize just the id. We get the full TrainRoute or TrainRouteGroup after loading the TrainRoutes and Groups
      serializer: trainRoute => {
        return pick(['id', '__typename'], trainRoute);
      }
    }, LOCAL_STORAGE_TRAIN_ROUTE, null
  );
  useDeserializeTrainRouteInStorage({
    loading,
    trainRoute,
    trainRoutes,
    trainRouteGroups,
    setTrainRoute
  });

  // The TrainRouteInterval that shows on both pages
  const [trainRouteInterval, setTrainRouteInterval] = useState(null);
  // The TrainRouteInterval that shows on the Running Characteristics Page. It represents an
  // aggregate of the active UserTrainRunIntervals
  const [trainRouteAggregateInterval, setTrainRouteAggregateInterval] = useState(null);
  const [trainRunFilterWithTrainRoute, setTrainRunFilterWithTrainRoute] = useState(null);
  // Tracks the modal to create a TrainRoute filter
  //const [choosingTrainRoute, setChoosingTrainRoute] = useState(false)
  const { organization } = organizationProps;

  const serviceLines = trainProps.serviceLineProps.serviceLines;

  useTrainApiSetTrainRouteGroups({
    loading,
    organization,
    // TrainRouteGroups don't currently depend on ServiceLines, just the organization, but they might some day, so
    // wait for service lines to be loaded
    dependenciesLoaded: !trainProps.serviceLineProps.loading,
    setTrainRouteGroups
  });

  useTrainApiSetTrainRoutes({
    loading,
    organization ,
    dependenciesLoaded: !trainProps.serviceLineProps.loading,
    serviceLines,
    setTrainRoutes
  });

  // If the trainRoute changes, null the TrainRouteIntervals
  // TODO this shouldn't be needed, as they should be set immediately in the hooks below
  useNotLoadingEffect(loading || !trainRouteInterval || !trainRoute, () => {
    if (trainRouteInterval.trainRoute !== trainRoute) {
      setTrainRouteInterval(null);
      setTrainRouteAggregateInterval(null);
      setTrainRunFilterWithTrainRoute(null);
    }
  }, [trainRouteInterval, trainRoute]);

  // Set the current TrainRoute. Defaults to the longest TrainRouteGroup or failing that longest TrainRoute
  useSetTrainRoute({
    loading: loading || !trainRouteGroups || !trainRoutes,
    trainRouteGroups,
    trainRoutes,
    trainRoute,
    setTrainRoute
  });

  // Updates TrainRoutes to have derived properties about the distances to stops
  // as well as adding a routeInterval, which is like a TrainRunInterval that can be used to update
  // the TrainRunInterval of any number of TrainRuns
  // Adds {
  //  routeInterval,
  //  measureDistancesFrom: Name of Reference Station (e.g. 'Oslo S'),
  //  distance: total distance of the Route in kilometers
  //  trackData: Geojson about the routes RailwayLines' tracks
  // }
  // and  trainRoute.pointsOnRoute[*].routePoint.routeDistance: distance to the route point in km from the start point of the route
  //
  useCreateTrainRoutesDerivatives({
    // TODO trainRoute can currently be set before trainRoutes if it came form localStorage
    loading: loading || !trainRoutes || !trainRoute || !trainRouteGroups,
    organization: organizationProps.organization,
    trainRouteGroups,
    setTrainRouteGroups,
    trainRoutes,
    setTrainRoutes,
    trainRoute,
    setTrainRoute
  });

  // The interval to use as a template for changing the interval of all synced TrainRunIntervals
  useUpdateOrCreateTrainRouteInterval({
    // TODO trainRoute can currently be set before trainRoutes if it came form localStorage
    // so make sure to check both
    loading: loading || !trainRoutes || !trainRoute || !trainRoute.trackData,
    // so make sure to check both
    trainRoute,
    trainRouteInterval,
    setTrainRouteInterval
  });

  // The aggregate interval that reflects the intervals of the active UserTrainRunIntervals
  useUpdateOrCreateTrainRouteInterval({
    // TODO trainRoute can currently be set before trainRoutes if it came form localStorage
    // so make sure to check both
    loading: loading || !trainRoutes || !trainRoute || !trainRoute.trackData,
    // so make sure to check both
    trainRoute,
    trainRouteInterval: trainRouteAggregateInterval,
    setTrainRouteInterval: setTrainRouteAggregateInterval,
    sourceKeyPrefix: 'aggregate'
  });

  // The trainRunFilterWithTrainRoute serves as a template UserTrainRunFilter
  // and is configured to the only filter for the current TrainRoute
  useUpdateOrCreateTrainRunFilterWithTrainRoute({
    // TODO trainRoute can currently be set before trainRoutes if it came form localStorage
    // so make sure to check both
    loading: loading || !trainRoutes || !trainRoute || !trainRoute.trackData,
    parentTrainRunFilter: trainProps.formationProps.trainRunFilterWithFormations,
    trainRouteGroups,
    trainRoutes,
    trainRoute,
    trainRunFilterWithTrainRoute,
    setTrainRunFilterWithTrainRoute
  });

  const allTrainRouteTrackData = unlessLoadingValue(loading || !trainRoutes,
    () => all(trainRoute => trainRoute.trackData, trainRoutes)
  );
  const localPropsNotReady = loading ||
    !trainRoute ||
    !trainRouteInterval ||
    !trainRouteAggregateInterval ||
    !allTrainRouteTrackData ||
    // Dependencies are blocked from calculation that depend on TrainRoute until
    // the TrainRoute intervals get synced with trainRoute when the latter changes
    trainRouteInterval.trainRoute.id !== trainRoute.id ||
    trainRouteAggregateInterval.trainRoute.id !== trainRoute.id;

  const loadingExplanation = useMemo(always({
    'trainProps.railwayLineProps.loading': trainProps.railwayLineProps.loading,
    trainRoute,
    trainRouteInterval,
    allTrainRouteTrackData
  }), [trainProps.railwayLineProps.loading, trainRouteInterval, allTrainRouteTrackData]);

  const trainRoutesOrGroups = useNotLoadingMemo(
    localPropsNotReady,
    () => [...trainRouteGroups, ...trainRoutes],
    [trainRouteGroups, trainRoutes]
  );
  return <DateDependency {...{
    appProps,
    organizationProps,
    trainProps: {
      ...trainProps,
      trainRouteProps: {
        loading: localPropsNotReady,
        loadingExplanation,
        trainRouteGroups, setTrainRouteGroups,
        trainRoutes, setTrainRoutes,
        trainRoutesOrGroups,
        trainRoute, setTrainRoute,
        trainRouteInterval, setTrainRouteInterval,
        trainRunFilterWithTrainRoute, setTrainRunFilterWithTrainRoute,
        trainRouteAggregateInterval, setTrainRouteAggregateInterval
      }
    }
  }} />;
};
TrainRouteDependency.displayName = 'TrainRouteDependency';
export default TrainRouteDependency;