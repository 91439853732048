import { Box, Stack, Typography } from '@mui/material';
import CollectionDevice from 'components/apps/trainAppComponents/trainRunChooserComponents/CollectionDevice.js';
import { map } from 'ramda';
import { trainRunCollectionDevices } from 'appUtils/trainAppUtils/trainUtils.js';
import { useTranslation } from 'react-i18next';

export const TrainRunEquipment = ({ showTitle = true, trainRun }) => {
  const { t } = useTranslation();
  return <Stack direction='row'
                spacing={2}>
    {showTitle && <Box sx={{ width: '40px' }}>
      <Typography key='equipmentTitle' variant='caption' sx={{ color: 'grey.400' }}>
        {t('equipment')}
      </Typography>
    </Box>}
    <Stack key='cdcs' direction='row' spacing={1} sx={{ color: 'secondary.main' }}>
      {
        map(
          ({ collectionDevice }) => {
            return <CollectionDevice key={collectionDevice.name} collectionDevice={collectionDevice} />;
          },
          trainRunCollectionDevices(trainRun)
        )
      }
    </Stack>
  </Stack>;
};