import {FeatureU, PointU} from "../../visualizer-railbed/railbedTypes/geometry/geojsonUnions";
import {RideComfortHeatMapDatum} from "../../types/rideComfort/rideComfortHeatMapDatum";
import {Setter} from "../../visualizer-railbed/railbedTypes/hookHelpers/setter";
import {
  RideComfortEdge,
  RideComfortGraphqlResponseAlertData,
  RideComfortGraphqlResponseReportData,
  RideComfortNodeData,
  RideComfortNodeMeta,
  RideComfortReportCount,
  RideComfortReportData,
  RideComfortReportRowSection
} from "../../types/rideComfort/rideComfortMapData";
import {
  add,
  always,
  chain,
  groupBy,
  length,
  map,
  mapObjIndexed,
  mergeAll,
  pick,
  propOr,
  reduce,
  times,
  values,
  zipWith
} from "ramda";
import {format} from "date-fns";
import {RideComfortScopeProps} from "types/rideComfort/rideComfortScopeProps";
import {RideComfortExcelDownloadData} from "types/rideComfort/rideComfortTrainRunGroupProps";
import {chainObjToValues} from '@rescapes/ramda'
import {CemitTypename} from "../../visualizer-railbed/railbedTypes/cemitTypename.ts";
import {typeObject} from "visualizer-railbed/railbedAppUtils/typeUtils/typenameUtils.ts";
import {TrainRunGroup} from "visualizer-railbed/railbedTypes/trainRuns/trainRunGroup";
import {RideComfortLevels} from "types/rideComfort/rideComfortAlertLevels";
import {rideComfortAlertLevelToLabel} from "../../types/rideComfort/rideComfortAlertLevel.ts";
import {RideComfortAlertTypeLabels} from "types/rideComfort/rideComfortAlertTypeKey.ts";
import * as XLSX from 'xlsx';

export const trainDataFriendlyDateFormatString = 'ccc LLL d, yyyy';
const trainDataFriendlyDatetimeFormatWithTImezoneString = `${trainDataFriendlyDateFormatString} HH:mm XXXXX`;

export const setHeatMapWithQueryData = (
  data: RideComfortGraphqlResponseAlertData,
  setHeatMapData: Setter<RideComfortHeatMapDatum[]>
) => {

  if (!data?.data?.levelAll) {
    return
  }
  const numericValues: number[] = [];
  const points: FeatureU<PointU>[] = []

  const array = Object?.entries(data?.data?.levelAll.edges || [])

  array?.forEach((element: [string, RideComfortEdge]) => {
    // get s-values and numeric value
    const node: RideComfortNodeMeta = element[1].node
    const nodeData: RideComfortNodeData = node.data
    const value = parseFloat(nodeData.rawValue);
    const feature: FeatureU<PointU> = node.location ? {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [
          element?.[1]?.node?.location?.lon,
          element?.[1]?.node?.location?.lat
        ]
      },
      properties: {
        value,
        timestamp: format(new Date(node.timestamp), trainDataFriendlyDatetimeFormatWithTImezoneString)
      }
    } : undefined;
    if (value !== undefined) {
      numericValues.push(value);
      points.push(feature)
    }
  });

  const zipped: RideComfortHeatMapDatum[] = zipWith(
    (value: number, point: FeatureU<PointU>) => {
      return {
        numericValue: value,
        point
      }
    },
    numericValues, points
  )

  // Set heatMapDataData only once with the final data
  setHeatMapData(zipped);
}

const levels = ['L0', 'L1', 'L2', 'L3'] as (keyof RideComfortReportData)[];
const headers = [
  'time',
  ...chain((rideComfortAlertTypeLabel: string) => {
    return [
      ...map((level: keyof RideComfortLevels) => {
        const levelLabel = rideComfortAlertLevelToLabel[level];
        return `${rideComfortAlertTypeLabel}_${levelLabel}`;
      }, levels),
      ...map((level: keyof RideComfortLevels) => {
        const levelLabel = rideComfortAlertLevelToLabel[level];
        return `${rideComfortAlertTypeLabel}_${levelLabel}%`;
      }, levels),
    ];
  }, values(RideComfortAlertTypeLabels)),
];
/**
 * Processes the RideComfortReport data into headers, rows, and a filename
 * in order to form an Excel file download
 * @param rideComfortScopeProps
 * @param dataSets
 * @param setRideComfortDownloadData
 */
export const setRideComfortReportWithQueryData = <T extends TrainRunGroup>(
  rideComfortScopeProps: RideComfortScopeProps<T>,
  dataSets: RideComfortGraphqlResponseReportData[],
) => {
  // Creates RideComfortReportRowSections, which each have a date, alertType, level, and count
  const rideComfortReportRowSections: RideComfortReportRowSection[] = chain(
    (dataSet: RideComfortGraphqlResponseReportData) => {
      return chainObjToValues(
        (rideComfortReportCounts: RideComfortReportCount[], level: string) => {
          return map((rideComfortReportCount: RideComfortReportCount) => {
            return typeObject<RideComfortReportRowSection>(
              CemitTypename.rideComfortReportRowSection,
              {
                time: rideComfortReportCount.time,
                rideComfortAlertType: dataSet.data.rideComfortAlertType,
                level,
                rideComfortReportCount,
              },
            );
          }, rideComfortReportCounts);
        },
        pick(levels, dataSet.data),
      ) as RideComfortReportRowSection[];
    },
    dataSets,
  );
  const rideComfortReportRowSectionsByTime: Record<
    string,
    RideComfortReportRowSection[]
  > = groupBy(
    (rideComfortReportRowSection: RideComfortReportRowSection) =>
      rideComfortReportRowSection.rideComfortReportCount.time,
    rideComfortReportRowSections,
  );

// Create a row for each date with a key/value for each non-null value where the keys match headers
  const objectRows = values(
    mapObjIndexed(
      (rideComfortReportRowSections: RideComfortReportRowSection[], time: string) => {
        // Group rideComfortReportRowSections by rideComfortAlertType.label so we can get the total incidents for each
        const rideComfortReportRowSectionByAlertType = groupBy(
          (rideComfortReportRowSection: RideComfortReportRowSection) => {
            return rideComfortReportRowSection.rideComfortAlertType.label;
          },
          rideComfortReportRowSections,
        );
        // Sum up the total incidents for each rideComfortReportRowSections
        const totalCountByAlertType = map(
          (rideComfortReportRowSectionOfAlertType: RideComfortReportRowSection[]) => {
            return reduce(
              (sum: number, rideComfortReportRowSection: RideComfortReportRowSection) => {
                return add(sum, rideComfortReportRowSection.rideComfortReportCount.count)
              },
              0,
              rideComfortReportRowSectionOfAlertType,
            );
          },
          rideComfortReportRowSectionByAlertType,
        );
        return mergeAll([
          // The first column is the date
          {time},
          // Next comes the count for each
          ...map((rideComfortReportRowSection: RideComfortReportRowSection): number => {
            const levelLabel =
              rideComfortAlertLevelToLabel[rideComfortReportRowSection.level];
            return {
              // Count for this alert type and level
              [`${rideComfortReportRowSection.rideComfortAlertType.label}_${levelLabel}`]:
              rideComfortReportRowSection.rideComfortReportCount.count,
              // Percent for this alert type and level
              [`${rideComfortReportRowSection.rideComfortAlertType.label}_${levelLabel}%`]:
                (
                  (rideComfortReportRowSection.rideComfortReportCount.count /
                    // Divide by the total incidents for this rideComfortAlertType.label
                    totalCountByAlertType[
                      rideComfortReportRowSection.rideComfortAlertType.label
                      ]) *
                  100
                ).toFixed(1),
            };
          }, rideComfortReportRowSections),
        ]);
      },
      rideComfortReportRowSectionsByTime,
    ),
  );
  // Convert the rowObjects to rows, with missing keys getting a default 0 value
  const rows: (string | number)[][] = map((obj: Object) => {
    return map((header: string) => {
      return propOr(0, header, obj);
    }, headers);
  }, objectRows) as (string | number)[][];

  exportRideComfortDataToExcel(
    typeObject<RideComfortExcelDownloadData>(CemitTypename.rideComfortExcelDownloadData, {
      headers,
      rows,
      rideComfortScopeProps,
    }),
  );
};
/**
 * Downloads RideComfortExcelDownloadData as an Excel workbook file
 * @param rideComfortDownloadData
 */
const exportRideComfortDataToExcel = async (
  rideComfortDownloadData: RideComfortExcelDownloadData,
) => {
  const name = rideComfortDownloadData.rideComfortScopeProps.trainInfo.trainId;
  const fileName = `ride_comfort_${name}.xlsx`;
  const wsInfo = XLSX.utils.json_to_sheet([
    {
      'Info': `Data for 71111 BFM

  Ride_Green: Number of green alerts corresponding to good ride comfort
  Ride_Yellow: Number of yellow alerts corresponding to reduced ride comfort
  Ride_Red: Number of red alerts corresponding to fatiguingly bad ride comfort
  Ride_Urgent: Number of urgent alerts corresponding to dangerously bad / crossing exposure limtis ride comfort

  Train_Green: Number of green alerts corresponding to good ride comfort related to the state of the train
  Train_Yellow: Number of yellow alerts corresponding to reduced ride comfort related to the state of the train
  Train_Red: Number of red alerts corresponding to fatiguingly bad ride comfort related to the state of the train
  Train_Urgent: Number of urgent alerts corresponding to dangerously bad / crossing exposure limtis ride comfort related to the state of the train

  Track_Green: Number of green alerts corresponding to good ride comfort related to the state of the track at location
  Track_Yellow: Number of yellow alerts corresponding to reduced ride comfort related to the state of the track at location
  Track_Red: Number of red alerts corresponding to fatiguingly bad ride comfort related to the state of the track at location
  Track_Urgent: Number of urgent alerts corresponding to dangerously bad / crossing exposure limtis ride comfort related to the state of the track at location

  The percentage columns show the distribution of alerts for each category.
    The data is based on the last 60 days.
    ISO 2631 provides guidance on measuring and assessing whole-body vibration (WBV) exposure during train travel.
    It sets exposure limits to ensure that vibration levels remain within acceptable bounds for passenger and operator comfort and health.
    The standard offers recommendations for designing trains and railway infrastructure to minimize WBV effects.
    It aims to optimize travel experience and reduce potential health risks associated with excessive vibration exposure.
  `,
    }
  ]);
  const ws = XLSX.utils.json_to_sheet(
    map(
      // For each row, create an object of {header1: cell1, header2: cell2, ...}
      (row: (string | number)[]) => {
        return mergeAll(
          zipWith(
            (header, cell) => {
              return {[header]: cell};
            },
            rideComfortDownloadData.headers,
            row,
          ),
        );
      },
      rideComfortDownloadData.rows,
    ),
  );
  const wb = XLSX.utils.book_new();

  // Make the single column huge
  wsInfo['!cols'] = [{wch: 400}];
  ;
  XLSX.utils.book_append_sheet(wb, wsInfo, 'Sheet1');

  // Make the time column larger, the others normal
  ws['!cols'] = [
    {wch: 24},
    ...times(always({wch: 12}), length(rideComfortDownloadData.headers) - 1),
  ];
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet2');
  XLSX.writeFile(wb, fileName);
};
