import { Stack, Tooltip } from '@mui/material';
import TrainRunLineContainer from 'components/apps/trainAppComponents/trainLineComponents/TrainRunLineContainer.js';
import TrainRunDescriptionContainer
  from 'components/apps/trainAppComponents/trainRunChooserComponents/TrainRunDescriptionContainer.js';
import { useTranslation } from 'react-i18next';
import { Train } from '@mui/icons-material';
import { strPathOr } from '@rescapes/ramda';
import LoaderWithText from 'components/loading/LoaderWithText.js';
import { lensPath, propOr, set } from 'ramda';
import ColorPicker from 'components/pickers/colorPicker.js';
import CaptionTypography from 'components/atoms/typography/CaptionTypography.js';

/**
 *  A TrainRunLine with specific user settings about the distance range of interest
 * @param appProps
 * @param organizationProps
 * @param trainProps
 * @param trainProps.userTrainRunIntervalProps
 * @param trainProps.userTrainRunIntervalProps.userTrainRunInterval
 * @param componentProps
 * @param {JSX.Element} [componentProps.activeIcon] Component that displays when UserTrainRunLine
 * when we are looking at active UserTrainRunLines
 * @param componentProps.showTrainRunLine Default false Don't show a TrainRunLine unless explicitly wanted
 * @param sxTrainRunIntervalBar
 * @returns {JSX.Element}
 * @constructor
 */
const UserTrainRunLine = (
  {
    appProps,
    organizationProps,
    trainProps,
    componentProps: {
      buttons,
      isSelectedTrainRuns,
      color,
      showTrainRunLine = false
    },
    sxTrainRunIntervalBar
  }) => {


  const { t } = useTranslation();
  const userTrainRunInterval = trainProps.userTrainRunIntervalProps.userTrainRunInterval;

  const iconSx = {
    color,
  };
  // Show a loading icon if the cdc data is loading for active UserTrainRunIntervals
  const isLoading = userTrainRunInterval && trainProps.trainRunIntervalProps.trainRunInterval.trainRun.loading;

  const loadingExplanation = userTrainRunInterval && !trainProps.trainRunIntervalProps.trainRunInterval.trainRun.error ? {
    [`trainProps.userTrainRunIntervalProps.sensorProps.trainRunIdWithSensorDataLookup[${userTrainRunInterval.trainRunInterval.trainRun.id}]`]: propOr(
      false,
      userTrainRunInterval.trainRunInterval.trainRun.id,
      trainProps.userTrainRunIntervalProps.sensorProps.trainRunIdWithSensorDataLookup
    )
  } : {};

  const isBaseline = strPathOr(false, 'activity.isBaseline', userTrainRunInterval)
  return <Stack {...{
    direction: 'row',
    key: userTrainRunInterval.sourceKey
  }} >
    <Stack {...{
      spacing: 0.5, direction: 'column', key: 'activeAndBaseline', sx: { minWidth: '55px', alignItems: 'center' }
    }}>
      <Tooltip arrow title={
        isBaseline ? t('baselineDescription') : t('userTrainRunIntervalDescription')
      }>
        <Stack {...{
          spacing: 0.5,
          direction: 'column',
          key: 'trainAndCaption',
          sx: { alignItems: 'center' }
        }}>
          <Train {...{  sx: iconSx }} />
          {isBaseline && <CaptionTypography {...{ color: 'secondary' }} >{t('baseline')}</CaptionTypography>}
        </Stack>
      </Tooltip>
      <ColorPicker {...{
        key: 'colorPicker',
        color,
        handleChangeComplete: color => {
          const modifiedUserTrainRunInterval = set(
            lensPath(['activity', 'isActiveColor']),
            color,
            userTrainRunInterval
          );
          trainProps.userTrainRunIntervalProps.crudUserTrainRunIntervals.updateOrCreate(modifiedUserTrainRunInterval);
        }
      }} />
      {isLoading && <LoaderWithText key='loader' {...{ text: '', spinner: true, loadingExplanation, size: 20 }} />}

    </Stack>
    <Stack
      key='userTrainRunInterval'
      spacing={1}
      sx={{
        width: isSelectedTrainRuns ? '90%' : '100%',
        color: 'secondary.main',
        borderRadius: 2,
        border: 1,
        padding: '4px'
      }}
    >
      {/* Selected TrainRun description */}
      <TrainRunDescriptionContainer {...{
        trainProps,
        componentProps: {
          buttons,
          userTrainRunInterval,
          showTrainRunLine
        }
      }} />

      {showTrainRunLine && <TrainRunLineContainer
        key='trainRunLine' {...{
        appProps,
        organizationProps,
        trainProps,
        componentProps: {
          // Setting this true cases removeIntermediate to be set true, which doesn't seem to have any point now
          onlyStopsNearInterval: false,
          spaceGeospatially: false,
          limitedDistanceRange: null,
          showTrainRunIntervalBars: false,
          isTrainRouteLine: false,
          isUserTrainRunLine: true
        },
        sxTrainRunIntervalBar
      }}
      />}
    </Stack>
  </Stack>;
};
export default UserTrainRunLine;