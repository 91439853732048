import React from 'react';
import ImuPointStatsComponent
  from 'components/apps/trainAppComponents/trainRunningCharacteristicsComponents/ImuPointStatsComponent.js';

/**
 *
 * @param trainProps
 * @param componentProps
 * @param componentProps.payloadItems The Recharts payload, which is a list of geojson features (usually 0 or 1) that the user
 * hovered over
 * @param componentProps.dataPathsConfigs
 * @param componentProps.userTrainRunIntervals
 * @param componentProps.referenceStopLookup
 * @returns {JSX.Element}
 * @constructor
 */
const ImuPointStatsComponentContainer = (
  {
    trainProps,
    componentProps: {
      payloadItems, dataPathsConfigs, referenceStopLookup
    }
  }) => {
  const loading = trainProps.userTrainRunIntervalProps.geojsonProps.loading;

  return !loading && <ImuPointStatsComponent {...{
    payloadItems,
    dataPathsConfigs,
    referenceStopLookup
  }} />;
};

export default ImuPointStatsComponentContainer;