import React from 'react';
import {IconButton, Stack, Typography} from '@mui/material';
import {Summarize} from '@mui/icons-material';
import {LoadingStatusEnum} from "../../../../../types/apis/loadingStatusEnum.ts";
import {CemitComponentProps} from "../../../../../types/propTypes/cemitComponenProps";
import {CEMIT_WHITE} from "../../../../../theme/colors.ts";

interface RideComfortExportExcelButtonProps extends CemitComponentProps {
  handleDownloadClick: () => void;
  rideComfortReportGraphqlStatus: LoadingStatusEnum;
}

const RideComfortExportExcelButton = ({
  handleDownloadClick,
}: RideComfortExportExcelButtonProps) => {
  return (
    <IconButton
      {...{
        sx: {
          display: 'flex',
          alignItems: 'flex-start',
          padding: '5px 12px',
          border: 'none',
          borderRadius: '2px',
          cursor: 'pointer',
          transition: 'background-color 0.3s, color 0.3s',
          marginBottom: '10px',
          outline: 'none',
        },
        onClick: handleDownloadClick,
      }}
    >
      <Stack {...{spacing: 1, direction: 'row'}}>
        <Summarize  {...{sx: {color: CEMIT_WHITE}}} />
        <Typography {...{variant: 'subtitle2', sx: {color: CEMIT_WHITE}}}>Download Report</Typography>
      </Stack>
    </IconButton>
  );
};

export default RideComfortExportExcelButton;
