import { compose, join, split, toLower } from 'ramda';

/**
 * Normalized navigation link names to be compatible as a URL path. This will be removed when we
 * removed URL paths in the future
 * @param name
 * @returns {*}
 */
export const normalizeLinkName = name => {
  return compose(join('_'), split(' '), toLower)(name);
};