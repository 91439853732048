import {
  all,
  always,
  any,
  chain,
  compose,
  cond,
  endsWith,
  equals,
  filter,
  is,
  length,
  map,
  mergeRight,
  pick,
  T,
  unless,
  values
} from 'ramda';
import {findOnlyOneOrThrow, headOrThrow} from '../railbedUtils/functional/functionalUtils.ts';
import {ClassFromObj} from '../railbedTypes/classes';
import {CemitTypename} from '../railbedTypes/cemitTypename.ts';
import {Cemited} from '../railbedTypes/cemited';
import {Perhaps} from '../railbedTypes/typeHelpers/perhaps';
import {chainObjToValues, toArrayIfNot} from '@rescapes/ramda';
import {CemitedClass, cemitTypeNameToClasses} from './cemitClasses.ts';

/**
 * Get the class or railbedClasses that the obj.__typename resolves to
 * @param obj
 */
export const cemitTypeObjectAsClasses = <T extends CemitedClass>(obj: Pick<T, '__typename'>) => {
  return toArrayIfNot(cemitTypeNameClassLookup<T>(obj) as ClassFromObj<T>);
};
/**
 * Resolve the class matching obj.__typename.
 * @param obj an object implemented Cemited so that it has a __typename
 * @returns The class
 */
export const cemitTypeObjectAsClass = <T extends CemitedClass>(obj: Pick<T, '__typename'>) => {
  const classes = cemitTypeObjectAsClasses<T>(obj);

  if (length(classes) > 1) {
    throw new Error(`cemitTypeObjectAsClassInstance called with an object ${obj.__typename} that resolves to multiple classes: ${map(cls => cls.name, classes)}. Limit
the object to one type before calling cemitTypeObjectAsClassInstance`);
  }
  return headOrThrow<ClassFromObj<T>>(classes);
};
/**
 * Resolve the class matching obj.__typename and instantiate it with obj.
 * This is useful for seeing what interfaces obj implements at runtime and in the future we will
 * probably convert all objects into railbedClasses with this
 * @param obj an object implemented Cemited so that it has a __typename
 * @returns an instantiation of a class matching the typename or thows if none exists yet
 */
export const cemitTypeObjectAsClassInstance = <T extends CemitedClass>(obj: T) => {
  const cls = cemitTypeObjectAsClass<T>(obj);
  return new cls(obj);
};
/**
 * Like cemitTypeObjectAsClassInstance but limits the the instantiated instance to the members of T.
 * This removes
 * @param downcastTypename
 * @param obj
 */
export const cemitTypeObjectAsClassInstanceDowncastedInstance = <T extends CemitedClass>(downcastTypename: CemitTypename, obj: T) => {
  const [cls, downcastObj] = _cemitTypeObjectAsClassInstanceDowncasted<T>(downcastTypename, obj);
  // Instantiate object as that class the limited attributes
  return new cls(downcastObj) as T;
};
/**
 * Downcasts to an object of the given type T. The downcastTypename must resolve to a class that is a base class of obj.__typename's resolved class
 * @param downcastTypename
 * @param obj
 */
export const cemitTypeObjectAsClassInstanceDowncastedObject = <T extends CemitedClass, F extends T = T>(downcastTypename: CemitTypename, obj: F) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_cls, downcastObj] = _cemitTypeObjectAsClassInstanceDowncasted<T>(downcastTypename, obj);
  // Just return the object
  return downcastObj as T;
};
export const _cemitTypeObjectAsClassInstanceDowncasted = <T extends CemitedClass>(downcastTypename: CemitTypename, obj: T):
  [ClassFromObj<T>, T] => {
  // Make sure obj implements the downcasted type
  implementsCemitTypeOrThrow(downcastTypename, obj);
  const objInstance = cemitTypeObjectAsClassInstance<T>(obj);
  // Get the class we want to downcast to
  const classes: ClassFromObj<T>[] = cemitTypeObjectAsClasses<T>({ __typename: downcastTypename });
  // If there are multiple railbedClasses that match the typename, find the one that obj.__typename extends
  const eligibleClasses = filter(
    clz => {
      return objInstance instanceof clz;
    },
    classes
  );
  if (length(eligibleClasses) != 1) {
    throw new Error(`Expected obj's corresponding class ${objInstance.prototype.name} to be a subclass of one of ${map(cls => cls.name, classes)}`);
  }
  const cls = headOrThrow(eligibleClasses);
  // Get the downcasted typename if downcastTypename resolved to multiple railbedClasses
  const typenameOfClass: CemitTypename = classToCemitTypeName(cls);

  // Get all attributes of the downcast class. We must instantiate it to access the class properties
  // We use Object.keys instead of Object.getOwnProperties to get inherited properties.
  const keys: string[] = Object.keys(new cls({ __typename: downcastTypename }));
  // Instantiate object as that class the limited attributes
  return [cls, mergeRight<T, Pick<T, '__typename'>>(
    pick<T, keyof T>(keys as (keyof T)[], obj) as T,
    { __typename: typenameOfClass }
  ) as T];
};
/**
 * Resolves the CemitTypename of the give Cemited class
 */
const classToCemitTypeName = compose(
  (pairs: [ClassFromObj<CemitedClass>, CemitTypename][]) => {
    // Create a function that expects a class and returns a CemitTypename
    return (cls: ClassFromObj<CemitedClass>): CemitTypename | never => {
      const results = filter(
        (pair: [ClassFromObj<CemitedClass>, cemitTypeName: CemitTypename]): boolean => {
          return equals(pair[0], cls);
        },
        pairs
      );
      if (!equals(1, length(results))) {
        throw new Error(`Expected class ${cls.name} to resolve to one CemitTypename, but got pairs ${results}`);
      } else {
        return headOrThrow(results)[1];
      }
    };
  },
  cemitTypeNameToClasses => {
    // Get pairs of [cls, cemitTypename]
    return chainObjToValues(
      (classes: ClassFromObj<CemitedClass>, cemitType: CemitTypename) => {
        return map<ClassFromObj<CemitedClass>, [ClassFromObj<CemitedClass>, CemitTypename]>(
          cls => {
            return [cls, cemitType];
          },
          // Only process CemitTypenames that resolve to one class. Those resolving to multiple
          // are railbedClasses are Or railbedTypes and must have separate listing for the individual railbedTypes
          unless<ClassFromObj<Cemited>[], ClassFromObj<Cemited>[]>(
            compose(equals(1), length), always([])
          )(toArrayIfNot(classes))
        );
      },
      cemitTypeNameToClasses
    );
  })(cemitTypeNameToClasses);
/**
 * Maps obj.__typename to all Cemit railbedClasses we have implemented that extend Cemit interfaces.
 * The railbedClasses never do more than accept all the properties of the interface.
 * @param obj
 */
export const cemitTypeNameClassLookup = <R extends CemitedClass>(obj: Pick<R, '__typename'>): ClassFromObj<R> | ClassFromObj<R>[] | never => {
  return cond<[CemitTypename], ClassFromObj<R> | ClassFromObj<R>[] | never>([
      [
        (typename: CemitTypename) => {
          return cemitTypeNameToClasses[typename];
        },
        (typename: CemitTypename) => {
          return cemitTypeNameToClasses[typename];
        }
      ],
      [T, () => {
        throw Error(`Class implementation for ${obj.__typename} not yet implemented. Add it in cemitClasses.ts`);
      }]
    ]
  )(obj.__typename) as ClassFromObj<R> | never;
};

/**
 * All unique Cemit railbedClasses registered in cemitTypeNameToClasses
 */
export const cemitClasses: ClassFromObj<CemitedClass>[] = chain(
  cls => {
    // Only process CemitTypenames that resolve to one class. Those resolving to multiple
    // are railbedClasses are 'Or' railbedTypes and must have separate listing for the individual railbedTypes
    return unless<ClassFromObj<Cemited>[], ClassFromObj<Cemited>[]>(
      compose(equals(1), length), always([])
    )(toArrayIfNot(cls))
  },
  values(cemitTypeNameToClasses)
);

/**
 * Returns true if the given obj implements typename. The object is converted to the class of its typename to
 * see if it inherits from the class of typenmae
 * TODO make this curryable with ramda's curry. I can't find good documentation on how to pass railbedTypes to a curry
 * call. See https://www.freecodecamp.org/news/typescript-curry-ramda-types-f747e99744ab/
 * @param typename
 * @param obj
 */
export const implementsCemitType = <T extends CemitedClass>(typename: CemitTypename, obj: Perhaps<Cemited>): never | boolean => {
  const classes: ClassFromObj<T>[] = toArrayIfNot(cemitTypeNameClassLookup({ __typename: typename }));
  if (!obj) {
    return false;
  }
  return any(cls => is(cls, cemitTypeObjectAsClassInstance(obj)), classes);
};

/**
 * Returns true if all objs implement the given typename
 * @param typename
 * @param objs
 */
export const allImplementCemitType = <T extends CemitedClass>(typename: CemitTypename, objs: Perhaps<Cemited[]>): never | boolean => {
  return _allOrAnyImplementCemitType<T>(all, typename, objs)
}
/**
 * Returns true if any obj implements the given typename
 * @param typename
 * @param objs
 */
export const anyImplementCemitType = <T extends CemitedClass>(typename: CemitTypename, objs: Perhaps<Cemited[]>): never | boolean => {
  return _allOrAnyImplementCemitType<T>(any, typename, objs)
}
type AllOrAny = <T, U extends { all: (fn: (a: T) => boolean) => boolean }>(fn: (a: T) => boolean, obj: U) => boolean;

/**
 * Calls implementsCemitType on each item of objs
 * @param allOrAny ramda.all or ramda.any
 * @param typename
 * @param objs
 */
export const _allOrAnyImplementCemitType = <T extends CemitedClass>(allOrAny: AllOrAny, typename: CemitTypename, objs: Perhaps<Cemited[]>): never | boolean => {
  const classes: ClassFromObj<T>[] = toArrayIfNot(cemitTypeNameClassLookup({ __typename: typename }));

  if (!objs) {
    return false;
  }
  return allOrAny(
    (obj: Cemited) => {
      // Get the corresponding class of the obj based on its __typename
      if (!obj) {
        return false;
      }
      const instance = cemitTypeObjectAsClassInstance<T>(obj as T);
      // See if objClass is a subclass of any of railbedClasses
      return any(clz => {
        return instance instanceof clz;
      }, classes);
    },
    objs
  );
};
export const cemitTypeError = <T extends CemitedClass>(expectedTypename: CemitTypename, obj: Perhaps<T>): never | boolean => {
  throw new Error(`Expected CemitTypename: ${expectedTypename}. Got ${obj ? obj.__typename : 'obj is undefined'}`);
};
/**
 * Calls cemitTypeImplements and if false calls cemitTypeError. Else returns true
 * @param typename
 * @param obj
 */
export const implementsCemitTypeOrThrow = (typename: CemitTypename, obj: Perhaps<Cemited>): never | boolean => {
  if (!implementsCemitType(typename, obj)) {
    cemitTypeError(typename, obj);
  }
  return true;
};
/**
 * Like implementsCemitTypeOrThrow but returns obj as type T
 * @param typename
 * @param obj
 */
export const asCemitTypeOrThrow = <T extends Cemited>(typename: CemitTypename, obj: Perhaps<Cemited>): never | T => {
  if (!(obj && implementsCemitType(typename, obj))) {
    cemitTypeError(typename, obj);
  }
  return obj as T;
};
/**
 * Returns obj as T if it implements typename, else undefined
 * @param typename
 * @param obj
 */
export const maybeAsCemitType = <T extends Cemited>(typename: CemitTypename, obj: Perhaps<Cemited>): Perhaps<T> => {
  if (!(obj && implementsCemitType(typename, obj))) {
    return undefined;
  }
  return obj as T;
};

/**
 * Finds the class that extends obj's corresponding class and implements AsDerived.
 * Since javascript has no Interfaces and the typescript Interfaces are gone at runtime, this
 * simply checks that the class name conforms to the standard of ending with 'WithDerivedClass'
 * @param obj
 */
export const wihtDerivedTypeOf = (obj: Cemited): CemitTypename | never => {
  const objectAsClass = cemitTypeObjectAsClass(obj);
  const asDerivedClass = findOnlyOneOrThrow(
    (cls: ClassFromObj<CemitedClass>) => {
      return cls.prototype instanceof objectAsClass && endsWith('WithDerivedClass', cls.name);
    },
    cemitClasses
  );
  return classToCemitTypeName(asDerivedClass);
};
