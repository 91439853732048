import { DatePicker, PickersDay } from '@mui/x-date-pickers';
import { Box, IconButton, InputAdornment, Stack, TextField, Tooltip } from '@mui/material';
import {
  addIndex, all, always,
  equals,
  head,
  includes,
  last,
  lensPath,
  lensProp,
  map,
  mergeRight,
  over,
  props as rProps, unless
} from 'ramda';
import { useTranslation } from 'react-i18next';
import { endOfDay, format, getDate, startOfDay } from 'date-fns';
import { trainDataFriendlyDateFormatString } from 'appUtils/trainAppUtils/trainDataUtils.js';
import { CalendarToday, DateRange } from '@mui/icons-material';
import CaptionTypography from 'components/atoms/typography/CaptionTypography.js';

/**
 * Override of the DatePick text field to show the date range
 * @param {Object} dateRange The current dateRange of the calendar
 * @param {Function} onChange Used by the most recent date button to update the calendar to the most recent date
 * @param {Function} handleAddDateRangeToFilter Used by the most recent date button to update application's dateRange
 * @param {Object} mostRecentDate most recent date with data
 * @returns {JSX.Element}
 * @constructor
 */
const DateRangeChooserTextField = (
  {
    dateRange,
    onChange,
    handleAddDateRangeToFilter,
    mostRecentDate,
    ...props
  }) => {
  const { t } = useTranslation();
  const alreadyAtMostRecentDate = all(date => equals(mostRecentDate, startOfDay(date)), rProps(['start', 'end'], dateRange));
  const textFields = addIndex(map)(
    (date, index) => {
      const isTop = equals(0, index);
      const updatedProps = over(
        lensPath(['InputProps', 'endAdornment']),
        component => {
          if (isTop) {
            // Just wrap the InputAdornment with a Tooltip. TODO ideally the tooltip wrap the child button not
            // the InputAdornment
            return <Tooltip key='calendarDayTooltip' title={t('chooseDateRange')}>
              {component}
            </Tooltip>;
          } else {
            // Override InputAdornment to be a set-to-most-recent-date button
            return <InputAdornment {...{
              position: 'end', sx: {
                '& 	.MuiInputAdornment-filled': {
                  color: 'red'
                },
                '& 	.MuiInputAdornment-standard': {
                  color: 'blue'
                }
              }
            }} >
              <Tooltip key='calendarDayTooltip' arrow
                       title={alreadyAtMostRecentDate ? t('atMostRecentDate') : t('mostRecentDateUpdate')}>
                <Box>
                  <IconButton {...{
                    // Disable if the start and date match mostRecentDate
                    disabled: alreadyAtMostRecentDate,
                    edge: 'end',
                    sx: {
                      color: 'red'
                    },
                    onClick: () => {
                      onChange(mostRecentDate, true);
                      handleAddDateRangeToFilter({ start: startOfDay(mostRecentDate), end: endOfDay(mostRecentDate) });
                    }
                  }}>

                    <CalendarToday {...{
                      // Disable if the start and date match mostRecentDate
                      color: alreadyAtMostRecentDate ? 'disabled' : 'primary'
                    }} />
                    <Box {...{
                      position: 'absolute',
                      // Positions the number in the frame of the calendar icon
                      top: '20%',
                      width: '100%', height: '100%'
                    }}>
                      <CaptionTypography {...{ color: 'primary' }}>{getDate(mostRecentDate)}</CaptionTypography>
                    </Box>
                  </IconButton>
                </Box>
              </Tooltip>
            </InputAdornment>;
          }
        },
        unless(always(isTop), props => mergeRight(props, { label: t('to') }))(props)
      );

      // This can be the top or bottom date range chooser
      return <TextField key={isTop ? 'start' : 'end'} {...{
        size: 'small',
        margin: 'dense',
        sx: {
          width: '200px',
          label: { color: 'secondary.main' },
          input: { color: 'secondary.main', verticalAlign: 'middle' },
          svg: isTop ? { color: 'secondary.main' } : { color: alreadyAtMostRecentDate ? 'disabled' : 'primary.main' }
        },
        ...over(
          lensProp('inputProps'),
          inputProps => {
            // Override value to be the dateRange
            return mergeRight(inputProps, {
              value: format(date, trainDataFriendlyDateFormatString),
              readOnly: true
            });
          },
          updatedProps
        )
      }} />;
    },
    rProps(['start', 'end'], dateRange)
  );
  return <Stack>{textFields}</Stack>;
};

/***
 * Shows a display that allows adding a DateRange the TrainRunFilter
 * @param onChange
 * @param noDataForAnyDate
 * @param availableDates
 * @param dateRange
 * @param setDateRange
 * @param currentDateRange
 * @param handleAddDateRangeToFilter
 * @param datesInDateRange
 * @returns {JSX.Element}
 * @constructor
 */
const DateRangeChooser = (
  {
    onChange,
    noDataForAnyDate,
    availableDates,
    dateRange,
    setDateRange,
    currentDateRange,
    handleAddDateRangeToFilter,
    datesInDateRange
  }) => {

  const { t } = useTranslation();
  const mostRecentDate = last(availableDates);
  return <Stack direction='column' spacing={2}>
    <DatePicker {...{
      components: {
        OpenPickerIcon: DateRange
      },
      closeOnSelect: false,
      label: t('dateRange'),
      disabled: noDataForAnyDate,
      disableFuture: true,
      minDate: head(availableDates),
      maxDate: last(availableDates),
      value: dateRange.start,
      // When the user clicks away, save the dateRange on the calender.
      // If the clicked cancel, this will have been reverted to currentDateRange
      onClose: () => {
        handleAddDateRangeToFilter(dateRange);
      },
      // Accept the change
      onAccept: () => {
        handleAddDateRangeToFilter(dateRange);
      },
      // Cancel the change
      onCancel: () => {
        setDateRange(currentDateRange);
      },
      // Selects the new date range based on the single value clicked by the user
      onChange,
      componentsProps: {
        actionBar: {
          // The actions will be the same between desktop and mobile
          actions: ['cancel', 'accept']
        }
      },
      renderDay: (day, selectedDays, pickersDayProps) => {
        let selectedMuiClass = '';

        // Pseudo-code here! You will have to use the proper functions from the
        // date-fns library to evaluate if 'day' is in your dateArray.
        if (includes(day, datesInDateRange)) {
          selectedMuiClass = 'Mui-selected';
        }

        return <Tooltip key={day} arrow title={t('dateRangeChooserHelp')}>
          <Box key='tooltipBox'>
            <PickersDay {...{
              key: 'pickersDay',
              className: selectedMuiClass,
              onClick: event => {
                // Hack to allow select the start date only by double clicking
                if (event.detail === 2) {
                  onChange(dateRange.start);
                }
              },
              ...pickersDayProps
            }}
            />
          </Box>
        </Tooltip>;
      },
      renderInput: params => {
        return <DateRangeChooserTextField {...{
          dateRange,
          handleAddDateRangeToFilter,
          onChange,
          mostRecentDate,
          ...params
        }} />;
      }
    }} />

  </Stack>;
};

export default DateRangeChooser;