import { Box, Stack, Typography } from '@mui/material';
import { map } from 'ramda';
import { trainRunCollectionDevices } from 'appUtils/trainAppUtils/trainUtils.js';
import { useTranslation } from 'react-i18next';

/**
 * A train vehicle description from the CDCs
 * @param showTitle Default true. Show the title
 * @param trainRun
 * @returns {JSX.Element}
 * @constructor
 */
export const TrainDescription = ({ showTitle=true, trainRun  }) => {
  const {t} = useTranslation()
  return <Stack direction='row'
                spacing={2}>
    {showTitle && <Box sx={{ width: '40px' }}>
      <Typography key='equipmentTitle' variant='caption' sx={{ color: 'grey.400' }}>
        {t('train')}
      </Typography>
    </Box>}
    <Stack key='vehicles' direction='row' spacing={1} sx={{ color: 'secondary.main' }}>
      {
        map(
          ({ vehicle, collectionDevice }) => {
            return <Typography variant='caption' key={collectionDevice.name}>{vehicle.name}</Typography>;
          },
          trainRunCollectionDevices(trainRun)
        )
      }
    </Stack>
  </Stack>;
};