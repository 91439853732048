import { useTranslation } from 'react-i18next';
import { allPass, always, complement, cond, equals, map, prop, T } from 'ramda';
import { Box, Button } from '@mui/material';
import { strPathOr } from '@rescapes/ramda';
import { trainAppNavigationConfig } from 'pages/trainApp/trainAppConfig/trainAppNavigationConfig.js';
import { normalizeLinkName } from 'utils/navigation/navigationUtils.js';

/**
 * Renders links to TrainApp pages.
 * TODO Clean up the components
 * @param currentAppPage
 * @return {JSX.Element}
 * @constructor
 */
const TrainAppPageLinks = ({ currentAppPage }) => {
  const { t } = useTranslation();
  const navigationSet = trainAppNavigationConfig({ t });

  return <Box component='nav' sx={{
    maxHeight: '100vh',
    width: '100%',
    display: 'flex',
    marginBottom: '10px',
    borderBottom: '1px solid rgba(255, 255, 255, 0.25)'
  }}>
    {map(navigation => {
      const selected = equals(...map(normalizeLinkName, [navigation?.name, currentAppPage]));
      const color = cond([
        // If selected always use the primary color
        [prop('selected'), always('primary')],
        // If otherwise enabled use the secondary color
        [
          allPass([
            strPathOr(false, 'navigation.access'),
            complement(strPathOr)(false, 'disabled')
          ]),
          always('secondary')
        ],
        // This will use the disabled color because of the disabled flag below
        [T, always('primary')]
      ])({ selected, navigation });

      const normalizedName = normalizeLinkName(navigation.name);
      return (
        <Button key={navigation.id} {...{
          href: normalizedName,
          sx: {
            padding: '15px 25px',
            fontWeight: 600,
            fontSize: '16px',
            position: 'relative'
          },
          color,
          name: navigation.name,
          disabled: navigation.disabled
        }}>
          {navigation.name}
        </Button>
      );
    }, navigationSet)}
  </Box>;
};

export default TrainAppPageLinks;
