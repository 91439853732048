import {simplyFetchFromGraph} from '../../appUtils/rideComfortUtils/graphqlQueryUtils.ts';
import {join, keys, map, mapObjIndexed, mergeRight, values} from 'ramda';
import {formatISO, subMonths} from 'date-fns';
import {RideComfortIntervalDescription} from '../../types/rideComfort/rideComfortintervalDescription';
import {
  RideComfortGraphqlResponseReportData,
  RideComfortReportData,
} from '../../types/rideComfort/rideComfortMapData';
import {levelToAttribute} from '../../types/rideComfort/rideComfortAlertLevel.ts';
import {RideComfortLevels} from '../../types/rideComfort/rideComfortAlertLevels';
import {DateInterval} from 'types/propTypes/trainPropTypes/dateProps';
import {VehicleCollectionDevice} from '../../types/sensors/vehicleCollectionDevice';
import {
  RideComfortAlertType,
  RideComfortAlertTypeValue,
} from '../../types/rideComfort/rideComfortAlertType';
import {
  RideComfortAlertTypeKey,
  RideComfortAlertTypeLabels,
} from '../../types/rideComfort/rideComfortAlertTypeKey.ts';
import {CemitTypename} from "../../visualizer-railbed/railbedTypes/cemitTypename.ts";
import {typeObject} from "../../visualizer-railbed/railbedAppUtils/typeUtils/typenameUtils.ts";
import {RideComfortTrainInfo} from "../../types/rideComfort/rideComfortTrainInfo";

const stringifyDate = (date: Date) => {
  return formatISO(date);
};

/**
 * Creates several graphql queries that are sent together to the server.
 * The first 3 are aggregations and the second 3 are individual results with lat/lon
 * @param dateInterval We use the duration along with dateInterval.end to make the start and end date
 * If we are query for a TrainRun, we use dateInterval.start and dateInterval.end
 * @param dateInterval
 * @param trainInfo
 */
export async function getRideComfortReportGraphqlData(
  dateInterval: DateInterval,
  trainInfo: RideComfortTrainInfo,
): Promise<RideComfortGraphqlResponseReportData[]> {
  const levels: (keyof RideComfortLevels)[] = keys(levelToAttribute);
  const {alertPointId, trackPointId, trainPointId} = trainInfo;
  const rideComfortAlertTypes: RideComfortAlertType[] = values(
    mapObjIndexed(
      (value: string, alertPointKey: RideComfortAlertTypeKey) => {
        return typeObject<RideComfortAlertTypeValue>(CemitTypename.rideComfortAlertType, {
          label: RideComfortAlertTypeLabels[alertPointKey],
          alertPointKey,
          value,
        });
      },
      {alertPointId, trackPointId, trainPointId},
    ),
  );

  // TODO The calculatedDateInterval is based on the chosen map view, which is confusing
  // but calculatedDateInterval is only used for the aggregate query for heatmap dat
  const {calculatedDateInterval, window} = getRideComfortReportDateInterval(
    dateInterval,
  );

  return await Promise.all(
    map(async (rideComfortAlertType: RideComfortAlertTypeValue) => {
      const signalAggregations = map((level) => {
        const {start: fromDate, end: toDate} = calculatedDateInterval;
        const [fromStr, toStr] = map(stringifyDate, [fromDate, toDate]);

        return `${level}: signalsAggregation(
      where: { pointId:"${rideComfortAlertType.value}", type: "${level}", unit: GENERIC }
      aggregate: {
        from:"${fromStr}"
        to: "${toStr}"
        window: ${window.label}
      }
    ) {
      time
      count
    }`;
      }, levels);

      const pointsData = `
  query p_comfort_alerts_stats_last_X_days {
    ${join('\n', signalAggregations)}
}
  `;
      try {
        const {data} = await simplyFetchFromGraph({
          query: pointsData,
        });
        return typeObject<RideComfortGraphqlResponseReportData>(
          CemitTypename.rideComfortGraphqlResponseReportData,
          {
            data: typeObject<RideComfortReportData>(CemitTypename.rideComfortReportData, {
              ...data,
              rideComfortAlertType,
            }),
          },
        );
      } catch (error) {
        return undefined;
      }
    }, rideComfortAlertTypes),
  );
}

export const getRideComfortReportDateInterval = (
  dateInterval: DateInterval,
) => {
  // Only two months is currently available for reports
  // TODO DAY_1 is intentional
  const window = {label: 'DAY_1', value: 60 * 60 * 24 * 60};

  const fromDate = subMonths(dateInterval.end, 2);
  const toDate = dateInterval.end;

  return {
    calculatedDateInterval: typeObject<DateInterval>(CemitTypename.dateInterval, {
      start: fromDate,
      end: toDate,
    }),
    window,
  };
};
