import { TrainRouteOrGroupServiceDescription } from 'components/apps/trainAppComponents/trainRunChooserComponents/TrainRouteOrGroupServiceDescription.js';
import { isTrainRouteGroup, trainRouteOrGroupName } from 'appUtils/trainAppUtils/trainRouteUtils.js';
import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FLYTOGET_GRAY, FLYTOGET_GRAY_DARKENED } from 'appConfigs/trainConfigs/trainConfig.js';
import { mergeRight } from 'ramda';

/**
 * Displays the TrainRoute or TrainRouteGroup label, including the serviceLine name and a
 * name describing the origin and destination
 * @param trainRouteOrGroup
 * @returns {JSX.Element}
 * @constructor
 */
const TrainRouteOrGroupLabel = ({ trainRouteOrGroup }) => {
  const { t } = useTranslation();
  const name = trainRouteOrGroupName({ t }, trainRouteOrGroup);
  // TODO configure the color by service_line type and/or operator
  const sxBox = mergeRight(
    { bgcolor: FLYTOGET_GRAY },
    isTrainRouteGroup(trainRouteOrGroup) ? {
      boxShadow: `5px 5px 0 ${FLYTOGET_GRAY_DARKENED}`
    } : {}
  );
  return <Stack {...{ direction: 'row', spacing: 1, sx: { alignItems: 'center' } }}>
    <TrainRouteOrGroupServiceDescription
      key='trainRouteServiceLineDescription' {...{
      showTitle: false,
      // TODO define trainRouteGroup.serviceLine or trainRouteGroup.operator.servicePrefix
      serviceLine: trainRouteOrGroup.serviceLine || { name: 'FLY' },
      sxBox
    }} />
    <Typography>{name}</Typography>
  </Stack>;

};
export default TrainRouteOrGroupLabel;