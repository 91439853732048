import { includes, length } from 'ramda';
import {
  addDays,
  differenceInCalendarDays,
  eachDayOfInterval,
  endOfDay,
  max,
  min,
  startOfDay,
  subDays
} from 'date-fns';
import { useEffect, useState } from 'react';
import { unlessLoadingProps } from 'utils/componentLogic/loadingUtils.js';
import DateRangeChooser from 'components/apps/trainAppComponents/dateRangeChooserComponents/DateRangeChooser.js';
import { MAX_DAYS } from 'appConfigs/appConfig.js';
import Loading from 'components/loading/index.js';


/**
 * Shows a display that allows adding a DateRange the TrainRunFilter
 * @param trainProps
 *
 * @param addDateRangeToFilter
 * @returns {JSX.Element}
 * @constructor
 */
const DateRangeChooserContainer = ({ trainProps, currentDateRange, handleAddDateRangeToFilter }) => {
  const availableDates = trainProps.dateProps.availableDates;
  // This local state lets us call addDateRangeToFilter once both calendars are filled out
  // We currently only expect one date range from the filters

  const [dateRange, setDateRange] = useState(null);
  const [explicitChoice, setExplicitChoice] = useState(false);

  const loading = !dateRange;
  // Update the dateRange to currentDateRange, which
  // defaults to the latest date with date or today if there is no data at all,
  // until the user chooses something explicitly
  useEffect(() => {
      if (!explicitChoice && dateRange !== currentDateRange) {
        setDateRange(currentDateRange);
      }
    },
    [availableDates, currentDateRange]
  );

  const { datesInDateRange, noDataForAnyDate } = unlessLoadingProps(loading, () => {
    const datesInDateRange= eachDayOfInterval(dateRange)
    return { datesInDateRange, noDataForAnyDate: !length(datesInDateRange) };
  });

  /**
   * Updates the first, last or both dates of dateRange
   * @param newValue
   * @parm {Boolean} forceBoth Default false. Force the start and end dates to be the newValue
   */
  const onChange = (newValue, forceBoth = false) => {
    setExplicitChoice(true);
    if (forceBoth) {
      setDateRange({
        start: startOfDay(newValue),
        end: endOfDay(newValue)
      });
    }
    else if (newValue < dateRange.start) {
      if (differenceInCalendarDays(newValue, dateRange.start) > MAX_DAYS) {
        // If moved more than a week behind the previous, set 1 day
        setDateRange({
          start: startOfDay(newValue),
          end: endOfDay(newValue)
        });
      }
      // Shift the start back to the newValue
      setDateRange({
        start: startOfDay(newValue),
        end: min([endOfDay(addDays(newValue, MAX_DAYS)), dateRange.end])
      });
    } else if (newValue > dateRange.end) {
      if (differenceInCalendarDays(newValue, dateRange.end) > MAX_DAYS) {
        // If moved more than a week ahead the previous, set 1 day
        setDateRange({
          start: startOfDay(newValue),
          end: endOfDay(newValue)
        });
      }
      // Shift the end forward to the newValue
      setDateRange({
        start: max([startOfDay(subDays(newValue, MAX_DAYS)), dateRange.start]),
        end: endOfDay(newValue)
      });
    } else if (includes(newValue, [dateRange.start, startOfDay(dateRange.end)])) {
      setDateRange({ start: startOfDay(newValue), end: endOfDay(newValue) });
    } else {
      // If its inside the range but not at the end, trim the farthest of start or end, favoring end if equal
      if (
        (startOfDay(newValue) - startOfDay(dateRange.start)) <
        (startOfDay(dateRange.end) - startOfDay(newValue))) {
        // If near the start or middle, trim the end
        setDateRange({ start: dateRange.start, end: endOfDay(newValue) });
      } else {
        // If nearer the end, trim the start
        setDateRange({ start: startOfDay(newValue), end: dateRange.end });
      }
    }
  };

  return loading ?
    <Loading /> :
    <DateRangeChooser {...{
      key: 'dateRangeChoose',
      onChange,
      noDataForAnyDate,
      availableDates,
      dateRange,
      setDateRange,
      currentDateRange,
      handleAddDateRangeToFilter,
      datesInDateRange
    }}
    />;
};

export default DateRangeChooserContainer;