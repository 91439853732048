import { TRAIN_API, trainApiGetUrl } from 'appConfigs/trainConfigs/trainConfig.js';
import { fetcher } from 'pages/trainApp/api/TrainSWRContainer.js';
import { ERROR_NO_IMU_POINTS, STATUS_PROPS } from 'lib/fetch/cemitApi/config.js';
import {
  always,
  any,
  chain,
  compose,
  head,
  indexBy,
  join,
  length, lensProp,
  map,
  mergeRight,
  mergeWith,
  none,
  omit, or, over,
  prop,
  props,
  values, when
} from 'ramda';
import { mergeTrainRun } from 'appUtils/trainAppUtils/typeMerging/trainRunMerging.js';
import { distanceIntervalIndexForTrainRunId } from 'appUtils/trainAppUtils/trainRunUtils.js';
import { hashDistanceRange } from 'utils/distance/distanceUtils.js';

/**
 * Requests ImuPoint data for the given TrainRun at the given distanceInterval for the given
 * distanceRanges, where the ranges align with the trainRun's TrainRoute
 * @param trainRunId The TrainRun id to request ImuPoints for
 * @param distanceInterval The current distanceInterval (e.g. 100 for 100 meters between points)
 * @param {[Object]} requestDistanceRanges List of distance ranges for the distance interval instruction the
 * API what range the user wants to look at relative to meters from the start of the TrainRoute to the end.
 * E.g. [{start: 0, end: 89000}]
 * @param {Object} trainRun Currently just for missing data handling
 * @param {[Object]} trainRunIdsRequestedWithImuPoints: Keeps track of loading of imuPoints for trainRuns
 in the form [{ trainRunId, distanceInterval, distanceRanges }, ...]
 where distanceInterval is the distance interval we requested ImuPoints at, such as 100 meters or 50 meters,
 and distanceRanges are the ranges requested at that interval. They are always in order by trainRoute distance
 and consolidated to not overlap.
 Each distanceInterval object can be marked with distanceRangeErrors containing distanceRange
 requests that errored.
 * @param {Function} setTrainRunIdsRequestedWithImuPoints Only used on error to force a rerequest
 */
export const trainApiTrainRunWithImuPoints = (
  {
    trainRunId, distanceInterval, distanceRanges, trainRun,
    trainRunIdsRequestedWithImuPoints,
    setTrainRunIdsRequestedWithImuPoints,
    setMinimumTrainRunsWithImuPoints
  }
) => {

  const url = trainApiGetUrl({
    route: `${TRAIN_API.TRAIN_RUNS.route}/${TRAIN_API.TRAIN_RUNS.paths.withImuPoints}`,
    query: {
      id: trainRunId,
      // The requested distance interval. The default is currently 100 meters
      distanceInterval,
      // If distanceRanges is non-null, split them into a string of start, end so that the API can handle them
      // If null, we want to query for the TrainRun's entire range at this distanceInterval
      // this results in 'start1,end1,start2,end2,...'
      ...distanceRanges ? { distanceRanges_in: join(',', chain(props(['start', 'end']), distanceRanges)) } : {}
    }
  });
  fetcher({ timeout: 15000 }, url).then(minimumTrainRuns => {
      // If the request doesn't return minimumTrainRuns, it means an error occurred
      // Try to recover by downloading again
      const isError = any(prop('error'), minimumTrainRuns);
      const noDataEver = none(length, values(minimumTrainRuns[0].imuPoints)) && !any(length, values(trainRun.imuPoints || {}));
      const updatedMinimumTrainRuns = when(
        always(or(isError, noDataEver)),
        () => {
          // Assume just one TrainRun for now
          console.warn(`Could not fetch any ImuPoint data for 
       TrainRun id: ${trainRunId},
       distanceInterval: ${distanceInterval} meters, 
       distanceRanges: ${JSON.stringify(distanceRanges, 2, null)}`
          );

          const _updatedMinimumTrainRuns = [{
            id: trainRunId,
            error: noDataEver ? ERROR_NO_IMU_POINTS : head(minimumTrainRuns).error.message,
            errorDate: noDataEver ? Date.now() : head(minimumTrainRuns).errorDate
          }];

          // In the distanceInterval object, update distanceRangeErrors to include the distanceRanges that errored
          // This tells us when we retry to try to load those again
          const errorTrainRunIdRequestedWithImuPoints = mergeInDistanceRangeErrors(
            { distanceInterval, distanceRanges, trainRunIdsRequestedWithImuPoints, trainRunId }
          );
          // Update only this TrainRun to mark the errors
          setTrainRunIdsRequestedWithImuPoints(current => {
            return mergeRight(current, { [trainRunId]: errorTrainRunIdRequestedWithImuPoints });
          });

          return _updatedMinimumTrainRuns;
        }
      )(minimumTrainRuns);

      // Merge the downloaded updatedMinimumTrainRuns with the successfully downloaded
      // distanceIntervalsAndRanges if there was no error
      const incomingMinimumTrainRuns = isError ? updatedMinimumTrainRuns : map(
        updatedMinimumTrainRun => {
          return mergeRight(
            updatedMinimumTrainRun,
            {
              distanceIntervalsAndRanges: [{
                distanceInterval,
                distanceRanges
              }]
            });
        },
        updatedMinimumTrainRuns
      );

      // Merge the loaded minimumTrainRuns
      setMinimumTrainRunsWithImuPoints(minTrainRunsWithImuPointsSetter(incomingMinimumTrainRuns));
    }
  );
};

/**
 * Merging setter code for setMinimumTrainRunsWithImuPoints
 * @param incomingMinimumTrainRuns
 * @returns {function(*): *}
 */
const minTrainRunsWithImuPointsSetter = incomingMinimumTrainRuns => existingMinimumTrainRuns => {
  // Merge what we just fetched with the existing
  const mergedMinimumTrainRuns = compose(
    mergedById => {
      // Removed the ids
      return values(mergedById);
    },
    ([a, b]) => {
      // Merge by TrainRun id
      return mergeWith(
        (existingTrainRun, incomingTrainRun) => {
          // Return the success case or other errors, the latter where we merge the error prop in
          return mergeTrainRun(
            omit(STATUS_PROPS, existingTrainRun),
            incomingTrainRun
          );
        },
        a, b
      );
    },
    // Index by TrainRun id
    map(indexBy(prop('id')))
  )([existingMinimumTrainRuns, incomingMinimumTrainRuns]);
  return mergedMinimumTrainRuns;
};

/**
 * In the distanceInterval object, update distanceRangeErrors to include the distanceRanges that errored
 * This tells us when we retry to try to load those again
 * @param distanceInterval
 * @param distanceRanges
 * @param trainRunIdsRequestedWithImuPoints
 * @param trainRunId
 * @returns {*}
 */
function mergeInDistanceRangeErrors(
  {
    distanceInterval,
    distanceRanges,
    trainRunIdsRequestedWithImuPoints,
    trainRunId
  }) {
  const distanceIntervalIndex = distanceIntervalIndexForTrainRunId({
    trainRunIdsRequestedWithImuPoints,
    trainRunId,
    distanceInterval
  });

  return over(
    lensProp(distanceIntervalIndex),
    obj => {
      const distanceRangeHashToDistanceRange = indexBy(
        distanceRange => hashDistanceRange(distanceRange),
        distanceRanges
      );
      return over(
        lensProp('distanceRangeErrors'),
        distanceRangeErrors => {
          return mergeRight(distanceRangeErrors, distanceRangeHashToDistanceRange);
        },
        obj
      );
    },
    trainRunIdsRequestedWithImuPoints[trainRunId]
  );
}