import {Stack, Typography} from "@mui/material";
import RideComfortGaugeComponent from "./RideComfortGaugeComponent.tsx";
import React, {useRef} from "react";
import {tempColors} from "../../../../theme/tempColors.ts";
import {toArrayIfNot} from "@rescapes/ramda";
import RideComfortErrorLevelsComponent from "./RideComfortErrorLevelsComponent.tsx";
import {RideComfortGauge, RideComfortGaugeByTimePeriod} from "../../../../types/rideComfort/rideComfortGauge";
import {SxProps} from "@mui/system";
import {RideComfortProps} from "../../../../types/rideComfort/rideComfortProps.ts";
import RideComfortTimePeriodForMapChooser from "./RideComfortTimePeriodForMapChooser.tsx";

type RideComfortTypesOfErrorsCompoentProps = {
    rideComfortProps: RideComfortProps
    scopedRideComfortGaugeByTimePeriod: keyof RideComfortGaugeByTimePeriod,
    sx: SxProps,
    titleVariant: string,
    labelVariant: string,
    iconSize: number,
    label: string,
    showCount: boolean
}

/**
 * Shows errors for one of the attributes of rideComfortProps.rideComfortGaugeByTimePeriod,
 * e.g. month, week, day
 * @param rideComfortProps
 * @param scopedRideComfortGaugeByTimePeriod
 * @param sx
 * @param titleVariant
 * @param labelVariant
 * @param iconSize
 * @param label
 * @param showCount
 * @constructor
 */
const RideComfortTypesOfErrorsComponent = (
    {
        rideComfortProps,
        scopedRideComfortGaugeByTimePeriod,
        sx,
        titleVariant,
        labelVariant,
        iconSize,
        label,
        showCount = false
    }: RideComfortTypesOfErrorsCompoentProps) => {

    const rideComfortGauge: RideComfortGauge = rideComfortProps.rideComfortGaugeByTimePeriod[scopedRideComfortGaugeByTimePeriod]

    const ref = useRef()

    return <Stack {...{
        sx: [{
            minWidth: 0, minHeight: 0, flex: 1, overflow: 'hidden', position: 'relative',
            padding: 1,
            backgroundColor: tempColors.darkGrey,
            border: `1.64px solid ${tempColors.white}`,
            borderRadius: '8px',
        },
            ...toArrayIfNot(sx)
        ]
    }} >
        <Stack {...{
            key: 'label',
            direction: 'row',
            spacing: 1
        }}>
            <Typography {...{
                key: 'typesOfErrors',
                variant: titleVariant,
                sx: {
                    color: tempColors.white,
                    padding: '4px 0 4px 16px',
                }
            }} >Types of Errors:</Typography>
            <Typography {...{
                key: 'type',
                variant: titleVariant,
                sx: {
                    color: tempColors.yellow,
                    padding: '4px 0 4px 16px',
                }
            }}> {label}</Typography>
            <Stack {...{
                key: 'controls',
                direction: 'row',
                sx: {alignItems: 'center'}
            }}>
                <RideComfortTimePeriodForMapChooser {...{rideComfortProps, scopedRideComfortGaugeByTimePeriod}}/>
            </Stack>
        </Stack>
        <Stack {...{
            key: 'rideComfortGauge',
            direction: 'row',
            spacing: 1,
            sx: {
                height: '100%',
                justifyContent: 'center',
                minWidth: 0, minHeight: 0, flex: 1, overflow: 'hidden', position: 'relative',
            }
        }}>

            <RideComfortGaugeComponent {...{
                parentRef: ref,
                rideComfortGauge: rideComfortGauge
            }}/>

            <RideComfortErrorLevelsComponent {...{rideComfortGauge, labelVariant, iconSize, showCount}} />
        </Stack>
    </Stack>
}

export default RideComfortTypesOfErrorsComponent
