import {GRAVITY_ACCELERATION} from 'appConfigs/appConfig.js';
import {
    add,
    always,
    equals,
    find,
    groupBy,
    head,
    ifElse,
    length,
    lt,
    map,
    prop,
    propOr,
    reduce,
    subtract,
    values,
    when
} from 'ramda';
import {isWithinInterval} from "date-fns";
import {compact} from "@rescapes/ramda";

export const calculateMeanXYZAcceleration = properties => {
    const values = map(
        attr => when(
            always(equals('accZMean', attr)),
            subtract(GRAVITY_ACCELERATION)
        )(prop(attr, properties)),
        ['accXMean', 'accYMean', 'accZMean']
    );
    return ifElse(
        values => lt(1, length(values)),
        values => {
            return Math.sqrt(
                reduce(
                    (accum, values) => {
                        return add(accum, Math.pow(values, 2));
                    },
                    0,
                    values
                )
            );
        },
        head
    )(values);
};

export const calculateRmse = (trainRun, {properties}) => {
    const rmses = find(length, values(trainRun.rmses))
    const date = properties.time

    const frequencyNameLookup = {rmse2hz: 'class_y_2hz', rmse4hz: 'class_y_4hz'}
    const rmseByFrequencyName = groupBy(prop('frequency'), rmses || [])

    // For each frequency, find an Rmse instance that matches the imupoint timestamp, if any
    return compact(map(
        frequenceyName => {
            const matchingRmse = rmses &&
                find(rmse => {
                        return isWithinInterval(date, {start: rmse.startTime, end: rmse.finishTime})
                    },
                    propOr([], frequenceyName, rmseByFrequencyName)
                )
            return matchingRmse?.classification
        },
        frequencyNameLookup
    ))
}

export const calculateRmseHighestValues = (trainRun) => {
    const rmses = find(length, values(trainRun.rmses))
    const rmsesByFrequencyName = groupBy(prop('frequency'), rmses || [])

    // For each frequency, find the highes rmse level
    return compact(map(
        rmses => {
            return length(rmses) ? Math.max(...map(rmse => rmse.classification, rmses)) : undefined
        },
        rmsesByFrequencyName
    ))
}