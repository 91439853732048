import {
  useMemoCalculateTrainRunDataToLoad,
  useSetTrainRunsCompletedWithImuPoints
} from 'async/trainAppAsync/hooks/trainApiHooks/trainApiTrainRunHooks.js';
import { useMemo, useState } from 'react';
import { all, always, any, complement, equals, filter, indexBy, length, map, pick, prop, propOr, sortBy } from 'ramda';
import { unlessLoadingProps } from 'utils/componentLogic/loadingUtils.js';
import UserTrainRunIntervalGeojsonDependency
  from 'async/trainAppAsync/dependencies/UserTrainRunIntervalGeojsonDepedency.js';
import { outstandingTrainRunIntervalImuPointsForDistanceIntervalAndRange } from 'appUtils/trainAppUtils/userTrainRunIntervalUtil.js';
import { useNotLoadingEffect, useNotLoadingMemo } from 'utils/hooks/useMemoHooks.js';
import { trainRunHasImuPoints } from 'appUtils/trainAppUtils/trainRunUtils.js';
import { useEffectMaybeUpdateUserTrainRunIntervalsStatusToLoading } from 'async/trainAppAsync/hooks/typeHooks/userTrainRunIntervalHooks.js';
import { trainRoutesOfTrainRouteOrGroup } from 'appUtils/trainAppUtils/trainRouteUtils.js';
import { pathOr } from '@rescapes/ramda';


/**
 * Loads UserTrainRunInterval sensor base props, meaning data from the CDC devices in similar. This
 * data is loaded to match the TrainRun of the UserTrainRunInterval and might in the future
 * be limited to the interval of the UserTrainRunInterval.
 * Depends directly on trainProps.userTrainRunIntervalProps.loading
 * @param appProps
 * @param organizationProps
 * @param trainProps
 * @param children
 * @returns {*}
 * @constructor
 */
const TrainContainerUserTrainRunIntervalSensorDependency = ({ appProps, organizationProps, trainProps, mapProps }) => {

  const loading = trainProps.userTrainRunIntervalProps.loading;

  // Keeps track of loading of imuPoints for trainRuns
  // in the form [{ trainRunId, distanceInterval, distanceRanges }, ...]
  // where distanceInterval is the distance interval we requested ImuPoints at, such as 100 meters or 50 meters,
  // and distanceRanges are the ranges requested at that interval. They are always in order by trainRoute distance
  // and consolidated to not overlap.
  // Each distanceInterval object can be marked with distanceRangeErrors containing distanceRange
  // requests that errored.
  const [trainRunIdsRequestedWithImuPoints, setTrainRunIdsRequestedWithImuPoints] = useState([]);

  // The loaded data base on trainRunIdsRequestedWithImuPoints
  // Each has a property distanceIntervalsAndRanges with arrays of distanceInterval and its distanceRanges that have been downloaded
  // This is compared to trainRunIdsRequestedWithImuPoints to determine what needs to be downloaded
  // We store TrainRuns here for all TrainRoutes so that when the user switches between TrainRoutes, we don't
  // download the same ImuPoint data again. This means that we must always filter minimumTrainRunsWithImuPoints
  // by the current trainProps.trainRoutePros.trainRoute
  const [minimumTrainRunsWithImuPoints, setMinimumTrainRunsWithImuPoints] = useState([]);
  const minimumTrainRunsWithImuPointsForTrainRouteOrGroup = useNotLoadingMemo(loading, () => {
      const trainRouteLookup = indexBy(prop('id'), trainRoutesOfTrainRouteOrGroup(trainProps.trainRouteProps.trainRoute));
      const trainRunIdLookup = indexBy(prop('id'), trainProps.trainRunProps.trainRuns);
      return filter(
        trainRun => {
          const trainRouteId = pathOr(null, [trainRun.id, 'trainRoute', 'id'], trainRunIdLookup)
          return trainRouteId && propOr(false, trainRouteId, trainRouteLookup);
        },
        minimumTrainRunsWithImuPoints);
    },
    [minimumTrainRunsWithImuPoints, trainProps.trainRouteProps.trainRoute, trainProps.trainRunProps.trainRuns]
  );

  // Used by charts and maps to store what was hovered over last so we can display it
  const [mostRecentTooltipPayload, setMostRecentTooltipPayload] = useState(null);

  const {
    crudUserTrainRunIntervals,
    activeUserTrainRunIntervals,
    activeUserTrainRunIntervalsWithoutErrors
  } = unlessLoadingProps(loading, () => {
    return trainProps.userTrainRunIntervalProps;
  });

  // Load sensor data for active UserTrainRuns
  const { updatedTrainRunIdsRequestedWithImuPoints, trainRunDataToLoad } = useMemoCalculateTrainRunDataToLoad({
      loading,
      trainRouteOrGroup: trainProps.trainRouteProps.trainRoute,
      trainRouteAggregateInterval: trainProps.trainRouteProps.trainRouteAggregateInterval,
      // Include those with errors in case the re-requests
      activeUserTrainRunIntervals,
      trainRunIdsRequestedWithImuPoints
    }
  ) || {};

  useEffectMaybeUpdateUserTrainRunIntervalsStatusToLoading(loading || !trainRunDataToLoad,
    {
      trainRouteOrGroup: trainProps.trainRouteProps.trainRoute,
      updatedTrainRunIdsRequestedWithImuPoints,
      trainRunDataToLoad,
      crudUserTrainRunIntervals,
      setTrainRunIdsRequestedWithImuPoints,
      setMinimumTrainRunsWithImuPoints
    }
  );

  // Set crudUserTrainRunIntervals to the data loaded in useMemoCalculateTrainRunDataToLoad
  // once minimumTrainRunsWithImuPoints has all those in crudUserTrainRunIntervals
  useSetTrainRunsCompletedWithImuPoints({
    loading,
    crudUserTrainRunIntervals,
    minimumTrainRunsWithImuPointsForTrainRouteOrGroup
  });

  // Whenever mostRecentTooltipPayload changes, update the cursor layer on the map to show on the map
  // where the user is hovering on a chart
  useNotLoadingEffect(loading,
    () => {
      appProps.moveCursorAlongChartLine(mostRecentTooltipPayload);
    },
    [mostRecentTooltipPayload]
  );

  const trainRunIdWithSensorDataLookup = indexBy(
    prop('id'),
    minimumTrainRunsWithImuPoints
  );

  // We aren't ready until minimumTrainRunsWithImuPoints has the data and it's merged into
  // the UserTrainRunIntervals so it shows up in activeUserTrainRunIntervals[*].trainRunInterval.trainRun.imuPoints
  const allActiveUserTrainRunIntervalsHaveImuPoints = useNotLoadingMemo(loading, () => all(
    userTrainRunInterval => {
      const trainRun = userTrainRunInterval.trainRunInterval.trainRun;
      const outstanding = outstandingTrainRunIntervalImuPointsForDistanceIntervalAndRange({
        trainRouteOrGroup: trainProps.trainRouteProps.trainRoute,
        trainRouteAggregateInterval: trainProps.trainRouteProps.trainRouteAggregateInterval,
        minimumTrainRunsWithImuPoints,
        userTrainRunInterval
      });
      return trainRunHasImuPoints(trainRun) && !length(outstanding);
    }, activeUserTrainRunIntervalsWithoutErrors
  ), [minimumTrainRunsWithImuPoints, activeUserTrainRunIntervalsWithoutErrors]);

  // Test if more ImuPoints have been requested
  const additionalImuPointsAreLoading = useNotLoadingMemo(loading, () => {
    return any(
      trainRun => {
        const requested = map(pick(['distanceInterval', 'distanceRanges']), trainRunIdsRequestedWithImuPoints[trainRun.id]);
        return !(trainRunHasImuPoints(trainRun) &&
          equals(
            ...map(sortBy(prop('distanceInterval')), [
              requested,
              trainRun.distanceIntervalsAndRanges
            ])
          ));
      },
      filter(complement(prop)('error'), minimumTrainRunsWithImuPoints)
    );
  }, [trainRunIdsRequestedWithImuPoints, minimumTrainRunsWithImuPoints]);

  const notReady = loading || !allActiveUserTrainRunIntervalsHaveImuPoints;
  const loadingExplanation = useMemo(always({
    'trainProps.userTrainRunIntervalProps.loading': trainProps.userTrainRunIntervalProps.loading,
    allActiveUserTrainRunIntervalsHaveImuPoints
  }), [
    trainProps.userTrainRunIntervalProps.loading,
    allActiveUserTrainRunIntervalsHaveImuPoints
  ]);

  return <UserTrainRunIntervalGeojsonDependency {...{
    appProps,
    organizationProps,
    trainProps: {
      ...trainProps,
      userTrainRunIntervalProps: {
        ...trainProps.userTrainRunIntervalProps,
        sensorProps: {
          loading: notReady,
          loadingExplanation,
          trainRunIdWithSensorDataLookup,
          additionalImuPointsAreLoading,
          minimumTrainRunsWithImuPoints,
          minimumTrainRunsWithImuPointsForTrainRouteOrGroup,
          mostRecentTooltipPayload,
          setMostRecentTooltipPayload
        }
      }
    },
    mapProps
  }} />;
};
TrainContainerUserTrainRunIntervalSensorDependency.displayName = 'TrainContainerUserTrainRunIntervalSensorDependency';
export default TrainContainerUserTrainRunIntervalSensorDependency;