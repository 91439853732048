import {
  addFormationsToFilters,
  extractLabelsForFormations,
  removeFormationFilters
} from 'appUtils/trainAppUtils/trainFilterTrainFormationUtils.js';
import { always, ifElse, join, length } from 'ramda';
import { useTranslation } from 'react-i18next';
import { TrainRunFilterFormationsComponent } from 'components/apps/trainAppComponents/trainFilterComponents/TrainRunFilterFormationsComponent.js';
import LoaderWithText from 'components/loading/LoaderWithText.js';

/**
 * Shows current TrainRunFormationFilters and if not readonly gives an interface to add and delete them
 * @param trainProps
 * @param setOpen
 * @returns {JSX.Element}
 * @constructor
 */
const TrainRunFilterFormationsContainer = (
  {
    trainProps,
  }) => {
  const loading = trainProps.formationProps.loading
  const { t } = useTranslation();

  if (loading) {
    return <LoaderWithText text='loadingFormationFilter' />
  }

  // The only props the filter needs are the eligible formations so that formationIds can resolve to formations
  const filterProps = { formations: trainProps.formationProps.formationsWithSensors };
  // Creates a filter from the given formation and adds it to
  // trainProps.formationProps.trainRunFilterWithFormations
  const trainRunFilterWithFormations = trainProps.formationProps.trainRunFilterWithFormations;
  const formationLabelsFromFilter = extractLabelsForFormations(trainRunFilterWithFormations, filterProps);
  const formationsLabel = ifElse(
    length,
    join(', '),
    always(t('allFormationsWithSensors'))
  )(formationLabelsFromFilter);

  /**
   * Add the given formation to the trainRunFilterWithFormations
   * @param formation
   */
  const handleAddFormationToFilter = formation => {
    const updatedTrainRunFilterWithFormations = addFormationsToFilters(
      trainRunFilterWithFormations,
      formation,
      filterProps
    );
    trainProps.formationProps.setTrainRunFilterWithFormations(updatedTrainRunFilterWithFormations);
  };
  /**
   * Remove the given formation from the trainRunFilterWithFormations
   * @param formation
   */
  const handleRemoveFormationFromFilters = formation => {
    const updatedTrainRunFilterWithFormations = removeFormationFilters(trainRunFilterWithFormations, formation, filterProps);
    trainProps.formationProps.setTrainRunFilterWithFormations(updatedTrainRunFilterWithFormations);
  };

  return <TrainRunFilterFormationsComponent {...{
    trainProps,
    componentProps: {
      readonly: false,
      handleAddFormationToFilter,
      formationLabelsFromFilter,
      filterProps,
      handleRemoveFormationFromFilters,
      formationsLabel
    }
  }} />;
};
export default TrainRunFilterFormationsContainer;