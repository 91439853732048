import { TRAIN_API, trainApiGetUrl } from 'appConfigs/trainConfigs/trainConfig.js';
import { compactEmpty } from '@rescapes/ramda';
import { identity, join, length, map, prop, when } from 'ramda';
import {
  trainDateParams,
  trainDateRecurrenceParams,
  trainRunTrainRouteParams
} from 'appUtils/trainAppUtils/trainApiTrainRunUtils.js';

/**
 * Queries for the logged in Client
 * @param organization
 * @returns {String}
 */
export const trainApiClient = organization => {
  // Returns the client config of the authenticated user
  // TODO Fix to get client from auth
  return trainApiGetUrl({
    route: TRAIN_API.CLIENTS.route,
    query: { sourceKey: organization.sourceKey }
  });
};

/**
 * Queries for TrainRuns
 * @param dateRange
 * @param formations
 * @param dateRecurrences
 * @param trainRoute
 * @param {[Number]} trainRunIds Used to query for TrainRuns by id
 * @returns {String|null}
 */
export const trainApiTrainRuns = (dateRange, formations, dateRecurrences, trainRoute, trainRunIds) => {
  if (!dateRange && !trainRunIds) {
    // We must either be limited to a dateRange or trainRunIds to proceed
    return null;
  }

  const formationIds = formations && map(prop('id'), formations);
  const dateParams = trainDateParams(dateRange);
  const dateRecurrenceParams = trainDateRecurrenceParams(dateRecurrences);
  return trainApiGetUrl({
    route: TRAIN_API.TRAIN_RUNS.route,
    query: compactEmpty({
      // Only query for train runs that have a formation and thus have CDC data
      must_have_formation: true,
      // Limit TrainRuns to the current TrainRoute or TrainRouteGroup's TrainRoutes
      // This is only required if dateRange is specified. If trainRunIds is, we don't need a trainRoute
      ...!trainRunIds || trainRoute ? trainRunTrainRouteParams(trainRoute) : {},
      ...dateParams,
      ...dateRecurrenceParams,
      formationId_in_: formationIds,
      id_in_: when(identity, join(','))(trainRunIds)
      // Limit TrainRuns to the given dates
    })
  });
};


/**
 * Queries for the available dates of TrainRuns matching the given TrainRoute or TrainRouteGroup
 * @param {Object} trainRouteOrGroup A TrainRoute or TrainRouteGroup instance
 * @returns {String}
 */
export const trainApiAvailableTrainRunDates = trainRouteOrGroup => {
  // Get the TrainRuns in the given date range
  return trainApiGetUrl({
    route: `${TRAIN_API.TRAIN_RUNS.route}/available_date_ranges`,
    query: trainRunTrainRouteParams(trainRouteOrGroup)
  });
};

/**
 * Queries for the ServiceLines of the client
 * @param organization
 * @returns {String}
 */
export const trainApiServiceLines = organization => {
  // Get the TrainRuns in the given date range
  return trainApiGetUrl({
    route: TRAIN_API.SERVICE_LINES.route,
    // TODO Assume the client is the operator for now
    query: { operatorId: organization.operator.id }
  });
};

/**
 * Queries for the Railway lines
 * @returns {String}
 */
export const trainApiRailwayLines = organization => {
  // Get the RailwayLines TODO currently not used
  // since we get railwayLines from serviceLines
  return trainApiGetUrl({
    route: TRAIN_API.RAILWAY_LINES.route,
    query: { operatorId: organization.operator.id }
  });
};

/**
 * Queries for the TrainRoutes of the given ServiceLines
 * @param serviceLines
 * @returns {String}
 */
export const trainApiTrainRoutes = serviceLines => {
  if (!length(serviceLines)) {
    throw Error('Empty serviceLines parameter. This should not ever happen');
  }
  return trainApiGetUrl({
    route: TRAIN_API.TRAIN_ROUTES.route,
    query: {
      serviceLineId_in_: join(',', map(prop('id'), serviceLines))
    }
  });
};

export const trainApiTrainRouteGroups = organization => {
  if (!organization) {
    throw Error('Null client parameter. This should not ever happen');
  }
  return trainApiGetUrl({
    route: TRAIN_API.TRAIN_ROUTE_GROUPS.route,
    query: { operatorId: organization.operator.id }
  });
};


export const trainApiFormations = organization => {
  // Get the TrainRuns in the given date range
  return trainApiGetUrl({
    route: TRAIN_API.FORMATIONS.route,
    query: { operatorId: organization.operator.id }
  });
};
