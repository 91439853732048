import { createTheme, darken } from '@mui/material/styles/index.js';
import { alpha } from '@mui/material';
import { addIndex, fromPairs, map } from 'ramda';
import {
  CEMIT_ALERT_BLUE,
  CEMIT_ALERT_RED,
  CEMIT_ALERT_YELLOW,
  CEMIT_COMPARE_BASELINE,
  CEMIT_COMPARE_FILL_1,
  CEMIT_COMPARE_FILL_2,
  CEMIT_COMPARE_LINES,
  CEMIT_DARK_RED,
  CEMIT_GRAY,
  CEMIT_GRAY_ON_DARK,
  CEMIT_GREEN,
  CEMIT_ORANGE,
  CEMIT_TRAIN_BUTTON_BACKGROUND,
  CEMIT_TRAIN_RUN_LINE,
  CEMIT_WHITE,
  CEMIT_YELLOW,
  colors
} from './colors.ts';
import LinkBehavior from './LinkBehavior.tsx';
import { Theme } from '@mui/material/styles';

// https://stackoverflow.com/questions/70613394/property-palette-is-not-recognised-by-defaulttheme-from-materialui-it-stopped
declare module '@mui/system' {
  interface DefaultTheme extends Theme {
  }
}

const theme = createTheme({
  typography: {
    button: {
      // Disabled default uppercasing
      textTransform: 'none'
    }
  },
  palette: {
    primary: {
      main: CEMIT_YELLOW,
      disabled: darken(CEMIT_YELLOW, .25)
    },
    secondary: {
      main: CEMIT_WHITE,
      disabled: darken(CEMIT_WHITE, .25)
    },
    dividerBar: {
      main: CEMIT_GRAY
    },
    alertNoData: {
      main: alpha(CEMIT_WHITE, .25)
    },
    alertError: {
      main: CEMIT_ALERT_RED
    },
    alertWarning: {
      main: CEMIT_ALERT_YELLOW
    },
    alertNormal: {
      main: CEMIT_ALERT_BLUE
    },
    remove: {
      main: CEMIT_DARK_RED
    },
    error: {
      main: CEMIT_ORANGE
    },
    activate: {
      main: CEMIT_GREEN
    },
    visibility: {
      main: CEMIT_WHITE
    },
    scheduleButton: {
      main: CEMIT_TRAIN_BUTTON_BACKGROUND
    },

    trainRunIntervalBar: {
      main: CEMIT_TRAIN_RUN_LINE
    },
    trainRunLine: {
      main: CEMIT_TRAIN_RUN_LINE,
      action: {
        isOver: 'orange'
      }
    },
    trainRunLineStationDot: {
      main: CEMIT_WHITE,
      action: {
        isOver: CEMIT_YELLOW,
        isDisabled: alpha(CEMIT_WHITE, 0.5)
      }
    },

    compareButton: {
      main: CEMIT_WHITE,
      disabled: CEMIT_WHITE,
      action: {
        isOver: CEMIT_YELLOW,
        isDisabled: CEMIT_WHITE
      }
    },

    action: {
      disabled: CEMIT_GRAY_ON_DARK
    },

   trainDistanceIntervalBar: {
      main: CEMIT_TRAIN_RUN_LINE,
      dragging: 'orange',
      action: {
        drag: '#35E9E9',
        dragOpacity: 0.2
      }
    },
    // For showing a mark on the TrainRunLine of the feature being hovered over on the map
    trainRunLiveHoverFeature: {
      main: CEMIT_YELLOW
    },
    // TODO I can't figure how to define custom color variants
   trainDistanceIntervalBarExtender: {
      main: CEMIT_TRAIN_BUTTON_BACKGROUND
    },
   trainDistanceIntervalBarExtenderDrag: {
      main: '#FFA500'
    },

    background: {
      paper: CEMIT_GRAY,
      default: CEMIT_GRAY,
      ...colors
    },
    // Non-standard below this line
    compare: {
      // Used for the comparison graph that shows a line
      baseline: { main: CEMIT_COMPARE_BASELINE },
      // Creates {'1': color1, '2': color2, ...}
      line: fromPairs<string>(addIndex<string>(map)(
        (color, index) => [index + 1, color],
        CEMIT_COMPARE_LINES)
      ),
      // Used for the comparison graph that shows a line
      fill: {
        primary: { main: CEMIT_COMPARE_FILL_1 },
        secondary: { main: CEMIT_COMPARE_FILL_2 }
      }
    },
    schedule: {
      button: {
        background: {
          default: CEMIT_TRAIN_BUTTON_BACKGROUND
        }
      },
      line: {
        background: {
          default: CEMIT_TRAIN_RUN_LINE
        }
      }
    }
  },
  components: {
    MuiLink: {
      // https://mui.com/material-ui/guides/routing/
      defaultProps: {
        // @ts-expect-error meh
        component: LinkBehavior
      }
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          color: CEMIT_WHITE,
          fontSize: 12
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: CEMIT_WHITE,
          '&:hover': {
            backgroundColor: CEMIT_GRAY_ON_DARK
          }
        }
      }
    },
    MuiSvgIcon: {
      variants: [
        {
          // @ts-expect-error meh
          props: { variant: 'tiny' },
          style: {
            fontSize: 20,
            margin: 0
          }
        }
      ]
    },
    MuiAccordion: {
      styleOverrides: {
        // Name of the slot
        root: {
          padding: 0,
          backgroundColor: 'inherit',
          boxShadow: 'none',
          ':before': {
            backgroundColor: 'inherit'
          }
        }
      }
    },
    MuiAccordionSummary: {
      styleOverrides: {
        // Name of the slot
        root: {
          padding: 0,
          minHeight: 0,
          boxShadow: 'none',
          // The moves the expander to be left of the label
          flexDirection: 'row-reverse'
        },
        expandIconWrapper: {
          color: CEMIT_WHITE
        },
        content: {
          margin: 0
        }
      }
    },
    MuiTable: {
      styleOverrides: {
        root: {
          px: 0,
          color: CEMIT_WHITE
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          color: CEMIT_WHITE,
          "&.Mui-selected": {
            "backgroundColor": alpha(CEMIT_YELLOW, .25)
          }
        },
      }
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          justifyContent: 'left',
          color: CEMIT_WHITE,
          '&.Mui-active': {
            'color': CEMIT_WHITE
          }
        }

      }
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          flexDirection: 'row',
          color: CEMIT_WHITE,
          '&.Mui-active': {
            'color': CEMIT_WHITE,
            '.MuiTableSortLabel-icon': {
              'color': CEMIT_WHITE
            }
          },
          '&:hover': {
            'color': CEMIT_WHITE
          }
        },
        icon: {
          color: CEMIT_WHITE
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          textAlign: 'left',
          color: CEMIT_WHITE,
          padding: '5px'
        },
        head: { color: CEMIT_WHITE },
        body: { color: CEMIT_WHITE }
      }
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          '& .MuiLinearProgress-bar': {
            transition: 'none'
          },
          height: 10,
          borderRadius: 5
        },
        colorPrimary: {},
        bar: {
          borderRadius: 5
        }
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          color: CEMIT_WHITE,
          '& .MuiTablePagination-toolbar': {
            paddingLeft: 0,
            paddingRight: 0
          }
        }
      }
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          color: CEMIT_WHITE,
          '& .MuiTablePagination-toolbar': {
            paddingLeft: 0,
            paddingRight: 0
          }
        },
        selectLabel: {
          color: CEMIT_WHITE
        },
        selectIcon: {
          color: CEMIT_WHITE
        },
        menuItem: {
          color: CEMIT_WHITE
        }
      }
    },
  }
});
export default theme;
