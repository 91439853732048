import {Stack, Tooltip, Typography} from "@mui/material";
import React from "react";
import {tempColors} from "../../../../theme/tempColors.ts";
import {Train} from "@mui/icons-material";
import {RideComfortTrainInfo} from "../../../../types/rideComfort/rideComfortTrainInfo";
import {AppProps} from "../../../../visualizer-railbed/types/propTypes/appProps";
import {RideComfortHeatMapDatum} from "../../../../types/rideComfort/rideComfortHeatMapDatum";
import {RideComfortGauge, RideComfortGaugeByTimePeriod} from "../../../../types/rideComfort/rideComfortGauge";
import {GetTotalCount, QueryRideComfortApi} from "../../../../types/rideComfort/rideComfortFunctions";
import {Setter} from "../../../../visualizer-railbed/types/hookHelpers/setter";
import {RideComfortIntervalDescription} from "../../../../types/rideComfort/rideComfortintervalDescription";
import {RideComfortAlertType} from "../../../../types/rideComfort/rideComfortAlertType";
import {RideComfortAlertLevelDatum} from "../../../../types/rideComfort/rideComfortMapData";
import {equals} from "ramda";

type RideComfortTrainComponentProps = {
    appProps: AppProps,
    trainInfo: RideComfortTrainInfo,
    selectedTrainInfo: RideComfortTrainInfo,
    setTrainInfo: Setter<RideComfortTrainInfo>,
    queryRideComfortApiAndMutate: QueryRideComfortApi,
    date: Date,
    getTotalCount: GetTotalCount,
    setRideComfortGaugeByTimePeriod: Setter<RideComfortGauge>,
    setHeatMapData: Setter<RideComfortHeatMapDatum>,
    totalMapDataValues: Setter<RideComfortAlertLevelDatum>,
    intervalDescription: RideComfortIntervalDescription,
    rideComfortAlertType: RideComfortAlertType
    rideComfortTimePeriodForMap: keyof RideComfortGaugeByTimePeriod
}
const RideComfortTrainComponent = (
    {
        appProps,
        trainInfo,
        selectedTrainInfo,
        setTrainInfo,
    }: RideComfortTrainComponentProps) => {

    const typographySx = {
        fontFamily: 'Roboto, Helvetica',
    }

    const iconSx = {
        // eslint-disable-next-line no-undef
        color: tempColors.white,
    };
    return <Stack {...{
        key: 'trainComponent',
        spacing: 1,
        direction: 'row',
        onClick: () => {
            setTrainInfo(trainInfo);
        },
        sx: {
            minWidth: 0, flex: 1, overflow: 'hidden', position: 'relative',
            height: '50px',
            minHeight: '50px',
            maxHeight: '50px',
            alignItems: 'center',
            '&:hover': {
                cursor: 'pointer',
            },
            borderRadius: '8px',
        }
    }}>
        <Tooltip arrow title={appProps.t('rideComfortDescription')}>
            <Stack {...{
                spacing: 0.5,
                direction: 'column',
                key: 'train',
                sx: {alignItems: 'center'}
            }}>
                <Train {...{sx: iconSx}} />
            </Stack>
        </Tooltip>
        <Stack {...{
            key: 'box',
            direction: 'column',
            spacing: 0.5,
            sx: {
                padding: '5px',
                minWidth: 0, minHeight: 0, flex: 1, overflow: 'hidden', position: 'relative',
                margin: 10,
                ...equals(selectedTrainInfo, trainInfo) ? {
                    backgroundColor: tempColors.lightYellow,
                } : {},
                border: '1.64px solid #6fcf97',
                borderRadius: '8px',
            }
        }}>
            <Stack {...{
                key: 'statuses',
                spacing: 0.5,
                sx: {
                    alignItems: 'left',
                }
            }}>
                <Typography {...{
                    key: 'trainIdTitle',
                    variant: 'subtitle',
                    sx: {
                        ...typographySx,
                        color: tempColors.white
                    }
                }}>Train ID: {trainInfo?.trainId}</Typography>
                <Typography {...{
                    key: 'cdcTitle',
                    variant: 'subtitle',
                    sx: {
                        ...typographySx,
                        color: tempColors.white
                    }
                }}>CDC: {trainInfo?.cdc}</Typography>
            </Stack>
        </Stack>
    </Stack>
}
export default RideComfortTrainComponent
