import { compose, cond, equals, fromPairs, identity, map, pick, prop, T } from 'ramda';
import { compact } from '@rescapes/ramda';
import { getType } from '@turf/invariant';
import center from '@turf/center';
import { TrainProps } from '../../railbedTypes/propTypes/trainProps';
import { AppProps } from '../../railbedTypes/propTypes/appProps';
import { TrainRunGroup } from '../../railbedTypes/trainRuns/trainRunGroup';
import { ChartPayloadItem, ChartPayloadItemMinimized } from '../../railbedTypes/dataVisualizations/chartPayloadItem';
import { Feature } from '@turf/helpers';
import { Map } from 'mapbox-gl';
import { FeatureCollectionU, FeatureU, PointU } from '../../railbedTypes/geometry/geojsonUnions';
import { colors } from '@mui/material';
import { singlePointFeatureFromPayload } from '../dataFeatures/dataFeaturePayloadUtils.ts';

export const CHART_HOVER_SOURCE = 'chart-hover-trace-source';
export const CHART_HOVER_LAYER = 'chart-hover-trace-layer';

/**
 * Returns the TrainRunGroup sourceKey as a single item array to indicate that it is the item being hovered over
 * Not TrainRunGroups currently return an empty list
 * @param trainProps
 * @param trainRunGroup
 * @returns a single item string array or an empty array
 */
const hoverFeatureKeys = ({ trainProps, trainRunGroup }: {
  trainProps: TrainProps,
  trainRunGroup: TrainRunGroup
}): [string] | [] => {
  // Mark the trainRunGroup.sourceKey position with the feature
  return compact([
    trainRunGroup.sourceKey,
    // Set the same feature for the TrainRoute for now so we can mark the feature on the SampleChart Page TrainRunGroupLine
    // TODO this might not ever be needed. It might be enough to set the same TrainRoute or TrainRouteGroup below
    // if there is never a case where we need to distinguish an individual TrainRoute from the TrainRouteGroup it is in
    trainRunGroup.trainRouteOrGroup!.id,
    // Set the same feature for the current TrainRoute in case it is a TrainRouteGroup that differs from the
    // TrainRoute of trainRunGroup.
    trainProps.trainRouteOrGroupProps.trainRouteOrGroup!.id
  ]);
};

/**
 * Responds to a map layer hover, telling the app what feature is hovered over for the given TrainRunGroup
 * @param appProps
 * @param trainProps
 * @returns
 */
export const moveCursorAlongTrainMapLine = ({ appProps, trainProps }: {
  appProps: AppProps,
  trainProps: TrainProps
}) => {
  return (trainRunGroup: TrainRunGroup, payload: ChartPayloadItem[]): void => {
    const feature = singlePointFeatureFromPayload(payload);
    appProps.setHoverFeature((obj: FeatureU) => {
      // Merge new keys with the current values to handle hovering over multiple TrainRunGroups
      return {
        ...obj,
        ...fromPairs(
          map(
            (key: string) => [key, feature],
            hoverFeatureKeys({
              trainProps, trainRunGroup
            })
          )
        )
      };
    });
  };
};

/**
 * Responds to hover ending on the map and clears the hover feature for the given TrainRunGroup
 * @param appProps
 * @returns {(function(*=): void)|*}
 */
export const removeCursorFromTrainMap = ({ appProps }: { appProps: AppProps }) => () => {
  appProps.setHoverFeature(() => {
    // TODO not sure if this is correct
    return undefined;
  });
};

export const removeCursorFromChart = (trainMap: Map) => () => {
  if (!trainMap) {
    return;
  }
  if (trainMap.getSource(CHART_HOVER_SOURCE)) {
    trainMap.removeLayer(CHART_HOVER_LAYER);
    trainMap.removeSource(CHART_HOVER_SOURCE);
  }
};

/**
 * When the user hovers on the chart, this creates a layer on the trainMap
 * to match the user's hover position on the chart.
 * It also update the chart's TrainRunLine vertical hash to mark the same position
 * @param updateTrainRunLinePosition
 * @parma payload  Contains the most recent payload from hovering on the dataVisualizations
 * @param trainMap
 * @returns {(function(*): void)|*}
 */
export const moveCursorAlongChartLine = (
  organizationServiceLinesExists: boolean,
  updateTrainRunLinePosition: (payload: ChartPayloadItem[]) => void,
  trainMap?: Map
) => (payloadItems: ChartPayloadItem[]) => {
  // Update the chart's TrainRunLine hash mark
  updateTrainRunLinePosition(payloadItems);

  // If we don't have TrainRoutes, it means we don't have a map, so quit
  if (!organizationServiceLinesExists) {
    return
  }

  // Get the Point Feature from each payload item
  const featureCollection: FeatureCollectionU | undefined = featureCollectionFromPayload(payloadItems);
  if (!featureCollection) {
    return;
  }

  // Update the data if the source exists
  if (trainMap.getSource(CHART_HOVER_SOURCE)) {
    // @ts-expect-error meh
    trainMap.getSource(CHART_HOVER_SOURCE).setData(featureCollection);
    return;
  }
  // Otherwise create the source and layer
  trainMap.addSource(CHART_HOVER_SOURCE, { type: 'geojson', data: featureCollection });
  trainMap.addLayer({
    id: CHART_HOVER_LAYER,
    type: 'circle',
    source: CHART_HOVER_SOURCE,
    paint: {
      'circle-color': 'rgba(0,0,0,0)',
      'circle-radius': 13,
      // @ts-expect-error figure out later
      'circle-stroke-color': colors.orange,
      'circle-stroke-width': 1.5
    }
  });
};

/**
 * Creates a FeatureCollection from the first item of a recharts payload. If the payload has no items, null is returned
 * @param payloadItems
 * @returns {*|{features: *[], type: string}}
 */
export const singleItemFeatureCollectionFromPayload = (payloadItems: ChartPayloadItem[]) => {
  const feature = singlePointFeatureFromPayload(payloadItems);
  return feature && {
    type: 'FeatureCollection',
    features: [feature]
  };
};

/**
 * Convert the payloadItems[*].payload features to a FeatureCollection
 * @param payloadItems
 * @returns A FeatureCollection or undefined if payloadItems is undefined
 */
export const featureCollectionFromPayload = (
  payloadItems: ChartPayloadItem[]
): FeatureCollectionU | undefined => {
  return payloadItems ? {
    type: 'FeatureCollection',
    features: map(prop('payload'), payloadItems)
  } : undefined;
};

/**
 * Converts the payloadItem.paylaod feature to a Point feature if it isn't one, preserving
 * the properties, id, and centroid of the feature
 * @param payloadItem
 * @returns {*}
 */
export const pointFeatureFromPayLoadItem = (payloadItem: ChartPayloadItemMinimized): FeatureU<PointU> => {

  // Converts the feature to a Point feature, preserving the given attributes if defined
  const featureToPoint = (feature: Feature) => {
    return center(feature, pick(['properties', 'centroid', 'id'], feature));
  };
  // Return the point or the center Point of a LineString or Polygon
  // @ts-expect-error too complicated for now
  return cond([
    [
      compose(equals('Polygon'), getType),
      featureToPoint
    ],
    [
      compose(equals('Linestring'), getType),
      featureToPoint
    ],
    // Leave it alone
    [compose(equals('Point'), getType), identity],
    [T, (feature: Feature) => {
      throw Error(`Feature expected to be a Polygon or Point, but got a ${getType(feature)}`);
    }]
  ])(payloadItem.payload);
};
