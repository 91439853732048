import { IconButton, Tooltip } from '@mui/material';
import { VisibilityOutlined, VisibilityOffOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types'

const UserTrainRunVisibilityButton = ({ userTrainRunInterval, toggleUserTrainRunIntervalVisibility, disabled = false }) => {
  const { t } = useTranslation();
  const { Icon, tooltipKey } = userTrainRunInterval?.activity?.isVisible ?
    { Icon: VisibilityOutlined, tooltipKey: 'hideUserTrainRunInterval' } :
    { Icon: VisibilityOffOutlined, tooltipKey: 'showUserTrainRunInterval' }

  const button = <IconButton {...{
    key: 'visibilityButton',
    'aria-label': t(tooltipKey),
    disabled,
    sx: {
      float: 'right',
      alignItems: 'flex-start',
      color: userTrainRunInterval.activity.isActiveColor,
      p: 0
    },
    onClick: () => {
      toggleUserTrainRunIntervalVisibility(userTrainRunInterval);
    }
  }}>
    <Icon />
  </IconButton>;

  return disabled ?
    button :
    <Tooltip key='tooltipVisualize' arrow title={t(tooltipKey)}>
      {button}
    </Tooltip>;
};
UserTrainRunVisibilityButton.propTypes = {
  userTrainRunInterval: PropTypes.object.isRequired,
  toggleUserTrainRunIntervalVisibility: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

export default UserTrainRunVisibilityButton;