import { any, compose, identity, indexBy, join, length, map, prop, propOr, values } from 'ramda';
import { useNotLoadingHasChanged } from 'utils/hooks/useHasChanged.js';
import { useState } from 'react';
import {
  useEffectCreateCrudUserTrainRunIntervals,
  useMemoActiveUserTrainRunIntervals,
  useMemoActiveUserTrainRunIntervalsWithoutErrors,
  useSyncUserTrainRunIntervalsToStoredAndPreconfigured
} from 'async/trainAppAsync/hooks/typeHooks/userTrainRunIntervalHooks.js';
import TrainContainerUserTrainRunIntervalSensorDependency
  from 'async/trainAppAsync/dependencies/UserTrainRunIntervalSensorDepedency.js';
import { strPathListsEqual } from 'utils/functional/functionalUtils.js';
import { trainRouteOfUserTrainRunInterval } from 'appUtils/trainAppUtils/userTrainRunIntervalUtil.js';
import { trainRoutesOfTrainRouteOrGroup } from 'appUtils/trainAppUtils/trainRouteUtils.js';
import { unlessLoadingProps } from 'utils/componentLogic/loadingUtils.js';


/**
 * Loads/Updates UserTrainRunInterval props in trainProps.userTrainRunIntervalProps
 * Depends directly on TrainRunInterval props in trainProps.trainRunIntervalProps
 * @param appProps
 * @param organizationProps
 * @param trainProps
 * @param children
 * @return {*}
 * @constructor
 */
const UserTrainRunIntervalDependency = ({ appProps, organizationProps, trainProps, mapProps }) => {

  const loading = trainProps.trainRunIntervalProps.loading;

  const [userTrainRunIntervals, setUserTrainRunIntervals] = useState([]);

  // useWhatChanged([userTrainRunIntervals], 'userTrainRunIntervals')
  // Note userTrainRunIntervalsForTrainRoute is defined in trainRunDependency.js
  useSyncUserTrainRunIntervalsToStoredAndPreconfigured({
    loading,
    trainRuns: trainProps.trainRunProps.trainRuns,
    userTrainRunIntervalsForTrainRoute: trainProps.trainRunProps.userTrainRunIntervalsForTrainRoute,
    userTrainRunIntervals,
    setUserTrainRunIntervals
  });

  const [crudUserTrainRunIntervals, setCrudUserTrainRunIntervals] = useState(null);
  // Manages changes to the UserTrainRunIntervals
  useEffectCreateCrudUserTrainRunIntervals({
    userTrainRunIntervals,
    setUserTrainRunIntervals,
    trainRoute: trainProps.trainRouteProps.trainRoute,
    setUserTrainRunIntervalsForAllTrainRoutes: trainProps.trainRunProps.setUserTrainRunIntervalsForAllTrainRoutes,
    crudUserTrainRunIntervals,
    setCrudUserTrainRunIntervals
  });

  // Currently limit the UserTrainRunIntervals with property activity: {isActive: true} to the two UserTrainRunIntervals
  // so that the comparison functionality only needs to compare two trains runs.
  const activeUserTrainRunIntervals = useMemoActiveUserTrainRunIntervals(
    { loading, crudUserTrainRunIntervals }
  );

  // Some functionality can ignore the errored UserTrainRunIntervals, such as graphs and maps
  const activeUserTrainRunIntervalsWithoutErrors = useMemoActiveUserTrainRunIntervalsWithoutErrors(
    { loading, activeUserTrainRunIntervals }
  );

  // Take a hash of the activeUserTrainRunIntervals ids
  // to tell us if the actual instances change, not just distance ranges, so we can update mapbox sources
  const userTrainRunIntervalsHaveChanged = useNotLoadingHasChanged(loading || !activeUserTrainRunIntervals, () => {
      return compose(
        join(','),
        map(prop('sourceKey'))
      )(activeUserTrainRunIntervals);
    }
  );

  /**
   * Compares the TrainRouteOrGroup to that of the UserTrainRunInterval. Any mismatch means we are in a loading state
   * @returns {*}
   */
  const userTrainRunIntervalsMismatchTrainRouteOrGroup = () => {
    const trainRouteIdLookup = indexBy(prop('id'), trainRoutesOfTrainRouteOrGroup(trainProps.trainRouteProps.trainRoute));
    return any(
      userTrainRunInterval => {
        return !propOr(false,
          trainRouteOfUserTrainRunInterval(userTrainRunInterval).id,
          trainRouteIdLookup
        );
      },
      userTrainRunIntervals
    );
  };

  /**
   * Compares the TrainRuns loaded from cache and preconfigured to those that are in userTrainRunIntervals to
   * determine if userTrainRunIntervals is up-to-date
   * @returns {*}
   */
  const userTrainRunIntervalsMismatchCache = () => {
    return !(trainProps.trainRouteProps.trainRoute && strPathListsEqual(
      'trainRunInterval.trainRun.id',
      trainProps.trainRunProps.userTrainRunIntervalsForTrainRoute,
      userTrainRunIntervals
    ));
  };
  const userTrainRunIntervalsMismatchCrud = () => {
    return !strPathListsEqual(
      'trainRunInterval.trainRun.id',
      length(userTrainRunIntervals),
      length(crudUserTrainRunIntervals.list || [])
    );
  };
  const localLoadingProps = unlessLoadingProps(loading, () => {
    return {
      userTrainRunIntervalsMismatchTrainRouteOrGroup: userTrainRunIntervalsMismatchTrainRouteOrGroup(),
      userTrainRunIntervalsMismatchCache: userTrainRunIntervalsMismatchCache(),
      userTrainRunIntervalsMismatchCrud: userTrainRunIntervalsMismatchCrud()
    };
  });

  const localPropsNotReady = loading || !activeUserTrainRunIntervals || !length(values(localLoadingProps)) ||
    any(identity, values(localLoadingProps));
  const loadingExplanation = {
    'trainProps.trainRunIntervalProps.loading': loading,
    activeUserTrainRunIntervalsReady: !!activeUserTrainRunIntervals,
    ...localLoadingProps || {}
  };
  const userTrainRunIntervalProps = {
    loading: localPropsNotReady,
    loadingExplanation,
    instancesChanged: userTrainRunIntervalsHaveChanged,
    userTrainRunIntervals,
    setUserTrainRunIntervals,
    crudUserTrainRunIntervals,
    activeUserTrainRunIntervals,
    activeUserTrainRunIntervalsWithoutErrors
  };
  // We don't require anything to be in UserTrainRunIntervals, because not every TrainRoute has
  // a baseline TrainRun, which would otherwise be the default active UserTrainRunInterval
  return <TrainContainerUserTrainRunIntervalSensorDependency {...{
    appProps,
    organizationProps,
    trainProps: {
      ...trainProps,
      userTrainRunIntervalProps
    },
    mapProps
  }} />;
};
UserTrainRunIntervalDependency.displayName = 'UserTrainRunIntervalDependency';
export default UserTrainRunIntervalDependency;