import {getRideComfortGraphqlData} from "./rideComfortGraphqlQueries.ts";
import {RideComfortTrainInfo} from "../../types/rideComfort/rideComfortTrainInfo";
import {RideComfortHeatMapDatum} from "../../types/rideComfort/rideComfortHeatMapDatum";
import {Setter} from "../../visualizer-railbed/railbedTypes/hookHelpers/setter";
import {RideComfortGaugeByTimePeriod} from "../../types/rideComfort/rideComfortGauge";
import {RideComfortIntervalDescription} from "../../types/rideComfort/rideComfortintervalDescription";
import {RideComfortAlertType} from "../../types/rideComfort/rideComfortAlertType";
import {RideComfortPeriodMapData} from "../../types/rideComfort/rideComfortPeriodMapData"
import {
    RideComfortAlertLevelDatum,
    RideComfortGraphqlResponseAlertData
} from "../../types/rideComfort/rideComfortMapData";
import {compose, divide, lt, map, multiply, when} from "ramda";
import {GetTotalCount, TotaledMapDataValues} from "../../types/rideComfort/rideComfortFunctions";
import {setHeatMapWithQueryData} from "./rideComfortHooks.ts";


export const queryRideComfortApiAndMutate = async (
    currentTrainInfo: RideComfortTrainInfo,
    date: Date,
    getTotalCount: GetTotalCount,
    setRideComfortGaugeByTimePeriod: Setter<RideComfortGaugeByTimePeriod>,
    setHeatMapData: Setter<RideComfortHeatMapDatum[]>,
    totalMapDataValues: TotaledMapDataValues,
    intervalDescription: RideComfortIntervalDescription,
    rideComfortAlertType: RideComfortAlertType,
    rideComfortTimePeriodForMap: keyof RideComfortGaugeByTimePeriod,
): Promise<RideComfortGraphqlResponseAlertData> => {

    const data : RideComfortGraphqlResponseAlertData= await getRideComfortGraphqlData(
        date,
        currentTrainInfo[rideComfortAlertType.alertPointKey],
        intervalDescription,
        rideComfortTimePeriodForMap
    );

    const rideComfortPeriodMapData: RideComfortPeriodMapData = totalMapDataValues(data, getTotalCount);
    // Maps {today: {warn: total, critical: total, ...}, week: {...}, ....} to
    //  {today: {warn: {value: percentage, count: total}, ...}
    const rideComfortGauges: RideComfortGaugeByTimePeriod = map(
        (
            rideComfortAlertLevelDatum: RideComfortAlertLevelDatum,
        ) => {
            const dividend: number = (rideComfortAlertLevelDatum.normal + rideComfortAlertLevelDatum.warning + rideComfortAlertLevelDatum.critical)
            return map(
                (count: number) => {
                    return {
                        // Divide by the dividend and multiply by 100 when dividend is greater than 0
                        value: when(
                            lt(0),
                            compose(
                                (quotient: number) => multiply(100, quotient),
                                (dividend: number) => divide(count, dividend)
                            )
                        )(dividend),
                        count
                    }
                }, rideComfortAlertLevelDatum
            )
        },
        rideComfortPeriodMapData
    )
    setRideComfortGaugeByTimePeriod(rideComfortGauges)
    setHeatMapWithQueryData(data, setHeatMapData)
    return data
}
