import TrainRunDescriptionWithTrainRunLine
    from 'components/apps/trainAppComponents/trainRunChooserComponents/TrainRunDescriptionWithTrainRunLine.js';
import {useTranslation} from 'react-i18next';
import {isTrainRouteGroup, trainRouteOrGroupName} from 'appUtils/trainAppUtils/trainRouteUtils.js';
import {format} from 'date-fns';
import React from 'react'
import {
    trainDataFriendlyDateFormatString,
    trainDataFriendlyDatetimeFormatString
} from 'appUtils/trainAppUtils/trainDataUtils.js';
import TrainRunDescriptionWithoutTrainRunLine
    from 'components/apps/trainAppComponents/trainRunChooserComponents/TrainRunDescriptionWithoutTrainRunLine.js';
import {calculateRmseHighestValues} from "../../../../appUtils/trainAppUtils/derivedAttributeUtils.js";
import {propOr} from "ramda";
import {Error, Warning} from '@mui/icons-material';
import {Tooltip} from "@mui/material";
import {compact, mapObjToValues} from "@rescapes/ramda";

/***
 * Renders the TrainRunDescriptionWithTrainRunLine, possibly adding a loading icon
 * @param trainProps
 * @param componentProps
 * @param {[React.ReactElement]}componentProps.buttons
 * @param {Boolean} componentProps.isBaseline
 * @returns {JSX.Element}
 * @constructor
 */
const TrainRunDescriptionContainer = (
    {
        trainProps,
        componentProps: {
            buttons,
            showTrainRunLine
        }
    }) => {

    const {t} = useTranslation();
    // The destination TimetabledPassingTime of the TrainRun
    const destinationScheduledStopPoint = trainProps.trainRunProps.trainRun.endScheduledStopPoint;

    const trainRun = trainProps.trainRunProps.trainRun;
    const rsmeMaxValues = calculateRmseHighestValues(trainRun)
    const rmseIconLookup = {
        2: {icon: Warning, color: 'alertWarning', label: 'Warning'},
        3: {icon: Error, color: 'alertCritical', label: 'Critical'}
    }

    // Create icons for any rmse value >= 2
    const rmseIcons = compact(mapObjToValues(
        (rsmeMaxValue, key) => {
            const {icon, color, label} = propOr({}, rsmeMaxValue, rmseIconLookup)
            return icon && <Tooltip {...{key, title: `${label} alert for ${key}`}}>
                {React.createElement(icon, {color, variant: 'tiny'})}
            </Tooltip>;
        },
        rsmeMaxValues
    ))

    const trainRouteLabel = showTrainRunLine && isTrainRouteGroup(trainProps.trainRouteProps.trainRoute) ?
        trainRouteOrGroupName({t}, trainProps.trainRunProps.trainRun.trainRoute) :
        destinationScheduledStopPoint.name;

    const [TrainRunDescriptionComponent, props] = showTrainRunLine ?
        [TrainRunDescriptionWithTrainRunLine,
            {dateAndOrTimeString: format(trainRun.departureDatetime, trainDataFriendlyDateFormatString)}
        ] :
        [TrainRunDescriptionWithoutTrainRunLine,
            {
                dateAndOrTimeString: format(trainRun.departureDatetime, trainDataFriendlyDatetimeFormatString),
                isBaseline: trainProps.userTrainRunIntervalProps.userTrainRunInterval.activity.isBaseline
            }
        ];
    return <TrainRunDescriptionComponent {...{
        trainRun,
        destinationScheduledStopPoint,
        buttons,
        trainRouteLabel,
        rmseIcons,
        ...props
    }} />;
};
export default TrainRunDescriptionContainer;