import { Box } from '@mui/material';
import { toArrayIfNot } from '@rescapes/ramda';
import TrainRouteFunctionChooser
  from 'components/apps/trainAppComponents/trainRouteChooserComponents/TrainRouteFunctionChooser.js';

/**
 * The Train Line Station Dot
 * @param sx
 * @param diameter
 * @param displayProps the windowSize used to trigger rerenders
 * @param setOffsetLeft
 * @param trainRunInterval
 * @param limitedStations
 * @param recalculateOffsetLefts
 * @returns {JSX.Element}
 * @constructor
 */
const TrainLineStationDot = (
  {
    sx,
    diameter,
    stationRef,
    // showTrainRouteFunctionChooser,
    // hideTrainRouteFunctionChooser,
    showMenu,
  }) => {
  return <Box
    ref={stationRef}
    // TODO supports a pie menu for choosing TrainRoutes
    // onTouchStart={showTrainRouteFunctionChooser}
    // onTouchEnd={hideTrainRouteFunctionChooser}
    // onMouseDown={showTrainRouteFunctionChooser}
    // onMouseUp={hideTrainRouteFunctionChooser}
    // onMouseLeave={hideTrainRouteFunctionChooser}
    sx={[{
      height: diameter,
      width: diameter,
      bgcolor: 'secondary.main',
      borderRadius: '100%',
      zIndex: 2,
      position:'relative'
    },
      ...toArrayIfNot(sx)
    ]}
  >
    {showMenu && <TrainRouteFunctionChooser {...{
      x:stationRef.current.offsetWidth/2,
      y:stationRef.current.offsetHeight/2,
    }} />
    }
  </Box>;
};

export default TrainLineStationDot;