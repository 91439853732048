import React from 'react';
import TrainOverviewSchedule from 'components/apps/trainAppComponents/trainOverviewComponents/TrainOverviewSchedule.js';
import { useTheme } from '@mui/styles';
import { Box, Stack, Typography } from '@mui/material';
import { always, identity, ifElse } from 'ramda';
import TrainRunLineContainer from 'components/apps/trainAppComponents/trainLineComponents/TrainRunLineContainer.js';
import { useElementSize } from 'usehooks-ts';

import PropTypes from 'prop-types';
import TrainRouteChooserContainer
  from 'components/apps/trainAppComponents/trainRouteChooserComponents/TrainRouteChooserContainer.js';
import TrainFilterSettingsContainer
  from 'components/apps/trainAppComponents/trainFilterComponents/TrainFilterSettingsContainer.js';


/**
 * The runs tab for the trains application, which shows a list of trains that can be selected
 * @param {Object} appProps,
 * @param {Object} organizationProps,
 * @param {Object} trainProps,
 * @param {Object} componentProps
 * @param {Object} componentProps. trainPropsWithoutTrainRunIntervals
 * @param {Object} componentProps.dates
 * @param {Object} componentProps.reverseTrainRouteOrGroup Sets the reversed TrainRoute or TrainRouteGroup or
 * the current if one exists
 * @param {Object} componentProps.chooseTrainRouteOrGroup Chooses the selected TrainRoute or TrainRouteGroup
 * @param {Object} componentProps.handleDrawerOpen
 * @returns {JSX.Element}
 */
export default function TrainOverview(
  {
    appProps,
    organizationProps,
    trainProps,
    componentProps: {
      trainPropsWithoutTrainRunIntervals,
      reverseTrainRouteOrGroup,
      chooseTrainRouteOrGroup,
      //handleDrawerOpen,
      //handleDrawerClose,
      panelSize
    }
  }) {
  const [ref] = useElementSize();
  const theme = useTheme();
  const title = '';


  // This approximation of the chart position based on yAxisWidth and a bit of buffer
  // to make sure the stops of TrainRunLine match the chart points
  const trainRunLayoutProps = {
    width: '100%',
    height: '50px',
    minHeight: '38px'
  };

  const drawerWidth = '500px';
  return <Box key='select' {...{
    // This Box holders TrainFilterDrawer and the components next to it
    sx: {
      height: '100%',
      maxHeight: '100%',
      overflowY: 'hidden',
      overflowX: 'hidden',
      ...ifElse(identity,
        always({
          // Closed to open transition
          transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
          }),
          marginLeft: 0
        }),
        always({
          // Open to closed transition
          transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
          }),
          marginLeft: `-${drawerWidth}px`
        })
      )(appProps.trainFilterOpen)
    }
  }}
  >
    <Stack {...{
      direction: 'column',
      justifyContent: 'start',
      spacing: 2,
      ref,
      sx: {
        position: 'relative',
        color: 'white',
        overflowY: 'hidden',
        overflowX: 'hidden',
        height: '100%',
        maxHeight: '100%',
        overflow: 'hidden'
      }
    }}>
      <Stack {...{
        direction: 'row',
        sx: { mx: '16px' }
      }}>
        {/* TODO disabled for now in favor of the main screen display */}
        {/*<TrainFilterDrawer {...{*/}
        {/*  key: 'trainFilterDrawer',*/}
        {/*  ref,*/}
        {/*  appProps,*/}
        {/*  organizationProps,*/}
        {/*  trainProps,*/}
        {/*  componentProps: {*/}
        {/*    // TODO This is positioned absolute by MUI. I don't know if it needs to be*/}
        {/*    handleDrawerClose,*/}
        {/*    drawerWidth*/}
        {/*  }*/}
        {/*}} />*/}
        {/*<TrainFilterDrawerHandle {...{*/}
        {/*  key: 'trainFilterDrawerHandler',*/}
        {/*  // The Chip for opening the TrainFilterDrawer when closed*/}
        {/*  drawerWidth,*/}
        {/*  handleDrawerOpen,*/}
        {/*  open: appProps.trainFilterOpen*/}
        {/*}} />*/}
        <Stack {...{
          key: 'trainRouteAndFilterSummary',
          direction: 'column',
          spacing: 2,
          sx: { flexGrow: 1 }
        }} >
          <TrainRouteChooserContainer {...{
            // The TrainRoute chooser to reverse the current or select a new one
            key: 'trainRouteChooser',
            trainProps: trainPropsWithoutTrainRunIntervals,
            componentProps: {
              reverseTrainRouteOrGroup,
              chooseTrainRouteOrGroup
            }
          }} />
          <TrainRunLineContainer {...{
            key: 'trainRunLineAdjustable',
            appProps,
            organizationProps,
            trainProps: trainPropsWithoutTrainRunIntervals,
            componentProps: {
              isTrainRouteLine: true,
              isUserTrainRunLine: false,
              limitedDistanceRange: null,
              showTrainRunIntervalBars: true,
              spaceGeospatially: true,
              onlyStopsNearInterval: false,
              isAggregate: true,
              panelSize
            },
            sx: trainRunLayoutProps
          }}
          />
        </Stack>
      </Stack>
      <TrainFilterSettingsContainer {...{
        key: 'trainFilterSettings',
        appProps,
        organizationProps,
        trainProps,
        componentProps: { showAsOverview: true },
        sx: { justifyContent: 'center' }
      }} />
      <Box sx={{
        px: 2,
        pb: 2,
        width: '100%',
        overflow: 'auto'
      }}>
        <Typography key='title' align='center' variant='subtitle1'>{title}</Typography>
        <TrainOverviewSchedule {...{
          key: 'schedule',
          appProps,
          organizationProps,
          trainProps,
          componentProps: {
            panelSize
          }
        }}
        />
      </Box>
    </Stack>
  </Box>;
}

TrainOverview.propTypes = {
  appProps: PropTypes.object.isRequired,
  organizationProps: PropTypes.object.isRequired,
  trainProps: PropTypes.object.isRequired,
  componentProps: PropTypes.shape({
    trainPropsWithoutTrainRunIntervals: PropTypes.object.isRequired,
    reverseTrainRouteOrGroup: PropTypes.func.isRequired,
    chooseTrainRouteOrGroup: PropTypes.func.isRequired,
    handleDrawerOpen: PropTypes.func.isRequired,
    handleDrawerClose: PropTypes.func.isRequired
  }).isRequired
};
