// These variables are written in by Webpack
import {TFunction} from 'i18next';
import {TrainIcon} from '../../railbedComponents/apps/cemitAppComponents/sidebar/trainIcon.tsx';
import {RailIcon} from '../../railbedComponents/apps/cemitAppComponents/sidebar/railIcon.tsx';
import {CatenaryIcon} from '../../railbedComponents/apps/cemitAppComponents/sidebar/catenaryIcon.tsx';
import {StationIcon} from '../../railbedComponents/apps/cemitAppComponents/sidebar/stationIcon.tsx';
import {GrpsIcon} from '../../railbedComponents/apps/cemitAppComponents/sidebar/grpsIcon.tsx';
import {DepotIcon} from '../../railbedComponents/apps/cemitAppComponents/sidebar/depotIcon.tsx';
import {EventIcon} from '../../railbedComponents/apps/cemitAppComponents/sidebar/eventIcon.tsx';
import {SwtichIcon} from '../../railbedComponents/apps/cemitAppComponents/sidebar/swtichIcon.tsx';
import {BudgetIcon} from '../../railbedComponents/apps/cemitAppComponents/sidebar/budgetIcon.tsx';
import {SvgProps} from '../../railbedComponents/apps/cemitAppComponents/sidebar/SvgProps.ts';


const LOCAL_STORAGE_VERSION_DEFAULT = 'V20230823';
// @ts-expect-error meh
// TODO should work with typescript and process.env namespace
const featureSentry = FEATURE_SENTRY;
// @ts-expect-error meh
const featureServiceWorker = FEATURE_SERVICEWORKER;
// @ts-expect-error meh
const appEnv = APP_ENV;
// @ts-expect-error meh
const appRelease = APP_RELEASE;
export const isLocalEnvironment = () => AppSettings.APP_ENV === 'local'

// DistanceRange start/end distances are round to .02 kilometers to prevent comparison errors
export const DISTANCE_RANGE_PRECISION = 2;

export class AppSettings {
  static readonly FEATURE_SENTRY = featureSentry;
  static readonly FEATURE_SERVICEWORKER = featureServiceWorker;
  static readonly APP_ENV = appEnv;
  static readonly APP_RELEASE = appRelease;
  static readonly GRAPHQL_ENDPOINT = `https://staging-api.dimensionfour.io/cemit/graph`;
  static readonly SUB_GRAPHQL_ENDPOINT = `wss://staging-api.dimensionfour.io/subscription`;
  static readonly APPS_PATH = 'dashboard';
  // Used to correct y-acceleration by accounting for gravity
  static readonly GRAVITY_ACCELERATION = -9.80665;
  // Minimum number of meters for a TrainRun Interval so that we are sure to have at least 2 points with the 100m view
  static readonly TRAIN_RUN_INTERVAL_MINIMUM = 300;
  // TODO TrainPage is hardcoded until we reform authenticate
  static readonly tabAccess = ['train'];
  // Version the local storage since ids might change between releases for now
  static readonly LOCAL_STORAGE_TRAIN_ROUTE = `trainRoute${LOCAL_STORAGE_VERSION_DEFAULT}`;
  static readonly LOCAL_STORAGE_TRAIN_RUN_GROUPS = `trainRunGroups${LOCAL_STORAGE_VERSION_DEFAULT}`;
  static readonly LOCAL_STORAGE_TRAIN_RUN_FILTER_DATE_RANGE = `trainRunFilterDateRange${LOCAL_STORAGE_VERSION_DEFAULT}`;
  static readonly LOCAL_STORAGE_LIFETIME_INPUTS = `lifetimeInputs${LOCAL_STORAGE_VERSION_DEFAULT}`;
  // Current limit for number of TrainRuns to download at once. TODO replace with paging
  static readonly MAX_DAYS = 3;
  // The Mapbox style as created in Mapbox studio
  static readonly MAPBOX_STYLE = 'mapbox://styles/cemit/ckhxf27rc0rxq19p9cvrm7txa';
  static readonly MAPBOX_DIV_ID = 'train-map';
}

/**
 * Creates a list of TabNavigation items representing the possible Visualizer applications
 * @param t Used for translation
 * @returns The TabNavigation items
 */
export const applicationNavigationConfig = (t: TFunction): ({
  name: string;
  to: string;
  Icon: ({ fill, stroke }: SvgProps) => JSX.Element;
  key: string
})[] => {
  const appsPath = AppSettings.APPS_PATH;
  return [
    {
      key: 'train',
      name: t('train'),
      to: `/${appsPath}/train/overview`,
      Icon: TrainIcon
    },
    {
      key: 'rails,',
      name: t('rails'),
      to: `/${appsPath}/rails/overview`,
      Icon: RailIcon
    },
    {
      key: 'catenary',
      name: t('catenary'),
      to: `/${appsPath}/catenary/overview`,
      Icon: CatenaryIcon
    },
    {
      key: 'stations',
      name: t('stations'),
      to: `/${appsPath}/station/overview`,
      Icon: StationIcon
    },
    {
      key: 'depots',
      name: t('depots'),
      to: `/${appsPath}/depots/overview`,
      Icon: DepotIcon
    },
    {
      key: 'grps',
      name: t('grps'),
      to: `/${appsPath}/grps/overview`,
      Icon: GrpsIcon
    },
    {
      key: 'events',
      name: t('events'),
      to: `/${appsPath}/events`,
      Icon: EventIcon
    },
    {
      key: 'switch',
      name: t('switch'),
      to: `/${appsPath}/switch`,
      Icon: SwtichIcon
    },
    {
      key: 'budgets',
      name: t('budgets'),
      to: `/${appsPath}/budgets/overview`,
      Icon: BudgetIcon
    }
  ];
};
