import AuthFormComponent from './AuthForm.js';
import { Outer, AuthForm, ImageWrapper, Logo } from './styles.js';
import AuthPageImage from 'assets/images/auth-page.png';
import LogoSvg from 'assets/images/cemit-visualizer-logo.svg';
import { useNavigate } from 'react-router-dom';
import { routeMapping } from './utils.js';
import { always, concat, find, identity, ifElse, includes, when } from 'ramda';
import { useEffect } from 'react';
import { APPS_PATH } from 'appConfigs/appConfig.js';

const Authentication = () => {
  const token = localStorage.getItem('token');
  const group = JSON.parse(localStorage.getItem('group'));
  // TODO rename tabAccess, since the term tab is too unclear
  const access = when(identity, json => JSON.parse(json)?.tabAccess)(localStorage.getItem('tabAccess'))
  const tabAccess = concat(['train'], access || [])
  const navigate = useNavigate();

  const to = ifElse(
    always(group && Object.keys(group).length === 0 && group.constructor === Object),
    tabAccess => {
      // Iterate through the pageAccess?.tabAccess array to see if it matches train, which
      // is our only app right now
      const pageRoute = find(
        currentAppPage => {
          return includes(currentAppPage, ['train']);
        },
        tabAccess
      ) || tabAccess[0];
      return routeMapping[pageRoute];
    },
    always(`/${APPS_PATH}/train/ride_comfort`)
  )(tabAccess);

  useEffect(() => {
      if (token) {
        navigate(to, { replace: true });
      }
    },
    [token]
  );

  return (
    <>
      <Outer>
        <AuthForm>
          <AuthFormComponent />
        </AuthForm>
        <ImageWrapper>
          <Logo>
            <img src={LogoSvg} alt='Cemit Logo' />
          </Logo>
          <img src={AuthPageImage} alt='Artifact' />
        </ImageWrapper>
      </Outer>
    </>
  );
};

export default Authentication;
