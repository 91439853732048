import {
  resolveOffsetLeft,
  trainRunLineScheduledStopPointsAndMaybeDatetimes
} from 'appUtils/trainAppUtils/trainRunLineUtils.js';
import { toArrayIfNot } from '@rescapes/ramda';
import DraggableTrainRunIntervalBarsContainer
  from 'components/apps/trainAppComponents/trainRunChooserComponents/DraggableTrainRunIntervalBarsContainer.js';
import TrainRunLine from 'components/apps/trainAppComponents/trainLineComponents/TrainRunLine.js';
import { useMemo, useState } from 'react';
import useMemoTrainLineStationPropSets
  from 'components/apps/trainAppComponents/trainLineComponents/trainLineStationsProps.js';
import { useElementSize } from 'usehooks-ts';
import PropTypes from 'prop-types';
import { trainRunLineReadyHookProps } from 'components/apps/trainAppComponents/trainLineComponents/trainRunLineReadyProps.js';
import { useTrainRunLineMoverDrop } from 'async/trainAppAsync/hooks/dragHooks/trainRunIntervalDragAndDropHooks.js';
import LoaderWithText from 'components/loading/LoaderWithText.js';
import { always, length } from 'ramda';
import HoverFeature from 'components/apps/trainAppComponents/trainLineComponents/HoverFeature.js';

/**
 * Defines a TrainRunLine
 * This depends most directly trainProps.trainRunIntervalProps being loaded
 * @param appProps
 * @param organizationProps
 * @param trainProps
 * @param componentProps
 * @param componentProps.onlyStopsNearInterval
 * @param componentProps.limitedDistanceRange
 * @param componentProps.showTrainRunIntervalBars
 * @param componentProps.spaceGeospatially
 * @param componentProps.isTrainRouteLine
 * @param componentProps.isAggregate
 * @param sx
 * @param sxTrainRunIntervalBar
 * @returns {JSX.Element}
 * @constructor
 */
const TrainRunLineReadyContainer = (
  {
    appProps,
    trainProps,
    componentProps: {
      spaceGeospatially,
      onlyStopsNearInterval,
      limitedDistanceRange,
      showTrainRunIntervalBars,
      isTrainRouteLine,
      isAggregate,
      isUserTrainRunLine,
      hoverFeature,
      panelSize
    },
    sx,
    sxTrainRunIntervalBar
  }) => {

  // For now this is always false because TrainRunLineContainer gatekeeps for us
  const loading = false;

  // Store the ScheduledStopPoint that is being hovered
  const [hoveredScheduledStopPoint, setHoveredScheduledStopPoint] = useState(null)

  const [containerRef, { width }] = useElementSize();
  // Initialize the offsetLefts, we can set the offset if spaceGesopatially is true. If not we init to an empty array
  // and let the TrainLineStation components call their setOffset left which fills the array with values
  const [offsetLefts, setOffsetLefts] = useState([]);
  // This is updated with the current timestamp every time components need to recalculate their offset left
  // To report to fill offsetLefts above
  const [recalculateOffsetLefts, setRecalculateOffsetLefts] = useState(false);

  // Undefined for TrainRouteLine
  const trainRoute = trainProps.trainRouteProps.trainRoute;
  const trainRun = isTrainRouteLine ? null : trainProps.trainRunProps.trainRun;
  // The distanceRange can change and forces everything to rerender. We might change the stops showing
  // and the placement of the stops and thus also the lines between them.
  const trainRouteOrRunInterval = isTrainRouteLine ?
    trainProps.trainRouteProps.trainRouteInterval :
    trainProps.trainRunIntervalProps.trainRunInterval;

  // Props we can calculate without hooks
  const scheduledStopPointsAndMaybeTimes = trainRunLineScheduledStopPointsAndMaybeDatetimes(
    { loading, trainProps, isTrainRouteLine }
  );

  // Props we calculate with hooks
  const {
    loadingExplanation: loadingExplanationFromTrainRunLineReadyHookProps,
    visibleScheduledStopPointsAndMaybeTimes,
    visibleScheduledStopPointsAndMaybeTimesWithOffsetLefts,
    distanceRange,
    stopGaps,
    areOffsetLeftsReady
  } = trainRunLineReadyHookProps(
    {
      loading,
      trainProps,
      trainRoute,
      trainRouteOrRunInterval,
      scheduledStopPointsAndMaybeTimes,
      spaceGeospatially,
      onlyStopsNearInterval,
      isTrainRouteLine,
      isAggregate,
      isUserTrainRunLine,
      limitedDistanceRange,
      offsetLefts,
      setOffsetLefts,
      setRecalculateOffsetLefts,
    }
  );

  // Props for creating TrainLineStation components
  // offsetLefts must be initialized to an array of null items by useInitStopOffsetLefts in trainRunLineReadyHookProps
  // before this can run
  const trainLineStationPropSets = useMemoTrainLineStationPropSets(
    {
      loading: loading || !length(offsetLefts),
      trainRoute,
      trainRun,
      trainRouteOrRunInterval,
      scheduledStopPointsAndMaybeTimes: visibleScheduledStopPointsAndMaybeTimes,
      offsetLefts, setOffsetLefts,
      recalculateOffsetLefts,
      scheduledStopPointsAndMaybeTimesWithOffsetLefts: visibleScheduledStopPointsAndMaybeTimesWithOffsetLefts,
      onlyStopsNearInterval,
      spaceGeospatially,
      width,
      distanceRange
    }
  );

  // Define the drag functionality
  // Only ever one since we only have one UserTrainRunInterval defined per TrainRun
  const draggableTrainRunIntervalBars = areOffsetLeftsReady && showTrainRunIntervalBars ?
    <DraggableTrainRunIntervalBarsContainer {...{
      trainProps,
      componentProps: {
        isTrainRouteLine,
        isAggregate,
        isUserTrainRunLine,
        spaceGeospatially,
        limitedDistanceRange,
        parentWidth: width,
        routeDistancesWithOffsetLefts: visibleScheduledStopPointsAndMaybeTimesWithOffsetLefts,
        resolveOffsetLeft: resolveOffsetLeft(
          {
            routeDistancesWithOffsetLefts: visibleScheduledStopPointsAndMaybeTimesWithOffsetLefts
          }
        )
      },
      sxTrainRunIntervalBar: [{ zIndex: 3 }, ...toArrayIfNot(sxTrainRunIntervalBar)]
    }}
    />
    : null;
  const hoverFeatureComponent = areOffsetLeftsReady && hoverFeature ? <HoverFeature key='hoverFeature' {...{
    trainProps,
    componentProps: {
      hoverFeature,
      spaceGeospatially,
      resolveOffsetLeft: resolveOffsetLeft(
        {
          routeDistancesWithOffsetLefts: visibleScheduledStopPointsAndMaybeTimesWithOffsetLefts
        }
      )
    },
    sxTrainRunIntervalBar: [{ zIndex: 3 }, ...toArrayIfNot(sxTrainRunIntervalBar)]
  }}
  /> : null;

  // Defined the drop functionality
  const [{ isOver: isMoverOver }, moverDrop] = useTrainRunLineMoverDrop(
    {
      trainProps,
      isTrainRouteLine,
      areOffsetLeftsReady,
      crudTrainRunIntervals: trainProps.trainRunIntervalProps.crudTrainRunIntervals,
      crudUserTrainRunIntervals: trainProps.userTrainRunIntervalProps.crudUserTrainRunIntervals,
      trainRun,
      trainRoute,
      routeDistancesWithOffsetLefts: visibleScheduledStopPointsAndMaybeTimesWithOffsetLefts
    }
  );

  const localPropsNotReady = !trainLineStationPropSets;
  const loadingExplanation = useMemo(always({
    loadingTrainRunLineReadyContainer: loading,
    areOffsetLeftsReady,
    lengthOffsetLefts: length(offsetLefts),
    trainLineStations: trainLineStationPropSets,
    ...loadingExplanationFromTrainRunLineReadyHookProps
  }), [loading, areOffsetLeftsReady, offsetLefts, trainLineStationPropSets, loadingExplanationFromTrainRunLineReadyHookProps]);

  return localPropsNotReady ?
    <LoaderWithText {...{ text: 'loadingTrainRunLine', loadingExplanation }} /> :
    <TrainRunLine {...{
      appProps,
      trainProps,
      componentProps: {
        loadingExplanation,
        moverDrop,
        isMoverOver,
        stopGaps,
        spaceGeospatially,
        containerRef,
        trainLineStationPropSets,
        isTrainRouteLine,
        isUserTrainRunLine,
        draggableTrainRunIntervalBars,
        hoverFeatureComponent,
        hoveredScheduledStopPoint,
        setHoveredScheduledStopPoint,
        panelSize
      },
      sx,
      sxTrainRunIntervalBar
    }} />;
};

TrainRunLineReadyContainer.propTypes = {
  appProps: PropTypes.object.isRequired,
  organizationProps: PropTypes.object.isRequired,
  trainProps: PropTypes.shape({
    trainRouteProps: PropTypes.shape({
      trainRouteInterval: PropTypes.shape({
        distance: PropTypes.number.isRequired,
        distanceRange: PropTypes.shape({
          start: PropTypes.number.isRequired,
          end: PropTypes.number.isRequired
        }).isRequired
      }).isRequired
    }).isRequired,
    //'trainProps.trainRunIntervalProps.trainRunInterval': validateNotNilUnlessAnyTruthy(['componentProps.isTrainRouteLine']),
    trainRunIntervalProps: PropTypes.shape({
      trainRunInterval: PropTypes.shape({})
    }).isRequired
  }).isRequired,
  componentProps: PropTypes.shape({
    spaceGeospatially: PropTypes.bool.isRequired,
    onlyStopsNearInterval: PropTypes.bool.isRequired,
    limitedDistanceRange: PropTypes.shape({}),
    showTrainRunIntervalBars: PropTypes.bool.isRequired,
    isTrainRouteLine: PropTypes.bool.isRequired,
    isUserTrainRunLine: PropTypes.bool.isRequired,
    // This can be null but not undefined TODO make undefined validator
    hoverFeature: PropTypes.object
  })
};
export default TrainRunLineReadyContainer;