import React from 'react';
import {Box, Stack, TextField} from "@mui/material";
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider.js';
import {DateTimePicker} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns/index.js';
import enGB from "date-fns/locale/en-GB/index.js";
import {tempColors} from "../../../../theme/tempColors.ts";
import RideComfortNowButton from "./RideComfortNowButton.tsx";
import RideComfortIntervalPicker from "./RideComfortIntervalPicker.tsx"
import RideComfortAlertTypePickerComponent from "./RideComfortAlertTypePickerComponent.tsx";
import {RideComfortIntervalDescription} from "../../../../types/rideComfort/rideComfortintervalDescription";
import {Setter} from "../../../../visualizer-railbed/railbedTypes/hookHelpers/setter";
import {RideComfortAlertType} from "../../../../types/rideComfort/rideComfortAlertType";
import {Loader} from "../../../../visualizer-railbed/railbedComponents/loading/LoaderWithText.tsx";

type RideComfortChoosersComponentProps = {
    chosenDateTime: Date,
    setChosenDateTime: Setter<Date>,
    intervalDescription: RideComfortIntervalDescription,
    setIntervalDescription: Setter<RideComfortIntervalDescription>,
    intervalDescriptions: RideComfortIntervalDescription[],
    rideComfortAlertType: RideComfortAlertType,
    setRideComfortAlertType: Setter<RideComfortAlertType>,
    rideComfortAlertTypes: RideComfortAlertType[],
    rideComfortGraphqlDataLoading: boolean
}

export const RideComfortChoosersComponent = (
    {
        chosenDateTime,
        setChosenDateTime,
        intervalDescription,
        setIntervalDescription,
        intervalDescriptions,
        rideComfortAlertType,
        setRideComfortAlertType,
        rideComfortAlertTypes,
        rideComfortGraphqlDataLoading
    }: RideComfortChoosersComponentProps
) => {
    const color = tempColors.white
    const mostRecentDate = new Date()

    return <Stack {...{direction: 'row', spacing: 1, sx: {alignItems: 'center', minHeight: '75px'}}}>
        <RideComfortIntervalPicker {...{
            intervalDescription,
            setIntervalDescription,
            intervalDescriptions
        }} />
        <RideComfortAlertTypePickerComponent {...{
            rideComfortAlertType,
            setRideComfortAlertType,
            rideComfortAlertTypes,
            rideComfortGraphqlDataLoading
        }}/>

        <LocalizationProvider  {...{key: 'localization', dateAdapter: AdapterDateFns, adapterLocale: enGB}}>
            <DateTimePicker {...{
                disableFuture: true,
                renderInput: (props) => {
                    return <TextField
                        {...props}
                        sx={{
                            svg: {color},
                            input: {color},
                            label: {color}
                        }}
                    />
                },
                label: "Alerts before this date & time",
                value: chosenDateTime,
                onChange: (newValue) => {
                    if (!isNaN(newValue)) {
                        setChosenDateTime(newValue);
                    }
                }
            }}/>
        </LocalizationProvider>
        <RideComfortNowButton {...{setChosenDateTime, mostRecentDate}}/>
        <Box {...{paddingRight: '10px', right: 0, position: 'absolute'}}>
            <Loader {...{
                show: rideComfortGraphqlDataLoading
            }}/>
        </Box>
    </Stack>

}
