import {chain, filter, includes, lensPath, over, prop, uniqBy} from 'ramda';
import { useNotLoadingEffect } from 'utils/hooks/useMemoHooks.js';
import { getSimplifiedTracks } from 'lib/fetch/cemitApi/tracksApi.js';

/**
 * Resolves the RailwayLines from the ServiceLines
 * TODO this might go to the API in the future if client only retures ids
 * @param {Boolean} loading Do nothing if true
 * @param {[Object]} serviceLines The ServiceLine instances
 * @param {Function} setRailwayLines RailayLines setter
 */
export const useTrainApiSetRailwayLines = ({ loading, serviceLines, setRailwayLines }) => {
  useNotLoadingEffect(loading,
    () => {
      if (serviceLines) {
        const serviceLinesRailwayLines = uniqBy(prop('id'), chain(
          prop('railwayLines'),
          serviceLines
        ));
        setRailwayLines(serviceLinesRailwayLines);
      }
    },
    [serviceLines]
  );
};
/**
 * Load the oldTrackConfig from the API and filter down to those matching oldTrackConfig
 * @param tracks
 * @return {Promise<*>}
 */
export const fetchTracks = async tracks => {
  const tracksResponse = await getSimplifiedTracks();
  return over(
    lensPath(['data', 'features']),
    features => {
      return filter(
        feature => {
          return includes(feature.properties.trackname, tracks);
        },
        features
      );
    },
    tracksResponse
  );
};