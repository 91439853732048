import { shallowEquals } from 'utils/functional/functionalUtils.js';
import { mergeTrainRunInterval } from 'appUtils/trainAppUtils/typeMerging/trainRunIntervalMerging.js';
import { mergeDeep, strPathOr } from '@rescapes/ramda';
import { all, always, compose, cond, equals, filter, find, is, lensPath, map, mergeWithKey, over, T } from 'ramda';

/**
 *
 * Updates the ImuPoints for the UserTrainRunInterval, sorting existing with incoming
 * @param existingUserTrainRunInterval
 * @param incomingUserTrainRunInterval
 * @returns {*}
 */
export const mergeUserTrainRunInterval = (existingUserTrainRunInterval, incomingUserTrainRunInterval) => {
  return mergeWithKey(
    (key, existingPropValue, incomingPropValue) => {
      // Merge trainRunInterval with it's merge. Everything else uses the default mergeDeep,
      // which deep merges objects but not arrays
      return cond([
        // If references equal, always take the incoming prop value
        [shallowEquals, (a, b) => {
          return b;
        }],
        [always(equals('trainRunInterval', key)), mergeTrainRunInterval],
        [T, (a, b) => {
          return all(is(Object), [a, b]) ? mergeDeep(a, b) : b;
        }]
      ])(existingPropValue, incomingPropValue);
    },
    existingUserTrainRunInterval,
    incomingUserTrainRunInterval
  );
};
/**
 * Restore the UserTrainRunInterval by matching it with a TrainRun
 * @param trainRuns
 * @param userTrainRunIntervals
 * @returns {function(*=): *}
 */
export const mergeFullTrainRunsIntoUserTrainRunIntervals = ({ trainRuns, userTrainRunIntervals }) => {
  const restoredUserTrainRunIntervals = map(userTrainRunInterval => {
    return compose(
      // Restore the matching TrainRun
      userTrainRunInterval => {
        return over(
          lensPath(['trainRunInterval', 'trainRun']),
          ({ id }) => {
            const matchingTrainRun = find(({ id: _id }) => equals(id, _id), trainRuns);
            // If we don't match, it means the TrainRun is filtered out. So just return null
            if (!matchingTrainRun) {
              // throw Error(`Error deserializing UserTrainRunInterval from local storage with TrainRun id ${id}. Could not find matching loaded TrainRun`);
              return null;
            }
            return matchingTrainRun;
          },
          userTrainRunInterval
        );
      }
    )(userTrainRunInterval);
  }, userTrainRunIntervals);

  return filter(
    userTrainRunInterval => {
      return strPathOr(false, 'trainRunInterval.trainRun', userTrainRunInterval);
    },
    restoredUserTrainRunIntervals
  );
};