import { typenameEquals } from 'appUtils/typeUtils/typenameUtils.js';
import { inspect } from 'util';
import {
  always,
  chain,
  cond,
  equals,
  length,
  filter,
  flatten,
  identity,
  ifElse,
  join,
  map,
  prop,
  propOr,
  T,
  when
} from 'ramda';

/**
 * Gives the TrainApi parameter for querying for TrainRuns matching the givrn TrainRoute or TrainRouteGroup
 * @param {Object} trainRouteOrGroup A TrainRoute or TrainRouteGroup instance
 * @returns {Object} The query params
 */
export const trainRunTrainRouteParams = trainRouteOrGroup => {
  return cond([
    [typenameEquals('TrainRouteGroup'),
      trainRouteGroup => {
        return {
          jourenyPattern_trainRouteId_in_: join(',', map(prop('id'), trainRouteGroup.trainRoutes))
        };
      }
    ],
    [typenameEquals('TrainRoute'),
      trainRoute => ({ jourenyPattern_trainRouteId: trainRoute.id })
    ],
    [T,
      trainRoute => {
        throw Error(`Non-TrainRoute or TrainRouteGroup instance: ${inspect(trainRoute, { depth: 1 })}`);
      }
    ]
  ])(trainRouteOrGroup);
};

/**
 * Creates params for querying TrainRun by dateReanger
 * @param dateRange
 * @returns {*}
 */
export const trainDateParams = dateRange => {
  return ifElse(
    identity,
    dateRange => {
      return {
        departureDatetime_ge: dateRange.start,
        departureDatetime_le: dateRange.end
      };
    },
    always({})
  )(dateRange);
};

/**
 * Creates params for querying TrainRun by dateRecurrences
 * @param dateRecurrences
 * @returns {*}
 */
export const trainDateRecurrenceParams = dateRecurrences => {
  return ifElse(
    identity,
    dateRecurrences => {
      const dateRecurrenceDays = join(',',
        chain(
          dateRecurrence => {
            // flatten is here for weekend and weekday groupings
            return flatten(dateRecurrence.values);
          },
          filter(
            dateRecurrence => equals('getDay', dateRecurrence.type),
            dateRecurrences || []
          )
        )
      );

      const dateRecurrenceDepartureTimes = when(
        list => length(list),
        list => {
          return encodeURIComponent(join(',', list));
        }
      )(
        map(
          // TODO this format of dateRecurrence.values.values  needs to be fixed
          dateRecurrence => chain(value => {
              return propOr([value], 'values', value);
            },
            dateRecurrence.values
          ),
          filter(
            dateRecurrence => equals('getTime', dateRecurrence.type),
            dateRecurrences || []
          )
        )
      );
      return {
        departureDay_in_: dateRecurrenceDays,
        departureTime_in_: dateRecurrenceDepartureTimes
      };
    },
    always({})
  )(dateRecurrences);
};