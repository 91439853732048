import { format } from 'date-fns';
import { equals, ifElse, includes, isNil, map, prop, propOr, unless } from 'ramda';
import { shouldClusterIfOverlapsGreaterOrEqualPriorityStops } from 'appUtils/trainAppUtils/trainRunLineUtils.js';
import { useEffect, useState } from 'react';
import { extremes } from 'utils/functional/functionalUtils.js';
import { findMapped, strPath } from '@rescapes/ramda';
import TrainLineStation from 'components/apps/trainAppComponents/trainLineComponents/TrainLineStation.js';
import { referenceStopDistanceForTrainRoute } from 'appUtils/trainAppUtils/trainRouteUtils.js';
import useElementSizeWithRef from 'utils/hooks/domHooks.js';

/***
 * A Station on the TrainLine
 * @param {Object} scheduledStopPointAndMaybeDateTime The scheduledStopPointAndMaybeDateTime/station
 * @param {[Object]} stopsWithOffsetLefts Prevents overlapping stops by minimizing those too close to others
 * with higher priority
 * @param appProps
 * @param trainRun
 * @param measureDistanceFromDistance
 * @param offsetLeft  null unless spaceGeospatially is true. If we are not spacing geospationally, rather with
 * flex, this value is calculated by the station dots with useOffsetLeft, which calls setOffsetLeft
 * @param setOffsetLeft
 * @param trainRouteOrRunInterval
 * @param limitedStations
 * @param  {Boolean} recalculateOffsetLefts Toggles between true and folse to tell the TrainLineStation
 * to recompute its offsetLeft and call setOffsetLefts
 * @param {Boolean} [spaceGeospatially] Default false. If true, position the TrainLineStation absolutely
 * @param {Number} parentWidth The width of the parent component
 * @param {Object} distanceRange Used to trigger clustering
 * based on the
 * @param {String} stationPosition 'start', 'end' or null if the station is in the middle and not at the extremes
 * @returns {JSX.Element}
 * @constructor
 */
const TrainLineStationContainer = (
  {
    appProps,
    trainProps,
    componentProps: {
      offsetLeft,
      scheduledStopPointsAndMaybeTimesWithOffsetLefts,
      setOffsetLeft,
      recalculateOffsetLefts,
      scheduledStopPointAndMaybeDateTime,
      trainRun,
      trainRoute,
      measureDistanceFromDistance,
      trainRouteOrRunInterval,
      limitedStations,
      spaceGeospatially,
      parentWidth,
      distanceRange,
      isTrainRouteLine,
      stationPosition,
      hoveredScheduledStopPoint,
      setHoveredScheduledStopPoint,
      panelSize
    }
  }) => {

  /*
  const [{ isOverStop }, dropStop] = useDrop(
    () => ({
      accept: [
        ItemTypes.TRAIN_RUN_INTERVAL_BAR_MOVER,
        ItemTypes.TRAIN_RUN_INTERVAL_BAR_LEFT_EXPANDER,
        ItemTypes.TRAIN_RUN_INTERVAL_BAR_RIGHT_EXPANDER
      ],
      canDrop: always(false),
      collect: monitor => {
        return {
          isOverStop: monitor.isOver()
        };
      }
    }),
    []
  );
   */

  const [ref, { width: refWidth }, readRef] = useElementSizeWithRef();

  const [shouldCluster, setShouldCluster] = useState(false);

  useEffect(() => {
    const id = prop('id', scheduledStopPointAndMaybeDateTime);
    // Test if we should cluster and never cluster the first and last stops
    const _offsetLeft = spaceGeospatially ? offsetLeft : readRef?.offsetLeft;
    const should = refWidth && !isNil(_offsetLeft) && !includes(
      id,
      map(strPath('scheduledStopPointAndMaybeDateTime.id'), extremes(scheduledStopPointsAndMaybeTimesWithOffsetLefts))
    ) ?
      shouldClusterIfOverlapsGreaterOrEqualPriorityStops({
        // If spaceGeospatially, calculate componentWidth as a fraction of the parent.
        // For even flex spacing, we can rely on the component width
        componentWidth: spaceGeospatially ? (100 * refWidth / parentWidth) : refWidth,
        scheduledStopPointsAndMaybeTimesWithOffsetLefts,
        spaceGeospatially
      }, {
        scheduledStopPointAndMaybeDateTime,
        offsetLeft: _offsetLeft
      }) : false;
    setShouldCluster(should);
  }, [readRef?.offsetLeft, refWidth, parentWidth, distanceRange, scheduledStopPointsAndMaybeTimesWithOffsetLefts]);

  const scheduledStopPointStopName = scheduledStopPointAndMaybeDateTime.shortName;

  // If a TrainRoute, show distances, else show the departure time
  const scheduledStopPointStopTimeOrDistance = ifElse(
    ({ isTrainRouteLine }) => isTrainRouteLine,
    ({ trainRoute }) => {
      if (scheduledStopPointAndMaybeDateTime.distanceLabel) {
        // Pseudo stops
        return scheduledStopPointAndMaybeDateTime.distanceLabel;
      }
      const referenceStopDistance = referenceStopDistanceForTrainRoute(
        {
          trainRoute,
          scheduledStopPoint: scheduledStopPointAndMaybeDateTime,
          railwayLines: trainProps.railwayLineProps.railwayLines
        }
      );
      const measureFrom = referenceStopDistance ?
        Math.abs(referenceStopDistance - scheduledStopPointAndMaybeDateTime.routeDistance).toFixed(0) :
        scheduledStopPointAndMaybeDateTime.routeDistance.toFixed(0);
      // Convert to km rounded and display with a + if not 0
      const distance = unless(
        value => equals(0, Math.round(value)),
        value => `+${value}`
      )(Math.round(measureFrom / 1000));
      return `${distance}km`;
    },
    () => {
      // Choose the arrive time if the departure time doesn't exist
      // We could favor the arrival time sometimes, but it's not that important
      const stationDateTime = findMapped(
        prop => {
          return propOr(null, prop, scheduledStopPointAndMaybeDateTime.timetabledPassingDatetime);
        },
        ['departureDatetime', 'arrivalDatetime']
      );
      // stationDateTime is only defined if this station/stop is part of the TrainRun's TrainRoute
      return stationDateTime ? format(stationDateTime, 'HH:mm') : null;
    })({ isTrainRouteLine, trainRoute, trainRun });

  return <TrainLineStation {...{
    ref,
    appProps,
    trainProps,
    componentProps: {
      scheduledStopPoint: scheduledStopPointAndMaybeDateTime,
      hoveredScheduledStopPoint,
      setHoveredScheduledStopPoint,
      scheduledStopPointsAndMaybeTimesWithOffsetLefts,
      trainRun,
      measureDistanceFromDistance,
      offsetLeft,
      setOffsetLeft,
      recalculateOffsetLefts,
      trainRouteOrRunInterval,
      limitedStations,
      spaceGeospatially,
      parentWidth,
      distanceRange,
      refWidth,
      shouldCluster,
      scheduledStopPointStopName,
      scheduledStopPointStopTimeOrDistance,
      stationPosition,
      panelSize
    }
  }} />;
};
export default TrainLineStationContainer;

