import {simplyFetchFromGraph} from '../../appUtils/rideComfortUtils/graphqlQueryUtils.ts';
import {chain, equals, find, join, map} from "ramda";
import {subMinutes, subMonths, subWeeks} from "date-fns";
import {RideComfortIntervalDescription} from "../../types/rideComfort/rideComfortintervalDescription";
import {Perhaps} from "../../visualizer-railbed/railbedTypes/typeHelpers/perhaps";
import {RideComfortGraphqlResponseAlertData} from "../../types/rideComfort/rideComfortMapData";
import {RideComfortGaugeByTimePeriod} from "../../types/rideComfort/rideComfortGauge";
import {PeriodEnum} from "../../types/rideComfort/rideComfortAlertLevel.ts";
import {RideComfortDuration} from "../../types/rideComfort/RideComfortDuration";
import {findOrThrow} from "../../visualizer-railbed/railbedUtils/functional/functionalUtils.ts";

const stringifyDate = (date: Date) => {
    return date.toISOString()
}

/**
 * Creates several graphql queries that are sent together to the server.
 * The first 3 are aggregations and the second 3 are individual results with lat/lon
 * @param from
 * @param pointId
 * @param intervalDescription
 * @param rideComfortTimePeriodForMap
 */
export async function getRideComfortGraphqlData(
    from: Date,
    pointId: string,
    intervalDescription: RideComfortIntervalDescription,
    rideComfortTimePeriodForMap: keyof RideComfortGaugeByTimePeriod
): Promise<Perhaps<RideComfortGraphqlResponseAlertData>> {
    const levels = ["L0", "L1", "L2", "L3"]
    const durations: RideComfortDuration[] = [
        {
            label: "Today",
            key: PeriodEnum.today,
            func: date => {
                // Today uses interval.value minutes to get the minutes up to the chosen DateTime
                return subMinutes(date, intervalDescription.duration || 0)
            }
        } as RideComfortDuration,
        {
            label: "Week",
            key: PeriodEnum.week,
            func: date => {
                // Ignore interval and get the last week up to the chosen DateTime
                return subWeeks(date, 1)
            }
        } as RideComfortDuration,
        {
            label: "Month",
            key: PeriodEnum.month,
            func: date => {
                // Ignore interval and get the last month up to the chosen DateTime
                return subMonths(date, 1)
            }
        } as RideComfortDuration
    ]
    const filteredDurations = intervalDescription.label == 'Short' ? [durations[0]] : durations


    // minute 1 up to 24 hours, hour up to 7 days, day over that
    const windows = [
        {label: 'MINUTE_1', value: 60 * 60 * 24}, // For up to a day
        {label: 'HOUR_1', value: 60 * 60 * 24 * 7}, // For up to week
        {label: 'DAY_1', value: 60 * 60 * 24 * 365} // For all else
    ]

    // Use the main duration, the first, for the bottom queries for the heatmap non-aggregated data
    const chosenDuration: RideComfortDuration = findOrThrow<Duration>(
        (filteredDuration: RideComfortDuration): RideComfortDuration => {
            return equals(filteredDuration.key, rideComfortTimePeriodForMap)
        },
        filteredDurations
    )
    const fromStringified = stringifyDate(chosenDuration.func(from))
    const toStringified = stringifyDate(from)

    const signalAggregations = chain(
        duration => {
            return map(
                level => {
                    // Use the interval of from - chosen duration to from
                    const [fromDate, toDate] = [duration.func(from), from]
                    const [fromStr, toStr] = map(
                        stringifyDate,
                        [fromDate, toDate]
                    )
                    const seconds = (toDate - fromDate) / 1000
                    const windowToUse = find(
                        win => {
                            return win.value > seconds
                        },
                        windows
                    )

                    return `${level}_${duration.label}:signalsAggregation(
      where: { pointId:"${pointId}", type: "${level}", unit: GENERIC }
      aggregate: {
        from:"${fromStr}"
        to: "${toStr}"
        window: ${windowToUse.label}
      }
    ) {
      count
    }`
                },
                levels
            )
        },
        filteredDurations
    )

    const pointsData = `
  query{
    ${join("\n", signalAggregations)}
    
  levelAll: signals(
    where: {
    pointId: { _EQ:"${pointId}"}
      _AND: [
        { timestamp: { _GTE: "${fromStringified}" } }
        { timestamp: { _LTE: "${toStringified}"} }
      ]
 
      data: { numericValue: { _GT: 0.5 } }
    }

    paginate: { last: 400 }
  ) {
    edges {
      node {
        timestamp
        type
        data {
          rawValue
        }
        metadata
        location {
          lat
          lon
        }
      }
    }
  }
}  
  `;
    try {
        const {data} = await simplyFetchFromGraph({
            query: pointsData,
        });
        return {data};
    } catch (error) {
        return null;
    }
}
